<div class="login-wrapper request-password-reset">
  <form class="login" (ngSubmit)="onSubmit()" #requestPasswordResetForm="ngForm">
    <div class="login-group">
      <input clrInput #email class="username" type="email" name="email" placeholder="{{ 'USER.EMAIL' | translate }}" [(ngModel)]="model.email" required />
      <clr-alert *ngIf="errorMessage" [clrAlertType]="'danger'" [clrAlertClosable]="false">
        <div class="alert-item">
          <span class="alert-text">{{errorMessage}}</span>
        </div>
      </clr-alert>
      <clr-alert *ngIf="successMessage" [clrAlertType]="'success'" [clrAlertClosable]="false">
        <div class="alert-item">
          <span class="alert-text">{{successMessage}}</span>
        </div>
      </clr-alert>
      <button type="submit" class="btn btn-primary" [disabled]="!requestPasswordResetForm.form.valid">{{ 'LOGIN.REQUEST_PASSWORD_RESET' | translate }}</button>
    </div>
    <a [routerLink]="['/core/register']" class="register-link">
      {{ 'LOGIN.REGISTER' | translate }}
    </a>
  </form>
</div>

