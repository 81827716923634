import { Component, Injector, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import * as _moment from 'moment';
import {  catchError, of, switchMap } from "rxjs";
import notify from 'devextreme/ui/notify';
import { Product, ProductPropertyTypes } from "../../../auction/shared/models/product";
import { ServiceFactory } from "../../factory/service-factory";
import { getFileNameFromResponseContentDisposition, saveFile } from "../../helpers/file-download-helper";
import { IProductService } from "../../interfaces/product";
import { IReportService } from "../../interfaces/report";
import { ApplicationSettings } from "../../models/application-settings";
import { DateRanges, Report, ReportFilterModel } from "../../models/report";
import { AuthService } from "../../services/auth.service";
import { TitleService } from "../../services/title.service";
import { WebApiService } from "../../services/web-api.service";

const moment = (_moment as any).default ? (_moment as any).default : _moment;

const NOON = 12;
const BEGINNING_DATE = 'dateFrom';
const END_DATE = 'dateTo';

@Component({
  selector: 'report-download-component',
  templateUrl: 'report-download.component.html',
  styleUrls: ['report-download.component.scss']
})
export class ReportDownloadComponent implements OnInit {

  message: string;
  reportId: number;
  productId: number;
  auctionClusterId: number;
  report: Report;
  queryParameters: any;
  reportService: IReportService;
  productService: IProductService;

  constructor(
    protected translate: TranslateService,
    protected titleService: TitleService,
    protected route: ActivatedRoute,
    protected appSettings: ApplicationSettings,
    protected webApiService: WebApiService,
    private authenticationService: AuthService
  ) {
    this.getServices(route, appSettings, webApiService)
  }

  ngOnInit(): void {
      
  }

  init() {
    this.titleService.set('REPORTS.QR_INVOICE');
    this.translate.get('REPORTS.DOWNLOAD').subscribe((res: string) => {
      this.message = res;
    });

    this.getParameters();
    this.getDataFromServices();
  }

  getServices(route, appSettings, webApiService) {
    this.reportService = ServiceFactory.getReportService(route, appSettings, webApiService);
    this.productService = ServiceFactory.getProductService(route, appSettings, webApiService);
  }

  getParameters() {
    this.auctionClusterId = isNaN(+this.route.snapshot.params['auctionClusterId'])
    ? +this.route.snapshot.params['id']
    : +this.route.snapshot.params['auctionClusterId'];
    
    this.route.params.subscribe(params => {
      if(params['reportId']) {
        this.reportId = params['reportId'] as number;
      }
    });
    
    this.route.queryParams.subscribe(qparams => {
      this.queryParameters = qparams;
    });
  }

  getDataFromServices() {
    const result = this.reportService.getReport(this.reportId).pipe(switchMap(report => {
      this.report = report;
      return this.productService.getProductForReports(this.auctionClusterId, report.reportDesign.productId);
    }), switchMap(product => this.createReportRequestModel(this.queryParameters, product)), 
    catchError(error => of(
      this.translate.get('REPORTS.DOWNLOAD_FAILED').subscribe((res: string) => {
      this.message = res;
      }))
    ));

    result.subscribe(reportRequestModel => {
      this.reportService.requestDownload(this.reportId, reportRequestModel).subscribe(result => {
            var fileName = getFileNameFromResponseContentDisposition(result);
            saveFile(result.body, fileName);
            this.translate.get('REPORTS.DOWNLOAD_SUCCESS').subscribe((res: string) => {
              this.message = res;
            });
          }, error => {
            this.translate.get('REPORTS.DOWNLOAD_FAILED').subscribe((res: string) => {
              this.message = res;
            });
          });
    });
  }
  
  createReportRequestModel(queryParameters: any, product: Product) {   

    // creating report request model from query parameters
    // exclude dateFrom and dateTo from system properties (used for dates without stored procedures)    
    var systemPropertyKeys = Object.keys(queryParameters).filter(prop => prop !== BEGINNING_DATE && prop !== END_DATE);
    var filters = [];

    systemPropertyKeys.forEach(property => {
      var reportFilter = this.buildReportFilterModel(property, product);
      filters.push(reportFilter);      
    });

    var reportRequestModel = {
      LanguageCode: this.translate.currentLang,
      TimeZoneOffset: new Date().getTimezoneOffset(),
      UserId: this.authenticationService.currentUserId,
      Filters: filters
    };

    return of(reportRequestModel);
  }

  buildReportFilterModel(propertyName: string, product: Product) {    
    let filterValue = this.queryParameters[propertyName];
    let filterEntityId = null;

    const productProperty = product.productProperties.filter(x => x.sysName === propertyName)[0];
    let currentProperty = null;

    if (productProperty) {
      currentProperty = product.reportProperties.filter(x => x.productPropertyId === productProperty.productPropertyId)[0];
      if (productProperty.propertyTypeId === ProductPropertyTypes.DATE && !currentProperty?.filterStoredProcedure) {

        var selectedDate;
        if (this.queryParameters.hasOwnProperty(propertyName)) {
          selectedDate = this.queryParameters[propertyName];
        }

        if (selectedDate === DateRanges[DateRanges.TODAY]) {
          filterValue = moment.utc().hours(NOON).format().toString();
        }
        if (selectedDate === DateRanges[DateRanges.YESTERDAY]) {
          filterValue = moment.utc().subtract(1, 'days').hours(NOON).format().toString();
        }
        if (selectedDate === DateRanges[DateRanges.LAST_WEEK]) {
          const dateFrom = moment.utc().subtract(1, 'weeks').hours(NOON);
          const dateTo = moment.utc().hours(NOON);
          filterValue = dateFrom.format() + '|' + dateTo.format().toString();
        }
        if (selectedDate === DateRanges[DateRanges.TOMORROW]) {
          filterValue = moment.utc().add(1, 'days').hours(NOON).format().toString();
        }
        if (selectedDate === DateRanges[DateRanges.RANGE]) {
          var dateFrom;
          var dateTo;

          if (this.queryParameters.hasOwnProperty(BEGINNING_DATE)) {
            dateFrom = this.queryParameters[BEGINNING_DATE];
          }
          if (this.queryParameters.hasOwnProperty(END_DATE)) {
            dateTo = this.queryParameters[END_DATE];
          }

          // check date validity 
          if (!moment(dateFrom).isValid() || !moment(dateTo).isValid()) {         
            this.translate.get('REPORTS.INVALID_DATE').subscribe((res: string) => {              
              notify(res, 'error', 5000);
            });   
          } else {
            filterValue = moment(dateFrom).hours(NOON).format() + '|' + moment(dateTo).hours(NOON).format().toString();
          }
        }      
        if (selectedDate === DateRanges[DateRanges.DATE]) {          
          var dateFrom;

          if (this.queryParameters.hasOwnProperty(BEGINNING_DATE)) {
            dateFrom = this.queryParameters[BEGINNING_DATE];
          }

          // check date validity 
          if (!moment(dateFrom).isValid()) {         
            this.translate.get('REPORTS.INVALID_DATE').subscribe((res: string) => {              
              notify(res, 'error', 5000);
            });   
          } else {
            filterValue = moment(dateFrom).hours(NOON).format().toString();
          }          
        }
      } 
      else if (productProperty.propertyTypeId === ProductPropertyTypes.DATE && currentProperty?.filterStoredProcedure) {
        var selectedDate;
        if (this.queryParameters.hasOwnProperty(propertyName)) {
          selectedDate = this.queryParameters[propertyName];
        }  
        filterValue = selectedDate ? moment(selectedDate).hours(NOON).format().toString() : null;
      } 
      else {
        if (this.queryParameters.hasOwnProperty(propertyName)) {
          filterValue = this.queryParameters[propertyName];
        }  
        if (productProperty.propertyTypeId === ProductPropertyTypes.MASTER_DATA_VALUE) {
          //TODO: MASTER DATA ENTITY ID
        }
      }
    } 

    var reportFilter = new ReportFilterModel();
    reportFilter.productPropertyId = productProperty?.productPropertyId;
    reportFilter.productPropertyTypeId = productProperty?.propertyTypeId;
    reportFilter.key = propertyName,
    reportFilter.value = filterValue,
    reportFilter.entityId = filterEntityId;

    return reportFilter;  
  }
}
