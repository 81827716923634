<div class="item-list-component">

  <br />
  <ng-container *ngIf="showFilterProperties">
      <label>{{ 'PRODUCT.FILTER_PROPERTIES' | translate }}</label>
    <generic-product-properties-component [data]="filterProperties" (onDataChanged)="onFilterPropertiesUpdated()"
      [productPropertyTypes]="propertyTypes" [productProperties]="productProperties"
      [masterData]="masterData" [isOrderNeeded]="false" [isMasterDataFieldNeeded]="false" [showName]="false"></generic-product-properties-component>
  </ng-container>

  <label>{{ 'PRODUCT.GROUPING_PROPERTIES' | translate }}</label>
  <div class="grid-buttons">
    <clr-tooltip>
      <clr-icon shape="add" clrTooltipTrigger size="16" (click)="addGroupProperty()"></clr-icon>
      <clr-tooltip-content class="tooltip-content" clrPosition="top-left" clrSize="sm">
        <span>{{ 'PRODUCT.ADD_GROUPING_PROPERTY' | translate }}</span>
      </clr-tooltip-content>
    </clr-tooltip>
  </div>
  <clr-datagrid id="printSection">
    <clr-dg-column class="no-print two-buttons-column">
      <clr-tooltip role="tooltip" aria-haspopup="true" [clrTooltipDirection]="'top-left'" [clrTooltipSize]="'xs'">
        <clr-icon clrTooltipTrigger class="edit-btn" shape="arrow" dir="up" size="16" (click)="moveUp($event)"></clr-icon>
        <clr-tooltip-content class="tooltip-content">{{ 'MOVE_UP' | translate }}</clr-tooltip-content>
      </clr-tooltip>
      <clr-tooltip role="tooltip" aria-haspopup="true" [clrTooltipDirection]="'top-left'" [clrTooltipSize]="'xs'">
        <clr-icon clrTooltipTrigger class="edit-btn" shape="arrow" dir="down" size="16" (click)="moveDown($event)"></clr-icon>
        <clr-tooltip-content class="tooltip-content">{{ 'MOVE_DOWN' | translate }}</clr-tooltip-content>
      </clr-tooltip>
    </clr-dg-column>
    <clr-dg-column>{{ 'PRODUCT.PROPERTY_NAME' | translate }}</clr-dg-column>
    <clr-dg-column>{{ 'PRODUCT.PROPERTY.TYPE' | translate }}</clr-dg-column>
    <clr-dg-column class="no-print two-buttons-column">&nbsp;</clr-dg-column>

    <clr-dg-placeholder>{{'NO_DATA' | translate }}</clr-dg-placeholder>

    <clr-dg-row *clrDgItems="let item of groupingProperties; let i = index">
      <clr-dg-cell class="no-print two-buttons-cell">
        <input type="checkbox" clrCheckbox name="isCollapsed" [(ngModel)]="item.selected" />
      </clr-dg-cell>
      <clr-dg-cell (click)="editGroupProperties(item, $event)">{{item.productPropertyName | translateJson}}</clr-dg-cell>
      <clr-dg-cell (click)="editGroupProperties(item, $event)">{{item.productPropertyTypeName}}</clr-dg-cell>
      <clr-dg-cell class="no-print two-buttons-cell" (click)="editGroupProperties(item, $event)">
        <clr-tooltip role="tooltip" aria-haspopup="true" [clrTooltipDirection]="'top-left'" [clrTooltipSize]="'xs'">
          <clr-icon class="edit-btn" shape="edit" size="16" (click)="editGroupProperties(item, $event)"></clr-icon>
          <clr-tooltip-content class="tooltip-content">{{ 'EDIT' | translate }}</clr-tooltip-content>
        </clr-tooltip>
        <clr-tooltip role="tooltip" aria-haspopup="true" [clrTooltipDirection]="'top-left'" [clrTooltipSize]="'xs'">
          <clr-icon class="delete-btn" shape="trash" size="16" (click)="deleteGroupingProperty(item, $event)"></clr-icon>
          <clr-tooltip-content class="tooltip-content">{{ 'DELETE' | translate }}</clr-tooltip-content>
        </clr-tooltip>
      </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer>
      <pagination-component class="footer-pagination" tootltip-content="{{ 'SET_PAGE_SIZE' | translate }}" [pageSizeValue]="pageSizeValue" (changed)="setPageSize($event)"></pagination-component>
      <span class="pagination-fixed footer-pagination">
        <span *ngIf="groupingProperties?.length > 0">{{ 'PRODUCT.PROPERTY.PAGINATION_TEXT' | translate:{firstItem: pagination.firstItem + 1, lastItem: pagination.lastItem + 1, totalItems: pagination.totalItems} }}</span>
        <span *ngIf="groupingProperties?.length == 0">{{ 'PRODUCT.PROPERTY.PAGINATION_TEXT_PLACEHOLDER' | translate:{totalItems: pagination.totalItems} }}</span>
        <clr-dg-pagination #pagination [clrDgPageSize]="pageSize"></clr-dg-pagination>
      </span>
    </clr-dg-footer>
  </clr-datagrid>


  <div class="group-items">
    <label>{{ 'PRODUCT.NON_GROUPING_PROPERTIES' | translate }}</label>
  </div>
  <div class="grid-buttons group-items">
    <clr-tooltip>
      <clr-icon shape="add" clrTooltipTrigger size="16" (click)="addNonGroupProperty()"></clr-icon>
      <clr-tooltip-content class="tooltip-content" clrPosition="top-left" clrSize="sm">
        <span>{{ 'PRODUCT.ADD_NON_GROUPING_PROPERTY' | translate }}</span>
      </clr-tooltip-content>
    </clr-tooltip>
  </div>
  <clr-datagrid id="printSection">
    <clr-dg-column>{{ 'PRODUCT.PROPERTY.NAME' | translate }}</clr-dg-column>
    <clr-dg-column>{{ 'PRODUCT.PROPERTY.TYPE' | translate }}</clr-dg-column>
    <clr-dg-column>{{ 'PRODUCT.PROPERTY.MASTER_DATA_FIELD' | translate }}</clr-dg-column>
    <clr-dg-column class="no-print tab-two-buttons-column">&nbsp;</clr-dg-column>

    <clr-dg-placeholder>{{'NO_DATA' | translate }}</clr-dg-placeholder>

    <clr-dg-row *clrDgItems="let item of nonGroupingProperties; let i = index" (click)="editNonGroupProperties(item, $event)">
      <clr-dg-cell>{{item.productPropertyName | translateJson}}</clr-dg-cell>
      <clr-dg-cell>{{item.productPropertyTypeName}}</clr-dg-cell>
      <clr-dg-cell>{{item.masterDataFieldName | translateJson }}</clr-dg-cell>
      <clr-dg-cell class="no-print two-buttons-cell">
        <clr-tooltip role="tooltip" aria-haspopup="true" [clrTooltipDirection]="'top-left'" [clrTooltipSize]="'xs'">
          <clr-icon class="edit-btn" shape="edit" size="16" (click)="editNonGroupProperties(item, $event)"></clr-icon>
          <clr-tooltip-content class="tooltip-content">{{ 'EDIT' | translate }}</clr-tooltip-content>
        </clr-tooltip>
        <clr-tooltip role="tooltip" aria-haspopup="true" [clrTooltipDirection]="'top-left'" [clrTooltipSize]="'xs'">
          <clr-icon class="delete-btn" shape="trash" size="16" (click)="deleteNonGroupingProperty(item, $event)"></clr-icon>
          <clr-tooltip-content class="tooltip-content">{{ 'DELETE' | translate }}</clr-tooltip-content>
        </clr-tooltip>
      </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer>
      <pagination-component class="footer-pagination" tootltip-content="{{ 'SET_PAGE_SIZE' | translate }}" [pageSizeValue]="pageSizeValue" (changed)="setPageSize($event)"></pagination-component>
      <span class="pagination-fixed footer-pagination">
          <span *ngIf="nonGroupingProperties?.length > 0">{{ 'PRODUCT.PROPERTY.PAGINATION_TEXT' | translate:{firstItem: pagination.firstItem + 1, lastItem: pagination.lastItem + 1, totalItems: pagination.totalItems} }}</span>
          <span *ngIf="nonGroupingProperties?.length == 0">{{ 'PRODUCT.PROPERTY.PAGINATION_TEXT_PLACEHOLDER' | translate:{totalItems: pagination.totalItems} }}</span>
          <clr-dg-pagination #pagination [clrDgPageSize]="pageSize"></clr-dg-pagination>
      </span>
    </clr-dg-footer>
  </clr-datagrid>
</div>

<grouping-property-component (onAdded)="onGroupingPropertyAdded($event)" (onUpdated)="onGroupingPropertyUpdated($event)" [isSupplyData]="isSupplyData"></grouping-property-component>
<non-grouping-property-component (onAdded)="onNonGroupingPropertyAdded($event)" (onUpdated)="onNonGroupingPropertyUpdated($event)"></non-grouping-property-component>
