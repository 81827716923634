import { Component, Output, OnInit, Injector, EventEmitter, Input } from '@angular/core';

// general services
import { LanguageService } from '../../shared/services/language.service';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';

// models
import { ProductProperty, ProductPropertyType, ProductPropertyTypes, GroupingSelection } from '../shared/models/product';
import { MasterData, MasterDataField } from '../shared/models/master-data';
import { MasterDataService } from '../shared/services/master-data.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'grouping-property-component',
  templateUrl: 'grouping-property.component.html',
  styleUrls: ['./grouping-property.component.scss']
})
export class GroupingPropertyComponent extends ItemDetailsComponent<GroupingSelection> implements OnInit {

  productPropertyTypes: Array<ProductPropertyType> = [];
  masterDatas: Array<MasterData> = [];
  productProperties: Array<ProductProperty> = [];
  isSupplyData: boolean;
  isMasterData: boolean;
  masterDataFields: Array<MasterDataField> = [];
  auctionClusterId: number;

  // tslint:disable:no-output-on-prefix
  @Output() onAdded = new EventEmitter<GroupingSelection>();
  @Output() onUpdated = new EventEmitter<GroupingSelection>();
  // tslint:enable:no-output-on-prefix

  @Input('isSupplyData')
  set supplyData(value: boolean) {
    this.isSupplyData = value;
  }

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private masterDataService: MasterDataService,
    private route: ActivatedRoute
  ) {
    super(injector);
    this.auctionClusterId = +this.route.snapshot.params['id'];
  }

  ngOnInit() {
    this.model = new GroupingSelection();
  }

  open(properties: Array<GroupingSelection>, property: GroupingSelection, productProperties: Array<ProductProperty>, productPropertyTypes: Array<ProductPropertyType>, masterDataList: Array<MasterData>) { // tslint:disable-line:max-line-length

    this.allItems = properties;
    this.productProperties = productProperties;
    this.masterDatas = masterDataList;
    this.productPropertyTypes = productPropertyTypes;

    this.translateProductProperties();

    this.masterDatas.forEach(masterData => {
      masterData.masterDataNameText = this.languageService.getTranslatableText(masterData.name);
    });

    if (property !== null) {
      this.isEditMode = true;
      this.model = property;
      this.filterMasterDataFields();
      this.isOpened = true;
    } else {
      this.model = new GroupingSelection();
      this.isEditMode = false;
      this.isOpened = true;
    }
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  translateProductProperties() {
    this.productProperties.forEach(productProperty => {
      productProperty.productPropertyNameText = this.languageService.getTranslatableText(productProperty.name);
    });
  }

  save() {
    this.model.orderNumber = this.allItems.length > 0 ? this.allItems[this.allItems.length - 1].orderNumber + 1 : 1;
    if (this.isEditMode) {
      this.onUpdated.emit(this.model);
    } else {
      this.onAdded.emit(this.model);
    }

    this.model = new GroupingSelection();
    this.detailsForm.reset();
    this.close(true);
    this.errorMessage = null;
  }

  filterMasterDataFields() {
    const prop = this.productProperties.find(pp => pp.productPropertyId === this.model.productPropertyId);
    if (prop && prop.propertyTypeId === ProductPropertyTypes.MASTER_DATA_VALUE) {
      this.masterDataService.getMasterDataList(prop.masterDataListId, this.auctionClusterId).subscribe(mdl => {
        this.masterDataFields = mdl.fields;
        this.isMasterData = true;
      });
    } else {
      this.isMasterData = false;
    }
  }
}
