<div class="user-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup
  #addUser
  [width]="600"
  [showTitle]="true"
  title="{{ modalTitle }}"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [rtlEnabled]="rtlEnabled"
  [(visible)]="isOpened"
  (onShown)="onFieldDataChanged($event)">
  <dx-scroll-view width="100%" height="100%">
        <dx-form
            #userForm
            id="userForm"
            [showValidationSummary]="false"
            (onFieldDataChanged)="onFieldDataChanged($event)"
            colCount="auto"
            [(formData)]="model"
            [minColWidth]="500">
            <dxi-item 
            itemType="tabbed">
                <dxi-tab title="{{ 'USER.GENERAL' | translate }}">
                  
                  <dxi-item itemType="group">
                    <dxi-item dataField="email">
                      <dxo-label text="{{ 'USER.EMAIL' | translate }}"></dxo-label>
                      <dxi-validation-rule type="required" message="{{ 'USER.EMAIL_VALIDATION' | translate }}"></dxi-validation-rule>
                      <dxi-validation-rule *ngIf="configService.config.validateEmail" type="email" message="{{ 'USER.EMAIL_VALIDATION' | translate }}"></dxi-validation-rule>
                    </dxi-item>
                    <dxi-item dataField="firstname">
                      <dxo-label text="{{ 'USER.FIRST_NAME' | translate }}"></dxo-label>
                      <dxi-validation-rule type="required" message="{{ 'USER.FIRST_NAME_VALIDATION' | translate }}"></dxi-validation-rule>
                    </dxi-item>
                    <dxi-item dataField="lastname">
                      <dxo-label text="{{ 'USER.LAST_NAME' | translate }}"></dxo-label>
                      <dxi-validation-rule type="required" message="{{ 'USER.LAST_NAME_VALIDATION' | translate }}"></dxi-validation-rule>
                    </dxi-item>
                    <dxi-item dataField="telNumber">
                      <dxo-label text="{{ 'USER.TEL_NUMBER' | translate }}"></dxo-label>
                    </dxi-item>
                    <dxi-item dataField="languageId"
                              editorType="dxSelectBox"
                              [editorOptions]="{ items: languages, valueExpr:'languageId', displayExpr:'name'}">
                      <dxo-label text="{{ 'USER.PREFERRED_LANGUAGE' | translate }}"></dxo-label>
                    </dxi-item>
                    <dxi-item dataField="isSystemUser" editorType="dxCheckBox" *ngIf="isSystemUser">
                      <dxo-label text="{{ 'USER.SYSTEM_USER' | translate }}"></dxo-label>
                    </dxi-item>
                  </dxi-item>

                  <dxi-item itemType="group"  caption="{{ 'USER.USER_LEVEL' | translate }}">
                    <dxi-item dataField="isPlatformUser" [template]="'platformUserTemplate'">
                      <dxo-label text="{{ 'USER.PLATFORM_USER' | translate }}"></dxo-label>
                    </dxi-item>
                    <dxi-item dataField="isAuctionClusterUser" [template]="'auctionClusterTemplate'">
                      <dxo-label text="{{ 'USER.AUCTION_CLUSTER_USER' | translate }}"></dxo-label>
                    </dxi-item>
                    <dxi-item dataField="isBuyerUser" [template]="'buyerClusterTemplate'">
                      <dxo-label text="{{ 'USER.BUYER_USER' | translate }}"></dxo-label>
                    </dxi-item>
                    <dxi-item dataField="isSupplierUser" [template]="'supplierUserTemplate'">
                      <dxo-label text="{{ 'USER.SUPPLIER_USER' | translate }}"></dxo-label>
                    </dxi-item>
                  </dxi-item>
                </dxi-tab>
                <dxi-tab title="{{ 'USER.PLATFORM' | translate }}" [disabled]="!isPlatformUser">
                  <dxi-item 
                        dataField="platformRoleId"
                        editorType="dxSelectBox"
                        [editorOptions]="{ 
                          items: platformRoles, 
                          valueExpr:'platformRoleId',  
                          displayExpr: translateSelectBoxes
                        }">
                        <dxi-validation-rule type="required" message="{{ 'USER.PLATFORM_ROLE' | translate }}"></dxi-validation-rule>
                      <dxo-label text="{{ 'USER.PLATFORM_ROLE' | translate }}"></dxo-label>
                    </dxi-item>
                </dxi-tab>
                <dxi-tab title="{{ 'USER.AUCTION_CLUSTER' | translate }}" [disabled]="!isAuctionClusterUser">
                  <div *dxTemplate>
                  <dx-data-grid
                    [rtlEnabled]="rtlEnabled"
                    [(dataSource)]="model.auctionClusterRoles"
                    [showBorders]="true"
                    [allowColumnReordering]="true" [hoverStateEnabled]="true" [columnAutoWidth]="true"
                    (onInitialized)="initAuctionCluster($event)"
                    (onEditorPreparing)="onEditorPreparing($event)">
                    <dxo-selection mode="single"></dxo-selection>
                    <dxo-editing
                      mode="cell"
                      [allowUpdating]="true"
                      [allowAdding]="true"
                      [allowDeleting]="true"
                      [useIcons]="true">  
                    </dxo-editing>
                    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]" [showNavigationButtons]="true"></dxo-pager>
                      <dxi-column
                          dataField="auctionClusterId"
                          caption="{{ 'USER.AUCTION_CLUSTER' | translate }}">
                          <dxo-lookup
                            [dataSource]="auctionClusters"
                            displayExpr="name"
                            valueExpr="auctionClusterId">
                        </dxo-lookup>
                      </dxi-column>
                      <dxi-column
                          dataField="auctionId"
                          caption="{{ 'USER.AUCTION' | translate }}">
                          <dxo-lookup
                            [dataSource]="auctions"
                            displayExpr="name"
                            valueExpr="auctionId">
                        </dxo-lookup>
                      </dxi-column>
                      <dxi-column
                          dataField="auctionClusterRoleId"
                          caption="{{ 'USER.ROLE' | translate }}">
                          <dxo-lookup
                            [dataSource]="auctionClusterRoles"
                            [displayExpr]="translateSelectBoxes"
                            valueExpr="auctionClusterRoleId">
                        </dxo-lookup>
                      </dxi-column>
                    </dx-data-grid>
                    </div>
                </dxi-tab>
                <dxi-tab title="{{ 'USER.BUYER' | translate }}" [disabled]="!isBuyerUser">
                  <dx-data-grid
                    [rtlEnabled]="rtlEnabled"
                    [(dataSource)]="model.buyerRoles"
                    [showBorders]="true"
                    [allowColumnReordering]="true" [hoverStateEnabled]="true" [columnAutoWidth]="true"
                    (onInitialized)="initBuyer($event)"
                    (onInitNewRow)="onInitNewRow($event)"
                    (onEditorPreparing)="onEditorPreparing($event)">
                    <dxo-selection mode="single"></dxo-selection>
                    <dxo-editing
                      mode="cell"
                      [allowUpdating]="true"
                      [allowAdding]="true"
                      [allowDeleting]="true"
                      [useIcons]="true">
                    </dxo-editing>
                    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]" [showNavigationButtons]="true"></dxo-pager>
                      <dxi-column
                          dataField="buyerId"
                          caption="{{ 'USER.BUYER_NAME' | translate }}">
                          <dxo-lookup
                            [dataSource]="buyersPaginated"
                            displayExpr="name"
                            valueExpr="buyerId">
                        </dxo-lookup>
                      </dxi-column>
                      <dxi-column
                          dataField="buyerRoleId"
                          caption="{{ 'USER.ROLE' | translate }}">
                          <dxo-lookup
                            [dataSource]="buyerRoles"
                            [displayExpr]="translateSelectBoxes"
                            valueExpr="buyerRoleId">
                        </dxo-lookup>
                      </dxi-column>
                    </dx-data-grid>
                </dxi-tab>
                <dxi-tab title="{{ 'USER.SUPPLIER' | translate }}" [disabled]="!isSupplierUser">
                  <dx-data-grid
                    [rtlEnabled]="rtlEnabled"
                    [(dataSource)]="model.supplierRoles"
                    [showBorders]="true"
                    [allowColumnReordering]="true" [hoverStateEnabled]="true" [columnAutoWidth]="true"
                    (onInitialized)="initSupplier($event)"
                    (onEditorPreparing)="onEditorPreparing($event)">
                    <dxo-selection mode="single"></dxo-selection>
                    <dxo-editing
                      mode="cell"
                      [allowUpdating]="true"
                      [allowAdding]="true"
                      [allowDeleting]="true"
                      [useIcons]="true">
                    </dxo-editing>
                    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]" [showNavigationButtons]="true"></dxo-pager>
                      <dxi-column
                          dataField="supplierId"
                          caption="{{ 'USER.SUPPLIER_NAME' | translate }}">
                          <dxo-lookup
                            [dataSource]="suppliersPaginated"
                            displayExpr="name"
                            valueExpr="supplierId">
                        </dxo-lookup>
                      </dxi-column>
                      <dxi-column
                          dataField="supplierRoleId"
                          caption="{{ 'USER.ROLE' | translate }}">
                          <dxo-lookup
                            [dataSource]="supplierRoles"
                            [displayExpr]="translateSelectBoxes"
                            valueExpr="supplierRoleId">
                        </dxo-lookup>
                      </dxi-column>
                    </dx-data-grid>
                </dxi-tab>
            </dxi-item>


            <div *dxTemplate="let data of 'statusTemplate'">
                <div *ngIf="data === null"; else elseBlock>
                    <span>(All)</span>
                </div>
                <div dx-template #elseBlock>
                    <span class="middle">{{data.name}}</span>
                </div>
            </div>

            <div *dxTemplate="let data of 'platformRoleItem'">
              <div>{{ getTranslation(data.name) }}</div>
            </div>

            <div *dxTemplate="let data of 'platformUserTemplate'">
              <dx-check-box [(value)]="isPlatformUser"></dx-check-box>
            </div>

            <div *dxTemplate="let data of 'auctionClusterTemplate'">
              <dx-check-box [(value)]="isAuctionClusterUser"></dx-check-box>
            </div>

            <div *dxTemplate="let data of 'buyerClusterTemplate'">
              <dx-check-box [(value)]="isBuyerUser"></dx-check-box>
            </div>

            <div *dxTemplate="let data of 'supplierUserTemplate'">
              <dx-check-box 
                    [(value)]="isSupplierUser"
                    [rtlEnabled]="rtlEnabled"
                    ></dx-check-box>
            </div>
            <!-- Templates -->
        </dx-form>

          <button type="submit" class="btn btn-primary" (click)="onSubmitAccountInfo();" name="btnUserSubmitAccountInfo" disabled="disabled">
            {{ 'USER.SEND_ACC_INFO' | translate }}
          </button>
          <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnUserSubmit" disabled="disabled">
            {{ 'SAVE' | translate }}
          </button>
          <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'CANCEL' | translate }}</button>
        </dx-scroll-view>
</dx-popup>
</div>
<save-confirmation-component #saveConfirmation title="{{ 'LEAVE_PAGE' | translate }}" buttonCloseText="OK" cancellable="true" (close)="onCancel();"></save-confirmation-component>
