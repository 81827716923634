import { Injectable } from '@angular/core';
import { empty, Observable } from 'rxjs';
import { BuyerUser } from '../models/buyer-user';

import { ApplicationSettings } from '../../../shared/models/application-settings';
import { WebApiService } from '../../../shared/services/web-api.service';

class UserFilter {
  showActive = true;
  showInactive = true;
  showBlocked = true;
  showPending = true;
}

@Injectable()
export class BuyerUserService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'buyerlevel';
  }

  private appendToUrl(url: string, field: string, value: any): string {

    if (url) {
      url += `&${field}=${value}`;
    } else {
      url = `?${field}=${value}`;
    }
    return url;
  }

  getUsersFiltered(buyerId: number, userFilter: UserFilter): Observable<Array<BuyerUser>> {

    let url: string;

    if (!userFilter) {
      return empty();
    }

    // build url on applied filter:
    if (userFilter.showActive !== null) {
      url = this.appendToUrl(url, 'ShowActive', userFilter.showActive);
    }
    if (userFilter.showInactive !== null) {
      url = this.appendToUrl(url, 'ShowInactive', userFilter.showInactive);
    }
    if (userFilter.showBlocked !== null) {
      url = this.appendToUrl(url, 'ShowBlocked', userFilter.showBlocked);
    }
    if (userFilter.showPending !== null) {
      url = this.appendToUrl(url, 'ShowPending', userFilter.showPending);
    }

    return this.webApiService.getList(this.apiPath + '/' + buyerId + '/user/filter' + url);
  }

  getUser(buyerId: number, userId: number): Observable<BuyerUser> {
    return this.webApiService.get(this.apiPath + '/' + buyerId + '/user', userId);
  }

  // save(auctionClusterId: number, user: AuctionClusterUser): Observable<any> {
  //   return this.webApiService.save<AuctionClusterUser>(this.apiPath + '/' + auctionClusterId + '/user', user);
  // }

  edit(buyerId: number, user: BuyerUser) {
    return this.webApiService.editSingle<BuyerUser>(this.apiPath + '/' + buyerId + '/user', user);
  }

  // enableDisable(auctionClusterId: number, user: AuctionClusterUser): Observable<AuctionClusterUser> {
  //   if (user.isActive) {
  //     return this.webApiService.enableDisable(this.apiPath + '/' + auctionClusterId + '/user/disable', user.userId);
  //   } else {
  //     return this.webApiService.enableDisable(this.apiPath + '/' + auctionClusterId + '/user/enable', user.userId);
  //   }
  // }

  delete(buyerId: number, userId: number): Observable<any> {
    return this.webApiService.delete(this.apiPath + '/' + buyerId + '/user', userId);
  }

  verifyEmail(buyerId: number, email: string): Observable<any> {
    return this.webApiService.verifyEmail(this.apiPath + '/' + buyerId + '/user/verify', email);
  }
}
