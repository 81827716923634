import { Component, OnInit, Injector, ViewChild, OnDestroy } from '@angular/core';
import { Subscription, forkJoin } from 'rxjs';
import notify from 'devextreme/ui/notify';

// components
import { AuctionClusterUserComponent } from './auction-cluster-user.component';
import { ConfirmationComponent } from '../../shared/components/confirmation/confirmation.component';
import { AuthenticationComponent } from '../../shared/components/authentication/authentication.component';
import { FullListComponent } from '../../shared/components/full-list/full-list.component';

// models
import { AuctionClusterUser } from '../shared/models/auction-cluster-user';

import { Auction } from '../../auction/shared/models/auction';

import { AuctionCluster } from '../../platform/shared/models/auction-cluster';

import { AuctionClusterRole } from '../../shared/models/auction-cluster-role';
import { AuctionClusterReportingRole } from '../../shared/models/auction-cluster-reporting-role';
import { Buyer } from '../../shared/models/buyer';
import { BuyerRole } from '../../shared/models/buyer-role';
import { Language } from '../../shared/models/language';
import { Supplier } from '../../shared/models/supplier';
import { SupplierRole } from '../../shared/models/supplier-role';
import { User } from '../../shared/models/user';

// services
import { AuctionClusterAuctionService } from '../shared/services/auction-cluster-auction.service';
import { AuctionClusterBuyerService } from '../shared/services/auction-cluster-buyer.service';
import { AuctionClusterLevelRoleService } from '../shared/services/auction-cluster-level-role.service';
import { AuctionClusterReportingRoleService } from '../shared/services/auction-cluster-reporting-role.service';
import { AuctionClusterService } from '../shared/services/auction-cluster.service';
import { AuctionClusterSupplierService } from '../shared/services/auction-cluster.supplier.service';
import { AuctionClusterUserService } from '../shared/services/auction-cluster-user.service';

import { ImportSchema, ImportField } from '../../shared/import/import-schema';
import { BuyerRoleService } from '../../shared/services/buyer-role.service';
import { ConfigService } from '../../shared/services/config.service';
import { LanguageService } from '../../shared/services/language.service';
import { SupplierRoleService } from '../../shared/services/supplier-role.service';
import { UserService } from '../../shared/services/user.service';

class UserFilter {
  showAuction = false;
  showBuyer = false;
  showSupplier = false;
  showActive = true;
  showInactive = true;
  showBlocked = true;
  showPending = true;
  auctionId: number;
  buyerId: number;
  supplierId: number;
}


@Component({
  selector: 'auction-cluster-users-component',
  templateUrl: 'auction-cluster-users.component.html',
  styleUrls: ['./auction-cluster-users.component.scss']
})
export class AuctionClusterUsersComponent extends FullListComponent<AuctionClusterUser, AuctionClusterUserComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: AuctionClusterUserComponent;

  auctions: Array<Auction> = [];
  auctionCluster: AuctionCluster;
  auctionClusterRoles: Array<AuctionClusterRole> = [];
  auctionClusterReportingRoles: Array<AuctionClusterReportingRole> = [];
  languages: Array<Language> = [];
  userToEnableDisable: AuctionClusterUser = new AuctionClusterUser();
  userToBlockUnblock: AuctionClusterUser = new AuctionClusterUser();
  user: User = new User();
  initialized = false;

  buyers: Array<Buyer> = [];
  buyerRoles: Array<BuyerRole> = [];

  suppliers: Array<Supplier> = [];
  supplierRoles: Array<SupplierRole> = [];

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  userFilter: UserFilter;

  buyersPaginated: any = {};
  suppliersPaginated: any = {};

  @ViewChild(AuthenticationComponent) authComponent: AuthenticationComponent;
  @ViewChild('confirmationAuctionClusterLicenses') confirmationAuctionClusterLicenses: ConfirmationComponent;
  @ViewChild('disable') disable: ConfirmationComponent;
  

  constructor(
    protected injector: Injector,
    private auctionService: AuctionClusterAuctionService,
    private dataService: AuctionClusterUserService,
    private languageService: LanguageService,
    private auctionClusterRoleService: AuctionClusterLevelRoleService,
    private auctionClusterReportingRoleService: AuctionClusterReportingRoleService,
    private supplierRoleService: SupplierRoleService,
    private buyerRoleService: BuyerRoleService,
    private buyerService: AuctionClusterBuyerService,
    private supplierService: AuctionClusterSupplierService,
    private auctionClusterService: AuctionClusterService,
    private configService: ConfigService,
    private userService: UserService
  ) {
    super(injector, AuctionClusterUser);
    this.title.set('PLATFORM.USERS');
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.userFilter = new UserFilter();

    this.route.queryParams.subscribe(params => {
      if (params['auctionCluster']) {
        this.userFilter.showAuction = true;
        this.userFilter.showBuyer = false;
        this.userFilter.showSupplier = false;
        this.userFilter.auctionId = +params['auction'];
      } else if (params['buyers']) {
        this.userFilter.showAuction = false;
        this.userFilter.showSupplier = false;
        this.userFilter.showBuyer = true;
        this.userFilter.buyerId = +params['buyers'];
      } else if (params['suppliers']) {
        this.userFilter.showSupplier = true;
        this.userFilter.showAuction = false;
        this.userFilter.showBuyer = false;
        this.userFilter.supplierId = +params['suppliers'];
      } else {
        this.userFilter.showActive = true;
        this.userFilter.showAuction = true;
        this.userFilter.showBlocked = true;
        this.userFilter.showBuyer = true;
        this.userFilter.showInactive = true;
        this.userFilter.showPending = true;
        this.userFilter.showSupplier = true;
      }
      this.initialized = true;

      this.getData();

      this.setTranslations('USERS');

    });

  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  // tslint:disable:no-magic-numbers
  getData(filter?: boolean) {
    if(!this.initialized) return;

    if (this.userFilter) {
      this.spinner.show();
    }

    forkJoin(
      this.dataService.getUsersFiltered(this.id, this.userFilter),
      this.auctionClusterRoleService.getAuctionClusterRoles(this.id),
      this.auctionService.getAuctions(this.id),
      this.languageService.getAuctionClusterLanguages(this.id),
      this.buyerService.getBuyers(this.id),
      this.supplierService.getSuppliersSimple(this.id),
      this.auctionClusterService.getAuctionCluster(this.id),
      this.auctionClusterReportingRoleService.getAuctionClusterReportingRoles(this.id),
      this.translate.get('USERS.FILTER_SUCCESS'),
      this.userService.getCurrentUser()
    ).subscribe((result: Array<any>) => {
      this.items = result[0];
      this.auctionClusterRoles = result[1];
      this.auctions = result[2];
      this.languages = result[3];
      this.buyers = result[4];
      this.suppliers = result[5];
      this.auctionCluster = result[6];
      this.auctionClusterReportingRoles = result[7];
      const successMessage = result[8];
      this.user = result[9];

      this.buyersPaginated = {
        paginate: true,
        pageSize: 20,
        store: this.buyers,
        sort: 'name'
      };

      this.suppliersPaginated = {
        paginate: true,
        pageSize: 20,
        store: this.suppliers,
        sort: 'name'
      };

      this.translateUserRoles();
      this.spinner.hide();
      
      if (filter) {
        notify(successMessage, 'success', 5000);
      }
    },
      error => {
        this.spinner.hide();
        notify(error.message, 'error', 5000);
      });
  }
  // tslint:disable:no-magic-numbers

  edit = (e: any) => {    
    const userId = e.row !== undefined ? e.row.data.userId : e.data.userId;
    this.detailsComponent.modalTitle = this.translations.EDIT;
    this.detailsComponent.open(this.items, userId, this.auctionClusterRoles, this.auctions,
      this.languages, this.buyers, this.suppliers, this.auctionCluster, this.auctionClusterReportingRoles, this.user.isSystemUser);
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.ADD_NEW;
    this.detailsComponent.open(this.items, null, this.auctionClusterRoles, this.auctions,
      this.languages, this.buyers, this.suppliers, this.auctionCluster, this.auctionClusterReportingRoles, this.user.isSystemUser);
  }

  enableDisableUser = (e: any) => {
    const user = e.row.data;
    this.userToEnableDisable = user;
    this.dataService.enableDisable(this.id, this.userToEnableDisable, false).subscribe(() => {
      this.getData();
    },
      error => {
        if (error.error && error.error.length > 0 && error.error[0].ErrorMessage === 'ALL_AUCTIONCLUSTER_LICENCES_USED') {
          this.confirmationAuctionClusterLicenses.opened = true;
        }
        else {
          notify(error.message, 'error', 5000);
        }
      }
    );
  }

  blockUnblockUser = (e: any) => {
    const user: AuctionClusterUser = e.row.data;
    this.userToBlockUnblock = user;
    this.dataService.blockUnblock(this.id, this.userToBlockUnblock).subscribe(() => {
      this.getData();
    },
      error => {
        notify(error.message, 'error', 5000);
      }
    );
  }

  sendUserLicenseExceededMail() {
    this.dataService.enableDisable(this.id, this.userToEnableDisable, true).subscribe(() => {
      this.getData();
    },
      error => {
        notify(error.message, 'error', 5000);
      }
    );
  }

  translateUserRoles() {
    this.items.forEach((user) => {
      user.auctionClusterRoles.forEach((role) => {        
        const roleId = this.auctionClusterRoles.find(r => r.auctionClusterRoleId === role.auctionClusterRoleId);
        if (user.auctionClusterRolesText) {
          user.auctionClusterRolesText += ', ' + this.languageService.getTranslatableText(roleId.name);
        } else {
          user.auctionClusterRolesText = this.languageService.getTranslatableText(roleId.name);
        }
      });
      
      user.buyerRoles.forEach((role) => {
        const roleId = role.buyerRole;
        if (user.auctionClusterRolesText) {
          user.auctionClusterRolesText += ', ' + this.languageService.getTranslatableText(roleId.name);
        } else {
          user.auctionClusterRolesText = this.languageService.getTranslatableText(roleId.name);
        }
      });
      
      user.supplierRoles.forEach((role) => {
        const roleId = role.supplierRole;
        if (user.auctionClusterRolesText) {
          user.auctionClusterRolesText += ', ' + this.languageService.getTranslatableText(roleId.name);
        } else {
          user.auctionClusterRolesText = this.languageService.getTranslatableText(roleId.name);
        }
      });   
    });
  }

  deleteSelected() {
    this.spinner.show();
    this.dataService.delete(this.id, this.itemIdToDelete)
      .subscribe((users: Array<AuctionClusterUser>) => {
        this.getData();
        this.spinner.hide();
      },
      error => {
        notify(error.message, 'error', 5000);
        this.spinner.hide();
      });
  }

  changeUserPassword = (e: any) => {
    this.authComponent.open(e.row.data.userId, this.id, false, false);
  }

  createDataSchema() {
    const schema = new ImportSchema();

    const isActive = new ImportField('isActive', this.translations.ACTIVE);
    isActive.required = true;

    const isBlocked = new ImportField('isBlocked', this.translations.BLOCKED);
    isBlocked.required = true;

    const email = new ImportField('email', this.translations.EMAIL);
    email.required = true;

    const firstname = new ImportField('firstname', this.translations.FIRST_NAME);
    firstname.required = true;

    const lastname = new ImportField('lastname', this.translations.LAST_NAME);
    lastname.required = true;

    const auctionClusterRolesText = new ImportField('auctionClusterRolesText', this.translations.AUCTION_CLUSTER_ROLES);
    auctionClusterRolesText.required = true;

    schema.fields.push(isActive, email, firstname, lastname, auctionClusterRolesText
    );

    this.schema = schema;
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.userId;
    this.confirmation.opened = true;
  }

  calculateDisabledDisplayValue = (e: any) => {
    if (e.row.data.isActive) {
      return true;
    } else {
      return false;
    }
  }

  calculateEnabledDisplayValue = (e: any) => {
    if (!e.row.data.isActive) {
      return true;
    } else {
      return false;
    }
   }

   calculateBlockedDisplayValue = (e: any) => {
    if (e.row.data.isBlocked) {
      return false;
    } else {
      return true;
    }
  }

  calculateUnblockedDisplayValue = (e: any) => {
    if (!e.row.data.isBlocked) {
      return false;
    } else {
      return true;
    }
   }
}
