<div class="main-container content-component" [class.efice]="baseUrl && baseUrl.includes('efice.com')">
  <div class="content-container">
    <div class="content-area">
      <login-header-component *ngIf="!authenticationService.isLoggedIn()"></login-header-component>
      <header-component *ngIf="authenticationService.isLoggedIn()"></header-component>
      <div class="contents">
        <alert-component></alert-component>
        <router-outlet></router-outlet>
      </div>
    </div>
    <nav class="sidenav" [ngClass]="{'collapsed': !expanded && !pin}">
      <section class="sidenav-content" *ngIf="expanded || pin">
        <div class="collapsed-btn" (click)="navCollapse()">
          <clr-icon shape="arrow left" size="40" style="padding-left: 16px;padding-bottom: 16px"></clr-icon>
        </div>

        <a href="./" class="logo-wrapper">
          <img *ngIf="!sidebarLogo" src="assets/images/Aucxis.png" class="aucxis-logo" />
          <img *ngIf="!sidebarLogo" src="assets/images/efice.png" class="efice-logo" style="display: none;" />
          <img *ngIf="sidebarLogo" [src]="sidebarLogo" />
        </a>

        <a routerLinkActive="active" class="nav-link" (click)="login()">
          {{ 'CONTENT.LOGIN' | translate }}
        </a>

        <a [routerLink]="['/core/register']" routerLinkActive="active" class="nav-link" (click)="onRouterLinkClick()">
          {{ 'CONTENT.REGISTER' | translate }}
        </a>

        <a [routerLink]="['/core/supplyforecast']" routerLinkActive="active" class="nav-link public-supply-forecast-urk">{{ 'CONTENT.SUPPLY_FORECAST' | translate }}</a>

        <ng-container *ngFor="let item of menuItems">
          <collapsable-section *ngIf="item.menuType === 3" [caption]="getTranslation(item.menuItemName)" sectionId="item.menuItemName" (clicked)="onRouterLinkClick('item.menuItemName')">
            <ul class="nav-list">
              <ng-container *ngFor="let subItem of subMenuItems">
                <ng-container *ngIf="item.externalAppId === subItem.submenuId">
                  <a *ngIf="!subItem.openInNewTab"
                     [routerLink]="['/core/menu-item/' + subItem.externalAppId]"
                     routerLinkActive="active"
                     class="nav-link">{{ getTranslation(subItem.menuItemName) }}</a>

                  <a *ngIf="subItem.openInNewTab"
                     [href]="subItem.applicationUrl"
                     [target]="'_blank'"
                     routerLinkActive="active"
                     class="nav-link">{{ getTranslation(subItem.menuItemName) }}</a>
                </ng-container>
              </ng-container>
            </ul>
          </collapsable-section>

          <ng-container *ngIf="item.menuType === 1">
            <a *ngIf="!item.openInNewTab"
               [routerLink]="['/core/menu-item/' + item.externalAppId]"
               routerLinkActive="active"
               class="nav-link">{{ getTranslation(item.menuItemName) }}</a>

            <a *ngIf="item.openInNewTab"
               [href]="item.applicationUrl"
               [target]="'_blank'"
               routerLinkActive="active"
               class="nav-link">{{ getTranslation(item.menuItemName) }}</a>
          </ng-container>

          <ng-container *ngIf="item.menuType === 2">
            <a [routerLink]="['/core/menu-item/' + item.externalAppId]"
               routerLinkActive="active"
               class="nav-link">{{ getTranslation(item.menuItemName) }}</a>
          </ng-container>
        </ng-container>

        <!--<collapsable-section sectionId="public" [caption]="'CONTENT.PUBLIC_MENU_ITEM' | translate" (clicked)="onRouterLinkClick('public')">
    <ul class="nav-list">
      <li><a class="nav-link">{{'CONTENT.PUBLIC_MENU_SUBITEM1' | translate }}</a></li>
      <li><a class="nav-link">{{'CONTENT.PUBLIC_MENU_SUBITEM2' | translate }}</a></li>
    </ul>
  </collapsable-section>-->
        <!--<a [routerLink]="['/core/reports']" routerLinkActive="active" class="nav-link" (click)="onRouterLinkClick()">
    {{ 'CONTENT.REPORTING' | translate }}
  </a>-->

      </section>
      <a *ngIf="expanded || pin" href="http://www.aucxis.com/" target="_blank" class="bottom-logo-wrapper">
        <img src="assets/images/Aucxis_w.png" />
      </a>

      <section class="sidenav-content" *ngIf="!expanded && !pin">
        <a [routerLink]="['/core/home']" class="small-logo-wrapper">
          <!--<img src="assets/images/europool_logo_small.png" />-->
        </a>
        <div class="expand-btn" (click)="navExpand()">
          <clr-icon shape="arrow right" size="42"></clr-icon>
        </div>
        <a href="http://www.aucxis.com/" target="_blank" class="bottom-logo-wrapper">
          <img src="assets/images/Aucxis_w_vert.png" />
        </a>
      </section>
    </nav>
  </div>
</div>
<spinner-component></spinner-component>
