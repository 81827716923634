<div class="item-list-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <div class="grid-buttons">
    <dx-button hint="{{ 'ADD' | translate }}" icon="add" (onClick)="add()"></dx-button>
  </div>
  <dx-data-grid #lookupTableFieldsGrid id="printSection" [rtlEnabled]="rtlEnabled" [dataSource]="items"
                [showBorders]="true"
                [allowColumnReordering]="true"
                [hoverStateEnabled]="true"
                [columnAutoWidth]="true"
                [rowAlternationEnabled]="true"
                (onCellClick)="openEditClick($event)">
    <dxo-selection mode="single"></dxo-selection>
    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]" 
    [showNavigationButtons]="true" [showInfo]="true" infoText="{2} {{ 'LOOKUP_TABLE.FIELD.PAGINATION_TEXT_PLACEHOLDER' | translate | slice:2 }}">
    </dxo-pager>
    <dxi-column dataField="masterDataListId" caption="{{ 'LOOKUP_TABLE.FIELD_NAME' | translate }}" dataType="string" cellTemplate="fieldNameCell">
    </dxi-column>
    <dxi-column dataField="propertyTypeId" caption="{{ 'LOOKUP_TABLE.FIELD_TYPE' | translate }}" dataType="string" cellTemplate="fieldTypeCell">
    </dxi-column>
    <dxi-column dataField="isResult" caption="{{ 'LOOKUP_TABLE.RESULT_COLUMN' | translate }}" dataType="string" cellTemplate="resultColumnCell">
    </dxi-column>
    <dxi-column type="buttons" class="btns-column">
      <dxi-button [visible]="isButtonVisible" hint="{{ 'EDIT' | translate }}" icon="edit" [onClick]="edit"></dxi-button>
      <dxi-button [visible]="isButtonVisible" hint="{{ 'DELETE' | translate }}" icon="trash" [onClick]="delete"></dxi-button>
    </dxi-column>

    <div *dxTemplate="let data of 'fieldNameCell'">
      <span *ngIf="data.value">{{ getMasterDataListName(data.value) }}</span>
      <span *ngIf="!data.value">{{ 'LOOKUP_TABLE.FIELD.RESULT_COLUMN' | translate }}</span>
    </div>

    <div *dxTemplate="let data of 'fieldTypeCell'">
      <span *ngIf="!data.value">{{ 'LOOKUP_TABLE.FIELD.MASTER_DATA' | translate }}</span>
      <span *ngIf="data.value">{{ getPropertyTypeName(data.value) }}</span>
    </div>

    <div *dxTemplate="let data of 'resultColumnCell'">
      <i *ngIf="data.value" class="dx-icon-check"></i>
    </div>

</dx-data-grid>
</div>
<lookup-table-field-component (onAdded)="onLookupTableFieldAdded($event)" (onUpdated)="onLookupTableFieldEdited($event)"></lookup-table-field-component>
