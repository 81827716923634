import { Component, OnInit, Injector, OnDestroy, ViewChild } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { ProductPropertyStylingComponent } from './product-property-styling.component';

import notify from 'devextreme/ui/notify';

// services
import { ProductPropertyStylingService } from '../../shared/services/product-property-styling.service';
import { LanguageService } from '../../shared/services/language.service';

// models
import { ProductPropertyStyling } from '../../shared/models/product-property-styling';
import { ImportSchema, ImportField } from '../../shared/import/import-schema';

@Component({
  selector: 'product-property-stylings-component',
  templateUrl: 'product-property-stylings.component.html',
  styleUrls: ['./product-property-stylings.component.scss']
})
export class ProductPropertyStylingsComponent extends FullListComponent<ProductPropertyStyling, ProductPropertyStylingComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: ProductPropertyStylingComponent;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private productPropertyStylingService: ProductPropertyStylingService,
    private languageService: LanguageService
  ) {
    super(injector, ProductPropertyStyling);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.title.set('PRODUCT_PROPERTY_STYLING.TITLE');
    this.setTranslations('PRODUCT_PROPERTY_STYLING');
    this.getData();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getData() {
    this.spinner.show();

    forkJoin(
      this.productPropertyStylingService.getProductPropertyStylings()
    ).subscribe(result => {
      this.items = result[0];
      this.items.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      this.spinner.hide();
    }, error => {
      notify(error.message, 'error', 5000);
      this.spinner.hide();
    });
  }

  edit(itemId: number, event: Event) {
    event.stopPropagation();

    this.detailsComponent.modalTitle = this.translations.EDIT;
    this.detailsComponent.open(this.items, itemId);
  }

  editItem = (e: any) => {
    const productPropertyStylingId = e.row !== undefined ? e.row.data.productPropertyStylingId : e.data.productPropertyStylingId;
    this.detailsComponent.modalTitle = this.translations.EDIT;
    this.detailsComponent.open(this.items, productPropertyStylingId);
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.ADD_NEW;
    this.detailsComponent.open(this.items, null);
  }

  createDataSchema() {
    const schema = new ImportSchema();

    const name = new ImportField('name', this.translations.NAME);
    name.required = true;

    const fontSize = new ImportField('fontSize', this.translations.FONT_SIZE);
    fontSize.required = true;

    const backgroundColor = new ImportField('backgroundColor', this.translations.BACKGROUND_COLOR);
    backgroundColor.required = true;

    const borderColor = new ImportField('borderColor', this.translations.BORDER_COLOR);
    borderColor.required = true;

    const color = new ImportField('color', this.translations.COLOR);
    color.required = true;

    const font = new ImportField('font', this.translations.FONT);
    font.required = true;

    schema.fields.push(name, fontSize, backgroundColor, borderColor, color, font);

    this.schema = schema;
  }

  deleteSelected() {
    this.spinner.show();
    this.productPropertyStylingService.delete(this.itemIdToDelete).subscribe(() => {
      this.getData();
      this.spinner.hide();
    });

    this.confirmation.opened = false;
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.productPropertyStylingId;
    this.confirmation.opened = true;
  }
}
