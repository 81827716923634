import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AdditionalMenuItem } from '../models/additional-menu-item';
import { WebApiService } from './web-api.service';
import { ApplicationSettings } from '../models/application-settings';
import { BehaviorSubject } from 'rxjs';
import { ErrorService } from './error.service';

@Injectable()
export class AdditionalMenuItemsService {

  private apiPath: string;
  public publicMenuItems: BehaviorSubject<Array<AdditionalMenuItem>> = new BehaviorSubject([]);

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService, public errorService: ErrorService) {
    this.apiPath = this.appSettings.adminApi + 'menu-items';
    this.getPublicMenuItems().subscribe(result => {
      this.publicMenuItems.next(result);
    },
      error => {
        this.errorService.show(error);
      });
  }

  getMenuItems(): Observable<Array<AdditionalMenuItem>> {
    return this.webApiService.getList(this.apiPath);
  }

  getPublicMenuItems(): Observable<Array<AdditionalMenuItem>> {
    return this.webApiService.getList(this.apiPath + '/public');
  }

  getMenuItem(externalAppId: number): Observable<AdditionalMenuItem> {
    return this.webApiService.get(this.apiPath, externalAppId);
  }

  save(menuItem: AdditionalMenuItem): Observable<any> {
    return this.webApiService.save<AdditionalMenuItem>(this.apiPath, menuItem);
  }

  edit(menuItem: AdditionalMenuItem) {
    return this.webApiService.edit<AdditionalMenuItem>(this.apiPath, menuItem.externalAppId, menuItem);
  }

  delete(menuItemId: number): Observable<any> {
    return this.webApiService.delete(this.apiPath, menuItemId);
  }
}
