<div class="item-list-component">

  <div class="grid-buttons">
    <clr-tooltip>
      <clr-icon shape="add" clrTooltipTrigger size="16" (click)="add()"></clr-icon>
      <clr-tooltip-content class="tooltip-content" clrPosition="top-left" clrSize="sm">
        <span>{{ 'ADD' | translate }}</span>
      </clr-tooltip-content>
    </clr-tooltip>
  </div>

  <clr-datagrid id="printSection">
    <clr-dg-column>{{ 'BUYERS.SUB_BUYERS_NAME' | translate }}</clr-dg-column>
    <clr-dg-column>{{ 'BUYERS.SUB_BUYERS_NUMBER' | translate }}</clr-dg-column>
    <clr-dg-column>{{ 'BUYERS.OVERRULE' | translate }}</clr-dg-column>
    <clr-dg-column class="no-print tab-one-button-column">&nbsp;</clr-dg-column>

    <clr-dg-placeholder>{{'NO_DATA' | translate }}</clr-dg-placeholder>

    <clr-dg-row *clrDgItems="let subBuyer of items; let i = index" (click)="editSubBuyer(subBuyer, $event)">
      <clr-dg-cell>{{subBuyer.name | translateJson}}</clr-dg-cell>
      <clr-dg-cell>{{subBuyer.orderNumber}}</clr-dg-cell>
      <clr-dg-cell>
          <clr-icon shape="check" *ngIf="subBuyer.buyerSubBuyerOverrule"></clr-icon>
          <clr-icon shape="times" *ngIf="!subBuyer.buyerSubBuyerOverrule"></clr-icon></clr-dg-cell>
      <clr-dg-cell class="no-print one-button-cell">
        <clr-tooltip role="tooltip" aria-haspopup="true" [clrTooltipDirection]="'top-left'" [clrTooltipSize]="'xs'">
          <clr-icon class="delete-btn" shape="trash" size="16" (click)="delete(subBuyer, $event)"></clr-icon>
          <clr-tooltip-content class="tooltip-content">{{ 'DELETE' | translate }}</clr-tooltip-content>
        </clr-tooltip>
      </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer>
      <span class="pagination-fixed footer-pagination">
        <span *ngIf="items?.length > 0">{{ 'PRODUCT.PROPERTY.PAGINATION_TEXT' | translate:{firstItem: pagination.firstItem + 1, lastItem: pagination.lastItem + 1, totalItems: pagination.totalItems} }}</span>
        <span *ngIf="items?.length == 0">{{ 'PRODUCT.PROPERTY.PAGINATION_TEXT_PLACEHOLDER' | translate:{totalItems: pagination.totalItems} }}</span>
        <clr-dg-pagination #pagination [clrDgPageSize]="pageSize"></clr-dg-pagination>
      </span>
    </clr-dg-footer>
  </clr-datagrid>
</div>

<sub-buyer-component (onAdded)="onSubBuyerAdded($event)" (onUpdated)="onSubBuyerUpdated($event)"></sub-buyer-component>
