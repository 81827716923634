<div dir="{{rtlEnabled ? 'rtl' : 'ltr'}}" class="main-container content-component" [class.efice]="baseUrl && baseUrl.includes('efice.com')" [class.test]="baseUrl && baseUrl.includes('test')">
  <div class="content-container">
    <div class="content-area">
      <header-component></header-component>
      <div class="contents">
        <alert-component></alert-component>
        <router-outlet></router-outlet>
      </div>
    </div>
    <nav class="sidenav" [ngClass]="{'collapsed': !expanded && !pin}">
      <section class="sidenav-content" *ngIf="expanded || pin">
        <div class="collapsed-btn" (click)="navCollapse()">
          <clr-icon shape="arrow left" size="40" style="padding-left: 16px;padding-bottom: 16px"></clr-icon>
        </div>

        <a [routerLink]="['/auction/supplymonitor/'+ id]" class="logo-wrapper" (click)="onRouterLinkClick()">
          <img *ngIf="!sidebarLogo" src="assets/images/Aucxis.png" class="aucxis-logo" />
          <img *ngIf="!sidebarLogo" src="assets/images/efice.png" class="efice-logo" style="display: none;" />
          <img *ngIf="sidebarLogo" [src]="sidebarLogo" />
        </a>

        <collapsable-section *ngIf="tokenService.permissionMet('AuctionClusterPermissions.1', id)||tokenService.permissionMet('AuctionClusterPermissions.16', id)" sectionId="ac-users" [caption]="'AUCTION.USERS_ROLES_MANAGEMENT' | translate" (clicked)="onRouterLinkClick('ac-users')">
          <ul class="nav-list">
            <li *ngIf="tokenService.permissionMet('AuctionClusterPermissions.1', id)">
              <a [routerLink]="['/auction/users/' + id]" routerLinkActive="active" class="nav-link" (click)="onRouterLinkClick('ac-users')">
                {{ 'AUCTION.USERS' | translate }}
              </a>
            </li>
            <li *ngIf="tokenService.permissionMet('AuctionClusterPermissions.16', id)">
              <a [routerLink]="['/auction/roles/' + id]" routerLinkActive="active" class="nav-link" (click)="onRouterLinkClick('ac-users')">
                {{ 'AUCTION.ROLES' | translate }}
              </a>
            </li>
            <li *ngIf="tokenService.permissionMet('AuctionClusterPermissions.16', id)">
              <a [routerLink]="['/auction/reportingroles/' + id]" routerLinkActive="active" class="nav-link" (click)="onRouterLinkClick('ac-users')">
                {{ 'AUCTION.REPORTING_ROLES' | translate }}
              </a>
            </li>
          </ul>
        </collapsable-section>

        <a routerLinkActive="active" *ngIf="tokenService.permissionMet('AuctionClusterPermissions.8', id)" [routerLink]="['/auction/buyer/' + id]" class="nav-link" (click)="onRouterLinkClick()">
          {{ 'AUCTION.BUYER_MANAGEMENT' | translate }}
        </a>

        <a routerLinkActive="active" *ngIf="tokenService.permissionMet('AuctionClusterPermissions.9', id)" [routerLink]="['/auction/supplier/' + id]" class="nav-link" (click)="onRouterLinkClick()">
          {{ 'AUCTION.SUPPLIER_MANAGEMENT' | translate }}
        </a>

        <a routerLinkActive="active" *ngIf="tokenService.permissionMet('AuctionClusterPermissions.2', id)" [routerLink]="['/auction/auction/' + id]" class="nav-link" (click)="onRouterLinkClick()">
          {{ 'AUCTION.AUCTION_MANAGEMENT' | translate }}
        </a>

        <collapsable-section *ngIf="tokenService.permissionMet('AuctionClusterPermissions.5', id) || tokenService.permissionMet('AuctionClusterPermissions.17', id)" sectionId="ac-locations" [caption]="'AUCTION.LOCATION_AND_ZONE_MANAGEMENT' | translate" (clicked)="onRouterLinkClick('ac-locations')">
          <ul class="nav-list">
            <li *ngIf="tokenService.permissionMet('AuctionClusterPermissions.5', id)">
              <a [routerLink]="['/auction/locations/' + id]" routerLinkActive="active" class="nav-link" (click)="onRouterLinkClick('ac-locations')">
                {{ 'AUCTION.LOCATION_MANAGEMENT' | translate }}
              </a>
            </li>
            <li *ngIf="tokenService.permissionMet('AuctionClusterPermissions.17', id)" >
              <a [routerLink]="['/auction/zones/' + id]" routerLinkActive="active" class="nav-link" (click)="onRouterLinkClick('ac-locations')">
                {{ 'AUCTION.ZONE_MANAGEMENT' | translate }}
              </a>
            </li>
          </ul>
        </collapsable-section>

        <a routerLinkActive="active" *ngIf="tokenService.permissionMet('AuctionClusterPermissions.22', id)" [routerLink]="['/auction/gallery/' + id]" class="nav-link" (click)="onRouterLinkClick()">
          {{ 'AUCTION.GALLERY_MANAGEMENT' | translate }}
        </a>

        <a routerLinkActive="active"
           *ngIf="tokenService.permissionMet('AuctionClusterPermissions.3', id) || tokenService.permissionMet('AuctionPermissions.10', id) || tokenService.permissionMet('AuctionPermissions.3', id)"
           [routerLink]="['/auction/clocks/' + id]" class="nav-link" (click)="onRouterLinkClick()">
          {{ 'AUCTION.CLOCK_MANAGEMENT' | translate }}
        </a>

        <collapsable-section *ngIf="tokenService.permissionMet('AuctionClusterPermissions.2', id) || tokenService.permissionMet('AuctionClusterPermissions.6', id) || tokenService.permissionMet('AuctionClusterPermissions.7', id)" sectionId="ac-products" [caption]="'AUCTION.PRODUCTS_AND_MASTER_DATA' | translate" (clicked)="onRouterLinkClick('ac-products')">
          <ul class="nav-list">
            <li *ngIf="tokenService.permissionMet('AuctionClusterPermissions.2', id)">
              <a [routerLink]="['/auction/products/' + id]" routerLinkActive="active" class="nav-link" (click)="onRouterLinkClick('ac-products')">
                {{ 'AUCTION.PRODUCT_MANAGEMENT' | translate }}
              </a>
            </li>
            <li *ngIf="tokenService.permissionMet('AuctionClusterPermissions.6', id)">
              <a [routerLink]="['/auction/masterdata/' + id]" routerLinkActive="active" class="nav-link" (click)="onRouterLinkClick('ac-products')">
                {{ 'AUCTION.MASTER_DATA' | translate }}
              </a>
            </li>
            <li *ngIf="tokenService.permissionMet('AuctionClusterPermissions.7', id)">
              <a [routerLink]="['/auction/lookuptables/' + id]" routerLinkActive="active" class="nav-link" (click)="onRouterLinkClick('ac-products')">
                {{ 'AUCTION.LOOKUP_TABLES' | translate }}
              </a>
            </li>
            <li *ngIf="tokenService.permissionMet('AuctionClusterPermissions.2', id)">
              <a [routerLink]="['/auction/masks/' + id]" routerLinkActive="active" class="nav-link" (click)="onRouterLinkClick('ac-products')">
                {{ 'AUCTION.MASKS' | translate }}
              </a>
            </li>
            <li *ngIf="tokenService.permissionMet('AuctionClusterPermissions.2', id)">
              <a [routerLink]="['/auction/calculations/' + id]" routerLinkActive="active" class="nav-link" (click)="onRouterLinkClick('ac-products')">
                {{ 'AUCTION.CALCULATIONS' | translate }}
              </a>
            </li>
          </ul>
        </collapsable-section>

        <collapsable-section *ngIf="tokenService.permissionMet('AuctionClusterPermissions.4', id) || tokenService.permissionMet('AuctionPermissions.4', id) || tokenService.permissionMet('AuctionClusterPermissions.18', id) || tokenService.permissionMet('AuctionPermissions.18', id)" sectionId="ac-catalogs" [caption]="'AUCTION.CATALOG_MANAGEMENT' | translate" (clicked)="onRouterLinkClick('ac-catalogs')">
          <ul class="nav-list">
            <li *ngIf="tokenService.permissionMet('AuctionClusterPermissions.4', id) || tokenService.permissionMet('AuctionPermissions.4', id)">
              <a [routerLink]="['/auction/supplycatalogs/' + id]" routerLinkActive="active" class="nav-link" (click)="onRouterLinkClick('ac-catalogs')">
                {{ 'AUCTION.SUPPLY_CATALOG_MANAGEMENT' | translate }}
              </a>
            </li>
            <li *ngIf="tokenService.permissionMet('AuctionClusterPermissions.18', id) || tokenService.permissionMet('AuctionPermissions.18', id)">
              <a [routerLink]="['/auction/forecastcatalogs/' + id]" routerLinkActive="active" class="nav-link" (click)="onRouterLinkClick('ac-catalogs')">
                {{ 'AUCTION.FORECAST_CATALOG_MANAGEMENT' | translate }}
              </a>
            </li>
          </ul>
        </collapsable-section>

        <a routerLinkActive="active"
           *ngIf="tokenService.permissionMet('AuctionClusterPermissions.19', id) || tokenService.permissionMet('AuctionPermissions.19', id)"
           [routerLink]="['/auction/lottery/' + id]" class="nav-link" (click)="onRouterLinkClick()">
          {{ 'AUCTION.LOTTERY' | translate }}
        </a>

        <a routerLinkActive="active" [routerLink]="['/auction/supplyforecast/' + id]" class="nav-link public-supply-forecast-urk" (click)="onRouterLinkClick()">
          {{ 'CONTENT.SUPPLY_FORECAST' | translate }}
        </a>

        <a routerLinkActive="active"
           *ngIf="tokenService.permissionMet('AuctionClusterPermissions.12', id) || tokenService.permissionMet('AuctionPermissions.12', id)"
           [routerLink]="['/auction/supplymonitor/'+ id]" class="nav-link" (click)="onRouterLinkClick()">
          {{ 'AUCTION.SUPPLY_MONITOR.TITLE' | translate }}
        </a>

        <a routerLinkActive="active"
           *ngIf="tokenService.permissionMet('AuctionClusterPermissions.15', id) || tokenService.permissionMet('AuctionPermissions.15', id)"
           [routerLink]="['/auction/transactionmonitor/'+ id]" class="nav-link" (click)="onRouterLinkClick()">
          {{ 'AUCTION.TRANSACTION_MONITOR.TITLE' | translate }}
        </a>

        <a routerLinkActive="active"
           *ngIf="tokenService.permissionMet('AuctionClusterPermissions.13', id)"
           [routerLink]="['/auction/ticketprinters/'+ id]" class="nav-link" (click)="onRouterLinkClick()">
          {{ 'TICKET_PRINTER.TITLE' | translate }}
        </a>

        <a routerLinkActive="active"
           *ngIf="tokenService.permissionMet('AuctionClusterPermissions.21', id)"
           [routerLink]="['/auction/terms/'+ id]" class="nav-link" (click)="onRouterLinkClick()">
          {{ 'TERMS_AND_CONDITIONS.TITLE' | translate }}
        </a>

        <a routerLinkActive="active" *ngIf="tokenService.permissionMet('AuctionClusterPermissions.11', id) || tokenService.permissionMet('AuctionPermissions.11', id)" [routerLink]="['/auction/reports/' + id]" class="nav-link" (click)="onRouterLinkClick()">
          {{ 'REPORTING.TITLE' | translate }}
        </a>

        <a routerLinkActive="active" *ngIf="tokenService.permissionMet('AuctionClusterPermissions.11', id) || tokenService.permissionMet('AuctionPermissions.11', id)" [routerLink]="['/auction/notifications/' + id]" class="nav-link" (click)="onRouterLinkClick()">
          {{ 'AUCTION.NOTIFICATIONS' | translate }}
        </a>

        <ng-container *ngFor="let item of menuItems" >
          <collapsable-section *ngIf="item.menuType === 3" [caption]="getTranslation(item.menuItemName)" sectionId="item.menuItemName" (clicked)="onRouterLinkClick('item.menuItemName')">
            <ul class="nav-list">
              <ng-container *ngFor="let subItem of subMenuItems" >
                <ng-container *ngIf="item.externalAppId === subItem.submenuId">
                  <a *ngIf="!subItem.openInNewTab"
                  [routerLink]="['/auction/' + id + '/menu-item/' + subItem.externalAppId]"
                  routerLinkActive="active"
                  class="nav-link">{{ getTranslation(subItem.menuItemName) }}</a>

                  <a *ngIf="subItem.openInNewTab"
                  [href]="subItem.applicationUrl"
                  [target]="'_blank'"
                  routerLinkActive="active"
                  class="nav-link">{{ getTranslation(subItem.menuItemName) }}</a>
                </ng-container>
              </ng-container>
            </ul>
          </collapsable-section>

          <ng-container *ngIf="item.menuType === 1">
            <a *ngIf="!item.openInNewTab"
            [routerLink]="['/auction/' + id + '/menu-item/' + item.externalAppId]"
            routerLinkActive="active"
            class="nav-link">{{ getTranslation(item.menuItemName) }}</a>

            <a *ngIf="item.openInNewTab"
            [href]="item.applicationUrl"
            [target]="'_blank'"
            routerLinkActive="active"
            class="nav-link">{{ getTranslation(item.menuItemName) }}</a>
          </ng-container>

          <ng-container *ngIf="item.menuType === 2">
            <a [routerLink]="['/auction/' + id + '/menu-item/' + item.externalAppId]"
            routerLinkActive="active"
            class="nav-link">{{ getTranslation(item.menuItemName) }}</a>
          </ng-container>
        </ng-container>
      </section>
      <a *ngIf="expanded || pin" href="http://www.aucxis.com/" target="_blank" class="bottom-logo-wrapper">
        <img src="assets/images/Aucxis_w.png" />
      </a>
      <version-component></version-component>

      <section class="sidenav-content" *ngIf="!expanded && !pin">
        <a [routerLink]="['/core/home']" class="small-logo-wrapper">
          <!--<img src="assets/images/europool_logo_small.png" />-->
        </a>
        <div class="expand-btn" (click)="navExpand()">
          <clr-icon shape="arrow right" size="42"></clr-icon>
        </div>
        <a href="http://www.aucxis.com/" target="_blank" class="bottom-logo-wrapper">
          <img src="assets/images/Aucxis_w_vert.png" />
        </a>
      </section>
    </nav>
  </div>
</div>
<spinner-component></spinner-component>
