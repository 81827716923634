<div class="card register-assign-account-component">
  <div class="card-header">
    {{ 'REGISTER.ASSIGN_ACCOUNT' | translate }}
  </div>
  <form #registerAssignAccountForm="ngForm" (ngSubmit)="registerAssignAccount()" style="padding-top: 0.5rem">
  <div class="card-block">
      <div class="card-block">
        <section class="form-block">
          <div class="form-group" id="outter-div">
            <label class="required" id="label">{{ 'REGISTER.DESIRED_ACCESS' | translate }}:</label>
            <label for="desiredAccess" aria-haspopup="true" role="tooltip" class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm">
              <div class="select">
                <select name="desiredAccess" [(ngModel)]="model.desiredAccess" class="inner-select">
                  <option [ngValue]="0">{{ 'USER.BUYER' | translate }}</option>
                  <option [ngValue]="1">{{ 'USER.SUPPLIER' | translate }}</option>
                </select>
              </div>
            </label>
          </div>
          <div class="form-group" id="outter-div" *ngIf="auctionClusters.length > 1">
            <label class="required" id="label" for="auctionCluster">{{ 'REGISTER.EXISTING_AUCTION_CLUSTER' | translate }}</label>
            <div class="select">
              <select name="auctionCluster" [(ngModel)]="user.existingAuctionClusterId" class="inner-select">
                <option *ngFor="let auctionCluster of auctionClusters" [ngValue]="auctionCluster.auctionClusterId">{{ auctionCluster.name }}</option>
              </select>
            </div>
          </div>

            <div class="radio-group" *ngIf="model.desiredAccess === DesiredAccess.BUYER">
              <!--<div class="radio radio-group-items">
                <input type="radio" name="menuOption" id="existingBuyerAccount" [checked]="model.buyerAccountType === AccountType.EXISTING" (change)="changeBuyerAccountType(AccountType.EXISTING)" />
                <label for="existingBuyerAccount">{{ 'REGISTER.EXISTING_BUYER_ACCOUNT' | translate }}</label>
              </div>-->
              <div class="radio-group-subitems" *ngIf="model.buyerAccountType === AccountType.EXISTING">
                <section class="form-block">
                  <div class="form-group" id="outter-div">
                    <label class="required" id="label" for="existingBuyerVatCountryCode">{{ 'SUPPLIERS.VAT' | translate }}:</label>
                    <div class="select" style="margin-right: 0">
                      <select id="existingBuyerVatCountryCode" class="inner-select" style="padding-bottom: 3px;" name="vatCountryCode" [(ngModel)]="vatCountryCode" (change)="user.existingBuyerVat = vatCountryCode + vatNumber">
                        <option *ngFor="let countryCode of vatService.getCountryCodes()" [value]="countryCode" [attr.selected]="countryCode === vatCountryCode">
                          {{ countryCode }}
                        </option>
                      </select>
                    </div>
                    <label for="existingBuyerVatNumber" class="input vat tooltip tooltip-validation tooltip-md" [class.invalid]="existingBuyerVatNumberLocal.dirty && !vatService.validate(vatCountryCode, vatNumber)" role="tooltip" aria-haspopup="true">
                      <input type="text" class="inner-select" id="existingBuyerVatNumber" #existingBuyerVatNumberLocal="ngModel" name="existingBuyerVatNumber" [(ngModel)]="vatNumber" (change)="user.existingBuyerVat = vatCountryCode + vatNumber" [placeholder]="('EXAMPLE' | translate) + ' ' + vatService.getExample(vatCountryCode)">
                      <span class="tooltip-content">{{ 'SUPPLIERS.VAT_INVALID' | translate }}</span>
                    </label>
                  </div>
                </section>
              </div>
              <!--<div class="radio radio-group-items">
                <input type="radio" name="menuOption" id="requestNewBuyerAccount" [checked]="model.buyerAccountType === AccountType.NEW" (change)="changeBuyerAccountType(AccountType.NEW)" />
                <label for="requestNewBuyerAccount">{{ 'REGISTER.REQUEST_NEW_BUYER_ACCOUNT' | translate }}</label>
              </div>
              <div class="radio-group-subitems" *ngIf="model.buyerAccountType === AccountType.NEW">
                <section class="form-block">
                  <div class="form-group">
                    <label for="buyerName" class="required">{{ 'BUYERS.NAME' | translate }}:</label>
                    <label aria-haspopup="true" role="tooltip" class="input tooltip tooltip-bottom-left tooltip-validation tooltip-sm" [ngClass]="{'invalid': buyerName.dirty && buyerName.invalid}">
                      <input type="text" name="buyerName" id="buyerName" placeholder="{{ 'BUYERS.NAME' | translate }}" [(ngModel)]="model.buyerName" #buyerName="ngModel" required />
                      <span class="tooltip-content">
                        {{ 'BUYERS.NAME_VALIDATION' | translate }}
                      </span>
                    </label>
                  </div>
                  <div class="form-group">
                    <label for="buyerAddress1" class="required">{{ 'BUYERS.ADDRESS' | translate }}:</label>
                    <label aria-haspopup="true" role="tooltip" class="input tooltip tooltip-bottom-left tooltip-validation tooltip-sm" [ngClass]="{'invalid': buyerAddress1.dirty && buyerAddress1.invalid}">
                      <input type="text" name="buyerAddress1" id="buyerAddress1" placeholder="{{ 'BUYERS.ADDRESS' | translate }}" [(ngModel)]="model.buyerAddress1" #buyerAddress1="ngModel" required />
                      <span class="tooltip-content">
                        {{ 'BUYERS.ADDRESS_VALIDATION' | translate }}
                      </span>
                    </label>
                  </div>
                  <div class="form-group">
                    <label for="buyerAddress2">{{ 'BUYERS.ADDRESS2' | translate }}:</label>
                    <label aria-haspopup="true" role="tooltip" class="input tooltip tooltip-bottom-left tooltip-validation tooltip-sm">
                      <input type="text" name="buyerAddress2" maxlength="50" id="buyerAddress2" placeholder="{{ 'BUYERS.ADDRESS2' | translate }}" [(ngModel)]="model.buyerAddress2" #buyerAddress2="ngModel" />
                      <span class="tooltip-content">
                        {{ 'BUYERS.ADDRESS2_VALIDATION' | translate }}
                      </span>
                    </label>
                  </div>
                  <div class="form-group">
                    <label for="buyerZip" class="required">{{ 'BUYERS.ZIP' | translate }}:</label>
                    <label aria-haspopup="true" role="tooltip" class="input tooltip tooltip-bottom-left tooltip-validation tooltip-sm" [ngClass]="{'invalid': buyerZip.dirty && buyerZip.invalid}">
                      <input type="text" name="buyerZip" id="buyerZip" placeholder="{{ 'BUYERS.ZIP' | translate }}" [(ngModel)]="model.buyerZip" #buyerZip="ngModel" required />
                      <span class="tooltip-content">
                        {{ 'BUYERS.ZIP_VALIDATION' | translate }}
                      </span>
                    </label>
                  </div>
                  <div class="form-group">
                    <label for="buyerCity" class="required">{{ 'BUYERS.CITY' | translate }}:</label>
                    <label aria-haspopup="true" role="tooltip" class="input tooltip tooltip-bottom-left tooltip-validation tooltip-sm" [ngClass]="{'invalid': buyerCity.dirty && buyerCity.invalid}">
                      <input type="text" maxlength="50" name="buyerCity" id="buyerCity" placeholder="{{ 'BUYERS.CITY' | translate }}" [(ngModel)]="model.buyerCity" #buyerCity="ngModel" required />
                      <span class="tooltip-content">
                        {{ 'BUYERS.CITY_VALIDATION' | translate }}
                      </span>
                    </label>
                  </div>
                  <div class="form-group">
                    <label for="buyerCountry" class="required">{{ 'BUYERS.COUNTRY' | translate }}:</label>
                    <label aria-haspopup="true" role="tooltip" class="input tooltip tooltip-bottom-left tooltip-validation tooltip-sm" [ngClass]="{'invalid': buyerCountry.dirty && buyerCountry.invalid}">
                      <input type="text" name="buyerCountry" maxlength="50" id="buyerCountry" placeholder="{{ 'BUYERS.COUNTRY' | translate }}" [(ngModel)]="model.buyerCountry" #buyerCountry="ngModel" required />
                      <span class="tooltip-content">
                        {{ 'BUYERS.COUNTRY_VALIDATION' | translate }}
                      </span>
                    </label>
                  </div>
                  <div class="form-group">
                    <label for="buyerVatCountryCode" class="required">{{ 'BUYERS.VAT' | translate }}:</label>
                    <div class="select" style="margin-right: 0">
                      <select id="buyerVatCountryCode" name="vatCountryCode" [(ngModel)]="vatCountryCode" (change)="model.buyerVat = vatCountryCode + vatNumber">
                        <option *ngFor="let countryCode of vatService.getCountryCodes()" [value]="countryCode" [attr.selected]="countryCode === vatCountryCode">
                          {{ countryCode }}
                        </option>
                      </select>
                    </div>
                    <label for="buyerVatNumber" class="input vat tooltip tooltip-bottom-left tooltip-validation tooltip-sm" [class.invalid]="buyerVatNumberLocal.dirty && !vatService.validate(vatCountryCode, vatNumber)" role="tooltip" aria-haspopup="true">
                      <input type="text" id="buyerVatNumber" name="buyerVatNumber" #buyerVatNumberLocal="ngModel" [(ngModel)]="vatNumber" (change)="model.buyerVat = vatCountryCode + vatNumber" [placeholder]="('EXAMPLE' | translate) + ' ' + vatService.getExample(vatCountryCode)">
                      <span class="tooltip-content">{{ 'SUPPLIERS.VAT_INVALID' | translate }}</span>
                    </label>
                  </div>
                  <div class="form-group">
                    <label for="buyerTelNumber" class="required">{{ 'BUYERS.TEL_NUMBER' | translate }}:</label>
                    <label aria-haspopup="true" role="tooltip" class="input tooltip tooltip-bottom-left tooltip-validation tooltip-sm" [ngClass]="{'invalid': buyerTelNumber.dirty && buyerTelNumber.invalid}">
                      <input type="text" name="buyerTelNumber" id="buyerTelNumber" placeholder="{{ 'BUYERS.TEL_NUMBER' | translate }}" [(ngModel)]="model.buyerTelNumber" #buyerTelNumber="ngModel" phoneNumber required />
                      <span class="tooltip-content">
                        {{ 'BUYERS.TEL_NUMBER_VALIDATION' | translate }}
                      </span>
                    </label>
                  </div>
                  <div class="form-group">
                    <label for="buyerFaxNumber">{{ 'BUYERS.FAX_NUMBER' | translate }}:</label>
                    <label aria-haspopup="true" role="tooltip" class="input tooltip tooltip-bottom-left tooltip-validation tooltip-sm">
                      <input type="text" name="buyerFaxNumber" id="buyerFaxNumber" placeholder="{{ 'BUYERS.FAX_NUMBER' | translate }}" [(ngModel)]="model.buyerFaxNumber" #buyerFaxNumber="ngModel" phoneNumber />
                      <span class="tooltip-content">
                        {{ 'BUYERS.FAX_NUMBER_VALIDATION' | translate }}
                      </span>
                    </label>
                  </div>
                  <div class="form-group">
                    <label for="buyerEmail" class="required">{{ 'BUYERS.EMAIL' | translate }}:</label>
                    <label aria-haspopup="true" role="tooltip" class="input tooltip tooltip-bottom-left tooltip-validation tooltip-sm" [ngClass]="{'invalid': buyerEmail.dirty && buyerEmail.invalid}">
                      <input type="text" name="buyerEmail" id="buyerEmail" placeholder="{{ 'BUYERS.EMAIL' | translate }}" [(ngModel)]="model.buyerEmail" #buyerEmail="ngModel" emailAddress required />
                      <span class="tooltip-content">
                        {{ 'BUYERS.EMAIL_VALIDATION' | translate }}
                      </span>
                    </label>
                  </div>
                  <div class="form-group">
                    <label for="buyerUrl">{{ 'BUYERS.URL' | translate }}:</label>
                    <label aria-haspopup="true" role="tooltip" class="input tooltip tooltip-bottom-left tooltip-validation tooltip-sm">
                      <input type="text" name="buyerUrl" id="buyerUrl" placeholder="{{ 'BUYERS.URL' | translate }}" [(ngModel)]="model.buyerUrl" #url="ngModel" url />
                      <span class="tooltip-content">
                        {{ 'BUYERS.URL_VALIDATION' | translate }}
                      </span>
                    </label>
                  </div>
                </section>
              </div>-->
            </div>

            <div class="radio-group" *ngIf="model.desiredAccess === DesiredAccess.SUPPLIER">
              <!--<div class="radio radio-group-items">
                <input type="radio" name="menuOption" id="existingSupplierAccount" [checked]="model.supplierAccountType === AccountType.EXISTING" (change)="changeSupplierAccountType(AccountType.EXISTING)" />
                <label for="existingSupplierAccount">{{ 'REGISTER.EXISTING_SUPPLIER_ACCOUNT' | translate }}</label>
              </div>-->
              <div class="radio-group-subitems" *ngIf="model.supplierAccountType === AccountType.EXISTING">
                <section class="form-block">
                  <div class="form-group" id="outter-div">
                    <label class="required" id="label" for="existingSupplierVatCountryCode">{{ 'SUPPLIERS.VAT' | translate }}:</label>
                    <div class="select" style="margin-right: 0">
                      <select id="existingSupplierVatCountryCode" class="inner-select" style="padding-bottom: 3px;" name="vatCountryCode" [(ngModel)]="vatCountryCode" (change)="user.existingSupplierVat = vatCountryCode + vatNumber">
                        <option *ngFor="let countryCode of vatService.getCountryCodes()" [value]="countryCode" [attr.selected]="countryCode === vatCountryCode">
                          {{ countryCode }}
                        </option>
                      </select>
                    </div>
                    <label for="existingSupplierVatNumber" class="input vat tooltip tooltip-validation tooltip-md" [class.invalid]="existingSupplierVatNumberLocal.dirty && !vatService.validate(vatCountryCode, vatNumber)" role="tooltip" aria-haspopup="true">
                      <input type="text" class="inner-select" id="existingSupplierVatNumber" name="existingSupplierVatNumber" #existingSupplierVatNumberLocal="ngModel" [(ngModel)]="vatNumber" (change)="user.existingSupplierVat = vatCountryCode + vatNumber" [placeholder]="('EXAMPLE' | translate) + ' ' + vatService.getExample(vatCountryCode)">
                      <span class="tooltip-content">{{ 'SUPPLIERS.VAT_INVALID' | translate }}</span>
                    </label>
                  </div>
                </section>
              </div>
              <!--<div class="radio radio-group-items">
                <input type="radio" name="menuOption" id="requestNewSupplierAccount" [checked]="model.supplierAccountType === AccountType.NEW" (change)="changeSupplierAccountType(AccountType.NEW)" />
                <label for="requestNewSupplierAccount">{{ 'REGISTER.REQUEST_NEW_SUPPLIER_ACCOUNT' | translate }}</label>
              </div>
              <div class="radio-group-subitems" *ngIf="model.supplierAccountType === AccountType.NEW">
                <section class="form-block">
                  <div class="form-group">
                    <label for="supplierName" class="required">{{ 'SUPPLIERS.NAME' | translate }}:</label>
                    <label aria-haspopup="true" role="tooltip" class="input tooltip tooltip-bottom-left tooltip-validation tooltip-sm" [ngClass]="{'invalid': supplierName.dirty && supplierName.invalid}">
                      <input type="text" name="supplierName" id="supplierName" placeholder="{{ 'SUPPLIERS.NAME' | translate }}" [(ngModel)]="model.supplierName" #supplierName="ngModel" required />
                      <span class="tooltip-content">
                        {{ 'SUPPLIERS.NAME_VALIDATION' | translate }}
                      </span>
                    </label>
                  </div>
                  <div class="form-group">
                    <label for="supplierAddress1" class="required">{{ 'SUPPLIERS.ADDRESS' | translate }}:</label>
                    <label aria-haspopup="true" role="tooltip" class="input tooltip tooltip-bottom-left tooltip-validation tooltip-sm" [ngClass]="{'invalid': supplierAddress1.dirty && supplierAddress1.invalid}">
                      <input type="text" name="supplierAddress1" id="supplierAddress1" placeholder="{{ 'SUPPLIERS.ADDRESS' | translate }}" [(ngModel)]="model.supplierAddress1" #supplierAddress1="ngModel" required />
                      <span class="tooltip-content">
                        {{ 'SUPPLIERS.ADDRESS_VALIDATION' | translate }}
                      </span>
                    </label>
                  </div>
                  <div class="form-group">
                    <label for="supplierAddress2">{{ 'SUPPLIERS.ADDRESS2' | translate }}:</label>
                    <label aria-haspopup="true" role="tooltip" class="input tooltip tooltip-bottom-left tooltip-validation tooltip-sm">
                      <input type="text" name="supplierAddress2" maxlength="50" id="supplierAddress2" placeholder="{{ 'SUPPLIERS.ADDRESS2' | translate }}" [(ngModel)]="model.supplierAddress2" #supplierAddress2="ngModel" />
                      <span class="tooltip-content">
                        {{ 'SUPPLIERS.ADDRESS2_VALIDATION' | translate }}
                      </span>
                    </label>
                  </div>
                  <div class="form-group">
                    <label for="supplierZip" class="required">{{ 'SUPPLIERS.ZIP' | translate }}:</label>
                    <label aria-haspopup="true" role="tooltip" class="input tooltip tooltip-bottom-left tooltip-validation tooltip-sm" [ngClass]="{'invalid': supplierZip.dirty && supplierZip.invalid}">
                      <input type="text" name="supplierZip" id="supplierZip" placeholder="{{ 'SUPPLIERS.ZIP' | translate }}" [(ngModel)]="model.supplierZip" #supplierZip="ngModel" required />
                      <span class="tooltip-content">
                        {{ 'SUPPLIERS.ZIP_VALIDATION' | translate }}
                      </span>
                    </label>
                  </div>
                  <div class="form-group">
                    <label for="supplierCity" class="required">{{ 'SUPPLIERS.CITY' | translate }}:</label>
                    <label aria-haspopup="true" role="tooltip" class="input tooltip tooltip-bottom-left tooltip-validation tooltip-sm" [ngClass]="{'invalid': supplierCity.dirty && supplierCity.invalid}">
                      <input type="text" name="supplierCity" maxlength="50" id="supplierCity" placeholder="{{ 'SUPPLIERS.CITY' | translate }}" [(ngModel)]="model.supplierCity" #supplierCity="ngModel" required />
                      <span class="tooltip-content">
                        {{ 'SUPPLIERS.CITY_VALIDATION' | translate }}
                      </span>
                    </label>
                  </div>
                  <div class="form-group">
                    <label for="supplierCountry" class="required">{{ 'SUPPLIERS.COUNTRY' | translate }}:</label>
                    <label aria-haspopup="true" role="tooltip" class="input tooltip tooltip-bottom-left tooltip-validation tooltip-sm" [ngClass]="{'invalid': supplierCountry.dirty && supplierCountry.invalid}">
                      <input type="text" name="supplierCountry" maxlength="50" id="supplierCountry" placeholder="{{ 'SUPPLIERS.COUNTRY' | translate }}" [(ngModel)]="model.supplierCountry" #supplierCountry="ngModel" required />
                      <span class="tooltip-content">
                        {{ 'SUPPLIERS.COUNTRY_VALIDATION' | translate }}
                      </span>
                    </label>
                  </div>
                  <div class="form-group">
                    <label for="supplierVatCountryCode" class="required">{{ 'SUPPLIERS.VAT' | translate }}:</label>
                    <div class="select" style="margin-right: 0">
                      <select id="supplierVatCountryCode" name="vatCountryCode" [(ngModel)]="vatCountryCode" (change)="model.supplierVat = vatCountryCode + vatNumber">
                        <option *ngFor="let countryCode of vatService.getCountryCodes()" [value]="countryCode" [attr.selected]="countryCode === vatCountryCode">
                          {{ countryCode }}
                        </option>
                      </select>
                    </div>
                    <label for="supplierVatNumber" class="input vat tooltip tooltip-bottom-left tooltip-validation tooltip-sm" [class.invalid]="supplierVatNumberLocal.dirty && !vatService.validate(vatCountryCode, vatNumber)" role="tooltip" aria-haspopup="true">
                      <input type="text" id="supplierVatNumber" #supplierVatNumberLocal="ngModel" name="supplierVatNumber" [(ngModel)]="vatNumber" (change)="model.supplierVat = vatCountryCode + vatNumber" [placeholder]="('EXAMPLE' | translate) + ' ' + vatService.getExample(vatCountryCode)">
                      <span class="tooltip-content">{{ 'SUPPLIERS.VAT_INVALID' | translate }}</span>
                    </label>
                  </div>
                  <div class="form-group">
                    <label for="supplierTelNumber" class="required">{{ 'SUPPLIERS.TEL_NUMBER' | translate }}:</label>
                    <label aria-haspopup="true" role="tooltip" class="input tooltip tooltip-bottom-left tooltip-validation tooltip-sm" [ngClass]="{'invalid': supplierTelNumber.dirty && supplierTelNumber.invalid}">
                      <input type="text" name="supplierTelNumber" id="supplierTelNumber" placeholder="{{ 'SUPPLIERS.TEL_NUMBER' | translate }}" [(ngModel)]="model.supplierTelNumber" #supplierTelNumber="ngModel" phoneNumber required />
                      <span class="tooltip-content">
                        {{ 'SUPPLIERS.TEL_NUMBER_VALIDATION' | translate }}
                      </span>
                    </label>
                  </div>
                  <div class="form-group">
                    <label for="supplierFaxNumber">{{ 'SUPPLIERS.FAX_NUMBER' | translate }}:</label>
                    <label aria-haspopup="true" role="tooltip" class="input tooltip tooltip-bottom-left tooltip-validation tooltip-sm">
                      <input type="text" name="supplierFaxNumber" id="supplierFaxNumber" placeholder="{{ 'SUPPLIERS.FAX_NUMBER' | translate }}" [(ngModel)]="model.supplierFaxNumber" #supplierFaxNumber="ngModel" phoneNumber />
                      <span class="tooltip-content">
                        {{ 'SUPPLIERS.FAX_NUMBER_VALIDATION' | translate }}
                      </span>
                    </label>
                  </div>
                  <div class="form-group">
                    <label for="supplierEmail" class="required">{{ 'SUPPLIERS.EMAIL' | translate }}:</label>
                    <label aria-haspopup="true" role="tooltip" class="input tooltip tooltip-bottom-left tooltip-validation tooltip-sm" [ngClass]="{'invalid': supplierEmail.dirty && supplierEmail.invalid}">
                      <input type="text" name="supplierEmail" id="supplierEmail" placeholder="{{ 'SUPPLIERS.EMAIL' | translate }}" [(ngModel)]="model.supplierEmail" #supplierEmail="ngModel" emailAddress required />
                      <span class="tooltip-content">
                        {{ 'SUPPLIERS.EMAIL_VALIDATION' | translate }}
                      </span>
                    </label>
                  </div>
                  <div class="form-group">
                    <label for="supplierUrl">{{ 'SUPPLIERS.URL' | translate }}:</label>
                    <label aria-haspopup="true" role="tooltip" class="input tooltip tooltip-bottom-left tooltip-validation tooltip-sm">
                      <input type="text" name="supplierUrl" id="supplierUrl" placeholder="{{ 'SUPPLIERS.URL' | translate }}" [(ngModel)]="model.supplierUrl" #url="ngModel" url />
                      <span class="tooltip-content">
                        {{ 'SUPPLIERS.URL_VALIDATION' | translate }}
                      </span>
                    </label>
                  </div>
                </section>
              </div>-->
            </div>
        </section>
      </div>
    </div>
    <div class="card-footer">
      <button type="submit" class="btn btn-primary" [disabled]="registerAssignAccountForm.form.invalid || !vatService.validate(vatCountryCode, vatNumber)">{{ 'REGISTER.FINISH' | translate }}</button>
    </div>
  </form>
</div>
