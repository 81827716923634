import { Component, OnInit, OnDestroy, Injector, ViewChild } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import notify from 'devextreme/ui/notify';

// general services
import { LanguageService } from '../../shared/services/language.service';


// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { MaskComponent } from './mask.component';

// services
import { MaskService } from '../shared/services/mask.service';

// models
import { Language } from '../../shared/models/language';
import { Mask, MaskTypes } from '../shared/models/mask';
import { ImportSchema, ImportField } from '../../shared/import/import-schema';

@Component({
  selector: 'masks-component',
  templateUrl: 'masks.component.html',
  styleUrls: ['./masks.component.scss']
})
export class MasksComponent extends FullListComponent<Mask, MaskComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: MaskComponent;

  languages: Array<Language> = [];
  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;

  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private dataService: MaskService
  ) {
    super(injector, Mask);
    this.title.set('MASK.TITLE');
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.setTranslations('MASK');
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  getData() {
    this.spinner.show();
    forkJoin(
      this.dataService.getMasks(this.id),
      this.languageService.getAuctionClusterLanguages(this.id)
    ).subscribe(result => {
      this.items = result[0];
      this.languages = result[1];
      this.translateMasks();
      this.spinner.hide();
    },
      error => {
        notify(error.message, 'error', 5000);
        this.spinner.hide();
      });
  }

  getMaskSourceTypeName = (e: any) => {
    const sourceType = e.value;
    switch (sourceType) {
      case MaskTypes.SUPPLY_FORECAST_MASK_TYPE:
        return this.translations.SUPPLY_FORECAST;
      case MaskTypes.SUPPLY_MASK_TYPE:
        return this.translations.SUPPLY;
      default:
        return this.translations.MASTER_DATA;
    }
  }

  edit = (maskId: number) => {

    this.detailsComponent.modalTitle = this.translations.EDIT;
    this.detailsComponent.open(this.items, maskId, this.languages);
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.ADD_NEW;
    this.detailsComponent.open(this.items, null, this.languages);
  }

  deleteSelected() {
    this.spinner.show();
    this.dataService.delete(this.itemIdToDelete, this.id)
      .subscribe((users: Array<Mask>) => {
        this.getData();
        this.spinner.hide();
      },
      error => {
        notify(this.errorService.translations.DELETE_ERROR_MESSAGE, 'error', 5000);
        this.spinner.hide();
      });
  }

  translateMasks() {
    this.items.forEach(m => {
      m.displayName = this.languageService.getTranslatableText(m.name);
    });
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.maskId;
    this.confirmation.opened = true;
  }

  onRowClick = (e: any) => {
    this.edit(e.data.maskId);
  }

  editItem = (e: any) => {
    this.edit(e.row.data.maskId);
  }

  createDataSchema() {

    const schema = new ImportSchema();

    const maskName = new ImportField('MaskName', this.translations.NAME);
    maskName.required = true;

    schema.fields.push(maskName);

    this.schema = schema;
  }
}
