<div class="auction-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup #addAuction [width]="600" [height]="500" [showTitle]="true" title="{{ modalTitle }}" [dragEnabled]="false"
    [closeOnOutsideClick]="false" [rtlEnabled]="rtlEnabled" [(visible)]="isOpened"
    (onShown)="onFieldDataChanged($event)">
    <dx-scroll-view width="100%" height="100%">
      <dx-form #auctionForm id="auctionForm" validationGroup="auctionData" [showValidationSummary]="false"
        (onFieldDataChanged)="onFieldDataChanged($event)" colCount="auto" [(formData)]="model" [minColWidth]="500">

        <dxi-item itemType="group">
          <dxi-item dataField="name">
            <dxo-label text="{{ 'AUCTIONS_MANAGEMENT.NAME' | translate }}"></dxo-label>
            <dxi-validation-rule type="required" message="{{ 'AUCTIONS_MANAGEMENT.NAME_VALIDATION' | translate }}">
            </dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="percentageLimit" editorType="dxNumberBox">
            <dxo-label text="{{ 'AUCTIONS_MANAGEMENT.PERCENTAGE_LIMIT' | translate }}"></dxo-label>
          </dxi-item>
          <dxi-item dataField="creditLimitCanBeOverruled" editorType="dxCheckBox">
            <dxo-label text="{{ 'AUCTIONS_MANAGEMENT.CREDIT_LIMIT' | translate }}"></dxo-label>
          </dxi-item>
          <dxi-item dataField="numberOfTransactionsStartPrice" editorType="dxNumberBox">
            <dxo-label text="{{ 'AUCTIONS_MANAGEMENT.NUMBER_OF_TRANSACTIONS_START_PRICE' | translate }}"></dxo-label>
            <dxi-validation-rule type="required" message="{{ 'AUCTIONS_MANAGEMENT.NUMBER_OF_TRANSACTIONS_START_PRICE_VALIDATION' | translate }}">
            </dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="minTransactionAmountEffectStartPrice" editorType="dxNumberBox">
            <dxo-label text="{{ 'AUCTIONS_MANAGEMENT.MIN_TRANSACTION_AMOUNT_EFFECT_START_PRICE' | translate }}">
            </dxo-label>
            <dxi-validation-rule type="required" message="{{ 'AUCTIONS_MANAGEMENT.MIN_TRANSACTION_AMOUNT_EFFECT_START_PRICE_VALIDATION' | translate }}">
            </dxi-validation-rule>
          </dxi-item>
        </dxi-item>

      </dx-form>
      <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnAuctionSubmit" disabled="disabled">
        {{ 'SAVE' | translate }}
      </button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'CANCEL' | translate }}</button>
    </dx-scroll-view>
  </dx-popup>
</div>