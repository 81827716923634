import { Component, EventEmitter, HostListener, Injector, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import DataGrid from 'devextreme/ui/data_grid';
import notify from 'devextreme/ui/notify';
import * as _moment from 'moment';
import { Subscription } from 'rxjs';

import { Catalog } from '../../auction/shared/models/catalog';
import { ProductPropertyTypes, UrlTypes } from '../../auction/shared/models/product';
import { AuctionCluster } from '../../platform/shared/models/auction-cluster';
// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
// models
import { ImportField, ImportSchema } from '../../shared/import/import-schema';
import { BuyerActionOnLotGroup, GenericLotColumn, GenericLotRow } from '../../shared/models/generic-lot';

// general services
import { TokenService } from '../../shared/services/token.service';
import { PrebidOnLotComponent } from '../shared/components/prebids/prebid-on-lot.component';
import { BuyerClock } from '../shared/models/buyer-level-clocks';
import { BuyerLevelSubbuyer } from '../shared/models/buyer-level-subbuyer';
// services
import { SupplyMatrixService } from '../shared/services/supply-matrix.service';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'buyer-supply-matrix-lots-component',
  templateUrl: 'buyer-supply-matrix-lots.component.html',
  styleUrls: ['./buyer-supply-matrix-lots.component.scss']
})
export class BuyerSupplyMatrixLotsComponent extends FullListComponent<GenericLotRow, PrebidOnLotComponent> implements OnInit, OnDestroy {

  auctionClusterId: number = null;
  auctionClusters: Array<AuctionCluster> = [];
  catalogId: number = null;
  catalogs: Array<Catalog> = [];
  clockId = 0;
  clocks: Array<BuyerClock> = [];
  date: Date = new Date();
  columns: Array<any> = [];
  subbuyers: Array<BuyerLevelSubbuyer> = [];
  data: Array<any> = new Array<any>();
  objectKeys: Array<string> = new Array<string>();
  items: Array<GenericLotRow> = [];
  filterPrebids = false;
  filterMarked = false;
  lastRefresh = '';
  currentPage = 1;
  lastRecordedPage = 1;
  lotIds: Array<number> = [];
  isOpened = false;
  dataGridInstance: DataGrid;

  tokenService: TokenService;
    
  popUpShowed: boolean = false;
  popUpOpened: boolean = false;
  carrouselLoaded: boolean = false;
  carrouselData: Array<any> = [];
  carrouselShown: boolean = false;

  @ViewChild('prebid') buyerSupplyMatrixPrebidComponent: PrebidOnLotComponent;

  @Output() onClosed = new EventEmitter<any>(); // tslint:disable-line:no-output-on-prefix

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;
  gridColumns = [];

  constructor(
    protected injector: Injector,
    private supplyMatrixService: SupplyMatrixService,
    private translateService: TranslateService,
  ) {
    super(injector, GenericLotRow);
    this.tokenService = this.injector.get(TokenService);
    this._subscription = this.language.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.setTranslations('BUYER.SUPPLY_MATRIX');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getData() {
    return;
  }

  open(auctionClusterId: number, catalogId: number, date: Date, clockId: number,
    lotIds: Array<number>, subbuyers: Array<BuyerLevelSubbuyer>) {
    this.auctionClusterId = auctionClusterId;
    this.catalogId = catalogId;
    this.date = date;
    this.clockId = clockId;
    this.lotIds = lotIds;
    this.subbuyers = subbuyers;
    this.isOpened = true;
    this.fetchLots(true);
  }

  onPrebidClosed() {
    this.fetchLots(false);
  }

  fetchLots(refreshColumns: boolean) {
    // Reset any existing filtering if column refresh is requested
    if (refreshColumns) {
      this.columns = [];
    }

    this.data = [];
    this.items = [];
    const dateFormatted = moment(this.date).local().format('YYYY-MM-DD');
    this.spinner.show();

    this.supplyMatrixService.getCatalogs(this.id, this.auctionClusterId)
      .subscribe(result => {
        this.catalogs = result;
      },
        error => {
          notify(error.message, 'error', 5000);
        });

    this.supplyMatrixService.getLotsForPrebids(this.id, this.auctionClusterId, this.catalogId, dateFormatted, this.lotIds, this.clockId)
      .subscribe(result => {
        this.items = result.rows;
        if (refreshColumns) {
          // Parse columns only if explicitly requested
          this.parseColumns(result.columnTitles);
        }
        this.parseObject(result.rows);
        this.spinner.hide();
        this.lastRefresh = moment().locale(this.translateService.currentLang).format('DD.MM.YYYY. HH:mm');
        this.generateTable(result);
      },
        error => {
          notify(error.message, 'error', 5000);
          this.spinner.hide();
        });
  }

  private parseObject(rows: Array<GenericLotRow>) {
    this.data = [];

    // Ignore parsing if there are no columns defined
    if (this.columns.length === 0) {
      return;
    }

    // Apply predib/marked filter for lots
    if (this.filterPrebids || this.filterMarked) {
      rows = rows.filter(_ => {
        return (this.filterPrebids && _.prebid) || (this.filterMarked && _.hasMark);
      });
    }

    rows.forEach(row => {
      const obj = new Object();
      this.columns.forEach((column, i) => {
        // Parse prebids
        if (row.prebid) {
          // Add prebid price in values
          if (row.prebid.price !== 0) {
            row.values.push(row.prebid.price.toFixed(2).toString());
          } else {
            row.values.push(row.prebid.buyerActionOnLot.map(_ => _.price).join(', '));
          }
          // Find and add subbuyer in values
          const subbuyer = this.subbuyers.find(_ => _.buyerId === row.prebid.subbuyerId);
          if (subbuyer) {
            row.values.push(`${subbuyer.subNumber} - ${subbuyer.name}`);
          } else {
            // Add null if there is no subbuyer
            row.values.push(null);
          }
        }
        if (column.propertyTypeId === ProductPropertyTypes.DATE) {
          obj['key' + i] = this.addLineBreaks(row.values[i], column.propertyTypeId, column.propertyTypeFormatId);
        } else if (column.propertyTypeId === ProductPropertyTypes.URL) {
          obj['key' + i] = row.values[i];
        } else if (column.propertyTypeId === ProductPropertyTypes.TEXT
          || column.propertyTypeId === ProductPropertyTypes.BOOLEAN
          || column.propertyTypeId === ProductPropertyTypes.IMAGE
          || column.propertyTypeId === ProductPropertyTypes.MASTER_DATA_VALUE
          || isNaN(Number(row.values[i]))) {
          // Add line breaks for string value
          let objValue = this.getTranslation(row.values[i]);
          objValue = this.addLineBreaks(objValue, column.propertyTypeId);
          obj['key' + i] = objValue;
        } else {
          obj['key' + i] = this.format(Number(row.values[i]), column.propertyTypeId);
        }
      });
      obj['__item__'] = row;

      this.data.push(obj);
    });

    // Reset default page size
    this.currentPage = this.lastRecordedPage;
    this.setPageSize(this.pageSizeValue);
  }

  private parseColumns(columns: Array<GenericLotColumn>) {
    const _columns = [];
    columns.forEach(column => {
      _columns.push({
        name: this.getTranslation(column.name),
        propertyTypeId: column.propertyTypeId,
        propertyTypeFormatId: column.propertyTypeFormatId
      });
    });

    // Additional columns for displaying prebid info
    _columns.push({
      name: this.getTranslation(this.translations.PREBID_PRICE),
      propertyTypeId: null
    });
    _columns.push({
      name: this.getTranslation(this.translations.PREBID_SUBBUYER),
      propertyTypeId: null
    });


    const _objectKeys = [];
    _columns.forEach((column, i) => {
      _objectKeys.push('key' + i);
    });

    this.objectKeys = _objectKeys;
    this.columns = _columns;
    this.createDataSchema();
  }

  findLot(lotId: number) {
    for (let i = 0; i < this.data.length; i += 1) {
      if (this.data[i].__item__ && this.data[i].__item__.id === lotId) {
        return this.data[i].__item__;
      }
    }

    return null;
  }

  edit(lotId: number, event: Event) {
    event.stopPropagation();

    this.buyerSupplyMatrixPrebidComponent.modalTitle = this.translations.PREBID;
    const lot = this.findLot(lotId);
    lot.lotIds = lot.groupedIds;
    const catalog = this.catalogs.find(c => c.catalogId === this.catalogId);
    this.buyerSupplyMatrixPrebidComponent.open(this.items, lotId, this.auctionClusterId, this.catalogId, this.subbuyers,
      this.date, catalog.allowPartialSaleOfSingleLotWithMultipleSaleUnits, catalog.amountIsBoxContentMultitude, catalog.allowMultiplePrebidsPerBuyerOnSameLot);
  }

  markLot(lot: GenericLotRow, isMark: boolean, event: Event) {
    event.stopPropagation();
    const prebid = new BuyerActionOnLotGroup();
    prebid.buyerId = this.id;
    prebid.catalogId = this.catalogId;
    prebid.groupedIds = lot.groupedIds;

    if (isMark) {
      this.supplyMatrixService.saveMark(this.id, prebid, this.auctionClusterId).subscribe(result => {
        this.fetchLots(false);
      }, err => {
        if (err.error && err.error.length > 0 && err.error[0].ErrorMessage === 'LOT_ALREADY_SERVED') {
          notify(this.translations.LOT_ALREADY_SERVED, 'error', 5000);
        }
        else {
          let errorMessage = `${err.status}: ${err.statusText}`;
          notify(errorMessage, 'error', 5000);
        }
      });
    } else {
      this.supplyMatrixService.deleteMark(this.id, prebid, this.auctionClusterId).subscribe(result => {
        this.fetchLots(false);
      }, err => {
        if (err.error && err.error.length > 0 && err.error[0].ErrorMessage === 'LOT_ALREADY_SERVED') {
          notify(this.translations.LOT_ALREADY_SERVED, 'error', 5000);
        }
        else {
          let errorMessage = `${err.status}: ${err.statusText}`;
          notify(errorMessage, 'error', 5000);
        }
      });
    }
  }

  deleteSelected() {
    //
  }

  onClose() {
    this.isOpened = false;
    this.onClosed.emit();
  }

  createDataSchema() {
    const schema = new ImportSchema();

    this.columns.forEach((column, i) => {
      const field = new ImportField(this.objectKeys[i], column);
      field.required = true;
      schema.fields.push(field);
    });

    this.schema = schema;
  }

  initColumn = (e: any) => {
    this.dataGridInstance = e.component;
  }

  editBackground(e: any) {
    if (e.rowType == 'data') {
      if (e.data.__item__.prebid) {
        e.rowElement.style.backgroundColor = '#A3EDB2';
      }
      else if (e.data.__item__.hasMark) {
        e.rowElement.style.backgroundColor = '#A3C4ED';
      }
    }
  }

  editCellBackground(e: any) {
    if (e.rowType == 'data') {
      if (e.data.__item__.prebid) {
        e.cellElement.style.backgroundColor = '#A3EDB2';
      }
      else if (e.data.__item__.hasMark) {
        e.cellElement.style.backgroundColor = '#A3C4ED';
      }
    }
  }

  generateTable = (rows: any) => {
    this.gridColumns = [];
    let gridCurrentColums = this.dataGridInstance.getVisibleColumns();
    rows.columnTitles.forEach((row: any, i: number) => {
      let columnName = this.getTranslation(row.name);
      if (this.gridColumns.includes(columnName)) {
        let j = 0;
        do {
          columnName = this.getTranslation(row.name) + j;
          j++;
        } while (this.gridColumns.includes(columnName));
      }
      this.gridColumns.push(columnName);
      const isColumn = gridCurrentColums.find(c => c.dataField === columnName);
      const isImage = (row.propertyTypeId === ProductPropertyTypes.URL &&
        row.propertyTypeFormatId === UrlTypes.IMAGE_URL);

      if (!isColumn) {
        this.dataGridInstance.addColumn({
          dataField: columnName,
          caption: this.getTranslation(row.name),
          visibleIndex: i,
          allowEditing: false,
          encodeHtml: false,
          cellTemplate: isImage ? 'imageCellTemplate' : 'cellTemplate'
        });
      }
    });

    const isColumnPrebidPrice = gridCurrentColums.find(c => c.dataField === this.getTranslation(this.translations.PREBID_PRICE));
    this.gridColumns.push(this.getTranslation(this.translations.PREBID_PRICE));
    if (!isColumnPrebidPrice) {
      gridCurrentColums = this.dataGridInstance.getVisibleColumns();
      this.dataGridInstance.addColumn({
        dataField: this.getTranslation(this.translations.PREBID_PRICE),
        caption: this.getTranslation(this.translations.PREBID_PRICE),
        visibleIndex: gridCurrentColums.length - 1,
        allowEditing: false
      });

    }

    const isColumnPrebidSubbuyer = gridCurrentColums.find(c => c.dataField === this.getTranslation(this.translations.PREBID_SUBBUYER));
    this.gridColumns.push(this.getTranslation(this.translations.PREBID_SUBBUYER));
    if (!isColumnPrebidSubbuyer) {
      gridCurrentColums = this.dataGridInstance.getVisibleColumns();
      this.dataGridInstance.addColumn({
        dataField: this.getTranslation(this.translations.PREBID_SUBBUYER),
        caption: this.getTranslation(this.translations.PREBID_SUBBUYER),
        visibleIndex: gridCurrentColums.length - 1,
        allowEditing: false
      });

    }

    this.generateTableData();
  }


  generateTableData() {
    const gridItems = [];
    this.data.forEach((row, i) => {
      let gridItem = {};
      var imageColumns =  this.columns.filter(tableColumn => tableColumn.propertyTypeId === ProductPropertyTypes.URL && tableColumn.propertyTypeFormatId === UrlTypes.IMAGE_URL);

      this.gridColumns.forEach((column, j) => {
        if (imageColumns.length !== 0 && imageColumns.filter(_ => _.name === column).length !== 0) {
          gridItem[column] = row['key' + j];
        } else {
          gridItem[column] = this.getTranslation(row['key' + j]);
        }
      });

      gridItem['__item__'] = row.__item__;

      gridItems.push(gridItem);
    });

    const rows = this.dataGridInstance.getDataSource();
    if (rows === null) {
      if (gridItems.length > 0) {
        this.dataGridInstance.option('dataSource', gridItems);
      }
    } else {
      this.dataGridInstance.option('dataSource', gridItems);
    }
  }

  doesImageCalculationExist(data: any) {
    if (data && JSON.parse(data).find(el => el.field_0 !== '')) {
      return true;
    }
    return false;
  }

  getImage(data: any) {
    let imageUrl = JSON.parse(data).find(el => el.field_0 !== '').field_0;
    return imageUrl + '-thumbnail';
  }

  showImageCarrousel(data: any) {
    this.carrouselData = JSON.parse(data).filter(el => el.field_0 !== '');
    this.spinner.show();
    this.popUpOpened = true;

    if (this.carrouselLoaded) {
      this.spinner.hide();
      this.popUpShowed = true;
      this.carrouselShown = true;
    }
  }

  openPopUp() {
    this.spinner.hide();
    this.popUpShowed = true;
    this.carrouselLoaded = true;
    this.carrouselShown = true;
  }

  closePopUp() {
    this.popUpOpened = false;
    this.popUpShowed = false;
    this.carrouselLoaded = false;
  }

  @HostListener('window:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === "Escape") {
      if(this.buyerSupplyMatrixPrebidComponent.isOpened || this.carrouselShown) {
        this.carrouselShown = false;
        return;
      } else {
        event.stopPropagation();
        this.isOpened = false;
      }
    }
  }

  add() { }
}
