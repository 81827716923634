import { Component, OnInit, Injector, OnDestroy, ViewChild } from '@angular/core';

// general services
import { LanguageService } from '../../shared/services/language.service';
import { TokenService } from '../../shared/services/token.service';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { AuctionClusterReportingRoleComponent } from './auction-cluster-reporting-role.component';

// services
import { AuctionClusterReportingRoleService } from '../shared/services/auction-cluster-reporting-role.service';
import { AuctionClusterReportService } from '../shared/services/report.service';

// models
import { Language } from '../../shared/models/language';
import { AuctionClusterReportingRole } from '../../shared/models/auction-cluster-reporting-role';
import { ReportDesign } from '../../shared/models/report';
import { AuctionClusterPermissionEnum } from '../../shared/models/user-permissions';
import { ImportSchema, ImportField } from '../../shared/import/import-schema';
import notify from 'devextreme/ui/notify';
import { Subscription, timer, forkJoin } from 'rxjs';

@Component({
  selector: 'auction-cluster-reporting-roles.component',
  templateUrl: 'auction-cluster-reporting-roles.component.html',
  styleUrls: ['./auction-cluster-reporting-roles.component.scss']
})
export class AuctionClusterReportingRolesComponent extends FullListComponent<AuctionClusterReportingRole, AuctionClusterReportingRoleComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: AuctionClusterReportingRoleComponent;

  reportDesigns: Array<ReportDesign> = [];
  languages: Array<Language> = [];
  roleHasUsers: boolean;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private dataService: AuctionClusterReportingRoleService,
    private reportService: AuctionClusterReportService,
    private languageService: LanguageService,
    private tokenService: TokenService
  ) {
    super(injector, AuctionClusterReportingRole);
    this.title.set('PLATFORM.ROLES');
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.setTranslations('ROLES');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  delete(itemId: number, event: Event) {
    event.stopPropagation();

    this.itemIdToDelete = itemId;
    this.dataService.checkIfRoleHasUsers(this.id, this.itemIdToDelete).subscribe(res => {
      if (res.length === 0) {
        this.confirmation.opened = true;
      } else {
        this.roleHasUsers = true;
      }
    });
    const alertDuration = 3000;
    timer(alertDuration).subscribe(() => {
      this.roleHasUsers = false;
    });
  }

  deleteSelected() {
    this.spinner.show();
    this.dataService.delete(this.id, this.itemIdToDelete)
      .subscribe((result: boolean) => {
        this.getData();
        this.spinner.hide();
      },
        error => {
          notify(this.errorService.translations.DELETE_ERROR_MESSAGE, 'error', 5000);
          this.spinner.hide();
        });
  }

  // tslint:disable:no-magic-numbers
  getData() {
    this.spinner.show();
    forkJoin(
      this.reportService.getAllAuctionClusterDesigns(this.id),
      this.languageService.getAuctionClusterLanguages(this.id),
      this.dataService.getAuctionClusterReportingRoles(this.id),
    ).subscribe(result => {
      this.reportDesigns = result[0];
      this.languages = result[1];
      this.items = result[2];

      this.translateRoles();
      this.spinner.hide();
    },
      error => {
        notify(error.message, 'error', 5000);
        this.spinner.hide();
      });
  }
  // tslint:disable:no-magic-numbers

  edit(roleId: number, event: Event) {
    event.stopPropagation();

    this.detailsComponent.modalTitle = this.translations.EDIT;
    this.detailsComponent.open(this.items, roleId, this.reportDesigns, this.languages, this.id);
  }

  editItem = (e: any) => {
    const auctionClusterReportingRoleId = e.row !== undefined ? e.row.data.auctionClusterReportingRoleId : e.data.auctionClusterReportingRoleId;
    this.detailsComponent.modalTitle = this.translations.EDIT;
    this.detailsComponent.open(this.items, auctionClusterReportingRoleId, this.reportDesigns, this.languages, this.id);
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.ADD_NEW;
    this.detailsComponent.open(this.items, null, this.reportDesigns, this.languages, this.id);
  }

  private translateRoles() {
    this.items.forEach(role => {
      role.roleNameText = this.languageService.getTranslatableText(role.name);
      role.roleDescriptionText = this.languageService.getTranslatableText(role.description);
      role.permissionString = this.buildPermissionsString(role.reportDesigns);
    });
  }

  private buildPermissionsString(permissions: Array<number>) {
    const permissionNames: Array<string> = [];
    permissions.forEach(item => {
      const reportDesign = this.reportDesigns.find(p => p.reportDesignId === item);
      if(reportDesign !== undefined)
      {
        const permissionName = reportDesign.name;
        permissionNames.push(this.languageService.getTranslatableText(permissionName));
      }
      
    });
    return permissionNames.join(', ');
  }

  createDataSchema() {
    const schema = new ImportSchema();

    const roleName = new ImportField('roleNameText', this.translations.ROLE_NAME);
    roleName.required = true;

    const roleDesc = new ImportField('roleDescriptionText', this.translations.ROLE_DESCRIPTION);
    roleDesc.required = true;

    const permission = new ImportField('permissionString', this.translations.PERMISSIONS);
    permission.required = true;

    schema.fields.push(roleName, roleDesc, permission);

    this.schema = schema;
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.auctionClusterReportingRoleId;

    this.dataService.checkIfRoleHasUsers(this.id, this.itemIdToDelete).subscribe(res => {
      if (res.length === 0) {
        this.confirmation.opened = true;
      } else {
        notify(this.translations.ROLE_HAS_USERS, 'error', 5000);
      }
    });

    const alertDuration = 3000;
    timer(alertDuration).subscribe(() => {
      this.roleHasUsers = false;
    });
  }
}
