<div class="lookup-table-field-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup #editLookupTableField [width]="600" height="350px" [showTitle]="true" title="{{ modalTitle }}" [dragEnabled]="false"
    [closeOnOutsideClick]="false" [rtlEnabled]="rtlEnabled" [(visible)]="isOpened"
    (onShown)="onFieldDataChanged($event)">
    <dx-scroll-view width="100%" height="100%">
      <dx-form #lookupTableFieldForm id="lookupTableFieldForm" [showValidationSummary]="false"
        (onFieldDataChanged)="onFieldDataChanged($event)" colCount="auto" [(formData)]="model" [minColWidth]="500">

        <dxi-item dataField="propertyTypeId" editorType="dxSelectBox" 
          [editorOptions]="{ items: productPropertyTypes, valueExpr:'propertyTypeId', displayExpr:'name', onSelectionChanged: onTypeChanged}">
          <dxo-label text="{{ 'LOOKUP_TABLE.FIELD.TYPE' | translate }}"></dxo-label>
        </dxi-item>

        <dxi-item dataField="propertyFormatId" editorType="dxSelectBox" 
          [editorOptions]="{ items: productPropertyFormats, valueExpr:'propertyTypeFormatId', displayExpr:'name', visible: (model.propertyTypeId === 8)}">
          <dxo-label text="{{ 'LOOKUP_TABLE.FIELD.FORMAT' | translate }}" [visible]="model.propertyTypeId === 8"></dxo-label>
        </dxi-item>

        <dxi-item dataField="masterDataListId" editorType="dxSelectBox" 
          [editorOptions]="{ items: masterDataLists, valueExpr:'masterDataListId', displayExpr:getTranslation}">
          <dxo-label text="{{ 'LOOKUP_TABLE.FIELD.MASTER_DATA' | translate }}"></dxo-label>
        </dxi-item>

        <dxi-item dataField="sysName">
          <dxo-label text="{{ 'PRODUCT.PROPERTY.SYSTEM_NAME' | translate }}"></dxo-label>
        </dxi-item>

        <dxi-item dataField="isResult" editorType="dxCheckBox">
          <dxo-label text="{{ 'LOOKUP_TABLE.FIELD.RESULT_COLUMN' | translate }}"></dxo-label>
        </dxi-item>


      </dx-form>
      <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnLookupTableFieldSubmit"
        disabled="disabled">{{ 'SAVE' | translate }}</button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'CANCEL' | translate }}</button>
    </dx-scroll-view>
  </dx-popup>
</div>