import { Component, Injector, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { ClrTabs } from '@clr/angular';

// general services
import { Language } from '../../shared/models/language';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';
import notify from 'devextreme/ui/notify';
import { Subscription } from 'rxjs';

// services
import { AuctionClusterReportingRoleService } from '../shared/services/auction-cluster-reporting-role.service';
import { LanguageService } from '../../shared/services/language.service';

// models
import { AuctionClusterReportingRole } from '../../shared/models/auction-cluster-reporting-role';
import { ReportDesign, ReportLevels } from '../../shared/models/report';
import { DxFormComponent } from 'devextreme-angular';

@Component({
  selector: 'auction-cluster-reporting-role-component',
  templateUrl: 'auction-cluster-reporting-role.component.html',
  styleUrls: ['./auction-cluster-reporting-role.component.scss']
})
export class AuctionClusterReportingRoleComponent extends ItemDetailsComponent<AuctionClusterReportingRole> implements OnInit, OnDestroy {

  reportDesigns: Array<ReportDesign> = [];
  requiredReportDesigns: Array<number> = [];
  languages: Array<Language> = [];
  auctionClusterId: number;
  validFields: boolean = false;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  @ViewChild('tabs') tabs: ClrTabs;
  @ViewChild('roleName') roleName;
  @ViewChild('roleDescription') roleDescription;
  @ViewChild('reportingRoleForm', { read: DxFormComponent}) roleForm: DxFormComponent;

  constructor(
    protected injector: Injector,
    private dataService: AuctionClusterReportingRoleService,
    private languageService: LanguageService
  ) {
    super(injector);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.model = new AuctionClusterReportingRole();
    this.setTranslations('ROLE');
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  open(roles: Array<AuctionClusterReportingRole>, roleId: number, reportDesigns: Array<ReportDesign>,
    languages: Array<Language>, auctionClusterId: number) {
    this.allItems = roles;
    this.languages = languages;
    this.reportDesigns = reportDesigns.sort((a, b) => a.reportLevel - b.reportLevel).map(source => {

      let json = JSON.parse(source.name);

      for (var key in json) {
        if (json.hasOwnProperty(key)) {
          if (source.reportLevel === ReportLevels.AuctionCluster && !json[key].startsWith("(" + this.translations.AUCTIONCLUSTER + ")")) {
            json[key] = "(" + this.translations.AUCTIONCLUSTER + ") " + json[key];
          }
          if (source.reportLevel === ReportLevels.Buyer && !json[key].startsWith("(" + this.translations.BUYER + ")")) {
            json[key] = "(" + this.translations.BUYER + ") " + json[key];
          }
          if (source.reportLevel === ReportLevels.Supplier && !json[key].startsWith("(" + this.translations.SUPPLIER + ")")) {
            json[key] = "(" + this.translations.SUPPLIER + ") " + json[key];
          }
        }
      }
      source.name = JSON.stringify(json);
      return source;
    });
    this.requiredReportDesigns = [];
    this.auctionClusterId = auctionClusterId;

    // Autoselect first tab
    // this.tabs.ifActiveService.current = this.tabs.tabsService.children[0].id;

    if (roleId != null) {
      this.isEditMode = true;
      this.spinner.show();
      this.dataService.getAuctionClusterReportingRole(this.auctionClusterId, roleId)
        .subscribe((res: AuctionClusterReportingRole) => {
          this.model = res;
          this.model.name = this.languageService.updateStringToAllLanguages(languages, this.model.name);
          this.model.description = this.languageService.updateStringToAllLanguages(languages, this.model.description);
          this.validFields = this.languageService.checkDefaultLanguageNotEmpty(this.languages, this.model.name);
          this.requiredReportDesigns = this.model.reportDesigns;

          this.isOpened = true;
          this.spinner.hide();
        },
        error => {
          notify(error.message, 'error', 5000);
          this.spinner.hide();
        });
    } else {
      this.model = new AuctionClusterReportingRole();
      this.model.name = this.model.description = this.languageService.updateStringToAllLanguages(languages, "{}");
      this.isEditMode = false;
      this.isOpened = true;
    }

    // this.roleName.elementFocus();
  }

  save() {
    this.model.name = this.roleName.data;

    this.model.reportDesigns = this.requiredReportDesigns;

    if (!this.isUnique()) {
      return;
    }

    this.model.description = this.roleDescription.data;

    if (this.isEditMode) {
      this.spinner.show();
      this.dataService.edit(this.model)
        .subscribe((res: any) => {
          this.model = new AuctionClusterReportingRole();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
        error => {
          this.errorMessage = this.errorService.toString(error);
          notify(this.errorMessage, 'error', 5000);
          this.spinner.hide();
        });
    } else {
      this.spinner.show();

      this.model.auctionClusterId = this.auctionClusterId;

      this.dataService.save(this.model.auctionClusterId, this.model)
        .subscribe((res: any) => {
          this.model = new AuctionClusterReportingRole();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
        error => {
          this.errorMessage = this.errorService.toString(error);
          notify(this.errorMessage, 'error', 5000);
          this.spinner.hide();
        });
    }
  }

  private isUnique(): boolean {

    // create role list without current role
    const roleList = this.allItems.filter((r) => {
      return r.auctionClusterReportingRoleId !== this.model.auctionClusterReportingRoleId;
    });

    const nameNotUniqueList = roleList.filter((s) => {
      let name = this.languageService.updateStringToAllLanguages(this.languages, s.name);
      return !this.languageService.checkAllLanguageFieldsUnique(this.languages, name, this.model.name)
    });

    if (nameNotUniqueList.length > 0) {
      this.errorMessage = this.translations.NAME_NOT_UNIQUE;
      notify(this.errorMessage, 'error', 5000);
      return false;
    }

    return true;
  }

  onCancel() {
   this.roleName.resetLanguageButtons();
   this.roleDescription.resetLanguageButtons();
   super.onCancel();
  }

  onFieldDataChanged(e: any) {
    if ((e.component._isReady && e.component.NAME !== 'dxPopup') || e.component.NAME === 'dxForm') {
      const result = e.component.validate();
      if (result.brokenRules.length == 0 || this.validFields) {
        document.getElementsByName('btnRoleSubmit')[0].removeAttribute('disabled');
      } else {
        document.getElementsByName('btnRoleSubmit')[0].setAttribute('disabled', 'disabled');
      }
    } else {
      if (this.isEditMode && this.validFields) {
        document.getElementsByName('btnRoleSubmit')[0].removeAttribute('disabled');
      } else {
        document.getElementsByName('btnRoleSubmit')[0].setAttribute('disabled', 'disabled');
      }
    }
  }

  onChangeTranslationField = (e: any, fieldName: string) => {
    if (fieldName === 'name') {
      this.validFields = this.languageService.checkDefaultLanguageNotEmpty(this.languages, this.roleName.data);
      this.roleForm.instance.updateData(fieldName, this.roleName.data);
    } else if (fieldName === 'description') {
      this.roleForm.instance.updateData(fieldName, this.roleDescription.data);
    }
  }

  translateCheckBoxes = (item) => {
    if (item) {
      const label = this.getTranslation(item.name);
      return label;
    }
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }
}
