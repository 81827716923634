import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Sale } from '../models/sale';

import { ApplicationSettings } from '../../../shared/models/application-settings';
import { WebApiService } from '../../../shared/services/web-api.service';

@Injectable()
export class SaleService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'auctioncluster';
  }

  getSales(auctionClusterId: number): Observable<Array<Sale>> {
    return this.webApiService.getList(this.apiPath + '/' + auctionClusterId + '/auction/sale');
  }

  getAuctionSales(auctionId: number): Observable<Array<Sale>> {
    return this.webApiService.getList(this.apiPath + '/auction/' + auctionId + '/sale');
  }

  getSale(auctionId: number, saleId: number): Observable<Sale> {
    return this.webApiService.get(this.apiPath + '/auction/' + auctionId + '/sale', saleId);
  }

  save(auctionId: number, sale: Sale): Observable<any> {
    return this.webApiService.save<Sale>(this.apiPath + '/auction/' + auctionId + '/sale', sale);
  }

  edit(auctionId: number, sale: Sale): Observable<any> {
    return this.webApiService.edit<Sale>(this.apiPath + '/auction/' + auctionId + '/sale', sale.saleId, sale);
  }

  delete(auctionId: number, saleId: number): Observable<any> {
    return this.webApiService.delete(this.apiPath + '/auction/' + auctionId + '/sale', saleId);
  }

  startSale(saleId: number, auctionId: number): Observable<any> {
    return this.webApiService.editSingle<any>(this.apiPath + '/auction/' + auctionId + '/sale/' + saleId + '/start', null);
  }

  stopSale(saleId: number, auctionId: number): Observable<any> {
    return this.webApiService.editSingle<any>(this.apiPath + '/auction/' + auctionId + '/sale/' + saleId + '/stop', null);
  }

  getSaleState(saleId: number, auctionId: number): Observable<any> {
    return this.webApiService.getSingle(this.apiPath + '/auction/' + auctionId + '/sale/' + saleId + '/state');
  }
}
