import { Component, ViewChild, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { TermsAndConditionsService } from '../../shared/services/terms-and-conditions.service';
import { TermsAndConditions } from '../../shared/models/terms-and-conditions';

import { SpinnerService } from '../../shared/services/spinner.service';
import { ErrorService } from '../../shared/services/error.service';
import { AccepTermsAndConditionsComponent } from './accept-terms-and-conditions.component';
import { TitleService } from '../../shared/services/title.service';

@Component({
  selector: 'terms-and-conditions-component',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {

  model: TermsAndConditions = new TermsAndConditions();

  @ViewChild('accept', { static: true }) acceptComponent: AccepTermsAndConditionsComponent;

  constructor(
    private router: Router,
    private dataService: TermsAndConditionsService,
    protected route: ActivatedRoute,
    private spinner: SpinnerService,
    private errorService: ErrorService,
    private title: TitleService
  ) {
    this.title.set('TERMS_AND_CONDITIONS.TITLE');
  }

  ngOnInit() {
    // Get auctionId from url
    let auctionId = +this.route.snapshot.params['id'];

    // Display accept window
    this.acceptComponent.open(auctionId);
  }
}
