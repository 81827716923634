<div class="item-list-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <div class="grid-buttons" *ngIf="item && item.enableCreate">
    <dx-button hint="{{ 'ADD' | translate }}" icon="add" (onClick)="add()"></dx-button>
  </div>
  <br />
  <dx-data-grid [dataSource]="customDataSource" 
                [rtlEnabled]="rtlEnabled"
                #editorGrid
                id="printSection"
                [showBorders]="true"
                [allowColumnReordering]="true" 
                [hoverStateEnabled]="true"
                [columnAutoWidth]="true"
                [rowAlternationEnabled]="true"
                (onInitialized)="initColumn($event)" 
                (onRowClick)="openEditClick($event)">
    <dxo-selection mode="single"></dxo-selection>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    
    <dxo-remote-operations [paging]="true" [filtering]="true" [sorting]="true"></dxo-remote-operations> 
    <dxo-paging [pageSize]="20"></dxo-paging>  
    <dxo-pager [showPageSizeSelector]="true" 
               [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]"
               [showNavigationButtons]="true" 
               [showInfo]="true" 
               [visible]="true" 
               infoText="{2} {{ 'LOOKUP_TABLE.ROWS.PAGINATION_TEXT_PLACEHOLDER' | translate | slice:2 }}">
    </dxo-pager>
    
    <dxi-column type="buttons" [visible]="isEditEnabled() || isDeleteEnabled()">
      <dxi-button hint="{{ 'EDIT' | translate }}" icon="edit" [onClick]="editClick" [visible]="isEditEnabled"></dxi-button>
      <dxi-button hint="{{ 'DELETE' | translate }}" icon="trash" [onClick]="delete" [visible]="isDeleteEnabled"></dxi-button>
    </dxi-column>

    <div *dxTemplate="let data of 'imageCellTemplate'" class="image-column">
      <img *ngIf="data.value" [src]="data.value + '-thumbnail'" (click)="showLargerImage(data.value);"/>
    </div>

  </dx-data-grid>
</div>

<dx-popup [width]="'auto'" [height]="'auto'" [showTitle]="true" title="{{ 'LOOKUP_TABLE.ROWS.SHOW_LARGER_IMAGE' | translate }}" [dragEnabled]="false" [showCloseButton]="true" [(visible)]="popUpShowed" (onHiding)="closePopUp();">
    <img (load)="openPopUp();" [src]="largeImageSrc"/>
</dx-popup>

<lookup-table-row-editor-component #details
                                   (onClosed)="manualRefresh()"></lookup-table-row-editor-component>
<confirmation-component #confirmation title="{{ 'LOOKUP_TABLE.ROWS.DELETE_LOOKUP_TABLE_ROW' | translate }}"
                        message="{{ 'LOOKUP_TABLE.ROWS.DELETE_LOOKUP_TABLE_ROWS_MESSAGE' | translate }}"
                        buttonCloseText="DELETE" cancellable="true" (close)="deleteSelected();"></confirmation-component>
