<div class="auction-cluster-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup
  #addAuctionCluster
  [width]="600"
  [showTitle]="true"
  title="{{ modalTitle }}"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [rtlEnabled]="rtlEnabled"
  [(visible)]="isOpened"
  (onShown)="onFieldDataChanged($event)">
  <dx-scroll-view width="100%" height="100%">
    <dx-form
            #auctionClusterForm
            id="auctionClusterForm"
            validationGroup="auctionClusterData"
            [showValidationSummary]="false"
            (onFieldDataChanged)="onFieldDataChanged($event)"
            colCount="auto"
            [(formData)]="model"
            [minColWidth]="500">
            <dxi-item itemType="tabbed">
              <dxi-tab title="General">
                <dxi-item itemType="group">
                  <dxi-item dataField="name">
                    <dxo-label text="{{ 'AUCTIONS_CLUSTERS.NAME' | translate }}"></dxo-label>
                    <dxi-validation-rule type="required" message="{{ 'AUCTIONS_CLUSTERS.NAME_VALIDATION' | translate }}"></dxi-validation-rule>
                  </dxi-item>
                  <dxi-item 
                      dataField="currencyId"
                      editorType="dxSelectBox"
                      [editorOptions]="{ items: currencies, valueExpr:'currencyId' ,  
                      displayExpr:'shortName', searchEnabled: 'true', showClearButton: 'true'}">
                    <dxo-label text="{{ 'AUCTIONS_CLUSTERS.CURRENCY' | translate }}"></dxo-label>
                  </dxi-item>
                  <dxi-item 
                  dataField="currencyFactor" 
                  editorType="dxNumberBox"
                  [editorOptions]="{ 
                    showSpinButtons: true, 
                    mode: 'number', 
                    format: '#0.0000', 
                    min: 0,
                    step: 0.0001}"
                  >
                    <dxo-label text="{{ 'AUCTIONS_CLUSTERS.CURRENCYFACTOR' | translate }}"></dxo-label>
                  </dxi-item>
                  <dxi-item 
                      dataField="defaultUserProfileId"
                      editorType="dxSelectBox"
                      [editorOptions]="{ items: users, valueExpr:'userId' ,  
                      displayExpr: returnValues, searchEnabled: 'true', showClearButton: 'true'}">
                    <dxo-label text="{{ 'AUCTIONS_CLUSTERS.DEFAULT_PROFILE' | translate }}"></dxo-label>
                  </dxi-item>
                  <dxi-item 
                      dataField="defaultAuctioneerProfileId"
                      editorType="dxSelectBox"
                      [editorOptions]="{ items: users, valueExpr:'userId' ,  
                      displayExpr: returnValues, searchEnabled: 'true', showClearButton: 'true'}">
                    <dxo-label text="{{ 'AUCTIONS_CLUSTERS.DEFAULT_PROFILE_AUCTIONEER' | translate }}"></dxo-label>
                  </dxi-item>
                  <dxi-item 
                      dataField="supplierBuyerId"
                      editorType="dxSelectBox"
                      [editorOptions]="{ items: buyers, valueExpr:'buyerId' ,  
                      displayExpr: 'name', searchEnabled: 'true', showClearButton: 'true'}">
                    <dxo-label text="{{ 'AUCTIONS_CLUSTERS.SUPPLIER_BUYER_ID' | translate }}"></dxo-label>
                  </dxi-item>
                  <dxi-item 
                      dataField="financiallyResponsible"
                      editorType="dxSelectBox"
                      [editorOptions]="{ items: financiallyResponsible, valueExpr:'value' ,  
                      displayExpr: 'name', searchEnabled: 'true', showClearButton: 'true'}">
                    <dxo-label text="{{ 'AUCTIONS_CLUSTERS.FINANCIALLY_RESPONSIBLE' | translate }}"></dxo-label>
                  </dxi-item>
                  <dxi-item dataField="webServiceUrl">
                    <dxo-label text="{{ 'AUCTIONS_CLUSTERS.WEBSERVICE_URL' | translate }}"></dxo-label>
                  </dxi-item>
                  <dxi-item dataField="auctionClusterLanguageIds" [template]="'auctionClusterLanguageTemplate'">
                    <dxo-label text="{{ 'AUCTIONS_CLUSTERS.LANGUAGES' | translate }}"></dxo-label>
                  </dxi-item>
                  <dxi-item
                      dataField="auctionClusterDefaultLanguage"
                      editorType="dxSelectBox"
                      [editorOptions]="{ items: auctionClusterLanguages, valueExpr:'languageId',  
                      displayExpr: translateSelectBoxes, searchEnabled: 'true', showClearButton: 'true'}">
                    <dxo-label text="{{ 'AUCTIONS_CLUSTERS.DEFAULT_LANGUAGE' | translate }}"></dxo-label>
                  </dxi-item>
                  <dxi-item dataField="logo" [template]="'auctionClusterLogoTemplate'">
                    <dxo-label text="{{ 'AUCTIONS_CLUSTERS.LOGO' | translate }}"></dxo-label>
                  </dxi-item>
                  <dxi-item 
                  dataField="userLicenses" 
                  editorType="dxNumberBox"
                  [editorOptions]="{ 
                    showSpinButtons: true, 
                    mode: 'number', 
                    format: '#0', 
                    min: 0,
                    step: 1}"
                  ></dxi-item>
                  <dxi-item dataField="mfaTypeId" editorType="dxSelectBox"
                  [editorOptions]="{ items: mfaTypes, valueExpr:'mfaTypeId', displayExpr: 'name'}">
                    <dxo-label text="{{ 'AUCTIONS_CLUSTERS.MFA' | translate }}"></dxo-label>
                  </dxi-item>
                  <dxi-item dataField="timeZone" editorType="dxSelectBox"
                    [editorOptions]="{ items: timeZones, valueExpr:'timeZoneInfoId',
                            displayExpr: 'displayName', searchEnabled: 'true' }">
                    <dxo-label text="{{ 'AUCTIONS_CLUSTERS.TIME_ZONE' | translate }}"></dxo-label>
                  </dxi-item>
                  <dxi-item dataField="secondRestrictionMasterDataId" editorType="dxSelectBox"
                  [editorOptions]="{ items: auctionClusterMasterData, valueExpr:'masterDataListId', displayExpr: 'name',
                                    searchEnabled: 'true', showClearButton: 'true'}">
                    <dxo-label text="{{ 'AUCTIONS_CLUSTERS.SECOND_RESTRICTION_MASTER_DATA' | translate }}"></dxo-label>
                  </dxi-item>
                  <dxi-item dataField="code">
                    <dxo-label text="{{ 'AUCTIONS_CLUSTERS.CODE' | translate }}"></dxo-label>
                  </dxi-item>
                </dxi-item>
                <dxi-item itemType="group" [(visible)]="!isEditMode">
                  <dxi-item dataField="assignAdminUser" [template]="'assignAdminUserTemplate'">
                    <dxo-label text="{{ 'AUCTIONS_CLUSTERS.ADMIN_USER_LABEL' | translate }}"></dxo-label>
                  </dxi-item>
                  <dxi-item 
                      dataField="administratorId"
                      editorType="dxSelectBox"
                      [editorOptions]="{ items: users, valueExpr:'userId' ,  
                      displayExpr: returnValues, searchEnabled: 'true', showClearButton: 'true', disabled: !assignAdminUser}">
                    <dxo-label [visible]="false"></dxo-label>
                  </dxi-item>
                </dxi-item>
              </dxi-tab>
              <dxi-tab title="Audio">
                <dxi-item dataField="sampleRate"
                          editorType="dxNumberBox"
                          [editorOptions]="{ showSpinButtons: true, mode: 'number', format: '#0', min: 0, step: 1}">
                  <dxo-label text="{{ 'AUCTIONS_CLUSTERS.SAMPLE_RATE' | translate }}"></dxo-label>
                </dxi-item>
                <dxi-item dataField="channelCount"
                          editorType="dxNumberBox"
                          [editorOptions]="{ showSpinButtons: true, mode: 'number', format: '#0', min: 0, step: 1}">
                  <dxo-label text="{{ 'AUCTIONS_CLUSTERS.CHANNEL_COUNT' | translate }}"></dxo-label>
                </dxi-item>
                <dxi-item dataField="bitRate"
                          editorType="dxNumberBox"
                          [editorOptions]="{ showSpinButtons: true, mode: 'number', format: '#0', min: 0, step: 1}">
                  <dxo-label text="{{ 'AUCTIONS_CLUSTERS.BIT_RATE' | translate }}"></dxo-label>
                </dxi-item>
                <dxi-item dataField="minimumBufferSize"
                          editorType="dxNumberBox"
                          [editorOptions]="{ showSpinButtons: true, mode: 'number', format: '#0', min: 0, step: 1}">
                  <dxo-label text="{{ 'AUCTIONS_CLUSTERS.MINIMUM_BUFFER_SIZE' | translate }}"></dxo-label>
                </dxi-item>
                <dxi-item dataField="maximumBufferSize"
                          editorType="dxNumberBox"
                          [editorOptions]="{ showSpinButtons: true, mode: 'number', format: '#0', min: 0, step: 1}">
                  <dxo-label text="{{ 'AUCTIONS_CLUSTERS.MAXIMUM_BUFFER_SIZE' | translate }}"></dxo-label>
                </dxi-item>
                <dxi-item dataField="frameDuration"
                          editorType="dxNumberBox"
                          [editorOptions]="{ showSpinButtons: true, mode: 'number', format: '#0', min: 0, step: 1}">
                  <dxo-label text="{{ 'AUCTIONS_CLUSTERS.FRAME_DURATION' | translate }}"></dxo-label>
                </dxi-item>
              </dxi-tab>
              <dxi-tab title="Notifications" [disabled]="!isEditMode">
                <dxi-item itemType="group" caption="{{ 'NOTIFICATION.NOTIFICATIONS' | translate }}">
                  <dx-data-grid #auctionClusterNotificationsGrid
                                [rtlEnabled]="rtlEnabled"
                                [dataSource]="notifications"
                                [showBorders]="true"
                                (onCellClick)="editNotification($event)"
                                [allowColumnReordering]="true"
                                [hoverStateEnabled]="true"
                                [rowAlternationEnabled]="true">
                    <dxo-selection mode="single"></dxo-selection>

                    <dxi-column [width]="75" dataType="boolean" dataField="isEnabled" caption="{{ 'NOTIFICATION.ACTIVE' | translate }}">
                    </dxi-column>
                    <dxi-column dataType="string" dataField="defaultName" caption="{{ 'NOTIFICATION.NOTIFICATION_TYPE' | translate }}">
                    </dxi-column>
                    <dxi-column dataType="string" dataField="nameText" caption="{{ 'NOTIFICATION.NAME' | translate }}">
                    </dxi-column>
                    <dxi-column type="buttons">
                      <dxi-button hint="{{ 'EDIT' | translate }}" icon="edit" [onClick]="editNotification"></dxi-button>
                    </dxi-column>
                  </dx-data-grid>
                </dxi-item>

                <dxi-item itemType="group" caption="{{ 'NOTIFICATION.CHANNELS' | translate }}">
                  <dx-data-grid #auctionClusterChannelsGrid
                                [rtlEnabled]="rtlEnabled"
                                [dataSource]="channels"
                                [showBorders]="true"
                                (onCellClick)="editChannel($event)"
                                [allowColumnReordering]="true"
                                [hoverStateEnabled]="true"
                                [columnAutoWidth]="true"
                                [rowAlternationEnabled]="true">
                    <dxo-selection mode="single"></dxo-selection>

                    <dxi-column [width]="75" dataType="boolean" dataField="isActive" caption="{{ 'NOTIFICATION.ACTIVE' | translate }}">
                    </dxi-column>
                    <dxi-column dataType="string" dataField="defaultName" caption="{{ 'NOTIFICATION.CHANNEL_TYPE' | translate }}">
                    </dxi-column>
                    <dxi-column dataType="string" dataField="nameText" caption="{{ 'NOTIFICATION.NAME' | translate }}">
                    </dxi-column>

                    <dxi-column type="buttons">
                      <dxi-button hint="{{ 'EDIT' | translate }}" icon="edit" [onClick]="editChannel"></dxi-button>
                    </dxi-column>
                  </dx-data-grid>
                </dxi-item>
              </dxi-tab>
            </dxi-item>

            <div *dxTemplate="let data of 'assignAdminUserTemplate'">
              <dx-check-box [(value)]="assignAdminUser"></dx-check-box>
            </div>

            <div *dxTemplate="let data of 'auctionClusterLanguageTemplate'">
              <dx-tag-box
                  [dataSource]="languages"
                  valueExpr="languageId"
                  displayExpr="name"
                  searchEnabled="true"
                  (onValueChanged)="onACLanguagesChange($event)"
                  [(value)]="auctionClusterLanguageIds">
              </dx-tag-box>
            </div>



            <div *dxTemplate="let data of 'auctionClusterLogoTemplate'">
              <input type="file" (change)="fileChangeEvent($event)" #inputCropper />

              <image-cropper [imageChangedEvent]="imageChangedEvent"
                             [maintainAspectRatio]="true"
                             [aspectRatio]="7 / 3"
                             [resizeToWidth]="224"
                             format="png"
                             (imageCropped)="imageCropped($event)"
                             (imageLoaded)="imageLoaded()"
                             (cropperReady)="cropperReady()"
                             (loadImageFailed)="loadImageFailed()"></image-cropper>

              <img [src]="croppedImage" />
            </div>

    </dx-form>
    <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnAuctionClusterSubmit" disabled="disabled">
      {{ 'SAVE' | translate }}
    </button>
    <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'CANCEL' | translate }}</button>
  </dx-scroll-view>
</dx-popup>
</div>

<auction-cluster-notification-component (onUpdated)="translateChannelAndNotificationLists()" (onCancelled)="getData()"></auction-cluster-notification-component>
<auction-cluster-channel-component (onUpdated)="translateChannelAndNotificationLists()" (onCancelled)="getData()"></auction-cluster-channel-component>
