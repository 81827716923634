import { Component, OnInit, Injector, OnDestroy, ViewChild } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import { ClrDatagridComparatorInterface } from "@clr/angular";

// general services
import { LanguageService } from '../../shared/services/language.service';


// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { MasterDataListComponent } from './master-data-list.component';

// services
import { MasterDataService } from '../shared/services/master-data.service';

// models
import { Language } from '../../shared/models/language';
import { MasterData } from '../shared/models/master-data';
import { ImportSchema, ImportField } from '../../shared/import/import-schema';

import notify from 'devextreme/ui/notify';

class NameComparator implements ClrDatagridComparatorInterface<MasterData>{
  compare(a: MasterData, b: MasterData) {
    return a.masterDataNameText > b.masterDataNameText ? 1 : -1;
  }
}

class DescriptionComparator implements ClrDatagridComparatorInterface<MasterData>{
  compare(a: MasterData, b: MasterData) {
    return a.masterDataDescriptionText > b.masterDataDescriptionText ? 1 : -1;
  }
}

@Component({
  selector: 'master-data-lists-component',
  templateUrl: 'master-data-lists.component.html',
  styleUrls: ['./master-data-lists.component.scss']
})
export class MasterDataListsComponent extends FullListComponent<MasterData, MasterDataListComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: MasterDataListComponent;

  languages: Array<Language> = [];
  nameComparator = new NameComparator();
  descriptionComparator = new DescriptionComparator();

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private dataService: MasterDataService
  ) {
    super(injector, MasterData);
    this.title.set('MASTER_DATA.TITLE');
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.setTranslations('MASTER_DATA');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getData() {
    this.spinner.show();
    forkJoin(
      this.dataService.getMasterDataLists(this.id),
      this.languageService.getAuctionClusterLanguages(this.id)
    ).subscribe(result => {
      this.items = result[0];
      this.languages = result[1];
      this.translateMasterDataLists();
      this.spinner.hide();
    },
      error => {
        notify(error.message, 'error', 5000);
        this.spinner.hide();
      });
  }

  edit = (e: any) => {
    this.detailsComponent.modalTitle = this.translations.EDIT;
    this.detailsComponent.open(this.items, e.row.data.masterDataListId, this.languages);
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.ADD_NEW;
    this.detailsComponent.open(this.items, null, this.languages);
  }

  translateMasterDataLists() {
    this.items.forEach(masterData => {
      masterData.masterDataNameText = this.languageService.getTranslatableText(masterData.name);
      masterData.masterDataDescriptionText = this.languageService.getTranslatableText(masterData.description);
    });
  }

  openMasterDataListEditor = (e: any) => {
    this.router.navigate(['/auction/masterdata/' + this.id + '/masterdatalist/' + e.row.data.masterDataListId]);
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.masterDataListId;
    this.confirmation.opened = true;
  }

  openMasterDataListEditorClick = (e: any) => {
    this.router.navigate(['/auction/masterdata/' + this.id + '/masterdatalist/' + e.data.masterDataListId]);
  }


  deleteSelected() {
    this.spinner.show();
    this.dataService.delete(this.itemIdToDelete, this.id)
      .subscribe((users: Array<MasterData>) => {
        this.getData();
        this.spinner.hide();
      },
      error => {
        notify(this.errorService.translations.DELETE_ERROR_MESSAGE, 'error', 5000);
        this.spinner.hide();
      });
  }

  createDataSchema() {

    const schema = new ImportSchema();

    const masterDataName = new ImportField('masterDataNameText', this.translations.NAME);
    masterDataName.required = true;

    const masterDataDescription = new ImportField('masterDataDescriptionText', this.translations.DESCRIPTION);
    masterDataDescription.required = true;

    schema.fields.push(masterDataName, masterDataDescription
    );

    this.schema = schema;
  }
}
