<div [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup #saveConfirmation
            height="25%"
            width="30%"
            [visible]="opened"
            [showTitle]="true"
            title="{{ title }}"
            [dragEnabled]="false"
            [closeOnOutsideClick]="false"
            [showCloseButton]="false"
            [rtlEnabled]="rtlEnabled"
            (onHiding)="leavePage(false);">
    <dx-scroll-view width="100%" height="100%">
      <p>{{ message }}</p>
      <br /><br />
      <button *ngIf="cancellable" type="button" class="btn btn-outline"
        (click)="leavePage(false);">{{ 'CANCEL' | translate }}</button>
      <button type="button" class="btn btn-primary"
        (click)="leavePage(true);">{{ buttonCloseText | translate }}</button>
    </dx-scroll-view>
  </dx-popup>
</div>
