<div class="translatable-field-component" [ngClass]="{'inside-lot-editor' : insideLotEditor}">
  <div *ngFor="let item of items; let i=index" [class.hidden]="!items[i].isSelected">
    <!-- Text input -->
    <div *ngIf="type==='text'">
      <div class="" [ngClass]="{ 'clr-error': lang.dirty && lang.invalid && textRequired }">
        <ng-container *ngIf="!isLabelHidden">
          <label for="{{name}}-lang-{{componentIndex}}-{{i}}" *ngIf="caption !== ''" class="clr-control-label" [class.required]="textRequired">{{ caption | translate }}</label>
        </ng-container>
        <div class="clr-control-container">
          <div class="clr-input-wrapper">
            <input class="clr-input" [required]="textRequired" #textinput type="text" (onFocus)="onFocused()" [ngClass]="{'focusable': isFocusable && i == 0}" (focus)="elementFocus()" name="{{name}}-lang-{{componentIndex}}-{{i}}" id="{{name}}-lang-{{componentIndex}}-{{i}}" placeholder="{{ caption | translate }}" [disabled]="isDisabled" [(ngModel)]="items[i].value" (ngModelChange)="valueChanged.emit(true);" #lang="ngModel" />
          </div>
          <clr-control-error *ngIf="lang.dirty && lang.invalid && textRequired">{{ 'REQUIRED_FIELD_VALIDATION' | translate }}</clr-control-error>
        </div>
      </div>
    </div>

    <!-- Textarea input -->
    <div *ngIf="type==='textbox'">
      <div class="" [ngClass]="{ 'clr-error': lang.dirty && lang.invalid && textRequired }">
        <ng-container *ngIf="!isLabelHidden">
          <label for="{{name}}-lang-{{componentIndex}}-{{i}}" *ngIf="caption !== ''" class="clr-control-label" [class.required]="textRequired">{{ caption | translate }}</label>
        </ng-container>
        <div class="clr-control-container">
          <div class="clr-textarea-wrapper">
            <textarea class="clr-textarea" [required]="textRequired" #textbox rows="4" name="{{name}}-lang-{{componentIndex}}-{{i}}" (onFocus)="onFocused()" id="{{name}}-lang-{{componentIndex}}-{{i}}" placeholder="{{ caption | translate }}" [disabled]="isDisabled" [(ngModel)]="items[i].value" #lang="ngModel"></textarea>
          </div>
          <clr-control-error *ngIf="lang.dirty && lang.invalid && textRequired">{{ 'REQUIRED_FIELD_VALIDATION' | translate }}</clr-control-error>
        </div>
      </div>
    </div>

    <!-- HTML editor -->
    <dx-html-editor *ngIf="type=='htmlEditor'" height="480px" [(value)]="items[i].value" [swipeEnabled]="false">
      <dxo-toolbar>
        <dxi-item name="undo" locateInMenu="never"></dxi-item>
        <dxi-item name="redo" locateInMenu="never"></dxi-item>
        <dxi-item name="separator" locateInMenu="never"></dxi-item>
        <dxi-item name="size"
                  [options]="sizeFormatOptions"
                  [formatValues]="sizeFormatValues"
                  locateInMenu="never"></dxi-item>
        <dxi-item name="font"
                  [options]="fontFormatOptions"
                  [formatValues]="fontFormatValues"
                  locateInMenu="never"></dxi-item>
        <dxi-item name="separator" locateInMenu="never"></dxi-item>
        <dxi-item name="alignLeft" locateInMenu="never"></dxi-item>
        <dxi-item name="alignCenter" locateInMenu="never"></dxi-item>
        <dxi-item name="alignRight" locateInMenu="never"></dxi-item>
        <dxi-item name="alignJustify" locateInMenu="never"></dxi-item>
        <dxi-item name="separator" locateInMenu="never"></dxi-item>
        <dxi-item name="bold" locateInMenu="never"></dxi-item>
        <dxi-item name="italic" locateInMenu="never"></dxi-item>
        <dxi-item name="strike" locateInMenu="never"></dxi-item>
        <dxi-item name="underline" locateInMenu="never"></dxi-item>
        <dxi-item name="separator" locateInMenu="never"></dxi-item>
        <dxi-item name="orderedList" locateInMenu="never"></dxi-item>
        <dxi-item name="bulletList" locateInMenu="never"></dxi-item>
        <dxi-item name="separator" locateInMenu="never"></dxi-item>
        <dxi-item name="color" locateInMenu="always"></dxi-item>
        <dxi-item name="background" locateInMenu="always"></dxi-item>
        <dxi-item name="separator" locateInMenu="always"></dxi-item>
        <dxi-item name="link" locateInMenu="always"></dxi-item>
        <dxi-item name="image" locateInMenu="always"></dxi-item>
        <dxi-item name="separator" locateInMenu="always"></dxi-item>
        <dxi-item name="clear" locateInMenu="always"></dxi-item>
        <dxi-item name="codeBlock" locateInMenu="always"></dxi-item>
        <dxi-item name="blockquote" locateInMenu="always"></dxi-item>
      </dxo-toolbar>
    </dx-html-editor>
  </div>

  <div *ngIf="isDevExpress && type=='htmlEditor'">
    <br /><br />
  </div>

  <div class="selector-buttons">
    <div class="btn-group btn-sm langs-label-holder">
      <div class="radio btn" *ngFor="let item of items; let j=index" [class.invalid]="item.value == ''">
        <input type="radio" [name]="item.id" [id]="item.id" [checked]="item.isSelected" [value]="item.isSelected" [disabled]="isDisabled" (change)="changeLanguage(j)">
        <label [for]="item.id">{{ item.languageText | translate }}</label>
      </div>
    </div>
  </div>
</div>
