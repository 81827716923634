<header class="header header-1 header-component">
  <div class="branding">
    <span class="title">{{title?.text?.value}}</span>
  </div>
  <div class="header-actions">
    <clr-dropdown class="dropdown bottom-right">
      <button class="nav-icon" clrDropdownToggle (click)="login()">
        <div>{{ 'HEADER.LOGIN' | translate }}</div>
        <clr-icon shape="user"></clr-icon>
      </button>
    </clr-dropdown>

    <clr-dropdown class="dropdown bottom-right">
      <button class="nav-icon" clrDropdownToggle (click)="register()">
        <div>{{ 'HEADER.REGISTER' | translate }}</div>
        <clr-icon shape="key"></clr-icon>
      </button>
    </clr-dropdown>

    <clr-dropdown class="dropdown bottom-right" [clrCloseMenuOnItemClick]="true">
      <button class="nav-icon" clrDropdownToggle>
        <div>{{ currentLanguage.name | translate }}</div>
        <clr-icon shape="caret down"></clr-icon>
      </button>
      <clr-dropdown-menu>
        <div *ngFor="let l of languages;">
          <button clrDropdownItem type="submit" class="btn btn-link" [class.active]="currentLanguage.code == l.code" (click)="setLanguage(l.code)">
            {{ l.name }}
          </button>
        </div>
      </clr-dropdown-menu>
    </clr-dropdown>
  </div>
</header>
