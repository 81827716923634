import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApplicationSettings } from '../../../shared/models/application-settings';
import { AuctionClusterRole } from '../../../shared/models/auction-cluster-role';

import { WebApiService } from '../../../shared/services/web-api.service';

@Injectable()
export class AuctionClusterLevelRoleService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'auctioncluster';
  }

  getAuctionClusterRoles(auctionClusterId: number): Observable<Array<AuctionClusterRole>> {
    return this.webApiService.getList(this.apiPath + '/' + auctionClusterId + '/auctionclusterrole');
  }

  getAuctionClusterRole(auctionClusterId: number, userId: number): Observable<AuctionClusterRole> {
    return this.webApiService.get(this.apiPath + '/' + auctionClusterId + '/auctionclusterrole', userId);
  }

  save(auctionClusterId: number, auctionClusterRole: AuctionClusterRole): Observable<any> {
    return this.webApiService.save<AuctionClusterRole>(this.apiPath + '/' + auctionClusterId + '/auctionclusterrole', auctionClusterRole);
  }

  edit(auctionClusterRole: AuctionClusterRole): Observable<any> {
    return this.webApiService.edit<AuctionClusterRole>(this.apiPath + '/' + auctionClusterRole.auctionClusterId
      + '/auctionclusterrole', auctionClusterRole.auctionClusterRoleId, auctionClusterRole);
  }

  delete(auctionClusterId: number, auctionClusterRoleId: number): Observable<any> {
    return this.webApiService.delete(this.apiPath + '/' + auctionClusterId + '/auctionclusterrole', auctionClusterRoleId);
  }

  checkIfRoleHasUsers(auctionClusterId: number, roleId: number): Observable<Array<number>> {
    return this.webApiService.getList(this.apiPath + '/' + auctionClusterId + '/auctionclusterrole/' + roleId + '/checkusers');
  }
}
