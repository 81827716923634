<div class="item-list-component">

    <div class="grid-buttons">
      <clr-tooltip>
        <clr-icon shape="add" clrTooltipTrigger size="24" (click)="add()"></clr-icon>
        <clr-tooltip-content class="tooltip-content" clrPosition="top-left" clrSize="sm">
          <span>{{ 'ADD' | translate }}</span>
        </clr-tooltip-content>
      </clr-tooltip>
      <clr-tooltip>
        <search-box clrTooltipTrigger (update)="searchText = $event"></search-box>
        <clr-tooltip-content class="tooltip-content" clrPosition="top-left" clrSize="sm">
          <span>{{ 'SEARCH' | translate }}</span>
        </clr-tooltip-content>
      </clr-tooltip>
      <clr-tooltip>
        <clr-icon shape="printer" clrTooltipTrigger size="24" (click)="print();"></clr-icon>
        <clr-tooltip-content class="tooltip-content" clrPosition="top-left" clrSize="sm">
          <span>{{ 'PRINT' | translate }}</span>
        </clr-tooltip-content>
      </clr-tooltip>
    </div>
  
    <clr-datagrid id="printSection">
      <clr-dg-column [clrDgField]="'name'">{{ 'PREBID_ON_PRODUCT.NAME' | translate }}</clr-dg-column>
      <clr-dg-column [clrDgField]="'description'">{{ 'PREBID_ON_PRODUCT.DESCRIPTION' | translate }}</clr-dg-column>
      <clr-dg-column class="no-print three-buttons-column">&nbsp;</clr-dg-column>
  
      <clr-dg-placeholder>{{'NO_DATA' | translate }}</clr-dg-placeholder>
  
      <clr-dg-row *clrDgItems="let item of items | search: searchText" (click)="edit(item.catalogId, $event)">
        <clr-dg-cell>{{item.prebidNameText}}</clr-dg-cell>
        <clr-dg-cell>{{item.prebidDescriptionText}}</clr-dg-cell>
        <clr-dg-cell class="no-print three-buttons-cell">
  
          <clr-tooltip role="tooltip" aria-haspopup="true">
            <clr-icon clrTooltipTrigger shape="list" class="edit-btn" size="16" (click)="openPrebidEditor(item.catalogId, $event)"></clr-icon>
            <clr-tooltip-content class="tooltip-content" clrPosition="top-left" clrSize="sm">
              <span>{{ 'PREBID_ON_PRODUCT.OPEN_PREBID_ON_PRODUCT_LIST_EDITOR' | translate }}</span>
            </clr-tooltip-content>
          </clr-tooltip>
  
          <clr-tooltip role="tooltip" aria-haspopup="true">
            <clr-icon clrTooltipTrigger shape="edit" class="edit-btn" size="16" (click)="edit(item.catalogId, $event)"></clr-icon>
            <clr-tooltip-content class="tooltip-content" clrPosition="top-left" clrSize="sm">
              <span>{{ 'EDIT' | translate }}</span>
            </clr-tooltip-content>
          </clr-tooltip>
  
          <clr-tooltip role="tooltip" aria-haspopup="true">
            <clr-icon clrTooltipTrigger shape="trash" class="delete-btn" size="16" (click)="delete(item.catalogId, $event)"></clr-icon>
            <clr-tooltip-content class="tooltip-content" clrPosition="top-left" clrSize="sm">
              <span>{{ 'DELETE' | translate }}</span>
            </clr-tooltip-content>
          </clr-tooltip>
        </clr-dg-cell>
      </clr-dg-row>
  
      <clr-dg-footer>
        <pagination-component class="footer-pagination" tootltip-content="{{ 'SET_PAGE_SIZE' | translate }}" [pageSizeValue]="pageSizeValue" (changed)="setPageSize($event)"></pagination-component>
        <span class="pagination-fixed footer-pagination">
          <span *ngIf="items?.length > 0">{{ 'PREBID_ON_PRODUCT.PAGINATION_TEXT' | translate:{firstItem: pagination.firstItem + 1, lastItem: pagination.lastItem + 1, totalItems: pagination.totalItems} }}</span>
          <span *ngIf="items?.length == 0">{{ 'PREBID_ON_PRODUCT.PAGINATION_TEXT_PLACEHOLDER' | translate:{totalItems: pagination.totalItems} }}</span>
          <clr-dg-pagination #pagination [clrDgPageSize]="pageSize"></clr-dg-pagination>
        </span>
      </clr-dg-footer>
    </clr-datagrid>
  </div>
  
  <buyer-prebid-component #details (onClosed)="onDetailsComponentClosed($event)"></buyer-prebid-component>
  <confirmation-component #confirmation title="{{ 'PREBID_ON_PRODUCT.DELETE_PREBID_ON_PRODUCT' | translate }}" message="{{ 'PREBID_ON_PRODUCT.DELETE_PREBID_ON_PRODUCT_MESSAGE' | translate }}" buttonCloseText="DELETE" cancellable="true" (close)="deleteSelected();"></confirmation-component>
  
  <!--<import-component (process)="importData($event)" (refresh)="getData()"></import-component>-->
