<div class="product-editor-component">
  <form #detailsForm="ngForm" class="clr-form clr-form-horizontal">
    <clr-tabs #tabs>
      <clr-tab>
        <button clrTabLink title="{{ 'PRODUCT.GENERAL' | translate }}">{{ 'PRODUCT.GENERAL' | translate }}</button>
        <clr-tab-content>
          <div class="tab-content product-form">

            <div class="clr-form-control">
              <label class="clr-control-label">{{ 'PRODUCT.NAME' | translate }}</label>
              <div class="clr-control-container">
                <translatable-field [(data)]="model.name" [name]="'name'" [clusterLanguages]="languages" #productName></translatable-field>
              </div>
            </div>

            <div class="clr-form-control">
              <label class="clr-control-label">{{ 'PRODUCT.DESCRIPTION' | translate }}</label>
              <div class="clr-control-container">
                <translatable-field [type]="'textbox'" [name]="'description'" [(data)]="model.description" [clusterLanguages]="languages" #productDescription></translatable-field>
              </div>
            </div>

            <div class="clr-form-control">
              <label for="propertyIdToConcatenateInsideClock" class="clr-control-label">{{ 'PRODUCT.PROPERTY.PROPERTY_TO_CONCATENATE' | translate }}</label>
              <div class="clr-control-container">
                <div class="clr-select-wrapper">
                  <select id="propertyIdToConcatenateInsideClock" name="propertyIdToConcatenateInsideClock" [(ngModel)]="model.propertyIdToConcatenateInsideClock">
                    <option></option>
                    <option *ngFor="let field of model.productProperties" [ngValue]="field.productPropertyId">{{ field.name | translateJson }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="clr-form-control">
              <label for="propertyIdToConcatenateInsideClockGrouping" class="clr-control-label">{{ 'PRODUCT.PROPERTY.AUTOMATIC' | translate }}</label>
              <div class="clr-control-container">
                <div class="clr-select-wrapper">
                  <select id="propertyIdToConcatenateInsideClockGrouping" name="propertyIdToConcatenateInsideClockGrouping" [(ngModel)]="model.propertyToConcatenateGroupingSelectionId">
                    <option></option>
                    <option *ngFor="let field of getConcatenateGroupings()" [ngValue]="field.productPropertyGroupingSelectionId">{{ field.actionLabel }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="clr-form-control">
              <label for="propertyToConcatenateManualGroupingSelection" class="clr-control-label">{{ 'PRODUCT.PROPERTY.MANUAL' | translate }}</label>
              <div class="clr-control-container">
                <div class="clr-select-wrapper">
                  <select id="propertyToConcatenateManualGroupingSelection" name="propertyToConcatenateManualGroupingSelection" [(ngModel)]="model.propertyToConcatenateManualGroupingSelectionId">
                    <option></option>
                    <option *ngFor="let field of getConcatenateGroupingsManual()" [ngValue]="field.productPropertyGroupingSelectionId">{{ field.actionLabel }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="clr-form-control">
              <label for="propertyUsedForRestFieldId" class="clr-control-label">{{ 'PRODUCT.PROPERTY.PROPERTY_USED_FOR_REST_FIELD' | translate }}</label>
              <div class="clr-control-container">
                <div class="clr-select-wrapper">
                  <select id="propertyUsedForRestFieldId" name="propertyUsedForRestFieldId" [(ngModel)]="model.propertyUsedForRestFieldId">
                    <option></option>
                    <option *ngFor="let field of getNumericProperties()" [ngValue]="field.productPropertyId">{{ field.name | translateJson }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="clr-form-control">
              <label for="propertyUsedForTransactionInfoFieldId" class="clr-control-label">{{ 'PRODUCT.PROPERTY.PROPERTY_USED_FOR_TRANSACTION_INFO_FIELD' | translate }}</label>
              <div class="clr-control-container">
                <div class="clr-select-wrapper">
                  <select id="propertyUsedForTransactionInfoFieldId" name="propertyUsedForTransactionInfoFieldId" [(ngModel)]="model.propertyUsedForTransactionInfoFieldId">
                    <option></option>
                    <option *ngFor="let field of model.productProperties" [ngValue]="field.productPropertyId">{{ field.name | translateJson }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="clr-form-control">
              <label for="propertyUsedForTotalFieldId" class="clr-control-label">{{ 'PRODUCT.PROPERTY.PROPERTY_USED_FOR_TOTAL_FIELD' | translate }}</label>
              <div class="clr-control-container">
                <div class="clr-select-wrapper">
                  <select id="propertyUsedForTotalFieldId" name="propertyUsedForTotalFieldId" [(ngModel)]="model.propertyUsedForTotalFieldId">
                    <option></option>
                    <option *ngFor="let field of getNumericProperties()" [ngValue]="field.productPropertyId">{{ field.name | translateJson }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="clr-form-control">
              <label for="startPriceLookup" class="clr-control-label">{{ 'PRODUCT.PROPERTY.START_PRICE_LOOKUP' | translate }}</label>
              <div class="clr-control-container">
                <div class="clr-select-wrapper">
                  <select id="startPriceLookup" name="startPriceLookup" [(ngModel)]="model.startPriceLookupId">
                    <option></option>
                    <option *ngFor="let field of priceLookups" [ngValue]="field.lookupTableId">Lo{{ field.name | translateJson }}</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="clr-form-control">
              <label for="startPriceBehaviour" class="clr-control-label">{{ 'PRODUCT.PROPERTY.START_PRICE_BEHAVIOUR' | translate }}</label>
              <div class="clr-control-container">
                <div class="clr-select-wrapper">
                  <select id="startPriceBehaviour" name="startPriceBehaviour" [(ngModel)]="model.startPriceBehaviour">
                    <option *ngFor="let item of startPriceBehaviours" [ngValue]="item.value">{{ 'PRODUCT.PROPERTY.' + item.name | translate }}</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="clr-form-control">
              <label for="lotGroupMinimumPriceBehaviour" class="clr-control-label">{{ 'PRODUCT.PROPERTY.LOT_GROUP_MINIMUM_PRICE_BEHAVIOUR' | translate }}</label>
              <div class="clr-control-container">
                <div class="clr-select-wrapper">
                  <select id="lotGroupMinimumPriceBehaviour" name="lotGroupMinimumPriceBehaviour" [(ngModel)]="model.lotGroupMinimumPriceBehaviour">
                    <option *ngFor="let item of lotGroupMinimumPriceBehaviours" [ngValue]="item.value">{{ 'PRODUCT.PROPERTY.' + item.name | translate }}</option>
                  </select>
                </div>
              </div>
            </div>
            <!--<section class="form-block">
    <div class="form-group price-unit">
      <label class="required">{{ 'PRODUCT.PRICE_UNIT' | translate }}</label>
      <label for="priceUnitAmount" aria-haspopup="true" role="tooltip" class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm" [ngClass]="{'invalid': priceUnitAmount.dirty && priceUnitAmount.invalid}">
        <input id="priceUnitAmount" type="number" name="priceUnitAmount" #priceUnitAmount="ngModel" [(ngModel)]="model.priceUnitAmount" required />
        <span class="tooltip-content">
          {{ 'PRODUCT.PRICE_UNIT_VALIDATION' | translate }}
        </span>
      </label>
      <div class="select">
        <select id="priceUnit" [(ngModel)]="model.priceUnitId" name="priceUnit" (ngModelChange)="priceUnitChanged($event)" required>
          <option [ngValue]="null">&nbsp;</option>
          <option *ngFor="let unit of priceUnits" [(ngValue)]="unit.priceUnitId">{{ getTranslation(unit.name) }}</option>
        </select>
      </div>
    </div>
    <sale-units-component [data]="model.saleUnits" [priceUnit]="priceUnit" [languages]="languages"></sale-units-component>
  </section> -->
          </div>
        </clr-tab-content>
      </clr-tab>
      <clr-tab>
        <button clrTabLink title="{{ 'PRODUCT.PROPERTIES' | translate }}">{{ 'PRODUCT.PROPERTIES' | translate }}</button>
        <clr-tab-content>
          <div class="tab-content">
            <clr-tabs *ngIf="model.productId">
              <clr-tab>
                <button clrTabLink title="{{ 'PRODUCT.PROPERTY_GROUPS' | translate }}">{{ 'PRODUCT.PROPERTY_GROUPS' | translate }}</button>
                <clr-tab-content>
                  <div class="tab-content">
                    <product-property-groups-component [data]="model.productPropertyGroups" [languages]="languages" (dataChanged)="save()"></product-property-groups-component>
                  </div>
                </clr-tab-content>
              </clr-tab>
              <clr-tab>
                <button clrTabLink title="{{ 'PRODUCT.PROPERTIES' | translate }}">{{ 'PRODUCT.PROPERTIES' | translate }}</button>
                <clr-tab-content>
                  <div class="tab-content">
                    <product-properties-component [data]="model.productProperties" [systemProductProperties]="systemFields" [languages]="languages" [productPropertyTypes]="productPropertyTypes" [masterData]="masterDataLists" [productId]="model.productId" (dataChanged)="save()"></product-properties-component>
                  </div>
                </clr-tab-content>
              </clr-tab>
            </clr-tabs>
          </div>
        </clr-tab-content>
      </clr-tab>
      <clr-tab>
        <button clrTabLink title="{{ 'PRODUCT.SUPPLY_DATA' | translate }}">{{ 'PRODUCT.SUPPLY_DATA' | translate }}</button>
        <clr-tab-content>

          <div class="tab-content">
            <clr-alert *ngIf="!model.productId" [clrAlertType]="'danger'" [clrAlertClosable]="false">
              <div class="alert-item">
                <span class="alert-text">
                  {{ 'PRODUCT.ADD_PRODUCT_BEFORE_SUPPLY_DATA_PROPERTIES' | translate }}
                </span>
              </div>
            </clr-alert>
            <clr-tabs *ngIf="model.productId">
              <clr-tab>
                <button clrTabLink title="{{ 'PRODUCT.SUPPLY_DATA' | translate }}">{{ 'PRODUCT.SUPPLY_DATA' | translate }}</button>
                <clr-tab-content>
                  <div class="tab-content">
                    <clr-tabs *ngIf="model.productId">
                      <clr-tab>
                        <button clrTabLink title="{{ 'PRODUCT.PROPERTY.SUPPLY_CATALOG' | translate }}">{{ 'PRODUCT.PROPERTY.SUPPLY_CATALOG' | translate }}</button>
                        <clr-tab-content>
                          <div class="tab-content">
                            <generic-product-properties-component [languages]="languages" [productPropertyTypes]="productPropertyTypes" [data]="model.supplyDataEntryGridProperties" [productProperties]="model.productProperties" [isSupplyData]="true" [masterData]="masterDataLists" (onDataChanged)="save()"></generic-product-properties-component>
                          </div>
                        </clr-tab-content>
                      </clr-tab>
                      <clr-tab>
                        <button clrTabLink title="{{ 'PRODUCT.PROPERTY.FORECAST_CATALOG' | translate }}">{{ 'PRODUCT.PROPERTY.FORECAST_CATALOG' | translate }}</button>
                        <clr-tab-content>
                          <div class="tab-content">
                            <generic-product-properties-component [languages]="languages" [productPropertyTypes]="productPropertyTypes" [data]="model.supplyForecastDataEntryGridProperties" [productProperties]="model.productProperties" [isSupplyData]="true" [masterData]="masterDataLists" (onDataChanged)="save()"></generic-product-properties-component>
                          </div>
                        </clr-tab-content>
                      </clr-tab>
                      <clr-tab>
                        <button clrTabLink title="{{ 'PRODUCT.PROPERTY.PUBLIC_FORECAST_CATALOG' | translate }}">{{ 'PRODUCT.PROPERTY.PUBLIC_FORECAST_CATALOG' | translate }}</button>
                        <clr-tab-content>
                          <div class="tab-content">
                            <generic-product-properties-component [languages]="languages" [productPropertyTypes]="productPropertyTypes" [data]="model.supplyForecastPublicGridProperties" [productProperties]="model.productProperties" [isSupplyData]="true" [masterData]="masterDataLists" (onDataChanged)="save()"></generic-product-properties-component>
                          </div>
                        </clr-tab-content>
                      </clr-tab>
                    </clr-tabs>
                  </div>
                </clr-tab-content>
              </clr-tab>
              <clr-tab>
                <button clrTabLink title="{{ 'PRODUCT.SUPPLY_DATA_EDITOR' | translate }}">{{ 'PRODUCT.SUPPLY_DATA_EDITOR' | translate }}</button>
                <clr-tab-content>
                  <div class="tab-content">
                    <clr-alert *ngIf="!model.productId" [clrAlertType]="'danger'" [clrAlertClosable]="false">
                      <div class="alert-item">
                        <span class="alert-text">
                          {{ 'PRODUCT.ADD_PRODUCT_BEFORE_SUPPLY_DATA_EDITOR_PROPERTIES' | translate }}
                        </span>
                      </div>
                    </clr-alert>
                    <clr-tabs *ngIf="model.productId">
                      <clr-tab>
                        <button clrTabLink title="{{ 'PRODUCT.PROPERTY.SUPPLY_CATALOG_EDITOR' | translate }}">{{ 'PRODUCT.PROPERTY.SUPPLY_CATALOG_EDITOR' | translate }}</button>
                        <clr-tab-content>
                          <div class="tab-content">
                            <div class="clr-form-control">
                              <label for="supplyEditorLayout" class="clr-control-label">{{ 'PRODUCT.PROPERTY.LAYOUT' | translate }}</label>
                              <div class="clr-control-container">
                                <div class="clr-select-wrapper">
                                  <select id="supplyEditorLayout" name="supplyEditorLayout" [(ngModel)]="model.supplyEditorLayout">
                                    <option [ngValue]="1">{{ 'PRODUCT.PROPERTY.HORIZONTAL' | translate }}</option>
                                    <option [ngValue]="2">{{ 'PRODUCT.PROPERTY.VERTICAL' | translate }}</option>
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div class="clr-form-control" *ngIf="model.supplyEditorLayout === 2">
                              <label for="supplyEditorLayoutWidth" class="clr-control-label">{{ 'PRODUCT.PROPERTY.WIDTH_PERCENTAGE' | translate }}</label>
                              <div class="clr-control-container">
                                <div class="clr-input-wrapper">
                                  <input id="supplyEditorLayoutWidth" type="number" name="supplyEditorLayoutWidth" class="clr-input"
                                         #supplyEditorLayoutWidth="ngModel" [(ngModel)]="model.supplyEditorLayoutWidth" min="0" max="100" />
                                </div>
                              </div>
                            </div>

                            <div class="clr-form-control">
                              <label for="enableDesktopScreenLayout" class="clr-control-label">{{ 'PRODUCT.PROPERTY.AVAILABLE' | translate }}</label>
                              <div class="clr-control-container">
                                <div class="clr-checkbox-wrapper">
                                  <input id="enableDesktopScreenLayout" type="checkbox" clrCheckbox name="enableDesktopScreenLayout" [(ngModel)]="model.enableDesktopScreenLayout" />
                                </div>
                              </div>
                            </div>

                            <current-lot-properties-component [languages]="languages"
                                                              [data]="model.supplyDataEditorProperties" (dataChanged)="save()"
                                                              [propertyGroups]="model.productPropertyGroups"
                                                              [isEditorProperty]="true"
                                                              [productPropertyTypes]="productPropertyTypes"
                                                              [productProperties]="model.productProperties"
                                                              [masterData]="masterDataLists"
                                                              [showTouchScreenSettings]="model.enableTouchScreenLayout"></current-lot-properties-component>
                          </div>
                        </clr-tab-content>
                      </clr-tab>
                      <clr-tab>
                        <button clrTabLink title="{{ 'PRODUCT.PROPERTY.SUPPLY_CATALOG_EDITOR_TOUCH' | translate }}">{{ 'PRODUCT.PROPERTY.SUPPLY_CATALOG_EDITOR_TOUCH' | translate }}</button>
                        <clr-tab-content>
                          <div class="tab-content">
                            <div class="clr-form-control">
                              <label for="supplyEditorLayout" class="clr-control-label">{{ 'PRODUCT.PROPERTY.LAYOUT' | translate }}</label>
                              <div class="clr-control-container">
                                <div class="clr-select-wrapper">
                                  <select id="supplyEditorLayout" name="supplyEditorLayout" [(ngModel)]="model.supplyEditorLayout">
                                    <option [ngValue]="1">{{ 'PRODUCT.PROPERTY.HORIZONTAL' | translate }}</option>
                                    <option [ngValue]="2">{{ 'PRODUCT.PROPERTY.VERTICAL' | translate }}</option>
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div class="clr-form-control" *ngIf="model.supplyEditorLayout === 2">
                              <label for="supplyEditorLayoutWidth" class="clr-control-label">{{ 'PRODUCT.PROPERTY.WIDTH_PERCENTAGE' | translate }}</label>
                              <div class="clr-control-container">
                                <div class="clr-input-wrapper">
                                  <input id="supplyEditorLayoutWidth" type="number" name="supplyEditorLayoutWidth" class="clr-input"
                                         #supplyEditorLayoutWidth="ngModel" [(ngModel)]="model.supplyEditorLayoutWidth" min="0" max="100" />
                                </div>
                              </div>
                            </div>

                            <div class="clr-form-control">
                              <label for="enableTouchScreenLayout" class="clr-control-label">{{ 'PRODUCT.PROPERTY.AVAILABLE' | translate }}</label>
                              <div class="clr-control-container">
                                <div class="clr-checkbox-wrapper">
                                  <input id="enableTouchScreenLayout" type="checkbox" clrCheckbox name="enableTouchScreenLayout" [(ngModel)]="model.enableTouchScreenLayout" />
                                </div>
                              </div>
                            </div>

                            <current-lot-properties-component [languages]="languages"
                                                              [data]="model.supplyDataTouchEditorProperties" (dataChanged)="save()"
                                                              [propertyGroups]="model.productPropertyGroups"
                                                              [isEditorProperty]="true"
                                                              [productPropertyTypes]="productPropertyTypes"
                                                              [productProperties]="model.productProperties"
                                                              [masterData]="masterDataLists"
                                                              [showTouchScreenSettings]="model.enableTouchScreenLayout"></current-lot-properties-component>
                          </div>
                        </clr-tab-content>
                      </clr-tab>
                      <clr-tab>
                        <button clrTabLink title="{{ 'PRODUCT.PROPERTY.FORECAST_CATALOG_EDITOR' | translate }}">{{ 'PRODUCT.PROPERTY.FORECAST_CATALOG_EDITOR' | translate }}</button>
                        <clr-tab-content>
                          <div class="tab-content">
                            <div class="clr-form-control">
                              <label for="supplyForecastEditorLayout" class="clr-control-label">{{ 'PRODUCT.PROPERTY.LAYOUT' | translate }}</label>
                              <div class="clr-control-container">
                                <div class="clr-select-wrapper">
                                  <select id="supplyForecastEditorLayout" name="supplyForecastEditorLayout" [(ngModel)]="model.supplyForecastEditorLayout">
                                    <option [ngValue]="1">{{ 'PRODUCT.PROPERTY.HORIZONTAL' | translate }}</option>
                                    <option [ngValue]="2">{{ 'PRODUCT.PROPERTY.VERTICAL' | translate }}</option>
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div class="clr-form-control" *ngIf="model.supplyForecastEditorLayout === 2">
                              <label for="supplyForecastEditorLayoutWidth" class="clr-control-label">{{ 'PRODUCT.PROPERTY.WIDTH_PERCENTAGE' | translate }}</label>
                              <div class="clr-control-container">
                                <div class="clr-input-wrapper">
                                  <input id="supplyForecastEditorLayoutWidth" type="number" name="supplyForecastEditorLayoutWidth" class="clr-input"
                                         #supplyEditorLasupplyForecastEditorLayoutWidthyoutWidth="ngModel" [(ngModel)]="model.supplyForecastEditorLayoutWidth" min="0" max="100" />
                                </div>
                              </div>
                            </div>

                            <current-lot-properties-component [languages]="languages"
                                                              [data]="model.supplyForecastDataEditorProperties"
                                                              (dataChanged)="save()"
                                                              [propertyGroups]="model.productPropertyGroups"
                                                              [isEditorProperty]="true"
                                                              [productPropertyTypes]="productPropertyTypes"
                                                              [productProperties]="model.productProperties"
                                                              [masterData]="masterDataLists"
                                                              [showTouchScreenSettings]="model.enableTouchScreenLayout"></current-lot-properties-component>
                          </div>
                        </clr-tab-content>
                      </clr-tab>
                    </clr-tabs>
                  </div>
                </clr-tab-content>
              </clr-tab>
              <clr-tab>
                <button clrTabLink title="{{ 'PRODUCT.PROPERTY.MASTER_DETAILS' | translate }}">{{ 'PRODUCT.PROPERTY.MASTER_DETAILS' | translate }}</button>
                <clr-tab-content>
                  <div class="tab-content">
                    <clr-alert *ngIf="!model.productId" [clrAlertType]="'danger'" [clrAlertClosable]="false">
                      <div class="alert-item">
                        <span class="alert-text">
                          {{ 'PRODUCT.ADD_PRODUCT_BEFORE_SUPPLY_DATA_EDITOR_PROPERTIES' | translate }}
                        </span>
                      </div>
                    </clr-alert>
                    <clr-tabs *ngIf="model.productId">
                      <clr-tab>
                        <button clrTabLink title="{{ 'PRODUCT.PROPERTY.SUPPLY_MASTER_DETAILS_GROUPING' | translate }}">{{ 'PRODUCT.PROPERTY.SUPPLY_MASTER_DETAILS_GROUPING' | translate }}</button>
                        <clr-tab-content>
                          <grouping-properties-component (save)="onSubmit()" (dataChanged)="save()"
                                                         [groupingProperties]="model.masterSupplyProductPropertyGroupingSelections"
                                                         [nonGroupingProperties]="model.masterSupplyProductPropertyNonGroupingSelections"
                                                         [productProperties]="model.productProperties" [productPropertyTypes]="productPropertyTypes"
                                                         [masterData]="masterDataLists"></grouping-properties-component>
                        </clr-tab-content>
                      </clr-tab>
                      <clr-tab>
                        <button clrTabLink title="{{ 'PRODUCT.PROPERTY.SUPPLY_MASTER_DETAILS_SELECTIONS' | translate }}">{{ 'PRODUCT.PROPERTY.SUPPLY_MASTER_DETAILS_SELECTIONS' | translate }}</button>
                        <clr-tab-content>
                          <generic-product-properties-component [languages]="languages" [data]="model.masterSupplyProperties"
                                                                (onDataChanged)="save()" [productPropertyTypes]="productPropertyTypes" [productProperties]="model.productProperties"
                                                                [masterData]="masterDataLists"></generic-product-properties-component>
                        </clr-tab-content>
                      </clr-tab>
                      <clr-tab>
                        <button clrTabLink title="{{ 'PRODUCT.PROPERTY.FORECAST_MASTER_DETAILS_GROUPING' | translate }}">{{ 'PRODUCT.PROPERTY.FORECAST_MASTER_DETAILS_GROUPING' | translate }}</button>
                        <clr-tab-content>
                          <grouping-properties-component (save)="onSubmit()" (dataChanged)="save()"
                                                         [groupingProperties]="model.masterForecastProductPropertyGroupingSelections"
                                                         [nonGroupingProperties]="model.masterForecastProductPropertyNonGroupingSelections"
                                                         [productProperties]="model.productProperties" [productPropertyTypes]="productPropertyTypes"
                                                         [masterData]="masterDataLists"></grouping-properties-component>
                        </clr-tab-content>
                      </clr-tab>
                      <clr-tab>
                        <button clrTabLink title="{{ 'PRODUCT.PROPERTY.FORECAST_MASTER_DETAILS_SELECTIONS' | translate }}">{{ 'PRODUCT.PROPERTY.FORECAST_MASTER_DETAILS_SELECTIONS' | translate }}</button>
                        <clr-tab-content>
                          <generic-product-properties-component [languages]="languages" [data]="model.masterForecastProperties"
                                                                (onDataChanged)="save()" [productPropertyTypes]="productPropertyTypes" [productProperties]="model.productProperties"
                                                                [masterData]="masterDataLists"></generic-product-properties-component>
                        </clr-tab-content>
                      </clr-tab>
                    </clr-tabs>
                  </div>
                </clr-tab-content>
              </clr-tab>
              <clr-tab>
                <button clrTabLink title="{{ 'PRODUCT.PROPERTY.MOVE_LOTS_TO_CATALOG' | translate }}">{{ 'PRODUCT.PROPERTY.MOVE_LOTS_TO_CATALOG' | translate }}</button>
                <clr-tab-content>
                  <div class="tab-content">
                    <clr-alert *ngIf="!model.productId" [clrAlertType]="'danger'" [clrAlertClosable]="false">
                      <div class="alert-item">
                        <span class="alert-text">
                          {{ 'PRODUCT.ADD_PRODUCT_BEFORE_DEFINING_GROUPING_PROPERTIES' | translate }}
                        </span>
                      </div>
                    </clr-alert>
                    <div *ngIf="model.productId">
                      <generic-product-properties-component [languages]="languages" [data]="model.moveLotsToCatalog" (onDataChanged)="save()"
                                                            [productPropertyTypes]="productPropertyTypes" [productProperties]="model.productProperties"
                                                            [masterData]="masterDataLists" [isOrderNeeded]="false" [isMasterDataFieldNeeded]="false"></generic-product-properties-component>
                    </div>
                  </div>
                </clr-tab-content>
              </clr-tab>
            </clr-tabs>
          </div>
        </clr-tab-content>
      </clr-tab>
      <clr-tab>
        <button clrTabLink title="{{ 'PRODUCT.REPORTS' | translate }}">{{ 'PRODUCT.REPORTS' | translate }}</button>
        <clr-tab-content>

          <clr-alert *ngIf="!model.productId" [clrAlertType]="'danger'" [clrAlertClosable]="false">
            <div class="alert-item">
              <span class="alert-text">
                {{ 'PRODUCT.ADD_PRODUCT_BEFORE_DEFINING_REPORTS' | translate }}
              </span>
            </div>
          </clr-alert>
          <div *ngIf="model.productId">
            <div class="tab-content">
              <div class="clr-form-control report-types">
                <label for="reportId" class="clr-control-label">{{ 'PRODUCT.REPORT_TYPES' | translate }}</label>
                <div class="clr-control-container">
                  <div class="clr-select-wrapper">
                    <select id="reportId" name="reportId" [(ngModel)]="reportId" (ngModelChange)="filterReportProperties()">
                      <option [ngValue]="null"></option>
                      <option *ngFor="let reportType of reportTypes" [ngValue]="reportType.reportDesignId">{{ReportLevels[reportType.reportLevel]}} - {{reportType.name | translateJson}}</option>
                    </select>
                  </div>
                </div>
              </div>

              <generic-product-properties-component [languages]="languages" *ngIf="reportId"
                                                    [productPropertyTypes]="productPropertyTypes"
                                                    (onDataChanged)="save()"
                                                    [productProperties]="model.productProperties"
                                                    [masterData]="masterDataLists"
                                                    [isReport]="true"
                                                    [data]="filteredReportProperties">
              </generic-product-properties-component>
            </div>
          </div>
        </clr-tab-content>
      </clr-tab>
      <clr-tab>
        <button clrTabLink title="{{ 'PRODUCT.PROPERTY.GROUPING' | translate }}">{{ 'PRODUCT.PROPERTY.GROUPING' | translate }}</button>
        <clr-tab-content>
          <div class="tab-content">
            <clr-alert *ngIf="!model.productId" [clrAlertType]="'danger'" [clrAlertClosable]="false">
              <div class="alert-item">
                <span class="alert-text">
                  {{ 'PRODUCT.ADD_PRODUCT_BEFORE_DEFINING_GROUPING_PROPERTIES' | translate }}
                </span>
              </div>
            </clr-alert>
            <clr-tabs *ngIf="model.productId">
          <clr-tab>
            <button clrTabLink title="{{ 'PRODUCT.PROPERTY.AUTOMATIC_GROUPING' | translate }}">{{ 'PRODUCT.PROPERTY.AUTOMATIC_GROUPING' | translate }}</button>
            <clr-tab-content>
              <div class="tab-content">
                <clr-alert *ngIf="!model.productId" [clrAlertType]="'danger'" [clrAlertClosable]="false">
                  <div class="alert-item">
                    <span class="alert-text">
                      {{ 'PRODUCT.ADD_PRODUCT_BEFORE_DEFINING_GROUPING_PROPERTIES' | translate }}
                    </span>
                  </div>
                </clr-alert>
                <div *ngIf="model.productId">
                  <grouping-properties-component (save)="onSubmit()" (dataChanged)="save()" [groupingProperties]="model.automaticProductPropertyGroupingSelections" [nonGroupingProperties]="model.automaticProductPropertyNonGroupingSelections" [productProperties]="model.productProperties" [productPropertyTypes]="productPropertyTypes" [masterData]="masterDataLists"></grouping-properties-component>
                </div>
              </div>
            </clr-tab-content>
          </clr-tab>
          <clr-tab>
            <button clrTabLink title="{{ 'PRODUCT.PROPERTY.MANUAL_GROUPING' | translate }}">{{ 'PRODUCT.PROPERTY.MANUAL_GROUPING' | translate }}</button>
            <clr-tab-content>
              <div class="tab-content">
                <clr-alert *ngIf="!model.productId" [clrAlertType]="'danger'" [clrAlertClosable]="false">
                  <div class="alert-item">
                    <span class="alert-text">
                      {{ 'PRODUCT.ADD_PRODUCT_BEFORE_DEFINING_GROUPING_PROPERTIES' | translate }}
                    </span>
                  </div>
                </clr-alert>
                <div *ngIf="model.productId">
                  <grouping-properties-component [groupingProperties]="model.manualProductPropertyGroupingSelections" (dataChanged)="save()"
                                                 [nonGroupingProperties]="model.manualProductPropertyNonGroupingSelections" [showFilterProperties]="true"
                                                 [filterProperties]="model.manualProductPropertyFilterSelections" [productProperties]="model.productProperties"
                                                 [productPropertyTypes]="productPropertyTypes" [masterData]="masterDataLists"></grouping-properties-component>
                </div>
              </div>
            </clr-tab-content>
          </clr-tab>
            </clr-tabs>
          </div>
        </clr-tab-content>
      </clr-tab>
      <clr-tab>
        <button clrTabLink title="{{ 'PRODUCT.PROPERTY.GRIDS' | translate }}">{{ 'PRODUCT.PROPERTY.GRIDS' | translate }}</button>
        <clr-tab-content>
          <div class="tab-content">
            <clr-tabs *ngIf="model.productId">
              <clr-tab>
                <button clrTabLink title="{{ 'PRODUCT.PROPERTY.TRANSACTION_MONITOR' | translate }}">{{ 'PRODUCT.PROPERTY.TRANSACTION_MONITOR' | translate }}</button>
                <clr-tab-content>
                  <div class="tab-content">
                    <clr-alert *ngIf="!model.productId" [clrAlertType]="'danger'" [clrAlertClosable]="false">
                      <div class="alert-item">
                        <span class="alert-text">
                          {{ 'PRODUCT.ADD_PRODUCT_BEFORE_TRANSACTION_MONITOR_PROPERTIES' | translate }}
                        </span>
                      </div>
                    </clr-alert>
                    <div *ngIf="model.productId">
                      <transaction-properties-component [propertyGroupingShowing]="true"
                                                        [automaticProductPropertyNonGroupingSelections]="model.automaticProductPropertyNonGroupingSelections"
                                                        [manualProductPropertyNonGroupingSelections]="model.manualProductPropertyNonGroupingSelections" [languages]="languages" [data]="model.transactionMonitor" (dataChanged)="save()" [productPropertyTypes]="productPropertyTypes" [productProperties]="model.productProperties" [masterData]="masterDataLists"></transaction-properties-component>
                    </div>
                  </div>
                </clr-tab-content>
              </clr-tab>
              <clr-tab>
                <button clrTabLink title="{{ 'PRODUCT.PROPERTY.SUPPLY_MONITOR' | translate }}">{{ 'PRODUCT.PROPERTY.SUPPLY_MONITOR' | translate }}</button>
                <clr-tab-content>
                  <generic-product-properties-component [propertyGroupingShowing]="true"
                                                        [automaticProductPropertyNonGroupingSelections]="model.automaticProductPropertyNonGroupingSelections"
                                                        [manualProductPropertyNonGroupingSelections]="model.manualProductPropertyNonGroupingSelections"
                                                        [languages]="languages" [data]="model.supplyMonitorProductDefinitionBuyer" (onDataChanged)="save()" [productPropertyTypes]="productPropertyTypes" [productProperties]="model.productProperties" [masterData]="masterDataLists"></generic-product-properties-component>
                </clr-tab-content>
              </clr-tab>
              <clr-tab>
                <button clrTabLink title="{{ 'PRODUCT.PROPERTY.SHOPPING_LIST_PRODUCT_DEFINITION' | translate }}">{{ 'PRODUCT.PROPERTY.SHOPPING_LIST_PRODUCT_DEFINITION' | translate }}</button>
                <clr-tab-content>
                  <generic-product-properties-component [languages]="languages" [data]="model.shoppingListProductDefinitionBuyer" (onDataChanged)="save()" [productPropertyTypes]="productPropertyTypes" [productProperties]="model.productProperties" [masterData]="masterDataLists"></generic-product-properties-component>
                </clr-tab-content>
              </clr-tab>
            </clr-tabs>
          </div>
        </clr-tab-content>
      </clr-tab>
      <clr-tab>
        <button clrTabLink title="{{ 'PRODUCT.PROPERTY.WIDGETS' | translate }}">{{ 'PRODUCT.PROPERTY.WIDGETS' | translate }}</button>
        TS
        nt
        <clr-tab-content>
          <div class="tab-content">
            <clr-tabs *ngIf="model.productId">
              <clr-tab>
                <button clrTabLink title="{{ 'PRODUCT.PROPERTY.SUPPLY_MATRIX' | translate }}">{{ 'PRODUCT.PROPERTY.SUPPLY_MATRIX' | translate }}</button>
                <clr-tab-content>
                  <generic-product-properties-component [languages]="languages" [data]="model.supplyMatrixAxisOne"
                                                        (onDataChanged)="save()" [productPropertyTypes]="productPropertyTypes" [productProperties]="model.productProperties"
                                                        [masterData]="masterDataLists" [showComparable]="true"></generic-product-properties-component>
                  <generic-product-properties-component [languages]="languages" [data]="model.supplyMatrixAxisTwo"
                                                        (onDataChanged)="save()" [productPropertyTypes]="productPropertyTypes" [productProperties]="model.productProperties"
                                                        [masterData]="masterDataLists" [showComparable]="true"></generic-product-properties-component>
                </clr-tab-content>
              </clr-tab>
              <clr-tab>
                <button clrTabLink title="{{ 'PRODUCT.PROPERTY.PRICE_OVERVIEW_GRID' | translate }}">{{ 'PRODUCT.PROPERTY.PRICE_OVERVIEW_GRID' | translate }}</button>
                <clr-tab-content>
                  <clr-alert *ngIf="!model.productId" [clrAlertType]="'danger'" [clrAlertClosable]="false">
                    <div class="alert-item">
                      <span class="alert-text">
                        {{ 'PRODUCT.ADD_PRODUCT_BEFORE_DEFINING_REPORTS' | translate }}
                      </span>
                    </div>
                  </clr-alert>
                  <div *ngIf="model.productId">
                    <div class="price-overview">
                      <clr-checkbox-container clrInline>
                        <clr-checkbox-wrapper>
                          <input type="checkbox" clrCheckbox [(ngModel)]="model.priceOverviewShowTopPrice" name="priceOverviewShowTopPrice" />
                          <label>{{ 'PRODUCT.PROPERTY.PRICE_OVERVIEW_SHOW_TOP_PRICE' | translate }}</label>
                        </clr-checkbox-wrapper>
                        <clr-checkbox-wrapper>
                          <input type="checkbox" clrCheckbox [(ngModel)]="model.priceOverviewShowAveragePrice" name="priceOverviewShowAveragePrice" />
                          <label>{{ 'PRODUCT.PROPERTY.PRICE_OVERVIEW_SHOW_AVERAGE_PRICE' | translate }}</label>
                        </clr-checkbox-wrapper>
                        <clr-checkbox-wrapper>
                          <input type="checkbox" clrCheckbox [(ngModel)]="model.priceOverviewShowMinPrice" name="priceOverviewShowMinPrice" />
                          <label>{{ 'PRODUCT.PROPERTY.PRICE_OVERVIEW_SHOW_MIN_PRICE' | translate }}</label>
                        </clr-checkbox-wrapper>
                        <clr-checkbox-wrapper>
                          <input type="checkbox" clrCheckbox [(ngModel)]="model.priceOverviewShowLastPurchasedAmount" name="priceOverviewShowLastPurchasedAmount" />
                          <label>{{ 'PRODUCT.PROPERTY.PRICE_OVERVIEW_SHOW_LAST_PURCHASED_AMOUNT' | translate }}</label>
                        </clr-checkbox-wrapper>
                        <clr-checkbox-wrapper>
                          <input type="checkbox" clrCheckbox [(ngModel)]="model.priceOverviewShowLastPaidPrice" name="priceOverviewShowLastPaidPrice" />
                          <label>{{ 'PRODUCT.PROPERTY.PRICE_OVERVIEW_SHOW_LAST_PAID_PRICE' | translate }}</label>
                        </clr-checkbox-wrapper>
                        <clr-checkbox-wrapper>
                          <input type="checkbox" clrCheckbox [(ngModel)]="model.priceOverviewShowTotalSoldAmount" name="priceOverviewShowTotalSoldAmount" />
                          <label>{{ 'PRODUCT.PROPERTY.PRICE_OVERVIEW_SHOW_TOTAL_SOLD_AMOUNT' | translate }}</label>
                        </clr-checkbox-wrapper>
                        <clr-checkbox-wrapper>
                          <input type="checkbox" clrCheckbox [(ngModel)]="model.priceOverviewShowSummary" name="priceOverviewShowSummary" />
                          <label>{{ 'PRODUCT.PROPERTY.PRICE_OVERVIEW_SHOW_SUMMARY' | translate }}</label>
                        </clr-checkbox-wrapper>
                        <clr-checkbox-wrapper>
                          <input type="checkbox" clrCheckbox [(ngModel)]="model.priceOverviewShowAvailableAmount" name="priceOverviewShowAvailableAmount" />
                          <label>{{ 'PRODUCT.PROPERTY.PRICE_OVERVIEW_SHOW_AVAILABLE_AMOUNT' | translate }}</label>
                        </clr-checkbox-wrapper>
                        <clr-checkbox-wrapper>
                          <input type="checkbox" clrCheckbox [(ngModel)]="model.priceOverviewShowTotalAmount" name="priceOverviewShowTotalAmount" />
                          <label>{{ 'PRODUCT.PROPERTY.PRICE_OVERVIEW_SHOW_TOTAL_AMOUNT' | translate }}</label>
                        </clr-checkbox-wrapper>
                      </clr-checkbox-container>
                    </div>
                    <generic-product-properties-component [languages]="languages" [data]="model.priceOverviewGridBuyer" (onDataChanged)="save()"
                                                          [productPropertyTypes]="productPropertyTypes" [productProperties]="model.productProperties"
                                                          [masterData]="masterDataLists" [showFilterOnCurrentLotProperty]="true" [showLastInFullScreenProperty]="true"
                                                          [showIncludeInSummaryProperty]="model.priceOverviewShowSummary"></generic-product-properties-component>
                  </div>
                </clr-tab-content>
              </clr-tab>
              <clr-tab>
                <button clrTabLink title="{{ 'PRODUCT.PROPERTY.IMAGES_WIDGET' | translate }}">{{ 'PRODUCT.PROPERTY.IMAGES_WIDGET' | translate }}</button>
                <clr-tab-content>
                  <div class="tab-content">
                    <generic-product-properties-component [propertyTypeFilter] = "productPropertyTypeEnum.URL" [urlFormatFilter] = "productPropertyUrlTypesEnum.IMAGE_URL"
                                                          [languages]="languages" [productPropertyTypes]="productPropertyTypes" [data]="model.imagesWidgetProperties"
                                                          [productProperties]="filteredImageProperties" [masterData]="masterDataLists" (onDataChanged)="save()"></generic-product-properties-component>
                  </div>
                </clr-tab-content>
              </clr-tab>
              <clr-tab>
                <button clrTabLink title="{{ 'PRODUCT.PROPERTY.CLOCK_VIDEO' | translate }}">{{ 'PRODUCT.PROPERTY.CLOCK_VIDEO' | translate }}</button>
                <clr-tab-content>
                  <div class="tab-content">
                    <div class="clr-form-control">
                      <label for="enableVideoStreaming" class="clr-control-label">{{ 'ENABLED' | translate }}</label>
                      <div class="clr-control-container">
                        <div class="clr-checkbox-wrapper">
                          <input id="enableVideoStreaming" type="checkbox" clrCheckbox name="isCollapsed" [(ngModel)]="model.enableVideoStreaming" />
                        </div>
                      </div>
                    </div>
                  </div>
                </clr-tab-content>
              </clr-tab>
            </clr-tabs>
          </div>
        </clr-tab-content>
      </clr-tab>

      <clr-tab>
        <button clrTabLink title="{{ 'PRODUCT.PROPERTY.AUCTIONEER' | translate }}">{{ 'PRODUCT.PROPERTY.AUCTIONEER' | translate }}</button>
        <clr-tab-content>
          <div class="tab-content">
            <clr-alert *ngIf="!model.productId" [clrAlertType]="'danger'" [clrAlertClosable]="false">
              <div class="alert-item">
                <span class="alert-text">
                  {{ 'PRODUCT.ADD_PRODUCT_BEFORE_AUCTIONEER_PROPERTIES' | translate }}
                </span>
              </div>
            </clr-alert>
            <clr-tabs *ngIf="model.productId">
              <clr-tab>
                <button clrTabLink title="{{ 'PRODUCT.PROPERTY.NEXT_LOT_PROPERTIES' | translate }}">{{ 'PRODUCT.PROPERTY.NEXT_LOT_PROPERTIES' | translate }}</button>
                <clr-tab-content>
                  <generic-product-properties-component [propertyGroupingShowing]="true"
                                                        [automaticProductPropertyNonGroupingSelections]="model.automaticProductPropertyNonGroupingSelections"
                                                        [manualProductPropertyNonGroupingSelections]="model.manualProductPropertyNonGroupingSelections"
                                                        [languages]="languages" [data]="model.nextLotAuctioneerProperties" (onDataChanged)="save()"
                                                        [productProperties]="model.productProperties" [productPropertyTypes]="productPropertyTypes"
                                                        [masterData]="masterDataLists"></generic-product-properties-component>
                </clr-tab-content>
              </clr-tab>
              <clr-tab>
                <button clrTabLink title="{{ 'PRODUCT.PROPERTY.CURRENT_LOT_PROPERTIES' | translate }}">{{ 'PRODUCT.PROPERTY.CURRENT_LOT_PROPERTIES' | translate }}</button>
                <clr-tab-content>
                  <current-lot-properties-component [propertyGroupingShowing]="true"
                                                    [automaticProductPropertyNonGroupingSelections]="model.automaticProductPropertyNonGroupingSelections"
                                                    [manualProductPropertyNonGroupingSelections]="model.manualProductPropertyNonGroupingSelections"
                                                    [languages]="languages" [data]="model.currentLotAuctioneerProperties" (dataChanged)="save()"
                                                    [propertyGroups]="model.productPropertyGroups" [isEditorProperty]="false"
                                                    [productPropertyTypes]="productPropertyTypes" [productProperties]="model.productProperties"
                                                    [productPropertyStylings]="productPropertyStylings"
                                                    [masterData]="masterDataLists"></current-lot-properties-component>
                </clr-tab-content>
              </clr-tab>
              <clr-tab>
                <button clrTabLink title="{{ 'PRODUCT.PROPERTY.TRANSACTION_INFO' | translate }}">{{ 'PRODUCT.PROPERTY.TRANSACTION_INFO' | translate }}</button>
                <clr-tab-content>
                  <transaction-properties-component [propertyGroupingShowing]="true"
                                                    [automaticProductPropertyNonGroupingSelections]="model.automaticProductPropertyNonGroupingSelections"
                                                    [manualProductPropertyNonGroupingSelections]="model.manualProductPropertyNonGroupingSelections" [languages]="languages" [data]="model.transactionInfoAuctioneerProperties" (dataChanged)="save()" [productPropertyTypes]="productPropertyTypes" [productProperties]="model.productProperties" [masterData]="masterDataLists"></transaction-properties-component>
                </clr-tab-content>
              </clr-tab>
              <clr-tab>
                <button clrTabLink title="{{ 'PRODUCT.PROPERTY.POPUP' | translate }}">{{ 'PRODUCT.PROPERTY.POPUP' | translate }}</button>
                <clr-tab-content>
                  <generic-product-properties-component [automaticProductPropertyNonGroupingSelections]="model.automaticProductPropertyNonGroupingSelections"
                                                        [manualProductPropertyNonGroupingSelections]="model.manualProductPropertyNonGroupingSelections"
                                                        [languages]="languages" [data]="model.auctioneerPopup" (onDataChanged)="save()" [productPropertyTypes]="productPropertyTypes" [productProperties]="model.productProperties" [masterData]="masterDataLists"></generic-product-properties-component>
                </clr-tab-content>
              </clr-tab>
              <clr-tab>
                <button clrTabLink title="{{ 'PRODUCT.PROPERTY.GROUP_LEVEL_POPUP' | translate }}">{{ 'PRODUCT.PROPERTY.GROUP_LEVEL_POPUP' | translate }}</button>
                <clr-tab-content>
                  <generic-product-properties-component [propertyGroupingShowing]="true" [showOnlyAutomaticGrouping]="true"
                                                        [automaticProductPropertyNonGroupingSelections]="model.automaticProductPropertyNonGroupingSelections"
                                                        [manualProductPropertyNonGroupingSelections]="model.manualProductPropertyNonGroupingSelections"
                                                        [languages]="languages" [data]="model.auctioneerPopupGroupLevel" (onDataChanged)="save()" [productPropertyTypes]="productPropertyTypes" [productProperties]="model.productProperties" [masterData]="masterDataLists"></generic-product-properties-component>
                </clr-tab-content>
              </clr-tab>
              <clr-tab>
                <button clrTabLink title="{{ 'PRODUCT.PROPERTY.CLOCK_FACE_PROPERTIES' | translate }}">{{ 'PRODUCT.PROPERTY.CLOCK_FACE_PROPERTIES' | translate }}</button>
                <clr-tab-content>
                  <current-lot-properties-component [propertyGroupingShowing]="false"
                                                    [languages]="languages" [data]="model.clockFaceAuctioneerProperties" (dataChanged)="save()"
                                                    [propertyGroups]="model.productPropertyGroups" [isEditorProperty]="false" [isClockFaceProperty]="true"
                                                    [productPropertyTypes]="productPropertyTypes" [productProperties]="model.productProperties"
                                                    [productPropertyStylings]="productPropertyStylings"
                                                    [masterData]="masterDataLists"></current-lot-properties-component>
                </clr-tab-content>
              </clr-tab>
              <clr-tab>
                <button clrTabLink title="{{ 'PRODUCT.PROPERTY.LOT_IN_PREPARATION' | translate }}">{{ 'PRODUCT.PROPERTY.LOT_IN_PREPARATION' | translate }}</button>
                <clr-tab-content>
                  <current-lot-properties-component [languages]="languages"
                                                    [data]="model.lotInPreparationProperties" (dataChanged)="save()"
                                                    [propertyGroups]="model.productPropertyGroups"
                                                    [isEditorProperty]="true"
                                                    [productPropertyTypes]="productPropertyTypes"
                                                    [productProperties]="model.productProperties"
                                                    [masterData]="masterDataLists"
                                                    [lotInPreparationProperties]="true"></current-lot-properties-component>
                </clr-tab-content>
              </clr-tab>
              <clr-tab>
                <button clrTabLink
                  title="{{ 'PRODUCT.PROPERTY.PACKAGE_TYPE' | translate }}">{{ 'PRODUCT.PROPERTY.PACKAGE_TYPE' | translate }}</button>
                <clr-tab-content>
                  <generic-product-properties-component [languages]="languages" [data]="model.packageTypeAuctioneerProperties" (onDataChanged)="save()"
                                                        [productProperties]="model.productProperties" [productPropertyTypes]="productPropertyTypes"
                                                        [masterData]="masterDataLists"></generic-product-properties-component>
                </clr-tab-content>
              </clr-tab>
              <clr-tab>
                <button clrTabLink
                  title="{{ 'PRODUCT.PROPERTY.SELECT_LOT' | translate }}">{{ 'PRODUCT.PROPERTY.SELECT_LOT' | translate }}</button>
                <clr-tab-content>
                  <div class="select-lot">
                    <span>
                      {{ 'PRODUCT.PROPERTY.SEARCH_BEHAVIOUR' | translate }}
                    </span>:
                    <select class="search-behaviour-select" name="selectLotSearchBehaviour" [(ngModel)]="model.selectLotSearchBehaviour">
                      <option *ngFor="let behaviour of selectLotSearchBehaviours" [ngValue]="behaviour.value">{{ behaviour.key | translate }}</option>
                    </select>
                  </div>
                </clr-tab-content>
              </clr-tab>
              <clr-tab>
                <button clrTabLink 
                  title="{{ 'PRODUCT.PROPERTY.TRANSACTION_REGISTRATION' | translate }}">{{ 'PRODUCT.PROPERTY.TRANSACTION_REGISTRATION' | translate }}</button>
                <clr-tab-content>
                  <generic-product-properties-component [languages]="languages" [data]="model.transactionRegistrationFilterProperties" (onDataChanged)="save()"
                                                        [productProperties]="filteredMasterDataProperties" [productPropertyTypes]="productPropertyTypes"
                                                        [masterData]="masterDataLists" [isMasterDataFieldNeeded]="false"></generic-product-properties-component>
                </clr-tab-content>
              </clr-tab> 
              <clr-tab>
                <button clrTabLink 
                  title="{{ 'PRODUCT.PROPERTY.TRANSACTION_REGISTRATION_GRID' | translate }}">{{ 'PRODUCT.PROPERTY.TRANSACTION_REGISTRATION_GRID' | translate }}</button>
                <clr-tab-content>
                  <transaction-properties-component [propertyGroupingShowing]="false" [languages]="languages" [data]="model.transactionRegistrationGridProperties" 
                                                    (dataChanged)="save()" [productPropertyTypes]="productPropertyTypes" [productProperties]="model.productProperties" 
                                                    [masterData]="masterDataLists"></transaction-properties-component>
                </clr-tab-content>
              </clr-tab>
            </clr-tabs>
          </div>
        </clr-tab-content>
      </clr-tab>
      <clr-tab>
        <button clrTabLink title="{{ 'PRODUCT.PROPERTY.BUYER' | translate }}">{{ 'PRODUCT.PROPERTY.BUYER' | translate }}</button>
        <clr-tab-content>
          <div class="tab-content">
            <clr-alert *ngIf="!model.productId" [clrAlertType]="'danger'" [clrAlertClosable]="false">
              <div class="alert-item">
                <span class="alert-text">
                  {{ 'PRODUCT.ADD_PRODUCT_BEFORE_BUYER_PROPERTIES' | translate }}
                </span>
              </div>
            </clr-alert>
            <clr-tabs *ngIf="model.productId">
              <clr-tab>
                <button clrTabLink title="{{ 'PRODUCT.PROPERTY.NEXT_LOT_PROPERTIES' | translate }}">{{ 'PRODUCT.PROPERTY.NEXT_LOT_PROPERTIES' | translate }}</button>
                <clr-tab-content>
                  <generic-product-properties-component [propertyGroupingShowing]="true"
                                                        [automaticProductPropertyNonGroupingSelections]="model.automaticProductPropertyNonGroupingSelections"
                                                        [manualProductPropertyNonGroupingSelections]="model.manualProductPropertyNonGroupingSelections"
                                                        [languages]="languages" [data]="model.nextLotBuyerProperties" (onDataChanged)="save()" [productPropertyTypes]="productPropertyTypes" [productProperties]="model.productProperties" [masterData]="masterDataLists"></generic-product-properties-component>
                </clr-tab-content>
              </clr-tab>
              <clr-tab>
                <button clrTabLink title="{{ 'PRODUCT.PROPERTY.CURRENT_LOT_PROPERTIES' | translate }}">{{ 'PRODUCT.PROPERTY.CURRENT_LOT_PROPERTIES' | translate }}</button>
                <clr-tab-content>
                  <current-lot-properties-component [propertyGroupingShowing]="true"
                                                    [automaticProductPropertyNonGroupingSelections]="model.automaticProductPropertyNonGroupingSelections"
                                                    [manualProductPropertyNonGroupingSelections]="model.manualProductPropertyNonGroupingSelections"
                                                    [languages]="languages" [data]="model.currentLotBuyerProperties" (dataChanged)="save()"
                                                    [propertyGroups]="model.productPropertyGroups" [isEditorProperty]="false"
                                                    [productPropertyTypes]="productPropertyTypes" [productProperties]="model.productProperties"
                                                    [masterData]="masterDataLists"></current-lot-properties-component>
                </clr-tab-content>
              </clr-tab>
              <clr-tab>
                <button clrTabLink title="{{ 'PRODUCT.PROPERTY.TRANSACTION_INFO' | translate }}">{{ 'PRODUCT.PROPERTY.TRANSACTION_INFO' | translate }}</button>
                <clr-tab-content>
                  <transaction-properties-component [propertyGroupingShowing]="true"
                                                    [automaticProductPropertyNonGroupingSelections]="model.automaticProductPropertyNonGroupingSelections"
                                                    [manualProductPropertyNonGroupingSelections]="model.manualProductPropertyNonGroupingSelections" [languages]="languages" [data]="model.transactionInfoBuyerProperties" (dataChanged)="save()" [productPropertyTypes]="productPropertyTypes" [productProperties]="model.productProperties" [masterData]="masterDataLists"></transaction-properties-component>
                </clr-tab-content>
              </clr-tab>

              <clr-tab>
                <button clrTabLink title="{{ 'PRODUCT.PROPERTY.PREBID_ON_PRODUCT' | translate }}">{{ 'PRODUCT.PROPERTY.PREBID_ON_PRODUCT' | translate }}</button>
                <clr-tab-content>
                  <generic-product-properties-component [languages]="languages" [data]="model.prebidOnProductBuyer" (onDataChanged)="save()" [productPropertyTypes]="productPropertyTypes" [productProperties]="model.productProperties" [masterData]="masterDataLists"></generic-product-properties-component>
                </clr-tab-content>
              </clr-tab>

              <clr-tab>
                <button clrTabLink title="{{ 'PRODUCT.PROPERTY.POPUP' | translate }}">{{ 'PRODUCT.PROPERTY.POPUP' | translate }}</button>
                <clr-tab-content>
                  <generic-product-properties-component [automaticProductPropertyNonGroupingSelections]="model.automaticProductPropertyNonGroupingSelections"
                                                        [manualProductPropertyNonGroupingSelections]="model.manualProductPropertyNonGroupingSelections"
                                                        [languages]="languages" [data]="model.buyerPopup" (onDataChanged)="save()" [productPropertyTypes]="productPropertyTypes" [productProperties]="model.productProperties" [masterData]="masterDataLists"></generic-product-properties-component>
                </clr-tab-content>
              </clr-tab>
              <clr-tab>
                <button clrTabLink title="{{ 'PRODUCT.PROPERTY.GROUP_LEVEL_POPUP' | translate }}">{{ 'PRODUCT.PROPERTY.GROUP_LEVEL_POPUP' | translate }}</button>
                <clr-tab-content>
                  <generic-product-properties-component [propertyGroupingShowing]="true" [showOnlyAutomaticGrouping]="true"
                                                        [automaticProductPropertyNonGroupingSelections]="model.automaticProductPropertyNonGroupingSelections"
                                                        [manualProductPropertyNonGroupingSelections]="model.manualProductPropertyNonGroupingSelections"
                                                        [languages]="languages" [data]="model.buyerPopupGroupLevel" (onDataChanged)="save()" [productPropertyTypes]="productPropertyTypes" [productProperties]="model.productProperties" [masterData]="masterDataLists"></generic-product-properties-component>
                </clr-tab-content>
              </clr-tab>
              <clr-tab>
                <button clrTabLink title="{{ 'PRODUCT.PROPERTY.CLOCK_FACE_PROPERTIES' | translate }}">{{ 'PRODUCT.PROPERTY.CLOCK_FACE_PROPERTIES' | translate }}</button>
                <clr-tab-content>
                  <current-lot-properties-component [propertyGroupingShowing]="false"
                                                    [languages]="languages" [data]="model.clockFaceBuyerProperties" (dataChanged)="save()"
                                                    [propertyGroups]="model.productPropertyGroups" [isEditorProperty]="false" [isClockFaceProperty]="true"
                                                    [productPropertyTypes]="productPropertyTypes" [productProperties]="model.productProperties"
                                                    [productPropertyStylings]="productPropertyStylings"
                                                    [masterData]="masterDataLists"></current-lot-properties-component>
                </clr-tab-content>
              </clr-tab>
              <clr-tab>
                <button clrTabLink
                  title="{{ 'PRODUCT.PROPERTY.PACKAGE_TYPE' | translate }}">{{ 'PRODUCT.PROPERTY.PACKAGE_TYPE' | translate }}</button>
                <clr-tab-content>
                  <generic-product-properties-component [languages]="languages" [data]="model.packageTypeBuyerProperties" (onDataChanged)="save()"
                                                        [productProperties]="model.productProperties" [productPropertyTypes]="productPropertyTypes"
                                                        [masterData]="masterDataLists"></generic-product-properties-component>
                </clr-tab-content>
              </clr-tab>
            </clr-tabs>
          </div>
        </clr-tab-content>
      </clr-tab>

      <clr-tab>
        <button clrTabLink title="{{ 'PRODUCT.PROPERTY.CLOCK_UI' | translate }}">{{ 'PRODUCT.PROPERTY.CLOCK_UI' | translate }}</button>
        <clr-tab-content>
          <clr-alert *ngIf="!model.productId" [clrAlertType]="'danger'" [clrAlertClosable]="false">
            <div class="alert-item">
              <span class="alert-text">
                {{ 'PRODUCT.ADD_PRODUCT_BEFORE_DEFINING_CLOCK_UI' | translate }}
              </span>
            </div>
          </clr-alert>
          <div *ngIf="model.productId">
            <div class="price-overview">
              <div style="width:30%;">
                <div class="clr-form-control">
                  <label class="clr-control-label" for="isRectangle">{{ 'PRODUCT.PROPERTY.CLOCK_UI_IS_RECTANGLE' | translate }}</label>
                  <div class="clr-control-container">
                    <clr-checkbox-wrapper>
                      <input type="checkbox" id="isRectangle" clrCheckbox name="isRectangle" [(ngModel)]="model.clockUIStyle.isRectangle" />
                    </clr-checkbox-wrapper>
                  </div>
                </div>

                <div class="clr-form-control">
                  <label for="clockBottomMargin" class="clr-control-label">{{ 'PRODUCT.PROPERTY.CLOCK_UI_CLOCK_BOTTOM_MARGIN' | translate }}</label>
                  <div class="clr-control-container">
                    <div class="clr-input-wrapper">
                      <input id="clockBottomMargin" type="number" name="clockBottomMargin" class="clr-input" [(ngModel)]="model.clockUIStyle.clockBottomMargin" min="0" />
                    </div>
                  </div>
                </div>

                <div class="clr-form-control">
                  <label for="clockLeftMargin" class="clr-control-label">{{ 'PRODUCT.PROPERTY.CLOCK_UI_CLOCK_LEFT_MARGIN' | translate }}</label>
                  <div class="clr-control-container">
                    <div class="clr-input-wrapper">
                      <input id="clockLeftMargin" type="number" name="clockLeftMargin" class="clr-input" [(ngModel)]="model.clockUIStyle.clockLeftMargin" min="0" />
                    </div>
                  </div>
                </div>

                <div class="clr-form-control">
                  <label for="clockRightMargin" class="clr-control-label">{{ 'PRODUCT.PROPERTY.CLOCK_UI_CLOCK_RIGHT_MARGIN' | translate }}</label>
                  <div class="clr-control-container">
                    <div class="clr-input-wrapper">
                      <input id="clockRightMargin" type="number" name="clockRightMargin" class="clr-input" [(ngModel)]="model.clockUIStyle.clockRightMargin" min="0" />
                    </div>
                  </div>
                </div>

                <div class="clr-form-control">
                  <label for="clockTopMargin" class="clr-control-label">{{ 'PRODUCT.PROPERTY.CLOCK_UI_CLOCK_TOP_MARGIN' | translate }}</label>
                  <div class="clr-control-container">
                    <div class="clr-input-wrapper">
                      <input id="clockTopMargin" type="number" name="clockTopMargin" class="clr-input" [(ngModel)]="model.clockUIStyle.clockTopMargin" min="0" />
                    </div>
                  </div>
                </div>
              </div>

              <div style="width:30%;">
                <div class="form-group">
                  <label>{{ 'PRODUCT.PROPERTY.CLOCK_UI_DOT_ACTIVE_COLOR' | translate }}</label>
                  <dx-color-box name="dotActiveColor" [(ngModel)]="model.clockUIStyle.dotActiveColor"></dx-color-box>
                </div>
                <div class="form-group">
                  <label>{{ 'PRODUCT.PROPERTY.CLOCK_UI_DOT_INACTIVE_COLOR' | translate }}</label>
                  <dx-color-box name="dotInactiveColor" [(ngModel)]="model.clockUIStyle.dotInactiveColor"></dx-color-box>
                </div>
                <div class="form-group">
                  <label>{{ 'PRODUCT.PROPERTY.CLOCK_UI_DOT_BORDER_COLOR' | translate }}</label>
                  <dx-color-box name="dotBorderColor" [(ngModel)]="model.clockUIStyle.dotBorderColor"></dx-color-box>
                </div>
                <div class="form-group">
                  <label>{{ 'PRODUCT.PROPERTY.CLOCK_UI_DOT_PRICE_INDICATION_LINE_COLOR' | translate }}</label>
                  <dx-color-box name="dotPriceIndicationLineColor" [(ngModel)]="model.clockUIStyle.dotPriceIndicationLineColor"></dx-color-box>
                </div>
                <div class="form-group">
                  <label>{{ 'PRODUCT.PROPERTY.CLOCK_UI_DOT_PRICE_INDICATION_FONT_COLOR' | translate }}</label>
                  <dx-color-box name="dotPriceIndicationFontColor" [(ngModel)]="model.clockUIStyle.dotPriceIndicationFontColor"></dx-color-box>
                </div>
                <div class="form-group">
                  <label>{{ 'PRODUCT.PROPERTY.CLOCK_UI_DOT_TENFOLD_COLOR' | translate }}</label>
                  <dx-color-box name="dotTenfoldColor" [(ngModel)]="model.clockUIStyle.dotTenfoldColor"></dx-color-box>
                </div>
                <div class="form-group">
                  <label>{{ 'PRODUCT.PROPERTY.CLOCK_UI_WAIT_TIME_FILL_COLOR' | translate }}</label>
                  <dx-color-box name="waitTimeFillColor" [(ngModel)]="model.clockUIStyle.waitTimeFillColor"></dx-color-box>
                </div>
                <div class="form-group">
                  <label>{{ 'PRODUCT.PROPERTY.CLOCK_UI_WAIT_TIME_STROKE_COLOR' | translate }}</label>
                  <dx-color-box name="waitTimeStrokeColor" [(ngModel)]="model.clockUIStyle.waitTimeStrokeColor"></dx-color-box>
                </div>
              </div>

              <div style="width:40%; margin-left: 10px;">
                <div class="clr-form-control">
                  <label for="cssContent" class="clr-control-label" style="width: 4.5rem;">{{ 'PRODUCT.PROPERTY.CLOCK_UI_CSS_CONTENT' | translate }}</label>
                  <div class="clr-control-container" style="width: 100%;">
                    <div class="clr-textarea-wrapper" style="width: 100%;">
                      <textarea id="cssContent" name="cssContent" class="clr-textarea" [(ngModel)]="model.clockUIStyle.cssContent" rows="20" style="width: 100%;"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </clr-tab-content>
      </clr-tab>
      <clr-tab>
        <button clrTabLink title="{{ 'PRODUCT.PROPERTY.APP_BUYER' | translate }}">{{ 'PRODUCT.PROPERTY.APP_BUYER' | translate }}</button>
        <clr-tab-content>
          <div class="tab-content">
            <clr-alert *ngIf="!model.productId" [clrAlertType]="'danger'" [clrAlertClosable]="false">
              <div class="alert-item">
                <span class="alert-text">
                  {{ 'PRODUCT.ADD_PRODUCT_BEFORE_BUYER_PROPERTIES' | translate }}
                </span>
              </div>
            </clr-alert>
            <clr-tabs *ngIf="model.productId">
              <clr-tab>
                <button clrTabLink title="{{ 'PRODUCT.PROPERTY.NEXT_LOT_PROPERTIES' | translate }}">{{ 'PRODUCT.PROPERTY.NEXT_LOT_PROPERTIES' | translate }}</button>
                <clr-tab-content>
                  <generic-product-properties-component [propertyGroupingShowing]="true"
                                                        [automaticProductPropertyNonGroupingSelections]="model.automaticProductPropertyNonGroupingSelections"
                                                        [manualProductPropertyNonGroupingSelections]="model.manualProductPropertyNonGroupingSelections"
                                                        [languages]="languages" [data]="model.nextLotAppBuyerProperties" (onDataChanged)="save()"
                                                        [productPropertyTypes]="productPropertyTypes" [productProperties]="model.productProperties"
                                                        [masterData]="masterDataLists" [showWidth]="true" [showColRow]="true"></generic-product-properties-component>
                </clr-tab-content>
              </clr-tab>
              <clr-tab>
                <button clrTabLink title="{{ 'PRODUCT.PROPERTY.CURRENT_LOT_PROPERTIES' | translate }}">{{ 'PRODUCT.PROPERTY.CURRENT_LOT_PROPERTIES' | translate }}</button>
                <clr-tab-content>
                  <current-lot-properties-component [propertyGroupingShowing]="true"
                                                    [automaticProductPropertyNonGroupingSelections]="model.automaticProductPropertyNonGroupingSelections"
                                                    [manualProductPropertyNonGroupingSelections]="model.manualProductPropertyNonGroupingSelections"
                                                    [languages]="languages" [data]="model.currentLotAppBuyerProperties" (dataChanged)="save()"
                                                    [propertyGroups]="model.productPropertyGroups" [isEditorProperty]="false"
                                                    [productPropertyTypes]="productPropertyTypes" [productProperties]="model.productProperties"
                                                    [masterData]="masterDataLists" [showWidth]="true"></current-lot-properties-component>
                </clr-tab-content>
              </clr-tab>
              <clr-tab>
                <button clrTabLink title="{{ 'PRODUCT.PROPERTY.TRANSACTION_INFO' | translate }}">{{ 'PRODUCT.PROPERTY.TRANSACTION_INFO' | translate }}</button>
                <clr-tab-content>
                  <transaction-properties-component [propertyGroupingShowing]="true"
                                                    [automaticProductPropertyNonGroupingSelections]="model.automaticProductPropertyNonGroupingSelections"
                                                    [manualProductPropertyNonGroupingSelections]="model.manualProductPropertyNonGroupingSelections"
                                                    [languages]="languages" [data]="model.transactionInfoAppBuyerProperties" (dataChanged)="save()"
                                                    [productPropertyTypes]="productPropertyTypes" [productProperties]="model.productProperties"
                                                    [masterData]="masterDataLists" [showWidth]="true" [showColRow]="true"></transaction-properties-component>
                </clr-tab-content>
              </clr-tab>
              <clr-tab>
                <button clrTabLink title="{{ 'PRODUCT.PROPERTY.CLOCK_FACE_PROPERTIES' | translate }}">{{ 'PRODUCT.PROPERTY.CLOCK_FACE_PROPERTIES' | translate }}</button>
                <clr-tab-content>
                  <current-lot-properties-component [propertyGroupingShowing]="false"
                                                    [languages]="languages" [data]="model.appClockFaceBuyerProperties" (dataChanged)="save()"
                                                    [propertyGroups]="model.productPropertyGroups" [isEditorProperty]="false" [isClockFaceProperty]="true"
                                                    [productPropertyTypes]="productPropertyTypes" [productProperties]="model.productProperties"
                                                    [productPropertyStylings]="productPropertyStylings"
                                                    [masterData]="masterDataLists"></current-lot-properties-component>
                </clr-tab-content>
              </clr-tab>
            </clr-tabs>
          </div>
        </clr-tab-content>
      </clr-tab>
      <clr-tab>
        <button clrTabLink title="{{ 'PRODUCT.PROPERTY.BUY_BUTTONS' | translate }}">{{ 'PRODUCT.PROPERTY.BUY_BUTTONS' | translate }}</button>
        <clr-tab-content>
          <div class="tab-content">
            <product-buy-buttons-component [productBuyButtons]="model.productBuyButtons" (dataChanged)="save()"></product-buy-buttons-component>
          </div>
        </clr-tab-content>
      </clr-tab>
    </clr-tabs>
  </form>
  <div class="button-group">
    <button type="submit" class="btn btn-primary" (click)="onSubmit();" [disabled]="!detailsForm.form.valid || !productName?.valid">{{ 'SAVE' | translate }}</button>
    <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'CANCEL' | translate }}</button>
  </div>
</div>
