<div class="master-data-list-row-editor-component item-list-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup #editMasterDataListRow [width]="600" [height]="600" [showTitle]="true" title="{{ modalTitle }}" [dragEnabled]="false"
    [closeOnOutsideClick]="false" [rtlEnabled]="rtlEnabled" [(visible)]="isOpened">
    <dx-scroll-view width="100%" height="100%">

      <div *ngFor="let field of masterDataFields; let i = index">
        <div class="form-group" *ngIf="field.propertyTypeId === 1">
          <label>{{ field.name | translateJson }}</label>
          <dx-number-box [(value)]="model.values[i].intValue" [disabled]="isSystemList() && field.fieldType!==0 && field.fieldType!==1"></dx-number-box>
        </div>
        <div class="form-group" *ngIf="field.propertyTypeId === 2">
          <label>{{ field.name | translateJson }}</label>
          <dx-number-box [(value)]="model.values[i].decimalValue" [disabled]="isSystemList() && field.fieldType!==0 && field.fieldType!==1"></dx-number-box>
        </div>
        <div class="form-group" *ngIf="field.propertyTypeId === 3 && !field.translatable">
          <label>{{ field.name | translateJson }}</label>
          <dx-text-box name="{{'stringValue' + i}}" [(value)]="model.values[i].stringValue" [disabled]="isSystemList() && field.fieldType!==0"></dx-text-box>
        </div>
        <div class="form-group translatable-group" *ngIf="field.propertyTypeId === 3 && field.translatable">
          <label>{{ field.name | translateJson }}</label>
          <translatable-field [(data)]="model.values[i].stringValue" [isDevExpress]="true" [isLabelHidden]="true" [componentIndex]="i" [name]="'translatable' + i" [disabled]="(field.fieldType!==0) ? true : false" [clusterLanguages]="languages"></translatable-field>
        </div>
        <div class="form-group" *ngIf="field.propertyTypeId === 4 && field.propertyTypeFormatId === 11">
          <label>{{ field.name | translateJson }}</label>
          <full-date-time-component [data]="model.values[i].dateTimeValue" (modelChanged)="setDateTimeValue(i, $event)" [disabled]="isSystemList() && (field.fieldType!==0) ? true : false"></full-date-time-component>
        </div>
        <div class="form-group" *ngIf="field.propertyTypeId === 4 && field.propertyTypeFormatId === 12">
          <label>{{ field.name | translateJson }}</label>
          <long-date-component [data]="model.values[i].dateTimeValue" (modelChanged)="setDateTimeValue(i, $event)" [disabled]="isSystemList() && (field.fieldType!==0) ? true : false"></long-date-component>
        </div>
        <div class="form-group" *ngIf="field.propertyTypeId === 4 && field.propertyTypeFormatId === 13">
          <label>{{ field.name | translateJson }}</label>
          <long-time-component [data]="model.values[i].dateTimeValue" (modelChanged)="setDateTimeValue(i, $event)" [disabled]="isSystemList() && (field.fieldType!==0) ? true : false"></long-time-component>
        </div>
        <div class="form-group" *ngIf="field.propertyTypeId === 4 && field.propertyTypeFormatId === 14">
          <label>{{ field.name | translateJson }}</label>
          <short-date-component [data]="model.values[i].dateTimeValue" (modelChanged)="setDateTimeValue(i, $event)" [disabled]="isSystemList() && (field.fieldType!==0) ? true : false"></short-date-component>
        </div>
        <div class="form-group" *ngIf="field.propertyTypeId === 4 && field.propertyTypeFormatId === 15">
          <label>{{ field.name | translateJson }}</label>
          <short-time-component [data]="model.values[i].dateTimeValue" (modelChanged)="setDateTimeValue(i, $event)" [disabled]="isSystemList() && (field.fieldType!==0) ? true : false"></short-time-component>
        </div>
        <div class="form-group" *ngIf="field.propertyTypeId === 6">
          <label>{{field.name | translateJson }}</label>
          <label for="field" aria-haspopup="true" role="tooltip" class="tooltip tooltip-bottom-left tooltip-validation tooltip-md">
            <input name="image" class="focusable file-input-button" (input)="createImage(i, $event)" type="file" accept="image/*" #fileInput />
            <button class="btn btn-primary" (click)="fileInput.click()" *ngIf="!model.values[i].imageValue" [disabled]="isSystemList() && field.fieldType!==0">{{ 'SELECT_IMAGE' | translate }}</button>
            <img class="image-field" [src]="model.values[i].imageValue" *ngIf="model.values[i].imageValue" />
          </label>
        </div>
        <div class="form-group" *ngIf="field.propertyTypeId === 7">
          <label>{{field.name | translateJson }}</label>
          <dx-check-box name="{{'booleanValue' + i}}"
                        [(value)]="model.values[i].booleanValue"
                        [disabled]="isSystemList() && field.fieldType!==0 && field.fieldType!==16"> <!---fileldType 16 is 'AvailableInTouchMode' system field-->
          </dx-check-box>
        </div>
        <div class="form-group" *ngIf="field.propertyTypeId === 9">
          <label>{{field.name | translateJson }}</label>
          <dx-color-box [(ngModel)]="model.values[i].stringValue"></dx-color-box>
        </div>
        <div class="form-group" *ngIf="field.propertyTypeId === 8 && field.propertyTypeFormatId === 17">
          <label>{{field.name | translateJson }}</label>
          <div class="photo-thumbnail">            
            <dx-text-box [(value)]="model.values[i].displayUrlTextValue" [readOnly]="true"></dx-text-box>
            <label for="{{'urlValue' + i}}">
              <img [src]="model.values[i].displayUrlImageValue"/>
            </label>
            <input id="{{'urlValue' + i}}" name="{{'urlValue' + i}}" type="file" accept=".png, .jpg, .jpeg, image/*"
              (input)="onImageChanged($event, i)"/>        
            <dx-button class="clearButton" icon="clear" (click)="clearImage(i);"></dx-button>
          </div>
        </div>
      </div>
      <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnMasterDataListRowSubmit">{{ 'SAVE' | translate }}</button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'CANCEL' | translate }}</button>
    </dx-scroll-view>
  </dx-popup>  
</div>
