import { ActivatedRoute } from "@angular/router";
import { MasterDataService as AuctionMasterDataService } from '../../auction/shared/services/master-data.service';
import { MasterDataService as BuyerMasterDataService } from '../../buyer/shared/services/master-data.service';
import { MasterDataService as SupplierMasterDataService } from '../../supplier/shared/services/master-data.service';
import { IMasterDataService } from '../interfaces/master-data';
import { ApplicationSettings } from "../models/application-settings";
import { WebApiService } from "../services/web-api.service";
import { AuctionClusterReportService } from "../../auction/shared/services/report.service";
import { BuyerLevelReportService } from "../../buyer/shared/services/report.service";
import { SupplierReportService } from "../../supplier/shared/services/report.service";
import { PlatformReportService } from "../../platform/shared/services/platform-report.service";
import { IReportService } from "../interfaces/report";
import { ILookupTableService } from "../interfaces/lookup-table";
import { LookupTableService as AuctionLookupTableService } from '../../auction/shared/services/lookup-table.service';
import { LookupTableService as BuyerLookupTableService } from '../../buyer/shared/services/lookup-table.service';
import { LookupTableService as SupplierLookupTableService } from '../../supplier/shared/services/lookup-table.service';
import { IAuctionService } from "../interfaces/auction";
import { AuctionClusterAuctionService } from "../../auction/shared/services/auction-cluster-auction.service";
import { AuctionService as BuyerLevelAuctionService } from "../../buyer/shared/services/auction.service";
import { AuctionService as SupplierAuctionService } from "../../supplier/shared/services/auction.service";
import { ICatalogService } from "../interfaces/catalog";
import { CatalogService as AuctionCatalogService } from "../../auction/shared/services/catalog.service";
import { CatalogService as BuyerCatalogService } from "../../buyer/shared/services/catalog.service";
import { CatalogService as SupplierCatalogService } from "../../supplier/shared/services/catalog.service";
import { IProductService } from "../interfaces/product";
import { ProductService as AuctionProductService } from "../../auction/shared/services/product.service";
import { ProductService as BuyerProductService } from "../../buyer/shared/services/product.service";
import { ProductService as SupplierProductService } from "../../supplier/shared/services/product.service";
import { IAuctionClusterService } from "../interfaces/auction-cluster";
import { AuctionClusterService as PlatformLevelAuctionClusterService } from "../../platform/shared/services/auction-cluster.service";
import { AuctionClusterService as AuctionClusterLevelAuctionClusterService } from "../../auction/shared/services/auction-cluster.service";
import { AuctionClusterService as BuyerLevelAuctionClusterService } from "../../buyer/shared/services/auction-cluster.service";
import { AuctionClusterService as SupplierLevelAuctionClusterService } from "../../supplier/shared/services/auction-cluster.service";
import { PublicDataService } from "../services/public-data-service";

export class ServiceFactory{
    
    
    static getMasterDataService(
        route: ActivatedRoute,
        appSettings: ApplicationSettings,
        webApiService: WebApiService
    ): IMasterDataService {
        if(route.snapshot.parent.url.toString() === 'auction' || route.snapshot.parent.url.toString() === 'platform'){
            return new AuctionMasterDataService(appSettings, webApiService);
        } else if(route.snapshot.parent.parent.url[0].toString() === 'buyer'){
            let buyerId = route.snapshot.params['id'];
            let service = new BuyerMasterDataService(appSettings, webApiService);
            service.setId(buyerId);
            return service;
        } else if(route.snapshot.parent.parent.url[0].toString() === 'supplier'){
            let supplierId = route.snapshot.params['id'];
            let service = new SupplierMasterDataService(appSettings, webApiService);
            service.setId(supplierId);
            return service;
        }
    }

    static getReportService(
        route: ActivatedRoute,
        appSettings: ApplicationSettings,
        webApiService: WebApiService
    ): IReportService {
        if(route.snapshot.parent.url.toString() === 'auction'){
            let auctionClusterId = route.snapshot.params['id'];
            let service = new AuctionClusterReportService(appSettings, webApiService);
            service.setAuctionClusterId(auctionClusterId);
            return service;
        } else if(route.snapshot.parent.parent.url[0].toString() === 'buyer'){
            let buyerId = route.snapshot.params['id'];
            let service = new BuyerLevelReportService(appSettings, webApiService);
            service.setId(buyerId);
            return service;
        } else if(route.snapshot.parent.parent.url[0].toString() === 'supplier'){
            let supplierId = route.snapshot.params['id'];
            let service = new SupplierReportService(appSettings, webApiService);
            service.setId(supplierId);
            return service;
        } else if(route.snapshot.parent.parent.url[0].toString() === 'platform'){
            return new PlatformReportService(appSettings, webApiService);
        } else if(route.snapshot.parent.parent.url[0].toString() === 'core'){
            return new PublicDataService(appSettings, webApiService);
        }
    
    }

    static getLookupTableService(
        route: ActivatedRoute,
        appSettings: ApplicationSettings,
        webApiService: WebApiService
    ): ILookupTableService {
        if(route.snapshot.parent.url.toString() === 'auction'){
            return new AuctionLookupTableService(appSettings, webApiService);
        } else if(route.snapshot.parent.parent.url[0].toString() === 'buyer'){
            let buyerId = route.snapshot.params['id'];
            let service = new BuyerLookupTableService(appSettings, webApiService);
            service.setId(buyerId);
            return service;
        } else if(route.snapshot.parent.parent.url[0].toString() === 'supplier'){
            let supplierId = route.snapshot.params['id'];
            let service = new SupplierLookupTableService(appSettings, webApiService);
            service.setId(supplierId);
            return service;
        }
        else{
            return null;
        }
    }

    static getAuctionService(
        route: ActivatedRoute,
        appSettings: ApplicationSettings,
        webApiService: WebApiService
    ): IAuctionService {
        if(route.snapshot.parent.url.toString() === 'auction' || route.snapshot.parent.url.toString() === 'platform'){
            return new AuctionClusterAuctionService(appSettings, webApiService);
        } else if(route.snapshot.parent.parent.url[0].toString() === 'buyer'){
            let buyerId = route.snapshot.params['id'];
            let service =  new BuyerLevelAuctionService(appSettings, webApiService);
            service.setId(buyerId);
            return service;
        } else if(route.snapshot.parent.parent.url[0].toString() === 'supplier'){
            let supplierId = route.snapshot.params['id'];
            let service = new SupplierAuctionService(appSettings, webApiService);
            service.setId(supplierId);
            return service;
        }else if(route.snapshot.parent.parent.url[0].toString() === 'core'){
            return new PublicDataService(appSettings, webApiService);
        }
    }

    static getCatalogService(
        route: ActivatedRoute,
        appSettings: ApplicationSettings,
        webApiService: WebApiService
    ): ICatalogService {
        if(route.snapshot.parent.url.toString() === 'auction' || route.snapshot.parent.url.toString() === 'platform'){
            return new AuctionCatalogService(appSettings, webApiService);
        } else if(route.snapshot.parent.parent.url[0].toString() === 'buyer'){
            let buyerId = route.snapshot.params['id'];
            let service = new BuyerCatalogService(appSettings, webApiService);
            service.setId(buyerId);
            return service;
        } else if(route.snapshot.parent.parent.url[0].toString() === 'supplier'){
            let supplierId = route.snapshot.params['id'];
            let service = new SupplierCatalogService(appSettings, webApiService);
            service.setId(supplierId);
            return service;
        }else if(route.snapshot.parent.parent.url[0].toString() === 'core'){
            return new PublicDataService(appSettings, webApiService);
        }
    }

    static getProductService(
        route: ActivatedRoute,
        appSettings: ApplicationSettings,
        webApiService: WebApiService
    ): IProductService {
        if(route.snapshot.parent.url.toString() === 'auction' || route.snapshot.parent.url.toString() === 'platform'){
            return new AuctionProductService(appSettings, webApiService);
        } else if(route.snapshot.parent.parent.url[0].toString() === 'buyer'){
            let buyerId = route.snapshot.params['id'];
            let service = new BuyerProductService(appSettings, webApiService);
            service.setId(buyerId);
            return service;
        } else if(route.snapshot.parent.parent.url[0].toString() === 'supplier'){
            let supplierId = route.snapshot.params['id'];
            let service = new SupplierProductService(appSettings, webApiService);
            service.setId(supplierId);
            return service;
        }else if(route.snapshot.parent.parent.url[0].toString() === 'core'){
            return new PublicDataService(appSettings, webApiService);
        }
    }

    static getAuctionClusterService(
        url: string,
        route: ActivatedRoute,
        appSettings: ApplicationSettings,
        webApiService: WebApiService
    ) : IAuctionClusterService {
        if (url.indexOf('platform') > -1) {
            return new PlatformLevelAuctionClusterService(appSettings, webApiService);
        } else if (url.indexOf('auction') > -1) {
            let auctionClusterId = route.firstChild.snapshot.params['id'];
            let service = new AuctionClusterLevelAuctionClusterService(appSettings, webApiService);
            service.setId(auctionClusterId);
            return service;
        } else if (url.indexOf('buyer') > -1) {
            let buyerId = route.firstChild.snapshot.params['id'];
            let service = new BuyerLevelAuctionClusterService(appSettings, webApiService);
            service.setId(buyerId);
            return service;
        } else if (url.indexOf('supplier') > -1) {
            let supplierId = route.firstChild.snapshot.params['id'];
            let service = new SupplierLevelAuctionClusterService(appSettings, webApiService);
            service.setId(supplierId);
            return service;
        }
    }
}
