<div class="master-data-field-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup #editMasterDataField [width]="600" [showTitle]="true" title="{{ modalTitle }}" [dragEnabled]="false"
    [closeOnOutsideClick]="false" [rtlEnabled]="rtlEnabled" [(visible)]="isOpened"
    (onShown)="onFieldDataChanged($event)">
    <dx-scroll-view width="100%" height="100%">

      <dx-form #masterDataFieldForm id="masterDataFieldForm" [showValidationSummary]="false"
        (onFieldDataChanged)="onFieldDataChanged($event)" colCount="auto" [(formData)]="model" [minColWidth]="500">

              <dxi-item dataField="name" [template]="'masterDataFieldNameField'">
                <dxo-label text="{{ 'MASTER_DATA.FIELD.NAME' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'MASTER_DATA.NAME_VALIDATION' | translate }}"></dxi-validation-rule>
              </dxi-item>
              <dxi-item dataField="description" [template]="'masterDataFieldDescriptionField'">
                <dxo-label text="{{ 'MASTER_DATA.FIELD.DESCRIPTION' | translate }}"></dxo-label>
              </dxi-item>

              <dxi-item dataField="sysName">
                <dxo-label text="{{ 'PRODUCT.PROPERTY.SYSTEM_NAME' | translate }}"></dxo-label>
              </dxi-item>

              <dxi-item dataField="unique" editorType="dxCheckBox">
                <dxo-label text="{{ 'MASTER_DATA.FIELD.UNIQUE' | translate }}"></dxo-label>
              </dxi-item>

              <dxi-item dataField="required" editorType="dxCheckBox">
                <dxo-label text="{{ 'MASTER_DATA.FIELD.REQUIRED' | translate }}"></dxo-label>
              </dxi-item>

              <dxi-item dataField="useFieldInCatalog" editorType="dxCheckBox">
                <dxo-label text="{{ 'MASTER_DATA.FIELD.USE_FIELD_IN_CATALOG' | translate }}"></dxo-label>
              </dxi-item>

              <dxi-item dataField="propertyTypeId" editorType="dxSelectBox" 
                [editorOptions]="{ items: productPropertyTypes, valueExpr:'propertyTypeId', displayExpr:'name', onSelectionChanged: onTypeChanged}">
                <dxo-label text="{{ 'MASTER_DATA.FIELD.TYPE' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'MASTER_DATA.FIELD.TYPE_VALIDATION' | translate }}">
                </dxi-validation-rule>
              </dxi-item>

              <dxi-item dataField="fieldType" editorType="dxSelectBox" 
                [editorOptions]="{ items: fieldTypes, valueExpr:'systemMasterDataListFieldId', displayExpr:'name'}">
                <dxo-label text="{{ 'MASTER_DATA.FIELD.SYSTEM_FIELD_TYPE' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'MASTER_DATA.FIELD.SYSTEM_FIELD_TYPE_VALIDATION' | translate }}">
                </dxi-validation-rule>
              </dxi-item>

              
              <dxi-item dataField="propertyTypeFormatId" editorType="dxSelectBox" 
                [editorOptions]="{ items: dataFormats, valueExpr:'propertyTypeFormatId', displayExpr:'name', showClearButton: 'true', visible: (model.propertyTypeId === 2)}">
                <dxo-label text="{{ 'PRODUCT.PROPERTY.DECIMAL_PLACES' | translate }}" [visible]="model.propertyTypeId === 2"></dxo-label>
              </dxi-item>

              <dxi-item dataField="propertyTypeFormatId" editorType="dxSelectBox" 
                [editorOptions]="{ items: dataFormats, valueExpr:'propertyTypeFormatId', displayExpr:'name', showClearButton: 'true', visible: (model.propertyTypeId === 4)}">
                <dxo-label text="{{ 'PRODUCT.PROPERTY.DATE_FORMAT' | translate }}" [visible]="model.propertyTypeId === 4"></dxo-label>
              </dxi-item>

              <dxi-item dataField="propertyTypeFormatId" editorType="dxSelectBox" 
                [editorOptions]="{ items: dataFormats, valueExpr:'propertyTypeFormatId', displayExpr:'name', showClearButton: 'true', visible: (model.propertyTypeId === 8), onSelectionChanged: onUrlFormatChanged}">
                <dxo-label text="{{ 'PRODUCT.PROPERTY.URL_FORMAT' | translate }}" [visible]="model.propertyTypeId === 8"></dxo-label>
              </dxi-item>

              <dxi-item dataField="imageCaptionId" editorType="dxSelectBox" 
                [editorOptions]="{ items: availableFieldsForCaption, valueExpr:'masterDataListFieldId', displayExpr:'masterDataFieldName', showClearButton: 'true', visible: (model.propertyTypeId === 8 && model.propertyTypeFormatId === 17)}">
                <dxo-label text="{{ 'MASTER_DATA.FIELD.IMAGE_CAPTION' | translate }}" [visible]="model.propertyTypeId === 8 && model.propertyTypeFormatId === 17"></dxo-label>
              </dxi-item>

              <dxi-item dataField="maxLength" editorType="dxNumberBox" [visible]="model.propertyTypeId === 3">
                <dxo-label text="{{ 'MASTER_DATA.FIELD.MAX_LENGTH' | translate }}" [visible]="model.propertyTypeId === 3"></dxo-label>
              </dxi-item>

              <dxi-item dataField="translatable" editorType="dxCheckBox" [visible]="model.propertyTypeId === 3">
                <dxo-label text="{{ 'MASTER_DATA.FIELD.TRANSLATABLE' | translate }}" [visible]="model.propertyTypeId === 3"></dxo-label>
              </dxi-item>

              <dxi-item dataField="minValue" editorType="dxNumberBox" [visible]="(model.propertyTypeId === 1 || model.propertyTypeId === 2)">
                <dxo-label text="{{ 'MASTER_DATA.FIELD.MIN_VALUE' | translate }}" [visible]="(model.propertyTypeId === 1 || model.propertyTypeId === 2)"></dxo-label>
              </dxi-item>

              <dxi-item dataField="maxValue" editorType="dxNumberBox" [visible]="(model.propertyTypeId === 1 || model.propertyTypeId === 2)">
                <dxo-label text="{{ 'MASTER_DATA.FIELD.MAX_VALUE' | translate }}" [visible]="(model.propertyTypeId === 1 || model.propertyTypeId === 2)"></dxo-label>
              </dxi-item>

              <dxi-item dataField="dateValue" editorType="dxDateBox" [visible]="(model.propertyTypeId === 4)">
                <dxo-label text="{{ 'MASTER_DATA.FIELD.DATE_VALUE' | translate }}" [visible]="(model.propertyTypeId === 4)"></dxo-label>
              </dxi-item>                 

        <div *dxTemplate="let data of 'masterDataFieldNameField'" class="translatable-template">
          <translatable-field (change)="onChangeTranslationField($event, 'name')" [isDevExpress]="true" [isLabelHidden]="true" [caption]="'MASTER_DATA.FIELD.NAME'" [clusterLanguages]="languages" [(data)]="data.component.option('formData')[data.dataField]" [name]="'name'" #masterDataFieldName></translatable-field>  
        </div>
        <div *dxTemplate="let data of 'masterDataFieldDescriptionField'" class="translatable-template">
          <translatable-field (change)="onChangeTranslationField($event, 'description')" [isDevExpress]="true" [isLabelHidden]="true" [caption]="'MASTER_DATA.FIELD.DESCRIPTION'" [clusterLanguages]="languages" [type]="'textbox'" [(data)]="data.component.option('formData')[data.dataField]" [name]="'description'" #masterDataFieldDescription></translatable-field>  
        </div>

      </dx-form>
      <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnMasterDataFieldSubmit"
        disabled="disabled">{{ 'SAVE' | translate }}</button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'CANCEL' | translate }}</button>
    </dx-scroll-view>
  </dx-popup>
</div>