import { Component, OnInit, Injector, ViewChild, OnDestroy } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import notify from 'devextreme/ui/notify';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { ConfirmationComponent } from '../../shared/components/confirmation/confirmation.component';
import { UserComponent } from './user.component';
import { AuthenticationComponent } from '../../shared/components/authentication/authentication.component';
import { TokenService } from '../../shared/services/token.service';

// models
import { AuctionCluster } from '../shared/models/auction-cluster';
import { PlatformRole } from '../shared/models/platform-role';
import { User } from '../shared/models/user';
import { UserFilter } from '../shared/models/user-filter';

import { Auction } from '../../auction/shared/models/auction';

import { ImportSchema, ImportField } from '../../shared/import/import-schema';
import { AuctionClusterRole } from '../../shared/models/auction-cluster-role';
import { Buyer } from '../../shared/models/buyer';
import { BuyerRole } from '../../shared/models/buyer-role';
import { Supplier } from '../../shared/models/supplier';
import { SupplierRole } from '../../shared/models/supplier-role';
import { PlatformPermissionEnum, AuctionClusterPermissionEnum } from '../../shared/models/user-permissions';

// services
import { AuctionClusterService } from '../shared/services/auction-cluster.service';
import { PlatformRoleService } from '../shared/services/platform-role.service';
import { UserService } from '../shared/services/user.service';

import { AuctionService } from '../../auction/shared/services/auction.service';

import { AuctionClusterRoleService } from '../../shared/services/auction-cluster-role.service';
import { BuyerService } from '../../shared/services/buyer.service';
import { BuyerRoleService } from '../../shared/services/buyer-role.service';
import { ConfigService } from '../../shared/services/config.service';
import { LanguageService } from '../../shared/services/language.service';
import { SupplierService } from '../../shared/services/supplier.service';
import { SupplierRoleService } from '../../shared/services/supplier-role.service';
import { UserService as UserSharedService } from '../../shared/services/user.service';

@Component({
  selector: 'users-component',
  templateUrl: 'users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent extends FullListComponent<User, UserComponent> implements OnInit, OnDestroy {

  platformRoles: Array<PlatformRole> = [];

  auctionClusters: Array<AuctionCluster> = [];
  auctions: Array<Auction> = [];
  auctionClusterRoles: Array<AuctionClusterRole> = [];

  buyers: Array<Buyer> = [];
  buyerRoles: Array<BuyerRole> = [];

  suppliers: Array<Supplier> = [];
  supplierRoles: Array<SupplierRole> = [];

  userToEnableDisable: User = new User();
  userToBlockUnblock: User = new User();
  user: User = new User();

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  userFilter: UserFilter;

  buyersPaginated: any = {};
  suppliersPaginated: any = {};

  @ViewChild(AuthenticationComponent) authComponent: AuthenticationComponent;
  @ViewChild('disable') disable: ConfirmationComponent;
  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: UserComponent;

  constructor(
    protected injector: Injector,
    private platformRoleService: PlatformRoleService,
    private auctionClusterService: AuctionClusterService,
    private buyerService: BuyerService,
    private supplierService: SupplierService,
    private auctionService: AuctionService,
    private dataService: UserService,
    private languageService: LanguageService,
    private auctionClusterRoleService: AuctionClusterRoleService,
    private supplierRoleService: SupplierRoleService,
    private buyerRoleService: BuyerRoleService,
    private tokenService: TokenService,
    private configService: ConfigService,
    private userService: UserSharedService

  ) {
    super(injector, User);
    this.title.set('PLATFORM.USERS');
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  ngOnInit() {
    this.userFilter = new UserFilter();

    this.route.queryParams.subscribe(params => {
      if (params['auctionCluster']) {
        this.userFilter.showAuction = true;
        this.userFilter.showBuyer = false;
        this.userFilter.showSupplier = false;
        this.userFilter.showPlatform = false;
        this.userFilter.auctionClusterId = +params['auctionCluster'];
      } else if (params['buyers']) {
        this.userFilter.showAuction = false;
        this.userFilter.showSupplier = false;
        this.userFilter.showBuyer = true;
        this.userFilter.showPlatform = false;
        this.userFilter.buyerId = +params['buyers'];
      } else if (params['suppliers']) {
        this.userFilter.showSupplier = true;
        this.userFilter.showAuction = false;
        this.userFilter.showBuyer = false;
        this.userFilter.showPlatform = false;
        this.userFilter.supplierId = +params['suppliers'];
      } else {
        this.userFilter.showActive = true;
        this.userFilter.showAuction = true;
        this.userFilter.showBlocked = true;
        this.userFilter.showBuyer = true;
        this.userFilter.showInactive = true;
        this.userFilter.showPending = true;
        this.userFilter.showPlatform = true;
        this.userFilter.showSupplier = true;
      }
    });

    this.getData();

    this.setTranslations("USERS");
  }

  // tslint:disable:no-magic-numbers
  getData(filter?: boolean) {
    this.spinner.show();

    forkJoin(
      this.platformRoleService.getPlatformRoles(),

      this.auctionClusterService.getAuctionClusters(),
      this.auctionService.getAuctions(),
      this.auctionClusterRoleService.getAuctionClusterRoles(),

      this.buyerService.getBuyersSimple(),

      this.supplierService.getSuppliersSimple(),

      this.dataService.getUsersFiltered(this.userFilter),

      this.translate.get('USERS.FILTER_SUCCESS'),
      this.userService.getCurrentUser()

    ).subscribe((result: Array<any>) => {
      this.platformRoles = result[0];

      this.auctionClusters = result[1];

      this.auctions = result[2];
      this.auctionClusterRoles = result[3];

      this.buyers = result[4];

      this.suppliers = result[5];

      this.items = result[6];

      const successMessage = result[7];

      this.user = result[8];

      this.buyersPaginated = {
        paginate: true,
        pageSize: 20,
        store: this.buyers,
        sort: 'name'
      };

      this.suppliersPaginated = {
        paginate: true,
        pageSize: 20,
        store: this.suppliers,
        sort: 'name'
      };

      var userPermissions = this.tokenService.getCurrentUserPermissions();
      
      if (!userPermissions.PlatformPermissions.some(p => p === PlatformPermissionEnum.Administrator)) {
        this.platformRoles = this.platformRoles.filter(_ => !_.permissions.some(p => p === PlatformPermissionEnum.Administrator.valueOf())); 
        this.auctionClusterRoles = this.auctionClusterRoles.filter(_ => !_.permissions.some(p => p === AuctionClusterPermissionEnum.Administrator));

        this.items = this.items.filter(_ => (_.platformRoleId === null || this.platformRoles.some(r => r.platformRoleId === _.platformRoleId)) && (_.auctionClusterRoles.length === 0 || this.auctionClusterRoles.some(r => _.auctionClusterRoles.some(acr => acr.auctionClusterRoleId === r.auctionClusterRoleId))));
      }

      this.translateUserRoles();
      this.spinner.hide();

      if (filter) {
        notify(successMessage, 'success', 5000);
      }
    },
      error => {
        notify(error.message, 'error', 5000);
        this.spinner.hide();
      });
  }
  // tslint:enable:no-magic-numbers

  editItem = (e: any) => {
    const userId = e.row !== undefined ? e.row.data.userId : e.data.userId;
    this.detailsComponent.modalTitle = this.translations.EDIT;
    this.detailsComponent.open(this.items, userId, this.platformRoles,
    this.auctionClusters, this.auctions, this.auctionClusterRoles,
    this.buyers, this.suppliers, this.user.isSystemUser);
  }

  deleteItem = (e: any) => {

    this.itemIdToDelete = e.row.data.userId;
    this.confirmation.opened = true;
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.ADD_NEW;
    this.detailsComponent.open(this.items, null, this.platformRoles,
      this.auctionClusters, this.auctions, this.auctionClusterRoles,
      this.buyers,
      this.suppliers, this.user.isSystemUser);
  }

  enableDisableUser = (e: any) => {
    const user: User = e.row.data;
    this.userToEnableDisable = user;
    this.dataService.enableDisable(this.userToEnableDisable).subscribe(() => {
      this.getData();
    },
      error => {
        notify(error.message, 'error', 5000);
      }
    );
  }

  blockUnblockUser = (e: any) => {
    const user: User = e.row.data;
    this.userToBlockUnblock = user;
    this.dataService.blockUnblock(this.userToBlockUnblock).subscribe(() => {
      this.getData();
    },
      error => {
        notify(error.message, 'error', 5000);
      }
    );
  }

  translateUserRoles() {
    this.items.forEach((user) => {
      if (user.platformRoleId) {
        user.rolesString = this.languageService.getTranslatableText(
          this.platformRoles.find(r => r.platformRoleId === user.platformRoleId).name);
      }

      user.auctionClusterRoles.forEach((role) => {
        const roleId = this.auctionClusterRoles.find(r => r.auctionClusterRoleId === role.auctionClusterRoleId);
        if (user.rolesString) {
          user.rolesString += ', ' + this.languageService.getTranslatableText(roleId.name);
        } else {
          user.rolesString = this.languageService.getTranslatableText(roleId.name);
        }
      });

      user.buyerRoles.forEach((role) => {
        const roleId = role.buyerRole; //this.buyerRoles.find(r => r.buyerRoleId === role.buyerRoleId);
        if (user.rolesString) {
          user.rolesString += ', ' + this.languageService.getTranslatableText(roleId.name);
        } else {
          user.rolesString = this.languageService.getTranslatableText(roleId.name);
        }
      });

      user.supplierRoles.forEach((role) => {
        const roleId = role.supplierRole;//this.supplierRoles.find(r => r.supplierRoleId === role.supplierRoleId);
        if (user.rolesString) {
          user.rolesString += ', ' + this.languageService.getTranslatableText(roleId.name);
        } else {
          user.rolesString = this.languageService.getTranslatableText(roleId.name);
        }
      });
    });
  }

  deleteSelected() {
    this.spinner.show();
    this.dataService.delete(this.itemIdToDelete)
      .subscribe((users: Array<User>) => {
        this.getData();
        this.spinner.hide();
      },
        error => {
          notify(error.message, 'error', 5000);
          this.spinner.hide();
        });
  }

  createDataSchema() {
    const schema = new ImportSchema();

    const isActive = new ImportField('isActive', this.translations.ACTIVE);
    isActive.required = true;

    const isBlocked = new ImportField('isBlocked', this.translations.BLOCKED);
    isBlocked.required = true;

    const firstname = new ImportField('firstname', this.translations.FIRST_NAME);
    firstname.required = true;

    const lastname = new ImportField('lastname', this.translations.LAST_NAME);
    lastname.required = true;

    const email = new ImportField('email', this.translations.EMAIL);
    email.required = true;

    const roles = new ImportField('rolesString', this.translations.ROLE);
    roles.required = true;

    schema.fields.push(isActive, isBlocked, firstname, lastname, email, roles
    );

    this.schema = schema;
  }

  changeUserPassword = (e: any) => {
    this.authComponent.open(e.row.data.userId, null);
  }

  calculateDisabledDisplayValue = (e: any) => {
    if (e.row.data.isActive) {
      return true;
    } else {
      return false;
    }
  }

  calculateEnabledDisplayValue = (e: any) => {
    if (!e.row.data.isActive) {
      return true;
    } else {
      return false;
    }
   }

   calculateBlockedDisplayValue = (e: any) => {
    if (e.row.data.isBlocked) {
      return false;
    } else {
      return true;
    }
  }

  calculateUnblockedDisplayValue = (e: any) => {
    if (!e.row.data.isBlocked) {
      return false;
    } else {
      return true;
    }
  }

  edit(itemId: number, event: Event) { }
}
