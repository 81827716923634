import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { WebApiService } from '../../../shared/services/web-api.service';
import { Product } from '../../../auction/shared/models/product';
import { BuyerProduct } from '../models/buyer-product';
import { IProductService } from '../../../shared/interfaces/product';

@Injectable()
export class ProductService implements IProductService {

  private apiPath: string;
  private buyerId: number;

  setId(buyerId: number)
  {
    this.buyerId = buyerId;
  }

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'buyerlevel';
  }

  getProducts(buyerId: number, auctionClusterId: number): Observable<Array<Product>> {
    return this.webApiService.getList(this.apiPath + '/' + buyerId + '/auctioncluster/' + auctionClusterId + '/product');
  }

  getProduct(buyerId: number, auctionClusterId: number, productId: number): Observable<Product> {
    return this.webApiService.getSingle(`${this.apiPath}/${buyerId}/auctioncluster/${auctionClusterId}/product/${productId}`);
  }

  getProductsClocks(buyerId: number): Observable<Array<BuyerProduct>> {
    return this.webApiService.getList(this.apiPath + '/' + buyerId + '/product/clock');
  }

  getProductForReports(auctionClusterId: number, productId: number)
  {
    return this.webApiService.getSingle(`${this.apiPath}/${this.buyerId}/auctioncluster/${auctionClusterId}/product/${productId}`);
  }
}
