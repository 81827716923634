<div class="notification-component">
  <dx-popup #editAuctionClusterNotification [width]="600" [showTitle]="true" title="{{ modalTitle }}"
    [dragEnabled]="false" [closeOnOutsideClick]="false" [rtlEnabled]="rtlEnabled" *ngIf="isOpened"
    [(visible)]="isOpened">
    <dx-scroll-view width="100%" height="100%">
      <dx-form #notificationForm id="notificationForm" [showValidationSummary]="false" colCount="auto"
        [(formData)]="model" [minColWidth]="500">
      <dxi-item itemType="group">

        <dxi-item dataField="descriptionText" [editorOptions]="{ readOnly: true }">
          <dxo-label [visible]="false" ></dxo-label>  
        </dxi-item>

        <dxi-item dataField="title" [template]="'notificationTitleField'">
          <dxo-label text="{{ 'NOTIFICATION.TITLE' | translate }}"></dxo-label>
        </dxi-item>

        <dxi-item dataField="activeAuctionClusterNotificationChannelIds" [template]="'activeChannelsTemplate'">
          <dxo-label text="{{ 'NOTIFICATION.ASSIGNED_CHANNELS' | translate }}"></dxo-label>
        </dxi-item>

        <dxi-item dataField="defaultAuctionClusterNotificationChannelIds" [template]="'defaultChannelsTemplate'">
          <dxo-label text="{{ 'NOTIFICATION.DEFAULT_CHANNELS' | translate }}"></dxo-label>
        </dxi-item>

        <dxi-item dataField="body" [template]="'notificationBodyField'">
          <dxo-label text="{{ 'NOTIFICATION.BODY' | translate }}"></dxo-label>
        </dxi-item>

        <dxi-item dataField="reportId" editorType="dxSelectBox"
        [editorOptions]="{ items: reports, valueExpr:'reportId' ,
                  displayExpr: 'reportDesign.name', rtlEnabled: rtlEnabled,
                  showClearButton: true, searchEnabled: true}">
          <dxo-label text="{{ 'NOTIFICATION.REPORT_NAME' | translate }}"></dxo-label>
        </dxi-item>
      </dxi-item>
        <div *dxTemplate="let data of 'notificationTitleField'" class="translatable-template">
          <translatable-field (change)="onChangeTranslationField($event, 'title')" [isDevExpress]="true"
            [textRequired]="false" [isLabelHidden]="true" [caption]="'NOTIFICATION.TITLE'" [clusterLanguages]="languages"
            [(data)]="data.component.option('formData')[data.dataField]" [name]="'title'" #notificationTitle>
          </translatable-field>
        </div>

        <div *dxTemplate="let data of 'notificationBodyField'" class="translatable-template">
          <translatable-field [type]="'textbox'" (change)="onChangeTranslationField($event, 'body')" [isDevExpress]="true"
            [textRequired]="false" [isLabelHidden]="true" [caption]="'NOTIFICATION.BODY'" [clusterLanguages]="languages"
            [(data)]="data.component.option('formData')[data.dataField]" [name]="'body'" #notificationBody>
          </translatable-field>
        </div>

        <div *dxTemplate="let data of 'activeChannelsTemplate'">
          <dx-tag-box [dataSource]="channels" displayExpr="name" valueExpr="auctionClusterChannelId"
            searchEnabled="true" [(value)]="model.activeAuctionClusterNotificationChannelIds" showClearButton="true"
            [rtlEnabled]="rtlEnabled" (onValueChanged)="getAssignedChannels();">
          </dx-tag-box>
        </div>

        <div *dxTemplate="let data of 'defaultChannelsTemplate'">
          <dx-tag-box [dataSource]="assignedChannels" displayExpr="name" valueExpr="auctionClusterChannelId"
            searchEnabled="true" [(value)]="model.defaultAuctionClusterNotificationChannelIds" showClearButton="true"
            [rtlEnabled]="rtlEnabled">
          </dx-tag-box>
        </div>
      </dx-form>
      <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnNotificationSubmit">{{ 'SAVE' | translate }}</button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'CANCEL' | translate }}</button>
    </dx-scroll-view>
  </dx-popup>
</div>
