export class TransactionMonitorLot {
  columnTitles: Array<TransactionMonitorLotColumn>;
  rows: Array<TransactionMonitorLotRow>;
}

export class TransactionMonitorLotColumn {
  name: string;
  propertyTypeId: number;
  propertyTypeFormatId: number;
}

export class TransactionMonitorLotRow {
  id: number;
  clockStartGuid: string;
  groupedIds: Array<number>;
  values: Array<string>;
  selected = false;
}

