<div [ngClass]="{'dx-rtl': rtlEnabled}" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}" (capsLock)="capsOn=$event">
    <dx-popup
        #changeUserPassword
        width="40%"
        height="450"
        [showTitle]="true"
        title="{{ 'AUTHENTICATION.TITLE' | translate}}"
        [dragEnabled]="false"
        [closeOnOutsideClick]="false"
        [showCloseButton]="false"
        [rtlEnabled]="rtlEnabled"
        [visible]="isOpened"
        (onShown)="onFieldDataChanged($event)">
        <dx-scroll-view width="100%" height="100%">
            <dx-form #changeUserPasswordForm id="changeUserPasswordForm" [showValidationSummary]="false"
                (onFieldDataChanged)="onFieldDataChanged($event)" colCount="auto" [(formData)]="model" [minColWidth]="500">
                <dxi-item itemType="tabbed">
                    <dxi-tab title="{{ 'USER.PASSWORD' | translate }}">
                        <dxi-item dataField="currentPassword"  [editorOptions]="{mode: 'password', valueChangeEvent: 'keyup'}" *ngIf="isCurrentPasswordRequired && !showCurrentPassword">
                            <dxo-label text="{{ 'AUTHENTICATION.CURRENT_PASSWORD' | translate }}"></dxo-label>
                            <dxi-validation-rule type="required" message="{{ 'AUTHENTICATION.CURRENT_PASSWORD_VALIDATION' | translate }}">
                            </dxi-validation-rule>
                        </dxi-item>

                        <dxi-item dataField="currentPassword"  [editorOptions]="{mode: 'text', valueChangeEvent: 'keyup'}" *ngIf="isCurrentPasswordRequired && showCurrentPassword">
                            <dxo-label text="{{ 'AUTHENTICATION.CURRENT_PASSWORD' | translate }}"></dxo-label>
                            <dxi-validation-rule type="required" message="{{ 'AUTHENTICATION.CURRENT_PASSWORD_VALIDATION' | translate }}">
                            </dxi-validation-rule>
                        </dxi-item>

                        <dxi-item class="eyeToggle">
                            <i alt="show" class="far fa-eye eye-show" (click)="showCurrentPassword = !showCurrentPassword" [class.hide]="showCurrentPassword"></i>
                            <i alt="hide" class="far fa-eye-slash eye-hide" (click)="showCurrentPassword = !showCurrentPassword" [class.hide]="!showCurrentPassword"></i>
                        </dxi-item>

                        <dxi-item dataField="newPassword" *ngIf="!showPassword" [editorOptions]="{mode: 'password', valueChangeEvent: 'keyup'}">
                            <dxo-label text="{{ 'AUTHENTICATION.NEW_PASSWORD' | translate }}"></dxo-label>
                            <dxi-validation-rule type="required" message="{{ 'AUTHENTICATION.NEW_PASSWORD_VALIDATION' | translate }}">
                            </dxi-validation-rule> 
                            <dxi-validation-rule type="custom" message="{{ 'AUTHENTICATION.BANNED_PASSWORD' | translate }}" [validationCallback]="validateBannedPasswords" >
                            </dxi-validation-rule>
                        </dxi-item>

                        <dxi-item dataField="newPassword" *ngIf="showPassword" [editorOptions]="{mode: 'text', valueChangeEvent: 'keyup'}">
                            <dxo-label text="{{ 'AUTHENTICATION.NEW_PASSWORD' | translate }}"></dxo-label>
                            <dxi-validation-rule type="required" message="{{ 'AUTHENTICATION.NEW_PASSWORD_VALIDATION' | translate }}">
                            </dxi-validation-rule> 
                            <dxi-validation-rule type="custom" message="{{ 'AUTHENTICATION.BANNED_PASSWORD' | translate }}" [validationCallback]="validateBannedPasswords" >
                            </dxi-validation-rule>
                        </dxi-item>

                        <dxi-item class="eyeToggle">
                            <i alt="show" class="far fa-eye eye-show" (click)="showPassword = !showPassword" [class.hide]="showPassword"></i>
                            <i alt="hide" class="far fa-eye-slash eye-hide" (click)="showPassword = !showPassword" [class.hide]="!showPassword"></i>
                        </dxi-item>

                        <dxi-item dataField="repeatNewPassword" *ngIf="!showRepeatPassword" [editorOptions]="{mode: 'password', valueChangeEvent: 'keyup'}">
                            <dxo-label text="{{ 'AUTHENTICATION.REPEAT_NEW_PASSWORD' | translate }}"></dxo-label>
                            <dxi-validation-rule type="required" message="{{ 'AUTHENTICATION.REPEAT_NEW_PASSWORD_VALIDATION' | translate }}">
                            </dxi-validation-rule>
                            <dxi-validation-rule type="compare" [comparisonTarget]="passwordComparison"
                                message="{{ 'AUTHENTICATION.REPEAT_NEW_PASSWORD_VALIDATION' | translate }}"></dxi-validation-rule>
                            <dxi-validation-rule type="custom" message="{{ 'AUTHENTICATION.BANNED_PASSWORD' | translate }}" [validationCallback]="validateBannedPasswords" >
                            </dxi-validation-rule>
                        </dxi-item>

                        <dxi-item dataField="repeatNewPassword" *ngIf="showRepeatPassword" [editorOptions]="{mode: 'text', valueChangeEvent: 'keyup'}">
                            <dxo-label text="{{ 'AUTHENTICATION.REPEAT_NEW_PASSWORD' | translate }}"></dxo-label>
                            <dxi-validation-rule type="required" message="{{ 'AUTHENTICATION.REPEAT_NEW_PASSWORD_VALIDATION' | translate }}">
                            </dxi-validation-rule>
                            <dxi-validation-rule type="compare" [comparisonTarget]="passwordComparison"
                                message="{{ 'AUTHENTICATION.REPEAT_NEW_PASSWORD_VALIDATION' | translate }}"></dxi-validation-rule>
                            <dxi-validation-rule type="custom" message="{{ 'AUTHENTICATION.BANNED_PASSWORD' | translate }}" [validationCallback]="validateBannedPasswords" >
                            </dxi-validation-rule>
                        </dxi-item>

                        <dxi-item class="eyeToggle">
                            <i alt="show" class="far fa-eye eye-show" (click)="showRepeatPassword = !showRepeatPassword" [class.hide]="showRepeatPassword"></i>
                            <i alt="hide" class="far fa-eye-slash eye-hide" (click)="showRepeatPassword = !showRepeatPassword" [class.hide]="!showRepeatPassword"></i>
                        </dxi-item>

                        <dxi-item>
                            <div>
                                <img class="warning-image" *ngIf="capsOn" src="../../../../assets/images/warningCapsLock.png"/>
                                <span *ngIf="capsOn">{{ 'USER.CAPS_LOCK_WARNING' | translate }}</span>
                            </div>
                        </dxi-item>
                        
                    </dxi-tab>
                    <dxi-tab title="MFA" *ngIf="isMFAtabVisible"> 
                        <button type="button" class="btn btn-outline-danger" (click)="resetMFASecret();">Reset MFA</button>
                    </dxi-tab>
                </dxi-item>
            </dx-form>
            <br/>
            <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnChangeUserPasswordSubmit" disabled>{{ 'AUTHENTICATION.CHANGE' | translate }}</button>
            <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'CANCEL' | translate }}</button>
        </dx-scroll-view>
    </dx-popup>
    </div>
    <verify-my-password-component #verifyMyPassword></verify-my-password-component>