<div class="product-component">
  <clr-modal [(clrModalOpen)]="isOpened" [clrModalClosable]="false" [clrModalSize]="'xl'">
    <h3 class="modal-title">{{ modalTitle }}</h3>
    <div class="modal-body">
      <clr-alert *ngIf="errorMessage" [clrAlertType]="'danger'" [clrAlertClosable]="false">
        <div class="alert-item">
          <span class="alert-text">
            {{errorMessage}}
          </span>
        </div>
      </clr-alert>

      <form #detailsForm="ngForm" clrForm>
        <clr-tabs #tabs>
          <clr-tab>
            <button clrTabLink>{{ 'PRODUCT.GENERAL' | translate }}</button>
            <clr-tab-content>
              <div class="tab-content product-form">
                <translatable-field [caption]="'PRODUCT.NAME'" [(data)]="model.name" [name]="'name'" [clusterLanguages]="languages" #productName></translatable-field>
                <translatable-field [caption]="'PRODUCT.DESCRIPTION'" [type]="'textbox'" [name]="'description'" [(data)]="model.description" [clusterLanguages]="languages" #productDescription></translatable-field>

                <section class="form-block">
                  <div class="form-group">
                    <label class="required">{{ 'PRODUCT.PRICE_UNIT' | translate }}</label>
                    <div class="row">
                      <label for="priceUnitAmount" aria-haspopup="true" role="tooltip" class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm" [ngClass]="{'invalid': priceUnitAmount.dirty && priceUnitAmount.invalid}">
                        <input clrInput id="priceUnitAmount" type="number" name="priceUnitAmount" #priceUnitAmount="ngModel" [(ngModel)]="model.priceUnitAmount" required />
                        <span class="tooltip-content">
                          {{ 'PRODUCT.PRICE_UNIT_VALIDATION' | translate }}
                        </span>
                      </label>
                      <div class="select">
                        <select id="priceUnit" [(ngModel)]="model.priceUnitId" name="priceUnit" (ngModelChange)="priceUnitChanged($event)" required>
                          <option *ngFor="let unit of priceUnits" [(ngValue)]="unit.priceUnitId">{{ getTranslation(unit.name) }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <sale-units-component [data]="model.saleUnits" [priceUnit]="priceUnit" [languages]="languages"></sale-units-component>
                  <clr-alert [clrAlertType]="'danger'" [clrAlertClosable]="false" *ngIf="model.saleUnits.length===0">
                    <div clr-alert-item class="alert-item">
                      <span class="alert-text">
                        {{ 'PRODUCT.SALE_UNIT_VALIDATION' | translate}}
                      </span>
                    </div>
                  </clr-alert>
                </section>
              </div>
            </clr-tab-content>
          </clr-tab>
          <clr-tab>
            <button clrTabLink>{{ 'PRODUCT.PROPERTY_GROUPS' | translate }}</button>
            <clr-tab-content>
              <div class="tab-content">
                <product-property-groups-component [data]="model.productPropertyGroups" [languages]="languages"></product-property-groups-component>
              </div>
            </clr-tab-content>
          </clr-tab>
          <clr-tab>
            <button clrTabLink>{{ 'PRODUCT.PROPERTIES' | translate }}</button>
            <clr-tab-content>
              <div class="tab-content">
                <product-properties-component [data]="model.productProperties" [systemProductProperties]="systemProductProperties" [languages]="languages" [productPropertyTypes]="productPropertyTypes" [masterData]="masterDataLists" [productId]="model.productId"></product-properties-component>
              </div>
            </clr-tab-content>
          </clr-tab>
          <clr-tab>
            <button clrTabLink>{{ 'PRODUCT.SUPPLY_DATA' | translate }}</button>
            <clr-tab-content>
              <div class="tab-content">
                <clr-alert *ngIf="!model.productId" [clrAlertType]="'danger'" [clrAlertClosable]="false">
                  <div class="alert-item">
                    <span class="alert-text">
                      {{ 'PRODUCT.ADD_PRODUCT_BEFORE_SUPPLY_DATA_PROPERTIES' | translate }}
                    </span>
                  </div>
                </clr-alert>
                <clr-tabs *ngIf="model.productId">
                  <clr-tab>
                    <button clrTabLink>{{ 'PRODUCT.PROPERTY.SUPPLY_CATALOG' | translate }}</button>
                    <clr-tab-content>
                      <div class="tab-content">
                        <generic-product-properties-component [productPropertyTypes]="productPropertyTypes" [data]="model.supplyDataEntryGridProperties" [productProperties]="model.productProperties" [isSupplyData]="true" [masterData]="masterDataLists"></generic-product-properties-component>
                      </div>
                    </clr-tab-content>
                  </clr-tab>
                  <clr-tab>
                    <button clrTabLink>{{ 'PRODUCT.PROPERTY.FORECAST_CATALOG' | translate }}</button>
                    <clr-tab-content>
                      <div class="tab-content">
                        <generic-product-properties-component [productPropertyTypes]="productPropertyTypes" [data]="model.supplyForecastDataEntryGridProperties" [productProperties]="model.productProperties" [isSupplyData]="true" [masterData]="masterDataLists"></generic-product-properties-component>
                      </div>
                    </clr-tab-content>
                  </clr-tab>
                  <clr-tab>
                    <button clrTabLink>{{ 'PRODUCT.PROPERTY.PUBLIC_FORECAST_CATALOG' | translate }}</button>
                    <clr-tab-content>
                      <div class="tab-content">
                        <generic-product-properties-component [productPropertyTypes]="productPropertyTypes" [data]="model.supplyForecastPublicGridProperties" [productProperties]="model.productProperties" [isSupplyData]="true" [masterData]="masterDataLists"></generic-product-properties-component>
                      </div>
                    </clr-tab-content>
                  </clr-tab>
                </clr-tabs>
              </div>
            </clr-tab-content>
          </clr-tab>
          <clr-tab>
            <button clrTabLink>{{ 'PRODUCT.SUPPLY_DATA_EDITOR' | translate }}</button>
            <clr-tab-content>
              <div class="tab-content">
                <clr-alert *ngIf="!model.productId" [clrAlertType]="'danger'" [clrAlertClosable]="false">
                  <div class="alert-item">
                    <span class="alert-text">
                      {{ 'PRODUCT.ADD_PRODUCT_BEFORE_SUPPLY_DATA_EDITOR_PROPERTIES' | translate }}
                    </span>
                  </div>
                </clr-alert>
                <clr-tabs *ngIf="model.productId">
                  <clr-tab>
                    <button clrTabLink>{{ 'PRODUCT.PROPERTY.SUPPLY_CATALOG_EDITOR' | translate }}</button>
                    <clr-tab-content>
                      <div class="tab-content">
                        <current-lot-properties-component [data]="model.supplyDataEditorProperties" [propertyGroups]="model.productPropertyGroups" [isEditorProperty]="true" [productPropertyTypes]="productPropertyTypes" [productProperties]="model.productProperties" [masterData]="masterDataLists"></current-lot-properties-component>
                      </div>
                    </clr-tab-content>
                  </clr-tab>
                  <clr-tab>
                    <button clrTabLink>{{ 'PRODUCT.PROPERTY.FORECAST_CATALOG_EDITOR' | translate }}</button>
                    <clr-tab-content>
                      <div class="tab-content">
                        <current-lot-properties-component [data]="model.supplyForecastDataEditorProperties" [propertyGroups]="model.productPropertyGroups" [isEditorProperty]="true" [productPropertyTypes]="productPropertyTypes" [productProperties]="model.productProperties" [masterData]="masterDataLists"></current-lot-properties-component>
                      </div>
                    </clr-tab-content>
                  </clr-tab>
                </clr-tabs>
              </div>
            </clr-tab-content>
          </clr-tab>
          <clr-tab>
            <button clrTabLink>{{ 'PRODUCT.PROPERTY.AUCTIONEER' | translate }}</button>
            <clr-tab-content>
              <div class="tab-content">
                <clr-alert *ngIf="!model.productId" [clrAlertType]="'danger'" [clrAlertClosable]="false">
                  <div class="alert-item">
                    <span class="alert-text">
                      {{ 'PRODUCT.ADD_PRODUCT_BEFORE_AUCTIONEER_PROPERTIES' | translate }}
                    </span>
                  </div>
                </clr-alert>
                <clr-tabs *ngIf="model.productId">
                  <clr-tab>
                    <button clrTabLink>{{ 'PRODUCT.PROPERTY.NEXT_LOT_PROPERTIES' | translate }}</button>
                    <clr-tab-content>
                      <generic-product-properties-component [data]="model.nextLotAuctioneerProperties" [productProperties]="model.productProperties" [productPropertyTypes]="productPropertyTypes" [masterData]="masterDataLists"></generic-product-properties-component>
                    </clr-tab-content>
                  </clr-tab>
                  <clr-tab>
                    <button clrTabLink>{{ 'PRODUCT.PROPERTY.CURRENT_LOT_PROPERTIES' | translate }}</button>
                    <clr-tab-content>
                      <current-lot-properties-component [data]="model.currentLotAuctioneerProperties" [propertyGroups]="model.productPropertyGroups" [isEditorProperty]="false" [productPropertyTypes]="productPropertyTypes" [productProperties]="model.productProperties" [masterData]="masterDataLists"></current-lot-properties-component>
                    </clr-tab-content>
                  </clr-tab>
                  <clr-tab>
                    <button clrTabLink>{{ 'PRODUCT.PROPERTY.TRANSACTION_INFO' | translate }}</button>
                    <clr-tab-content>
                      <transaction-properties-component [data]="model.transactionInfoAuctioneerProperties" [productPropertyTypes]="productPropertyTypes" [productProperties]="model.productProperties" [masterData]="masterDataLists"></transaction-properties-component>
                    </clr-tab-content>
                  </clr-tab>
                </clr-tabs>
              </div>
            </clr-tab-content>
          </clr-tab>
          <clr-tab>
            <button clrTabLink>{{ 'PRODUCT.PROPERTY.BUYER' | translate }}</button>
            <clr-tab-content>
              <div class="tab-content">
                <clr-alert *ngIf="!model.productId" [clrAlertType]="'danger'" [clrAlertClosable]="false">
                  <div class="alert-item">
                    <span class="alert-text">
                      {{ 'PRODUCT.ADD_PRODUCT_BEFORE_BUYER_PROPERTIES' | translate }}
                    </span>
                  </div>
                </clr-alert>
                <clr-tabs *ngIf="model.productId">
                  <clr-tab>
                    <button clrTabLink>{{ 'PRODUCT.PROPERTY.NEXT_LOT_PROPERTIES' | translate }}</button>
                    <clr-tab-content>
                      <generic-product-properties-component [data]="model.nextLotBuyerProperties" [productPropertyTypes]="productPropertyTypes" [productProperties]="model.productProperties" [masterData]="masterDataLists"></generic-product-properties-component>
                    </clr-tab-content>
                  </clr-tab>
                  <clr-tab>
                    <button clrTabLink>{{ 'PRODUCT.PROPERTY.CURRENT_LOT_PROPERTIES' | translate }}</button>
                    <clr-tab-content>
                      <current-lot-properties-component [data]="model.currentLotBuyerProperties" [propertyGroups]="model.productPropertyGroups" [isEditorProperty]="false" [productPropertyTypes]="productPropertyTypes" [productProperties]="model.productProperties" [masterData]="masterDataLists"></current-lot-properties-component>
                    </clr-tab-content>
                  </clr-tab>
                  <clr-tab>
                    <button clrTabLink>{{ 'PRODUCT.PROPERTY.TRANSACTION_INFO' | translate }}</button>
                    <clr-tab-content>
                      <transaction-properties-component [data]="model.transactionInfoBuyerProperties" [productPropertyTypes]="productPropertyTypes" [productProperties]="model.productProperties" [masterData]="masterDataLists"></transaction-properties-component>
                    </clr-tab-content>
                  </clr-tab>
                  <clr-tab>
                    <button clrTabLink>{{ 'PRODUCT.PROPERTY.PRICE_OVERVIEW_GRID' | translate }}</button>
                    <clr-tab-content>
                      <generic-product-properties-component [data]="model.priceOverviewGridBuyer" [productPropertyTypes]="productPropertyTypes" [productProperties]="model.productProperties" [masterData]="masterDataLists"></generic-product-properties-component>
                    </clr-tab-content>
                  </clr-tab>
                  <clr-tab>
                    <button clrTabLink>{{ 'PRODUCT.PROPERTY.SHOPPING_LIST_PRODUCT_DEFINITION' | translate }}</button>
                    <clr-tab-content>
                      <generic-product-properties-component [data]="model.shoppingListProductDefinitionBuyer" [productPropertyTypes]="productPropertyTypes" [productProperties]="model.productProperties" [masterData]="masterDataLists"></generic-product-properties-component>
                    </clr-tab-content>
                  </clr-tab>
                  <clr-tab>
                    <button clrTabLink>{{ 'PRODUCT.PROPERTY.PREBID_ON_PRODUCT' | translate }}</button>
                    <clr-tab-content>
                      <generic-product-properties-component [data]="model.prebidOnProductBuyer" [productPropertyTypes]="productPropertyTypes" [productProperties]="model.productProperties" [masterData]="masterDataLists"></generic-product-properties-component>
                    </clr-tab-content>
                  </clr-tab>
                  <clr-tab>
                    <button clrTabLink>{{ 'PRODUCT.PROPERTY.SUPPLY_MONITOR_PRODUCT_DEFINITION' | translate }}</button>
                    <clr-tab-content>
                      <generic-product-properties-component [data]="model.supplyMonitorProductDefinitionBuyer" [productPropertyTypes]="productPropertyTypes" [productProperties]="model.productProperties" [masterData]="masterDataLists"></generic-product-properties-component>
                    </clr-tab-content>
                  </clr-tab>
                </clr-tabs>
              </div>
            </clr-tab-content>
          </clr-tab>
          <clr-tab>
            <button clrTabLink>{{ 'PRODUCT.REPORTS' | translate }}</button>
            <clr-tab-content>
              <div class="tab-content">
                <div class="form-group report-types">
                  <label for="reportTypes">{{ 'PRODUCT.REPORT_TYPES' | translate }}</label>
                  <div class="select">
                    <select name="reportId" [(ngModel)]="reportId" (ngModelChange)="filterReportProperties();">
                      <option [ngValue]="null"></option>
                      <option *ngFor="let reportType of reportTypes" [ngValue]="reportType.reportId">{{reportType.name}}</option>
                    </select>
                  </div>
                </div>
                <generic-product-properties-component *ngIf="reportId" [productPropertyTypes]="productPropertyTypes" [productProperties]="model.productProperties" [masterData]="masterDataLists" [data]="filteredReportProperties" (onDataChanged)="updateReportProperties($event)"></generic-product-properties-component>
              </div>
            </clr-tab-content>
          </clr-tab>
          <clr-tab>
            <button clrTabLink [clrTabLinkInOverflow]="true">{{ 'PRODUCT.PROPERTY.AUTOMATIC_GROUPING' | translate }}</button>
            <clr-tab-content>
              <div class="tab-content">
                <clr-alert *ngIf="!model.productId" [clrAlertType]="'danger'" [clrAlertClosable]="false">
                  <div class="alert-item">
                    <span class="alert-text">
                      {{ 'PRODUCT.ADD_PRODUCT_BEFORE_DEFINING_GROUPING_PROPERTIES' | translate }}
                    </span>
                  </div>
                </clr-alert>
                <grouping-properties-component (save)="onSubmit()" [groupingProperties]="model.automaticProductPropertyGroupingSelections" [nonGroupingProperties]="model.automaticProductPropertyNonGroupingSelections" [productProperties]="model.productProperties" [productPropertyTypes]="productPropertyTypes" [masterData]="masterDataLists"></grouping-properties-component>
              </div>
            </clr-tab-content>
          </clr-tab>
          <clr-tab>
            <button clrTabLink [clrTabLinkInOverflow]="true">{{ 'PRODUCT.PROPERTY.MANUAL_GROUPING' | translate }}</button>
            <clr-tab-content>
              <div class="tab-content">
                <clr-alert *ngIf="!model.productId" [clrAlertType]="'danger'" [clrAlertClosable]="false">
                  <div class="alert-item">
                    <span class="alert-text">
                      {{ 'PRODUCT.ADD_PRODUCT_BEFORE_DEFINING_GROUPING_PROPERTIES' | translate }}
                    </span>
                  </div>
                </clr-alert>
                <grouping-properties-component [groupingProperties]="model.manualProductPropertyGroupingSelections" [nonGroupingProperties]="model.manualProductPropertyNonGroupingSelections" [productProperties]="model.productProperties" [productPropertyTypes]="productPropertyTypes" [masterData]="masterDataLists"></grouping-properties-component>
              </div>
            </clr-tab-content>
          </clr-tab>
        </clr-tabs>
      </form>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" (click)="onSubmit();" [disabled]="!detailsForm.form.valid || !productName?.valid">{{ 'SAVE' | translate }}</button>
      <!--<button type="submit" class="btn btn-primary" (click)="onSubmit();" [disabled]="!detailsForm.form.valid || !productName?.valid || model.saleUnits.length==0">{{ 'SAVE' | translate }}</button>-->
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'CANCEL' | translate }}</button>
    </div>
  </clr-modal>
</div>
