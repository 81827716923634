import { Component, OnInit, Injector, OnDestroy, ViewChild } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import notify from 'devextreme/ui/notify';

// general services
import { LanguageService } from '../../shared/services/language.service';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { CalculationComponent } from './calculation.component';

// services
import { CalculationsService } from '../shared/services/calculations.service';
import { ProductService } from '../shared/services/product.service';
import { MasterDataService } from '../shared/services/master-data.service';

// models
import { Language } from '../../shared/models/language';
import { Calculation, CalculationFieldTypes } from '../shared/models/calculation';
import { ImportSchema, ImportField } from '../../shared/import/import-schema';
import { Product } from '../shared/models/product';
import { MasterData } from '../shared/models/master-data';

@Component({
  selector: 'calculations-component',
  templateUrl: 'calculations.component.html',
  styleUrls: ['./calculations.component.scss']
})
export class CalculationsComponent extends FullListComponent<Calculation, CalculationComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: CalculationComponent;

  languages: Array<Language> = [];
  products: Array<Product> = [];
  masterDatas: Array<MasterData> = [];

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private dataService: CalculationsService,
    private productService: ProductService,
    private masterDataService: MasterDataService,
  ) {
    super(injector, Calculation);
    this.title.set('CALCULATIONS.TITLE');
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.setTranslations('CALCULATIONS');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getData() {
    this.spinner.show();
    forkJoin(
      this.dataService.getCalculations(this.id),
      this.languageService.getAuctionClusterLanguages(this.id),
      this.productService.getProducts(this.id),
      this.masterDataService.getMasterDataListsWithFields(this.id)
    ).subscribe(result => {
      this.items = result[0];
      this.languages = result[1];
      // tslint:disable-next-line: no-magic-numbers
      this.products = result[2];
      // tslint:disable-next-line: no-magic-numbers
      this.masterDatas = result[3];
      this.translateCalculations();
      this.spinner.hide();
    },
      error => {
        notify(error.message, 'error', 5000);
        this.spinner.hide();
      });
  }

  getCalculationTypeName = (e: any) => {
    const sourceType = e.value;
    switch (sourceType) {
      case CalculationFieldTypes.AUTO_INCREMENT:
        return this.translations.AUTO_INCREMENT;
      /*case CalculationFieldTypes.MATH:
        return this.translations.MATH;
      case CalculationFieldTypes.CONCATENATE:
        return this.translations.CONCATENATE;*/
      case CalculationFieldTypes.FRONTEND:
        return this.translations.FRONTEND;
      case CalculationFieldTypes.EXPRESSION:
        return this.translations.EXPRESSION;
      case CalculationFieldTypes.JSONCALC:
        return this.translations.JSONCALC;
      default:
        return this.translations.AUTO_INCREMENT;
    }
  }

  edit = (calculationId: number) => {
    this.detailsComponent.modalTitle = this.translations.EDIT;
    this.detailsComponent.open(this.items, calculationId, this.languages, this.products, this.masterDatas);
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.ADD_NEW;
    this.detailsComponent.open(this.items, null, this.languages, this.products, this.masterDatas);
  }

  deleteSelected() {
    this.spinner.show();
    this.dataService.delete(this.itemIdToDelete, this.id)
      .subscribe((users: Array<Calculation>) => {
        this.getData();
        this.spinner.hide();
      },
        error => {
          notify(this.errorService.translations.DELETE_ERROR_MESSAGE, 'error', 5000);
          this.spinner.hide();
        });
  }

  translateCalculations() {
    this.items.forEach(m => {
      m.displayName = this.languageService.getTranslatableText(m.name);
    });
  }

  createDataSchema() {

    const schema = new ImportSchema();

    const maskName = new ImportField('CalculationName', this.translations.NAME);
    maskName.required = true;

    schema.fields.push(maskName);

    this.schema = schema;
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.calculationId;
    this.confirmation.opened = true;
  }

  onRowClick = (e: any) => {
    this.edit(e.data.calculationId);
  }

  editItem = (e: any) => {
    this.edit(e.row.data.calculationId);
  }
}
