<div class="lookup-table-row-editor-component item-list-component" [ngClass]="{ 'dx-rtl': rtlEnabled }"
  dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup #editLookupTableRow [width]="600" [height]="600" [showTitle]="true" title="{{ modalTitle }}"
    [dragEnabled]="false" [closeOnOutsideClick]="false" [rtlEnabled]="rtlEnabled" [(visible)]="isOpened">
    <dx-scroll-view width="100%" height="100%">

      <div class="form-group" *ngFor="let value of model.lookupTableValues;let i = index">
        <label class="required">{{getFieldName(value)}}</label>

        <dx-select-box #lookupTableField name="{{'lookupTableField' + value.lookupTableFieldId}}"
          [items]="model.lookupTableValuesParsed[i]" displayExpr="name" valueExpr="id"
          [(value)]="model.lookupTableValues[i].masterDataListRowId" [rtlEnabled]="rtlEnabled" [searchEnabled]="true"
          [showClearButton]="true" (onValueChanged)="handleValueChange($event)"></dx-select-box>
      </div>


      <div class="form-group"
        *ngIf="(lookupTableItem?.resultPropertyTypeId || lookupTableItem?.resultMasterDataListId)">
        <label required class="required">{{getDefaultFieldName()}}</label>
        <dx-number-box #intValueInput required [visible]="lookupTableItem?.resultPropertyTypeId === 1"
          name="intValueInput" [(value)]="model.resultIntValue" [showSpinButtons]="true" format="#0" [step]="1"
          [min]="0" [rtlEnabled]="rtlEnabled" (onValueChanged)="handleValueChange($event)"></dx-number-box>
        <dx-number-box #decimalValueInput required [visible]="lookupTableItem?.resultPropertyTypeId === 2"
          name="decimalValueInput" [(value)]="model.resultDecimalValue" [showSpinButtons]="true"
          [min]="0" [rtlEnabled]="rtlEnabled" (onValueChanged)="handleValueChange($event)"></dx-number-box>
        <dx-text-box #stringValueInput required [visible]="lookupTableItem.resultPropertyTypeId === 3"
          name="stringValueInput" [(value)]="model.resultStringValue" (onValueChanged)="handleValueChange($event)"></dx-text-box>
        <dx-date-box #dateTimeValueInput *ngIf="lookupTableItem?.resultPropertyTypeId === 4" 
          [(value)]="model.resultDateTimeValue" (onValueChanged)="handleValueChange($event)" type="datetime"></dx-date-box>
        <dx-check-box #booleanValueInput [visible]="lookupTableItem.resultPropertyTypeId === 7" name="booleanValueInput"
          [(value)]="model.resultBooleanValue"></dx-check-box>
        <dx-select-box #masterDataValueInput [visible]="lookupTableItem.resultMasterDataListId"
          name="masterDataValueInput" [items]="masterDataRows"
          displayExpr="name" valueExpr="id" [(value)]="model.resultMasterDataListRowId" [rtlEnabled]="rtlEnabled"
          [searchEnabled]="true" [showClearButton]="true"></dx-select-box>
        <div class="photo-thumbnail" *ngIf="lookupTableItem?.resultPropertyTypeId === 8 && lookupTableItem?.resultPropertyFormatId === 17">   
          <dx-text-box [(value)]="model.displayUrlTextValue" [readOnly]="true"></dx-text-box>
          <label for="resultUrlValue">
            <img [src]="model.displayUrlImageValue"/>
          </label>
          <input id="resultUrlValue" name="resultUrlValue" type="file" accept=".png, .jpg, .jpeg, image/*" (input)="onImageChanged($event)"/>        
          <dx-button class="clearButton" icon="clear" (click)="clearImage();"></dx-button>
        </div>
      </div>
      <div class="form-group" *ngIf="lookupTableItem?.lookupTableType !== 1">
        <label>{{ 'LOOKUP_TABLE.ROWS.IS_DEFAULT' | translate }}</label>
        <dx-check-box name="isDefault" [(value)]="model.isDefault" (onValueChanged)="handleValueChange($event)"></dx-check-box>
      </div>
      <div class="form-group" *ngIf="lookupTableItem?.hasPeriodFilter">

        <label for="datePeriodFrom">{{ 'LOOKUP_TABLE.ROWS.PERIOD_FROM' | translate }}</label>
        <dx-date-box #periodFromInput *ngIf="lookupTableItem?.hasPeriodFilter"
          [(value)]="model.periodFrom" (onValueChanged)="handleValueChange($event)" type="datetime"></dx-date-box>
      </div>
      <div class="form-group" *ngIf="lookupTableItem?.hasPeriodFilter">
        <label for="datePeriodTill">{{ 'LOOKUP_TABLE.ROWS.PERIOD_TO' | translate }}</label>
        <dx-date-box #periodTillInput *ngIf="lookupTableItem?.hasPeriodFilter" 
          [(value)]="model.periodTill" (onValueChanged)="handleValueChange($event)" type="datetime"></dx-date-box>
      </div>

      <button type="submit" class="btn btn-primary" (click)="onSubmit();"
        id="btnLookupTableRowSubmit" disabled>{{ 'SAVE' | translate }}</button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'CANCEL' | translate }}</button>
    </dx-scroll-view>
  </dx-popup>
</div>
