<div class="item-list-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <div class="grid-buttons">
    <dx-button hint="{{ 'ADD' | translate }}" icon="add" (onClick)="add()"></dx-button>
    <dx-button hint="{{ 'PRINT' | translate }}" icon="print" (onClick)="print()"></dx-button>
  </div>
  <br />
  <dx-data-grid #calculationsGrid
                id="printSection"
                [rtlEnabled]="rtlEnabled"
                [dataSource]="items"
                [showBorders]="true"
                (onRowClick)="onRowClick($event)"
                [allowColumnReordering]="true"
                [hoverStateEnabled]="true"
                [columnAutoWidth]="true"
                [rowAlternationEnabled]="true"
                (onExporting)="onExporting($event)">
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-selection mode="single"></dxo-selection>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]"
               [showNavigationButtons]="true" [showInfo]="true" [visible]="true" infoText="{2} {{ 'CALCULATIONS.PAGINATION_TEXT_PLACEHOLDER' | translate | slice:2 }}">
    </dxo-pager>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="displayName" caption="{{ 'CALCULATIONS.NAME' | translate }}">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="calculationType" caption="{{ 'CALCULATIONS.TYPE' | translate }}" [customizeText]="getCalculationTypeName">
    </dxi-column>
    <dxi-column type="buttons" [width]="90">
      <dxi-button hint="{{ 'EDIT' | translate }}" icon="edit" [onClick]="editItem"></dxi-button>
      <dxi-button hint="{{ 'DELETE' | translate }}" icon="trash" [onClick]="deleteItem"></dxi-button>
    </dxi-column>
  </dx-data-grid>
</div>
<calculation-component #details (onClosed)="onDetailsComponentClosed($event)"></calculation-component>
<confirmation-component #confirmation title="{{ 'CALCULATIONS.DELETE_CALCULATION' | translate }}"
                        message="{{ 'CALCULATIONS.DELETE_CALCULATION_MESSAGE' | translate }}"
                        buttonCloseText="DELETE" cancellable="true" (close)="deleteSelected();"></confirmation-component>
