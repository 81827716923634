import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApplicationSettings } from '../../../shared/models/application-settings';
import { WebApiService } from '../../../shared/services/web-api.service';
import { Calculation } from '../models/calculation';

@Injectable()
export class CalculationsService {
  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'auctioncluster';
  }

  getCalculations(auctionClusterId: number): Observable<Array<Calculation>> {

    const url = this.apiPath + '/' + auctionClusterId + '/calculation';
    return this.webApiService.getList(url);
  }

  getCalculation(auctionClusterId: number, calculationId: number): Observable<Calculation> {
    const url = this.apiPath + '/' + auctionClusterId + '/calculation/' + calculationId;
    return this.webApiService.getList(url);
  }

  getCalculationForPropertyType(auctionClusterId: number, productId: number, propertyTypeId: number) {
    const url = this.apiPath + '/' + auctionClusterId + '/calculation/product/' + productId + '/propertytype/' + propertyTypeId;
    return this.webApiService.getList(url);
  }

  save(calculation: Calculation): Observable<any> {
    const url = this.apiPath + '/' + calculation.auctionClusterId + '/calculation';
    return this.webApiService.save(url, calculation);
  }

  edit(calculation: Calculation): Observable<any> {
    const url = this.apiPath + '/' + calculation.auctionClusterId + '/calculation';
    return this.webApiService.edit(url, calculation.calculationId, calculation);
  }

  delete(calculationId: number, auctionClusterId: number): Observable<any> {
    const url = this.apiPath + '/' + auctionClusterId + '/calculation';
    return this.webApiService.delete(url, calculationId);
  }
}
