<div class="location-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup #addLocation [width]="600" [showTitle]="true" title="{{ modalTitle }}" [dragEnabled]="false"
    [closeOnOutsideClick]="false" [rtlEnabled]="rtlEnabled" [(visible)]="isOpened"
    (onShown)="onFieldDataChanged($event)">
    <dx-scroll-view width="100%" height="100%">
      <dx-form #locationForm id="locationForm" validationGroup="locationData" [showValidationSummary]="false"
        (onFieldDataChanged)="onFieldDataChanged($event)" colCount="auto" [(formData)]="model" [minColWidth]="500">


            <dxi-item itemType="group">
              <dxi-item dataField="name">
                <dxo-label text="{{ 'LOCATION.NAME' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'LOCATION.NAME_VALIDATION' | translate }}">
                </dxi-validation-rule>
              </dxi-item>

              <dxi-item dataField="address1">
                <dxo-label text="{{ 'LOCATION.ADDRESS1' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'LOCATION.ADDRESS1_VALIDATION' | translate }}">
                </dxi-validation-rule>
              </dxi-item>

              <dxi-item dataField="address2">
                <dxo-label text="{{ 'LOCATION.ADDRESS2' | translate }}"></dxo-label>
              </dxi-item>

              <dxi-item dataField="zip">
                <dxo-label text="{{ 'LOCATION.ZIP' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'LOCATION.ZIP_VALIDATION' | translate }}">
                </dxi-validation-rule>
              </dxi-item>

              <dxi-item dataField="city">
                <dxo-label text="{{ 'LOCATION.CITY' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'LOCATION.CITY_VALIDATION' | translate }}">
                </dxi-validation-rule>
              </dxi-item>

              <dxi-item dataField="country">
                <dxo-label text="{{ 'LOCATION.COUNTRY' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'LOCATION.COUNTRY_VALIDATION' | translate }}">
                </dxi-validation-rule>
              </dxi-item>
            </dxi-item>
      </dx-form>
      <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnLocationSubmit"
        disabled="disabled">{{ 'SAVE' | translate }}</button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'CANCEL' | translate }}</button>
    </dx-scroll-view>
  </dx-popup>
</div>