import { Component, Injector, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { ClrTabs } from '@clr/angular';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';
import notify from 'devextreme/ui/notify';
import { Subscription } from 'rxjs';

// services
import { PlatformRoleService } from '../shared/services/platform-role.service';
import { LanguageService } from '../../shared/services/language.service';

// models
import { Language } from '../../shared/models/language';
import { PlatformRole } from '../shared/models/platform-role';
import { PlatformPermission } from '../shared/models/platform-permission';
import { DxFormComponent } from 'devextreme-angular';

@Component({
  selector: 'role-component',
  templateUrl: 'role.component.html',
  styleUrls: ['./role.component.scss']
})
export class RoleComponent extends ItemDetailsComponent<PlatformRole> implements OnInit, OnDestroy {

  permissions: Array<PlatformPermission> = [];
  languages: Array<Language> = [];
  requiredPermissions: Array<number> = [];
  validFields: boolean = false;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  @ViewChild('tabs') tabs: ClrTabs;
  @ViewChild('roleName') roleName;
  @ViewChild('roleDescription') roleDescription;
  @ViewChild('roleForm', { read: DxFormComponent}) roleForm: DxFormComponent;
  
  constructor(
    protected injector: Injector,
    private dataService: PlatformRoleService,
    private languageService: LanguageService
  ) {
    super(injector);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.model = new PlatformRole();
    this.setTranslations('ROLE');
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  open(roles: Array<PlatformRole>, roleId: number, permissions: Array<PlatformPermission>, languages: Array<Language>) {
    this.allItems = roles;
    this.permissions = permissions;
    this.languages = languages;
    this.requiredPermissions = [];

    // Autoselect first tab
    // this.tabs.ifActiveService.current = this.tabs.tabsService.children[0].id;

    if (roleId != null) {
      this.isEditMode = true;
      this.spinner.show();
      this.dataService.getPlatformRole(roleId)
        .subscribe((res: PlatformRole) => {
          this.model = res;
          this.model.name = this.languageService.updateStringToAllLanguages(languages, this.model.name);
          this.model.description = this.languageService.updateStringToAllLanguages(languages, this.model.description);

          this.validFields = this.languageService.checkDefaultLanguageNotEmpty(this.languages, this.model.name);

          this.requiredPermissions = this.model.permissions;
          this.isOpened = true;
          this.spinner.hide();
        },
        error => {
          notify(error.message, 'error', 5000);
          this.spinner.hide();
        });
    } else {
      this.model = new PlatformRole();
      this.model.name = this.model.description = this.languageService.updateStringToAllLanguages(languages, "{}");
      this.isEditMode = false;
      this.isOpened = true;
    }

    // this.roleName.elementFocus();
  }

  save() {
    this.model.name = this.roleName.data;

    if (!this.isUnique()) {
      return;
    }

    this.model.permissions = this.requiredPermissions;

    this.model.description = this.roleDescription.data;

    if (this.isEditMode) {
      this.spinner.show();
      this.dataService.edit(this.model)
        .subscribe((res: any) => {
          this.model = new PlatformRole();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
        error => {
          this.errorMessage = this.errorService.toString(error);
          notify(this.errorMessage, 'error', 5000);
          this.spinner.hide();
        });
    } else {
      this.spinner.show();
      this.dataService.save(this.model)
        .subscribe((res: any) => {
          this.model = new PlatformRole();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
        error => {
          this.errorMessage = this.errorService.toString(error);
          notify(this.errorMessage, 'error', 5000);
          this.spinner.hide();
        });
    }
  }

  private isUnique(): boolean {

    // create role list without current role
    const roleList = this.allItems.filter((r) => {
      return r.platformRoleId !== this.model.platformRoleId;
    });

    const nameNotUniqueList = roleList.filter((s) => {
      let name = this.languageService.updateStringToAllLanguages(this.languages, s.name);
      return !this.languageService.checkAllLanguageFieldsUnique(this.languages, name, this.model.name);
    });

    if (nameNotUniqueList.length > 0) {
      this.errorMessage = this.translations.NAME_NOT_UNIQUE;
      return false;
    }

    return true;
  }

  onCancel() {
    this.roleName.resetLanguageButtons();
    this.roleDescription.resetLanguageButtons();
    super.onCancel();
  }

  onFieldDataChanged(e: any) {
    if ((e.component._isReady && e.component.NAME !== 'dxPopup') || e.component.NAME === 'dxForm') {
      const result = e.component.validate();
      if (result.brokenRules.length == 0 || this.validFields) {
        document.getElementsByName('btnRoleSubmit')[0].removeAttribute('disabled');
      } else {
        document.getElementsByName('btnRoleSubmit')[0].setAttribute('disabled', 'disabled');
      }
    } else {
      if (this.isEditMode && this.validFields) {
        document.getElementsByName('btnRoleSubmit')[0].removeAttribute('disabled');
      } else {
        document.getElementsByName('btnRoleSubmit')[0].setAttribute('disabled', 'disabled');
      }
    }
  }

  onChangeTranslationField = (e: any, fieldName: string) => {
    if (fieldName === 'name') {
      this.validFields = this.languageService.checkDefaultLanguageNotEmpty(this.languages, this.roleName.data);
      this.roleForm.instance.updateData(fieldName, this.roleName.data);
    } else if (fieldName === 'description') {
      this.roleForm.instance.updateData(fieldName, this.roleDescription.data);
    }
  }

  translateCheckBoxes = (item) => {
    if (item) {
      const label = this.getTranslation(item.name);
      return label;
    }
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }
}
