import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { ContentComponent } from '../../shared/content/content.component';
import { AuctionClusterService } from '../../buyer/shared/services/auction-cluster.service';
import { ActivatedRoute, NavigationEnd } from '@angular/router';
import { LanguageService } from '../../shared/services/language.service';
import { Subscription } from 'rxjs';
import { AccessLevel, AdditionalMenuItem } from '../../shared/models/additional-menu-item';

@Component({
  selector: 'buyer-content.component',
  templateUrl: './buyer-content.component.html',
  styleUrls: ['./buyer-content.component.scss']
})
export class BuyerContentComponent extends ContentComponent implements OnInit, OnDestroy {
  sidebarLogo = null;
  myRoute: any;
  navigationSubscription: any;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  menuItems: Array<AdditionalMenuItem> = [];
  subMenuItems: Array<AdditionalMenuItem> = [];

  constructor(
    injector: Injector,
    private auctionClusterService: AuctionClusterService,
    route: ActivatedRoute
  ) {
    super(injector);

    this.myRoute = route;
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.initialise();
      }
    });

    this._subscription = this.languageService.direction.subscribe(res => {
      this.rtlEnabled = res;
    });
  }

  initialise(){
    this.auctionClusterService.getAuctionClusters(this.id).subscribe(acs => {
      this.sidebarLogo = null;
      if (acs && acs.length > 0) {
        localStorage.setItem('AUCXIS_SIDEBAR_LOGO', acs[0].image);
      } else {
        localStorage.removeItem('AUCXIS_SIDEBAR_LOGO')
      }

      const acImage = localStorage.getItem('AUCXIS_SIDEBAR_LOGO');

      if (acImage) {
        this.sidebarLogo = acImage;
      }
    });
  }
  ngOnInit() {
    this.initialise();

    super.ngOnInit();

    this.additionalMenuItemsService.getPublicMenuItems().subscribe(result => {

      if (result !== undefined){
        result.forEach( item => {
          if (item.accessLevels.length > 0){
            item.accessLevels.forEach(_ => {
              if (_ === AccessLevel.BUYER) {
                if (item.submenuId !== null) {
                  this.subMenuItems.push(item);
                } else this.menuItems.push(item);
              }
            })
          }
        })

        this.menuItems.sort((a, b) => {
          if (a.orderNumber < b.orderNumber) {
            return -1;
          }
          if (a.orderNumber > b.orderNumber) {
            return 1;
          }
          return 0;
        })

        this.subMenuItems.sort((a, b) => {
          if (a.orderNumber < b.orderNumber) {
            return -1;
          }
          if (a.orderNumber > b.orderNumber) {
            return 1;
          }
          return 0;
        })
      }
    },
      error => {
        this.errorService.show(error);
      });
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  ngOnDestroy() {
    // avoid memory leaks here by cleaning up after ourselves. If we
    // don't then we will continue to run our initialise()
    // method on every navigationEnd event.
    if (this.navigationSubscription) {
       this.navigationSubscription.unsubscribe();
    }

    this._subscription.unsubscribe();
  }
 }
