import { Injectable } from '@angular/core';
import * as _moment from 'moment';
import { Observable } from 'rxjs';
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { WebApiService } from '../../../shared/services/web-api.service';
import { AuctionClusterNotification } from '../../../shared/models/auction-cluster-notification';
import { AuctionClusterChannel } from '../../../shared/models/auction-cluster-channel';
import { NotificationTypeEnum } from '../../../shared/models/notificationTypeEnum';

@Injectable()

export class AuctionClusterNotificationService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'auctioncluster';
  }
 
  getEnabledAuctionClusterNotificationChannels(auctionClusterId: number): Observable<Array<AuctionClusterNotification>> {
    return this.webApiService.getList(this.apiPath + '/' + auctionClusterId + '/auctionclusternotification');
  }

  getAuctionClusterNotificationForEdit(auctionClusterNotificationId: number, auctionClusterId: number): Observable<AuctionClusterNotification> {
    return this.webApiService.get(this.apiPath + '/' + auctionClusterId + '/auctionclusternotification', auctionClusterNotificationId);
  }

  getAuctionClusterNotificationByType(auctionClusterNotificationType: NotificationTypeEnum, auctionClusterId: number): Observable<AuctionClusterNotification> {
    return this.webApiService.getSingle(this.apiPath + '/' + auctionClusterId + '/auctionclusternotification/auctionclusternotificationtype/' + auctionClusterNotificationType);
  }

  getAuctionClusterChannels(auctionClusterId: number): Observable<Array<AuctionClusterChannel>> {
    return this.webApiService.getList(this.apiPath + '/' + auctionClusterId + '/auctionclusternotification/channels');
  }

  editAuctionClusterNotification(auctionClusterId: number, auctionClusterNotification: AuctionClusterNotification): Observable<any> {
    return this.webApiService.edit(this.apiPath + '/' + auctionClusterId + '/auctionclusternotification', auctionClusterNotification.auctionClusterNotificationId, auctionClusterNotification);
  }

  activateDeactivateAuctionClusterNotification(auctionClusterId: number, auctionClusterNotificationId: number, value: boolean): Observable<AuctionClusterNotification> {
    if (value) {    
      return this.webApiService.enableDisable(`${this.apiPath}/${auctionClusterId}/auctionclusternotification/activate`, auctionClusterNotificationId);  
    } else {          
      return this.webApiService.enableDisable(`${this.apiPath}/${auctionClusterId}/auctionclusternotification/deactivate`, auctionClusterNotificationId); 
    }
  }

  sendNotifications(auctionId: number, auctionClusterNotificationType: NotificationTypeEnum, catalogId: number, clockId: number, senddate: string, timeZoneOffset: number)
  {
    let url = this.apiPath + '/auction/' + auctionId + '/auctionclusternotification/send/' + auctionClusterNotificationType
    if (catalogId != null) {
      url += `?catalogid=${catalogId}`;
      if (clockId != null && clockId != 0) {
        url += `&clockid=${clockId}`;
      }
      if(senddate != null)
      {
        url+=`&senddate=${senddate}&timeZoneOffset=${timeZoneOffset}`;
      }

    }
    else if (clockId != null && clockId != 0) {
      url += `?clockid=${clockId}`;
    }
    else if(senddate != null)
    {
      url+=`?senddate=${senddate}&timeZoneOffset=${timeZoneOffset}`;
    }
    return this.webApiService.put(url, null);
  }

  resetChannelsForAllUsersToDefault(auctionClusterId: number, auctionClusterNotification: AuctionClusterNotification): Observable<any> {
    return this.webApiService.edit(this.apiPath + '/' + auctionClusterId + '/auctionclusternotification/resetToDefault', auctionClusterNotification.auctionClusterNotificationId, auctionClusterNotification);
  }
}
