<div class="supplier-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup #addSupplier [width]="600" [showTitle]="true" title="{{ modalTitle }}" [dragEnabled]="false"
    [closeOnOutsideClick]="false" [rtlEnabled]="rtlEnabled" [(visible)]="isOpened"
    (onShown)="onFieldDataChanged($event)">
    <dx-scroll-view width="100%" height="100%">

      <div class="form" *ngIf="!isEditMode">
        <div class="dx-fieldset">
          <div class="dx-field">
            <div class="dx-field-label">{{ 'SUPPLIER.VAT' | translate }}</div>
            <div class="dx-field-value">
              <dx-text-box [(value)]="vatValue" (onValueChanged)="searchByVat($event)"></dx-text-box>
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">{{ 'SEARCH' | translate }}</div>
            <div class="dx-field-value">
              <dx-button icon="search" (onClick)="searchByVat($event)"></dx-button>
            </div>
          </div>
        </div>
      </div>

      <div class="dx-fieldset" *ngIf="!isEditMode">
        <div class="dx-field">
          <div class="dx-field-value">
            <dx-select-box [items]="filteredSuppliers" displayExpr="name" valueExpr="supplierId"
              [(value)]="model.supplierId" [rtlEnabled]="rtlEnabled" (onOptionChanged)="suppliersChange($event)">
            </dx-select-box>
          </div>
        </div>
      </div>

      <div class="form-group" *ngIf="isEditMode">
        <label class="supplier-name" for="supplierName">{{'SUPPLIER.NAME' | translate}}&nbsp;{{model.name}}</label>
      </div>

      <dx-form #supplierForm id="supplierForm" validationGroup="supplierData" [showValidationSummary]="false"
        (onFieldDataChanged)="onFieldDataChanged($event)" colCount="auto" [(formData)]="model" [minColWidth]="500">

        <dxi-item itemType="tabbed">
          <dxi-tab title="{{ 'SUPPLIER.GENERAL' | translate }}">

            <dxi-item itemType="group">
              <dxi-item dataField="vat" [disabled]="model.usedOnOtherAuctionCluster">
                <dxo-label text="{{ 'SUPPLIERS.VAT' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SUPPLIERS.VAT_VALIDATION' | translate }}">
                </dxi-validation-rule>
              </dxi-item>

              <dxi-item dataField="name" [disabled]="model.usedOnOtherAuctionCluster">
                <dxo-label text="{{ 'SUPPLIERS.NAME' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SUPPLIERS.NAME_VALIDATION' | translate }}">
                </dxi-validation-rule>
              </dxi-item>

              <dxi-item dataField="address1" [disabled]="model.usedOnOtherAuctionCluster">
                <dxo-label text="{{ 'SUPPLIERS.ADDRESS' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SUPPLIERS.ADDRESS_VALIDATION' | translate }}">
                </dxi-validation-rule>
              </dxi-item>

              <dxi-item dataField="address2" [disabled]="model.usedOnOtherAuctionCluster">
                <dxo-label text="{{ 'SUPPLIERS.ADDRESS2' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SUPPLIERS.ADDRESS2_VALIDATION' | translate }}">
                </dxi-validation-rule>
              </dxi-item>

              <dxi-item dataField="zip" [disabled]="model.usedOnOtherAuctionCluster">
                <dxo-label text="{{ 'SUPPLIERS.ZIP' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SUPPLIERS.ZIP_VALIDATION' | translate }}">
                </dxi-validation-rule>
              </dxi-item>

              <dxi-item dataField="city" [disabled]="model.usedOnOtherAuctionCluster">
                <dxo-label text="{{ 'SUPPLIERS.CITY' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SUPPLIERS.CITY_VALIDATION' | translate }}">
                </dxi-validation-rule>
              </dxi-item>

              <dxi-item dataField="country" [disabled]="model.usedOnOtherAuctionCluster">
                <dxo-label text="{{ 'SUPPLIERS.COUNTRY' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SUPPLIERS.COUNTRY_VALIDATION' | translate }}">
                </dxi-validation-rule>
              </dxi-item>

              <dxi-item dataField="telNumber" [disabled]="model.usedOnOtherAuctionCluster">
                <dxo-label text="{{ 'SUPPLIERS.TEL_NUMBER' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SUPPLIERS.TEL_NUMBER_VALIDATION' | translate }}">
                </dxi-validation-rule>
              </dxi-item>

              <dxi-item dataField="faxNumber" [disabled]="model.usedOnOtherAuctionCluster">
                <dxo-label text="{{ 'SUPPLIERS.FAX_NUMBER' | translate }}"></dxo-label>
              </dxi-item>

              <dxi-item dataField="email" [disabled]="model.usedOnOtherAuctionCluster">
                <dxo-label text="{{ 'SUPPLIERS.EMAIL' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SUPPLIERS.EMAIL_VALIDATION' | translate }}">
                </dxi-validation-rule>
                <dxi-validation-rule type="email" message="{{ 'SUPPLIERS.EMAIL_VALIDATION' | translate }}">
                </dxi-validation-rule>
              </dxi-item>

              <dxi-item dataField="url" [disabled]="model.usedOnOtherAuctionCluster">
                <dxo-label text="{{ 'SUPPLIERS.URL' | translate }}"></dxo-label>
              </dxi-item>

              <dxi-item dataField="isActiveOnAuctionCluster" editorType="dxCheckBox"
                        [disabled]="model.usedOnOtherAuctionCluster">
                <dxo-label text="{{ 'SUPPLIERS.ACTIVE' | translate }}"></dxo-label>
              </dxi-item>

              <dxi-item name="show-administrator" [visible]="!isEditMode">
                <dxo-label text="{{ 'AUCTIONS_CLUSTERS.SUPPLIER_USER_LABEL' | translate }}"></dxo-label>
                <div *dxTemplate>
                  <dx-check-box [(value)]="administrator.visible">
                  </dx-check-box>
                </div>
              </dxi-item>

              <dxi-item #administrator name="administrator"
                        dataField="administratorId"
                        [visible]="!isEditMode"
                        editorType="dxSelectBox"
                        [editorOptions]="{ items: users, valueExpr:'userId', displayExpr:'lastname'}"
                        [rtlEnabled]="rtlEnabled">
              </dxi-item>

            </dxi-item>
          </dxi-tab>
          <dxi-tab title="{{ 'SUPPLIER.AUCTION_CLUSTER' | translate }}">
            <dxi-item itemType="group">
              <dxi-item dataField="supplierNumber">
                <dxo-label text="{{ 'SUPPLIER.NUMBER' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SUPPLIER.NUMBER_VALIDATION' | translate }}">
                </dxi-validation-rule>
              </dxi-item>
              <dxi-item dataField="sendSmsTransactionInfo" editorType="dxCheckBox">
                <dxo-label text="{{ 'SUPPLIER.SEND_SMS_TRANSACTION_INFO' | translate }}"></dxo-label>
              </dxi-item>
            </dxi-item>
          </dxi-tab>
        </dxi-item>
      </dx-form>
      <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnSupplierSubmit"
        disabled="disabled">{{ 'SAVE' | translate }}</button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'CANCEL' | translate }}</button>
    </dx-scroll-view>
  </dx-popup>
</div>
