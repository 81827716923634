import { Component, OnInit, Injector, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClrTabs } from '@clr/angular';
import { Subscription } from 'rxjs';
import notify from 'devextreme/ui/notify';

// general services
import { LanguageService } from '../../shared/services/language.service';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';
import { TranslatableFieldComponent } from '../../shared/components/translatable-field/translatable-field.component';

// services
import { CalculationsService } from '../shared/services/calculations.service';
import { ProductService } from '../shared/services/product.service';

// models
import { Language } from '../../shared/models/language';
import { Calculation, CalculationFieldTypes, CalculationMathTypes, CalculationFrontEndTypes } from '../shared/models/calculation';
import { ProductPropertyTypes, Product, ProductProperty } from '../shared/models/product';
import { MasterData } from '../shared/models/master-data';
import { DxFormComponent } from 'devextreme-angular';


@Component({
  selector: 'calculation-component',
  templateUrl: 'calculation.component.html'
})
export class CalculationComponent extends ItemDetailsComponent<Calculation> implements OnInit, OnDestroy {

  @ViewChild('tabs') tabs: ClrTabs;
  @ViewChild('calculationName') calculationName: TranslatableFieldComponent;

  auctionClusterId: number;
  languages: Array<Language> = [];
  calculationTypes: any = CalculationFieldTypes;
  propertyTypes: any = ProductPropertyTypes;
  mathTypes: any = CalculationMathTypes;
  frontEndTypes: any = CalculationFrontEndTypes;
  products: Array<Product> = [];
  productProperties: Array<ProductProperty> = [];
  masterDatas: Array<MasterData> = [];
  translations: any;
  translationSelectBoxes: any;
  validFields: boolean = false;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;
  @ViewChild('editCalculationsForm', { read: DxFormComponent}) editCalculationsForm: DxFormComponent;

  constructor(
    protected injector: Injector,
    private dataService: CalculationsService,
    private languageService: LanguageService,
    private productService: ProductService,
    private route: ActivatedRoute
  ) {
    super(injector);
    this.calculationTypes = Object.keys(this.calculationTypes).filter(f => !isNaN(Number(f))).map(key => ({ name: this.calculationTypes[key], value: Number(key) })); // tslint:disable-line:max-line-length
    this.propertyTypes = Object.keys(this.propertyTypes).filter(f => !isNaN(Number(f))).map(key => ({ name: this.propertyTypes[key], value: Number(key) })); // tslint:disable-line:max-line-length
    this.mathTypes = Object.keys(this.mathTypes).filter(f => !isNaN(Number(f))).map(key => ({ name: this.mathTypes[key], value: Number(key) })); // tslint:disable-line:max-line-length
    this.frontEndTypes = Object.keys(this.frontEndTypes).filter(f => !isNaN(Number(f))).map(key => ({ name: this.frontEndTypes[key], value: Number(key) })); // tslint:disable-line:max-line-length
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
    this.translate.get([
      'NUMBER',
      'DECIMAL',
      'TEXT',
      'DATE',
      'URL',
      'MASTER_DATA_VALUE',
      'IMAGE',
      'BOOLEAN',
      'AUTO_INCREMENT',
      'FRONTEND',
      'FRONTEND_OPERATION',
      'EXPRESSION',
      'NUMBER_OF_BOXES',
      'MATH',
      'CONCATENATE',
      'JSONCALC',
      'ADD',
      'SUBSTRACT',
      'MULTIPLY',
      'DIVIDE_ROUND',
      'DIVIDE_FLOOR',
      'DIVIDE_CEILING',
    ]).subscribe((res: string) => {
      this.translationSelectBoxes = res;
    });
  }

  ngOnInit() {
    this.model = new Calculation();
    this.auctionClusterId = +this.route.snapshot.params['id'];
    this.setTranslations('CALCULATIONS');
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  onProductChanged = (e: any) => {
    if (this.model.productId) {
      this.productService.getProduct(this.auctionClusterId, this.model.productId).subscribe((res: Product) => {
        this.productProperties = res.productProperties;
      });
    }
  }

  open(calculations: Array<Calculation>, calculationId: number, languages: Array<Language>,
      products: Array<Product>, masterDatas: Array<MasterData>) {
    // Autoselect first tab
    // this.tabs.ifActiveService.current = this.tabs.tabsService.children[0].id;

    this.allItems = calculations;
    this.languages = languages;
    this.products = products;
    this.masterDatas = masterDatas;

    this.products.forEach(item => {
      item.productNameText = this.languageService.getTranslatableText(item.name);
    });

    // EDIT
    if (calculationId != null) {
      this.isEditMode = true;
      this.spinner.show();
      this.dataService.getCalculation(this.auctionClusterId, calculationId)
        .subscribe((res: Calculation) => {
          this.model = res;
          this.validFields = this.languageService.checkDefaultLanguageNotEmpty(this.languages, this.model.name);

          this.onProductChanged(new Event('click'));

          this.isOpened = true;
          this.spinner.hide();
        },
        error => {
          notify(error.message, 'error', 5000);
          this.spinner.hide();
        });
    // NEW
    } else {
      this.model = new Calculation();
      this.isEditMode = false;
      this.isOpened = true;

      // Create empty JSON object for translation fields
      const emptyTranslation = {};
      this.languages.forEach(lang => {
        emptyTranslation[lang.code] = '';
      });

      this.model.name = JSON.stringify(emptyTranslation);
    }

    // this.calculationName.elementFocus();
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  save() {
    this.model.name = this.calculationName.data;

    if (this.isEditMode) {
      this.spinner.show();
      this.dataService.edit(this.model)
        .subscribe((res: any) => {
          this.model = new Calculation();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
        error => {
          this.errorMessage = this.errorService.toString(error);
          notify(this.errorMessage, 'error', 5000);
          this.spinner.hide();
        });
    } else {
      this.spinner.show();
      this.model.auctionClusterId = this.auctionClusterId;

      this.dataService.save(this.model)
        .subscribe((res: any) => {
          this.model = new Calculation();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
        error => {
          this.errorMessage = this.errorService.toString(error);
          notify(this.errorMessage, 'error', 5000);
          this.spinner.hide();
        });
    }
  }


  saveWithoutClose() {
    this.model.name = this.calculationName.data;

    if (this.isEditMode) {
      this.spinner.show();
      this.dataService.edit(this.model)
        .subscribe((res: any) => {
          this.model = res;
          this.errorMessage = null;
          this.spinner.hide();
        },
        error => {
          this.errorMessage = this.errorService.toString(error);
          notify(this.errorMessage, 'error', 5000);
          this.spinner.hide();
        });
    } else {
      this.spinner.show();
      this.model.auctionClusterId = this.auctionClusterId;

      this.dataService.save(this.model)
        .subscribe((res: any) => {
          this.model = res;
          this.errorMessage = null;
          this.spinner.hide();
        },
        error => {
          this.errorMessage = this.errorService.toString(error);
          notify(this.errorMessage, 'error', 5000);
          this.spinner.hide();
        });
    }
  }

  onFieldDataChanged(e: any) {
    if ((e.component._isReady && e.component.NAME !== 'dxPopup') || e.component.NAME === 'dxForm') {
      const result = e.component.validate();
      if (result.brokenRules.length == 0 || this.validFields) {
        document.getElementsByName('btnCalculationsSubmit')[0].removeAttribute('disabled');
      } else {
        document.getElementsByName('btnCalculationsSubmit')[0].setAttribute('disabled', 'disabled');
      }
    } else {
      if (this.isEditMode && this.validFields) {
        document.getElementsByName('btnCalculationsSubmit')[0].removeAttribute('disabled');
      } else {
        document.getElementsByName('btnCalculationsSubmit')[0].setAttribute('disabled', 'disabled');
      }
    }
  }

  onChangeTranslationField = (e: any, fieldName: string) => {
    if (fieldName === 'name') {
      this.validFields = this.languageService.checkDefaultLanguageNotEmpty(this.languages, this.calculationName.data);
      this.editCalculationsForm.instance.updateData(fieldName, this.calculationName.data);
    }
  }

  translateSelectBoxes = (item: any) => {
    if (item) {
      const label = this.translationSelectBoxes[item.name];
      return label;
    }
  }

}
