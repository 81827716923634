<div class="clocks-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <div class="item-list-component">
    <div class="row" id="auctionClocks">
      <div class="filter-column">
        <label for="clockSelect">{{ 'CLOCKS.SELECT_AUCTION' | translate }}</label>
        <dx-select-box name="clockSelect"
                       [items]="auctions"
                       displayExpr="name"
                       valueExpr="auctionId"
                       [(value)]="selectedAuction"
                       [rtlEnabled]="rtlEnabled"
                       [searchEnabled]="true"
                       [showClearButton]="true"
                       placeholder="{{ 'SELECT' | translate }} ..."
                       (onValueChanged)="getSelectedClocks()"></dx-select-box>
      </div>
    </div>
    <div class="grid-buttons">
      <dx-button hint="{{ 'ADD' | translate }}" icon="add" (onClick)="add()"></dx-button>
      <dx-button hint="{{ 'PRINT' | translate }}" icon="print" (onClick)="print()"></dx-button>
    </div>
    <br />
    <dx-data-grid #clocksGrid id="printSection" [rtlEnabled]="rtlEnabled" [dataSource]="items"
                  [showBorders]="true"
                  [allowColumnReordering]="true"
                  [hoverStateEnabled]="true"
                  [columnAutoWidth]="true"
                  [rowAlternationEnabled]="true"
                  (onCellClick)="startClockClick($event)"
                  (onExporting)="onExporting($event)">
      <dxo-export [enabled]="true"></dxo-export>
      <dxo-selection mode="single"></dxo-selection>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-filter-panel [visible]="true"></dxo-filter-panel>
      <dxo-search-panel [visible]="true"></dxo-search-panel>
      <dxo-header-filter [visible]="true"></dxo-header-filter>
      <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]"
                 [showNavigationButtons]="true" [showInfo]="true" infoText="{2} {{ 'CLOCKS.PAGINATION_TEXT_PLACEHOLDER' | translate | slice:2 }}">
      </dxo-pager>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="name" caption="{{ 'CLOCKS.NAME' | translate }}">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="auctionName" caption="{{ 'CLOCKS.AUCTION' | translate }}">
      </dxi-column>
      <dxi-column type="buttons" [width]="210">
        <dxi-button [visible]="startDemoVisible" hint="{{ 'CLOCKS.START' | translate }}" icon="video" [onClick]="startStopDemo"></dxi-button>
        <dxi-button [visible]="stopDemoVisible" hint="{{ 'CLOCKS.STOP' | translate }}" icon="square" [onClick]="startStopDemo"></dxi-button>
        <dxi-button [visible]="checkClockPermission" hint="{{ 'CLOCKS.LINE_MONITOR.TITLE' | translate }}" icon="menu" [onClick]="openLineMonitor"></dxi-button>
        <dxi-button [visible]="checkAuctioneerPermission" hint="{{ 'CLOCKS.OPEN_AUCTIONEER_CLOCK' | translate }}" icon="clock" [onClick]="startClock"></dxi-button>
        <dxi-button [visible]="checkAuctioneerPermission" hint="{{ 'CLOCKS.OPEN_AUCTIONEER_CLOCK_AS_VIEWER' | translate }}" icon="inactivefolder" [onClick]="openClockAsViewer"></dxi-button>
        <dxi-button [visible]="checkClockPermission" hint="{{ 'EDIT' | translate }}" icon="edit" [onClick]="edit"></dxi-button>
        <dxi-button [visible]="checkClockPermission" hint="{{ 'DELETE' | translate }}" icon="trash" [onClick]="deleteItem"></dxi-button>
        <dxi-button [visible]="tokenService.permissionMet('AuctionClusterPermissions.2', id)" hint="{{ 'CLOCKS.EXPORT_CLOCK' | translate }}" icon="export" [onClick]="exportClock"></dxi-button>
        <dxi-button [visible]="tokenService.permissionMet('AuctionClusterPermissions.2', id)" hint="{{ 'CLOCKS.EXPORT_CLOCK_RESULTS' | translate }}" icon="exportselected" [onClick]="exportClockResults"></dxi-button>
      </dxi-column>
    </dx-data-grid>
  </div>
  <clock-component #details (onClosed)="onDetailsComponentClosed($event)"></clock-component>
  <line-monitor-component #lineMonitor></line-monitor-component>
  <confirmation-component #confirmation title="{{ 'CLOCKS.DELETE_CLOCK' | translate }}"
                          message="{{ 'CLOCKS.DELETE_CLOCK_MESSAGE' | translate }}"
                          buttonCloseText="DELETE" cancellable="true" (close)="deleteSelected();"></confirmation-component>

  <a #exportClockLink class="export-link" [href]="exportUrl" [attr.download]="exportFilename"></a>
</div>
