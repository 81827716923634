<div class="users-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <div class="item-list-component">
    <div class="grid-buttons">
      <dx-button icon="add" hint="{{ 'ADD' | translate }}"
                 (onClick)="add()"></dx-button>
      <dx-button icon="print" hint="{{ 'PRINT' | translate }}"  
                 (onClick)="print()"></dx-button>
    </div>

    <div class="card">
      <div class="card-header">
        {{ 'USERS.FILTER_USERS' | translate }}
      </div>
      <div class="card-block">
        <form #detailsForm="ngForm">
          <div class="filter-column">
            <dx-check-box [(value)]="userFilter.showAuction"
                          text="{{ 'USERS.SHOW_AUCTION_USERS' | translate }}"
                          name="showAuctionUsers"
                          [rtlEnabled]="rtlEnabled"></dx-check-box>
            <dx-check-box [(value)]="userFilter.showBuyer"
                          text="{{ 'USERS.SHOW_BUYER_USERS' | translate }}"
                          name="showBuyerUsers"
                          [rtlEnabled]="rtlEnabled"></dx-check-box>
            <dx-check-box [(value)]="userFilter.showSupplier"
                          text="{{ 'USERS.SHOW_SUPPLIER_USERS' | translate }}"
                          name="showSupplierUsers"
                          [rtlEnabled]="rtlEnabled"></dx-check-box>
            <dx-check-box [(value)]="userFilter.showPlatform"
                          text="{{ 'USERS.SHOW_PLATFORM_USERS' | translate }}"
                          name="showPlatformUsers"
                          [rtlEnabled]="rtlEnabled"></dx-check-box>
          </div>
          <div class="filter-column">
            <dx-select-box name="auctionId"
                           [items]="auctionClusters"
                           valueExpr="auctionClusterId"
                           [(value)]="userFilter.auctionClusterId"
                           displayExpr="name"
                           [disabled]="!userFilter.showAuction"
                           [rtlEnabled]="rtlEnabled"
                           [searchEnabled]="true"
                           [showClearButton]="true"
                           placeholder="{{ 'SELECT' | translate }} ...">
            </dx-select-box>
            <dx-select-box name="buyerId"
                           [dataSource]="buyersPaginated"
                           valueExpr="buyerId"
                           [(value)]="userFilter.buyerId"
                           displayExpr="name"
                           [disabled]="!userFilter.showBuyer"
                           [rtlEnabled]="rtlEnabled"
                           [searchEnabled]="true"
                           [showClearButton]="true"
                           placeholder="{{ 'SELECT' | translate }} ...">
            </dx-select-box>
            <dx-select-box name="supplierId"
                           [dataSource]="suppliersPaginated"
                           valueExpr="supplierId"
                           [(value)]="userFilter.supplierId"
                           displayExpr="name"
                           [disabled]="!userFilter.showSupplier"
                           [rtlEnabled]="rtlEnabled"
                           [searchEnabled]="true"
                           [showClearButton]="true"
                           placeholder="{{ 'SELECT' | translate }} ...">
            </dx-select-box>
          </div>
          <div class="filter-column" id="user-filter-settings">

            <dx-check-box [(value)]="userFilter.showActive"
                          text="{{ 'USERS.SHOW_ACTIVE_USERS' | translate }}"
                          name="showActiveUsers"
                          [rtlEnabled]="rtlEnabled"></dx-check-box>
            <dx-check-box [(value)]="userFilter.showInactive"
                          text="{{ 'USERS.SHOW_INACTIVE_USERS' | translate }}"
                          name="showInactiveUsers"
                          [rtlEnabled]="rtlEnabled"></dx-check-box>
            <dx-check-box [(value)]="userFilter.showBlocked"
                          text="{{ 'USERS.SHOW_BLOCKED_USERS' | translate }}"
                          name="showBlockedUsers"
                          [rtlEnabled]="rtlEnabled"></dx-check-box>
            <dx-check-box [(value)]="userFilter.showPending"
                          text="{{ 'USERS.SHOW_PENDING_USERS' | translate }}"
                          name="showPendingUsers"
                          [rtlEnabled]="rtlEnabled"></dx-check-box>
          </div>
          <br /><br /><br />
          <div>
            <button type="submit" class="btn btn-primary" (click)="getData(true);">
              {{ 'FILTER_USERS' | translate }}
            </button><br /><br />
          </div>
        </form>
      </div>
    </div>

    <dx-data-grid #usersGrid id="printSection"
                  [allowColumnReordering]="true"
                  [allowColumnResizing]="true"
                  [columnAutoWidth]="true"
                  [dataSource]="items"
                  [hoverStateEnabled]="true"
                  [rtlEnabled]="rtlEnabled"
                  [showBorders]="true"
                  [rowAlternationEnabled]="true"
                  (onRowClick)="editItem($event)"
                  (onExporting)="onExporting($event)">
      <dxo-export [enabled]="true"></dxo-export>
      <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>
      <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
      <dxo-filter-panel [visible]="true"></dxo-filter-panel>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-header-filter [visible]="true"></dxo-header-filter>
      <dxo-search-panel [visible]="true"></dxo-search-panel>
      <dxo-selection mode="single"></dxo-selection>
      <dxo-state-storing [enabled]="true" type="localStorage" storageKey="storage_users"></dxo-state-storing>
      <dxo-pager [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]"
                 [showInfo]="true"
                 [showPageSizeSelector]="true"
                 [showNavigationButtons]="true"
                 [visible]="true"
                 infoText="{2} {{ 'USERS.PAGINATION_TEXT_PLACEHOLDER' | translate | slice:2 }}">
      </dxo-pager>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="boolean" dataField="isActive" caption="{{ 'USERS.ACTIVE' | translate }}">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="boolean" dataField="isBlocked" caption="{{ 'USERS.BLOCKED' | translate }}">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="firstname" caption="{{ 'USERS.FIRST_NAME' | translate }}">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="lastname" caption="{{ 'USERS.LAST_NAME' | translate }}">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="email" caption="{{ 'USERS.EMAIL' | translate }}">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="telNumber" caption="{{ 'USERS.TEL_NUMBER' | translate }}" [visible]="false">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="boolean" dataField="isSystemUser" caption="{{ 'USERS.SYSTEM_USER' | translate }}" *ngIf="user.isSystemUser">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="rolesString" caption="{{ 'USERS.ROLE' | translate }}">
      </dxi-column>
      <dxi-column type="buttons" caption="..." [allowHiding]="false">
        <dxi-button icon="edit" hint="{{ 'EDIT' | translate }}"
                    [onClick]="editItem"></dxi-button>
        <dxi-button icon="trash" hint="{{ 'DELETE' | translate }}"
                    [onClick]="deleteItem"></dxi-button>
        <dxi-button icon="key" hint="{{ 'USERS.CHANGE_USER_PASSWORD' | translate }}"
                    [onClick]="changeUserPassword"></dxi-button>
        <dxi-button icon="remove" hint="{{ 'USERS.DISABLE_USER' | translate }}"
                    [onClick]="enableDisableUser"
                    [visible]="calculateDisabledDisplayValue"></dxi-button>
        <dxi-button icon="check" hint="{{ 'USERS.ENABLE_USER' | translate }}"
                    [onClick]="enableDisableUser"
                    [visible]="calculateEnabledDisplayValue"></dxi-button>
        <dxi-button icon="clear" hint="{{ 'USERS.BLOCK_USER' | translate }}"
                    [onClick]="blockUnblockUser"
                    [visible]="calculateBlockedDisplayValue"></dxi-button>
        <dxi-button icon="user" hint="{{ 'USERS.UNBLOCK_USER' | translate }}"
                    [onClick]="blockUnblockUser"
                    [visible]="calculateUnblockedDisplayValue"></dxi-button>
      </dxi-column>
    </dx-data-grid>
  </div>

  <authentication-component></authentication-component>
  <user-component #details
                  (onClosed)="onDetailsComponentClosed($event)"></user-component>
  <confirmation-component #confirmation title="{{ 'USERS.DELETE_USER' | translate }}"
                          message="{{ 'USERS.DELETE_USER_MESSAGE' | translate }}"
                          buttonCloseText="DELETE" cancellable="true" (close)="deleteSelected();"></confirmation-component>
</div>
