import { Component, OnInit, OnDestroy, Injector, ViewChild } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';

// general services
import { LanguageService } from '../../shared/services/language.service';


// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { LookupTableComponent } from './lookup-table.component';

// services
import { LookupTableService } from '../shared/services/lookup-table.service';

// models
import { Language } from '../../shared/models/language';
import { LookupTable } from '../shared/models/lookup-table';
import { ImportSchema, ImportField } from '../../shared/import/import-schema';

import notify from 'devextreme/ui/notify';

@Component({
  selector: 'lookup-tables-component',
  templateUrl: 'lookup-tables.component.html',
  styleUrls: ['./lookup-tables.component.scss']
})
export class LookupTablesComponent extends FullListComponent<LookupTable, LookupTableComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: LookupTableComponent;

  languages: Array<Language> = [];

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private dataService: LookupTableService
  ) {
    super(injector, LookupTable);
    this.title.set('LOOKUP_TABLE.TITLE');

    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.setTranslations('LOOKUP_TABLE');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getData() {
    this.spinner.show();
    forkJoin(
      this.dataService.getLookupTables(this.id),
      this.languageService.getAuctionClusterLanguages(this.id)
    ).subscribe(result => {
      this.items = result[0];
      this.languages = result[1];
      this.translateLookupTables();
      this.spinner.hide();
    },
      error => {
        this.errorService.show(error);
        notify(error.message, 'error', 5000);
        this.spinner.hide();
      });
  }

  edit = (e: any) => {
    this.detailsComponent.modalTitle = this.translations.EDIT;
    this.detailsComponent.open(this.items, e.row.data.lookupTableId, this.languages);
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.lookupTableId;
    this.confirmation.opened = true;
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.ADD_NEW;
    this.detailsComponent.open(this.items, null, this.languages);
  }

  translateLookupTables() {
    this.items.forEach(loolupTable => {
      loolupTable.lookupTableText = this.languageService.getTranslatableText(loolupTable.name);
    });
  }

  openLookupTableListEditor = (e: any)  => {
    this.router.navigate(['/auction/lookuptables/' + this.id + '/lookuptableeditor/' + e.row.data.lookupTableId]);
  }

  openLookupTablesEditorClick = (e: any)  => {
    if (e.column.type !== 'buttons') {
      this.router.navigate(['/auction/lookuptables/' + this.id + '/lookuptableeditor/' + e.data.lookupTableId]);
    }
  }

  deleteSelected() {
    this.spinner.show();
    this.dataService.delete(this.itemIdToDelete, this.id)
      .subscribe((users: Array<LookupTable>) => {
        this.getData();
        this.spinner.hide();
      },
      error => {
        notify(this.errorService.translations.DELETE_ERROR_MESSAGE, 'error', 5000);
        this.spinner.hide();
      });
  }

  createDataSchema() {

    const schema = new ImportSchema();

    const lookupTableName = new ImportField('lookupTableText', this.translations.NAME);
    lookupTableName.required = true;

    schema.fields.push(lookupTableName);

    this.schema = schema;
  }

  getlookupTableTypeName = (e: any) => {
    if (e.value === 0) {
      return this.translations.FILTER;
    } else {
      return this.translations.DEFAULT_VALUE;
    }
  }
}
