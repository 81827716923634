<div class="item-list-component" [ngClass]="{'dx-rtl': rtlEnabled}" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">

    <div class="card">
      <div class="card-block">
        <form #filterForm="ngForm" *ngIf="product && product.moveLotsToCatalog">
            <div class="form-group move-lots-form-group destination">
              <label>{{ 'MOVE_LOTS.DESTINATION_CATALOG' | translate }}</label>
              <dx-select-box 
                name="catalog" 
                [items]="catalogs" 
                valueExpr="catalogId" 
                [(value)]="destinationCatalog"
                displayExpr="name"
                [rtlEnabled]="rtlEnabled"
                [searchEnabled]="true"
                [showClearButton]="true"
                (onClosed)="focusNextElement()"
                placeholder="{{ 'SELECT' | translate }} ...">
              </dx-select-box>
            </div>
            <br />
            <ng-container *ngFor="let prop of product.moveLotsToCatalog">
              <div class="form-group move-lots-form-group" *ngIf="prop.propertyTypeId === 5">
                <label>{{ prop.label | translateJson }}</label>
                <dx-tag-box
                  name="{{'select' + prop.productPropertyGroupingSelectionId }}"
                  [dataSource]="prop.options"
                  valueExpr="value"
                  displayExpr="label"
                  searchEnabled="true"
                  [(value)]="prop.masterDataRowIdSelection">
              </dx-tag-box>
              </div>
              <ng-container *ngIf="prop.propertyTypeId === 1">
                <div class="form-group from-to-group">
                  <label>{{ prop.label | translateJson }}</label>
                  <dx-number-box
                    name="{{'from' + prop.productPropertyGroupingSelectionId }}"
                    [(value)]="prop.intValueSelection"
                    [showSpinButtons]="true"
                    format="#0"
                    [step]="1"
                    min="0"
                    [rtlEnabled]="rtlEnabled"
                    >
                  </dx-number-box>
                  &nbsp;-&nbsp;
                  <dx-number-box
                    name="{{'to' + prop.productPropertyGroupingSelectionId }}"
                    [(value)]="prop.intValueToSelection"
                    [showSpinButtons]="true"
                    format="#0"
                    [step]="1"
                    min="0"
                    [rtlEnabled]="rtlEnabled"
                    >
                  </dx-number-box>
                </div>
              </ng-container>
            </ng-container>
            <div class="card-footer">
              <button type="button" class="btn btn-primary" (click)="save()" [disabled]="!filterForm.form.valid">{{ 'MOVE' | translate }}</button>
              <button type="button" class="btn btn-primary" (click)="cancel(false)">{{ 'CANCEL' | translate }}</button>
            </div>
        </form>
      </div>
    </div>
  