import { Component, OnInit, OnDestroy, Injector, ViewChild } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import notify from 'devextreme/ui/notify';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { GallerySeatComponent } from './gallery-seat.component';

// services
import { GalleryService } from '../shared/services/gallery.service';
import { LanguageService } from '../../shared/services/language.service';
import { AuctionClusterAuctionService } from '../shared/services/auction-cluster-auction.service';

// models
import { GallerySeat } from '../shared/models/gallery-seat';
import { ImportSchema, ImportField } from '../../shared/import/import-schema';
import { Auction } from '../shared/models/auction';
import { AuctionClusterPermissionEnum } from '../../shared/models/user-permissions';
import { AuctionClusterBuyerService } from '../shared/services/auction-cluster-buyer.service';
import { AuctionClusterBuyer } from '../shared/models/auction-cluster-buyer';


@Component({
  selector: 'gallery-component',
  templateUrl: 'gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent extends FullListComponent<GallerySeat, GallerySeatComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: GallerySeatComponent;

  clusters: Array<GallerySeat> = [];
  selectedAuction: number = -1;
  auctions: Array<Auction> = [];
  buyers: Array<AuctionClusterBuyer> = [];

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private dataService: GalleryService,
    private languageService: LanguageService,
    private auctionService: AuctionClusterAuctionService,
    private buyerService: AuctionClusterBuyerService
  ) {
    super(injector, GallerySeat);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.title.set('GALLERY.GALLERY_MANAGEMENT');
    this.setTranslations('GALLERY');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getData() {
    this.spinner.show();

    forkJoin(
      this.auctionService.getAuctionsForPermissions(this.id,
        [AuctionClusterPermissionEnum.GalleryManagement]),
      this.buyerService.getBuyers(this.id)

    ).subscribe(result => {

      this.auctions = result[0];
      this.buyers = result[1];
      if (this.auctions.length > 0) {
        if (this.selectedAuction == -1 || !this.selectedAuction)
          this.selectedAuction = this.auctions[0].auctionId;

        this.dataService.getGallerySeats(this.selectedAuction)
          .subscribe(res => {
            this.items = res;
            this.spinner.hide();
            this.matchAuctionName();
            this.matchBuyerNumber();
          }, error => {
            notify(error.message, 'error', 5000);
            this.spinner.hide();
          });
      }
      else {
        this.spinner.hide();
      }
    }, error => {
      notify(error.message, 'error', 5000);
      this.spinner.hide();
    });
  }

  edit = (e: any) => {
    if (this.selectedAuction) {
      this.detailsComponent.modalTitle = this.translations.EDIT;
      this.detailsComponent.open(this.items, e.row.data.gallerySeatId, this.selectedAuction, this.auctions, this.buyers);
    }
  }

  add() {
    if (this.selectedAuction) {
      this.detailsComponent.modalTitle = this.translations.ADD_NEW;
      this.detailsComponent.open(this.items, null, this.selectedAuction, this.auctions, this.buyers);
    }
  }

  private matchAuctionName() {
    this.items.forEach(item => {
      item.auctionName = this.auctions.find(f => f.auctionId === item.auctionId).name;
    });
  }

  private matchBuyerNumber() {
    this.items.forEach(item => {
      item.buyerNumber = this.buyers.find(f => f.buyerId === item.buyerId).buyerNumber;
    });
  }

  createDataSchema() {

    const schema = new ImportSchema();

    const auction = new ImportField('auctionName', this.translations.AUCTION);
    auction.required = true;

    const buyer = new ImportField('buyerNumber', this.translations.BUYER);
    buyer.required = true;

    const seatNumber = new ImportField('seatNumber', this.translations.SEAT_NUMBER);
    seatNumber.required = true;

    const reserved = new ImportField('reserved', this.translations.RESERVED);
    reserved.required = true;

    schema.fields.push(auction, buyer, seatNumber, reserved
    );

    this.schema = schema;
  }

  deleteItem = (e: any) => {
    if (this.selectedAuction) {
      this.itemIdToDelete = e.row.data.gallerySeatId;
      this.confirmation.opened = true;
    }
  }

  deleteSelected() {
    this.spinner.show();
    let itemToDelete: GallerySeat;
    this.items.forEach((gallerySeatItem) => {
      if (gallerySeatItem.gallerySeatId == this.itemIdToDelete) {
        itemToDelete = gallerySeatItem;
      }
    })
    this.dataService.delete(itemToDelete.auctionId, this.itemIdToDelete)
      .subscribe((locations: Array<GallerySeat>) => {
        this.getData();
        this.spinner.hide();
      },
        error => {
          this.errorService.show(this.errorService.translations.DELETE_ERROR_MESSAGE);
          this.spinner.hide();
        });
  }

  getSelectedGallerySeats() {
    if (this.selectedAuction) {
      this.dataService.getGallerySeats(this.selectedAuction).subscribe(seats => {
        this.items = seats;
        this.matchAuctionName();
        this.matchBuyerNumber();
        //this.buildDisplayUpcomingSaleText();
      });
    } else {
      this.getData();
    }
  }

  onDetailsComponentClosed(isDataChanged: boolean) {
    if (isDataChanged) {
      if (this.selectedAuction) {
        this.dataService.getGallerySeats(this.selectedAuction)
        .subscribe(res => {
          this.items = res;
          this.spinner.hide();
          this.matchAuctionName();
          this.matchBuyerNumber();
        }, error => {
          notify(error.message, 'error', 5000);
          this.spinner.hide();
        });
      } else {
        this.getData();
      }
    }
  }
}
