import { Component, OnInit, Injector, ViewChild, OnDestroy } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { AuctionComponent } from './auction.component';
import { NameConfirmationComponent } from './name-confirmation.component';
import { CleanupConfirmationComponent } from '../shared/components/cleanup-confirmation.component';
import notify from 'devextreme/ui/notify';

// services
import { AuctionClusterAuctionService } from '../shared/services/auction-cluster-auction.service';
import { TokenService } from '../../shared/services/token.service';
import { LanguageService } from '../../shared/services/language.service';

// models
import { Auction } from '../shared/models/auction';
import { AuctionCluster } from '../../platform/shared/models/auction-cluster';
import { ImportSchema, ImportField } from '../../shared/import/import-schema';

@Component({
  selector: 'auctions-component',
  templateUrl: 'auctions.component.html',
  styleUrls: ['./auctions.component.scss']
})
export class AuctionsComponent extends FullListComponent<Auction, AuctionComponent> implements OnInit, OnDestroy {

  clusters: Array<AuctionCluster> = [];

  @ViewChild('confirmation') confirmation: NameConfirmationComponent;
  @ViewChild('details') detailsComponent: AuctionComponent;
  @ViewChild('confirmationCleanup') cleanupConfirmation: CleanupConfirmationComponent;

  itemToDelete: Auction;
  auctionToCleanup: Auction;
  auctionCleanupDate = new Date();
  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private tokenService: TokenService,
    private auctionService: AuctionClusterAuctionService,
    private languageService: LanguageService
  ) {
    super(injector, Auction);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.title.set('PLATFORM.AUCTION_MANAGEMENT');
    this.setTranslations('AUCTIONS_MANAGEMENT');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getData() {
    this.spinner.show();

    forkJoin(
      this.auctionService.getAuctions(this.id)
    ).subscribe(
      result => {
        this.items = result[0];
        this.matchAuctionClusterNames();
        this.spinner.hide();
      }, error => {
        notify(error.message, 'error', 5000);
        this.spinner.hide();
      });
  }

  edit = (e: any) => {
    const auctionId = e.row !== undefined ? e.row.data.auctionId : e.data.auctionId;
    this.detailsComponent.modalTitle = this.translations.EDIT;
    this.detailsComponent.open(this.items, auctionId, this.id);
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.ADD_NEW;
    this.detailsComponent.open(this.items, null, this.id);
  }

  private matchAuctionClusterNames() {
    this.items.forEach(item => {
      const cluster = this.clusters.find(c => c.auctionClusterId === item.auctionClusterId);
      if (cluster) {
        item.auctionClusterDisplayName = cluster.name;
      }
    });
  }

  deleteItem = (e: any) => {
    this.itemToDelete = e.row.data;
    this.confirmation.opened = true;
  }

  openCleanupConfirmation(auction: Auction, event: Event) {
    event.stopPropagation();

    this.auctionToCleanup = auction;
    this.cleanupConfirmation.opened = true;
  }

  auctionCleanupDateChanged(date: Date) {
    this.auctionCleanupDate = date;
  }

  cleanupAuction() {
    if (this.tokenService.permissionMet('AuctionPermissions.14', this.auctionToCleanup.auctionId)) {
      this.auctionService.cleanupAuction(this.auctionToCleanup.auctionId, this.auctionCleanupDate).subscribe((res: any) => { });
    }

    this.cleanupConfirmation.opened = false;
  }

  deleteSelected() { } // tslint:disable-line:no-empty

  onNameConfirmationClosed(nameToDelete: string) {
    if (nameToDelete === this.itemToDelete.name) {
      this.spinner.show();
      this.auctionService.delete(this.id, this.itemToDelete.auctionId).subscribe((auctions) => {
        this.getData();
        this.spinner.hide();
      });
      this.confirmation.opened = false;
    }
  }

  createDataSchema() {

    const schema = new ImportSchema();

    const name = new ImportField('name', this.translations.NAME);
    name.required = true;

    schema.fields.push(name);

    this.schema = schema;
  }
}
