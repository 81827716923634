<div class="auction-cluster-role-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup
  [width]="600"
  [showTitle]="true"
  title="{{ modalTitle }}"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [rtlEnabled]="rtlEnabled"
  [(visible)]="isOpened"
  (onShown)="onFieldDataChanged($event)">
  <dx-scroll-view width="100%" height="100%">
    <dx-form
      #reportingRoleForm
      id="reportingRoleForm"
      validationGroup="roleData"
      [showValidationSummary]="false"
      (onFieldDataChanged)="onFieldDataChanged($event)"
      colCount="auto"
      [(formData)]="model"
      [minColWidth]="500">
        <dxi-item itemType="tabbed">
          <dxi-tab title="{{ 'ROLE.GENERAL' | translate }}">
            <dxi-item 
            dataField="name"
            [template]="'roleNameField'">
              <dxo-label text="{{ 'ROLE.NAME' | translate }}"></dxo-label>
              <dxi-validation-rule type="required" message="{{ 'USER.FIRST_NAME_VALIDATION' | translate }}"></dxi-validation-rule>
            </dxi-item>
            <dxi-item 
            dataField="description"
            [template]="'roleDescriptionField'">
              <dxo-label text="{{ 'ROLE.DESCRIPTION' | translate }}"></dxo-label>
              <dxi-validation-rule message="{{ 'USER.FIRST_NAME_VALIDATION' | translate }}"></dxi-validation-rule>
            </dxi-item>
          </dxi-tab>
          <dxi-tab title="{{ 'ROLE.PERMISSIONS' | translate }}">
            {{ 'AUCTIONS_CLUSTERS.TITLE' | translate }}
            <dx-list
                [items]="reportDesigns"
                [height]="400"
                [showSelectionControls]="true"
                [selectAllMode]="true"
                selectionMode="all"
                [(selectedItemKeys)]="requiredReportDesigns"
                [displayExpr]="translateCheckBoxes"
                keyExpr="reportDesignId">
            </dx-list>
          </dxi-tab>
        </dxi-item>

        <div *dxTemplate="let data of 'roleNameField'">
          <translatable-field (change)="onChangeTranslationField($event, 'name')" [isDevExpress]="true" [isLabelHidden]="true" [caption]="'ROLE.NAME'" [clusterLanguages]="languages" [(data)]="data.component.option('formData')[data.dataField]" [name]="'name'" #roleName></translatable-field>  
        </div>
        <div *dxTemplate="let data of 'roleDescriptionField'">
          <translatable-field (change)="onChangeTranslationField($event, 'description')" [isDevExpress]="true" [isLabelHidden]="true" [caption]="'ROLE.DESCRIPTION'" [clusterLanguages]="languages" [type]="'textbox'" [(data)]="data.component.option('formData')[data.dataField]" [name]="'description'" #roleDescription></translatable-field>  
        </div>
    </dx-form>

    <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnRoleSubmit" disabled="disabled">
      {{ 'SAVE' | translate }}
    </button>
    <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'CANCEL' | translate }}</button>
  </dx-scroll-view>
  </dx-popup>
</div>

