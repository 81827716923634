<div [ngClass]="{'dx-rtl': rtlEnabled}" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
    <dx-popup
        #verifyMyPassword
        width="40%"
        height="350"
        [showTitle]="true"
        title="{{ 'VERIFY_MY_PASSWORD.TITLE' | translate}}"
        [dragEnabled]="false"
        [closeOnOutsideClick]="false"
        [showCloseButton]="false"
        [rtlEnabled]="rtlEnabled"
        [visible]="isOpened"
        (onShown)="onFieldDataChanged($event)">
        <dx-scroll-view width="100%" height="100%">
            <dx-form #verifyMyPasswordForm id="verifyMyPasswordForm" [showValidationSummary]="false"  [(formData)]="model"
                (onFieldDataChanged)="onFieldDataChanged($event)" colCount="auto" [minColWidth]="500">
                <dxi-item dataField="password" [editorOptions]="{mode: 'password', valueChangeEvent: 'keyup'}">
                    <dxo-label text="{{ 'VERIFY_MY_PASSWORD.CURRENT_PASSWORD' | translate }}"></dxo-label>
                    <dxi-validation-rule type="required" message="{{ 'VERIFY_MY_PASSWORD.CURRENT_PASSWORD_VALIDATION' | translate }}">
                    </dxi-validation-rule>
                </dxi-item>
            </dx-form>
            <br/><br/>
            <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnVerifyMyPasswordSubmit" disabled>{{ 'VERIFY_MY_PASSWORD.VERIFY' | translate }}</button>
            <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'CANCEL' | translate }}</button>
        </dx-scroll-view>
    </dx-popup>
    </div>