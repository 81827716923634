import { UserAuctionClusterRole, UserAuctionClusterReportingRole, UserBuyerRole, UserSupplierRole } from '../../../platform/shared/models/user';
export class AuctionClusterUserSimple {
  userId: number;
  email: string;
}

export class AuctionClusterUser {
  userId: number;
  email: string;
  password: string;
  confirmPassword: string;
  firstname: string;
  lastname: string;
  isActive: boolean;
  isBlocked: boolean;
  isSystemUser: boolean;
  languageId: number;
  usedByOtherObjects: boolean;
  auctionClusterRoles: Array<UserAuctionClusterRole> = [];
  userAuctionClusterReportingRoles: Array<UserAuctionClusterReportingRole> = [];
  buyerRoles: Array<UserBuyerRole> = [];
  supplierRoles: Array<UserSupplierRole> = [];
  existingSupplierVat: string;
  isPending: boolean;
  existingBuyerVat: string;
  sendAccountInfo: boolean;
  telNumber: string;
  mfaEnabled: boolean;
  userDisplayName: string;

  // displayable fields:
  auctionClusterRolesText: string;

  domain: string;

  termsAccepted: boolean;

  overruleUserLicenses: boolean;
}
