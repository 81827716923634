<div class="report-editor-component">
  <div class="clr-row" style="width: 100%">
    <dx-report-viewer #reportViewer [reportUrl]="reportUrl" height="100%" class="clr-col-sm-9">
      <dxrv-request-options [invokeAction]="invokeAction" [host]="hostUrl"></dxrv-request-options>
      <dxrv-callbacks (StartBuild)="reportStartBuild($event)"
                      (DocumentReady)="reportDocumentReady($event)"
                      (OnServerError)="reportOnServerError($event)"
                      (CustomizeMenuActions)="reportCustomizeMenuActions($event)"
                      (CustomizeElements)="reportCustomizeElements($event)">
      </dxrv-callbacks>
    </dx-report-viewer>

    <div class="card form clr-col-sm-3" *ngIf="filters.length > 0">
      <div class="card-header">
        {{ reportName | translateJson}}
      </div>
      <div class="card-block">
        <div *ngFor="let filter of filters; let i = index" class="filter-column">
          <!-- Date -->
          <div *ngIf="filter.propertyTypeId == 4">
            <label>{{ getTranslation(filter.name) }}</label>
            <ng-select ngDefaultControl class="filter-select" name="filter.{{filter.name}}"
                       [(ngModel)]="dateFiltersCascadingDefinitions[filter.productPropertyId]">
              <ng-option *ngIf="filter.showToday == true" value="0">{{ 'TODAY' | translate }}</ng-option>
              <ng-option *ngIf="filter.showYesterday== true" value="1">{{ 'YESTERDAY' | translate }}</ng-option>
              <ng-option *ngIf="filter.showLastWeek== true" value="2">{{ 'LAST_WEEK' | translate }}</ng-option>
              <ng-option *ngIf="filter.showRange== true" value="3">{{ 'RANGE' | translate }}</ng-option>
              <ng-option *ngIf="filter.showTomorrow== true" value="4">{{ 'TOMORROW' | translate }}</ng-option>
              <ng-option *ngIf="filter.showDate== true" value="5">{{ 'DATE' | translate }}</ng-option>
            </ng-select>
            <div class="date-range-holder" *ngIf="dateFiltersCascadingDefinitions[filter.productPropertyId] == '3'">
              <!-- <label>Date</label> -->
              <input type="text" placeholder="Date Time Range:"
                     [(ngModel)]="filterBindings[filter.productPropertyId]" [selectMode]="'range'"
                     [owlDateTimeTrigger]="dtRange1" [owlDateTime]="dtRange1">
              <owl-date-time [pickerType]="'calendar'" #dtRange1></owl-date-time>
            </div>
            <div class="date-range-holder" *ngIf="dateFiltersCascadingDefinitions[filter.productPropertyId] == '5'">
              <input type="text" placeholder="Date Time:"
                     [(ngModel)]="filterBindings[filter.productPropertyId]" [selectMode]="'single'"
                     [owlDateTimeTrigger]="dtRange2" [owlDateTime]="dtRange2">
              <owl-date-time [pickerType]="'calendar'" #dtRange2></owl-date-time>
            </div>
          </div>
          <!-- Master data value -->
          <div *ngIf="filter.propertyTypeId == 5">
            <label>
              {{ getTranslation(filter.name) }}
            </label>
            <ng-select ngDefaultControl
                       [searchable]="true" class="filter-select"
                       #masterDataProp="ngModel"
                       [virtualScroll]="true"
                       (clear)="clearLotPropertyValue(filter.productPropertyId)"
                       name="{{'product' + i}}"
                       (change)="onPropertyChanged(filter, $event)"
                       [(ngModel)]="filterBindings[filter.productPropertyId]">
              <ng-option *ngFor="let option of comboBoxes[i]" [value]="option.value">{{ getPlainText(getTranslation(option.text)) }}</ng-option>
            </ng-select>
          </div>
        </div>
        <div>
          <div class="filter-template-column" *ngIf="!report.systemDefault">
            <button type="button" class="btn btn-icon btn-primary" (click)="save()">
              <i class="dx-icon-floppy"></i>{{ 'SAVE' | translate }}
            </button>
          </div>
          <div class="filter-template-column">
            <button type="button" class="btn btn-icon btn-primary" (click)="openSaveAsNewUserReportTemplateDialog()">
              <i class="dx-icon-plus"></i>{{ 'SAVE_AS' | translate }}
            </button>
          </div>
          <div class="filter-template-column">
            <button type="button" class="btn btn-icon btn-primary" (click)="filterReport()">
              <i class="dx-icon-detailslayout"></i>{{ 'RUN' | translate }}
            </button>
          </div>
          <div class="filter-template-column" *ngIf="report.reportDesign.exportType > 0">
            <button type="button" class="btn btn-icon btn-primary" (click)="exportReport()">
              <i class="dx-icon-export"></i>{{ 'EXPORT' | translate }}
            </button>
          </div>
          <div class="filter-template-column">
            <span *ngIf="lastRefresh !== ''">
              {{ 'BUYER.SUPPLY_MATRIX.LAST_REFRESH' | translate }} {{ lastRefresh }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <clr-modal [(clrModalOpen)]="isSaveProfileDialogOpened">
    <h3 class="modal-title">{{ 'REPORTING.USER_REPORT_TEMPLATE.TITLE' | translate }}</h3>
    <div class="modal-body">
      <form #detailsForm="ngForm">
        <div class="form-group">
          <label class="required">{{ 'REPORTING.USER_REPORT_TEMPLATE.NAME' | translate }}:</label>
          <label for="name" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm"
                 [ngClass]="{'invalid': name.dirty && name.invalid}">
            <input type="text" name="name" id="name" placeholder="{{ 'REPORTING.USER_REPORT_TEMPLATE.NAME' | translate }}"
                   [(ngModel)]="newReportName" #name="ngModel" required />
            <span class="tooltip-content">{{ 'REPORTING.USER_REPORT_TEMPLATE.NAME_VALIDATION' | translate }}</span>
          </label>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" (click)="saveNewReport();"
              [disabled]="!detailsForm.form.valid">
        {{ 'SAVE' | translate }}
      </button>
      <button type="button" class="btn btn-outline"
              (click)="closeSaveAsNewUserReportTemplateDialog();">
        {{ 'CANCEL' | translate }}
      </button>
    </div>
  </clr-modal>
</div>
