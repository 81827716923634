import { Component, Output, OnInit, Injector, EventEmitter, Input, OnDestroy, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs';

// general services
import { LanguageService } from '../../shared/services/language.service';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';

// models
import { ProductProperty, GridProperty, ProductPropertyTypes } from '../shared/models/product';
import { MasterData, MasterDataField } from '../shared/models/master-data';
import { MasterDataService } from '../shared/services/master-data.service';
import { ActivatedRoute } from '@angular/router';
import { DxSelectBoxComponent } from 'devextreme-angular';

@Component({
  selector: 'calculations-field-component',
  templateUrl: 'calculations-field.component.html',
})
export class CalculationsFieldComponent extends ItemDetailsComponent<GridProperty> implements OnInit, OnDestroy {

  masterDatas: Array<MasterData> = [];
  productProperties: Array<ProductProperty> = [];
  isMasterData = false;
  masterDataFields: Array<MasterDataField> = [];
  auctionClusterId: number;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  // tslint:disable:no-output-on-prefix
  @Output() onAdded = new EventEmitter<GridProperty>();
  @Output() onUpdated = new EventEmitter<GridProperty>();
  // tslint:enable:no-output-on-prefix

  @ViewChild('masterDataCombo') selectbox: DxSelectBoxComponent;

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private masterDataService: MasterDataService,
    private route: ActivatedRoute
  ) {
    super(injector);
    this.auctionClusterId = +this.route.snapshot.params['id'];
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.model = new GridProperty();
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  open(properties: Array<GridProperty>, property: GridProperty, productProperties: Array<ProductProperty>, masterDataList: Array<MasterData>) { // tslint:disable-line:max-line-length

    this.allItems = properties;
    this.productProperties = productProperties;
    this.masterDatas = masterDataList;

    this.translateProductProperties();

    this.masterDatas.forEach(masterData => {
      masterData.masterDataNameText = this.languageService.getTranslatableText(masterData.name);
    });

    if (property !== null) {
      this.isEditMode = true;
      this.model = property;
      this.filterMasterDataFields();
      this.isOpened = true;
    } else {
      this.model = new GridProperty();
      this.isEditMode = false;
      this.isOpened = true;
    }
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  translateProductProperties() {
    this.productProperties.forEach(productProperty => {
      productProperty.productPropertyNameText = this.languageService.getTranslatableText(productProperty.name);
    });
  }

  save() {
    if (this.isEditMode) {
      this.onUpdated.emit(this.model);
    } else {
      this.onAdded.emit(this.model);
    }

    this.model = new GridProperty();
    this.close(true);
    this.errorMessage = null;
  }

  onMasterDataChanged = (e: any) => {
    this.filterMasterDataFields();
    this.model.masterDataListFieldId = null;
  }

  filterMasterDataFields() {
    const prop = this.productProperties.find(pp => pp.productPropertyId === this.model.productPropertyId);
    if (prop && prop.propertyTypeId === ProductPropertyTypes.MASTER_DATA_VALUE) {
      this.masterDataService.getMasterDataListForEdit(prop.masterDataListId, this.auctionClusterId).subscribe(mdl => {
        this.masterDataFields = mdl.fields;
        this.isMasterData = true;
      });
    } else {
      this.masterDataFields = [];
      this.isMasterData = false;
    }
  }

  onFieldDataChanged(e: any) {
    if ((e.component._isReady && e.component.NAME !== 'dxPopup') || e.component.NAME === 'dxForm') {
      const result = e.component.validate();
      if (result.brokenRules.length >= 1) {
        document.getElementsByName('btnCalculationFieldSubmit')[0].setAttribute('disabled', 'disabled');
      } else {
        document.getElementsByName('btnCalculationFieldSubmit')[0].removeAttribute('disabled');
      }
    } else {
      if (this.isEditMode) {
        document.getElementsByName('btnCalculationFieldSubmit')[0].removeAttribute('disabled');
      } else {
        document.getElementsByName('btnCalculationFieldSubmit')[0].setAttribute('disabled', 'disabled');
      }
    }

    if (e.dataField === 'masterDataListFieldId') {

      const masterInterval = setInterval(() => {
        if (this.isMasterData) {
          clearInterval(masterInterval);
          this.selectbox.instance.getDataSource().load().then((items: any) => {
            const i = items.findIndex(item => item.name && item.name === this.model.masterDataFieldName);
            if (i !== -1) {
              const name = this.translateFields({ name: items[i].name });
              this.selectbox.instance.option('value', name);
            }
          });
        }
      }, 50);
    }
  }

  translateFields = (item: any) => {
    if (item) {
      const label = this.getTranslation(item.name);
      return label;
    }
  }

}
