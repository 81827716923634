<div class="buyer-prebid-list-component item-list-component">
    <clr-modal [(clrModalOpen)]="isOpened" [clrModalClosable]="false">
      <h3 class="modal-title">{{ modalTitle }}</h3>
      <div class="modal-body">
        <clr-alert *ngIf="errorMessage" [clrAlertType]="'danger'" [clrAlertClosable]="false">
          <div class="alert-item">
            <span class="alert-text">
              {{errorMessage}}
            </span>
          </div>
        </clr-alert>
  
        <form #detailsForm="ngForm">
          <clr-tabs #tabs>
            <clr-tab>
              <button clrTabLink>{{ 'PREBID_ON_PRODUCT.GENERAL' | translate }}</button>
              <clr-tab-content>
                <div class="tab-content">
                  <translatable-field [caption]="'PRODUCT.NAME'" [(data)]="model.name" [name]="'name'" #prebidName></translatable-field>
                  <translatable-field [caption]="'PRODUCT.DESCRIPTION'" [type]="'textbox'" [name]="'description'" [(data)]="model.description" #prebidNameDescription></translatable-field>
                  <div class="form-group">
                    <label class="required">{{ 'PREBID_ON_PRODUCT.AUCTION_CLUSTER' | translate }}</label>
                    <div class="select">
                      <select name="auctionCluster" required [(ngModel)]="model.auctionClusterId" (ngModelChange)="onAuctionClusterChanged()">
                        <option [ngValue]="null"></option>
                        <option *ngFor="let ac of auctionClusters" [ngValue]="ac.auctionClusterId">{{ ac.name }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="required">{{ 'PREBID_ON_PRODUCT.PRODUCT' | translate }}</label>
                    <div class="select">
                      <select name="product" required [(ngModel)]="model.productId" [disabled]="!model.auctionClusterId">
                        <option [ngValue]="null"></option>
                        <option *ngFor="let p of products" [ngValue]="p.productId">{{ getTranslation(p.name) }}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </clr-tab-content>
            </clr-tab>
          </clr-tabs>
        </form>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" (click)="onSubmit();" [disabled]="!detailsForm.form.valid || !prebidName?.valid">{{ 'SAVE' | translate }}</button>
        <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'CANCEL' | translate }}</button>
      </div>
    </clr-modal>
  </div>
