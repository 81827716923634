import { Component, ViewChild, OnInit, Injector, Input, Output, EventEmitter } from '@angular/core';

// general services
import { LanguageService } from '../../shared/services/language.service';

// components
import { SimpleListComponent } from '../../shared/components/simple-list/simple-list.component';
import { CurrentLotPropertyComponent } from './current-lot-property.component';

// models
import { ProductProperty, ProductPropertyType, LotEditorProperty,
  ProductPropertyGroup, NonGroupingSelection
} from '../shared/models/product';
import { ProductPropertyStyling } from '../../shared/models/product-property-styling';
import { MasterData, MasterDataField } from '../shared/models/master-data';
import { MasterDataService } from '../shared/services/master-data.service';
import { ActivatedRoute } from '@angular/router';
import { Language } from '../../shared/models/language';

@Component({
  selector: 'current-lot-properties-component',
  templateUrl: 'current-lot-properties.component.html'
})
export class CurrentLotPropertiesComponent extends SimpleListComponent implements OnInit {

  items: Array<LotEditorProperty> = [];
  productProperties: Array<ProductProperty> = [];
  propertyTypes: Array<ProductPropertyType> = [];
  propertyStylings: Array<ProductPropertyStyling> = [];
  propertyGroups: Array<ProductPropertyGroup> = [];
  masterData: Array<MasterData> = [];
  editorProperty: boolean;
  clockFaceProperty: boolean;
  showTouchScreenSettings: boolean;
  editingIndex: number;
  pageSize = 50;
  auctionClusterId: number;
  languages: Array<Language> = [];
  propertyGroupingShowing = false;
  automaticProductPropertyNonGroupingSelections: Array<NonGroupingSelection> = [];
  manualProductPropertyNonGroupingSelections: Array<NonGroupingSelection> = [];
  showWidth = false;
  lotInPreparationProperties = false;

  @Input('propertyGroupingShowing')
  set propertyGroupingShowingSetter(value: boolean) {
    this.propertyGroupingShowing = value;
  }

  @Input('showWidth')
  set showWidthSetter(value: boolean) {
    this.showWidth = value;
  }

  @Input('automaticProductPropertyNonGroupingSelections')
  set automaticProductPropertyNonGroupingSelectionsValues(value: Array<NonGroupingSelection>) {
    this.automaticProductPropertyNonGroupingSelections = value;
  }

  @Input('manualProductPropertyNonGroupingSelections')
  set manualProductPropertyNonGroupingSelectionsValues(value: Array<NonGroupingSelection>) {
    this.manualProductPropertyNonGroupingSelections = value;
  }

  @Input('languages')
  set langs(value: Array<Language>) {
    this.languages = value;
  }

  @Input('data')
  set data(value: Array<LotEditorProperty>) {
    this.items = value;
    this.items.sort((a, b) => {
      return a.orderNumber - b.orderNumber;
    });
    this.matchTypeNames();
    this.matchMasterDataFieldNames();
  }

  @Input('productProperties')
  set productProperty(value: Array<ProductProperty>) {
    this.productProperties = value;
    this.matchProductPropertiesNames();
    this.translateProperties();
    this.matchTypeNames();
    this.matchMasterDataFieldNames();
  }

  @Input('productPropertyTypes')
  set propertyType(value: Array<ProductPropertyType>) {
    this.propertyTypes = value;
    this.matchTypeNames();
    this.matchMasterDataFieldNames();
  }

  @Input('productPropertyStylings')
  set propertyStyling(value: Array<ProductPropertyStyling>) {
    this.propertyStylings = value;
    this.matchTypeNames();
    this.matchMasterDataFieldNames();
  }

  @Input('masterData')
  set masterDataList(value: Array<MasterData>) {
    this.masterData = value;
    this.matchTypeNames();
    this.matchMasterDataFieldNames();
  }

  @Input('propertyGroups')
  set propertyGroup(value: Array<ProductPropertyGroup>) {
    this.propertyGroups = value;
    this.matchTypeNames();
    this.matchMasterDataFieldNames();
  }

  @Input('isEditorProperty')
  set isEditorProperty(value: boolean) {
    this.editorProperty = value;
  }

  @Input('isClockFaceProperty')
  set isClockFaceProperty(value: boolean) {
    this.clockFaceProperty = value;
  }

  @Input('showTouchScreenSettings')
  set setShowTouchScreenSettings(value: boolean) {
    this.showTouchScreenSettings = value;
  }

  @Input('lotInPreparationProperties')
  set shortkeyLabelShown(value: boolean) {
    this.lotInPreparationProperties = value;
  }

  @Output() dataChanged = new EventEmitter<boolean>();

  @ViewChild(CurrentLotPropertyComponent) detailsComponent: CurrentLotPropertyComponent;

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private masterDataService: MasterDataService,
    private route: ActivatedRoute
  ) {
    super(injector);
    this.auctionClusterId = +this.route.snapshot.params['id'];
  }

  ngOnInit() { // tslint:disable-line:no-empty
    this.setTranslations('PRODUCT');
  }

  edit(property: LotEditorProperty, event: Event) {
    this.editingIndex = this.items.indexOf(property);
    event.stopPropagation();
    this.detailsComponent.modalTitle = this.translations.PROPERTY.EDIT;
    const clone = { ...property };
    this.detailsComponent.open(this.items.filter(item => item !== property), clone,
      this.productProperties, this.propertyTypes, this.propertyStylings, this.propertyGroups,
      this.masterData, this.editorProperty, this.clockFaceProperty, this.languages, this.showTouchScreenSettings); // tslint:disable-line:max-line-length
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.PROPERTY.ADD_NEW;
    this.detailsComponent.open(this.items, new LotEditorProperty(),
      this.productProperties, this.propertyTypes, this.propertyStylings, this.propertyGroups,
      this.masterData, this.editorProperty, this.clockFaceProperty, this.languages, this.showTouchScreenSettings); // tslint:disable-line:max-line-length
  }

  onProductPropertyAdded(property: LotEditorProperty) {
    this.items.push(property);
    this.matchProductPropertiesNames();
    this.translateProperties();
    this.matchTypeNames();
    this.matchMasterDataFieldNames();
    // first sort items and give them numbers from 0
    this.setOrderNumbers();
    this.dataChanged.emit();
  }

  onProductPropertyUpdated(property: LotEditorProperty) {
    this.items[this.editingIndex] = property;
    this.matchProductPropertiesNames();
    this.translateProperties();
    this.matchTypeNames();
    this.matchMasterDataFieldNames();
    // first sort items and give them numbers from 0
    this.setOrderNumbers();
    this.dataChanged.emit();
  }

  setOrderNumbers() {
    this.items.sort((a, b) => a.orderNumber - b.orderNumber);
    this.items.forEach((item, i) => {
      item.orderNumber = i;
    });
  }

  delete(property: LotEditorProperty) {
    this.items.splice(this.items.indexOf(property), 1);
    // first sort items and give them numbers from 0
    this.setOrderNumbers();
    this.dataChanged.emit();
  }

  matchProductPropertiesNames() {
    this.items.forEach(property => {
      let productProperty = this.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (productProperty) {
        property.productPropertyName = productProperty.name;
      }
    });

  }

  translateProperties() {
    this.items.forEach(property => {
      property.productPropertyName = this.languageService.getTranslatableText(property.productPropertyName);
    });
  }

  matchTypeNames() {
    this.items.forEach(item => {
      const prop = this.productProperties.find(f => f.productPropertyId === item.productPropertyId);
      if (prop) {
        const ppt = this.propertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId);
        if (ppt) {
          item.productPropertyTypeName = ppt.name;
        }
      }
    });
  }

  matchMasterDataFieldNames() {
    this.items.forEach(item => {
      const prop = this.productProperties.find(pp => pp.productPropertyId === item.productPropertyId);
      if (prop && prop.masterDataListId) {
        let masterDataFields: Array<MasterDataField> = [];
        const result = this.masterData.find(md => md.masterDataListId === prop.masterDataListId);
        if (result) {
          masterDataFields = result.fields;
          const masterDataField = masterDataFields.find(mdf => mdf.masterDataListFieldId === item.masterDataListFieldId);
          if (masterDataField) {
            item.masterDataFieldName = masterDataField.name;
          }
        }
      }
    });
  }

  moveUp(event: Event) {
    this.moveUpItems(event, this.items);
  }

  moveDown(event: Event) {
    this.moveDownItems(event, this.items);
  }
}
