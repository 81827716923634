import { Component, Injector, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import * as _moment from 'moment';

import { ItemDetailsComponent } from '../../../shared/components/item-details/item-details.component';
import { forkJoin, Subscription } from 'rxjs';

// models
import { BuybackOnLot, BuybackOnLotGroup, BuyerActionOnLot, BuyerActionOnLotGroup, GenericBuybackLot, GenericLotColumn, GenericLotRow, GenericLots } from '../../../shared/models/generic-lot';
import { ProductPropertyTypes } from '../../../auction/shared/models/product';


import { ImportSchema, ImportField } from '../../../shared/import/import-schema';
import notify from 'devextreme/ui/notify';
import { LotService } from '../services/lot.service';
import { AuctionSupplyMonitorService } from '../../shared/services/auction-supply-monitor.service';
import { AuctionClusterBuyer, SubBuyer, BuyerLevelSubbuyer, BuyerWithSubbuyers } from "../models/auction-cluster-buyer";
import { DxDataGridComponent } from "devextreme-angular";
import { AuctionClusterBuyerService } from "../services/auction-cluster-buyer.service";

const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'buyback-on-lot-component',
  templateUrl: 'buyback-on-lot.component.html',
  styleUrls: ['./buyback-on-lot.component.scss']
})
export class BuybackOnLotComponent extends ItemDetailsComponent<GenericLotRow> implements OnInit, OnDestroy {

  @ViewChild("buybackOnLotGrid") buybackGrid: DxDataGridComponent;

  buyerId: number;
  catalogId: number;
  auctionClusterId: number;
  currentLot: any;
  columns: Array<any> = [];
  data: Array<any> = new Array<any>();
  gridColumns = [];
  selectedRows: Array<number> = [];
  gridItems = [];
  items = [];
  objectKeys: Array<string> = new Array<string>();
  lotId: number;
  buybacksOnLot: Array<BuybackOnLot> = new Array<BuybackOnLot>();
  buyback: BuybackOnLot;
  buyers: Array<AuctionClusterBuyer>;
  buyersSubbuyers: Array<BuyerWithSubbuyers>;
  buyersSubbuyersPaginated: any = {};
  saveDisabled: boolean = false;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;
  dataGridInstance: any;

  constructor(
    protected injector: Injector,
    private route: ActivatedRoute,
    private lotService: LotService,
    private translateService: TranslateService,
    private supplyMonitorService: AuctionSupplyMonitorService,
    private auctionClusterBuyerService: AuctionClusterBuyerService
  ) {
    super(injector);
    this._subscription = this.language.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.model = new GenericLotRow();
    this.buyerId = +this.route.snapshot.params['id'];

    this.setTranslations('BUYER.SUPPLY_MONITOR');
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  open(lots: Array<number>, catalogId: number, auctionClusterId: number, buyers: Array<BuyerWithSubbuyers>, date: Date){

    this.auctionClusterId = auctionClusterId;
    this.buyersSubbuyers = buyers;
    this.catalogId = catalogId;

    this.buyersSubbuyersPaginated = {
      paginate: true,
      pageSize: 20,
      store: this.buyersSubbuyers,
      sort: 'buyerName'
    };

    this.data = [];
    this.items = [];
    const dateFormatted = moment(date).local().format('YYYY-MM-DD');

    this.spinner.show();

    this.supplyMonitorService.getUngroupedLots(auctionClusterId, catalogId, dateFormatted, lots)
      .subscribe(result => {
        this.items = result.lots.rows;

        this.parseColumns(result.lots.columnTitles);
        this.parseGenericLots(result);
        this.spinner.hide();
      },
        error => {
          notify(error.message, 'error', 5000);
          this.spinner.hide();
        });

      if (this.buybacksOnLot) {
          this.isEditMode = true;
        } else {
          this.buybacksOnLot = new Array<BuybackOnLot>();
          this.isEditMode = false;
        }

      this.isOpened = true;
  }

  private parseGenericLots(result: GenericBuybackLot) {
    this.data = [];


    result.lots.rows.forEach(lot => {

    let obj = this.parseLot(lot);

    this.data.push(obj);

      let lotbuybacks: Array<BuybackOnLot> = [];

      if (result.buybacks) {
        lotbuybacks = result.buybacks.filter(_ => _.lotId === lot.id);
      }

      if (lotbuybacks.length !== 0) {

        lotbuybacks.forEach((buyback, index) => {

          let obj = this.parseLot(lot);

          if (buyback.amountWanted === 0 || buyback.amountWanted === null) {
            buyback.amountWanted = obj.__item__.originalAmount;
          }

          obj.__item__.amount = lot.amount;
          obj.__item__.amountInBox = lot.amountInBox;
          obj.__item__.lotId = lot.id;
          obj.__item__.buybackOnLotId = buyback.buybackOnLotId;
          obj.__item__.buyerId = buyback.buyerId;
          obj.__item__.amountWanted = buyback.amountWanted;
          obj.__item__.subNumber = buyback.subNumber;
          obj.__item__.subbuyerId = buyback.subbuyerId;
          obj.__item__.subbuyers = this.parseBuyer(buyback.buyerId);
          obj.__item__.canRemove = true;
          obj.__item__.state = 'saved';

          this.data.push(obj);

        });

      }
    });

    this.data.sort((a, b) => {
      return a.__item__.id - b.__item__.id;
    });

    this.generateTable();
  }

  generateTable = () => {
    const gridCurrentColums = this.dataGridInstance.getVisibleColumns();
    const items = [];
    const columnsArray = [];

    this.columns.forEach((row: any, i: number) => {
      let columnName = this.getTranslation(row.name);
      if (columnsArray.includes(columnName)) {
        let j = 0;
        do {
          columnName = this.getTranslation(row.name) + j;
          j++;
        } while (columnsArray.includes(columnName));
      }
      columnsArray.push(columnName);
      const isColumn = gridCurrentColums.find(c => c.dataField === columnName);
      if (!isColumn) {
        this.dataGridInstance.addColumn({
          dataField: columnName,
          caption: this.getTranslation(row.name),
          visibleIndex: i,
          allowEditing: false,
          encodeHtml: false,
          editorOptions: {
            showClearButton: true
          },
          cellTemplate: this.assignDefaultCellTemplate(row.propertyTypeId)
        });
      }
    });

    this.data.forEach(row => {
      let item = {};
      columnsArray.forEach((column, i) => {
        if (!row.__item__.canRemove) {
          item[column] = this.getTranslation(row['key' + i]);
        } else {
          item[column] = '';
        }
      });

      item['__item__'] = row.__item__;

      items.push(item);
    });

    this.dataGridInstance.option('dataSource', items);
  }

  generateTemporaryRow = (newRow: boolean, rowIndex: number, lot: any) => {

    const gridCurrentColums = this.dataGridInstance.getVisibleColumns();
    const items = [];
    const columnsArray = [];

    this.validateBuybackAmounts(lot);

    this.columns.forEach((row: any, i: number) => {
      let columnName = this.getTranslation(row.name);
      if (columnsArray.includes(columnName)) {
        let j = 0;
        do {
          columnName = this.getTranslation(row.name) + j;
          j++;
        } while (columnsArray.includes(columnName));
      }
      columnsArray.push(columnName);
      const isColumn = gridCurrentColums.find(c => c.dataField === columnName);
      if (!isColumn) {
        this.dataGridInstance.addColumn({
          dataField: columnName,
          caption: this.getTranslation(row.name),
          visibleIndex: i,
          allowEditing: false,
          encodeHtml: false,
          editorOptions: {
            showClearButton: true
          },
          cellTemplate: 'cellTemplate'
        });
      }
    });

    if (newRow) {
      let obj = this.parseLot(this.data[rowIndex].__item__);

      obj.__item__.lotId = obj.__item__.id;
      obj.__item__.subNumber = null;
      obj.__item__.subbuyerId = null;
      obj.__item__.subbuyers = Array<BuyerLevelSubbuyer>();
      obj.__item__.canRemove = true;
      obj.__item__.state = 'new';
      obj.__item__.amountWanted = 0;//lot.amount - this.calculateSumOfBuybackAmounts(lot);

      this.data.splice(rowIndex + 1, 0, obj);
      
      if (obj.__item__.amountWanted > 0) {
        this.data.splice(rowIndex + 1, 0, obj);
      }
    }

    this.data.forEach(row => {
      let item = {};
      columnsArray.forEach((column, i) => {
        if (!row.__item__.canRemove) {
          item[column] = this.getTranslation(row['key' + i]);
        } else {
          item[column] = '';
        }
      });

      item['__item__'] = row.__item__;

      items.push(item);
    });

    this.dataGridInstance.option('dataSource', items);

  }

  parseLot(lot: GenericLotRow): any {
    const obj = new Object();
    this.columns.forEach((column, i) => {

      if (column.propertyTypeId === ProductPropertyTypes.DATE) {
        obj['key' + i] = this.datetime.getDateStringByFormatAnyUtc(lot.values[i], column.propertyTypeFormatId);
      }
      else if (column.propertyTypeId === ProductPropertyTypes.TEXT
        || column.propertyTypeId === ProductPropertyTypes.BOOLEAN
        || column.propertyTypeId === ProductPropertyTypes.IMAGE
        || column.propertyTypeId === ProductPropertyTypes.MASTER_DATA_VALUE) {
        // Add line breaks for string value
        let objValue = this.getTranslation(lot.values[i]);
        objValue = this.addLineBreaks(objValue);
        obj['key' + i] = objValue;
      } else if (isNaN(Number(lot.values[i]))) {
        let objValue = this.getTranslation(lot.values[i]);
        objValue = this.addLineBreaks(objValue);
        obj['key' + i] = objValue;
      } else {
        obj['key' + i] = Number(lot.values[i]);
      }
    });
    obj['__item__'] = JSON.parse(JSON.stringify(lot));

    return obj;
  }

  subBuyerName = (e: any) => {
    if (e !== null) {
      return e.subNumber + ' - ' + e.name;
    }
  }

  buyerName = (e: any) => {
    if (e !== null) {
      return e.name;
    }
  }

  private parseObject(rows: Array<GenericLotRow>) {
    this.data = [];

    // Ignore parsing if there are no columns defined
    if (this.columns.length === 0) {
      return;
    }

    rows.forEach(row => {
      const obj = new Object();
      this.columns.forEach((column, i) => {
        if (column.propertyTypeId === ProductPropertyTypes.DATE) {
          obj['key' + i] = this.addLineBreaks(row.values[i], column.propertyTypeId, column.propertyTypeFormatId);
        }
        else if (column.propertyTypeId === ProductPropertyTypes.TEXT
          || column.propertyTypeId === ProductPropertyTypes.BOOLEAN
          || column.propertyTypeId === ProductPropertyTypes.IMAGE
          || column.propertyTypeId === ProductPropertyTypes.MASTER_DATA_VALUE
          || isNaN(Number(row.values[i]))) {
          // Add line breaks for string value
          let objValue = this.getTranslation(row.values[i]);
          objValue = this.addLineBreaks(objValue, column.propertyTypeId);
          obj['key' + i] = objValue;
        } else {
          obj['key' + i] = row.values[i];
        }
      });
      obj['__item__'] = row;

      this.data.push(obj);
    });

    // Reset default page size
    this.setPageSize(this.pageSizeValue);
  }

  private parseColumns(columns: Array<GenericLotColumn>) {
    const _columns = [];
    columns.forEach(column => {
      _columns.push({
        name: this.getTranslation(column.name),
        propertyTypeId: column.propertyTypeId,
        propertyTypeFormatId: column.propertyTypeFormatId
      });
    });

    const _objectKeys = [];
    _columns.forEach((column, i) => {
      _objectKeys.push('key' + i);
    });

    this.objectKeys = _objectKeys;
    this.columns = _columns;

  }

  initColumn = (e: any) => {
    this.dataGridInstance = e.component;
  }

  getDataType(fieldType: number): string {
    switch (fieldType) {
      case ProductPropertyTypes.NUMBER: {
        return 'number';
      }
      case ProductPropertyTypes.DECIMAL: {
        return 'number';
      }
      case ProductPropertyTypes.TEXT: {
        return 'string';
      }
      case ProductPropertyTypes.DATE: {
        return 'date';
      }
      case ProductPropertyTypes.BOOLEAN: {
        return 'boolean';
      }
      case ProductPropertyTypes.COLOR: {
        return 'string';
      }
      default: break;
    }
    return
  }


  save() {

    let isValid = true;

    this.data.forEach(row => {

      if (row.__item__.canRemove && (row.__item__.amountWanted === null || row.__item__.amountWanted === undefined || row.__item__.amountWanted === 0)){
        notify(this.errorService.translations.INVALID_AMOUNT, 'error', 5000);
        isValid = false;
        return;
      };

      if (row.__item__.canRemove && (row.__item__.buyerId === null || row.__item__.buyerId === undefined ||
        row.__item__.subbuyerId === undefined || row.__item__.subbuyerId === null)){
        notify(this.errorService.translations.INVALID_BUYER, 'error', 5000);
        isValid = false;
        return;
      };

    });

    const sources: Array<any> = [];

    if (isValid) {
      this.data.forEach(row => {

        if (row.__item__.state === 'new') {
          let subb = row.__item__.subbuyers.find(sb => sb.buyerId === row.__item__.subbuyerId);

          let b = new BuybackOnLot;
          b.lotId = row.__item__.lotId;
          b.buyerId = row.__item__.buyerId;
          b.subbuyerId = row.__item__.subbuyerId;
          b.amountWanted = row.__item__.amountWanted;
          b.subNumber = subb.subNumber

          sources.push(this.supplyMonitorService.insertBuyback(b, this.auctionClusterId, this.catalogId));
        }

        if (row.__item__.state === 'edited') {
          let subb = row.__item__.subbuyers.find(sb => sb.buyerId === row.__item__.subbuyerId);

          let b = new BuybackOnLot;
          b.lotId = row.__item__.lotId;
          b.buyerId = row.__item__.buyerId;
          b.subbuyerId = row.__item__.subbuyerId;
          b.amountWanted = row.__item__.amountWanted;
          b.buybackOnLotId = row.__item__.buybackOnLotId;
          b.subNumber = subb.subNumber;

          sources.push(this.supplyMonitorService.editBuyback(b, this.auctionClusterId, this.catalogId));

        }
      })

      if(sources.length > 0) this.spinner.show();
        else this.close(true);
        
      forkJoin(...sources).subscribe(result => {
        this.spinner.hide();
        this.close(true);
      },
        error => {
          notify(error.message, 'error', 5000);
          this.spinner.hide();
        });

    }
  }

  public addBuybackValue(lotId: number, ev: any) {

    let rowIndex = ev.rowIndex;
    let lot = ev.data.__item__;

    this.generateTemporaryRow(true, rowIndex, lot);
  }

  public buybackAmountChanged(lot: any, ev: any){

    let item = this.data[ev.rowIndex];

    if (item.__item__.state !== 'new') {
      item.__item__.state = 'edited';
    }

    this.validateBuybackAmounts(lot);
  }

  public removeBuyback(data: any) {

    let newObj = this.data[data.rowIndex].__item__;
    this.data.splice(data.rowIndex, 1);

    this.supplyMonitorService.deleteBuyback(newObj, this.auctionClusterId, this.catalogId).subscribe(result => {
      let res = result;
    });

    this.generateTemporaryRow(false, data.rowIndex, newObj);

  }

  public buyerChanged(data: any, ev: any) {

    let item = this.data[ev.rowIndex];

    if (item.__item__.state !== 'new') {
      item.__item__.state = 'edited';
    }

    this.data[ev.rowIndex].__item__.subbuyers = [];

    if (!item.__item__.buyerId) {
      // if delete buyer also delete subbuyer
      item.__item__.subbuyerId = null
      return;
    }

    let currentSubbuyers = this.buyersSubbuyers.find(b => b.buyerId === item.__item__.buyerId).subbuyers;

    if (currentSubbuyers.length === 0){

      let defaultSubBuyer = new BuyerLevelSubbuyer();
      defaultSubBuyer.buyerId = item.__item__.buyerId;
      defaultSubBuyer.name = this.buyersSubbuyers.find(b => b.buyerId === item.__item__.buyerId).name;
      defaultSubBuyer.subNumber = 0;

      this.data[ev.rowIndex].__item__.subbuyers.push(defaultSubBuyer);
      this.data[ev.rowIndex].__item__.subbuyerId = item.__item__.buyerId;

    }
    else {
      currentSubbuyers.sort((a,b) => a.subNumber - b.subNumber);

      this.data[ev.rowIndex].__item__.subbuyers = currentSubbuyers;
      this.data[ev.rowIndex].__item__.subbuyerId = currentSubbuyers[0].buyerId;
    }
  }

  public subbuyerChanged(data: any, ev: any) {

    let item = this.data[ev.rowIndex];

    if (item.__item__.state !== 'new') {
      item.__item__.state = 'edited';
    }

  }


  public parseBuyer(buyerId: number): Array<BuyerLevelSubbuyer> {

    let subbuyers = new Array<BuyerLevelSubbuyer>();

    let currentBuyer = this.buyersSubbuyers.find(bb => bb.buyerId === buyerId);

    if (currentBuyer.subbuyers.length === 0){

      let defaultSubBuyer = new BuyerLevelSubbuyer();
      defaultSubBuyer.buyerId = currentBuyer.buyerId;
      defaultSubBuyer.name = this.buyersSubbuyers.find(b => b.buyerId === b.buyerId).name;
      defaultSubBuyer.subNumber = 0;

      subbuyers.push(defaultSubBuyer);

    } else {
      currentBuyer.subbuyers.forEach(sb => {
        subbuyers.push(sb);
      })
    }

    return subbuyers;
  }

  public insertNewBuyback(item: any){

    let newObj = new BuybackOnLot;

    if (item.buyerId && item.subbuyerId) {

      let subb = item.subbuyers.find(sb => sb.buyerId === item.buyerId);

      newObj.lotId = item.lotId;
      newObj.buyerId = item.buyerId;
      newObj.subbuyerId = item.subbuyerId;
      newObj.amountWanted = 0;//item.amountWanted;
      newObj.subNumber = subb.subNumber
    }

    if (newObj) {
      this.supplyMonitorService.insertBuyback(newObj, this.auctionClusterId, this.catalogId).subscribe(result => {
        let res = result;
      },
      error => {
        notify(error.message, 'error', 5000);
        this.spinner.hide();
      });
    }
  }

  private calculateSumOfBuybackAmounts(lot: any) {
    let buybackAmountSum = 0;

    this.data.forEach(item => {
      if (item.__item__.id === lot.id && item.__item__.amountWanted && item.__item__.amountWanted > 0) {
        buybackAmountSum += item.__item__.amountWanted;
      }
    });

    return buybackAmountSum;
  }

  private validateBuybackAmounts(lot: any) {

    //const buybackAmount = lot.amount;
    //const buybackAmountSum = this.calculateSumOfBuybackAmounts(lot);

    // error for undefined or null values
    if (this.data.find(item => item.__item__.canRemove &&
      (item.__item__.amountWanted === null || item.__item__.amountWanted === undefined))) {
        notify(this.errorService.translations.INVALID_AMOUNT, 'error', 2000);
        this.saveDisabled = true;
        return;
    }
    // error for values equal or less then zero
    else if (this.data.find(item => item.__item__.canRemove && item.__item__.amountWanted <= 0)) {
        notify(this.errorService.translations.INVALID_AMOUNT, 'error', 2000);
        this.saveDisabled = true;
        return;
    }
    // error when buyback sum is larger than buyback amount
    /*else if (buybackAmountSum > buybackAmount) {
        notify(this.errorService.translations.INVALID_AMOUNT, 'error', 2000);
        this.saveDisabled = true;
        return;
    }*/

    this.saveDisabled = false;
    return;
  }

  
  assignDefaultCellTemplate(propertyTypeId: ProductPropertyTypes) {
    if (propertyTypeId === ProductPropertyTypes.TEXT) {
      return 'textCellTemplate';
    } else {
      return 'cellTemplate';
    }
  }

}
