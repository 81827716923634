import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { forkJoin } from 'rxjs';

// general services
import { LanguageService } from '../../shared/services/language.service';


// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { BuyerPrebidComponent } from './buyer-prebid.component';

// services
import { PrebidOnProductService } from '../shared/services/prebid-on-product.service';

// models
import { Language } from '../../shared/models/language';
import { PrebidOnProduct } from '../shared/models/prebid';
import { ImportSchema, ImportField } from '../../shared/import/import-schema';

@Component({
  selector: 'buyer-prebids-component',
  templateUrl: 'buyer-prebids.component.html',
  styleUrls: ['./buyer-prebids.component.scss']
})
export class BuyerPrebidsComponent extends FullListComponent<PrebidOnProduct, BuyerPrebidComponent> implements OnInit {

  @ViewChild('details') detailsComponent: BuyerPrebidComponent;

  languages: Array<Language> = [];

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private dataService: PrebidOnProductService
  ) {
    super(injector, PrebidOnProduct);
    this.title.set('PREBID_ON_PRODUCT.TITLE');
  }

  ngOnInit() {
    this.setTranslations('PREBID_ON_PRODUCT');
  }

  getData() {
    this.spinner.show();
    forkJoin(
      this.dataService.getPrebids(this.id),
      this.languageService.getLanguages()
    ).subscribe(result => {
      this.items = result[0];
      this.languages = result[1];
      this.translatePrebids();
      this.spinner.hide();
    },
      error => {
        this.errorService.show(error);
        this.spinner.hide();
      });
  }

  edit(catalogId: number, event: Event) {
    event.stopPropagation();

    this.detailsComponent.modalTitle = this.translations.EDIT;
    this.detailsComponent.open(this.items, catalogId, this.languages, );
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.ADD_NEW;
    this.detailsComponent.open(this.items, null, this.languages);
  }

  translatePrebids() {
    this.items.forEach(pb => {
      pb.prebidNameText = this.languageService.getTranslatableText(pb.name);
      pb.prebidDescriptionText = this.languageService.getTranslatableText(pb.description);
    });
  }

  openPrebidEditor(catalogId: number, event: Event) {
    event.stopPropagation();
    this.router.navigate(['/buyer/prebids/' + this.id + '/lots/' + catalogId]);
  }

  deleteSelected() {
    this.spinner.show();
    this.dataService.delete(this.itemIdToDelete, this.id)
      .subscribe((prebids: Array<PrebidOnProduct>) => {
        this.getData();
        this.spinner.hide();
      },
      error => {
        this.errorService.show(this.errorService.translations.DELETE_ERROR_MESSAGE);
        this.spinner.hide();
      });
  }

  createDataSchema() {

    const schema = new ImportSchema();

    const prebidName = new ImportField('prebidNameText', this.translations.NAME);
    prebidName.required = true;

    const prebidDescription = new ImportField('prebidDescriptionText', this.translations.DESCRIPTION);
    prebidDescription.required = true;

    schema.fields.push(prebidName, prebidDescription
    );

    this.schema = schema;
  }
}
