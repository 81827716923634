import { Component, OnInit, Injector, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Moment } from 'moment';
import * as _moment from 'moment';
import { map, Subscription } from 'rxjs';
import notify from 'devextreme/ui/notify';
import DataGrid from 'devextreme/ui/data_grid';

// general services
import { DateTimeService } from '../../shared/services/datetime.service';

// services
import { AuctionSupplyMonitorService } from '../shared/services/auction-supply-monitor.service';
import { TitleService } from '../../shared/services/title.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../shared/services/auth.service';
import { AuctionClusterReportService } from '../shared/services/report.service';
import { ProductService } from '../shared/services/product.service';
import { LookupTableService } from '../shared/services/lookup-table.service';
import { AuctionClusterBuyerService } from '../shared/services/auction-cluster-buyer.service';

// models
import { ImportSchema, ImportField } from '../../shared/import/import-schema';
import { GenericLotRow, GenericLotColumn } from '../../shared/models/generic-lot';
import { AuctionCluster } from '../../platform/shared/models/auction-cluster';
import { Catalog } from '../../auction/shared/models/catalog';
import { Clock } from '../../shared/models/clock';
import { ClrDatagridSortOrder } from '@clr/angular';
import { ItemListComponent } from '../../shared/components/item-list/item-list.component';
import { AuctionClusterPermissionEnum } from '../../shared/models/user-permissions';
import { ExportConstants } from '../../shared/constants/export.constants';
import { ProductPropertyTypes, Product, UrlTypes, DateTypes} from '../shared/models/product';
import { Auction } from '../shared/models/auction';
import { LookupTable } from '../shared/models/lookup-table';
import { ReportFilterModel } from '../shared/models/report-filter-model';
import { MasterData } from '../shared/models/master-data';
import { MasterDataService } from '../shared/services/master-data.service';
import { forkJoin } from 'rxjs';
import { DateRanges, ReportLevels, Report, SystemReportDesignTypes } from '../../shared/models/report';
import { Lot, LotProperty } from '../../auction/shared/models/lot';
import { LookupTableField } from '../shared/models/lookup-table';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { BuybackOnLotComponent } from '../shared/components/buyback-on-lot.component';
import { AuctionClusterBuyer, BuyerWithSubbuyers, SubBuyer } from '../shared/models/auction-cluster-buyer';
import { LotService } from '../shared/services/lot.service';

const moment = (_moment as any).default ? (_moment as any).default : _moment;
const NOON = 12;

@Component({
  selector: 'auction-supply-monitor-component',
  templateUrl: 'auction-supply-monitor.component.html',
  styleUrls: ['./auction-supply-monitor.component.scss']
})
export class AuctionSupplyMonitorComponent extends FullListComponent<GenericLotRow, BuybackOnLotComponent> implements OnInit, OnDestroy {

  @ViewChild('detailsForm') detailsForm: any;
  @ViewChild('details') detailsComponent: BuybackOnLotComponent;

  auctionClusterId: number = null;
  auctionClusters: Array<AuctionCluster> = [];
  catalogId: number = null;
  catalogs: Array<Catalog> = [];
  clockId = 0;
  clocks: Array<Clock> = [];
  date: Date = new Date();
  columns: Array<any> = [];
  data: Array<any> = new Array<any>();
  objectKeys: Array<string> = new Array<string>();
  items = [];
  filterPrebids = false;
  filterMarked = false;
  lastRefresh = '';
  genericFilters = '';
  buyers: Array<AuctionClusterBuyer> = [];
  buyersSubbuyers: Array<BuyerWithSubbuyers> = [];
  hasBuybackProperty: boolean = false;

  productId: number;
  reportId: number;
  report: Report = new Report();
  allReports: Array<Report> = [];
  product: Product;

  masterDataDropdowns: Array<any> = [];
  masterDataDropdownsFiltered: Array<any> = [];
  dropdownsData: Array<any> = [];
  comboBoxes: Array<any> = [];
  lookupTables: Array<LookupTable> = [];

  auctionId: number;

  reportName: string;

  filters;
  filterBindings: ReportFilterModel;
  dateFiltersCascadingDefinitions;

  newReportName: string;
  isSaveProfileDialogOpened: boolean;
  isLoadProfileDialogOpened: boolean;

  exportConstants: ExportConstants = new ExportConstants();
  exportUrl: SafeUrl;
  exportFilename: string;
  @ViewChild('exportLink') exportLink: ElementRef;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;
  dateFilterItems = [];
  dataGridInstance: DataGrid;
  gridColumns = [];

  popUpShowed: boolean = false;
  popUpOpened: boolean = false;
  carrouselLoaded: boolean = false;
  carrouselData: Array<any> = [];

  constructor(
    protected injector: Injector,
    private supplyMonitorService: AuctionSupplyMonitorService,
    private reportService: AuctionClusterReportService,
    private masterDataService: MasterDataService,
    private productService: ProductService,
    private sanitizer: DomSanitizer,
    private titleService: TitleService,
    private lookupTableService: LookupTableService,
    private translateService: TranslateService,
    private authenticationService: AuthService,
    private auctionClusterBuyerService: AuctionClusterBuyerService
  ) {
    super(injector, GenericLotRow);
    this.title.set('AUCTION.SUPPLY_MONITOR.TITLE');
    this._subscription = this.language.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
    this.translate.get(['TODAY', 'YESTERDAY', 'LAST_WEEK', 'RANGE']).subscribe((res: string) => {
      this.dateFilterItems = [
        {
          value: 0,
          name: res['TODAY']
        },
        {
          value: 1,
          name: res['YESTERDAY']
        },
        {
          value: 2,
          name: res['LAST_WEEK']
        },
        {
          value: 3,
          name: res['RANGE']
        }
      ];
    });
  }

  ngOnInit() {
    this.setTranslations('AUCTION.SUPPLY_MONITOR');

    this.auctionClusterId = this.id;
    this.filterCatalogs();
    this.filterClocks();
    this.isSaveProfileDialogOpened = false;
    this.getBuyersAndSubbuyers();

    this.filters = [];
    this.filterBindings = new ReportFilterModel();
    this.dateFiltersCascadingDefinitions = [];

    this.reportName = '';
    this.auctionId = null;

    this.getReportTemplates();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getReportTemplates() {
    this.reportService.getReportsBySystemReportDesignType(this.auctionClusterId, SystemReportDesignTypes.AuctionClusterSupplyMonitorFilter).subscribe(result => {
      this.allReports = result;

      if (this.allReports.length !== 0) {
        let report = this.allReports.find(_ => _.systemDefault);
        if (report) {
          this.applyReportFilters(report);
        }
        else {
          this.getDefaultReport();
        }
      }
      else {
        this.getDefaultReport();
      }
    }, error => {
      notify(error.message, 'error', 5000);
      this.getDefaultReport();
    });
  }

  getDefaultReport() {
    this.reportService.getReportBySystemReportDesignType(this.auctionClusterId, SystemReportDesignTypes.AuctionClusterSupplyMonitorFilter)
      .subscribe(result => {
        this.applyReportFilters(result);
      });
  }

  applyReportFilters(report: Report) {
    this.filters = [];

    if (!report)
      return;

    this.report = report;
    this.reportId = report.reportId;
    this.reportName = this.report.description;
    this.productId = this.report.reportDesign.productId;
    this.productService.getProduct(this.auctionClusterId, this.productId).subscribe(product => {
      this.product = product;
      this.getMasterDataListsData(product);
      this.lookupTableService.getLookupTables(this.auctionClusterId).subscribe(lt => {
        this.lookupTables = lt;
      });
    });
  }

  getData() {
    if (!this.auctionClusterId || !this.catalogId) {
      return;
    }
    this.filterReport();

    // Fetch lots without columns refresh
    this.fetchLots(false);
  }

  fetchLots(refreshColumns: boolean) {
    // Reset any existing filtering if column refresh is requested
    if (refreshColumns) {
      this.columns = [];
    }

    this.data = [];
    this.items = [];
    const dateFormatted = moment(this.date).local().format('YYYY-MM-DD');

    this.spinner.show();

    this.supplyMonitorService.getLots(this.auctionClusterId, this.catalogId, dateFormatted, this.clockId, this.genericFilters)
      .subscribe(result => {
        this.items = result.rows;

        if (this.items.length > 0){
          this.hasBuybackProperty = this.items[0].hasBuybackTreshold;
        }

        if (refreshColumns) {
          // Parse columns only if explicitly requested
          this.parseColumns(result.columnTitles);
        }
        this.parseObject(result.rows);
        this.spinner.hide();
        this.lastRefresh = moment().locale(this.translateService.currentLang).format('DD.MM.YYYY. HH:mm');
        this.generateTable(result);
      },
        error => {
          notify(error.message, 'error', 5000);
          this.spinner.hide();
        });
  }

  private parseObject(rows: Array<GenericLotRow>) {
    this.data = [];

    // Ignore parsing if there are no columns defined
    if (this.columns.length === 0) {
      return;
    }

    rows.forEach(row => {
      const obj = new Object();
      this.columns.forEach((column, i) => {
        if (column.propertyTypeId === ProductPropertyTypes.DATE) {
          if(column.propertyTypeFormatId == DateTypes.SHORT_DATE)
          {
            let date = new Date(row.values[i]);
            date.setHours(12);
            date.setMinutes(0);
            obj['key' + i] = (date).toISOString();
          }
          else
          {
            obj['key' + i] = this.addLineBreaks(row.values[i], column.propertyTypeId, column.propertyTypeFormatId);
          }
          
        }  else if (column.propertyTypeId === ProductPropertyTypes.URL) {
          obj['key' + i] = row.values[i];
        }
        else if (column.propertyTypeId === ProductPropertyTypes.TEXT
          || column.propertyTypeId === ProductPropertyTypes.BOOLEAN
          || column.propertyTypeId === ProductPropertyTypes.IMAGE
          || column.propertyTypeId === ProductPropertyTypes.MASTER_DATA_VALUE
          || isNaN(Number(row.values[i]))) {
          // Add line breaks for string value
          let objValue = this.getTranslation(row.values[i]);
          objValue = this.addLineBreaks(objValue, column.propertyTypeId);
          obj['key' + i] = objValue;
        } else {
          obj['key' + i] = row.values[i]; //this.format(Number(row.values[i]), column.propertyTypeId);
        }
      });
      obj['__item__'] = row;

      this.data.push(obj);
    });

    // Reset default page size
    this.setPageSize(this.pageSizeValue);
  }

  private parseColumns(columns: Array<GenericLotColumn>) {
    const _columns = [];
    columns.forEach(column => {
      _columns.push({
        name: this.getTranslation(column.name),
        propertyTypeId: column.propertyTypeId,
        propertyTypeFormatId: column.propertyTypeFormatId
      });
    });

    const _objectKeys = [];
    _columns.forEach((column, i) => {
      _objectKeys.push('key' + i);
    });

    this.objectKeys = _objectKeys;
    this.columns = _columns;
    this.createDataSchema();
  }

  filterLots() {
    // Fetch data and force columns refresh
    this.filterReport();
    this.fetchLots(true);
  }

  filterCatalogs() {
    if (!this.auctionClusterId) {
      this.catalogs = [];
      this.catalogId = null;
      return;
    }

    this.supplyMonitorService.getCatalogsForPermissions(this.auctionClusterId, [AuctionClusterPermissionEnum.SupplyMonitor])
      .subscribe(result => {
        this.catalogs = result;
        if (this.catalogs.length !== 0) {
          this.catalogId = this.catalogs[0].catalogId;
          this.onCatalogChange();
        }
      });
  }

  filterClocks() {
    if (!this.auctionClusterId || !this.catalogId) {
      this.clocks = [];
      this.clockId = 0;
      return;
    }

    this.supplyMonitorService.getClocksByCatalog(this.auctionClusterId, this.catalogId, [AuctionClusterPermissionEnum.SupplyMonitor])
      .subscribe(result => {
        this.clocks = result;
        this.clockId = 0;
      });
  }

  onCatalogChange() {
    if (!this.auctionClusterId) {
      this.clocks = [];
      this.clockId = null;
      return;
    }

    if (!this.catalogId) {
      this.filterClocks();
    }

    this.supplyMonitorService.getClocksByCatalog(this.auctionClusterId, this.catalogId, [AuctionClusterPermissionEnum.SupplyMonitor])
      .subscribe(result => {
        this.clocks = result;
        this.clockId = 0;
      });
  }

  dateChanged(dateTime: Moment) {
    this.date = moment(dateTime).toDate();
  }

  createDataSchema() {
    const schema = new ImportSchema();

    this.columns.forEach((column, i) => {
      const field = new ImportField(this.objectKeys[i], column.name);
      field.required = true;
      schema.fields.push(field);
    });

    this.schema = schema;
  }

  getMasterDataListsData(product: Product) {
    const masterDataLists: Array<MasterData> = new Array<MasterData>();

    const masterDataListIds: Array<number> = [];
    const reportProperties = product.reportProperties.filter(x => x.propertyTypeId = this.reportId);

    reportProperties.forEach(reportProperty => {
      const productProperty = product.productProperties.filter(x => x.productPropertyId === reportProperty.productPropertyId)[0];
      const productPropertyTypeId = productProperty.propertyTypeId;
      const productPropertyMasterDataListId = productProperty.masterDataListId;
      // We show only date and master data property types.
      if (productPropertyTypeId === ProductPropertyTypes.DATE || productPropertyTypeId === ProductPropertyTypes.MASTER_DATA_VALUE) {
        if (productPropertyMasterDataListId !== null) {
          if (masterDataListIds.indexOf(productPropertyMasterDataListId) === -1) {
            masterDataListIds.push(productPropertyMasterDataListId);
          }
        }
      }
    });

    const observables = [];

    masterDataListIds.forEach(id => {
      observables.push(this.masterDataService.getMasterDataList(id, this.auctionClusterId).pipe(map((result: MasterData) => result)));
    });

    if (observables.length > 0) {
      forkJoin(
        observables
      ).subscribe((lists: Array<MasterData>) => {
        lists.forEach((result: MasterData) => {
          masterDataLists.push(result);
        });
        this.initReportData(this.auctionClusterId, product, lists);
      });
    } else {
      this.initReportData(this.auctionClusterId, product, new Array<MasterData>());
    }
  }

  initReportData(auctionClusterId: number, product: Product, masterDataLists: Array<MasterData>) {
    this.masterDataDropdowns.length = 0;
    product.reportProperties.filter(x => x.reportDesignId === this.report.reportDesignId)
      .sort((s, z) => s.orderNumber.toString().localeCompare(z.orderNumber.toString()))
      .forEach(reportProperty => {
        const productProperty = product.productProperties.filter(x => x.productPropertyId === reportProperty.productPropertyId)[0];
        const productPropertyId = productProperty.productPropertyId;
        const productPropertyTypeId = productProperty.propertyTypeId;
        const productPropertyMasterDataListId = productProperty.masterDataListId;
        const reportPropertyMasterDataListFieldId = reportProperty.masterDataListFieldId;

        const filter = {
          name: reportProperty.name !== null ? reportProperty.name : productProperty.name,
          sysName: productProperty.sysName,
          propertyTypeId: productPropertyTypeId,
          productPropertyId,
          masterDataListId: productPropertyMasterDataListId,
          masterDataListFieldId: reportPropertyMasterDataListFieldId,
          options: []
        };

        const masterData = masterDataLists.filter(x => x.masterDataListId === productPropertyMasterDataListId
          && x.auctionClusterId === auctionClusterId)[0];

        // We show only date and master data property types.
        if (productPropertyTypeId === ProductPropertyTypes.DATE || productPropertyTypeId === ProductPropertyTypes.MASTER_DATA_VALUE) {
          if (productPropertyMasterDataListId !== null) {
            masterData.rows.forEach(row => {
              let rowValueText = '';
              row.values.filter(x => x.masterDataListFieldId === reportPropertyMasterDataListFieldId).forEach(rowValue => {
                if (rowValue.stringValue !== undefined) {
                  rowValueText += rowValue.stringValue;
                }
                if (rowValue.intValue !== undefined) {
                  rowValueText += rowValue.intValue;
                }
              });

              filter.options.push({
                'label': rowValueText,
                'value': row.masterDataListRowId
              });
            });
          }

          this.masterDataDropdowns.push({ productPropertyId: reportProperty.productPropertyId, values: filter.options });

          this.filters.push(filter);

          this.filterBindings[filter.productPropertyId] = this.filterBindings[filter.productPropertyId] !== undefined
            ? this.filterBindings[filter.productPropertyId]
            : null;

          if (productPropertyTypeId === ProductPropertyTypes.MASTER_DATA_VALUE && reportProperty.defaultMasterDataListRowId !== null) {
            this.filterBindings[filter.productPropertyId] = reportProperty.defaultMasterDataListRowId;
          }

          this.dateFiltersCascadingDefinitions[filter.productPropertyId] =
            this.dateFiltersCascadingDefinitions[filter.productPropertyId] !== null ?
              this.dateFiltersCascadingDefinitions[filter.productPropertyId] : null;

          if (productPropertyTypeId === ProductPropertyTypes.DATE) {
            switch (reportProperty.dateType) {
              case DateRanges.RANGE: {
                this.dateFiltersCascadingDefinitions[filter.productPropertyId] = DateRanges.RANGE.toString();
                this.filterBindings[filter.productPropertyId] = [
                  moment.utc(reportProperty.defaultFromDate),
                  moment.utc(reportProperty.defaultTillDate)
                ];
                break;
              }
              case DateRanges.TODAY: {
                this.dateFiltersCascadingDefinitions[filter.productPropertyId] = DateRanges.TODAY.toString();
                this.filterBindings[filter.productPropertyId] = moment.utc().hours(NOON).format();
                break;
              }
              case DateRanges.YESTERDAY: {
                this.dateFiltersCascadingDefinitions[filter.productPropertyId] = DateRanges.YESTERDAY.toString();
                this.filterBindings[filter.productPropertyId] = moment.utc().subtract(1, 'days').hours(NOON).format();
                break;
              }
              case DateRanges.LAST_WEEK: {
                const dateFrom = moment.utc().subtract(1, 'weeks').hours(NOON);
                const dateTo = moment.utc().hours(NOON);
                this.dateFiltersCascadingDefinitions[filter.productPropertyId] = DateRanges.LAST_WEEK.toString();
                this.filterBindings[filter.productPropertyId] = [dateFrom, dateTo];
                break;
              }
              default:
                break;
            }
          }
        }
      });

    this.masterDataDropdownsFiltered = JSON.parse(JSON.stringify(this.masterDataDropdowns));
    this.dropdownsData = JSON.parse(JSON.stringify(this.masterDataDropdowns));

    this.parseReportFilterData();
    this.setComboBoxes();
  }

  parseReportFilterData() {
    if (!this.report.data) {
      return;
    }

    const filterData = JSON.parse(this.report.data);

    filterData.forEach(filter => {
      if (filter.productPropertyTypeId === ProductPropertyTypes.MASTER_DATA_VALUE) {
        this.filterBindings[filter.productPropertyId] = filter.value;
      } else if (filter.productPropertyTypeId === ProductPropertyTypes.DATE) {
        if (filter.dateRangeType !== null) {
          this.dateFiltersCascadingDefinitions[filter.productPropertyId] = filter.dateRangeType.toString();
          if (filter.dateRangeType === DateRanges.RANGE) {
            this.filterBindings[filter.productPropertyId] = [
              moment.utc(filter.value.split('|')[0]),
              moment.utc(filter.value.split('|')[1]),
            ];
          }
        } else {
          this.filterBindings[filter.productPropertyId] = moment.utc(filter.value);
        }
      }
    });
  }

  getFilterForServer() {
    const filterForServer: Array<ReportFilterModel> = [];

    for (const prop of Object.keys(this.filterBindings)) {
      let filterValue = null;
      let filterType = null;

      const filter = this.filters.filter(x => x.productPropertyId === +prop)[0];

      filterType = filter.propertyTypeId;

      let dateFilter = null;

      if (filter.propertyTypeId === ProductPropertyTypes.DATE) {
        dateFilter = this.dateFiltersCascadingDefinitions[prop] ? +this.dateFiltersCascadingDefinitions[prop] : null;
        if (dateFilter === DateRanges.TODAY) {
          filterValue = moment.utc().hours(NOON).format();
        }
        if (dateFilter === DateRanges.YESTERDAY) {
          filterValue = moment.utc().subtract(1, 'days').hours(NOON).format();
        }
        if (dateFilter === DateRanges.LAST_WEEK) {
          const dateFrom = moment.utc().subtract(1, 'weeks').hours(NOON);
          const dateTo = moment.utc().hours(NOON);
          filterValue = dateFrom.format() + '|' + dateTo.format();
        }
        if (dateFilter === DateRanges.RANGE) {
          const dateFrom = this.filterBindings[prop][0];
          const dateTo = this.filterBindings[prop][1];
          filterValue = moment(dateFrom).hours(NOON).format() + '|' + moment(dateTo).hours(NOON).format();
        }
      } else {
        filterValue = this.filterBindings[prop];
      }

      filterForServer.push({
        productPropertyTypeId: +filterType,
        productPropertyId: +prop,
        dateRangeType: dateFilter,
        key: filter.sysName,
        value: filterValue
      });
    }

    return filterForServer;
  }

  filterReport() {
    const filterForServer = this.getFilterForServer();

    const reportRequestModel = {
      LanguageCode: this.translateService.currentLang,
      TimeZoneOffset: new Date().getTimezoneOffset(),
      UserId: this.authenticationService.currentUserId,
      Filters: filterForServer
    };
    this.genericFilters = btoa(JSON.stringify(reportRequestModel));
  }

  openSaveAsNewUserReportTemplateDialog() {
    // this.detailsForm.reset();
    this.isSaveProfileDialogOpened = true;
  }

  closeSaveAsNewUserReportTemplateDialog() {
    this.isSaveProfileDialogOpened = false;
  }

  save() {
    this.report.data = JSON.stringify(this.getFilterForServer());
    this.spinner.show();
    this.reportService.edit(this.auctionClusterId, this.report).subscribe(report => {
      this.spinner.hide();
    },
      error => {
        this.spinner.hide();
        this.isSaveProfileDialogOpened = false;
        notify(error.message, 'error', 5000);
      });
  }

  saveNewReport() {
    const newReport: Report = JSON.parse(JSON.stringify(this.report));
    newReport.data = JSON.stringify(this.getFilterForServer());
    newReport.systemDefault = false;
    newReport.description = this.newReportName;

    this.spinner.show();
    this.reportService.save(this.auctionClusterId, newReport).subscribe(report => {
      this.spinner.hide();
      this.isSaveProfileDialogOpened = false;
      this.getReportTemplates();
    },
      error => {
        this.spinner.hide();
        this.isSaveProfileDialogOpened = false;
        notify(error.message, 'error', 5000);
      });
  }

  setLotPropertyValue(productPropertyId: number, value: any) {
    let isSame = false;
    if (this.filterBindings[productPropertyId] === value) {
      isSame = true;
    }
    this.filterBindings[productPropertyId] = value;

    return isSame;
  }

  clearLotPropertyValue(productPropertyId: number, e: any) {
    if (e.value === null) {
      this.filterBindings[productPropertyId] = null;
    }
  }

  setComboBoxes() {
    this.comboBoxes.length = 0;
    this.filters.forEach((f, i) => {
      this.comboBoxes[i] = this.getDropdownValues(f);
    });
  }

  getDropdownValues(filter: any) {
    const masterDataDropdown = this.dropdownsData.find(dd => dd.productPropertyId === filter.productPropertyId);
    if (masterDataDropdown) {
      const values = masterDataDropdown.values;

      const currentValue = values.find(v => v.value === this.filterBindings[filter.productPropertyId]);
      if (!currentValue) {
        const md = this.masterDataDropdowns.find(dd => dd.productPropertyId === filter.productPropertyId);
        if (md) {
          const row = md.values.find(v => v.value === filter.masterDataListFieldId);
          if (row) {
            values.push(row);
          }
        }
      }
      values.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }

        return 0;
      });

      if (values.length === 1) {
        const isSame = this.setLotPropertyValue(filter.productPropertyId, values[0].value);
        if (!isSame) {
          this.onPropertyChanged(filter);
        }
      }

      return values;
    }
  }

  getPropIdsForMasterDataFields(lookupTableFields: Array<LookupTableField>) {
    const ids = [];
    lookupTableFields.forEach(ltf => {
      if (ltf.isResult) {
        this.product.productProperties.forEach(pp => {
          if (pp.masterDataListId === ltf.masterDataListId) {
            ids.push(pp.productPropertyId);
          }
        });
      }
    });

    return ids;
  }

  getLookupDependentPropIds(productPropertyId: number) {
    let dependentPropIds = [];
    const productProperty = this.product.productProperties.find(f => f.productPropertyId === productPropertyId);
    if (!productProperty) {
      return dependentPropIds;
    }
    const productPropertyMasterData = productProperty.masterDataListId;
    if (!productPropertyMasterData) {
      return dependentPropIds;
    }
    for (let i = 0; i < this.lookupTables.length; i += 1) {
      if (this.lookupTables[i].lookupTableType === 1) {
        continue;
      }
      for (let j = 0; j < this.lookupTables[i].lookupTableFields.length; j += 1) {
        if (!this.lookupTables[i].lookupTableFields[j].isResult
          && this.lookupTables[i].lookupTableFields[j].masterDataListId === productPropertyMasterData) {
          const propIds = this.getPropIdsForMasterDataFields(this.lookupTables[i].lookupTableFields);
          dependentPropIds = [...dependentPropIds, ...propIds];
        }
      }
    }

    return dependentPropIds;
  }

  isProductWithLookupTable(productPropertyId: number) {
    for (let i = 0; i < this.product.productProperties.length; i += 1) {
      if (this.product.productProperties[i].productPropertyId === productPropertyId
        && this.product.productProperties[i].lookupTableId) {
        return this.product.productProperties[i].lookupTableId;
      }
    }

    return -1;
  }

  onPropertyChanged(filter: any) {
    const applyLookup = (lookup, i) => {
      if (lookup) {
        if (lookup.lookupTableType === 0 && lookup.lookupTableRows.length > 0) {
          const newValues = [];
          lookup.lookupTableRows.forEach(row => {
            row.lookupTableValues.forEach(value => {
              this.masterDataDropdowns[i].values.forEach(mdValue => {
                if (mdValue.value === value.masterDataListRowId && newValues.indexOf(mdValue) === -1) {
                  newValues.push(mdValue);
                }
              });
            });
          });
          if (newValues.length > 0) {
            this.dropdownsData.push({ productPropertyId: this.masterDataDropdowns[i].productPropertyId, values: newValues });
            this.masterDataDropdownsFiltered[i] = {
              productPropertyId: this.masterDataDropdowns[i].productPropertyId, values: newValues, filtered: true
            };
          }
          const defaultRow = lookup.lookupTableRows.find(r => r.isDefault);

          if (defaultRow) {
            defaultRow.lookupTableValues.forEach(ltv => {
              if (newValues.find(nv => nv.value === ltv.masterDataListRowId)) {
                this.setLotPropertyValue(this.masterDataDropdowns[i].productPropertyId, ltv.masterDataListRowId);
              }
            })
          }
        } else {
          if (this.masterDataDropdownsFiltered[i].filtered === true && this.filterBindings[filter.productPropertyId]) {
            this.dropdownsData.push(this.masterDataDropdownsFiltered[i]);
          } else {
            this.dropdownsData.push(this.masterDataDropdowns[i]);
          }
        }
      } else {
        if (this.masterDataDropdownsFiltered[i].filtered === true && this.filterBindings[filter.productPropertyId]) {
          this.dropdownsData.push(this.masterDataDropdownsFiltered[i]);
        } else {
          this.dropdownsData.push(this.masterDataDropdowns[i]);
        }
      }
      this.setComboBoxes();
    };

    const productPropertyIds = this.getLookupDependentPropIds(filter.productPropertyId);

    if (productPropertyIds.length === 0) {
      return;
    }

    // clear values first
    this.dropdownsData.length = 0;
    for (let i = 0; i < this.masterDataDropdowns.length; i += 1) {
      let checkThisProp = this.masterDataDropdowns[i].productPropertyId !== filter.productPropertyId;
      if (productPropertyIds.length > 0 && productPropertyIds.indexOf(this.masterDataDropdowns[i].productPropertyId) === -1) {
        checkThisProp = false;
      }
      const lookupTableId = this.isProductWithLookupTable(this.masterDataDropdowns[i].productPropertyId);
      if (checkThisProp && lookupTableId !== -1) {
        const lot = this.createLotFromBindings();
        this.lookupTableService.getLookupTableForProductProperty(this.auctionClusterId, this.masterDataDropdowns[i].productPropertyId, lot)
          .subscribe(lookup => {
            applyLookup(lookup, i);
          }, error => {
            if (this.masterDataDropdownsFiltered[i].filtered === true && this.filterBindings[filter.productPropertyId]) {
              this.dropdownsData.push(this.masterDataDropdownsFiltered[i]);
            } else {
              this.dropdownsData.push(this.masterDataDropdowns[i]);
            }
            this.setComboBoxes();
          });
      } else {
        if (this.masterDataDropdownsFiltered[i].filtered === true && this.filterBindings[filter.productPropertyId]) {
          this.dropdownsData.push(this.masterDataDropdownsFiltered[i]);
        } else {
          this.dropdownsData.push(this.masterDataDropdowns[i]);
        }
        if (productPropertyIds.length > 0) {
          this.setComboBoxes();
        }
      }
    }
  }

  createLotFromBindings() {
    const lot = new Lot();

    this.filters.forEach(f => {
      if (f.propertyTypeId === ProductPropertyTypes.MASTER_DATA_VALUE) {
        const lotProp = new LotProperty();
        lotProp.productPropertyId = f.productPropertyId;
        lotProp.masterDataListRowId = this.filterBindings[f.productPropertyId];
        lot.lotProperties.push(lotProp);
      }
    });

    return lot;
  }

  showLoadReportProfile() {
    this.isLoadProfileDialogOpened = true;
  }

  closeLoadReportProfile() {
    this.isLoadProfileDialogOpened = false;
  }

  reportTemplateChange() {
    this.isLoadProfileDialogOpened = false;

    let report = this.allReports.find(_ => _.reportId === this.reportId);
    if (report) {
      this.applyReportFilters(report);
    }
  }

  clearDatePeriod(index: number) {
    if (this.dateFiltersCascadingDefinitions[index] !== 3) {
      this.filterBindings[index] = [];
    }
  }

  setValue(index: number, e: any) {
    this.dateFiltersCascadingDefinitions[index] = e.value;
  }

  getDateValue(index: number) {
    return Number(this.dateFiltersCascadingDefinitions[index]);
  }

  handleDate1ValueChange(index: number, e: any) {
    if (e.value != null) {
      this.filterBindings[index] = [];
      this.filterBindings[index].push(moment(e.value));
    }
  }

  handleDate2ValueChange(index: number, e: any) {
    if (e.value != null) {
      this.filterBindings[index].push(moment(e.value));
    }
  }

  dateValue1(index: number) {
    if (Array.isArray(this.filterBindings[index]) && this.filterBindings[index].length > 0) {
      return moment(this.filterBindings[index][0]).toDate();
    } else {
      return null;
    }
  }

  dateValue2(index: number) {
    if (Array.isArray(this.filterBindings[index]) && this.filterBindings[index].length > 1) {
      return moment(this.filterBindings[index][1]).toDate();
    } else {
      return null;
    }
  }

  getMin(index: number) {
    if (Array.isArray(this.filterBindings[index]) && this.filterBindings[index].length > 0) {
      return moment(this.filterBindings[index][0]).toDate();
    }
  }

  isDisabled(index: number) {
    if (Array.isArray(this.filterBindings[index])) {
      if (this.filterBindings[index].length === 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  initColumn = (e: any) => {
    this.dataGridInstance = e.component;
  }

  translateSelectBoxes = (item: any) => {
    if (item) {
      const label = this.getTranslation(item.label);
      return label;
    }
  }

  prepareData(index: number) {
    return this.comboBoxes[index];
  }

  prepareValue(index: number) {
    return this.filterBindings[index];
  }

  generateTable = (rows: any) => {
    this.gridColumns = [];
    let gridCurrentColums = this.dataGridInstance.getVisibleColumns();
    rows.columnTitles.forEach((row: any, i: number) => {
      let columnName = this.getTranslation(row.name);
      if (this.gridColumns.includes(columnName)) {
        let j = 0;
        do {
          columnName = this.getTranslation(row.name) + j;
          j++;
        } while (this.gridColumns.includes(columnName));
      }
      this.gridColumns.push(columnName);
      const isColumn = gridCurrentColums.find(c => c.dataField === columnName);

      if (!isColumn) {
        this.dataGridInstance.addColumn({
          dataField: columnName,
          caption: this.getTranslation(row.name),
          visibleIndex: i,
          allowEditing: false,
          dataType: this.getDataType(row.propertyTypeId, row.propertyTypeFormatId),
          encodeHtml: false,
          cellTemplate: this.assignDefaultCellTemplate(row.propertyTypeId, row.propertyTypeFormatId),
          editorOptions: {
            showClearButton: true
          }
        });
      }
    });

    this.generateTableData();
  }


  generateTableData() {
    const gridItems = [];
    var imageColumns =  this.columns.filter(tableColumn => tableColumn.propertyTypeId === ProductPropertyTypes.URL && tableColumn.propertyTypeFormatId === UrlTypes.IMAGE_URL);

    this.data.forEach((row, i) => {
      let gridItem = '';
      this.gridColumns.forEach((column, j) => {
        if (imageColumns.length !== 0 && imageColumns.filter(_ => _.name === column).length !== 0) {
          if (row['key' + j] === "") {
            gridItem += '"' + column + '" : [],';
          } else {
            gridItem += '"' + column + '" : ' + row['key' + j] + ',';
          }          
        } else {
        gridItem += '"' + column + '" : "' + this.getTranslation(row['key' + j]) + '",';
      }
      });
      gridItem += '"__lotId__" : "' + row.__item__.id + '",';
      gridItems.push(JSON.parse('{ ' + gridItem.slice(0, -1) + '}'));
    });

    const rows = this.dataGridInstance.getDataSource();
    if (rows === null) {
      if (gridItems.length > 0) {
        this.dataGridInstance.option('dataSource', gridItems);
      }
    } else {
      this.dataGridInstance.option('dataSource', gridItems);
    }
  }

  isDisabledButton() {
    if (this.newReportName !== '') {
      return false;
    } else {
      return true;
    }
  }

  isDisabledLoadButton() {
    if (this.reportId !== null) {
      return false;
    } else {
      return true;
    }
  }

  edit(lotId: number, ev: any) {

      let lots = Array<number>();

      let group = this.data.find(r => r.__item__.id === Number(lotId));
      const lotIds = group.__item__.groupedIds;

      this.detailsComponent.modalTitle = this.translations.EDIT_BUYBACK;
      const catalog = this.catalogs.find(c => c.catalogId === this.catalogId);
      this.detailsComponent.open(lotIds, catalog.catalogId, catalog.auctionClusterId, this.buyersSubbuyers, this.date);

  }

  onDetailsComponentClosed(isDataChanged: boolean) {
    if (isDataChanged) {
      this.filterLots();
    }
  }

  getBuyersAndSubbuyers() {
    if (!this.auctionClusterId) {
      this.buyers = [];
      return;
    }

    let arr = [];

    this.auctionClusterBuyerService.getSubbuyers(arr, this.auctionClusterId)
    .subscribe(result => {
      this.buyersSubbuyers = result;
    })

  }

  doesImageCalculationExist(data: any) {
    if (data && data.find(el => el.field_0 !== '')) {
      return true;
    }
    return false;
  }

  getFirstDefinedImage(data: any) {
    let firstImageUrl = data.find(el => el.field_0 !== '').field_0;
    return firstImageUrl + '-thumbnail';
  }

  showImageCarrousel(data: any) {
    this.carrouselData = data.filter(el => el.field_0 !== '');
    this.spinner.show();
    this.popUpOpened = true;

    if (this.carrouselLoaded) {
      this.spinner.hide();
      this.popUpShowed = true;
    }
  }

  assignDefaultCellTemplate(propertyTypeId: ProductPropertyTypes, propertyTypeFormatId?: UrlTypes) {
    if (propertyTypeId === ProductPropertyTypes.URL && propertyTypeFormatId === UrlTypes.IMAGE_URL) {
      return 'imageCellTemplate';
    } else if (propertyTypeId === ProductPropertyTypes.TEXT) {
      return 'textCellTemplate';
    } else {
      return 'cellTemplate';
    }
  }

  openPopUp() {
    this.spinner.hide();
    this.popUpShowed = true;
    this.carrouselLoaded = true;
  }

  closePopUp() {
    this.popUpOpened = false;
    this.popUpShowed = false;
    this.carrouselLoaded = false;
  }

  add() { }
}

