import { Injectable } from '@angular/core';
import { Observable, empty } from 'rxjs';
import { AuctionClusterUser, AuctionClusterUserSimple } from '../models/auction-cluster-user';
import { UserFilter } from '../models/user-filter';

import { ApplicationSettings } from '../../../shared/models/application-settings';
import { WebApiService } from '../../../shared/services/web-api.service';


@Injectable()
export class AuctionClusterUserService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'auctionclusteruser';
  }

  private appendToUrl(url: string, field: string, value: any): string {

    if (url) {
      url += `&${field}=${value}`;
    } else {
      url = `?${field}=${value}`;
    }
    return url;
  }

  getUsersFiltered(auctionClusterId: number, userFilter: UserFilter): Observable<Array<AuctionClusterUser>> {

    let url: string;

    if (!userFilter) {
      return empty();
    }

    // build url on applied filter:
    if (userFilter.showAuction) {
      url = this.appendToUrl(url, 'ShowAuction', userFilter.showAuction);
      if (userFilter.auctionId || userFilter.auctionId === null) {
        url = this.appendToUrl(url, 'AuctionId', (userFilter.auctionId === null ? 'null' : userFilter.auctionId));
      }
    }
    if (userFilter.showBuyer) {
      url = this.appendToUrl(url, 'ShowBuyer', userFilter.showBuyer);
      if (userFilter.buyerId || userFilter.buyerId === null) {
        url = this.appendToUrl(url, 'BuyerId', (userFilter.buyerId === null ? 'null' : userFilter.buyerId));
      }
    }
    if (userFilter.showSupplier) {
      url = this.appendToUrl(url, 'ShowSupplier', userFilter.showSupplier);
      if (userFilter.supplierId || userFilter.supplierId === null) {
        url = this.appendToUrl(url, 'SupplierId', (userFilter.supplierId === null ? 'null' : userFilter.supplierId));
      }
    }
    if (userFilter.showActive !== null) {
      url = this.appendToUrl(url, 'ShowActive', userFilter.showActive);
    }
    if (userFilter.showInactive !== null) {
      url = this.appendToUrl(url, 'ShowInactive', userFilter.showInactive);
    }
    if (userFilter.showBlocked !== null) {
      url = this.appendToUrl(url, 'ShowBlocked', userFilter.showBlocked);
    }
    if (userFilter.showPending !== null) {
      url = this.appendToUrl(url, 'ShowPending', userFilter.showPending);
    }

    return this.webApiService.getList(this.apiPath + '/' + auctionClusterId + '/user/filter' + url);
  }

  getUser(auctionClusterId: number, userId: number): Observable<AuctionClusterUser> {
    return this.webApiService.get(this.apiPath + '/' + auctionClusterId + '/user', userId);
  }

  getDemoUsers(auctionClusterId: number): Observable<Array<AuctionClusterUser>> {
    return this.webApiService.getList(this.apiPath + '/' + auctionClusterId + '/user/demo');
  }

  getAllSimpleUsers(auctionClusterId: number): Observable<Array<AuctionClusterUserSimple>> {
    return this.webApiService.getList(this.apiPath + '/' + auctionClusterId + '/user/simple');
  }

  save(auctionClusterId: number, user: AuctionClusterUser): Observable<any> {
    return this.webApiService.save<AuctionClusterUser>(this.apiPath + '/' + auctionClusterId + '/user', user);
  }

  edit(auctionClusterId: number, user: AuctionClusterUser) {
    return this.webApiService.edit<AuctionClusterUser>(this.apiPath + '/' + auctionClusterId + '/user', user.userId, user);
  }

  enableDisable(auctionClusterId: number, user: AuctionClusterUser, overrule: boolean): Observable<AuctionClusterUser> {
    if (user.isActive) {
      return this.webApiService.enableDisable(this.apiPath + '/' + auctionClusterId + '/user/overrule/' + overrule + '/disable', user.userId);
    } else {
      return this.webApiService.enableDisable(this.apiPath + '/' + auctionClusterId + '/user/overrule/' + overrule + '/enable', user.userId);
    }
  }

  blockUnblock(auctionClusterId: number, user: AuctionClusterUser): Observable<AuctionClusterUser> {
    if (user.isBlocked) {
      return this.webApiService.enableDisable(this.apiPath + '/' + auctionClusterId + '/user/unblock', user.userId);
    } else {
      return this.webApiService.enableDisable(this.apiPath + '/' + auctionClusterId + '/user/block', user.userId);
    }
  }

  delete(auctionClusterId: number, userId: number): Observable<any> {
    return this.webApiService.delete(this.apiPath + '/' + auctionClusterId + '/user', userId);
  }

  verifyEmail(auctionClusterId: number, email: string): Observable<any> {
    return this.webApiService.verifyEmail(this.apiPath + '/' + auctionClusterId + '/user/verify', email);
  }
}
