export class Calculation {
  calculationId: number;
  auctionClusterId: number;
  productId: number;
  propertyTypeId: number;
  name: string;
  calculationType: number;
  mathOperation: number;
  separator: string;
  offset: number;
  expression: string;

  isLiveCalculation: boolean;
  isLiveCalculationPartialSale:boolean;
  isSplitLotCalculation: boolean;
  isMultiEditCalculation: boolean;
  isExternalAdminCalculation: boolean;

  calculationFields: Array<CalculationsField> = [];

  displayName: string;
}

export class CalculationsField {
  calculationFieldId: number;
  productPropertyId: number;
  masterDataListFieldId: number;
  isResetProperty: boolean;
}

export enum CalculationFieldTypes {
  'AUTO_INCREMENT' = 0,
  //'MATH' = 1,
  //'CONCATENATE' = 2,
  'FRONTEND' = 3,
  'EXPRESSION' = 4,
  'JSONCALC' = 5
}

export enum CalculationMathTypes {
  'ADD' = 0,
  'SUBSTRACT' = 1,
  'MULTIPLY' = 2,
  'DIVIDE_ROUND' = 3,
  'DIVIDE_FLOOR' = 4,
  'DIVIDE_CEILING' = 5
}

export enum CalculationFrontEndTypes
{
  'NUMBER_OF_BOXES' = 0
}
