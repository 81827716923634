import { Injectable, EventEmitter } from '@angular/core';
import { LanguageService } from './language.service';
import { TranslateService } from '@ngx-translate/core';
import { UpdatePreferences } from '../../shared/models/preferences';
import { UserService } from '../../shared/services/user.service';
import { AuthService } from './auth.service';
import { CookieService } from 'ngx-cookie-service';
import { Cookies } from '../constants/cookies';
import { loadMessages } from 'devextreme/localization';
import * as enMessages from '../../../assets/devexpress-lang/en-GB.json';
import * as nlMessages from '../../../assets/devexpress-lang/nl-NL.json';
import * as beMessages from '../../../assets/devexpress-lang/nl-BE.json';
import * as arMessages from '../../../assets/devexpress-lang/ar-AE.json';
import * as esMessages from '../../../assets/devexpress-lang/es-ES.json';
import * as frMessages from '../../../assets/devexpress-lang/fr-FR.json';
import * as deMessages from '../../../assets/devexpress-lang/de-DE.json';
import * as ptMessages from '../../../assets/devexpress-lang/pt-BR.json';
import * as zhMessages from '../../../assets/devexpress-lang/zh-CN.json';

@Injectable()
export class LanguagePreferenceService {

  localeMessage = new EventEmitter<string>();

  constructor(
    private languageService: LanguageService,
    private translateService: TranslateService,
    private userService: UserService,
    private cookieService: CookieService
  ) {

    this.initMessages();
  }

  initMessages() {
    loadMessages((enMessages as any).default);
    loadMessages((nlMessages as any).default);
    loadMessages((beMessages as any).default);
    loadMessages((arMessages as any).default);
    loadMessages((esMessages as any).default);
    loadMessages((frMessages as any).default);
    loadMessages((deMessages as any).default);
    loadMessages((ptMessages as any).default);
    loadMessages((zhMessages as any).default);
  }

  reload() {
    this.userService.getPreferences().subscribe(
      preferences => {
        if (preferences && preferences.language) {
          console.log("Preference language" + preferences.language.code);
          this.translateService.use(preferences.language.code);
          this.cookieService.set(Cookies.LANGUAGE_COOKIE, preferences.language.code, 1, '/');
          localStorage.setItem('last-selected-language-code', preferences.language.code);
          localStorage.setItem('last-selected-language-direction', preferences.language.direction.toString());
          this.cookieService.set(Cookies.LANGUAGE_COOKIE_DIRECTION, preferences.language.direction.toString(), 1, '/');
          this.languageService.direction.emit(preferences.language.direction);
        }
      }
    );
  }

  save(languageCode: string) {
    this.languageService.getLanguages().subscribe(
      languages => {
        const language = languages.find(lang => lang.code === languageCode);
        if (language) {
          const updatePreferences = new UpdatePreferences(language.languageId);
          this.userService.updatePreferences(updatePreferences).subscribe();
          this.translateService.use(language.code);
          this.cookieService.set(Cookies.LANGUAGE_COOKIE, language.code, 1, '/');
          localStorage.setItem('last-selected-language-code', language.code);
          localStorage.setItem('last-selected-language-direction', language.direction.toString());
          this.cookieService.set(Cookies.LANGUAGE_COOKIE_DIRECTION, language.direction.toString(), 1, '/');
          this.languageService.direction.emit(language.direction);
        }
      }
    );
  }

  configure() {
    let languageCode = 'en-GB';
    const lastSelectedLanguageCode = localStorage.getItem('last-selected-language-code');
    if (lastSelectedLanguageCode) {
      languageCode = lastSelectedLanguageCode;
    }

    console.log("Last-selected-language-code: " + languageCode);
    console.log("Languages: " + this.translateService.getLangs());
    
    this.translateService.setDefaultLang('en-GB');
    this.translateService.use(languageCode);

    this.reload();
  }

  current() {
    return this.translateService.currentLang;
  }
}
