<div class="item-list-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">

  <div class="grid-buttons">
    <dx-button hint="{{ 'ADD' | translate }}" icon="add" (onClick)="add()"></dx-button>
  </div>

  <br/>
  <dx-data-grid
                #calculationFieldsGrid
                id="printSection"
                [rtlEnabled]="rtlEnabled"
                [dataSource]="items"
                [showBorders]="true"
                [allowColumnReordering]="true"
                [hoverStateEnabled]="true"
                [columnAutoWidth]="true"
                [rowAlternationEnabled]="true"
                (onRowClick)="openEditClick($event)">
      <dxo-selection mode="single"></dxo-selection>
      <dxo-pager 
        [showPageSizeSelector]="true" 
        [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]" 
        [showNavigationButtons]="true" 
        [showInfo]="true" 
        infoText="{2} {{ 'PRODUCT.PROPERTY.PAGINATION_TEXT_PLACEHOLDER' | translate | slice:2 }}">
    </dxo-pager>
    <dxi-column [customizeText]="translateFields" dataField="productPropertyName" caption="{{ 'PRODUCT.PROPERTY.NAME' | translate }}" dataType="string">
    </dxi-column>
    <dxi-column [customizeText]="translateSelectBoxes" dataField="productPropertyTypeName" caption="{{ 'PRODUCT.PROPERTY.TYPE' | translate }}" dataType="string">
    </dxi-column>
    <dxi-column [customizeText]="translateFields" dataField="masterDataFieldName" caption="{{ 'PRODUCT.PROPERTY.MASTER_DATA_FIELD_NAME' | translate }}" dataType="string">
    </dxi-column>
    <dxi-column type="buttons" class="btns-column">
      <dxi-button hint="{{ 'EDIT' | translate }}" icon="edit" [onClick]="editItem"></dxi-button>
      <dxi-button hint="{{ 'DELETE' | translate }}" icon="trash" [onClick]="deleteItem"></dxi-button>
    </dxi-column>
</dx-data-grid>
</div>

<calculations-field-component (onAdded)="onProductPropertyAdded($event)" (onUpdated)="onProductPropertyUpdated($event)"></calculations-field-component>
