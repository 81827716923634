<div class="buyer-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup
  #addBuyer
  [width]="600"
  [showTitle]="true"
  title="{{ modalTitle }}"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [rtlEnabled]="rtlEnabled"
  [(visible)]="isOpened"
  (onShown)="onFieldDataChanged($event)">
    <dx-scroll-view width="100%" height="100%">
      <dx-form
      #buyerForm
      id="buyerForm"
      validationGroup="buyerData"
      [showValidationSummary]="false"
      (onFieldDataChanged)="onFieldDataChanged($event)"
      colCount="auto"
      [(formData)]="model"
      [minColWidth]="500">
        <dxi-item itemType="group">
          <dxi-item dataField="name">
            <dxo-label text="{{ 'BUYERS.NAME' | translate }}"></dxo-label>
            <dxi-validation-rule type="required" message="{{ 'BUYERS.NAME_VALIDATION' | translate }}"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="address1">
            <dxo-label text="{{ 'BUYERS.ADDRESS' | translate }}"></dxo-label>
            <dxi-validation-rule type="required" message="{{ 'BUYERS.ADDRESS_VALIDATION' | translate }}"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="address2">
            <dxo-label text="{{ 'BUYERS.ADDRESS2' | translate }}"></dxo-label>
          </dxi-item>
          <dxi-item dataField="zip">
            <dxo-label text="{{ 'BUYERS.ZIP' | translate }}"></dxo-label>
            <dxi-validation-rule type="required" message="{{ 'BUYERS.ZIP_VALIDATION' | translate }}"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="city">
            <dxo-label text="{{ 'BUYERS.CITY' | translate }}"></dxo-label>
            <dxi-validation-rule type="required" message="{{ 'BUYERS.CITY_VALIDATION' | translate }}"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="country">
            <dxo-label text="{{ 'BUYERS.COUNTRY' | translate }}"></dxo-label>
            <dxi-validation-rule type="required" message="{{ 'BUYERS.COUNTRY_VALIDATION' | translate }}"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="vat">
            <dxo-label text="{{ 'BUYERS.VAT' | translate }}"></dxo-label>
            <dxi-validation-rule type="required" message="{{ 'BUYERS.VAT_VALIDATION' | translate }}"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="telNumber">
            <dxo-label text="{{ 'BUYERS.TEL_NUMBER' | translate }}"></dxo-label>
            <dxi-validation-rule type="required" message="{{ 'BUYERS.TEL_NUMBER_VALIDATION' | translate }}"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="faxNumber">
            <dxo-label text="{{ 'BUYERS.FAX_NUMBER' | translate }}"></dxo-label>
          </dxi-item>
          <dxi-item dataField="email">
            <dxo-label text="{{ 'BUYERS.EMAIL' | translate }}"></dxo-label>
            <dxi-validation-rule type="required" message="{{ 'BUYERS.EMAIL_VALIDATION' | translate }}"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="url">
            <dxo-label text="{{ 'BUYERS.URL' | translate }}"></dxo-label>
          </dxi-item>
          <dxi-item dataField="isActive" editorType="dxCheckBox">
            <dxo-label text="{{ 'BUYERS.ACTIVE' | translate }}"></dxo-label>
          </dxi-item>
          <dxi-item 
              dataField="userLicenceCount" 
              editorType="dxNumberBox"
              [editorOptions]="{ 
                showSpinButtons: true, 
                mode: 'number', 
                format: '#0', 
                min: 0,
                step: 1}"
              >
                <dxo-label text="{{ 'BUYERS.USER_LICENCES' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'BUYERS.USER_LICENCES_VALIDATION' | translate }}"></dxi-validation-rule>
              </dxi-item>
        </dxi-item>
        <dxi-item itemType="group" [(visible)]="!isEditMode">
          <dxi-item dataField="assignAdminUser" [template]="'assignAdminUserTemplate'">
            <dxo-label text="{{ 'AUCTIONS_CLUSTERS.ADMIN_USER_LABEL' | translate }}"></dxo-label>
          </dxi-item>
          <dxi-item 
              dataField="administratorId"
              editorType="dxSelectBox"
              [editorOptions]="{ items: users, valueExpr:'userId' ,  
              displayExpr: returnValues, searchEnabled: 'true', showClearButton: 'true', disabled: !assignAdminUser}">
            <dxo-label [visible]="false"></dxo-label>
          </dxi-item>
        </dxi-item>

        <div *dxTemplate="let data of 'assignAdminUserTemplate'">
          <dx-check-box [(value)]="assignAdminUser"></dx-check-box>
        </div>

    </dx-form>
    <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnBuyerSubmit" disabled="disabled">
      {{ 'SAVE' | translate }}
    </button>
    <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'CANCEL' | translate }}</button>
    </dx-scroll-view>
  </dx-popup>
</div>
