import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { WebApiService } from '../../../shared/services/web-api.service';
import { MasterData } from '../../../auction/shared/models/master-data';
import { IMasterDataService } from '../../../shared/interfaces/master-data';

@Injectable()
export class MasterDataService implements IMasterDataService{

  private apiPath: string;
  private buyerId: number;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'buyerlevel';
  }

  setId(id: number){
    this.buyerId = id;
  }

  getMasterDatas(buyerId: number, auctionClusterId: number): Observable<Array<MasterData>> {
    return this.webApiService.getList(`${this.apiPath}/${buyerId}/auctioncluster/${auctionClusterId}/masterdatalist`);
  }
  
  getMasterData(buyerId: number, auctionClusterId: number, masterDataId: number): Observable<MasterData> {
    return this.webApiService.getSingle(`${this.apiPath}/${buyerId}/auctioncluster/${auctionClusterId}/masterdatalist/${masterDataId}`);
  }

  getMasterDataForReports(auctionClusterId: number, masterDataId: number): Observable<MasterData> {
    return this.webApiService.getSingle(`${this.apiPath}/${this.buyerId}/auctioncluster/${auctionClusterId}/masterdatalist/${masterDataId}`);
  }
}
