<div class="product-buy-button-component">
    <clr-modal [(clrModalOpen)]="isOpened" [clrModalClosable]="false">
        <h3 class="modal-title">{{ modalTitle }}</h3>
        <div class="modal-body">
            <form #detailsForm="ngForm" clrForm>
                <section class="columns">
                    <div class="column">
                        <div class="clr-form-control">
                            <label for="masterDataField" class="clr-control-label">{{ 'PRODUCT.BUY_BUTTON.TYPE' |
                                translate }}</label>
                            <div class="clr-control-container">
                                <div class="clr-select-wrapper">
                                    <select id="productBuyButtonType" name="productBuyButtonType"
                                        [(ngModel)]="model.type" required>
                                        <option *ngFor="let type of productBuyButtonsTypes" [ngValue]="type.value">{{ type.key | translate }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="clr-form-control" *ngIf="model.type === 3">
                            <label for="clockBottomMargin" class="clr-control-label">{{ 'PRODUCT.BUY_BUTTON.VALUE' |
                                translate }}</label>
                            <div class="clr-control-container">
                                <div class="clr-input-wrapper">
                                    <input id="productBuyButtonValue" type="number" name="productBuyButtonValue"
                                        #productBuyButtonValue="ngModel" class="clr-input" [(ngModel)]="model.value"
                                        required />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </form>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" (click)="onSubmit();" [disabled]="!detailsForm.form.valid">{{
                (isEditMode ? 'SAVE' : 'ADD') | translate }}</button>
            <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'CANCEL' | translate }}</button>
        </div>
    </clr-modal>
</div>