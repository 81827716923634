<div class="supplier-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup
  #addSupplier
  [width]="600"
  [showTitle]="true"
  title="{{ modalTitle }}"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [rtlEnabled]="rtlEnabled"
  [(visible)]="isOpened"
  (onShown)="onFieldDataChanged($event)">
    <dx-scroll-view width="100%" height="100%">
      <dx-form
      #supplierForm
      id="supplierForm"
      validationGroup="supplierData"
      [showValidationSummary]="false"
      (onFieldDataChanged)="onFieldDataChanged($event)"
      [width]="600"
      colCount="auto"
      [(formData)]="model"
      [minColWidth]="500">
        <dxi-item itemType="group">
          <dxi-item dataField="name">
            <dxo-label text="{{ 'SUPPLIERS.NAME' | translate }}"></dxo-label>
            <dxi-validation-rule type="required" message="{{ 'SUPPLIERS.NAME_VALIDATION' | translate }}"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="address1">
            <dxo-label text="{{ 'SUPPLIERS.ADDRESS' | translate }}"></dxo-label>
            <dxi-validation-rule type="required" message="{{ 'SUPPLIERS.ADDRESS_VALIDATION' | translate }}"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="address2">
            <dxo-label text="{{ 'SUPPLIERS.ADDRESS2' | translate }}"></dxo-label>
          </dxi-item>
          <dxi-item dataField="zip">
            <dxo-label text="{{ 'SUPPLIERS.ZIP' | translate }}"></dxo-label>
            <dxi-validation-rule type="required" message="{{ 'SUPPLIERS.ZIP_VALIDATION' | translate }}"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="city">
            <dxo-label text="{{ 'SUPPLIERS.CITY' | translate }}"></dxo-label>
            <dxi-validation-rule type="required" message="{{ 'SUPPLIERS.CITY_VALIDATION' | translate }}"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="country">
            <dxo-label text="{{ 'SUPPLIERS.COUNTRY' | translate }}"></dxo-label>
            <dxi-validation-rule type="required" message="{{ 'SUPPLIERS.COUNTRY_VALIDATION' | translate }}"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="vat">
            <dxo-label text="{{ 'SUPPLIERS.VAT' | translate }}"></dxo-label>
            <dxi-validation-rule type="required" message="{{ 'SUPPLIERS.VAT_VALIDATION' | translate }}"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="telNumber">
            <dxo-label text="{{ 'SUPPLIERS.TEL_NUMBER' | translate }}"></dxo-label>
            <dxi-validation-rule type="required" message="{{ 'SUPPLIERS.TEL_NUMBER_VALIDATION' | translate }}"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="faxNumber">
            <dxo-label text="{{ 'SUPPLIERS.FAX_NUMBER' | translate }}"></dxo-label>
          </dxi-item>
          <dxi-item dataField="email">
            <dxo-label text="{{ 'SUPPLIERS.EMAIL' | translate }}"></dxo-label>
            <dxi-validation-rule type="required" message="{{ 'SUPPLIERS.EMAIL_VALIDATION' | translate }}"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="url">
            <dxo-label text="{{ 'SUPPLIERS.URL' | translate }}"></dxo-label>
          </dxi-item>
          <dxi-item dataField="isActive" editorType="dxCheckBox">
            <dxo-label text="{{ 'SUPPLIERS.ACTIVE' | translate }}"></dxo-label>
          </dxi-item>
        </dxi-item>
        <dxi-item itemType="group" [(visible)]="!isEditMode">
          <dxi-item dataField="assignAdminUser" [template]="'assignAdminUserTemplate'">
            <dxo-label text="{{ 'AUCTIONS_CLUSTERS.ADMIN_USER_LABEL' | translate }}"></dxo-label>
          </dxi-item>
          <dxi-item 
              dataField="administratorId"
              editorType="dxSelectBox"
              [editorOptions]="{ items: users, valueExpr:'userId' ,  
              displayExpr: returnValues, searchEnabled: 'true', showClearButton: 'true', disabled: !assignAdminUser}">
            <dxo-label [visible]="false"></dxo-label>
          </dxi-item>
        </dxi-item>

        <div *dxTemplate="let data of 'assignAdminUserTemplate'">
          <dx-check-box [(value)]="assignAdminUser"></dx-check-box>
        </div>

    </dx-form>
    <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnSupplierSubmit" disabled="disabled">
      {{ 'SAVE' | translate }}
    </button>
    <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'CANCEL' | translate }}</button>
    </dx-scroll-view>
  </dx-popup>
</div>
