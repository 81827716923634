<div class="supply-catalog-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup 
    #addSupplyCatalog
    [width]="600" 
    [showTitle]="true" 
    title="{{ modalTitle }}" 
    [dragEnabled]="false"
    [closeOnOutsideClick]="false" 
    [rtlEnabled]="rtlEnabled" 
    [(visible)]="isOpened"
    (onShown)="onFieldDataChanged($event)">
      <dx-scroll-view width="100%" height="100%">
        <dx-form 
          #supplyCatalogForm 
          id="supplyCatalogForm" 
          [showValidationSummary]="false"
          (onFieldDataChanged)="onFieldDataChanged($event)" 
          colCount="auto" 
          [(formData)]="model" 
          [minColWidth]="500">
          <dxi-item itemType="tabbed">
            <dxi-tab title="{{ 'CATALOG.GENERAL' | translate }}">
              <dxi-item dataField="name">
                <dxo-label text="{{ 'CATALOG.NAME' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'CATALOG.NAME_VALIDATION' | translate }}">
                </dxi-validation-rule>
              </dxi-item>
              <dxi-item dataField="description" editorType="dxTextArea" [editorOptions]="{ height: 90 }" [colSpan]="2">
                <dxo-label text="{{ 'CATALOG.DESCRIPTION' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'CATALOG.DESCRIPTION_VALIDATION' | translate }}"></dxi-validation-rule>
              </dxi-item>
              <dxi-item dataField="auctionId"
                        editorType="dxSelectBox"
                        [editorOptions]="{ items: auctions, valueExpr:'auctionId',
                  displayExpr:'name', disabled: isEditMode, rtlEnabled: rtlEnabled,
                  searchEnabled: true, showClearButton: true}">
                <dxo-label text="{{ 'CATALOG.AUCTION' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'CATALOG.AUCTION_VALIDATION' | translate }}"></dxi-validation-rule>
              </dxi-item>
              <dxi-item dataField="productId"
                        editorType="dxSelectBox"
                        [editorOptions]="{ items: products, valueExpr:'productId' ,
                  displayExpr:'productNameText', rtlEnabled: rtlEnabled,
                  searchEnabled: true, showClearButton: true, onValueChanged: filterCatalogs}"
                        *ngIf="user.isSystemUser">
                <dxo-label text="{{ 'CATALOG.PRODUCT' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'CATALOG.PRODUCT_VALIDATION' | translate }}"></dxi-validation-rule>
              </dxi-item>
              <dxi-item dataField="forecastCatalogId"
                        editorType="dxSelectBox"
                        [editorOptions]="{ items: filteredForecastCatalogs, valueExpr:'catalogId' ,
                  displayExpr:'name', rtlEnabled: rtlEnabled,
                  searchEnabled: true, showClearButton: true}"
                        *ngIf="user.isSystemUser">
                <dxo-label text="{{ 'CATALOG.FORECAST_CATALOG' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item dataField="typeOfGrouping"
                        editorType="dxSelectBox"
                        [editorOptions]="{ items: groupingTypes, valueExpr:'value' ,
                  displayExpr:translateSelectBoxes, rtlEnabled: rtlEnabled,
                  searchEnabled: true}">
                <dxo-label text="{{ 'CATALOG.TYPE_OF_GROUPING' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item *ngIf="model.typeOfGrouping === 2 || model.typeOfGrouping === 3" 
                        dataField="groupingBehaviour" editorType="dxSelectBox"
                        [editorOptions]="{ items: groupingBehaviour, valueExpr:'value' ,
                        displayExpr:translateSelectBoxes, rtlEnabled: rtlEnabled,
                        searchEnabled: true}">
                <dxo-label text="{{ 'CATALOG.BEHAVIOUR_OF_GROUPING' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item dataField="allowLowestStopClockPrice"
                        editorType="dxSelectBox"
                        [editorOptions]="{ items: allowLowestStopClockPriceOptions, valueExpr:'value' ,
                  displayExpr:translateSelectBoxes, rtlEnabled: rtlEnabled,
                  searchEnabled: true}"
                        *ngIf="user.isSystemUser">
                <dxo-label text="{{ 'CATALOG.ALLOW_LOWEST_STOP_CLOCK_PRICE' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item dataField="printTiming"
                        editorType="dxSelectBox"
                        [editorOptions]="{ items: printTiming, valueExpr:'value' ,
                  displayExpr:translateSelectBoxes, rtlEnabled: rtlEnabled,
                  searchEnabled: true}">
                <dxo-label text="{{ 'CATALOG.PRINT_TIMING' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item dataField="printTicketOnLotCreated" editorType="dxCheckBox">
                <dxo-label text="{{ 'CATALOG.PRINT_TICKET_ON_LOT_CREATED' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item dataField="viewOnlyMode" editorType="dxCheckBox">
                <dxo-label text="{{ 'CATALOG.VIEW_ONLY_MODE' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item
                        dataField="refreshDelay"
                        editorType="dxNumberBox"
                        [editorOptions]="{
                  showSpinButtons: true,
                  mode: 'number',
                  format: '#0',
                  min: 0,
                  step: 1}">
                <dxo-label text="{{ 'CATALOG.REFRESH_DELAY' | translate }}"></dxo-label>
              </dxi-item>
            </dxi-tab>
            <dxi-tab title="{{ 'CATALOG.PREBIDS' | translate }}">
              <dxi-item
                        dataField="prebid"
                        editorType="dxSelectBox"
                        [editorOptions]="{ items: prebidAllowedOptions, valueExpr:'value' ,
                  displayExpr:translateSelectBoxes, rtlEnabled: rtlEnabled,
                  searchEnabled: true}">
                <dxo-label text="{{ 'CATALOG.PREBIDS_ENABLED' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item
                        dataField="prebidPriority"
                        editorType="dxSelectBox"
                        [visible]="model.prebid == prebidAllowedEnum.ENABLED"
                        [editorOptions]="{ items: prebidPriorities, valueExpr:'value' ,
                  displayExpr:translateSelectBoxes, rtlEnabled: rtlEnabled,
                  searchEnabled: true, showClearButton: true, disabled: model.prebid != prebidAllowedEnum.ENABLED}">
                <dxo-label text="{{ 'CATALOG.PREBID_PRIORITY' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item
                        dataField="shortPrebidPriority"
                        editorType="dxSelectBox"
                        [visible]="model.prebid == prebidAllowedEnum.ENABLED"
                        [editorOptions]="{ items: prebidPriorities, valueExpr:'value' ,
                  displayExpr:translateSelectBoxes, rtlEnabled: rtlEnabled,
                  searchEnabled: true, showClearButton: true}">
                <dxo-label text="{{ 'CATALOG.SHORT_PREBID_PRIORITY' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item
                        dataField="prebidMinMaxAmounts"
                        editorType="dxSelectBox"
                        [editorOptions]="{ items: prebidMinMaxAmountsEnum, valueExpr:'value' ,
                  displayExpr:translateSelectBoxes, rtlEnabled: rtlEnabled,
                  searchEnabled: true, showClearButton: true}">
                <dxo-label text="{{ 'CATALOG.MINMAX_AMOUNTS' | translate }}"></dxo-label>
              </dxi-item>
            </dxi-tab>
            <dxi-tab title="{{ 'CATALOG.VALIDATION' | translate }}">
              <dxi-item
                        dataField="partialSaleEntryTimeout"
                        editorType="dxNumberBox"
                        [editorOptions]="{
                  showSpinButtons: true,
                  mode: 'number',
                  format: '#0',
                  min: 0,
                  step: 1}">
                <dxo-label text="{{ 'CATALOG.BUY_POPUP_TIMEOUT' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'CATALOG.BUY_POPUP_TIMEOUT_VALIDATION' | translate }}"></dxi-validation-rule>
              </dxi-item>
              <dxi-item
                        dataField="buyPopupInactivityTimerStart"
                        editorType="dxNumberBox"
                        [editorOptions]="{
                  showSpinButtons: true,
                  mode: 'number',
                  format: '#0',
                  min: 0,
                  step: 1}">
                <dxo-label text="{{ 'CATALOG.BUY_POPUP_INACTIVITY_TIMER_START' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item
                        dataField="buyPopupInactivityTimeout"
                        editorType="dxNumberBox"
                        [editorOptions]="{
                  showSpinButtons: true,
                  mode: 'number',
                  format: '#0',
                  min: 0,
                  step: 1}">
                <dxo-label text="{{ 'CATALOG.BUY_POPUP_INACTIVITY_TIMEOUT' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item dataField="allowPartialSaleOfGroupedLots" editorType="dxCheckBox">
                <dxo-label text="{{ 'CATALOG.ALLOW_PARTIAL_SALE_GROUPED' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item dataField="allowPartialSaleOfSingleLotWithMultipleSaleUnits" editorType="dxCheckBox">
                <dxo-label text="{{ 'CATALOG.ALLOW_PARTIAL_SALE_SINGLE' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item dataField="allowMultiplePrebidsPerBuyerOnSameLot" editorType="dxCheckBox" [editorOptions]="{disabled: !model.allowPartialSaleOfSingleLotWithMultipleSaleUnits}" *ngIf="user.isSystemUser">
                <dxo-label text="{{ 'CATALOG.ALLOW_MULTIPLE_PREBIDS_PER_BUYER_ON_SAME_LOT' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item dataField="allowPriceAdjust" editorType="dxCheckBox">
                <dxo-label text="{{ 'CATALOG.ALLOW_PRICE_ADJUST' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item dataField="allowQuantityReduction" editorType="dxCheckBox">
                <dxo-label text="{{ 'CATALOG.ALLOW_QUANTITY_REDUCTION' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item dataField="amountIsBoxContentMultitude" editorType="dxCheckBox" [editorOptions]="{disabled: !model.allowQuantityReduction}">
                <dxo-label text="{{ 'CATALOG.AMOUNT_IS_BOX_CONTENT_MULTITUDE' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item dataField="allowBuyerChangeByAuctioneer" editorType="dxCheckBox">
                <dxo-label text="{{ 'CATALOG.ALLOW_BUYER_CHANGE_BY_AUCTIONEER' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item dataField="useBoxContentInCalculation" editorType="dxCheckBox" *ngIf="user.isSystemUser">
                <dxo-label text="{{ 'CATALOG.USE_BOX_CONTENT_IN_CALCULATION' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item dataField="autoSelectLotsAfterBid" editorType="dxCheckBox">
                <dxo-label text="{{ 'CATALOG.AUTO_SELECT_LOTS_AFTER_BID' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item dataField="autoSelectSingleLot" editorType="dxCheckBox">
                <dxo-label text="{{ 'CATALOG.AUTO_SELECT_SINGLE_LOT' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item
                        dataField="splitLotToMultipleSubbuyers"
                        editorType="dxSelectBox"   
                        [editorOptions]="{ items: splitLotToMultipleSubbuyers, valueExpr:'value' ,
                displayExpr:translateSelectBoxes, rtlEnabled: rtlEnabled,
                searchEnabled: true, wrapItemText: true}">
                <dxo-label text="{{ 'CATALOG.SPLIT_LOT_TO_MULTIPLE_SUBBUYERS' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item dataField="showMasterDetailInManualGroupsMode" editorType="dxCheckBox">
                <dxo-label text="{{ 'CATALOG.SHOW_MASTER_DETAIL_IN_MANUAL_GROUPS_MODE' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item dataField="immediateTransactionMode" editorType="dxCheckBox">
                <dxo-label text="{{ 'CATALOG.IMMEDIATE_TRANSACTION_MODE' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item dataField="showCreditLimitFieldsInValidationMode" editorType="dxCheckBox">
                <dxo-label text="{{ 'CATALOG.SHOW_CREDIT_LIMIT_FIELDS_IN_VALIDATION_MODE' | translate }}"></dxo-label>
              </dxi-item>
            </dxi-tab>
            <dxi-tab title="{{ 'CATALOG.BUFFER' | translate }}">
              <dxi-item dataField="enableTransactionBuffer" editorType="dxCheckBox">
                <dxo-label text="{{ 'CATALOG.TRANSACTION_BUFFER_ENABLE' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item dataField="groupedBuffer" editorType="dxCheckBox"
                        [visible]="model.enableTransactionBuffer"
                        [editorOptions]="{ disabled: !model.enableTransactionBuffer }"
                        *ngIf="user.isSystemUser">
                <dxo-label text="{{ 'CATALOG.GROUPED_BUFFER' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item
                        dataField="transactionBufferSize"
                        editorType="dxNumberBox"
                        [visible]="model.enableTransactionBuffer"
                        [editorOptions]="{
                  disabled: !model.enableTransactionBuffer,
                  showSpinButtons: true,
                  mode: 'number',
                  format: '#0',
                  min: 0,
                  step: 1}">
                <dxo-label text="{{ 'CATALOG.TRANSACTION_BUFFER_SIZE' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item dataField="allowRemoveTransaction" editorType="dxCheckBox">
                <dxo-label text="{{ 'CATALOG.ALLOW_REMOVE_TRANSACTION' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item
                        dataField="deletedTransactionPosition"
                        editorType="dxSelectBox"
                        [editorOptions]="{ items: deletedTransactionPosition, valueExpr:'value' ,
                displayExpr:translateSelectBoxes, rtlEnabled: rtlEnabled,
                searchEnabled: true}">
                <dxo-label text="{{ 'CATALOG.DELETED_TRANSACTION_POSITION' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item dataField="allowEditOfTransactionsOutsideBuffer" editorType="dxCheckBox">
                <dxo-label text="{{ 'CATALOG.ALLOW_EDIT_OF_TRANSACTIONS_OUTSIDE_BUFFER' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item dataField="allowUndoTransaction" editorType="dxCheckBox">
                <dxo-label text="{{ 'CATALOG.ALLOW_UNDO_TRANSACTION' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item dataField="allowCopyTransaction" editorType="dxCheckBox">
                <dxo-label text="{{ 'CATALOG.ALLOW_COPY_TRANSACTION' | translate }}"></dxo-label>
              </dxi-item>
            </dxi-tab>
            <dxi-tab title="{{ 'CATALOG.CLEANUP' | translate }}" *ngIf="user.isSystemUser">
              <dxi-item dataField="cleanupTransactions" editorType="dxCheckBox">
                <dxo-label text="{{ 'CATALOG.CLEANUP_TRANSACTIONS' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item dataField="cleanupTransactionsDelay"
                        editorType="dxNumberBox"
                        [editorOptions]="{
                          showSpinButtons: true,
                          mode: 'number',
                          format: '#0',
                          min: 0,
                          step: 1}">
                <dxo-label text="{{ 'CATALOG.CLEANUP_TRANSACTIONS_DELAY' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item dataField="cleanupSupplyPrebidsMarkings" editorType="dxCheckBox">
                <dxo-label text="{{ 'CATALOG.CLEANUP_SUPPLY_PREBIDS_MARKINGS' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item dataField="cleanupSupplyPrebidsMarkingsDelay"
                        editorType="dxNumberBox"
                        [editorOptions]="{
                          showSpinButtons: true,
                          mode: 'number',
                          format: '#0',
                          min: 0,
                          step: 1}">
                <dxo-label text="{{ 'CATALOG.CLEANUP_SUPPLY_PREBIDS_MARKINGS_DELAY' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item dataField="cleanupForecast" editorType="dxCheckBox">
                <dxo-label text="{{ 'CATALOG.CLEANUP_FORECAST' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item dataField="cleanupForecastDelay"
                        editorType="dxNumberBox"
                        [editorOptions]="{
                          showSpinButtons: true,
                          mode: 'number',
                          format: '#0',
                          min: 0,
                          step: 1}">
                <dxo-label text="{{ 'CATALOG.CLEANUP_FORECAST_DELAY' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item dataField="cleanupManualGroups" editorType="dxCheckBox">
                <dxo-label text="{{ 'CATALOG.CLEANUP_MANUALGROUPS' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item dataField="cleanupShoppingList" editorType="dxCheckBox">
                <dxo-label text="{{ 'CATALOG.CLEANUP_SHOPPINGLIST' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item dataField="prebidOnProductDataField" [template]="'prebidTemplate'">
                <dxo-label text="{{ 'CATALOG.CLEANUP_PREBID_ON_PRODUCT' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item dataField="scheduledCleanup" editorType="dxCheckBox">
                <dxo-label text="{{ 'CATALOG.SCHEDULED_CLEANUP' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item dataField="cleanupTime" [template]="'timeTemplate'" [visible]="model.scheduledCleanup">
                <dxo-label text="{{ 'CATALOG.SCHEDULED_CLEANUP_TIME' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item dataField="deletedMasterDataLookups" editorType="dxCheckBox">
                <dxo-label text="{{ 'CATALOG.DELETED_MASTER_DATA_LOOKUPS' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item dataField="deletedMasterDataLookupsDelay"
                        editorType="dxNumberBox"
                        [editorOptions]="{
                          showSpinButtons: true,
                          mode: 'number',
                          format: '#0',
                          min: 0,
                          step: 1}">
                <dxo-label text="{{ 'CATALOG.DELETED_MASTER_DATA_LOOKUPS_DELAY' | translate }}"></dxo-label>
              </dxi-item>
            </dxi-tab>
            <dxi-tab title="{{ 'CATALOG.DEMO' | translate }}">
              <dxi-item itemType="group">
                <dxi-item dataField="isDemoCatalog" editorType="dxCheckBox">
                  <dxo-label text="{{ 'CATALOG.IS_DEMO_CATALOG' | translate }}"></dxo-label>
                </dxi-item>
                <dxi-item itemType="group" [visible]="!model.isDemoCatalog">
                  <dxi-item 
                    dataField="demoCatalogId"
                    editorType="dxSelectBox"
                    [editorOptions]="{ items: filteredDemoCatalogs, valueExpr:'catalogId',
                    displayExpr: 'name', rtlEnabled: rtlEnabled,
                    searchEnabled: true, showClearButton: true}">
                    <dxo-label text="{{ 'CATALOG.DEMO_CATALOG' | translate }}"></dxo-label>
                  </dxi-item>
                </dxi-item>
                <dxi-item itemType="group" [visible]="model.isDemoCatalog">
                  <dxi-item dataField="isAutoSetStartPrice" editorType="dxCheckBox">
                    <dxo-label text="{{ 'CATALOG.IS_AUTO_SET_START_PRICE' | translate }}"></dxo-label>
                  </dxi-item>
                  <dxi-item itemType="group" [visible]="model.isAutoSetStartPrice">
                    <dxi-item dataField="autoSetStartPrice"
                              editorType="dxNumberBox"
                              [editorOptions]="{
                                showSpinButtons: true,
                                mode: 'number',
                                format: '#0',
                                min: 0,
                                step: 1}">
                      <dxo-label text="{{ 'CATALOG.AUTO_SET_START_PRICE' | translate }}"></dxo-label>
                    </dxi-item>
                  </dxi-item>
                  <dxi-item dataField="isAutoStartOnNewLotAfter" editorType="dxCheckBox">
                    <dxo-label text="{{ 'CATALOG.IS_AUTO_START_NEW_LOT_AFTER' | translate }}"></dxo-label>
                  </dxi-item>
                  <dxi-item itemType="group" [visible]="model.isAutoStartOnNewLotAfter">
                    <dxi-item dataField="autoStartOnNewLotAfter"
                              editorType="dxNumberBox"
                              [editorOptions]="{
                                showSpinButtons: true,
                                mode: 'number',
                                format: '#0',
                                min: 0,
                                step: 1}">
                      <dxo-label text="{{ 'CATALOG.AUTO_START_NEW_LOT_AFTER' | translate }}"></dxo-label>
                    </dxi-item>
                  </dxi-item>
                  <dxi-item dataField="isAutoStopClock" editorType="dxCheckBox">
                    <dxo-label text="{{ 'CATALOG.IS_AUTO_STOP_CLOCK' | translate }}"></dxo-label>
                  </dxi-item>
                  <dxi-item itemType="group" [visible]="model.isAutoStopClock">
                    <dxi-item dataField="autoStopClockAfterSteps"
                              editorType="dxNumberBox"
                              [editorOptions]="{
                                showSpinButtons: true,
                                mode: 'number',
                                format: '#0',
                                min: 0,
                                step: 1}">
                      <dxo-label text="{{ 'CATALOG.AUTO_STOP_CLOCK_AFTER' | translate }}"></dxo-label>
                    </dxi-item>
                    <dxi-item dataField="autoStopBuyerId"
                              editorType="dxSelectBox"
                              [editorOptions]="{ items: buyers, valueExpr:'buyerId',
                        displayExpr: 'name', rtlEnabled: rtlEnabled,
                        searchEnabled: true, showClearButton: true}">
                      <dxo-label text="{{ 'CATALOG.AUTO_STOP_BUYER_ID' | translate }}"></dxo-label>
                    </dxi-item>
                    <dxi-item dataField="autoValidatePurchase" editorType="dxCheckBox">
                      <dxo-label text="{{ 'CATALOG.AUTO_VALIDATE_PURCHASE' | translate }}"></dxo-label>
                    </dxi-item>
                  </dxi-item>
                  <dxi-item dataField="printTransactions" editorType="dxCheckBox">
                    <dxo-label text="{{ 'CATALOG.PRINT_TRANSACTIONS' | translate }}"></dxo-label>
                  </dxi-item>
                  <dxi-item dataField="sendTransactionsToReporting" editorType="dxCheckBox">
                    <dxo-label text="{{ 'CATALOG.SEND_TRANSACTIONS_TO_REPORTING' | translate }}"></dxo-label>
                  </dxi-item>
                  <dxi-item dataField="demoAuctioneerUserId"
                            editorType="dxSelectBox"
                            [editorOptions]="{ items: demoAdminUsers, valueExpr:'userId' ,
                    displayExpr: comboboxName, rtlEnabled: rtlEnabled,
                    searchEnabled: true, showClearButton: true}">
                    <dxo-label text="{{ 'CATALOG.DEMO_AUCTIONEER_ID' | translate }}"></dxo-label>
                  </dxi-item>
                </dxi-item>
              </dxi-item>
            </dxi-tab>
            <dxi-tab title="{{ 'CATALOG.MINMAX_AMOUNTS' | translate }}">
                <dxi-item itemType="group" [colCount]="2" cssClass="bottom-margin">

                  <dxi-item itemType="group">
                    <dxi-item
                          dataField="minAmount1"
                          editorType="dxNumberBox"
                          [editorOptions]="{showSpinButtons: true, mode: 'number', format: '#0', min: 0, step: 1}">
                      <dxo-label text="{{ 'CATALOG.MIN_AMOUNT' | translate }} 1"></dxo-label>
                    </dxi-item>
                    <dxi-item
                          dataField="minAmount2"
                          editorType="dxNumberBox"
                          [editorOptions]="{showSpinButtons: true, mode: 'number', format: '#0', min: 0, step: 1}">
                      <dxo-label text="{{ 'CATALOG.MIN_AMOUNT' | translate }} 2"></dxo-label>
                    </dxi-item>
                    <dxi-item
                          dataField="minAmount3"
                          editorType="dxNumberBox"
                          [editorOptions]="{showSpinButtons: true, mode: 'number', format: '#0', min: 0, step: 1}">
                      <dxo-label text="{{ 'CATALOG.MIN_AMOUNT' | translate }} 3"></dxo-label>
                    </dxi-item>
                    <dxi-item
                          dataField="minAmount4"
                          editorType="dxNumberBox"
                          [editorOptions]="{showSpinButtons: true, mode: 'number', format: '#0', min: 0, step: 1}">
                      <dxo-label text="{{ 'CATALOG.MIN_AMOUNT' | translate }} 4"></dxo-label>
                    </dxi-item>
                    <dxi-item
                          dataField="minAmount5"
                          editorType="dxNumberBox"
                          [editorOptions]="{showSpinButtons: true, mode: 'number', format: '#0', min: 0, step: 1}">
                      <dxo-label text="{{ 'CATALOG.MIN_AMOUNT' | translate }} 5"></dxo-label>
                    </dxi-item>
                  </dxi-item>

                  <dxi-item itemType="group">
                    <dxi-item
                          dataField="maxAmount1"
                          editorType="dxNumberBox"
                          [editorOptions]="{showSpinButtons: true, mode: 'number', format: '#0', min: 0, step: 1}">
                      <dxo-label text="{{ 'CATALOG.MAX_AMOUNT' | translate }} 1"></dxo-label>
                    </dxi-item>
                    <dxi-item
                          dataField="maxAmount2"
                          editorType="dxNumberBox"
                          [editorOptions]="{showSpinButtons: true, mode: 'number', format: '#0', min: 0, step: 1}">
                      <dxo-label text="{{ 'CATALOG.MAX_AMOUNT' | translate }} 2"></dxo-label>
                    </dxi-item>
                    <dxi-item
                          dataField="maxAmount3"
                          editorType="dxNumberBox"
                          [editorOptions]="{showSpinButtons: true, mode: 'number', format: '#0', min: 0, step: 1}">
                      <dxo-label text="{{ 'CATALOG.MAX_AMOUNT' | translate }} 3"></dxo-label>
                    </dxi-item>
                    <dxi-item
                          dataField="maxAmount4"
                          editorType="dxNumberBox"
                          [editorOptions]="{showSpinButtons: true, mode: 'number', format: '#0', min: 0, step: 1}">
                      <dxo-label text="{{ 'CATALOG.MAX_AMOUNT' | translate }} 4"></dxo-label>
                    </dxi-item>
                    <dxi-item
                          dataField="maxAmount5"
                          editorType="dxNumberBox"
                          [editorOptions]="{showSpinButtons: true, mode: 'number', format: '#0', min: 0, step: 1}">
                      <dxo-label text="{{ 'CATALOG.MAX_AMOUNT' | translate }} 5"></dxo-label>
                    </dxi-item>
                  </dxi-item>

                </dxi-item>
            </dxi-tab>
          </dxi-item>

          <div *dxTemplate="let data of 'timeTemplate'">
            <time-component [data]="dateValue(data)" (modelChanged)="dateChanged('cleanupTime', $event)" [(disabled)]="!model.scheduledCleanup"></time-component>
          </div>
          
          <div *dxTemplate="let data of 'prebidTemplate'">
              <dx-tag-box [dataSource]="cleanupPrebidOnProduct"
                          [displayExpr]="translateSelectBoxes"
                          valueExpr="value"
                          searchEnabled="true"
                          [(value)]="prebidOnProductDataField"
                          showClearButton ="true"
                          [rtlEnabled]="rtlEnabled">
              </dx-tag-box>
          </div>
        </dx-form>
        <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnSupplyCatalogSubmit"
        disabled="disabled">{{ 'SAVE' | translate }}</button>
        <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'CANCEL' | translate }}</button>
      </dx-scroll-view>
  </dx-popup>
