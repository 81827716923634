import { Component, OnInit, Injector, Input, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { Subscription } from 'rxjs';

// general services
import { LanguageService } from '../../shared/services/language.service';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { GroupFilterItemComponent } from './group-filter-item.component';

// services
import { CatalogService } from '../shared/services/catalog.service';
import { AuctionClusterAuctionService } from '../shared/services/auction-cluster-auction.service';
import { ProductService } from '../shared/services/product.service';

// models
import { Catalog, GroupFilter, CatalogGroupFilterProperty, CatalogGroupFilterValue } from '../shared/models/catalog';
import { Auction } from '../shared/models/auction';
import { Product, GridProperty } from '../shared/models/product';
import { ImportSchema, ImportField } from '../../shared/import/import-schema';
import { Language } from '../../shared/models/language';

@Component({
  selector: 'group-filter-component',
  templateUrl: 'group-filter.component.html',
  styleUrls: ['./group-filter.component.scss']
})
export class GroupFilterComponent extends FullListComponent<GroupFilter, GroupFilterItemComponent> implements OnInit, OnDestroy {

  @Output() close = new EventEmitter();

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('name') name: any;

  catalogId: number;
  auctionId: number;
  languages: Array<Language> = [];
  model: GroupFilter;
  product: Product;
  cardTitle: string;
  errorMessage: string;
  isEditMode = false;
  selections: {};
  masterDataDropdowns: Array<any> = [];
  lotIds: Array<number> = [];
  JSON = JSON;
  saveDisabled = false;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private dataService: CatalogService,
    private auctionService: AuctionClusterAuctionService,
    private productService: ProductService,
    private languageService: LanguageService
  ) {
    super(injector, GroupFilter);
    //this.title.set('GROUP_FILTER.TITLE');
    this.setPageSize(10);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.setTranslations('GROUP_FILTER');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  // tslint:disable:no-magic-numbers
  getData() {
    if (!this.auctionId || !this.catalogId) {
      return;
    }
    this.spinner.show();
    this.dataService.getGroupFilters(this.auctionId, this.catalogId).subscribe(gf => {
      this.items = gf.sort((a, b) => (this.languageService.getTranslatableText(a.name) > this.languageService.getTranslatableText(b.name)) ? 1 : -1);
      this.spinner.hide();
    }, error => {
      notify(error.message, 'error', 5000);
      this.spinner.hide();
    });

    if(this.lotIds.length > 0)
    {
      this.dataService.getGroupFilterPreselected(this.auctionId, this.catalogId, this.lotIds).subscribe(
        gf => {
        this.model = gf;
        this.parseModel();
        this.cardTitle = this.translations.EDIT;
        const emptyTranslation = {};
          this.languages.forEach(lang => {
            emptyTranslation[lang.code] = '';
          });
        this.model.name = JSON.stringify(emptyTranslation);
        this.spinner.hide();
      }, error => {
        notify(error.message, 'error', 5000);
        this.spinner.hide();
      });
    }
  }
  // tslint:enable:no-magic-numbers

  getLabel(prop: GridProperty) {
    const pp = this.product.productProperties.find(p => p.productPropertyId === prop.productPropertyId);

    if (pp) {
      return pp.name;
    }

    return '';
  }

  getItems(prop: GridProperty) {
    const dd = this.masterDataDropdowns.find(md => md.productPropertyId === prop.productPropertyId);
    if (dd) {
      return dd.values.sort((a, b) => (a.label > b.label) ? 1 : -1);
    }
    return [];
  }

  parseLabelAndOptions() {
    this.product.manualProductPropertyFilterSelections.forEach(s => {
      s.label = this.getLabel(s);
      s.options = this.getItems(s);
    });
  }

  clearSelections() {
    this.product.manualProductPropertyFilterSelections.forEach(s => {
      s.groupFilterSelections = [];
    });
  }

  init() {
    this.getData();
    if(this.model === null)
    {
      this.model = new GroupFilter();
    }
    this.model.catalogId = this.catalogId;

    this.errorMessage = null;

    const emptyTranslation = {};
    this.languages.forEach(lang => {
      emptyTranslation[lang.code] = '';
    });

    this.model.name = JSON.stringify(emptyTranslation);

    this.cardTitle = this.translations.NEW;

    this.isEditMode = false;

    this.clearSelections();
  }

  open(auctionId: number,
      catalogId: number,
      languages: Array<Language>,
      product: Product,
      masterDataDropdowns: Array<any>) {

    this.auctionId = auctionId;
    this.catalogId = catalogId;
    this.languages = languages;
    this.product = product;
    this.masterDataDropdowns = masterDataDropdowns;

    this.init();

    this.parseLabelAndOptions();
  }

  openPreselected(auctionId: number,
    catalogId: number,
    languages: Array<Language>,
    product: Product,
    masterDataDropdowns: Array<any>,
    lotIds: Array<number>) {

  this.auctionId = auctionId;
  this.catalogId = catalogId;
  this.languages = languages;
  this.product = product;
  this.masterDataDropdowns = masterDataDropdowns;
  this.lotIds = lotIds;

  this.init();

  this.parseLabelAndOptions();
}

  parseSelections() {
    this.model.catalogGroupFilterProperty = [];
    this.product.manualProductPropertyFilterSelections.forEach(s => {
      if (s.groupFilterSelections.length > 0) {
        const catalogGroupFilterProperty = new CatalogGroupFilterProperty();
        catalogGroupFilterProperty.productPropertyId = s.productPropertyId;
        s.groupFilterSelections.forEach(gs => {
          const catalogGroupFilterValue = new CatalogGroupFilterValue();
          catalogGroupFilterValue.masterDataListRowId = gs;
          catalogGroupFilterProperty.catalogGroupFilterValue.push(catalogGroupFilterValue);
        });

        this.model.catalogGroupFilterProperty.push(catalogGroupFilterProperty);
      }
    });
  }

  save() {
    this.saveDisabled = true;

    if (this.name) {
      this.model.name = this.name.data;
    }

    this.parseSelections();
    this.spinner.show();

    if (this.items.every(value => value.catalogGroupFilterId === this.model.catalogGroupFilterId
      || this.languageService.checkAllLanguageFieldsUniqueCanEmpty(this.languages, value.name, this.model.name))
      && this.languageService.checkOneLanguageNotEmpty(this.languages, this.model.name)) {

      if (this.model.catalogGroupFilterProperty && this.model.catalogGroupFilterProperty.length <= 0) {
        this.errorMessage = this.errorService.translations.INVALID_MANUAL_GROUP;
        notify(this.errorMessage, 'error', 5000);
        this.spinner.hide();
        this.saveDisabled = false;
        return;
      }

      this.errorMessage = null;

      if (!this.isEditMode) {
        this.dataService.saveGroupFilter(this.auctionId, this.catalogId, this.model).subscribe(() => {
          this.spinner.hide();
          this.saveDisabled = false;
          this.init();
        }, err => {
          this.errorMessage = this.errorService.toString(err);
          notify(this.errorMessage, 'error', 5000);
          this.spinner.hide();
          this.saveDisabled = false;
        });
      } else {
        this.dataService.editGroupFilter(this.auctionId, this.catalogId, this.model).subscribe(() => {
          this.spinner.hide();
          this.saveDisabled = false;
          this.init();
        }, err => {
          this.errorMessage = this.errorService.toString(err);
          notify(this.errorMessage, 'error', 5000);
          this.spinner.hide();
          this.saveDisabled = false;
        });
      }
    } else {
      this.errorMessage = this.errorService.translations.UNIQUE_NAME_ERROR;
      notify(this.errorMessage, 'error', 5000);
      this.spinner.hide();
      this.saveDisabled = false;
    }
  }

  cancel() {
    this.lotIds = [];
    this.init();
  }

  public onClose() {
    this.clearSelections();
    this.close.emit();
  }

  parseModel() {
    this.model.catalogGroupFilterProperty.forEach(p => {
      const prop = this.product.manualProductPropertyFilterSelections.find(s => s.productPropertyId === p.productPropertyId);

      if (prop) {
        prop.groupFilterSelections = [];

        p.catalogGroupFilterValue.forEach(v => {
          const option = prop.options.find(o => o.value === v.masterDataListRowId);
          if (option) {
            v.stringValue = option.label;
          }
        });

        p.catalogGroupFilterValue.sort((a, b) => (a.stringValue > b.stringValue) ? 1 : -1).forEach(v => {
          prop.groupFilterSelections.push(v.masterDataListRowId);
        });
      }
    });
  }

  edit(groupFilterId: number, event: Event) {
    event.stopPropagation();

    this.spinner.show();
    this.dataService.getGroupFilter(this.auctionId, this.catalogId, groupFilterId).subscribe(f => {
      this.errorMessage = null;
      this.model = f;
      this.parseModel();
      this.cardTitle = this.translations.EDIT;

      this.isEditMode = true;
      this.spinner.hide();
    }, err => {
        this.errorMessage = err.status + " : " + err.statusText;
        notify(this.errorMessage, 'error', 5000);
      this.spinner.hide();
    });
  }

  add() {
    // this.detailsComponent.modalTitle = this.translations.ADD_NEW;
    // this.detailsComponent.open(this.items, null, this.products, this.auctions, this.id);
  }

  // private matchProductName(productId: number) {
  //   const found = this.products.find(f => f.productId === productId);
  //   return found ? this.languageService.getTranslatableText(found.name) : '';
  // }

  // private matchAuctionName(auctionId: number) {
  //   const found = this.auctions.find(f => f.auctionId === auctionId);
  //   return found ? found.name : '';
  // }

  // getSelectedAuctions() {
  //   if (this.selectedAuction === null) {
  //     this.getData();
  //   } else {
  //     this.dataService.getAuctionForecastCatalog(this.selectedAuction).subscribe(catalogs => {
  //       this.items = catalogs;
  //       this.items.forEach(item => {
  //         item.auctionName = this.matchAuctionName(item.auctionId);
  //         item.productName = this.matchProductName(item.productId);
  //       });
  //     });
  //   }
  // }

  deleteSelected() {
    this.spinner.show();
    this.dataService.deleteGroupFilter(this.auctionId, this.catalogId, this.itemIdToDelete)
      .subscribe(() => {
        this.errorMessage = null;
        this.getData();
        this.spinner.hide();
      },
        error => {
          this.errorMessage = this.errorService.translations.DELETE_ERROR_MESSAGE;
          notify(this.errorMessage, 'error', 5000);
          this.spinner.hide();
        });
  }

  // openLots(catalogId: number, event: Event) {
  //   event.stopPropagation();
  //   this.router.navigate(['/auction/catalogs/' + this.id + '/supplyforecastlots/' + catalogId]);
  // }

  createDataSchema() {
    const schema = new ImportSchema();

    this.schema = schema;
  }

  onRowClick = (e: any) => {
    this.edit(e.data.catalogGroupFilterId, new Event('click'));
  }

  editItem = (e: any) => {
    this.edit(e.row.data.catalogGroupFilterId, new Event('click'));
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = +e.row.data.catalogGroupFilterId;
    this.confirmation.opened = true;
  }

  translateField = (item: any) => {
    if (item && this.isJson(item.value)) {
      const label = this.getTranslation(item.value);
      return label;
    } else {
      return item.value;
    }
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  isJson(value: any) {
    value = typeof value !== 'string' ? JSON.stringify(value) : value;

    try {
      value = JSON.parse(value);
    } catch (e) {
      return false;
    }
    if (typeof value === 'object' && value !== null) {
      return true;
    }
    return false;
  }
}
