<div class="buyer-supply-matrix-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <div class="card">
    <div class="card-header">
      {{ 'BUYER.SUPPLY_MATRIX.FILTER' | translate }}
    </div>

    <div>
      <div class="filter-column" *ngIf="auctionClusters.length > 1">
        <label for="auctionCluster">{{ 'BUYER.SUPPLY_MATRIX.AUCTION_CLUSTER' | translate }}</label>
        <dx-select-box 
          name="auctionCluster" 
          [items]="auctionClusters" 
          valueExpr="auctionClusterId" 
          [(value)]="auctionClusterId" 
          displayExpr="name" 
          [rtlEnabled]="rtlEnabled"
          [searchEnabled]="true"
          [showClearButton]="false"
          placeholder="{{ 'SELECT' | translate }} ..."
          (onValueChanged)="onAuctionClusterChange()"
          cssClass="filter-lot-select">
        </dx-select-box>
      </div>
      <div class="filter-column">
        <label for="catalogs">{{ 'BUYER.SUPPLY_MATRIX.CATALOGS' | translate }}</label>
        <dx-select-box 
          name="catalog" 
          [items]="catalogs" 
          valueExpr="catalogId" 
          [(value)]="catalogId" 
          displayExpr="name" 
          [rtlEnabled]="rtlEnabled"
          [searchEnabled]="true"
          [showClearButton]="false"
          [disabled]="auctionClusterId == null"
          placeholder="{{ 'SELECT' | translate }} ..."
          (onValueChanged)="onCatalogChange()"
          cssClass="filter-lot-select">
        </dx-select-box>
      </div>
      <div class="filter-column">
        <label for="catalog">{{ 'BUYER.SUPPLY_MATRIX.CLOCKS' | translate }}</label>
        <dx-select-box 
          name="clocks" 
          [items]="clocks" 
          valueExpr="clockId" 
          [(value)]="clockId" 
          displayExpr="name" 
          [disabled]="catalogId == null"
          [rtlEnabled]="rtlEnabled"
          [searchEnabled]="true"
          [showClearButton]="false"
          placeholder="{{ 'SELECT' | translate }} ..."
          (onValueChanged)="onClockChange()"
          cssClass="filter-lot-select">
        </dx-select-box>
      </div>
      <div class="filter-column">
        <label>{{ 'BUYER.SUPPLY_MATRIX.DATE' | translate }}</label>
        <label for="date" aria-haspopup="true" role="tooltip" class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm">
          <long-date-component class="dt-input"
                               [name]="date"
                               [data]="date"
                               [required]="true"
                               (modelChanged)="dateChanged($event)">
          </long-date-component>
        </label>
      </div>
    </div>

    <div>
      <div *ngFor="let filter of filters; let i = index" class="filter-column">
        <!-- Date -->
        <div *ngIf="filter.propertyTypeId == 4">
          <label>{{ getTranslation(filter.name) }}</label>
          <dx-select-box 
            name="filter.{{filter.name}}" 
            [items]="dateFilterItems" 
            valueExpr="value" 
            [value]="getDateValue(filter.productPropertyId)" 
            displayExpr="name"
            [rtlEnabled]="rtlEnabled"
            [searchEnabled]="true"
            [showClearButton]="true"
            placeholder="{{ 'SELECT' | translate }} ..."
            cssClass="filter-lot-select"
            (onValueChanged)="clearDatePeriod(filter.productPropertyId); setValue(filter.productPropertyId, $event)">
          </dx-select-box>
          <div class="date-range-holder" *ngIf="dateFiltersCascadingDefinitions[filter.productPropertyId] == '3'">
            <dx-date-box
                type="date"
                displayFormat="shortdate"
                openOnFieldClick="true"
                [value]="dateValue1(filter.productPropertyId)"
                (onValueChanged)="handleDate1ValueChange(filter.productPropertyId, $event)">
            </dx-date-box>
            <dx-date-box
                type="date"
                openOnFieldClick="true"
                [disabled]="isDisabled(filter.productPropertyId)"
                displayFormat="shortdate"
                [min]="getMin(filter.productPropertyId)"
                [value]="dateValue2(filter.productPropertyId)"
                (onValueChanged)="handleDate2ValueChange(filter.productPropertyId, $event)">
            </dx-date-box>
          </div>
        </div>
        <!-- Master data value -->
        <div *ngIf="filter.propertyTypeId == 5">
          <label>
            {{ getTranslation(filter.name) }}
          </label>
          <dx-select-box 
              name="product{{i}}" 
              [items]="prepareData(i)" 
              valueExpr="value" 
              [(value)]="filterBindings[filter.productPropertyId]" 
              [displayExpr]="translateSelectBoxes"
              [rtlEnabled]="rtlEnabled"
              [searchEnabled]="true"
              [showClearButton]="true"
              placeholder="{{ 'SELECT' | translate }} ..."
              (onValueChanged)="onPropertyChanged(filter); clearLotPropertyValue(filter.productPropertyId, $event)"
              cssClass="filter-lot-select">
            </dx-select-box>
        </div>
      </div>
      <div class="filter-column" *ngIf="filters.length !== 0">
        <label></label>
        <button type="button" class="btn btn-icon btn-primary" (click)="openSaveAsNewUserReportTemplateDialog()">
          <i class="dx-icon-plus"></i>{{ 'SAVE_AS' | translate }}
        </button>
      </div>
      <div class="filter-column" *ngIf="filters.length !== 0">
        <label></label>
        <button type="button" class="btn btn-icon btn-primary" (click)="showLoadReportProfile()">
          <i class="dx-icon-refresh"></i>{{ 'REPORTING.USER_REPORT_TEMPLATE.LOAD_TEMPLATE' | translate }}
        </button>
      </div>
    </div>

    <div class="filter-column">
      <div class="filter-button">
        <button type="button"
                [disabled]="auctionClusterId == null || catalogId == null || date == null"
                class="btn btn-primary" (click)="filterData()">
          {{ 'BUYER.SUPPLY_MATRIX.FILTER' | translate }}
        </button>
      </div>
    </div>
    <div class="last-refresh-column">
      <span class="last-refresh-text" *ngIf="lastRefresh !== ''">
        {{ 'BUYER.SUPPLY_MATRIX.LAST_REFRESH' | translate }} {{ lastRefresh }}
      </span>
    </div>
  </div>
  <div class="no-results" *ngIf="articlesNewAxisOne.length === 0 && suppliersNew.length === 0 && articlesNewAxisTwo.length === 0">
    {{ 'BUYER.SUPPLY_MATRIX.NO_RESULTS' | translate }}
  </div>
  <table class="table table-vertical" *ngIf="articlesNewAxisOne.length > 0 && (suppliersNew.length > 0 || articlesNewAxisTwo.length > 0) && !reversed">
    <tbody>
      <tr>
        <th>
          <div class="switch-holder">
            <button class="btn btn-icon btn-primary" (click)="reversed = true;"><clr-icon shape="switch"></clr-icon></button>
          </div>
        </th>
        <th>
          <div class="cell-content">{{ 'BUYER.SUPPLY_MATRIX.TOTAL' | translate }}</div>
        </th>
        <ng-container *ngIf="suppliersNew.length > 0">        
          <th *ngFor="let s of suppliersNew">
            <div class="cell-content">
              {{ s.supplierName }}
            </div>
          </th>
        </ng-container>         
        <ng-container *ngIf="articlesNewAxisTwo.length > 0">        
          <th *ngFor="let s of articlesNewAxisTwo">
            <div class="cell-content">
              {{ s.groupSuppliesTranslated }}
            </div>
          </th>   
        </ng-container>              
      </tr>
      <tr>
        <td>
          <div class="cell-content">{{ 'BUYER.SUPPLY_MATRIX.TOTAL' | translate }}</div>
        </td>
        <td class="grid-cell__number">{{ getTotalSumNew() | format }}</td>
        <ng-container *ngIf="suppliersNew.length > 0">        
          <td *ngFor="let s of suppliersNew; let supplierIndex = index"
              class="grid-cell__number">
            {{ getSumSupplierNew(s.supplierId) | format }}
          </td>
        </ng-container>             
        <ng-container *ngIf="articlesNewAxisTwo.length > 0">        
          <td *ngFor="let s of articlesNewAxisTwo; let articlesNewAxisTwoIndex = index"
            class="grid-cell__number">
          {{ getSumGroupNewAxisTwo(s.articleId) | format }}
          </td> 
        </ng-container>             
      </tr>
      <tr *ngFor="let a of articlesNewAxisOne; let groupIndex = index">
        <td>
          <div class="cell-content">
            {{ a.groupSuppliesTranslated }}
          </div>
        </td>
        <td class="grid-cell__number">{{ getSumGroupNewAxisOne(a.articleId) | format }}</td>
        <ng-container *ngIf="suppliersNew.length > 0">        
          <td *ngFor="let s of suppliersNew; let supplierIndex = index"
              class="grid-cell__number"
              [class]="matrixNew && matrixNew[supplierIndex][groupIndex] && matrixNew[supplierIndex][groupIndex].amount < 0  ? 'empty-cell' : 'default-cell'"
              [ngClass]="{'has-prebid': matrixNew && matrixNew[supplierIndex][groupIndex] && matrixNew[supplierIndex][groupIndex].hasMarkOrPrebid, 'forecast': matrixNew && matrixNew[supplierIndex][groupIndex] && matrixNew[supplierIndex][groupIndex].isForecast}">     
            <a *ngIf="matrixNew && matrixNew[supplierIndex][groupIndex] && matrixNew[supplierIndex][groupIndex].amount > -1 && hasBuyOnClockPermission() && !matrixNew[supplierIndex][groupIndex].isForecast && prebidsEnabled"
              class="open-prebid-link"
              (click)="openPrebidPopupEditorNew(s.supplierId, a.articleId)">
              {{ matrixNew[supplierIndex][groupIndex].amount | format }}
            </a>
            <ng-container *ngIf="matrixNew && matrixNew[supplierIndex][groupIndex] && matrixNew[supplierIndex][groupIndex].amount > -1 && (!hasBuyOnClockPermission() || matrixNew[supplierIndex][groupIndex].isForecast || !prebidsEnabled)">
              {{ matrixNew[supplierIndex][groupIndex].amount | format }}
            </ng-container>
            <ng-container *ngIf="!matrixNew || !matrixNew[supplierIndex][groupIndex] || matrixNew[supplierIndex][groupIndex].amount === -1">
              -
            </ng-container>
          </td>
        </ng-container>      
        <ng-container *ngIf="articlesNewAxisTwo.length > 0">        
          <td *ngFor="let s of articlesNewAxisTwo; let supplierIndex = index"
              class="grid-cell__number"
              [class]="matrixNew && matrixNew[supplierIndex][groupIndex] && matrixNew[supplierIndex][groupIndex].amount < 0  ? 'empty-cell' : 'default-cell'"
              [ngClass]="{'has-prebid': matrixNew && matrixNew[supplierIndex][groupIndex] && matrixNew[supplierIndex][groupIndex].hasMarkOrPrebid, 'forecast': matrixNew && matrixNew[supplierIndex][groupIndex] && matrixNew[supplierIndex][groupIndex].isForecast}">     
            <a *ngIf="matrixNew && matrixNew[supplierIndex][groupIndex] && matrixNew[supplierIndex][groupIndex].amount > -1 && hasBuyOnClockPermission() && !matrixNew[supplierIndex][groupIndex].isForecast && prebidsEnabled"
              class="open-prebid-link"
              (click)="openPrebidPopupEditorNew(s.supplierId, a.articleId)">
              {{ matrixNew[supplierIndex][groupIndex].amount | format }}
            </a>
            <ng-container *ngIf="matrixNew && matrixNew[supplierIndex][groupIndex] && matrixNew[supplierIndex][groupIndex].amount > -1 && (!hasBuyOnClockPermission() || matrixNew[supplierIndex][groupIndex].isForecast || !prebidsEnabled)">
              {{ matrixNew[supplierIndex][groupIndex].amount | format }}
            </ng-container>
            <ng-container *ngIf="!matrixNew || !matrixNew[supplierIndex][groupIndex] || matrixNew[supplierIndex][groupIndex].amount === -1">
              -
            </ng-container>
          </td>
      </ng-container>                
      </tr>
    </tbody>
  </table>

  <table class="table table-vertical" *ngIf="articlesNewAxisOne.length > 0 && (suppliersNew.length > 0 || articlesNewAxisTwo.length > 0) && reversed">
    <tbody>
      <tr>
        <th>
          <div class="switch-holder">
            <button class="btn btn-icon btn-primary" (click)="reversed = false;"><clr-icon shape="switch"></clr-icon></button>
          </div>
        </th>
        <th>
          <div class="cell-content">{{ 'BUYER.SUPPLY_MATRIX.TOTAL' | translate }}</div>
        </th>
        <th *ngFor="let a of articlesNewAxisOne">
          <div class="cell-content">
            {{ a.groupSuppliesTranslated }}
          </div>
        </th>
      </tr>
      <tr>
        <td>
          <div class="cell-content">{{ 'BUYER.SUPPLY_MATRIX.TOTAL' | translate }}</div>
        </td>
        <td class="grid-cell__number">{{ getTotalSumNew() | format }}</td>
        <td *ngFor="let a of articlesNewAxisOne; let groupIndex = index"
            class="grid-cell__number">
          {{ getSumGroupNewAxisOne(a.articleId) | format }}
        </td>
      </tr>
      <ng-container *ngIf="suppliersNew.length > 0">         
        <tr *ngFor="let s of suppliersNew; let supplierIndex = index">
          <td>
            <div class="cell-content">
              {{ s.supplierName }}
            </div>
          </td>
          <td class="grid-cell__number">{{ getSumSupplierNew(s.supplierId) | format }}</td>
          <td *ngFor="let a of articlesNewAxisOne; let groupIndex = index"
              class="grid-cell__number"
              [class]="matrixNew && matrixNew[supplierIndex][groupIndex] && matrixNew[supplierIndex][groupIndex].amount < 0  ? 'empty-cell' : 'default-cell'"
              [ngClass]="{'has-prebid': matrixNew && matrixNew[supplierIndex][groupIndex] && matrixNew[supplierIndex][groupIndex].hasMarkOrPrebid, 'forecast': matrixNew && matrixNew[supplierIndex][groupIndex] && matrixNew[supplierIndex][groupIndex].isForecast}">
            <a *ngIf="matrixNew && matrixNew[supplierIndex][groupIndex] && matrixNew[supplierIndex][groupIndex].amount > -1 && hasBuyOnClockPermission() && !matrixNew[supplierIndex][groupIndex].isForecast && prebidsEnabled"
              class="open-prebid-link"
              (click)="openPrebidPopupEditorNew(s.supplierId, a.articleId)">
              {{matrixNew[supplierIndex][groupIndex].amount | format}}
            </a>
            <ng-container *ngIf="matrixNew && matrixNew[supplierIndex][groupIndex] && matrixNew[supplierIndex][groupIndex].amount > -1 && (!hasBuyOnClockPermission() || matrixNew[supplierIndex][groupIndex].isForecast || !prebidsEnabled)">
              {{ matrixNew[supplierIndex][groupIndex].amount | format }}
            </ng-container>
            <ng-container *ngIf="!matrixNew || !matrixNew[supplierIndex][groupIndex] || matrixNew[supplierIndex][groupIndex].amount === -1">
              -
            </ng-container>
          </td>
        </tr>
      </ng-container>  
      <ng-container *ngIf="articlesNewAxisTwo.length > 0">        
        <tr *ngFor="let s of articlesNewAxisTwo; let supplierIndex = index">
          <td>
            <div class="cell-content">
              {{ s.supplierName }}
            </div>
          </td>
          <td class="grid-cell__number">{{ getSumGroupNewAxisTwo(s.articleId) | format }}</td>
          <td *ngFor="let a of articlesNewAxisOne; let groupIndex = index"
              class="grid-cell__number"
              [class]="matrixNew && matrixNew[supplierIndex][groupIndex] && matrixNew[supplierIndex][groupIndex].amount < 0  ? 'empty-cell' : 'default-cell'"
              [ngClass]="{'has-prebid': matrixNew && matrixNew[supplierIndex][groupIndex] && matrixNew[supplierIndex][groupIndex].hasMarkOrPrebid, 'forecast': matrixNew && matrixNew[supplierIndex][groupIndex] && matrixNew[supplierIndex][groupIndex].isForecast}">
            <a *ngIf="matrixNew && matrixNew[supplierIndex][groupIndex] && matrixNew[supplierIndex][groupIndex].amount > -1 && hasBuyOnClockPermission() && !matrixNew[supplierIndex][groupIndex].isForecast && prebidsEnabled"
              class="open-prebid-link"
              (click)="openPrebidPopupEditorNew(s.articleId, a.articleId)">
              {{matrixNew[supplierIndex][groupIndex].amount | format}}
            </a>
            <ng-container *ngIf="matrixNew && matrixNew[supplierIndex][groupIndex] && matrixNew[supplierIndex][groupIndex].amount > -1 && (!hasBuyOnClockPermission() || matrixNew[supplierIndex][groupIndex].isForecast || !prebidsEnabled)">
              {{ matrixNew[supplierIndex][groupIndex].amount | format }}
            </ng-container>
            <ng-container *ngIf="!matrixNew || !matrixNew[supplierIndex][groupIndex] || matrixNew[supplierIndex][groupIndex].amount === -1">
              -
            </ng-container>
          </td>
        </tr>    
      </ng-container>         
    </tbody>
  </table>

  <clr-modal [(clrModalOpen)]="isSaveProfileDialogOpened" class="report-template-editor">
    <h3 class="modal-title">{{ 'REPORTING.USER_REPORT_TEMPLATE.TITLE' | translate }}</h3>
    <div class="modal-body">
      <form #detailsForm="ngForm">
        <div class="form-group">
          <label class="required">{{ 'REPORTING.USER_REPORT_TEMPLATE.NAME' | translate }}:</label>
          <label for="newReportName" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm"
                 [ngClass]="{'invalid': name.dirty && name.invalid}">
            <input type="text" name="newReportName" id="newReportName" placeholder="{{ 'REPORTING.USER_REPORT_TEMPLATE.NAME' | translate }}"
                   [(ngModel)]="newReportName" #name="ngModel" required />
            <span class="tooltip-content">{{ 'REPORTING.USER_REPORT_TEMPLATE.NAME_VALIDATION' | translate }}</span>
          </label>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" (click)="saveNewReport();"
              [disabled]="!detailsForm.form.valid">
        {{ 'SAVE' | translate }}
      </button>
      <button type="button" class="btn btn-outline"
              (click)="closeSaveAsNewUserReportTemplateDialog();">
        {{ 'CANCEL' | translate }}
      </button>
    </div>
  </clr-modal>

  <clr-modal [(clrModalOpen)]="isLoadProfileDialogOpened" class="report-template-selector">
    <h3 class="modal-title">{{ 'REPORTING.USER_REPORT_TEMPLATE.LOAD_TEMPLATE' | translate }}</h3>
    <div class="modal-body">
      <form #detailsFormTemplate="ngForm">
        <div class="form-group">
          <label>{{ 'REPORTING.USER_REPORT_TEMPLATE.MANAGER_TITLE' | translate }}:</label>
          <label for="name" aria-haspopup="true" role="tooltip"
                 class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm"
                 [ngClass]="{'invalid': name.dirty && name.invalid}">
            <ng-select [searchable]="false"
                       name="reportId"
                       [clearable]="false"
                       class="filter-select"
                       [attr.name]="reportId"
                       [(ngModel)]="reportId"
                       [items]="allReports"
                       [virtualScroll]="true"
                       bindLabel="description"
                       bindValue="reportId">
            </ng-select>
          </label>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" (click)="reportTemplateChange();"
              [disabled]="!detailsFormTemplate.form.valid">
        {{ 'SELECT' | translate }}
      </button>
      <button type="button" class="btn btn-outline"
              (click)="closeLoadReportProfile();">
        {{ 'CANCEL' | translate }}
      </button>
    </div>
  </clr-modal>
</div>

<buyer-supply-matrix-lots-component #lots (onClosed)="onLotsClosed()"></buyer-supply-matrix-lots-component>
