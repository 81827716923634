import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { WebApiService } from '../../../shared/services/web-api.service';
import { AuctionCluster } from '../../../platform/shared/models/auction-cluster';
import { IAuctionClusterService } from '../../../shared/interfaces/auction-cluster';

@Injectable()
export class AuctionClusterService implements IAuctionClusterService {

  private apiPath: string;

  private buyerId: number;

  setId(buyerId: number)
  {
    this.buyerId = buyerId;
  }


  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'buyerlevel';
  }
  
  getAuctionClusters(buyerId: number): Observable<Array<AuctionCluster>> {
    return this.webApiService.getList(this.apiPath + '/' + buyerId + '/auctioncluster');
  }

  getAuctionClustersPerLevel(): Observable<Array<AuctionCluster>> {
    return this.webApiService.getList(this.apiPath + '/' + this.buyerId + '/auctioncluster');
  }
}
