import { Component, ViewChild, OnInit, Injector, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

// general services
import { LanguageService } from '../../shared/services/language.service';


// components
import { SimpleListComponent } from '../../shared/components/simple-list/simple-list.component';
import { CalculationsFieldComponent } from './calculations-field.component';

// services
import { MasterDataService } from '../shared/services/master-data.service';

// models
import { ProductProperty, GridProperty, ProductPropertyTypes } from '../shared/models/product';
import { MasterData, MasterDataField } from '../shared/models/master-data';

@Component({
  selector: 'calculations-fields-component',
  templateUrl: 'calculations-fields.component.html',
  styleUrls: ['./calculations-fields.component.scss']
})
export class CalculationsFieldsComponent extends SimpleListComponent implements OnInit, OnDestroy {

  editingIndex: number;
  masterData: Array<MasterData> = [];
  pageSize = 50;
  items: Array<GridProperty> = [];
  productProperties: Array<ProductProperty> = [];
  propertyTypes: any = ProductPropertyTypes;
  isSupplyData: boolean;
  auctionClusterId: number;
  translationSelectBoxes: any;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  @Input('data')
  set data(value: Array<GridProperty>) {
    this.items = value;
    this.matchProductPropertiesNames();
    this.matchMasterDataFieldNames();
    this.matchTypeNames();
  }

  @Input('productProperties')
  set productProperty(value: Array<ProductProperty>) {
    this.productProperties = value;
    this.matchProductPropertiesNames();
    this.translateProperties();
    this.matchTypeNames();
    this.matchMasterDataFieldNames();
  }

  @Input('masterData')
  set masterDataList(value: Array<MasterData>) {
    this.masterData = value;
    this.matchMasterDataFieldNames();
  }

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onDataChanged = new EventEmitter<Array<GridProperty>>();

  @ViewChild(CalculationsFieldComponent) detailsComponent: CalculationsFieldComponent;

  constructor(
    protected injector: Injector,
    protected languageService: LanguageService,
    private masterDataService: MasterDataService,
    private route: ActivatedRoute
  ) {
    super(injector);
    this.auctionClusterId = +this.route.snapshot.params['id'];
    this.propertyTypes = Object.keys(this.propertyTypes).filter(f => !isNaN(Number(f))).map(key => ({ name: this.propertyTypes[key], value: Number(key) })); // tslint:disable-line:max-line-length
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
    this.translate.get([
      'NUMBER',
      'DECIMAL',
      'TEXT',
      'DATE',
      'MASTER_DATA_VALUE',
      'IMAGE',
      'BOOLEAN',
      'AUTO_INCREMENT',
      'MATH',
      'CONCATENATE',
      'ADD',
      'SUBSTRACT',
      'MULTIPLY',
      'DIVIDE_ROUND',
      'DIVIDE_FLOOR',
      'DIVIDE_CEILING',
    ]).subscribe((res: string) => {
      this.translationSelectBoxes = res;
    });
  }

  ngOnInit() { // tslint:disable-line:no-empty
    this.setTranslations('PRODUCT');
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  edit(property: GridProperty, event: Event) {
    this.editingIndex = this.items.indexOf(property);
    event.stopPropagation();
    this.detailsComponent.modalTitle = this.translations.PROPERTY.EDIT;
    const clone = { ...property };
    this.detailsComponent.open(this.items, clone, this.productProperties, this.masterData);
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.PROPERTY.ADD_NEW;
    this.detailsComponent.open(this.items, null, this.productProperties, this.masterData);
  }

  onProductPropertyAdded(property: GridProperty) {
    this.items.push(property);
    this.matchProductPropertiesNames();
    this.translateProperties();
    this.matchTypeNames();
    this.matchMasterDataFieldNames();
    this.onDataChanged.emit(this.items);
  }

  onProductPropertyUpdated(property: GridProperty) {
    this.items[this.editingIndex] = property;
    this.matchProductPropertiesNames();
    this.translateProperties();
    this.matchTypeNames();
    this.matchMasterDataFieldNames();
    this.onDataChanged.emit(this.items);
  }

  delete(property: GridProperty) {
    this.items.splice(this.items.indexOf(property), 1);
    this.onDataChanged.emit();
  }

  private matchTypeNames() {
    this.items.forEach(item => {
      const prop = this.productProperties.find(f => f.productPropertyId === item.productPropertyId);
      if (prop) {
        const propertyType = this.propertyTypes.find(f => f.value === prop.propertyTypeId);
        if (propertyType)
          item.productPropertyTypeName = propertyType.name;
      }
    });
  }

  private matchProductPropertiesNames() {
    this.items.forEach(property => {
      const prop = this.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (prop) {
        property.productPropertyName = prop.name;
      }
    });
  }

  private translateProperties() {
    this.items.forEach(property => {
      property.productPropertyName = this.languageService.getTranslatableText(property.productPropertyName);
    });
  }

  matchMasterDataFieldNames() {
    this.items.forEach(item => {
      const prop = this.productProperties.find(pp => pp.productPropertyId === item.productPropertyId);
      if (prop && prop.masterDataListId) {
        let masterDataFields: Array<MasterDataField> = [];
        const result = this.masterData.find(md => md.masterDataListId === prop.masterDataListId);
        if (result) {
          masterDataFields = result.fields;
          const masterDataField = masterDataFields.find(mdf => mdf.masterDataListFieldId === item.masterDataListFieldId);
          if (masterDataField) {
            item.masterDataFieldName = masterDataField.name;
          }
        } else {
          item.masterDataFieldName = ' - ';
        }
      } else {
        item.masterDataFieldName = ' - ';
      }
    });
  }

  translateSelectBoxes = (item: any) => {
    if (item) {
      const label = this.translationSelectBoxes[item.value];
      return label;
    }
  }

  translateFields = (item: any) => {
    if (item) {
      const label = this.getTranslation(item.value);
      return label;
    }
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  editItem = (e: any) => {
    this.edit(e.row.data, new Event('click'));
  }

  openEditClick = (e: any) => {
    this.edit(e.data, new Event('click'));
  }

  deleteItem = (e: any) => {
    this.delete(e.row.data);
  }
}
