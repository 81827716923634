import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuctionClusterPermission } from '../models/auction-cluster-permission';

import { ApplicationSettings } from '../../../shared/models/application-settings';
import { WebApiService } from '../../../shared/services/web-api.service';

@Injectable()
export class AuctionClusterPermissionService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'auctioncluster';
  }

  getAuctionClusterPermissions(auctionClusterId: number): Observable<Array<AuctionClusterPermission>> {
    return this.webApiService.getList(this.apiPath + '/' + auctionClusterId + '/auctionclusterpermission');
  }
}
