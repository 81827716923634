import { Component, OnInit, Injector, ViewChild, ElementRef, OnDestroy } from '@angular/core';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';

// services
import { AuctionClusterSupplierService } from '../shared/services/auction-cluster.supplier.service';
import { LanguageService } from '../../shared/services/language.service';


// models
import { AuctionClusterSupplier } from '../shared/models/auction-cluster-supplier';
import { AuctionClusterUser } from '../shared/models/auction-cluster-user';

import { Subscription } from 'rxjs';
import { DxPopupComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'supplier-component',
  templateUrl: 'supplier.component.html',
  styleUrls: ['./supplier.component.scss']
})
export class SupplierComponent extends ItemDetailsComponent<AuctionClusterSupplier> implements OnInit, OnDestroy {
  assignAdminUser = false;
  auctionClusterId: number;
  vatValue: string;
  filteredSuppliers: Array<AuctionClusterSupplier> = [];
  vatValid = true;
  users = [];

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  @ViewChild('vat', { read: ElementRef }) vatInput: any;
  @ViewChild(DxPopupComponent) popup: DxPopupComponent;

  constructor(protected injector: Injector, private languageService: LanguageService, private dataService: AuctionClusterSupplierService) {
    super(injector);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.model = new AuctionClusterSupplier();
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  open(suppliers: Array<AuctionClusterSupplier>, id: number, auctionClusterId: number) {
    this.allItems = suppliers;
    this.auctionClusterId = auctionClusterId;

    if (id != null) {
      this.isEditMode = true;
      this.spinner.show();
      this.dataService.getSupplier(this.auctionClusterId, id)
        .subscribe((res: AuctionClusterSupplier) => {
          this.model = res;
          this.isOpened = true;
          this.spinner.hide();
        },
          error => {
            notify(error.message, 'error', 5000);
            this.spinner.hide();
          });
    } else {
      this.model = new AuctionClusterSupplier();
      this.model.isActive = true;
      this.isEditMode = false;
      this.isOpened = true;
    }

    // setTimeout(() => { this.vatInput.nativeElement.focus(); });
  }

  save() {
    this.spinner.show();
    if (this.model.supplierId) {
      this.dataService.edit(this.auctionClusterId, this.model)
      .subscribe((res: any) => {
        this.model = new AuctionClusterSupplier();
        // this.detailsForm.reset();
        this.close(true);
        this.errorMessage = null;
        this.spinner.hide();
      },
        error => {
          this.errorMessage = this.errorService.toString(error);
          notify(this.errorMessage, 'error', 5000);
          this.spinner.hide();
        });
    } else {
      this.dataService.save(this.auctionClusterId, this.model)
      .subscribe((res: any) => {
        this.model = new AuctionClusterSupplier();
        // this.detailsForm.reset();
        this.close(true);
        this.errorMessage = null;
        this.spinner.hide();
      },
        error => {
          this.errorMessage = this.errorService.toString(error);
          notify(this.errorMessage, 'error', 5000);
          this.spinner.hide();
        });
    }

  }

  // verifyVat(vat: string) {
  //   if (this.model.supplierId) {
  //     const currentItem = this.allItems.find(s => {
  //       return s.supplierId === this.model.supplierId;
  //     });
  //     if (currentItem) {
  //       if (currentItem.vat === vat) {
  //         this.vatValid = true;
  //         this.vatInvalid = false;
  //         return;
  //       }
  //     }
  //   } {
  //     this.dataService.checkVat(this.auctionClusterId, vat)
  //       .subscribe(ifExist => {
  //         if (!ifExist) {
  //           this.vatValid = true;
  //           this.vatInvalid = false;
  //           return;
  //         } else {
  //           this.vatInvalid = true;
  //           this.vatValid = false;
  //           return;
  //         }
  //       });
  //   }
  // }

  suppliersChange(event: any) {
    const supplierId = this.model.supplierId;

    if (supplierId) {
      const supplier = this.filteredSuppliers.find(b => b.supplierId === supplierId);

      if (supplier) {
        this.model.name = supplier.name;
        this.model.vat = supplier.vat;
        this.model.address1 = supplier.address1;
        this.model.address2 = supplier.address2;
        this.model.zip = supplier.zip;
        this.model.city = supplier.city;
        this.model.country = supplier.country;
        this.model.telNumber = supplier.telNumber;
        this.model.faxNumber = supplier.faxNumber;
        this.model.email = supplier.email;
        this.model.url = supplier.url;
        this.model.usedOnOtherAuctionCluster = supplier.usedOnOtherAuctionCluster;
      }
    }
  }

  searchByVat() {
    this.dataService.getByVat(this.auctionClusterId, this.vatValue).subscribe((suppliers: Array<AuctionClusterSupplier>) => {
      this.filteredSuppliers = suppliers;
    });
  }

  onFieldDataChanged(e: any) {
    if (e.component._isReady && e.component.NAME !== 'dxPopup') {
      const result = e.component.validate();
      if (result.brokenRules.length >= 1) {
        document.getElementsByName('btnSupplierSubmit')[0].setAttribute('disabled', 'disabled');
      } else {
        document.getElementsByName('btnSupplierSubmit')[0].removeAttribute('disabled');
      }
    } else {
      if (this.isEditMode) {
        document.getElementsByName('btnSupplierSubmit')[0].removeAttribute('disabled');
      }
    }
  }

}
