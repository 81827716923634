import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Auction } from '../../../auction/shared/models/auction';
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { WebApiService } from '../../../shared/services/web-api.service';
import { IAuctionService } from "../../../shared/interfaces/auction";

@Injectable()
export class AuctionService implements IAuctionService{

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi;
  }

  getAuctions(buyerId: number): Observable<Array<Auction>> {
    return this.webApiService.getList(this.apiPath + `buyerlevel/${ buyerId }` + '/auctioncluster/auction');
  }

  //IAuctionService

  private buyerId: number;

  setId(id: number) {
    this.buyerId = id;
  }

  getAuctionsForReports(auctionClusterId: number): Observable<Array<Auction>> {
    return this.webApiService.getList(this.apiPath + `buyerlevel/${this.buyerId}` + '/auctioncluster/auction');
  }

  
}
