import { Component, OnInit, Injector, ViewChild, OnDestroy } from '@angular/core';
import * as _moment from 'moment';
import { Moment } from 'moment';
import notify from 'devextreme/ui/notify';
import { forkJoin, Subscription } from 'rxjs';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

// general services
import { LanguageService } from '../../shared/services/language.service';
import { TokenService } from '../../shared/services/token.service';
import { DateTimeService } from '../../shared/services/datetime.service';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { SupplyCatalogComponent } from './supply-catalog.component';
import { CleanupConfirmationComponent } from '../shared/components/cleanup-confirmation.component';

// services
import { CatalogService } from '../shared/services/catalog.service';
import { AuctionClusterAuctionService } from '../shared/services/auction-cluster-auction.service';
import { ProductService } from '../shared/services/product.service';

// models
import { Catalog, MoveLot } from '../shared/models/catalog';
import { Auction } from '../shared/models/auction';
import { Product } from '../shared/models/product';
import { ImportSchema, ImportField } from '../../shared/import/import-schema';
import { AuctionClusterPermissionEnum } from '../../shared/models/user-permissions';
import { ConfirmationComponent } from '../../shared/components/confirmation/confirmation.component';
import { CookieService } from 'ngx-cookie-service';
import { Cookies } from '../../shared/constants/cookies';

@Component({
  selector: 'supply-catalogs-component',
  templateUrl: 'supply-catalogs.component.html',
  styleUrls: ['./supply-catalogs.component.scss']
})
export class SupplyCatalogsComponent extends FullListComponent<Catalog, SupplyCatalogComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: SupplyCatalogComponent;
  @ViewChild('confirmationCleanup') cleanupConfirmation: CleanupConfirmationComponent;
  @ViewChild('confirmationCleanupDialog') confirmationCleanupDialog: ConfirmationComponent;
  @ViewChild('confirmationEmptyBuffer') confirmationEmptyBuffer: ConfirmationComponent;
  @ViewChild('confirmationMoveLots') confirmationMoveLots: ConfirmationComponent;
  @ViewChild('cleanupDoneMessage') cleanupDoneMessage: ConfirmationComponent;

  auctions: Array<Auction> = [];
  products: Array<Product> = [];
  selectedAuction: number = -1;
  forecastCatalogs: Array<Catalog> = [];
  catalogToCleanup: Catalog;
  catalogCleanupDate = new Date();
  confirmationErrorMessage: string;
  cleanupRetry: number;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private dataService: CatalogService,
    private auctionService: AuctionClusterAuctionService,
    private productService: ProductService,
    private languageService: LanguageService,
    private tokenService: TokenService,
    private dateTimeService: DateTimeService,
    private cookieService: CookieService
  ) {
    super(injector, Catalog);
    this.title.set('AUCTION.SUPPLY_CATALOG_MANAGEMENT');
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.setTranslations('CATALOGS');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  // tslint:disable:no-magic-numbers
  getData() {
    this.spinner.show();

    forkJoin(
      this.dataService.getAllSupplyCatalogs(this.id),
      this.dataService.getAllForecastCatalogs(this.id),
      this.auctionService.getAuctionsForPermissions(this.id, [AuctionClusterPermissionEnum.SupplyCatalogManagement]),
      this.productService.getProducts(this.id),
    ).subscribe(result => {
      this.items = result[0];
      this.forecastCatalogs = result[1];
      this.auctions = result[2];
      this.products = result[3];
      this.spinner.hide();
      this.items.forEach(item => {
        item.auctionName = this.matchAuctionName(item.auctionId);
        item.productName = this.matchProductName(item.productId);
      });
    },
      error => {
        notify(error.message, 'error', 5000);
        this.spinner.hide();
      });
  }
  // tslint:enable:no-magic-numbers

  edit = (e: any) => {
    this.detailsComponent.modalTitle = this.translations.EDIT;
    this.detailsComponent.open(this.items, e.row.data.catalogId, this.forecastCatalogs, this.products, this.auctions, this.id);
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.catalogId;
    this.confirmation.opened = true;
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.ADD_NEW;
    this.detailsComponent.open(this.items, null, this.forecastCatalogs, this.products, this.auctions, this.id);
  }

  private matchProductName(productId: number) {
    const found = this.products.find(f => f.productId === productId);
    return found ? this.languageService.getTranslatableText(found.name) : '';
  }

  private matchAuctionName(auctionId: number) {
    const found = this.auctions.find(f => f.auctionId === auctionId);
    return found ? found.name : '';
  }

  getSelectedAuctions(e: any) {
    if (this.selectedAuction === null) {
      this.getData();
    } else {
      this.dataService.getAuctionCatalogs(this.selectedAuction).subscribe(catalogs => {
        this.items = catalogs;
        this.items.forEach(item => {
          item.auctionName = this.matchAuctionName(item.auctionId);
          item.productName = this.matchProductName(item.productId);
        });
      });
    }
  }

  deleteSelected() {
    this.spinner.show();
    const auctionId = this.items.find(f => f.catalogId === this.itemIdToDelete).auctionId;
    this.dataService.delete(auctionId, this.itemIdToDelete)
      .subscribe((catalogs: Array<Catalog>) => {
        this.getData();
        this.spinner.hide();
      },
        error => {
          notify(this.errorService.translations.DELETE_ERROR_MESSAGE, 'error', 5000);
          this.spinner.hide();
        });
  }

  isMasterDataSetup(catalogId) {
    const catalog = this.items.find(i => i.catalogId === catalogId);

    if (catalog) {
      const product = this.products.find(p => p.productId === catalog.productId);

      if (product) {
        return product.hasSupplyMasterDetails;
      }
    }


    return false;
  }

  openLots(catalogId: number, viewOnlyMode: boolean, productId: number) {
    let product = this.products.find(_ => _.productId == productId)

    if (product.enableDesktopScreenLayout && product.enableTouchScreenLayout) {
      let supplyEditorMode = this.cookieService.get(Cookies.SUPPLY_EDITOR_MODE);
      if (!supplyEditorMode) {
        this.cookieService.set(Cookies.SUPPLY_EDITOR_MODE, 'desktop');
      }
    } else {
      if (product.enableDesktopScreenLayout) {
        this.cookieService.set(Cookies.SUPPLY_EDITOR_MODE, 'desktop');
      } else {
        this.cookieService.set(Cookies.SUPPLY_EDITOR_MODE, 'touch');
      }
    }

    if (this.isMasterDataSetup(catalogId) && !viewOnlyMode) {
      this.router.navigate(['/auction/catalogs/' + this.id + '/masterdetails/' + catalogId]);
    } else {
      if (!viewOnlyMode)
        this.router.navigate(['/auction/catalogs/' + this.id + '/supplylots/' + catalogId]);
      else
        this.router.navigate(['/auction/catalogs/' + this.id + '/supplylots/' + catalogId], { queryParams: { viewOnlyMode: viewOnlyMode } });
    }
  }

  openAllLots = (e: any) => {
    this.openLots(e.row.data.catalogId, e.row.data.viewOnlyMode, e.row.data.productId);
  }

  openCleanupConfirmation = (e: any) => {
    const catalog = e.row.data;
    this.confirmationErrorMessage = null;

    this.catalogToCleanup = catalog;
    if (this.catalogToCleanup.demoCatalogId) {
      this.confirmationMoveLots.opened = true;
    } else {
      this.cleanupConfirmation.opened = true;
    }
  }

  catalogCleanupDateChanged(date: Date) {
    this.confirmationErrorMessage = null;
    this.catalogCleanupDate = date;
  }

  cleanupCatalog() {
    this.cleanupRetry = 3;
    this.confirmationErrorMessage = null;
    this.spinner.show();
    this.dataService.checkCleanup(this.catalogToCleanup.auctionId, this.catalogToCleanup.catalogId, this.catalogCleanupDate)
      .subscribe(res => {
        this.spinner.hide();
        if (res) {
          this.confirmationCleanupDialog.opened = true;
        } else {
          this.confirmationEmptyBuffer.opened = true;
        }
      }, error => {
        // error message show
        this.spinner.hide();


      });
  }

  doRetryingCleanup()
  {
    this.spinner.hide();
    this.confirmationErrorMessage = null;
    if (this.tokenService.permissionMet('AuctionPermissions.14', this.catalogToCleanup.auctionId)) {
      this.spinner.show();
      if (this.catalogToCleanup.isDemoCatalog) {
        this.dataService.resetCatalog(this.catalogToCleanup.auctionId, this.catalogToCleanup.catalogId, this.catalogCleanupDate)
          .subscribe((res: any) => {
            this.spinner.hide();
            this.cleanupDoneMessage.opened = true;
          });
      }
      else {
        this.dataService.cleanupCatalog(this.catalogToCleanup.auctionId, this.catalogToCleanup.catalogId, this.catalogCleanupDate)
          .subscribe((res: any) => {
            this.spinner.hide();
            this.cleanupDoneMessage.opened = true;
          },
          error => {
            this.cleanupRetry--;
            if(this.cleanupRetry > 0)
            {
              setTimeout(() => {
                this.doRetryingCleanup();
              }, 1000);
            }
            else
            {
              this.spinner.hide();
                if (error.error && error.error.length > 0 && error.error === 'MISSING_TRANSACTIONS_TRY_AGAIN')
                {
                  let errorMessage = this.translations['CLEANUP_TRANSACTIONS_MISSING'];
                  notify(errorMessage, 'error', 5000);
                }

            }
          });
      }
    }

    this.cleanupConfirmation.opened = false;
  }

  cleanupConfirmed() {
    this.cleanupRetry = 3;
    this.doRetryingCleanup();
  }

  emptyBufferConfirmed() {
    this.confirmationErrorMessage = null;
  if (this.tokenService.permissionMet('AuctionPermissions.14', this.catalogToCleanup.auctionId)) {
    this.spinner.show();
      this.dataService.emptyTransactionBuffer(this.catalogToCleanup.auctionId, this.catalogToCleanup.catalogId, this.catalogCleanupDate)
        .subscribe((res: any) => {
          this.spinner.hide();
          if (res) {
            this.confirmationCleanupDialog.opened = true;
          } else {
            this.confirmationErrorMessage = this.translations['CLEANUP_EMPTY_BUFFER_ERROR'];
            this.confirmationCleanupDialog.opened = true;
          }
        });
    }

    this.cleanupConfirmation.opened = false;
}

  emptyBufferCanceled() {
    this.confirmationCleanupDialog.opened = true;
  }

  moveLotsConfirmed() {
    this.confirmationErrorMessage = null;
    if (this.tokenService.permissionMet('AuctionPermissions.14', this.catalogToCleanup.auctionId)) {
      this.spinner.show();

      forkJoin(
        this.dataService.cleanupCatalog(this.catalogToCleanup.auctionId, this.catalogToCleanup.demoCatalogId, this.catalogCleanupDate),
        this.dataService.resetCatalog(this.catalogToCleanup.auctionId, this.catalogToCleanup.catalogId, this.catalogCleanupDate)
      ).subscribe(result => {
        this.dataService.moveLots(this.catalogToCleanup.auctionId, this.catalogToCleanup.catalogId, this.catalogToCleanup.demoCatalogId, new MoveLot())
          .subscribe((res: any) => {
            this.spinner.hide();
            this.cleanupConfirmation.opened = true;
          });
      },
        error => {
          notify(error.message, 'error', 5000);
          this.spinner.hide();
        });
    }

    this.cleanupConfirmation.opened = false;
  }

  moveLotsCanceled() {
    this.cleanupConfirmation.opened = true;
  }

  getDate() {
    return this.dateTimeService.getDateStringByFormatAny(moment(this.catalogCleanupDate), 14);
  }

  createDataSchema() {
    const schema = new ImportSchema();

    const name = new ImportField('name', this.translations.NAME);
    name.required = true;

    const auctionName = new ImportField('auctionName', this.translations.AUCTION);
    auctionName.required = true;

    const description = new ImportField('description', this.translations.DESCRIPTION);
    description.required = true;

    const productName = new ImportField('productName', this.translations.PRODUCT);
    productName.required = true;

    schema.fields.push(name, auctionName, description, productName );

    this.schema = schema;
  }

  permissionMet = (e: any) => {
    const requiredPermission = 'AuctionPermissions.14';
    return this.tokenService.permissionMet(requiredPermission, e.row.data.auctionId) && !e.row.data.viewOnlyMode;
  }

  rowClick = (e: any) => {
    this.openLots(e.data.catalogId, e.data.viewOnlyMode, e.data.productId);
  }

  copy = (e: any) => {

    this.spinner.show();

    this.dataService.copy(e.row.data.auctionId, e.row.data.catalogId)
      .subscribe((res: any) => {
        this.getData();
        this.spinner.hide();

      },
        error => {
          notify(this.errorService.toString(error), 'error', 5000);
          this.spinner.hide();
        });

  }
}
