import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../models/user';

import { ApplicationSettings } from '../../../shared/models/application-settings';
import { AuthService } from '../../../shared/services/auth.service';
import { WebApiService } from '../../../shared/services/web-api.service';
import { UserFilter } from '../models/user-filter';

@Injectable()
export class UserService {

  private adminApiPath: string;
  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private authService: AuthService, private webApiService: WebApiService) {
    this.adminApiPath = this.appSettings.adminApi;
    this.apiPath = this.appSettings.adminApi + 'user';
  }

  getUsers(): Observable<Array<User>> {
    return this.webApiService.getList(this.apiPath);
  }

  private appendToUrl(url: string, field: string, value: any): string {

    if (url) {
      url += `&${field}=${value}`;
    } else {
      url = `?${field}=${value}`;
    }
    return url;
  }

  getUsersFiltered(userFilter: UserFilter): Observable<User> {

    let url: string;

    // build url on applied filter:
    if (userFilter.showPlatform) {
      url = this.appendToUrl(url, 'ShowPlatform', userFilter.showPlatform);
    }
    if (userFilter.showAuction) {
      url = this.appendToUrl(url, 'ShowAuction', userFilter.showAuction);
      if (userFilter.auctionClusterId || userFilter.auctionClusterId === null) {
        url = this.appendToUrl(url, 'AuctionClusterId', (userFilter.auctionClusterId === null ? 'null' : userFilter.auctionClusterId));
      }
    }
    if (userFilter.showBuyer) {
      url = this.appendToUrl(url, 'ShowBuyer', userFilter.showBuyer);
      if (userFilter.buyerId || userFilter.buyerId === null) {
        url = this.appendToUrl(url, 'BuyerId', (userFilter.buyerId === null ? 'null' : userFilter.buyerId));
      }
    }
    if (userFilter.showSupplier) {
      url = this.appendToUrl(url, 'ShowSupplier', userFilter.showSupplier);
      if (userFilter.supplierId || userFilter.supplierId === null) {
        url = this.appendToUrl(url, 'SupplierId', (userFilter.supplierId === null ? 'null' : userFilter.supplierId));
      }
    }
    if (userFilter.showActive !== null) {
      url = this.appendToUrl(url, 'ShowActive', userFilter.showActive);
    }
    if (userFilter.showInactive !== null) {
      url = this.appendToUrl(url, 'ShowInactive', userFilter.showInactive);
    }
    if (userFilter.showBlocked !== null) {
      url = this.appendToUrl(url, 'ShowBlocked', userFilter.showBlocked);
    }
    if (userFilter.showPending !== null) {
      url = this.appendToUrl(url, 'ShowPending', userFilter.showPending);
    }

    return this.webApiService.getList(this.apiPath + '/filter' + url);
  }

  getUser(userId: number): Observable<User> {
    return this.webApiService.get(this.apiPath, userId);
  }

  save(user: User): Observable<any> {
    return this.webApiService.save<User>(this.apiPath, user);
  }

  edit(user: User) {
    return this.webApiService.edit<User>(this.apiPath, user.userId, user);
  }

  enableDisable(user: User): Observable<User> {
    if (user.isActive) {
      return this.webApiService.enableDisable(this.apiPath + '/disable', user.userId);
    } else {
      return this.webApiService.enableDisable(this.apiPath + '/enable', user.userId);
    }
  }

  blockUnblock(user: User): Observable<User> {
    if (user.isBlocked) {
      return this.webApiService.enableDisable(this.apiPath + '/unblock', user.userId);
    } else {
      return this.webApiService.enableDisable(this.apiPath + '/block', user.userId);
    }
  }

  delete(userId: number): Observable<any> {
    return this.webApiService.delete(this.apiPath, userId);
  }
}
