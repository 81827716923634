import { Component, ViewEncapsulation } from '@angular/core';

import { AuthService } from './shared/services/auth.service';
import { LanguagePreferenceService } from './shared/services/language-preference.service';
import { Title } from '@angular/platform-browser';
import { locale } from 'devextreme/localization';
import { NotificationService } from './shared/services/notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [
    './app.component.scss',
    '../../node_modules/jquery-ui/themes/base/all.css',
    '../../node_modules/devextreme/dist/css/dx.common.css',
    '../../node_modules/devextreme/dist/css/dx.light.css',
    '../../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.common.css',
    '../../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.light.css',
    '../../node_modules/devexpress-reporting/dist/css/dx-webdocumentviewer.css'
  ],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {

  constructor(
    languagePreferenceService: LanguagePreferenceService,
    titleService: Title,
    notificationService: NotificationService
  ) {
    languagePreferenceService.configure();
    // devexpress localization
    locale(localStorage.getItem('last-selected-language-code'));

    if (window.location.href.includes('efice')) {
      titleService.setTitle("Efice");
    }
  }
} 