<div class="card">
  <div class="card-header">
    {{ 'REGISTER.PROFILE' | translate }}
  </div>
  <div class="card-block">
    <form #registerProfileForm="ngForm">
      <div class="card-block">
        <section class="form-block">
          <div class="form-group" id="outter-div">
            <label class="required" id="label">{{ 'USER.FIRST_NAME' | translate }}:</label>
            <label for="firstname" aria-haspopup="true" role="tooltip" class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm" [ngClass]="{'invalid': firstname.dirty && firstname.invalid}">
              <input type="text" name="firstname" class="inner-input" id="firstname" placeholder="{{ 'USER.FIRST_NAME' | translate }}" [(ngModel)]="model.firstname" #firstname="ngModel" required />
              <span class="tooltip-content">
                {{ 'USER.FIRST_NAME_VALIDATION' | translate }}
              </span>
            </label>
          </div>
          <div class="form-group" id="outter-div">
            <label class="required" id="label">{{ 'USER.LAST_NAME' | translate }}:</label>
            <label for="lastname" aria-haspopup="true" role="tooltip" class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm" [ngClass]="{'invalid': lastname.dirty && lastname.invalid}">
              <input type="text" name="lastname" class="inner-input" id="lastname" placeholder="{{ 'USER.LAST_NAME' | translate }}" [(ngModel)]="model.lastname" #lastname="ngModel" required />
              <span class="tooltip-content">
                {{ 'USER.LAST_NAME_VALIDATION' | translate }}
              </span>
            </label>
          </div>
          <div class="form-group" id="outter-div">
            <label id="label">{{ 'USER.TEL_NUMBER' | translate }}:</label>
            <label for="lastname" aria-haspopup="true" role="tooltip" class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm">
              <input type="tel" name="telNumber" class="inner-input" id="telNumber" placeholder="{{ 'USER.TEL_NUMBER' | translate }}" [(ngModel)]="model.telNumber" #lastname="ngModel" />
            </label>
          </div>
          <div class="form-group" id="outter-div">
            <label class="required" id="label">{{ 'USER.PREFERRED_LANGUAGE' | translate }}:</label>
            <label for="preferredLanguage" aria-haspopup="true" role="tooltip" class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm">
              <div class="select">
                <select class="inner-input" id="preferredLanguage" [(ngModel)]="model.languageId" name="language">
                  <option *ngFor="let language of languages" [ngValue]="language.languageId">
                    {{ language.name }}
                  </option>
                </select>
              </div>
            </label>
          </div>
        </section>
      </div>
    </form>
  </div>
  <div class="card-footer">
    <button type="button" class="btn btn-primary" (click)="registerProfile()" [disabled]="!registerProfileForm.form.valid">{{ 'REGISTER.NEXT' | translate }}</button>
  </div>
</div>
