<div class="item-list-component">
  <div class="grid-buttons">
    <clr-tooltip>
      <clr-icon shape="add" clrTooltipTrigger size="16" (click)="add()"></clr-icon>
      <clr-tooltip-content class="tooltip-content" clrPosition="top-left" clrSize="sm">
        <span>{{ 'ADD' | translate }}</span>
      </clr-tooltip-content>
    </clr-tooltip>
  </div>

  <clr-datagrid id="printSection">
    <clr-dg-column class="no-print two-buttons-column">
      <clr-tooltip role="tooltip" aria-haspopup="true" [clrTooltipDirection]="'top-left'" [clrTooltipSize]="'xs'">
        <clr-icon clrTooltipTrigger class="edit-btn" shape="arrow" dir="up" size="16" (click)="moveUp($event)">
        </clr-icon>
        <clr-tooltip-content class="tooltip-content">{{ 'MOVE_UP' | translate }}</clr-tooltip-content>
      </clr-tooltip>
      <clr-tooltip role="tooltip" aria-haspopup="true" [clrTooltipDirection]="'top-left'" [clrTooltipSize]="'xs'">
        <clr-icon clrTooltipTrigger class="edit-btn" shape="arrow" dir="down" size="16" (click)="moveDown($event)">
        </clr-icon>
        <clr-tooltip-content class="tooltip-content">{{ 'MOVE_DOWN' | translate }}</clr-tooltip-content>
      </clr-tooltip>
    </clr-dg-column>

    <clr-dg-column>{{ 'PRODUCT.BUY_BUTTON.TYPE' | translate }}</clr-dg-column>
    <clr-dg-column>{{ 'PRODUCT.BUY_BUTTON.VALUE' | translate }}</clr-dg-column>
    <clr-dg-column class="no-print tab-four-buttons-column">&nbsp;</clr-dg-column>

    <clr-dg-placeholder>{{'NO_DATA' | translate }}</clr-dg-placeholder>

    <clr-dg-row *clrDgItems="let item of items; let i = index">
      <clr-dg-cell class="no-print two-buttons-cell">
        <input type="checkbox" clrCheckbox name="isCollapsed" [(ngModel)]="item.selected" />
      </clr-dg-cell>
      <clr-dg-cell (click)="edit(item, $event)">{{item.typeDisplayName | translate }}</clr-dg-cell>
      <clr-dg-cell (click)="edit(item, $event)">{{item.value}}</clr-dg-cell>
      <clr-dg-cell class="no-print two-buttons-cell">
        <clr-tooltip role="tooltip" aria-haspopup="true" [clrTooltipDirection]="'top-left'" [clrTooltipSize]="'xs'">
          <clr-icon class="edit-btn" shape="edit" size="16" (click)="edit(item, $event)"></clr-icon>
          <clr-tooltip-content class="tooltip-content">{{ 'EDIT' | translate }}</clr-tooltip-content>
        </clr-tooltip>
        <clr-tooltip role="tooltip" aria-haspopup="true" [clrTooltipDirection]="'top-left'" [clrTooltipSize]="'xs'">
          <clr-icon class="delete-btn" shape="trash" size="16" (click)="delete(item, $event)"></clr-icon>
          <clr-tooltip-content class="tooltip-content">{{ 'DELETE' | translate }}</clr-tooltip-content>
        </clr-tooltip>
      </clr-dg-cell>
    </clr-dg-row>
  </clr-datagrid>
</div>

<product-buy-button-component (onAdded)="onBuyButtonAdded($event)" (onUpdated)="onBuyButtonUpdated($event)">
</product-buy-button-component>