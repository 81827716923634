import { Component, OnInit, Injector, ViewChild, ElementRef, OnDestroy } from '@angular/core';

import { ImageCroppedEvent } from 'ngx-image-cropper';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';
import notify from 'devextreme/ui/notify';

// services
import { AuctionClusterService } from '../shared/services/auction-cluster.service';
import { LanguageService } from '../../shared/services/language.service';

// models
import { AuctionCluster } from '../shared/models/auction-cluster';
import { Buyer } from '../../shared/models/buyer';
import { User } from '../shared/models/user';
import { TimeZoneInfo } from '../../shared/models/timeZoneInfo';
import { Currency } from '../../shared/models/currency';
import { Language, AuctionClusterLanguage } from '../../shared/models/language';
import { forkJoin, Subscription } from 'rxjs';
import { EWOULDBLOCK } from 'constants';
import { MFAType } from '../../../app/shared/models/mfaType';
import { AuctionClusterNotificationComponent } from './auction-cluster-notification.component';
import { AuctionClusterNotification } from '../../shared/models/auction-cluster-notification';
import { AuctionClusterChannel } from '../../shared/models/auction-cluster-channel';
import { NotificationManagerService } from '../../shared/services/notification-manager.service';
import { AuctionClusterChannelComponent } from './auction-cluster-channel.component';
import { NotificationTypeEnum } from '../../shared/models/notificationTypeEnum';
import { NotificationChannelType } from '../../shared/models/notificationChannelTypeEnum';

@Component({
  selector: 'auction-cluster-component',
  templateUrl: 'auction-cluster.component.html',
  styleUrls: ['./auction-cluster.component.scss']
})
export class AuctionClusterComponent extends ItemDetailsComponent<AuctionCluster> implements OnInit, OnDestroy {

  buyers: Array<Buyer> = [];
  users: Array<User> = [];
  assignAdminUser = true;
  currencies: Array<Currency> = []; 
  saving = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  auctionClusterId: number;
  auctionClusterLanguageIds: Array<number> = [];
  languages: Array<Language> = [];
  auctionClusterLanguages: Array<AuctionClusterLanguage> = [];
  mfaTypes: Array<MFAType> = [];
  notifications: Array<AuctionClusterNotification> = [];
  channels: Array<AuctionClusterChannel> = [];
  timeZones: Array<TimeZoneInfo> = [];
  auctionClusterMasterData: Array<any> = [];
  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  financiallyResponsible = [
    {
      value: 0,
      name: 'Buyer'
    },
    {
      value: 1,
      name: 'Subbuyer'
    }
  ];

  @ViewChild('inputCropper') inputCropper: ElementRef;
  @ViewChild('name', { read: ElementRef }) nameInput: any;
  @ViewChild(AuctionClusterNotificationComponent) auctionClusterNotificationComponent: AuctionClusterNotificationComponent;
  @ViewChild(AuctionClusterChannelComponent) auctionClusterChannelComponent: AuctionClusterChannelComponent;

  constructor(
    protected injector: Injector,
    private dataService: AuctionClusterService,
    private notificationManagerService: NotificationManagerService,
    private languageService: LanguageService) {
    super(injector);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.model = new AuctionCluster();
    this.setTranslations('NOTIFICATION');
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  translateSelectBoxes = (item) => {
    if (item) {
      const label = this.getLangName(item.languageId);
      return label;
    }
  }

  onACLanguagesChange() {
    this.auctionClusterLanguageIds.forEach(id => {
      var existingLanguage = this.auctionClusterLanguages.find(acLanguage => acLanguage.languageId == id);
      var lang = this.languages.find(l => l.languageId == id);
      if (lang) {
        if (!existingLanguage) {
          const newLang = new AuctionClusterLanguage();
          newLang.auctionClusterId = this.auctionClusterId;
          newLang.languageId = lang.languageId;
          newLang.isDefault = lang.languageId === this.model.auctionClusterDefaultLanguage
          this.auctionClusterLanguages.push(newLang);
        }
        else {
          existingLanguage.isDefault = lang.languageId === this.model.auctionClusterDefaultLanguage;
        }
      }
    });

    this.auctionClusterLanguages = this.auctionClusterLanguages.filter(acLanguage => this.auctionClusterLanguageIds.find(id => id == acLanguage.languageId));
  }

  getLangName(languageId) {
    const n = '';

    const l = this.languages.find(l => l.languageId === languageId);

    if (l) {
      return l.name;
    }

    return n;
  }

  getData() {
    this.spinner.show();
    forkJoin(
      this.dataService.getAuctionCluster(this.auctionClusterId),
      this.notificationManagerService.getAuctionClusterNotifications(this.auctionClusterId),
      this.notificationManagerService.getAuctionClusterChannels(this.auctionClusterId)
    ).subscribe(result => {
        this.model = result[0] as AuctionCluster;
        this.notifications = result[1];
        this.channels = result[2];
        this.croppedImage = this.model.image;
        this.auctionClusterLanguages = this.model.languages;
        this.auctionClusterLanguageIds = [];
        this.auctionClusterMasterData = this.model.masterDataList.map(md => {
          md.name = this.languageService.getTranslatableText(md.name);
          return md;
        });
        this.model.languages.forEach(l => {
          this.auctionClusterLanguageIds.push(l.languageId);
          if (l.isDefault) {
              this.model.auctionClusterDefaultLanguage = l.languageId;
          }
        });
        this.translateChannelAndNotificationLists();
        this.isOpened = true;
        this.spinner.hide();
      },
        error => {
          notify(error.message, 'error', 5000);
          this.spinner.hide();
        });
  }

    open(auctionClusters: Array<AuctionCluster>, id: number, buyers: Array<Buyer>, users: Array<User>, currencies: Array<Currency>, languages, mfaTypes: Array<MFAType>, timeZones: Array<TimeZoneInfo>) {
    this.allItems = auctionClusters;
    this.buyers = buyers;
    this.users = users;
    this.currencies = currencies;
    this.languages = languages;
    this.auctionClusterId = id;
    this.mfaTypes = mfaTypes;
    this.timeZones = timeZones;
    this.auctionClusterMasterData = [];

    if (id != null) {
      this.isEditMode = true;
      this.getData();
    } else {
      this.model = new AuctionCluster();
      this.isEditMode = false;
      this.isOpened = true;
    }

    // setTimeout(() => { this.nameInput.nativeElement.focus(); });
  }

  handleSubmit() {
    if (this.saving) {
      return;
    }

    this.onSubmit();

    this.inputCropper.nativeElement.value = "";
    this.imageChangedEvent = null;
    this.croppedImage = null;
  }

  save() {
    this.saving = true;
    this.model.image = this.croppedImage;

    //Set defaultlanguage
    this.onACLanguagesChange();

    this.model.languages = this.auctionClusterLanguages;

    if (this.isEditMode) {
      this.spinner.show();
      this.dataService.edit(this.model)
        .subscribe((res: any) => {
          this.model = new AuctionCluster();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorMessage = this.errorService.toString(error);
            notify(this.errorMessage, 'error', 5000);
          this.spinner.hide();
        }, () => this.saving = false);
    } else {
      this.spinner.show();

      // Force reset of administratorId if assign admin user is unchecked
      if (!this.assignAdminUser) {
        this.model.administratorId = null;
      }

      this.dataService.save(this.model)
        .subscribe((res: any) => {
          this.model = new AuctionCluster();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorMessage = this.errorService.toString(error);
            notify(this.errorMessage, 'error', 5000);
          this.spinner.hide();
        }, () => this.saving = false);
    }
  }

  editNotification = (e: any) => {
    const auctionClusterNotification = e.row !== undefined ? e.row.data : e.data;

    if(e.column.dataType === "boolean") {
      this.enableDisableNotification(auctionClusterNotification);
    } else {      
      this.auctionClusterNotificationComponent.open(auctionClusterNotification, this.languages);
    }
  }

  editChannel = (e: any) => {
    const auctionClusterChannel = e.row !== undefined ? e.row.data : e.data;
    
    if(e.column.dataType === "boolean") {
      this.enableDisableChannel(auctionClusterChannel);
    } else {      
      this.auctionClusterChannelComponent.open(auctionClusterChannel, this.languages);
    }
  }

  handleCancel() {
    this.inputCropper.nativeElement.value = '';
    this.imageChangedEvent = null;
    this.croppedImage = null;

    this.onCancel();
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  onFieldDataChanged(e: any) {
    if (e.component._isReady && e.component.NAME !== 'dxPopup') {
      const result = e.component.validate();
      if (result.brokenRules.length >= 1) {
        document.getElementsByName('btnAuctionClusterSubmit')[0].setAttribute('disabled', 'disabled');
      } else {
        document.getElementsByName('btnAuctionClusterSubmit')[0].removeAttribute('disabled');
      }
    } else {
      if (this.isEditMode) {
        document.getElementsByName('btnAuctionClusterSubmit')[0].removeAttribute('disabled');
      } else {
        document.getElementsByName('btnAuctionClusterSubmit')[0].setAttribute('disabled', 'disabled');
      }
    }
  }

  enableDisableNotification = (auctionClusterNotification: AuctionClusterNotification) => {
    this.notificationManagerService.enableDisableAuctionClusterNotification(auctionClusterNotification, !auctionClusterNotification.isEnabled).subscribe(() => {
      this.getData();
    }, error => {
      notify(error.message, 'error', 5000);
    });
  }

  enableDisableChannel = (auctionClusterChannel: AuctionClusterChannel) => {
      this.notificationManagerService.enableDisableAuctionClusterChannel(auctionClusterChannel, !auctionClusterChannel.isActive).subscribe(() => {
        this.getData();
      }, error => {
        notify(error.message, 'error', 5000);
      });
  }

  translateChannelAndNotificationLists() {
    this.notifications.forEach(item => {
      item.nameText = this.languageService.getTranslatableText(item.name);
      
      if(item.notificationType === NotificationTypeEnum.PublishingForecastCatalog) item.defaultName = this.translations.TYPE_PUBLISHING_FORECAST_CATALOG;
      else if(item.notificationType === NotificationTypeEnum.PublishingSupplyCatalog) item.defaultName = this.translations.TYPE_PUBLISHING_SUPPLY_CATALOG;
      else if(item.notificationType === NotificationTypeEnum.TransactionSummary) item.defaultName = this.translations.TYPE_TRANSACTION_SUMMARY;
      else if(item.notificationType === NotificationTypeEnum.ErrorReporting) item.defaultName = this.translations.TYPE_ERROR_REPORTING;
      else if(item.notificationType === NotificationTypeEnum.CancelledPrebids) item.defaultName = this.translations.TYPE_CANCELLED_PREBIDS;
    });
    this.channels.forEach(item => {
      item.nameText = this.languageService.getTranslatableText(item.name);    

      if(item.notificationChannelType === NotificationChannelType.Email) item.defaultName = this.translations.CHANNEL_TYPE_EMAIL;
      else if(item.notificationChannelType === NotificationChannelType.SMS) item.defaultName = this.translations.CHANNEL_TYPE_SMS;
      else if(item.notificationChannelType === NotificationChannelType.Push) item.defaultName = this.translations.CHANNEL_TYPE_PUSH;
    });
  }

  returnValues = (e: any) => {
    if (e != null) {
      return e.firstname + ' ' + e.lastname;
    }
  }
}
