<div class="zone-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup #addZone [width]="600" [showTitle]="true" title="{{ modalTitle }}" [dragEnabled]="false"
    [closeOnOutsideClick]="false" [rtlEnabled]="rtlEnabled" [(visible)]="isOpened"
    (onShown)="onFieldDataChanged($event)">

    <dx-scroll-view width="100%" height="100%">
      <dx-form #zoneForm id="zoneForm" validationGroup="zoneData" [showValidationSummary]="false"
        (onFieldDataChanged)="onFieldDataChanged($event)" colCount="auto" [(formData)]="model" [minColWidth]="500">

        <dxi-item itemType="group">
          <dxi-item dataField="name">
            <dxo-label text="{{ 'ZONE.NAME' | translate }}"></dxo-label>
            <dxi-validation-rule type="required" message="{{ 'ZONE.NAME_VALIDATION' | translate }}">
            </dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="locationId" editorType="dxSelectBox"
            [editorOptions]="{ items: locations, valueExpr:'locationId', displayExpr:'name'}" [rtlEnabled]="rtlEnabled">
            <dxo-label text="{{ 'ZONE.LOCATION' | translate }}"></dxo-label>
            <dxi-validation-rule type="required" message="{{ 'ZONE.LOCATION_VALIDATION' | translate }}">
            </dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="isActive" editorType="dxCheckBox">
            <dxo-label text="{{ 'ZONE.ACTIVE' | translate }}"></dxo-label>
          </dxi-item>

          <dxi-item dataField="type" editorType="dxSelectBox"
            [editorOptions]="{ items: zoneTypes, valueExpr:'value', displayExpr:translateSelectBoxes }" [rtlEnabled]="rtlEnabled">
            <dxo-label text="{{ 'ZONE.TYPE' | translate }}"></dxo-label>
            <dxi-validation-rule type="required" message="{{ 'ZONE.TYPE_VALIDATION' | translate }}">
            </dxi-validation-rule>
          </dxi-item>

        </dxi-item>
      </dx-form>

      <div *ngIf="model.type === 1">{{ 'ZONE.BUYER' | translate }}</div>
      <dx-select-box [visible]="model.type === 1" [(value)]="selectedBuyer" [items]="buyers" valueExpr="buyerId"
        displayExpr="name" editorType="dxSelectBox" [rtlEnabled]="rtlEnabled">
      </dx-select-box>

      <div *ngIf="model.type === 2">{{ 'ZONE.BUYERS' | translate }}</div>
      <dx-list [visible]="model.type === 2" [items]="buyers" [height]="400" [showSelectionControls]="true"
        [selectAllMode]="true" selectionMode="all" [(selectedItemKeys)]="requiredBuyers" displayExpr="name"
        keyExpr="buyerId">
      </dx-list>

      <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnZoneSubmit"
        disabled="disabled">{{ 'SAVE' | translate }}</button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'CANCEL' | translate }}</button>
    </dx-scroll-view>
  </dx-popup>
</div>