<div dir="{{rtlEnabled ? 'rtl' : 'ltr'}}" class="main-container content-component" [class.efice]="baseUrl && baseUrl.includes('efice.com')" [class.test]="baseUrl && baseUrl.includes('test')">
  <div class="content-container">
    <div class="content-area">
      <header-component></header-component>
      <div class="contents">
        <alert-component></alert-component>
        <router-outlet></router-outlet>
      </div>
    </div>
    <nav class="sidenav" [ngClass]="{'collapsed': !expanded && !pin}">
      <section class="sidenav-content" *ngIf="expanded || pin">
        <div class="collapsed-btn" (click)="navCollapse()">
          <clr-icon shape="arrow left" size="40" style="padding-left: 16px;padding-bottom: 16px"></clr-icon>
        </div>

        <a [routerLink]="['/platform/home']" class="logo-wrapper" (click)="onRouterLinkClick()">
          <img *ngIf="!sidebarLogo" src="assets/images/Aucxis.png" class="aucxis-logo" />
          <img *ngIf="!sidebarLogo" src="assets/images/efice.png" class="efice-logo" style="display: none;" />
          <img *ngIf="sidebarLogo" [src]="sidebarLogo" />
        </a>

        <!--<a routerLinkActive="active" [routerLink]="['/platform/home']" class="nav-link" (click)="onRouterLinkClick()">
          {{ 'PLATFORM.OVERVIEW' | translate }}
        </a>-->

        <collapsable-section *ngIf="tokenService.permissionMet('PlatformPermissions.1')||tokenService.permissionMet('PlatformPermissions.8')" sectionId="platform-users" [caption]="'PLATFORM.USERS_ROLES_MANAGEMENT' | translate" (clicked)="onRouterLinkClick('platform-users')">
          <ul class="nav-list">
            <li *ngIf="tokenService.permissionMet('PlatformPermissions.1')">
              <a [routerLink]="['/platform/users']" routerLinkActive="active" class="nav-link" (click)="onRouterLinkClick('platform-users')">
                {{ 'PLATFORM.USERS' | translate }}
              </a>
            </li>
            <li *ngIf="tokenService.permissionMet('PlatformPermissions.8')">
              <a [routerLink]="['/platform/roles']" routerLinkActive="active" class="nav-link" (click)="onRouterLinkClick('platform-users')">
                {{ 'PLATFORM.ROLES' | translate }}
              </a>
            </li>
          </ul>
        </collapsable-section>

        <a routerLinkActive="active" *ngIf="tokenService.permissionMet('PlatformPermissions.3')" [routerLink]="['/platform/clusters']" class="nav-link" (click)="onRouterLinkClick()">
          {{ 'PLATFORM.AUCTION_CLUSTER_MANAGEMENT' | translate }}
        </a>
        <a routerLinkActive="active" *ngIf="tokenService.permissionMet('PlatformPermissions.4')" [routerLink]="['/platform/buyers']" class="nav-link" (click)="onRouterLinkClick()">
          {{ 'PLATFORM.BUYER_MANAGEMENT' | translate }}
        </a>
        <a routerLinkActive="active" *ngIf="tokenService.permissionMet('PlatformPermissions.5')" [routerLink]="['/platform/suppliers']" class="nav-link" (click)="onRouterLinkClick()">
          {{ 'PLATFORM.SUPPLIER_MANAGEMENT' | translate }}
        </a>

        <!--<a routerLinkActive="active" *ngIf="tokenService.permissionMet('PlatformPermissions.6')" [routerLink]="['/platform/reports']" class="nav-link" (click)="onRouterLinkClick()">
          {{ 'PLATFORM.REPORTING' | translate }}
        </a>-->

        <a routerLinkActive="active" *ngIf="tokenService.permissionMet('PlatformPermissions.7')" [routerLink]="['/platform/additional-menu-items']" class="nav-link" (click)="onRouterLinkClick()">
          {{ 'PLATFORM.ADDITIONAL_MENU_ITEMS' | translate }}
        </a>

        <a routerLinkActive="active" [routerLink]="['/platform/supplyforecast/']" class="nav-link public-supply-forecast-urk" (click)="onRouterLinkClick()">
          {{ 'CONTENT.SUPPLY_FORECAST' | translate }}
        </a>

        <collapsable-section *ngIf="tokenService.permissionMet('PlatformPermissions.9')" sectionId="management" [caption]="'PLATFORM.PLATFORM_MANAGEMENT' | translate" (clicked)="onRouterLinkClick('management')">
          <ul class="nav-list">
            <li>
              <a [routerLink]="['/platform/configurationparameter']"  routerLinkActive="active" class="nav-link" (click)="onRouterLinkClick('management')">
                {{ 'PLATFORM.CONFIGURATION_PARAMETERS' | translate }}
              </a>
            </li>
            <li>
              <a routerLinkActive="active" class="nav-link" (click)="onRouterLinkClick('management')">
                {{ 'PLATFORM.PRODUCT_DEFINITION' | translate }}
              </a>
            </li>
            <li>
              <a routerLinkActive="active" class="nav-link" (click)="onRouterLinkClick('management')">
                {{ 'PLATFORM.LICENSING' | translate }}
              </a>
            </li>
            <li>
              <a [routerLink]="['/platform/importauctioncluster']" routerLinkActive="active" class="nav-link" (click)="onRouterLinkClick('management')">
                {{ 'PLATFORM.IMPORT_AUCTIONCLUSTER' | translate }}
              </a>
            </li>
            <li>
              <a [routerLink]="['/platform/externalplatforms']" routerLinkActive="active" class="nav-link" (click)="onRouterLinkClick('management')">
                {{ 'PLATFORM.EXTERNAL_PLATFORMS' | translate }}
              </a>
            </li>
          </ul>
        </collapsable-section>

        <collapsable-section *ngIf="tokenService.permissionMet('PlatformPermissions.9')" sectionId="styling" [caption]="'PLATFORM.STYLING' | translate" (clicked)="onRouterLinkClick('styling')">
          <ul class="nav-list">
            <li>
              <a [routerLink]="['/platform/productpropertystylings']" routerLinkActive="active" class="nav-link" (click)="onRouterLinkClick('styling')">
                {{ 'PLATFORM.PRODUCT_PROPERTY' | translate }}
              </a>
            </li>
          </ul>
        </collapsable-section>

        <ng-container *ngFor="let item of menuItems" >
          <collapsable-section *ngIf="item.menuType === 3" [caption]="getTranslation(item.menuItemName)" sectionId="item.menuItemName" (clicked)="onRouterLinkClick('item.menuItemName')">
            <ul class="nav-list">
              <ng-container *ngFor="let subItem of subMenuItems" >
                <ng-container *ngIf="item.externalAppId === subItem.submenuId">
                  <a *ngIf="!subItem.openInNewTab"
                  [routerLink]="['/platform/menu-item/' + subItem.externalAppId]"
                  routerLinkActive="active"
                  class="nav-link">{{ getTranslation(subItem.menuItemName) }}</a>

                  <a *ngIf="subItem.openInNewTab"
                  [href]="subItem.applicationUrl"
                  [target]="'_blank'"
                  routerLinkActive="active"
                  class="nav-link">{{ getTranslation(subItem.menuItemName) }}</a>
                </ng-container>
              </ng-container>
            </ul>
          </collapsable-section>

          <ng-container *ngIf="item.menuType === 1">
            <a *ngIf="!item.openInNewTab"
            [routerLink]="['/platform/menu-item/' + item.externalAppId]"
            routerLinkActive="active"
            class="nav-link">{{ getTranslation(item.menuItemName) }}</a>

            <a *ngIf="item.openInNewTab"
            [href]="item.applicationUrl"
            [target]="'_blank'"
            routerLinkActive="active"
            class="nav-link">{{ getTranslation(item.menuItemName) }}</a>
          </ng-container>

          <ng-container *ngIf="item.menuType === 2">
            <a [routerLink]="['/platform/menu-item/' + item.externalAppId]"
            routerLinkActive="active"
            class="nav-link">{{ getTranslation(item.menuItemName) }}</a>
          </ng-container>

        </ng-container>
      </section>
      <a *ngIf="expanded || pin" href="http://www.aucxis.com/" target="_blank" class="bottom-logo-wrapper">
        <img src="assets/images/Aucxis_w.png" />
      </a>
      <version-component></version-component>

      <section class="sidenav-content" *ngIf="!expanded && !pin">
        <a [routerLink]="['/core/home']" class="small-logo-wrapper">
          <!--<img src="assets/images/europool_logo_small.png" />-->
        </a>
        <div class="expand-btn" (click)="navExpand()">
          <clr-icon shape="arrow right" size="42"></clr-icon>
        </div>
        <a href="http://www.aucxis.com/" target="_blank" class="bottom-logo-wrapper">
          <img src="assets/images/Aucxis_w_vert.png" />
        </a>
      </section>
    </nav>
  </div>
</div>
<spinner-component></spinner-component>
