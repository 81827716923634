<div class="product-property-component">
  <clr-modal [(clrModalOpen)]="isOpened" [clrModalClosable]="false">
    <h3 class="modal-title">{{ modalTitle }}</h3>
    <div class="modal-body">
      <clr-alert *ngIf="errorMessage" [clrAlertType]="'danger'" [clrAlertClosable]="false">
        <div class="alert-item">
          <span class="alert-text">
            {{errorMessage}}
          </span>
        </div>
      </clr-alert>

      <form #detailsForm="ngForm" clrForm>
        <div class="clr-form-control">
          <label class="clr-control-label">{{ 'PRODUCT.PROPERTY.NAME' | translate }}</label>
          <div class="clr-control-container">
            <translatable-field [(data)]="model.name" [name]="'propertyName'" [clusterLanguages]="languages" #productPropertyName></translatable-field>
          </div>
        </div>

        <div class="clr-form-control">
          <label for="sysName" class="clr-control-label">{{ 'PRODUCT.PROPERTY.SYSTEM_NAME' | translate }}</label>
          <div class="clr-control-container">
            <div class="clr-input-wrapper">
              <input id="sysName" type="text" name="sysName" class="clr-input" [(ngModel)]="model.sysName" />
            </div>
          </div>
        </div>

        <div class="clr-form-control">
          <label for="propertyType" class="clr-control-label">{{ 'PRODUCT.PROPERTY.TYPE' | translate }}</label>
          <div class="clr-control-container">
            <div class="clr-select-wrapper">
              <select id="propertyType" name="propertyType" [(ngModel)]="model.propertyTypeId" (change)="getLookups(); getCalculations(); resetPropertyTypeFormat();" (ngModelChange)="filterSystemPropertyTypes(true); filterDataFormats()" required>
                <option [ngValue]="null">&nbsp;</option>
                <option *ngFor="let propertyType of productPropertyTypes" [ngValue]="propertyType.propertyTypeId">{{propertyType.name}}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="clr-form-control" *ngIf="model.propertyTypeId === 2">
          <label for="decimalPlaces" class="clr-control-label">{{ 'PRODUCT.PROPERTY.DECIMAL_PLACES' | translate }}</label>
          <div class="clr-control-container">
            <div class="clr-select-wrapper">
              <select id="decimalPlaces" name="decimalPlaces" [(ngModel)]="model.propertyTypeFormatId">
                <option [ngValue]="null">&nbsp;</option>
                <option *ngFor="let format of dataFormats" [ngValue]="format.propertyTypeFormatId">{{format.name | translateJson}}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="clr-form-control" *ngIf="model.propertyTypeId === 4">
          <label for="dateFormat" class="clr-control-label">{{ 'PRODUCT.PROPERTY.DATE_FORMAT' | translate }}</label>
          <div class="clr-control-container">
            <div class="clr-select-wrapper">
              <select id="dateFormat" name="dateFormat" [(ngModel)]="model.propertyTypeFormatId">
                <option [ngValue]="null">&nbsp;</option>
                <option *ngFor="let format of dataFormats" [ngValue]="format.propertyTypeFormatId">{{format.name | translateJson}}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="clr-form-control" *ngIf="model.propertyTypeId === 8">
          <label for="urlFormat" class="clr-control-label">{{ 'PRODUCT.PROPERTY.URL_FORMAT' | translate }}</label>
          <div class="clr-control-container">
            <div class="clr-select-wrapper">
              <select id="urlFormat" name="urlFormat" [(ngModel)]="model.propertyTypeFormatId" (change)="resetImageCaption();">
                <option [ngValue]="null">&nbsp;</option>
                <option *ngFor="let format of dataFormats" [ngValue]="format.propertyTypeFormatId">{{format.name | translateJson}}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="clr-form-control" *ngIf="model.propertyTypeId === 8 && model.propertyTypeFormatId === 17">
          <label for="imageCaption" class="clr-control-label">{{ 'PRODUCT.PROPERTY.IMAGE_CAPTION' | translate }}</label>
          <div class="clr-control-container">
            <div class="clr-select-wrapper">
              <select id="imageCaption" name="imageCaption" [(ngModel)]="model.imageCaptionId">
                <option [ngValue]="null">&nbsp;</option>
                <option *ngFor="let property of availablePropertiesForCaption" [ngValue]="property.productPropertyId">{{property.name | translateJson}}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="clr-form-control">
          <label for="systemPropertyType" class="clr-control-label">{{ 'PRODUCT.PROPERTY.SYSTEM_PRODUCT_PROPERTY_TYPE' | translate }}</label>
          <div class="clr-control-container">
            <div class="clr-select-wrapper">
              <select id="systemPropertyType" name="systemPropertyType" [(ngModel)]="model.systemProductPropertyType" (ngModelChange)="filterMasterDatas()">
                <option [ngValue]="null">&nbsp;</option>
                <option *ngFor="let systemType of filteredSystemProductPropertyTypes" [ngValue]="systemType.systemProductPropertyId">{{systemType.name | translateJson}}</option>
              </select>
            </div>
          </div>
        </div>

        <div *ngIf="model.propertyTypeId === 3">
          <div class="clr-form-control">
            <label for="maxLength" class="clr-control-label">{{ 'PRODUCT.PROPERTY.MAX_LENGTH' | translate }}</label>
            <div class="clr-control-container">
              <div class="clr-input-wrapper">
                <input type="number" class="clr-input" id="maxLength" name="maxLength" [(ngModel)]="model.maxLength" /> {{ 'PRODUCT.PROPERTY.CHARACTERS' | translate }}
              </div>
            </div>
          </div>
          <div class="clr-form-control">
            <label for="translatable" class="clr-control-label">{{ 'PRODUCT.PROPERTY.TRANSLATABLE' | translate }}</label>
            <div class="clr-control-container">
              <div class="clr-checkbox-wrapper">
                <input id="translatable" type="checkbox" clrCheckbox name="translatable" [(ngModel)]="model.translatable" />
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="model.propertyTypeId === 1 || model.propertyTypeId === 2">
          <div class="clr-form-control">
            <label for="minValue" class="clr-control-label">{{ 'PRODUCT.PROPERTY.MIN_VALUE' | translate }}</label>
            <div class="clr-control-container">
              <div class="clr-input-wrapper">
                <input type="number" class="clr-input" id="minValue" name="minValue" [(ngModel)]="model.minValue" />
              </div>
            </div>
          </div>
          <div class="clr-form-control">
            <label for="maxValue" class="clr-control-label">{{ 'PRODUCT.PROPERTY.MAX_VALUE' | translate }}</label>
            <div class="clr-control-container">
              <div class="clr-input-wrapper">
                <input type="number" class="clr-input" id="maxValue" name="maxValue" [(ngModel)]="model.maxValue" />
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="model.propertyTypeId === 5">
          <div class="clr-form-control">
            <label for="masterData" class="clr-control-label">{{ 'PRODUCT.PROPERTY.MASTER_DATA' | translate }}</label>
            <div class="clr-control-container">
              <div class="clr-select-wrapper">
                <select id="masterData" name="masterData" [(ngModel)]="model.masterDataListId" (change)="getLookups()" [disabled]="isSystemField">
                  <option [ngValue]="null">&nbsp;</option>
                  <option *ngFor="let masterData of masterDatas" [ngValue]="masterData.masterDataListId">{{masterData.masterDataNameText}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="clr-form-control">
          <label for="lookupTable" class="clr-control-label">{{ 'PRODUCT.PROPERTY.LOOKUP_TABLE' | translate }}</label>
          <div class="clr-control-container">
            <div class="clr-select-wrapper">
              <select id="lookupTable" name="lookupTable" [(ngModel)]="model.lookupTableId">
                <option [ngValue]="null">&nbsp;</option>
                <option *ngFor="let lt of lookupTables" [ngValue]="lt.lookupTableId">{{lt.lookupTableText}}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="clr-form-control">
          <label for="calculation" class="clr-control-label">{{ 'PRODUCT.PROPERTY.CALCULATION' | translate }}</label>
          <div class="clr-control-container">
            <div class="clr-select-wrapper">
              <select id="calculation" name="calculation" [(ngModel)]="model.calculationId">
                <option [ngValue]="null">&nbsp;</option>
                <option *ngFor="let lt of calculations" [ngValue]="lt.calculationId">{{lt.name | translateJson}}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="clr-form-control" *ngIf="isDateFilterDropdownVisible()">
          <label for="dateFilterProductProperty" class="clr-control-label">{{ 'PRODUCT.PROPERTY.DATE_FITLER_PROPERTY' | translate }}</label>
          <div class="clr-control-container">
            <div class="clr-select-wrapper">
              <select id="dateFilterProductProperty" name="dateFilterProductProperty" [(ngModel)]="model.dateFilterProductPropertyId">
                <option [ngValue]="null">&nbsp;</option>
                <option *ngFor="let df of getDateFilterProductPropertyOptions()" [ngValue]="df.productPropertyId">{{df.productPropertyNameText}}</option>
              </select>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" (click)="onSubmit();" [disabled]="!detailsForm.form.valid || !productPropertyName.valid">{{ (isEditMode ? 'SAVE' : 'ADD') | translate }}</button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'CANCEL' | translate }}</button>
    </div>
  </clr-modal>
</div>
