<div class="item-list-component buyback-on-lot" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup #buybackOnLotList
            width="98%"
            [showTitle]="true"
            title="{{ modalTitle }}"
            [dragEnabled]="false"
            [closeOnOutsideClick]="false"
            [rtlEnabled]="rtlEnabled"
            [(visible)]="isOpened">
            <dx-scroll-view width="100%" height="100%">
              <dx-data-grid #buybackOnLotGrid
                              id="printSection"
                              [rtlEnabled]="rtlEnabled"
                              [showBorders]="true"
                              (onInitialized)="initColumn($event)"
                              [allowColumnReordering]="true" [hoverStateEnabled]="true" [columnAutoWidth]="true">
                  <dxo-selection mode="single"></dxo-selection>
                  <dxo-search-panel [visible]="true"></dxo-search-panel>

                  <dxi-column [editorOptions]="{ showClearButton: true }"
                              cellTemplate="templateBuybackAmount"
                              caption="{{ 'AUCTION.SUPPLY_MONITOR.BUYBACK_AMOUNT' | translate }}"
                              [fixed]="true"
                              fixedPosition="right">
                  </dxi-column>
                  <dxi-column [editorOptions]="{ showClearButton: true }"
                              cellTemplate="templateBuybackBuyer"
                              caption="{{ 'AUCTION.SUPPLY_MONITOR.BUYBACK_BUYER' | translate }}"
                              [fixed]="true"
                              fixedPosition="right">
                  </dxi-column>
                  <dxi-column [editorOptions]="{ showClearButton: true }"
                              cellTemplate="templateBuybackSubbuyer"
                              caption="{{ 'AUCTION.SUPPLY_MONITOR.BUYBACK_SUBBUYER' | translate }}"
                              [fixed]="true"
                              fixedPosition="right">
                  </dxi-column>
                  <dxi-column [editorOptions]="{ showClearButton: true }"
                              cellTemplate="templateBuybackEdit"
                              caption="{{ 'AUCTION.SUPPLY_MONITOR.BUYBACK_EDIT' | translate }}"
                              [fixed]="true"
                              fixedPosition="right">
                  </dxi-column>

                  <div *dxTemplate="let d of 'templateBuybackAmount'">
                    <ng-container *ngIf="!d.data.__item__.canRemove" style="background-color: azure;">

                    </ng-container>
                    <ng-container *ngIf="d.data.__item__.canRemove">

                    <dx-number-box [(value)]="d.data.__item__.amountWanted"
                                  (onValueChanged)="buybackAmountChanged(d.data.__item__, d)"></dx-number-box>
                    </ng-container>
                  </div>

                  <div *dxTemplate="let d of 'templateBuybackBuyer'">
                    <ng-container *ngIf="!d.data.__item__.canRemove">

                    </ng-container>
                    <ng-container *ngIf="d.data.__item__.canRemove">
                    <dx-select-box [dataSource]="buyersSubbuyersPaginated"
                                  valueExpr="buyerId"
                                  [displayExpr]="buyerName"
                                  [(value)]="d.data.__item__.buyerId"
                                  (onValueChanged)="buyerChanged(d.data.__item__, d)"
                                  showClearButton="true"
                                  [searchEnabled]="true">
                    </dx-select-box>
                    </ng-container>
                  </div>

                  <div *dxTemplate="let d of 'templateBuybackSubbuyer'">
                    <ng-container *ngIf="!d.data.__item__.canRemove">

                    </ng-container>
                    <ng-container *ngIf="d.data.__item__.canRemove">
                    <dx-select-box [dataSource]="d.data.__item__.subbuyers"
                                   valueExpr="buyerId"
                                  [displayExpr]="subBuyerName"
                                  [(value)]="d.data.__item__.subbuyerId"
                                  (onValueChanged)="subbuyerChanged(d.data.__item__, d)"
                                  showClearButton="true"
                                  [searchEnabled]="true">
                    </dx-select-box>
                    </ng-container>
                  </div>

                  <div *dxTemplate="let d of 'templateBuybackEdit'">
                    <ng-container *ngIf="!d.data.__item__.canRemove">
                      <a (click)="addBuybackValue(d.data.__item__.id, d);" class="dx-link">
                        <i title="{{ 'ADD' | translate }}" class="dx-icon-add"></i>
                        </a>
                    </ng-container>
                    <ng-container *ngIf="d.data.__item__.canRemove">
                      <a (click)="removeBuyback(d)" class="dx-link" *ngIf="d.data.__item__.canRemove">
                      <i title="{{ 'AUCTION.SUPPLY_MONITOR.BUYBACK_DELETE' | translate }}" class="dx-icon-trash"></i>
                      </a>
                    </ng-container>
                  </div>

                  <div *dxTemplate="let cell of 'cellTemplate'">
                    <span [innerHtml]="cell.text | format | safeHtml"></span>
                  </div>
                  <div *dxTemplate="let cell of 'textCellTemplate'">
                    <span [innerHtml]="cell.text | safeHtml"></span>
                  </div>
                </dx-data-grid>

                <br />
                <button type="submit" class="btn btn-primary" (click)="onSubmit();" [disabled]="saveDisabled">
                  {{ 'SAVE' | translate }}
                </button>
                <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'CANCEL' | translate }}</button>
            </dx-scroll-view>
  </dx-popup>


</div>
