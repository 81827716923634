import { Component, OnInit, Injector, OnDestroy, ViewChild } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { ExternalPlatformComponent } from './external-platform.component';

// services
import { ExternalPlatformService } from '../shared/services/external-platform.service';
import { LanguageService } from '../../shared/services/language.service';

// models
import { ExternalPlatform } from '..//shared/models/external-platform';
import { ImportSchema, ImportField } from '../../shared/import/import-schema';

import notify from 'devextreme/ui/notify';

@Component({
  selector: 'external-platforms-component',
  templateUrl: 'external-platforms.component.html',
  styleUrls: ['./external-platforms.component.scss']
})
export class ExternalPlatformsComponent extends FullListComponent<ExternalPlatform, ExternalPlatformComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: ExternalPlatformComponent;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private externalPlatformService: ExternalPlatformService,
    private languageService: LanguageService
  ) {
    super(injector, ExternalPlatform);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.title.set('EXTERNAL_PLATFORMS.TITLE');
    this.setTranslations('EXTERNAL_PLATFORMS');
    this.getData();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getData() {
    this.spinner.show();

    forkJoin(
      this.externalPlatformService.getExternalPlatforms()
    ).subscribe(result => {
      this.items = result[0];
      this.items.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      this.spinner.hide();
    }, error => {
      notify(error.message, 'error', 5000);
      this.spinner.hide();
    });
  }

  edit = (e: any) => {
    const externalPlatformId = e.row !== undefined ? e.row.data.externalPlatformId : e.data.externalPlatformId;
    this.detailsComponent.modalTitle = this.translations.EDIT;
    this.detailsComponent.open(this.items, externalPlatformId);
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.ADD_NEW;
    this.detailsComponent.open(this.items, null);
  }

  createDataSchema() {
    const schema = new ImportSchema();

    const name = new ImportField('name', this.translations.NAME);
    name.required = true;

    const webServiceUrl = new ImportField('webServiceUrl', this.translations.WEBSERVICE_URL);
    webServiceUrl.required = true;

    schema.fields.push(name, webServiceUrl);

    this.schema = schema;
  }

  deleteSelected() {
    this.spinner.show();
    this.externalPlatformService.delete(this.itemIdToDelete).subscribe(() => {
      this.getData();
      this.spinner.hide();
    });

    this.confirmation.opened = false;
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.externalPlatformId;
    this.confirmation.opened = true;
  }
}
