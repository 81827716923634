export class PlatformRole {

  platformRoleId: number;
  name: string;
  description: string;
  permissions: Array<number>;

  roleNameText: string;
  roleDescriptionText: string;
  permissionString: string;
}
