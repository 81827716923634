import { Component, OnInit, Injector, OnDestroy, ViewChild } from '@angular/core';
import { forkJoin, timer, Subscription } from 'rxjs';

// general services
import { LanguageService } from '../../shared/services/language.service';
import { TokenService } from '../../shared/services/token.service';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { RoleComponent } from './role.component';

// services
import { PlatformRoleService } from '../shared/services/platform-role.service';
import { PlatformPermissionService } from '../shared/services/platform-permission.service';

// models
import { PlatformPermission } from '../shared/models/platform-permission';
import { PlatformPermissionEnum } from '../../shared/models/user-permissions';
import { PlatformRole } from '../shared/models/platform-role';
import { Language } from '../../shared/models/language';
import { ImportSchema, ImportField } from '../../shared/import/import-schema';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'roles-component',
  templateUrl: 'roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent extends FullListComponent<PlatformRole, RoleComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: RoleComponent;

  permissions: Array<PlatformPermission> = [];
  languages: Array<Language> = [];
  roleHasUsers: boolean;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private dataService: PlatformRoleService,
    private platformPermissionService: PlatformPermissionService,
    private languageService: LanguageService,
    private tokenService: TokenService
  ) {
    super(injector, PlatformRole);
    this.title.set('PLATFORM.ROLES');
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.getData();

    this.setTranslations('ROLES');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  delete(itemId: number, event: Event) {
    event.stopPropagation();
    this.itemIdToDelete = itemId;

    this.dataService.checkIfRoleHasUsers(this.itemIdToDelete).subscribe(res => {
      if (res.length === 0) {
        this.confirmation.opened = true;
      } else {
        this.roleHasUsers = true;
      }
    });

    const alertDuration = 3000;
    timer(alertDuration).subscribe(() => {
      this.roleHasUsers = false;
    });
  }

  deleteSelected() {
    this.spinner.show();
    this.dataService.delete(this.itemIdToDelete)
      .subscribe((result: boolean) => {
        this.getData();
        this.spinner.hide();
      },
        error => {
          notify(this.errorService.translations.DELETE_ERROR_MESSAGE, 'error', 5000);
          this.spinner.hide();
        });
  }

  // tslint:disable:no-magic-numbers
  getData() {
    this.spinner.show();

    forkJoin(
      this.platformPermissionService.getPlatformPermissions(),
      this.languageService.getLanguages(),
      this.dataService.getPlatformRoles()
    ).subscribe(result => {
      this.permissions = result[0];
      this.languages = result[1];
      this.items = result[2];

      var userPermissions = this.tokenService.getCurrentUserPermissions();

      if (!userPermissions.PlatformPermissions.some(p => p === PlatformPermissionEnum.Administrator)) {
        this.permissions = this.permissions.filter(_ => _.platformPermissionId !== PlatformPermissionEnum.Administrator.valueOf());
        this.items = this.items.filter(_ => !_.permissions.some(pp => pp === PlatformPermissionEnum.Administrator.valueOf()));
      }
      this.translateRoles();
      this.spinner.hide();
    },
      error => {
        notify(error.message, 'error', 5000);
        this.spinner.hide();
      });
  }
  // tslint:enable:no-magic-numbers

  edit(roleId: number, event: Event) {
    event.stopPropagation();

    this.detailsComponent.modalTitle = this.translations.EDIT;
    this.detailsComponent.open(this.items, roleId, this.permissions, this.languages);
  }

  editItem = (e: any) => {
    const platformRoleId = e.row !== undefined ? e.row.data.platformRoleId : e.data.platformRoleId;
    this.detailsComponent.modalTitle = this.translations.EDIT;
    this.detailsComponent.open(this.items, platformRoleId, this.permissions, this.languages);
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.ADD_NEW;
    this.detailsComponent.open(this.items, null, this.permissions, this.languages);
  }

  private translateRoles() {
    this.items.forEach(role => {
      role.roleNameText = this.languageService.getTranslatableText(role.name);
      role.roleDescriptionText = this.languageService.getTranslatableText(role.description);
      role.permissionString = this.buildPermissionsString(role.permissions);
    });
  }

  private buildPermissionsString(permissions: Array<number>) {
    const permissionNames: Array<string> = [];
    permissions.forEach(item => {
      const permissionName = this.permissions.find(p => p.platformPermissionId === item).name;
      permissionNames.push(this.languageService.getTranslatableText(permissionName));
    });
    return permissionNames.join(', ');
  }

  createDataSchema() {

    const schema = new ImportSchema();

    const roleName = new ImportField('roleNameText', this.translations.ROLE_NAME);
    roleName.required = true;

    const roleDescription = new ImportField('roleDescriptionText', this.translations.ROLE_DESCRIPTION);
    roleDescription.required = true;

    const permission = new ImportField('permissionString', this.translations.PERMISSIONS);
    permission.required = true;

    schema.fields.push(roleName, roleDescription, permission);

    this.schema = schema;
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.platformRoleId;

    this.dataService.checkIfRoleHasUsers(this.itemIdToDelete).subscribe(res => {
      if (res.length === 0) {
        this.confirmation.opened = true;
      } else {
        notify(this.translations.ROLE_HAS_USERS, 'error', 5000);
      }
    });

    const alertDuration = 3000;
    timer(alertDuration).subscribe(() => {
      this.roleHasUsers = false;
    });
  }
}
