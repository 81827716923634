<div class="item-list-component">

  <div class="grid-buttons">
    <clr-tooltip>
      <clr-icon shape="add" clrTooltipTrigger size="16" (click)="add()"></clr-icon>
      <clr-tooltip-content class="tooltip-content" clrPosition="top-left" clrSize="sm">
        <span>{{ 'ADD' | translate }}</span>
      </clr-tooltip-content>
    </clr-tooltip>
  </div>

  <clr-datagrid id="printSection">
    <clr-dg-column>{{ 'PRODUCT.PROPERTY.NAME' | translate }}</clr-dg-column>
    <clr-dg-column>{{ 'PRODUCT.PROPERTY.TYPE' | translate }}</clr-dg-column>
    <clr-dg-column>{{ 'PRODUCT.PROPERTY.SYSTEM_FIELD_TYPE' | translate }}</clr-dg-column>
    <clr-dg-column class="no-print tab-two-buttons-column">&nbsp;</clr-dg-column>

    <clr-dg-placeholder>{{'NO_DATA' | translate }}</clr-dg-placeholder>

    <clr-dg-row *clrDgItems="let item of items; let i = index" (click)="edit(item, $event)">
      <clr-dg-cell>{{item.productPropertyNameText}}</clr-dg-cell>
      <clr-dg-cell>{{item.productPropertyTypeText}}</clr-dg-cell>
      <clr-dg-cell>{{item.systemFieldTypeText | translateJson }}</clr-dg-cell>
      <clr-dg-cell class="no-print two-buttons-cell">
        <clr-tooltip role="tooltip" aria-haspopup="true" [clrTooltipDirection]="'top-left'" [clrTooltipSize]="'xs'">
          <clr-icon class="edit-btn" shape="edit" size="16" (click)="edit(item, $event)"></clr-icon>
          <clr-tooltip-content class="tooltip-content">{{ 'EDIT' | translate }}</clr-tooltip-content>
        </clr-tooltip>
        <clr-tooltip role="tooltip" aria-haspopup="true" [clrTooltipDirection]="'top-left'" [clrTooltipSize]="'xs'">
          <clr-icon class="delete-btn" shape="trash" size="16" (click)="delete(item, $event)"></clr-icon>
          <clr-tooltip-content class="tooltip-content">{{ 'DELETE' | translate }}</clr-tooltip-content>
        </clr-tooltip>
      </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer>
      <pagination-component class="footer-pagination" tootltip-content="{{ 'SET_PAGE_SIZE' | translate }}" [pageSizeValue]="pageSizeValue" (changed)="setPageSize($event)"></pagination-component>
      <span class="pagination-fixed footer-pagination">
          <span *ngIf="items?.length > 0">{{ 'PRODUCT.PROPERTY.PAGINATION_TEXT' | translate:{firstItem: pagination.firstItem + 1, lastItem: pagination.lastItem + 1, totalItems: pagination.totalItems} }}</span>
          <span *ngIf="items?.length == 0">{{ 'PRODUCT.PROPERTY.PAGINATION_TEXT_PLACEHOLDER' | translate:{totalItems: pagination.totalItems} }}</span>
          <clr-dg-pagination #pagination [clrDgPageSize]="pageSize"></clr-dg-pagination>
      </span>
    </clr-dg-footer>
  </clr-datagrid>
</div>

<product-property-component (onAdded)="onProductPropertyAdded($event)" (onUpdated)="onProductPropertyUpdated($event)"></product-property-component>
