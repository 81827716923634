import { Injector, Component, OnInit, ViewChildren, OnDestroy, ViewChild } from '@angular/core';
import { forkJoin, map } from 'rxjs';
import * as _moment from 'moment';
import { Moment } from 'moment';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

// general services
import { LanguageService } from '../../shared/services/language.service';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { PrebidOnProductItemComponent } from './prebid-on-product-item.component';
import { Subscription } from 'rxjs';
import notify from 'devextreme/ui/notify';
import DataGrid from 'devextreme/ui/data_grid';

// services
import { LookupTableService } from '../shared/services/lookup-table.service';
import { ProductService } from '../shared/services/product.service';
import { TranslateService } from '@ngx-translate/core';

// models
import { Catalog, GroupFilter, CatalogGroupFilterProperty, CatalogGroupFilterValue } from '../shared/models/catalog';
import { Auction } from '../shared/models/auction';
import { ImportSchema, ImportField } from '../../shared/import/import-schema';
import { Language } from '../../shared/models/language';
import { Product, ProductPropertyTypes, GridProperty } from '../../auction/shared/models/product';
import { PrebidOnProduct } from '../shared/models/prebid';
import { MasterData, MasterDataValue, MasterDataField } from '../shared/models/master-data';
import { Supplier } from '../../shared/models/supplier';
import { PrebidOnProductService } from '../shared/services/prebid-on-product.service';
import { TranslatableFieldComponent } from '../../shared/components/translatable-field/translatable-field.component';
import { Lot, LotProperty } from '../../auction/shared/models/lot';
import { MasterDataService } from '../shared/services/master-data.service';
import { SupplierService } from '../shared/services/supplier.service';
import { DateTimeService } from '../../shared/services/datetime.service';
import { LookupTable, LookupTableField } from '../../shared/models/lookup-table';
import { DxFormComponent } from 'devextreme-angular';



export class MasterDataDropdown {
  value: any;
  label: string;
}

@Component({
  selector: 'prebid-on-product-component',
  templateUrl: 'prebid-on-product.component.html',
  styleUrls: ['./prebid-on-product.component.scss']
})
export class PrebidOnProductComponent extends FullListComponent<Lot, PrebidOnProductItemComponent> implements OnInit, OnDestroy {
  @ViewChildren(TranslatableFieldComponent) translatableFields: Array<TranslatableFieldComponent> = [];

  languages: Array<Language> = [];
  auctionClusterId: number;
  secondRestrictionMasterDataId: number;
  buyerSecondRestrictionMasterDataRowIds: Array<number>;
  productId: number;
  // prebidOnProduct: PrebidOnProduct = new PrebidOnProduct();
  product: Product = new Product();
  prebidPropertyFields = [];
  masterDatas: Array<MasterData> = [];
  masterDataDropdowns: Array<any> = [];
  masterDataDropdownsFiltered: Array<any> = [];
  dropdownsData: Array<any> = [];
  comboBoxes: Array<any> = [];
  suppliers: Array<Supplier> = [];
  firstLoad = true;
  visible = false;
  isEditMode = false;
  errorMessage = null;
  JSON: any = JSON;
  model: Lot = new Lot();
  lookupTables: Array<LookupTable> = [];
  lang = 'nl-be';
  dataGridInstance: DataGrid;
  secondRestrictionLotPropertyFilled = false;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;
  gridColumns = [];
  gridData = [];

  @ViewChild('buyerPrebidOnProductForm') formComponent: DxFormComponent;

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private dataService: PrebidOnProductService,
    private productService: ProductService,
    private masterDataService: MasterDataService,
    private supplierService: SupplierService,
    private lookupTableService: LookupTableService,
    private translateServece: TranslateService,
    private dateTimeService: DateTimeService
  ) {
    super(injector, Lot);
    this.lang = this.translateServece.currentLang;
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.setTranslations('PREBID_ON_PRODUCT');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  open(auctionClusterId: number, productId: number, secondRestrictionMasterDataId: number, buyerSecondRestrictionMasterDataRowIds: Array<number>) {
    this.auctionClusterId = auctionClusterId;
    this.secondRestrictionMasterDataId = secondRestrictionMasterDataId;
    this.buyerSecondRestrictionMasterDataRowIds = buyerSecondRestrictionMasterDataRowIds;
    if (productId !== this.productId) {
      this.firstLoad = true;
    }
    this.productId = productId;

    this.visible = true;

    this.getData();
  }

  getData() {
    if (!this.auctionClusterId || !this.productId) {
      return;
    }
    this.spinner.show();
    forkJoin(
      this.dataService.getProductLots(this.id, this.auctionClusterId, this.productId),
      this.languageService.getLanguages(),
      this.productService.getProduct(this.id, this.auctionClusterId, this.productId)
    ).subscribe((result: Array<any>) => {
      this.items = result[0];
      this.languages = result[1];
      // tslint:disable-next-line:no-magic-numbers
      this.product = result[2];
      if (this.firstLoad) {
        this.lookupTableService.getLookupTables(this.id, this.product.productId).subscribe(lt => {
          this.lookupTables = lt;
        });
        this.getMasterDataListsAndSuppliers();
        this.firstLoad = false;
      } else {
        this.parseData();
        this.parseMasterDataDropdown();
      }
      this.generateForm();
    },
      error => {
        this.errorService.show(error);
        this.spinner.hide();
      });
  }

  initModelOnNew() {
    this.model = new Lot();
    this.parseModel();
    this.isEditMode = false;
  }

  edit(lotId) {
    this.isEditMode = true;
    this.spinner.show();
    this.dataService.getLot(this.id, this.auctionClusterId, lotId)
      .subscribe((res: Lot) => {
        this.model = res;
        this.parseModel();

        this.model.lotProperties.forEach(lp => {
          const lookupTableId = this.isProductWithLookupTable(lp.productPropertyId);

          if (lookupTableId > -1) {
            this.lookupTableService.getLookupTableForProductProperty(this.id, lp.productPropertyId, this.model)
              .subscribe(lookup => {

                if (lookup) {
                  const md = this.masterDataDropdowns.find(dd => dd.productPropertyId === lp.productPropertyId);
                  const index = this.masterDataDropdowns.indexOf(md);
                  if (index !== -1) {
                    this.doApplyLookup(lookup, index, lp);
                  }

                }
              }, error => {
              });
          }
        });

        this.model.lotProperties = this.model.lotProperties.filter(e => e.propertyTypeId !== undefined);
        this.sortArray(this.model.lotProperties, 'orderNumber', 1);
        this.setComboBoxes();
        this.spinner.hide();
      },
        error => {
          this.errorService.show(error);
          this.spinner.hide();
        });
  }

  createEmtpyObjectForTranslatableFields() {
    // Create empty JSON object for translation fields
    const emptyTranslation = {};
    this.languages.forEach(lang => {
      emptyTranslation[lang.code] = '';
    });

    return JSON.stringify(emptyTranslation);
  }


  dateChanged(property: any, dateTime: Moment) {
    const matches = property.dataField.match(/\[(.*?)\]/);
    this.model.lotProperties[Number(matches[1])].dateTimeValue = moment(dateTime).toDate();
  }

  dateValue(property: any) {
    const matches = property.dataField.match(/\[(.*?)\]/);
    return this.model.lotProperties[Number(matches[1])].dateTimeValue;
  }

  //setLotPropertyValue(lotProperty: LotProperty, value: any) {
  //  let isSame = false;
  //  switch (lotProperty.propertyTypeId) {
  //    case ProductPropertyTypes.MASTER_DATA_VALUE:
  //      if (lotProperty.masterDataListRowId === value) {
  //        isSame = true;
  //      }
  //      lotProperty.masterDataListRowId = value;
  //      break;
  //    case ProductPropertyTypes.NUMBER:
  //      if (lotProperty.intValue === value) {
  //        isSame = true;
  //      }
  //      lotProperty.intValue = value;
  //      break;
  //    case ProductPropertyTypes.DECIMAL:
  //      if (lotProperty.decimalValue === value) {
  //        isSame = true;
  //      }
  //      lotProperty.decimalValue = value;
  //      break;
  //    case ProductPropertyTypes.TEXT:
  //      if (lotProperty.stringValue === value) {
  //        isSame = true;
  //      }
  //      lotProperty.stringValue = value;
  //      break;
  //    case ProductPropertyTypes.DATE:
  //      if (lotProperty.dateTimeValue === value) {
  //        isSame = true;
  //      }
  //      const serverReturnedTime = moment.utc(value);
  //      lotProperty.dateTimeValue = moment.utc(serverReturnedTime).locale(this.lang).local();
  //      // lotProperty.dateTimeValue = value;
  //      break;
  //    case ProductPropertyTypes.BOOLEAN:
  //      if (lotProperty.booleanValue === value) {
  //        isSame = true;
  //      }
  //      lotProperty.booleanValue = value;
  //      break;
  //    default:
  //      break;
  //  }

  //  return isSame;
  //}

  clearLotPropertyValue(lotProperty: LotProperty) {
    const lp = this.model.lotProperties.find(lp => lp.lotPropertyId === lotProperty.lotPropertyId);
    if (lp) {
      lp.masterDataListRowId = null;
    }
  }

  setComboBoxes() {
    this.comboBoxes = [];
    this.model.lotProperties.forEach((lp, i) => {
      this.comboBoxes[i] = this.getDropdownValues(lp);
      if (this.comboBoxes[i]) {
        const comboBoxValue = this.comboBoxes[i].find(c => c.value === lp.masterDataListRowId);
        if (!comboBoxValue) {
          lp.masterDataListRowId = null;
        }
      }
    });
  }

  getDropdownValues(lotProperty: LotProperty) {
    const filtered = this.masterDataDropdownsFiltered.find(dd => dd.productPropertyId === lotProperty.productPropertyId);
    if (filtered && filtered.values.length > 0) {
      return this.filterMasterDataListBySecondRestriction(filtered);
    } else {
      const unfiltered = this.masterDataDropdowns.find(dd => dd.productPropertyId === lotProperty.productPropertyId);
      if (unfiltered && unfiltered.values.length > 0)
        return this.filterMasterDataListBySecondRestriction(unfiltered);
    }

    const masterDataDropdown = this.dropdownsData.find(dd => dd.productPropertyId === lotProperty.productPropertyId);
    if (masterDataDropdown) {
      const values = masterDataDropdown.values;

      const currentValue = values.find(v => v.value === lotProperty.masterDataListRowId);
      if (!currentValue) {
        const md = this.masterDataDropdowns.find(dd => dd.productPropertyId === lotProperty.productPropertyId);
        if (md) {
          const row = md.values.find(v => v.value === lotProperty.masterDataListFieldId);
          if (row) {
            values.push(row);
          }
        }
      }
      values.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }

        return 0;
      });

      //if (values.length === 1) {
      //  const isSame = this.setLotPropertyValue(lotProperty, values[0].value);
      //  if (!isSame) {
      //    this.onPropertyChanged(lotProperty);
      //  }
      //}

      return values;
    }
  }

  parseBeforeSave() {
    this.model.lotProperties.forEach(lotProperty => {
      if (lotProperty.propertyTypeId === ProductPropertyTypes.DATE && lotProperty.dateTimeValue) {
        lotProperty.dateTimeValue = moment.utc(lotProperty.dateTimeValue);
      }

      if (lotProperty.propertyTypeId === ProductPropertyTypes.TEXT && lotProperty.translatable) {
        const field = this.translatableFields.find(tf => tf.componentIndex === lotProperty.productPropertyId);
        if (field) {
          lotProperty.stringValue = field.data;
        }
      }
    });
  }

  save() {
    this.parseBeforeSave();

    if (this.model.lotId) {
      this.isEditMode = true;
    }

    if (this.isEditMode) {
      this.spinner.show();
      this.dataService.editLot(this.id, this.auctionClusterId, this.model)
        .subscribe((res: any) => {
          this.model = new Lot();
          this.initModelOnNew();
          this.errorMessage = null;
          this.spinner.hide();
          this.getData();
        },
          err => {
            if (err.error && err.error.length > 0 && err.error[0].ErrorMessage === 'RESTRCTED_LOT_PROPERTY_VALUE') {
              this.errorMessage = this.translations.RESTRCTED_LOT_PROPERTY_VALUE;
              let lotPropertyName = this.model.lotProperties.find(_ => _.productPropertyId ===  err.error[0].ProductPropertyId).name;
              this.errorMessage += ' Lot property name: ' + lotPropertyName;
              notify(this.errorMessage, 'error', 5000);
            } else {
              this.errorMessage = `${err.status}: ${err.statusText}`;
              notify(this.errorMessage, 'error', 5000);
            }
            this.spinner.hide();
          });
    } else {
      this.spinner.show();
      this.dataService.saveLotForProduct(this.id, this.auctionClusterId, this.productId, this.model)
        .subscribe((res: any) => {
          this.model = new Lot();
          this.initModelOnNew();
          this.errorMessage = null;
          this.spinner.hide();
          this.getData();
        },
          err => {
            if (err.error && err.error.length > 0 && err.error[0].ErrorMessage === 'RESTRCTED_LOT_PROPERTY_VALUE') {
              this.errorMessage = this.translations.RESTRCTED_LOT_PROPERTY_VALUE;
              let lotPropertyName = this.model.lotProperties.find(_ => _.productPropertyId ===  err.error[0].ProductPropertyId).name;
              this.errorMessage += ' Lot property name: ' + lotPropertyName;
              notify(this.errorMessage, 'error', 5000);
            } else {
              this.errorMessage = `${err.status}: ${err.statusText}`;
              notify(this.errorMessage, 'error', 5000);
            }
            this.spinner.hide();
          });
    }
  }

  parseModel() {
    if (this.model.lotProperties.length === 0) {
      this.prebidPropertyFields.forEach(ppf => {
        // check if already added
        const alreadyAddedProp = this.model.lotProperties.find(p => p.productPropertyId === ppf.productPropertyId);
        if (!alreadyAddedProp) {
          const newObj = new LotProperty();
          newObj.orderNumber = ppf.orderNumber;
          newObj.masterDataListFieldId = ppf.masterDataListFieldId;
          newObj.name = ppf.name;
          newObj.propertyTypeId = ppf.propertyTypeId;
          newObj.propertyTypeFormatId = ppf.propertyTypeFormatId;
          newObj.productPropertyId = ppf.productPropertyId;
          newObj.translatable = ppf.translatable;
          newObj.masterDataListId = ppf.masterDataListId;
          if (newObj.translatable) {
            newObj.stringValue = this.createEmtpyObjectForTranslatableFields();
          }

          this.model.lotProperties.push(newObj);
        }
      });
    } else {
      this.model.lotProperties.forEach(lp => {
        const field = this.prebidPropertyFields.find(ppf => ppf.productPropertyId === lp.productPropertyId);
        if (field) {
          lp.orderNumber = field.orderNumber;
          lp.masterDataListFieldId = field.masterDataListFieldId;
          lp.name = field.name;
          lp.propertyTypeId = field.propertyTypeId;
          lp.propertyTypeFormatId = field.propertyTypeFormatId;
          lp.translatable = field.translatable;
          lp.masterDataListId = field.masterDataListId;

          if (lp.propertyTypeId === ProductPropertyTypes.DATE && lp.dateTimeValue) {
            const serverReturnedTime = moment.utc(lp.dateTimeValue);
            lp.dateTimeValue = moment(serverReturnedTime).local();
          }
        }
      });
    }

    this.model.lotProperties.sort((a, b) => a.orderNumber - b.orderNumber);

    this.setComboBoxes();
  }

  getPropIdsForMasterDataFields(lookupTableFields: Array<LookupTableField>) {
    const ids = [];
    lookupTableFields.forEach(ltf => {
      if (ltf.isResult) {
        this.product.productProperties.forEach(pp => {
          if (pp.masterDataListId === ltf.masterDataListId) {
            ids.push(pp.productPropertyId);
          }
        });
      }
    });

    return ids;
  }

  getLookupDependentPropIds(productPropertyId: number) {
    let dependentPropIds = [];
    const productProperty = this.product.productProperties.find(f => f.productPropertyId === productPropertyId);
    if (!productProperty) {
      return dependentPropIds;
    }
    const productPropertyMasterData = productProperty.masterDataListId;
    if (!productPropertyMasterData) {
      return dependentPropIds;
    }
    for (let i = 0; i < this.lookupTables.length; i += 1) {
      if (this.lookupTables[i].lookupTableType === 1) {
        continue;
      }
      for (let j = 0; j < this.lookupTables[i].lookupTableFields.length; j += 1) {
        if (!this.lookupTables[i].lookupTableFields[j].isResult
          && this.lookupTables[i].lookupTableFields[j].masterDataListId === productPropertyMasterData) {
          const propIds = this.getPropIdsForMasterDataFields(this.lookupTables[i].lookupTableFields);
          dependentPropIds = [...dependentPropIds, ...propIds];
        }
      }
    }

    return dependentPropIds;
  }

  isProductWithLookupTable(productPropertyId: number) {
    for (let i = 0; i < this.product.productProperties.length; i += 1) {
      if (this.product.productProperties[i].productPropertyId === productPropertyId
        && this.product.productProperties[i].lookupTableId) {
        return this.product.productProperties[i].lookupTableId;
      }
    }

    return -1;
  }

  doApplyLookup(lookup: LookupTable, i: number, property: LotProperty) {
    if (lookup) {
      if (lookup.lookupTableType === 0 && lookup.lookupTableRows.length > 0) {
        const newValues = [];
        lookup.lookupTableRows.forEach(row => {
          row.lookupTableValues.forEach(value => {
            this.masterDataDropdowns[i].values.forEach(mdValue => {
              if (mdValue.value === value.masterDataListRowId && newValues.indexOf(mdValue) === -1) {
                newValues.push(mdValue);
              }
            });
          });
        });

        if (newValues.length > 0) {

          this.masterDataDropdownsFiltered[i] = {
            productPropertyId: this.masterDataDropdowns[i].productPropertyId, values: newValues, filtered: true
          };

          var existingData = this.dropdownsData.find(d => d.productPropertyId == this.masterDataDropdownsFiltered[i].productPropertyId);
          if (existingData) {
            var indexToDelete = this.dropdownsData.indexOf(existingData);
            this.dropdownsData.splice(indexToDelete, 1);
          }
          this.dropdownsData.push({ productPropertyId: this.masterDataDropdownsFiltered[i].productPropertyId, values: newValues });
          const currentLotProperty = this.model.lotProperties.find(lp => lp.productPropertyId === this.masterDataDropdowns[i].productPropertyId);
          if (currentLotProperty && currentLotProperty.masterDataListRowId) {
            var foundValue = newValues.find(v => v.value === currentLotProperty.masterDataListRowId);
            if (!foundValue) {
              currentLotProperty.masterDataListRowId = null;
            }
          }
        }
      } else {
        {
          var existingData = this.dropdownsData.find(d => d.productPropertyId == this.masterDataDropdowns[i].productPropertyId);
          if (existingData) {
            var indexToDelete = this.dropdownsData.indexOf(existingData);
            this.dropdownsData.splice(indexToDelete, 1);
          }
          this.dropdownsData.push(this.masterDataDropdowns[i]);
        }
      }
    } else {
      {
        var existingData = this.dropdownsData.find(d => d.productPropertyId == this.masterDataDropdowns[i].productPropertyId);
        if (existingData) {
          var indexToDelete = this.dropdownsData.indexOf(existingData);
          this.dropdownsData.splice(indexToDelete, 1);
        }
        this.dropdownsData.push(this.masterDataDropdowns[i]);
      }
    }
    this.setComboBoxes();
  }

  onPropertyChanged(property: LotProperty) {

    const productPropertyIds = this.getLookupDependentPropIds(property.productPropertyId);

    if (productPropertyIds.length === 0) {
      return;
    }

    // clear values first
    //this.dropdownsData.length = 0;
    for (let i = 0; i < this.masterDataDropdowns.length; i += 1) {
      let checkThisProp = this.masterDataDropdowns[i].productPropertyId !== property.productPropertyId;
      if (productPropertyIds.length > 0 && productPropertyIds.indexOf(this.masterDataDropdowns[i].productPropertyId) === -1) {
        checkThisProp = false;
      }
      const lookupTableId = this.isProductWithLookupTable(this.masterDataDropdowns[i].productPropertyId);
      if (checkThisProp && lookupTableId !== -1) {
        this.lookupTableService.getLookupTableForProductProperty(this.id, this.masterDataDropdowns[i].productPropertyId, this.model)
          .subscribe(lookup => {
            this.doApplyLookup(lookup, i, property);
          }, error => {
            {
              var existingData = this.dropdownsData.find(d => d.productPropertyId == this.masterDataDropdowns[i].productPropertyId);
              if (existingData) {
                var indexToDelete = this.dropdownsData.indexOf(existingData);
                this.dropdownsData.splice(indexToDelete, 1);
              }
              this.dropdownsData.push(this.masterDataDropdowns[i]);
            }
            this.setComboBoxes();
          });
      } else {
        {
          var existingData = this.dropdownsData.find(d => d.productPropertyId == this.masterDataDropdowns[i].productPropertyId);
          if (existingData) {
            var indexToDelete = this.dropdownsData.indexOf(existingData);
            this.dropdownsData.splice(indexToDelete, 1);
          }
          this.dropdownsData.push(this.masterDataDropdowns[i]);
        }
        if (productPropertyIds.length > 0) {
          this.setComboBoxes();
        }
      }
    }
  }

  getSuppliers() {
    this.supplierService.getSuppliers(this.auctionClusterId).subscribe(s => {
      this.suppliers = s;
    });
  }

  getLotPropertyDisplayValue(lotProperties: Array<LotProperty>, propField: any) {
    const lp = lotProperties.find(p => p.productPropertyId === propField.productPropertyId);

    if (lp) {
      return lp.displayValue;
    }

    return '-';
  }

  parseMasterDataDropdown() {
    this.prebidPropertyFields.forEach(lotProp => {
      const dropdownValues: Array<MasterDataDropdown> = [];
      if (lotProp.propertyTypeId === ProductPropertyTypes.MASTER_DATA_VALUE) {
        const masterData = this.masterDatas
          .find(md => md.masterDataListId === this.product.productProperties
            .find(pp => pp.productPropertyId === lotProp.productPropertyId).masterDataListId);

        const useInCatalogFields = masterData.fields.filter(f => f.useFieldInCatalog);

        if (useInCatalogFields.length === 0) {
          useInCatalogFields.push(masterData.fields[0]);
        }

        useInCatalogFields.forEach(field => {
          masterData.rows.forEach(row => {
            row.values.forEach(rowValue => {
              if (rowValue.masterDataListFieldId === field.masterDataListFieldId) {
                switch (field.propertyTypeId) {
                  case ProductPropertyTypes.NUMBER:
                    if (rowValue.intValue !== null) {
                      dropdownValues.push({ value: row.masterDataListRowId, label: (rowValue.intValue).toString() });
                    }
                    break;
                  case ProductPropertyTypes.TEXT:
                    if (rowValue.stringValue !== null) {
                      let text = rowValue.stringValue;
                      if (field.translatable) {
                        text = this.getTranslation(text);
                      }
                      if (text) {
                        dropdownValues.push({ value: row.masterDataListRowId, label: text.replace(/<[^>]*>/g, '') });
                      }
                    }
                    break;
                  case ProductPropertyTypes.DECIMAL:
                    if (rowValue.decimalValue !== null) {
                      dropdownValues.push({ value: row.masterDataListRowId, label: (rowValue.decimalValue).toString() });
                    }
                    break;
                  case ProductPropertyTypes.DATE:
                    if (rowValue.dateTimeValue !== null) {
                      const dateString = this.dateTimeService
                        .getDateStringByFormatAnyUtc(rowValue.dateTimeValue, field.propertyTypeFormatId);
                      dropdownValues.push({ value: row.masterDataListRowId, label: dateString });
                    }
                    break;
                  case ProductPropertyTypes.BOOLEAN:
                    if (rowValue.booleanValue !== null) {
                      dropdownValues.push({
                        value: row.masterDataListRowId,
                        label: `${rowValue.booleanValue ? 'true' : 'false'}`
                      });
                    }
                    break;
                  default: break;
                }
              }
            });
          });
        });
      }

      for (let i = 0; i < dropdownValues.length; i++) {
        for (let j = i + 1; j < dropdownValues.length; j++) {
          if (dropdownValues[i].value === dropdownValues[j].value) {
            if (dropdownValues[j].label !== '') {
              dropdownValues[i].label = dropdownValues[i].label + ' - ' + dropdownValues[j].label;
            }
            dropdownValues.splice(j, 1);
          }
        }
      }
      this.masterDataDropdowns.push({ productPropertyId: lotProp.productPropertyId, values: dropdownValues });
    });

    this.masterDataDropdownsFiltered = JSON.parse(JSON.stringify(this.masterDataDropdowns));
    this.dropdownsData.length = 0;
    this.dropdownsData = JSON.parse(JSON.stringify(this.masterDataDropdowns));

    this.initModelOnNew();
  }

  getMasterDataListsAndSuppliers() {
    const masterDataListIds: Array<number> = [];
    this.product.productProperties.forEach(property => {
      if (property.masterDataListId) {
        if (masterDataListIds.indexOf(property.masterDataListId) === -1) {
          masterDataListIds.push(property.masterDataListId);
        }
      }
    });

    this.supplierService.getSuppliers(this.auctionClusterId).subscribe(s => {
      this.suppliers = s;

      const observables = [];

      masterDataListIds.forEach(id => {
        observables.push(this.masterDataService.getMasterData(this.id, this.auctionClusterId, id)
          .pipe(map((result: MasterData) => result)));
      });

      forkJoin(
        observables
      ).subscribe(res => {
        res.forEach((result: MasterData) => {
          this.masterDatas.push(result);
          const catalogMasterDataIds: Array<number> = [];
          this.masterDatas.forEach(masterData => {
            masterData.fields.forEach(masterDataField => {
              if (masterDataField.useFieldInCatalog) {
                catalogMasterDataIds.push(masterDataField.masterDataListFieldId);
              }
            });
          });
        });
        this.parseData();
        this.parseMasterDataDropdown();
      });
    });
  }

  getMasterDataFieldName(selection: GridProperty) {
    for (let i = 0; i < this.masterDatas.length; i += 1) {
      for (let j = 0; j < this.masterDatas[i].fields.length; j += 1) {
        if (this.masterDatas[i].fields[j].masterDataListFieldId === selection.masterDataListFieldId) {
          return this.getTranslation(this.masterDatas[i].fields[j].name);
        }
      }
    }
  }

  parseData() {
    const prebidPropertyFields = [];
    const prebidSelection = this.product.prebidOnProductBuyer.sort((a, b) => a.orderNumber - b.orderNumber);
    prebidSelection.forEach(selection => {
      let fieldObj = null;

      const productProperty = this.product.productProperties.find(p => p.productPropertyId === selection.productPropertyId);
      if (productProperty) {
        let name = this.getTranslation(productProperty.name);
        if (productProperty.propertyTypeId === ProductPropertyTypes.MASTER_DATA_VALUE) {
          const fieldName = this.getMasterDataFieldName(selection);
          name = `${name} (${fieldName})`;
        }
        fieldObj = {
          ...selection,
          name,
          propertyTypeId: productProperty.propertyTypeId,
          propertyTypeFormatId: productProperty.propertyTypeFormatId,
          translatable: productProperty.translatable,
          masterDataListId: productProperty.masterDataListId
        };
      }

      if (fieldObj) {
        prebidPropertyFields.push(fieldObj);
      }
    });

    this.prebidPropertyFields = prebidPropertyFields;

    this.items.forEach(i => {
      this.prebidPropertyFields.forEach(pp => {
        const lotProperty = i.lotProperties.find(lp => lp.productPropertyId === pp.productPropertyId);
        if (lotProperty) {
          const displayLotProp = JSON.parse(JSON.stringify(lotProperty));
          displayLotProp.displayValue = this.findPropertyValue(lotProperty, pp);
          if (!i.displayLotProperties) {
            i.displayLotProperties = [];
          }
          i.displayLotProperties.push(displayLotProp);
        }
      });
    });

    this.generateTable();
  }

  findMasterDataValue(masterDataValue: MasterDataValue, masterDataField: MasterDataField): any {
    if (masterDataValue.decimalValue) {
      return masterDataValue.decimalValue;
    } else if (masterDataValue.intValue) {
      return masterDataValue.intValue;
    } else if (masterDataValue.stringValue) {
      if (masterDataField.translatable) {
        return this.getTranslation(masterDataValue.stringValue);
      }
      return masterDataValue.stringValue;
    } else if (masterDataField.propertyTypeId === ProductPropertyTypes.BOOLEAN) {
      return masterDataValue.booleanValue ? 'true' : 'false';
    } else if (masterDataValue.dateTimeValue) {
      return this.dateTimeService.getDateStringByFormatAnyUtc(masterDataValue.dateTimeValue, masterDataField.propertyTypeFormatId);
    }
  }


  getMasterDataValue(lotProperty: LotProperty, masterDataListFieldId) {
    const masterDataListRowId = lotProperty.masterDataListRowId;
    let value = masterDataListRowId.toString();
    this.masterDatas.forEach(md => {
      md.rows.forEach(r => {
        if (r.masterDataListRowId === masterDataListRowId) {
          r.values.forEach(v => {
            if (v.masterDataListFieldId === masterDataListFieldId) {
              value = this.findMasterDataValue(v, md.fields.find(f => f.masterDataListFieldId === v.masterDataListFieldId));
            }
          });
        }
      });
    });
    return value;
  }


  findPropertyValue(lotProperty: LotProperty, prop: any): any {
    if (lotProperty.dateTimeValue) {
      return this.dateTimeService.getDateStringByFormatAnyUtc(lotProperty.dateTimeValue, prop.propertyTypeFormatId);
    } else if (lotProperty.decimalValue) {
      return lotProperty.decimalValue;
    } else if (lotProperty.intValue) {
      return lotProperty.intValue;
    } else if (lotProperty.stringValue) {
      if (prop.translatable) {
        return this.getTranslation(lotProperty.stringValue);
      }
      return lotProperty.stringValue;
    } else if (prop.propertyTypeId === ProductPropertyTypes.BOOLEAN) {
      return lotProperty.booleanValue ? 'true' : 'false';
    } else if (lotProperty.masterDataListRowId) {
      // return lotProperty.masterDataListRowId;
      return this.getMasterDataValue(lotProperty, prop.masterDataListFieldId);
    }

    return '';
  }


  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  deleteSelected() {
    this.spinner.show();
    this.dataService.deleteLot(this.id, this.auctionClusterId, this.itemIdToDelete)
      .subscribe((lots: Array<Lot>) => {
        this.getData();
        this.spinner.hide();
      },
        error => {
          this.errorService.show(this.errorService.translations.DELETE_ERROR_MESSAGE);
          this.spinner.hide();
        });
  }

  createDataSchema() {

    const schema = new ImportSchema();

    this.schema = schema;
  }

  close() {
    this.visible = false;
  }

  initColumn = (e: any) => {
    this.dataGridInstance = e.component;
  }

  generateTable = () => {
    const gridCurrentColums = this.dataGridInstance.getVisibleColumns();
    const items = [];
    const columnsArray = [];

    this.prebidPropertyFields.forEach((row: any, i: number) => {
      let columnName = this.getTranslation(row.name);
      if (columnsArray.includes(columnName)) {
        let j = 0;
        do {
          columnName = this.getTranslation(row.name) + j;
          j++;
        } while (columnsArray.includes(columnName));
      }
      columnsArray.push(columnName);
      const isColumn = gridCurrentColums.find(c => c.dataField === columnName);
      if (!isColumn) {
        this.dataGridInstance.addColumn({
          dataField: columnName,
          caption: this.getTranslation(row.name),
          visibleIndex: i,
          allowEditing: false,
          encodeHtml: false,
          editorOptions: {
            showClearButton: true
          }
        });
      }
    });

    this.items.forEach(row => {
      let item = {};
      columnsArray.forEach((column, i) => {
        item[column] = row.displayLotProperties[i].displayValue;
      });

      item['__item__'] = row;

      items.push(item);
    });
    this.dataGridInstance.option('dataSource', items);
  }

  generateFields = () => {
    const generatedItems = [];
    this.model.lotProperties = this.model.lotProperties.filter(e => e.propertyTypeId !== undefined);
    this.sortArray(this.model.lotProperties, 'orderNumber', 1);

    return new Promise((resolve, reject) => {

      this.model.lotProperties.forEach((field, i) => {
        if (field.propertyTypeId === 1 && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].intValue',
              editorType: 'dxNumberBox',
              label: { text: field.name },
              editorOptions: {
                showSpinButtons: true,
                mode: 'number',
                format: '#0',
                min: 0,
                step: 1
              }
            }
          );
        } else if (field.propertyTypeId === 2 && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].decimalValue',
              editorType: 'dxNumberBox',
              label: { text: field.name },
              editorOptions: {
                showSpinButtons: true,
                mode: 'number',
                format: '#0.0',
                min: 0,
                step: 0.1
              }
            }
          );
        } else if (field.propertyTypeId === 3 && !field.translatable && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].stringValue',
              label: { text: field.name }
            }
          );
        } else if (field.propertyTypeId === 3 && field.translatable && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].stringValue',
              label: { text: field.name },
              template: 'translatableField'
            }
          );
        } else if (field.propertyTypeId === 4 && field.propertyTypeFormatId === 11 && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].dateTimeValue',
              label: { text: field.name },
              template: 'fullDateTemplate'
            }
          );
        } else if (field.propertyTypeId === 4 && field.propertyTypeFormatId === 12 && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].dateTimeValue',
              label: { text: field.name },
              template: 'longDateTemplate'
            }
          );
        } else if (field.propertyTypeId === 4 && field.propertyTypeFormatId === 13 && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].dateTimeValue',
              label: { text: field.name },
              template: 'longTimeTemplate'
            }
          );
        } else if (field.propertyTypeId === 4 && field.propertyTypeFormatId === 14 && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].dateTimeValue',
              label: { text: field.name },
              template: 'shortDateTemplate'
            }
          );
        } else if (field.propertyTypeId === 4 && field.propertyTypeFormatId === 15 && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].dateTimeValue',
              label: { text: field.name },
              template: 'shortTimeTemplate'
            }
          );
        } else if (field.propertyTypeId === 5 && this.model.lotProperties[i]) {

          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].masterDataListRowId',
              label: { text: field.name },
              editorType: 'dxSelectBox',
              editorOptions: {
                dataSource: this.comboBoxes[i],
                valueExpr: 'value',
                displayExpr: 'label',
                searchEnabled: true,
                showClearButton: true,
                onValueChanged: (e: any) => {
                  this.onPropertyChanged(field);
                },
                onOpened: (e: any) => {
                  this.onOpenedBoxes(e, i);
                }
              }
            }
          );
        } else if (field.propertyTypeId === 7 && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].booleanValue',
              editorType: 'dxCheckBox',
              label: { text: field.name }
            }
          );
        }
      });

      const intervalResolve = setInterval(() => {
        if (generatedItems.length >= this.model.lotProperties.length) {
          resolve(generatedItems);
          clearInterval(intervalResolve);
        }
      }, 50);

    });
  }

  onOpenedBoxes = (e: any, index: number) => {
    e.component.option('dataSource', this.comboBoxes[index]);
  }

  generateForm = async () => {
    let items = [];
    let generatedItems = null;
    this.formComponent.instance.option('items', items);
    const intervalLotProperties = setInterval(async () => {
      items = [];
      generatedItems = null;
      if (this.model.lotProperties.length > 0) {
        clearInterval(intervalLotProperties);
        generatedItems = await this.generateFields();
        items.push(
          {
            itemType: 'group',
            caption: '',
            items: generatedItems
          });
        this.formComponent.instance.option('items', items);
        this.spinner.hide();
      }
    }, 50);
  }


  sortArray(array, property, direction) {
    direction = direction || 1;
    array.sort(function compare(a, b) {
      let comparison = 0;
      if (a[property] > b[property]) {
        comparison = 1 * direction;
      } else if (a[property] < b[property]) {
        comparison = -1 * direction;
      }
      return comparison;
    });
    return array; // Chainable
  }

  checkIsSecondRestrictionLotPropertyFilled() {
    this.secondRestrictionLotPropertyFilled = true;
    if (this.secondRestrictionMasterDataId) {
      let secondRestrictionLotProperty = this.model.lotProperties.find(_ => _.masterDataListId === this.secondRestrictionMasterDataId);
      if (!secondRestrictionLotProperty || !secondRestrictionLotProperty.masterDataListRowId) {
        this.secondRestrictionLotPropertyFilled = false;
      } 
    }
  }

  filterMasterDataListBySecondRestriction(masterDataList) {
    let unfilteredValues = masterDataList.values;
    if (this.secondRestrictionMasterDataId) {
      let secondRestrictedProductPropertyId = this.model.lotProperties.find(_ => _.masterDataListId === this.secondRestrictionMasterDataId).productPropertyId;
      if (masterDataList.productPropertyId === secondRestrictedProductPropertyId) {
        return masterDataList.values.filter(item => !this.buyerSecondRestrictionMasterDataRowIds.includes(item.value))
      } 
    } 

    return unfilteredValues;
  }

  add() { }
}
