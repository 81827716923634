<div class="shopping-list-component item-list-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup
  #addShoppingList
  [width]="600"
  [showTitle]="true"
  title="{{ modalTitle }}"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [rtlEnabled]="rtlEnabled"
  [(visible)]="isOpened"
  (onShown)="onFieldDataChanged($event)">
    <dx-scroll-view width="100%" height="100%">
      <dx-form
            #userForm
            id="userForm"
            [showValidationSummary]="false"
            (onFieldDataChanged)="onFieldDataChanged($event)"
            colCount="auto"
            [(formData)]="model"
            [minColWidth]="500">

            <dxi-item itemType="group" caption="{{ 'SHOPPING_LIST.GENERAL' | translate }}">
              <dxi-item dataField="name">
                <dxo-label text="{{ 'SHOPPING_LIST.NAME' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SHOPPING_LIST.NAME_VALIDATION' | translate }}"></dxi-validation-rule>
              </dxi-item>
              <dxi-item dataField="description" editorType="dxTextArea" [editorOptions]="{ height: 90 }" [colSpan]="2">
                <dxo-label text="{{ 'SHOPPING_LIST.DESCRIPTION' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item 
                  dataField="shoppingListReferenceCatalogId"
                  editorType="dxSelectBox"
                  [editorOptions]="{ items: catalogs, valueExpr:'catalogId' ,  
                  displayExpr:'name'}">
                <dxo-label text="{{ 'SHOPPING_LIST.CATALOG' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SHOPPING_LIST.CATALOG_VALIDATION' | translate }}"></dxi-validation-rule>
              </dxi-item>
            </dxi-item>

      </dx-form>

      <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnShoppingListSubmit" disabled="disabled">
        {{ 'SAVE' | translate }}
      </button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'CANCEL' | translate }}</button>
    </dx-scroll-view>
  </dx-popup>
</div>
