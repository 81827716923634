<div class="browser-info">
  <div class="alert alert-info" role="alert">
      <div class="alert-items">
          <div class="alert-item static">
              <div class="alert-icon-wrapper">
                  <clr-icon class="alert-icon" shape="info-circle"></clr-icon>
              </div>
              <span class="alert-text">{{ 'BROWSER_INFO' | translate }}</span>
          </div>
      </div>
  </div>
</div>
  
  