<div class="gallery-seat-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup #addGallerySeat [width]="600" [showTitle]="true" title="{{ modalTitle }}" [dragEnabled]="false"
            [closeOnOutsideClick]="false" [rtlEnabled]="rtlEnabled" [(visible)]="isOpened"
            (onShown)="onFieldDataChanged($event)">
    <dx-scroll-view width="100%" height="100%">
      <dx-form #masterDataFieldForm id="masterDataFieldForm" [showValidationSummary]="false"
               (onFieldDataChanged)="onFieldDataChanged($event)" colCount="auto" [(formData)]="model" [minColWidth]="500">
        <dxi-item itemType="group">
          <dxi-item dataField="auctionId" editorType="dxSelectBox"
                    [editorOptions]="{ items: auctions, valueExpr:'auctionId', displayExpr:'name'}">
            <dxo-label text="{{ 'GALLERY.AUCTION' | translate }}"></dxo-label>
            <dxi-validation-rule type="required" message="{{ 'GALLERY.AUCTION_VALIDATION' | translate }}">
            </dxi-validation-rule>
          </dxi-item>

          <dxi-item dataField="seatNumber" editorType="dxNumberBox">
            <dxo-label text="{{ 'GALLERY.SEAT_NUMBER' | translate }}"></dxo-label>
            <dxi-validation-rule type="required" message="{{ 'GALLERY.SEAT_NUMBER_VALIDATION' | translate }}"></dxi-validation-rule>
          </dxi-item>

          <dxi-item dataField="buyerId" editorType="dxSelectBox"
                    [editorOptions]="{ items: buyers, valueExpr:'buyerId', displayExpr:'buyerNumber', searchEnabled: true }">
            <dxo-label text="{{ 'GALLERY.BUYER' | translate }}"></dxo-label>
            <dxi-validation-rule type="required" message="{{ 'GALLERY.BUYER_VALIDATION' | translate }}">
            </dxi-validation-rule>
          </dxi-item>

          <dxi-item dataField="reserved" editorType="dxCheckBox">
            <dxo-label text="{{ 'GALLERY.RESERVED' | translate }}"></dxo-label>
          </dxi-item>
        </dxi-item>
      </dx-form>
      <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnGallerySeatSubmit"
              disabled="disabled">
        {{ 'SAVE' | translate }}
      </button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'CANCEL' | translate }}</button>
    </dx-scroll-view>
  </dx-popup>

  <confirmation-component #confirmation title="{{ 'GALLERY.GALLERY_SEAT_NOT_UNIQUE' | translate }}"
                          message="{{ 'GALLERY.GALLERY_SEAT_OVERRIDE_CONFIRMATION_MESSAGE' | translate }}" buttonCloseText="CONFIRM" cancellable="false"
                          (close)="overrideSeat()" (cancel)="confirmation.opened=false"></confirmation-component>
</div>
