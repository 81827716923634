<div class="item-list-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <div class="grid-buttons">
    <dx-button hint="{{ 'ADD' | translate }}" icon="add" (onClick)="add()"></dx-button>
  </div>
  <br/>
  <dx-data-grid
                #maskFieldsGrid
                id="printSection"
                [rtlEnabled]="rtlEnabled"
                [showBorders]="true"
                [allowColumnReordering]="true"
                [hoverStateEnabled]="true"
                [columnAutoWidth]="true"
                [rowAlternationEnabled]="true"
    (onInitialized)="initColumn($event)"
    (onRowClick)="openEditClick($event)">
      <dxo-selection mode="single"></dxo-selection>
      <dxo-pager 
        [showPageSizeSelector]="true" 
        [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]" 
        [showNavigationButtons]="true" 
        [showInfo]="true" 
        infoText="{2} {{ 'MASK.FIELD.PAGINATION_TEXT_PLACEHOLDER' | translate | slice:2 }}">
    </dxo-pager>
    <dxi-column dataField="name" caption="{{ 'MASK.FIELD_NAME' | translate }}" dataType="string" [cellTemplate]="'itemNameCell'">
    </dxi-column>
    <dxi-column dataField="isResult" caption="{{ 'MASK.IS_RESULT' | translate }}" dataType="boolean">
    </dxi-column>
    <dxi-column type="buttons" class="btns-column">
      <dxi-button hint="{{ 'EDIT' | translate }}" icon="edit" [onClick]="editItem"></dxi-button>
      <dxi-button hint="{{ 'DELETE' | translate }}" icon="trash" [onClick]="deleteItem"></dxi-button>
    </dxi-column>
    <div *dxTemplate="let data of 'itemNameCell'">
      <span [innerHTML]="getMaskFieldName(data)"></span>
    </div>
</dx-data-grid>
</div>

<mask-field-component (onAdded)="onMaskFieldAdded($event)" (onUpdated)="onMaskFieldEdited($event)"></mask-field-component>
