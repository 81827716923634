<div class="auction-cluster-notification-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup
  #auctionClusterNotification
  [width]="600"
  [showTitle]="true"
  title="{{ 'NOTIFICATION.NOTIFICATIONS' | translate}}"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [showCloseButton]="false"
  [rtlEnabled]="rtlEnabled"
  [(visible)]="isOpened">
    <dx-scroll-view width="100%" height="100%">
      <dx-form
          #editAuctionClusterNotificationForm
          id="editAuctionClusterNotificationForm"
          [showValidationSummary]="false"
          (onFieldDataChanged)="onFieldDataChanged($event)"
          colCount="auto"
          [(formData)]="model"
          [minColWidth]="500">

          <dxi-item itemType="group">
            <dxi-item 
              dataField="name"
              [template]="'notificationNameField'">
              <dxo-label text="{{ 'NOTIFICATION.NAME' | translate }}"></dxo-label>
            </dxi-item>
  
            <dxi-item 
              dataField="description"
              [template]="'notificationDescriptionField'">
              <dxo-label text="{{ 'NOTIFICATION.DESCRIPTION' | translate }}"></dxo-label>
            </dxi-item>
          </dxi-item>

          <div *dxTemplate="let data of 'notificationNameField'" class="translatable-template">
            <translatable-field (change)="onChangeTranslationField($event, 'name')" [isDevExpress]="true" [isLabelHidden]="true" [textRequired]="false" [caption]="'NOTIFICATION.NAME'" [clusterLanguages]="languages" [(data)]="data.component.option('formData')[data.dataField]" [name]="'name'" #notificationName></translatable-field>  
          </div>
          <div *dxTemplate="let data of 'notificationDescriptionField'" class="translatable-template">
            <translatable-field (change)="onChangeTranslationField($event, 'description')" [isDevExpress]="true" [isLabelHidden]="true" [textRequired]="false" [caption]="'NOTIFICATION.DESCRIPTION'" [clusterLanguages]="languages" [type]="'textbox'" [(data)]="data.component.option('formData')[data.dataField]" [name]="'description'" #notificationDescription></translatable-field>  
          </div>
      </dx-form>
      <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnAuctionClusterNotificationSubmit">{{ 'SAVE' | translate }}</button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'CANCEL' | translate }}</button>
    </dx-scroll-view>
  </dx-popup>
</div>
<save-confirmation-component #saveConfirmation title="{{ 'LEAVE_PAGE' | translate }}" buttonCloseText="OK" cancellable="true" (close)="onCancel();"></save-confirmation-component>