import { Component, OnInit, ViewChild, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';

// general services
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../services/auth.service';
import { LanguageService } from '../services/language.service';
import { ErrorService } from '../services/error.service';
import { TokenService } from '../services/token.service';
import { DynamicRoutingService } from '../services/dynamic-routing.service';
import { TitleService } from '../services/title.service';
import { ServiceFactory } from '../factory/service-factory';

// components
import { AuthenticationComponent } from '../../shared/components/authentication/authentication.component';
import { EditProfileComponent } from '../../shared/components/edit-profile/edit-profile.component';
import { EditUserNotificationsComponent } from '../../shared/components/edit-user-notifications/edit-user-notifications.component';

// services
import { UserPermissions } from '../models/user-permissions';
import { AuctionClusterService } from '../../platform/shared/services/auction-cluster.service';
import { BuyerService } from '../services/buyer.service';
import { SupplierService } from '../services/supplier.service';
import { UserService } from '../services/user.service';
import { WebApiService } from '../services/web-api.service';

// models
import { AuctionCluster } from '../../platform/shared/models/auction-cluster';
import { Language } from '../models/language';
import { BuyerLevel } from '../models/buyer-level';
import { SupplierLevel } from '../models/supplier-level';
import { ApplicationSettings } from '../models/application-settings';
import { LanguagePreferenceService } from '../services/language-preference.service';
import { MFATypeEnum } from '../models/mfaType';

import notify from 'devextreme/ui/notify';

@Component({
  selector: 'header-component',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit, OnDestroy {

  translations: any; // make readonly
  languages: Array<Language> = [];
  currentLanguage: Language = new Language();
  appModeDialogOpened: boolean;

  auctionClusters: Array<AuctionCluster>;
  auctionClusterId = 0;

  buyers: Array<BuyerLevel>;
  buyerId = 0;

  suppliers: Array<SupplierLevel>;
  supplierId = 0;

  permissions: UserPermissions;
  appModeEnabled: boolean;
  isPlatformUser: boolean;
  isAuctionClusterUser: boolean;
  isBuyerUser: boolean;
  isSupplierUser: boolean;
  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  @ViewChild(AuthenticationComponent) authComponent: AuthenticationComponent;
  @ViewChild(EditProfileComponent) epComponent: EditProfileComponent;
  @ViewChild(EditUserNotificationsComponent) epnComponent: EditUserNotificationsComponent;

  constructor(
    public authenticationService: AuthService,
    protected translate: TranslateService,
    private router: Router,
    private auctionClusterService: AuctionClusterService,
    private buyerService: BuyerService,
    private supplierService: SupplierService,
    private languageService: LanguageService,
    private errorService: ErrorService,
    private tokenService: TokenService,
    private dynamicRoutingService: DynamicRoutingService,
    public title: TitleService,
    public languagePreferenceService: LanguagePreferenceService,
    public userService: UserService,
    private activatedRoute: ActivatedRoute,
    protected appSettings: ApplicationSettings,
    protected webApiService: WebApiService
  ) {
    if (document.URL.indexOf('efice') > -1) {
      var element = document.getElementById("faviconchangable");
      document.head.removeChild(element);

      var linkElement = document.createElement("link");
      linkElement.setAttribute("id", "faviconchangable");
      linkElement.setAttribute("rel", "icon");
      linkElement.setAttribute("type", "image/x-icon");
      linkElement.setAttribute("href", "assets/images/logo_efice_icon.ico");
      
        document.head.appendChild( linkElement );
      }
    
      this._subscription = this.languageService.direction.subscribe(res => {
        this.rtlEnabled = res;
      });

  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  isLevelUser(level: Array<any>): boolean {
    return level.length > 0;
  }

  ngOnInit() {
    this.translate.get('HEADER').subscribe((res: string) => {
      this.translations = res;
    });

    this.translate.onLangChange.subscribe(() => {
      this.translate.get('HEADER').subscribe((res: string) => {
        this.translations = res;
      });
    });

    this.appModeDialogOpened = false;

    this.permissions = this.tokenService.getCurrentUserPermissions();

    this.isPlatformUser = this.isLevelUser(this.permissions.PlatformPermissions);
    this.isAuctionClusterUser = this.isLevelUser(this.permissions.AuctionClusterPermissions)
      || this.isLevelUser(this.permissions.AuctionPermissions);
    this.isBuyerUser = this.isLevelUser(this.permissions.BuyerPermissions);
    this.isSupplierUser = this.isLevelUser(this.permissions.SupplierPermissions);

    let levelsAllowed = 0;
    // tslint:disable:curly
    if (this.isPlatformUser) levelsAllowed++;
    if (this.isAuctionClusterUser) levelsAllowed++;
    if (this.isBuyerUser) levelsAllowed++;
    if (this.isSupplierUser) levelsAllowed++;
    // tslint:enable:curly

    this.appModeEnabled = levelsAllowed > 1;

    // tslint:disable:no-magic-numbers
    forkJoin(
      this.auctionClusterService.getAuctionClusters(),
      this.languageService.getLanguages(),
      this.buyerService.getUserLevelBuyers(),
      this.supplierService.getUserLevelSuppliers()
    ).subscribe(result => {
      this.languages = result[1];
      this.currentLanguage = this.languages.find(lang => lang.code === this.languagePreferenceService.current());

      if (this.appModeEnabled && this.isAuctionClusterUser) {
        this.auctionClusters = result[0].filter(f =>
          this.permissions.AuctionClusterPermissions.findIndex(p => p.Key === f.auctionClusterId) > -1 || this.permissions.AuctionPermissions.some(p => f.auction.some(a => a.auctionId == p.Key)));
        this.auctionClusterId = this.auctionClusters[0].auctionClusterId;
      }

      // set sidebar image
      const acs = result[0];      

      if (acs && acs.length > 0) {
        if (document.URL.indexOf('auction') > -1) {
          const ac = acs.find(a => a.auctionClusterId === this.auctionClusterId);

          if (ac) {
            localStorage.setItem('AUCXIS_SIDEBAR_LOGO', ac.image);
          } else {
            localStorage.removeItem('AUCXIS_SIDEBAR_LOGO')
          }
        } else {
          localStorage.setItem('AUCXIS_SIDEBAR_LOGO', acs[0].image);
        }
      } else {
        localStorage.removeItem('AUCXIS_SIDEBAR_LOGO')
      }


      this.buyers = result[2];
      if (this.buyers.length !== 0) {
        this.buyerId = this.buyers[0].buyerId;
      }

      this.suppliers = result[3];
      if (this.suppliers.length !== 0) {
        this.supplierId = this.suppliers[0].supplierId;
      }
    },
      error => {
        notify(error.message, 'error', 5000);
      });
  }
  // tslint:enable:no-magic-numbers

  logout() {
    this.authenticationService.logout();
  }

  setLanguage(lang: string) {
    this.currentLanguage = this.languages.find(l => l.code === lang);
    this.languagePreferenceService.save(lang);
  }

  navigateToPlatformLevel() {
    this.router.navigate(['/platform/' + this.dynamicRoutingService.getFirstAllowedRouteForLevel(this.permissions, 'platform')]);
    this.appModeDialogOpened = false;
  }
  navigateToAuctionClusterLevel() {
    this.router.navigate(['/auction/' + this.dynamicRoutingService.getFirstAllowedRouteForLevel(
      this.permissions, 'auction', this.auctionClusterId)]);
    this.appModeDialogOpened = false;
  }
  navigateToBuyerLevel() {
    this.router.navigate(['/buyer/' + this.dynamicRoutingService.getFirstAllowedRouteForLevel(
      this.permissions, 'buyer', this.buyerId)]);
    this.appModeDialogOpened = false;
  }
  navigateToSupplierLevel() {
    this.router.navigate(['/supplier/' + this.dynamicRoutingService.getFirstAllowedRouteForLevel(
      this.permissions, 'supplier', this.supplierId)]);
    this.appModeDialogOpened = false;
  }

  curentMode() {
    const url = document.location.hash;
    
    if (this.translations) {
      if (url.indexOf('platform') > -1) {
        return '(' + this.translations.PLATFORM + ')';
      }
      if (url.indexOf('auction') > -1) {
        return '(' + this.translations.AUCTIONCLUSTER + ')';
      }
      if (url.indexOf('buyer') > -1) {
        return '(' + this.translations.BUYER + ')';
      }
      if (url.indexOf('supplier') > -1) {
        return '(' + this.translations.SUPPLIER + ')';
      }
    }

    return '';
  }

  openAuthentication(userId: number, event: Event) {
    event.stopPropagation();
    let currentAuctionCluster = null;

    forkJoin(ServiceFactory.getAuctionClusterService(document.location.hash, this.activatedRoute, this.appSettings, this.webApiService).getAuctionClustersPerLevel(), this.userService.getCurrentUser()).subscribe(result => {
      currentAuctionCluster = result[0][0] ? result[0][0] : null;
      let user = result[1];
      let isMFAEnabledOnCluster = (currentAuctionCluster === null || currentAuctionCluster === undefined) ? false : currentAuctionCluster.mfaTypeId !== MFATypeEnum.None;
      this.authComponent.open(user.userId, currentAuctionCluster ? currentAuctionCluster.auctionClusterId : this.auctionClusterId, user.mfaEnabled && isMFAEnabledOnCluster, true);
    });
  }

  editProfile(userId: number, event: Event) {
    event.stopPropagation();
    this.epComponent.open(userId, this.languages);
  }

  editNotifications(userId: number, event: Event) {
    event.stopPropagation();
    this.epnComponent.open(userId, this.languages);
  }
}
