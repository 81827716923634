import { Component, OnInit, Injector, OnDestroy, ViewChild } from '@angular/core';
import { forkJoin, map, Subscription } from 'rxjs';

// general services
import { LanguageService } from '../../shared/services/language.service';
import { DateTimeService } from '../../shared/services/datetime.service';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { ShoppingListLotComponent } from './shopping-list-lot.component';

import notify from 'devextreme/ui/notify';
import DataGrid from 'devextreme/ui/data_grid';

// services
import { ShoppingListsService } from '../shared/services/shopping-lists.service';

// models
import { Language } from '../../shared/models/language';
import { ShoppingList } from '../shared/models/shopping-list';
import { ImportSchema, ImportField } from '../../shared/import/import-schema';
import { Lot, LotProperty } from '../../auction/shared/models/lot';
import { Product, ProductPropertyTypes, GridProperty, DecimalTypes, SystemProductPropertyTypesEnum } from '../../auction/shared/models/product';
import { MasterData, MasterDataValue, MasterDataField } from '../../auction/shared/models/master-data';
import { MasterDataService } from '../shared/services/master-data.service';
import { ShoppingListUpdated } from '../shared/models/shopping-list-updated';

export class MasterDataDropdown {
  value: any;
  label: string;
}

@Component({
  selector: 'shopping-list-lots-component',
  templateUrl: 'shopping-list-lots.component.html',
  styleUrls: ['./shopping-list-lots.component.scss']
})
export class ShoppingListLotsComponent extends FullListComponent<Lot, ShoppingListLotComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: ShoppingListLotComponent;

  languages: Array<Language> = [];
  catalogId: number;
  shoppingList: ShoppingList = new ShoppingList();
  product: Product = new Product();
  shoppingListPropertyFields = [];
  masterDatas: Array<MasterData> = [];
  masterDataDropdowns: Array<any> = [];
  firstLoad = true;
  JSON: any = JSON;
  purchased100PercentColorCode = '#d1ffda';
  purchased75OrMorePercentColorCode = '#a8bee0';
  purchasedLessThan75PercentColorCode = '#f2d5d5';

  dataGridInstance: DataGrid;
  initGrid = false;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private dataService: ShoppingListsService,
    private masterDataService: MasterDataService,
    private dateTimeService: DateTimeService
  ) {
    super(injector, Lot);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
      this.refreshColumnHeaders();
    });
  }

  ngOnInit() {
    this.catalogId = +this.route.snapshot.params['catalogId'];
    this.setTranslations('SHOPPING_LIST');

    this.dataService.shoppingListUpdated.subscribe((shoppingListUpdated: ShoppingListUpdated) => {
      if (shoppingListUpdated.buyerId !== this.id) {
        return;
      }

      // refresh list
      this.getData();
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getData = () => {
    this.spinner.show();
    this.dataService.getShoppingList(this.id, +this.route.snapshot.params['catalogId']).subscribe(pb => {
      pb.shoppingListProductDefinitionBuyer.forEach(productPropertyOrder => {
        let name = this.getTranslation(productPropertyOrder.name);
        if (productPropertyOrder.fieldName) {
          const fieldName = this.getTranslation(productPropertyOrder.fieldName);
          name = `${name} (${fieldName})`;
        }
        productPropertyOrder.name = name;
      });

      this.shoppingList = pb;
      this.title.set('SHOPPING_LIST.TITLE');
      this.title.add(this.shoppingList.name);

      forkJoin(
        this.dataService.getLots(this.id, this.shoppingList.auctionClusterId, this.shoppingList.shoppingListId),
        this.languageService.getLanguages()
      ).subscribe((result: Array<any>) => {
        this.items = result[0];
        this.languages = result[1];
        // tslint:disable-next-line:no-magic-numbers

        this.generateTable();
        if (this.firstLoad) {
          this.getMasterDataLists();
          this.firstLoad = false;
        } else {
          this.parseData();
          this.parseMasterDataDropdown();
        }
        this.spinner.hide();
      },
        error => {
          notify(error.message, 'error', 5000);
          this.spinner.hide();
        });
    },
      error => {
        notify(error.message, 'error', 5000);
        this.spinner.hide();
      });
  }

  getMasterDataLists() {
    const observables = [];

    this.shoppingList.shoppingListProductDefinitionBuyer.filter(ppo => ppo.masterDataListId != null).forEach(productPropertyOrder => {
      observables.push(this.masterDataService.getMasterData(this.id, this.shoppingList.auctionClusterId, productPropertyOrder.masterDataListId)
        .pipe(map((result: MasterData) => result)));
    });

    forkJoin(observables).subscribe(res => {
      res.forEach((result: MasterData) => {
        this.masterDatas.push(result);
      });
      this.parseData();
      this.parseMasterDataDropdown();
    });
  }

  parseMasterDataDropdown() {
    this.shoppingList.shoppingListProductDefinitionBuyer.forEach(productPropertyOrder => {
      const dropdownValues: Array<MasterDataDropdown> = [];
      if (productPropertyOrder.propertyTypeId === ProductPropertyTypes.MASTER_DATA_VALUE) {
        const masterData = this.masterDatas.find(md => md.masterDataListId === productPropertyOrder.masterDataListId);

        const useInCatalogFields = masterData.fields.filter(f => f.useFieldInCatalog);
        if (useInCatalogFields.length === 0) {
          useInCatalogFields.push(masterData.fields[0]);
        }

        useInCatalogFields.forEach(field => {
          masterData.rows.forEach(row => {
            var mdr = row.values.find(rowValue => rowValue.masterDataListFieldId === field.masterDataListFieldId);

            dropdownValues.push({
              value: row.masterDataListRowId, label: this.findValue(mdr, field)
            });
          });
        });
      }

      for (let i = 0; i < dropdownValues.length; i++) {
        for (let j = i + 1; j < dropdownValues.length; j++) {
          if (dropdownValues[i].value === dropdownValues[j].value) {
            if (dropdownValues[j].label !== '') {
              dropdownValues[i].label = dropdownValues[i].label + ' - ' + dropdownValues[j].label;
            }
            dropdownValues.splice(j, 1);
          }
        }
      }
      this.masterDataDropdowns.push({ productPropertyId: productPropertyOrder.productPropertyId, values: dropdownValues });
    });
  }

  parseData() {
    this.items.forEach(i => {
      this.shoppingList.shoppingListProductDefinitionBuyer.forEach(productPropertyOrder => {
        const lotProperty = i.lotProperties.find(lp => lp.productPropertyId === productPropertyOrder.productPropertyId);
        if (lotProperty) {
          const displayLotProp = JSON.parse(JSON.stringify(lotProperty));
          displayLotProp.displayValue = this.findValue(lotProperty, productPropertyOrder);
          if (!i.displayLotProperties) {
            i.displayLotProperties = [];
          }
          i.displayLotProperties.push(displayLotProp);
        }
      });

      i.percentageBought = i.amountBought > 0 ? i.amountBought / i.amountWanted : 0;

      if (i.percentageBought >= 1) {
        i.rowColor = this.purchased100PercentColorCode;
      } else if (i.percentageBought < 1 && i.percentageBought >= 0.75) {
        i.rowColor = this.purchased75OrMorePercentColorCode;
      } else {
        i.rowColor = this.purchasedLessThan75PercentColorCode;
      }
    });

    let sortBy = [];
    this.shoppingList.shoppingListProductDefinitionBuyer.forEach((column, i) => {
      sortBy.push({ prop: 'key' + i, direction: 1 });
    });

    this.items.sort(function (a, b) {
      let i = 0, result = 0;
      while (i < sortBy.length && result === 0) {
        result = sortBy[i].direction * (a.displayLotProperties[i].displayValue < b.displayLotProperties[i].displayValue ? -1 : (a.displayLotProperties[i].displayValue > b.displayLotProperties[i].displayValue ? 1 : 0));
        i++;
      }
      return result;
    })
  }

  findValue(value: any, prop: any): any {
    if (prop.propertyTypeId === ProductPropertyTypes.DECIMAL && value.decimalValue) {
      return value.decimalValue;
    } else if (prop.propertyTypeId === ProductPropertyTypes.NUMBER && value.intValue) {
      return value.intValue;
    } else if (prop.propertyTypeId === ProductPropertyTypes.TEXT && value.stringValue) {
      return prop.translatable ? this.getTranslation(value.stringValue) : value.stringValue;
    } else if (prop.propertyTypeId === ProductPropertyTypes.BOOLEAN) {
      return value.booleanValue ? 'true' : 'false';
    } else if (prop.propertyTypeId === ProductPropertyTypes.DATE && value.dateTimeValue) {
      return this.dateTimeService.getDateStringByFormatAnyUtc(value.dateTimeValue, prop.propertyTypeFormatId);
    } else if (prop.propertyTypeId === ProductPropertyTypes.MASTER_DATA_VALUE && value.masterDataListRowId) {
      return this.getMasterDataValue(value, prop.masterDataListFieldId);
    }
    return '';
  }

  getMasterDataValue(lotProperty: LotProperty, masterDataListFieldId) {
    let value = lotProperty.masterDataListRowId.toString();
    this.masterDatas.forEach(md => {
      var masterDataListRow = md.rows.find(r => r.masterDataListRowId === lotProperty.masterDataListRowId);
      if (masterDataListRow) {
        var mdv = masterDataListRow.values.find(v => v.masterDataListFieldId === masterDataListFieldId);
        if (mdv) {
          value = this.findValue(mdv, md.fields.find(_ => _.masterDataListFieldId === masterDataListFieldId));
        }
      }
    })
    return value;
  }

  edit = (e: any) => {
    this.detailsComponent.modalTitle = this.translations.EDIT;
    const lot = this.items.find(_ => _.lotId === e.row.data.lotId);
    this.detailsComponent.open(lot, this.languages, this.shoppingList, this.masterDataDropdowns);
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.ADD_NEW;
    this.detailsComponent.open(null, this.languages, this.shoppingList, this.masterDataDropdowns);
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  deleteSelected() {
    this.spinner.show();
    this.dataService.deleteLot(this.id, this.shoppingList.auctionClusterId, this.itemIdToDelete)
      .subscribe((lots: Array<Lot>) => {
        this.getData();
        this.spinner.hide();
      },
        error => {
          notify(this.errorService.translations.DELETE_ERROR_MESSAGE, 'error', 5000);
          this.spinner.hide();
        });
  }

  createDataSchema() {
    const schema = new ImportSchema();

    this.schema = schema;
  }

  generateTable = () => {
    if (!this.initGrid) {
      this.initGrid = true;
      this.shoppingList.shoppingListProductDefinitionBuyer.sort((a, b) => a.orderNumber - b.orderNumber).forEach((row: any, i: number) => {

        this.dataGridInstance.addColumn({
          dataField: 'displayLotProperties[' + i + '].displayValue',
          caption: this.getTranslation(row.name),
          visibleIndex: i,
          allowEditing: false,
          dataType: this.getDataType(row.productPropertyId, row.propertyTypeFormatId),
          encodeHtml: false,
          editorOptions: {
            showClearButton: true
          }
        });
      });
    }
  }

  initColumn = (e: any) => {
    this.dataGridInstance = e.component;
  }

  onRowPrepared = (e: any) => {
    if (this.items && e.rowType === 'data') {
      let lot = this.items.find(_ => _.lotId === e.data.lotId);
      if (lot && lot.rowColor) {
        e.rowElement.style.backgroundColor = lot.rowColor;
      }
    }
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.lotId;
    this.confirmation.opened = true;
  }

  refreshColumnHeaders = () => {

    this.dataService.getShoppingList(this.id, +this.route.snapshot.params['catalogId']).subscribe(pb => {
      pb.shoppingListProductDefinitionBuyer.forEach((productPropertyOrder, i) => {
        let name = this.getTranslation(productPropertyOrder.name);
        if (productPropertyOrder.fieldName) {
          const fieldName = this.getTranslation(productPropertyOrder.fieldName);
          name = `${name} (${fieldName})`;
        }
        this.shoppingList.shoppingListProductDefinitionBuyer[i].name = name;
        this.dataGridInstance.columnOption('displayLotProperties[' + i + '].displayValue', 'caption', name);
      });
    });
  }
}
