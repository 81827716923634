<div class="generic-product-property-component">
  <clr-modal [(clrModalOpen)]="isOpened" [clrModalClosable]="false">
    <h3 class="modal-title">{{ modalTitle }}</h3>
    <div class="modal-body">
      <clr-alert *ngIf="errorMessage" [clrAlertType]="'danger'" [clrAlertClosable]="false">
        <div class="alert-item">
          <span class="alert-text">
            {{errorMessage}}
          </span>
        </div>
      </clr-alert>
      <form #detailsForm="ngForm" clrForm>
        <div class="clr-form-control">
          <label class="clr-control-label">{{ 'PRODUCT.NAME' | translate }}</label>
          <div class="clr-control-container">
            <translatable-field [(data)]="model.name" [name]="'propertyGroupName'" [clusterLanguages]="languages" #propertyGroupName></translatable-field>
          </div>
        </div>
        <div class="clr-form-control">
          <label for="isCollapsed" class="clr-control-label">{{ 'PRODUCT.COLLAPSE' | translate }}</label>
          <div class="clr-control-container">
            <div class="clr-checkbox-wrapper">
              <input id="isCollapsed" type="checkbox" clrCheckbox name="isCollapsed" [(ngModel)]="model.collapse" />
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" (click)="onSubmit();" [disabled]="!detailsForm.form.valid">{{  (isEditMode ? 'SAVE' : 'ADD') | translate }}</button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'CANCEL' | translate }}</button>
    </div>
  </clr-modal>
</div>
