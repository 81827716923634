<div [ngClass]="{'dx-rtl': rtlEnabled}" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup
      #nameConfirmation
      width="40%"
      height="350"
      [showTitle]="true"
      title="{{ title }}"
      [dragEnabled]="false"
      [closeOnOutsideClick]="false"
      [rtlEnabled]="rtlEnabled"
      [visible]="opened"
      (onHiding)="opened = false;">
      <dx-scroll-view width="100%" height="100%">
          <p>{{ infoText }}</p>
          <br/>
          <div class="dx-field">
            <div class="dx-field-label">{{ 'AUCTION.NAME' | translate }}</div>
            <div class="dx-field-value">
              <dx-text-box [(value)]="name"></dx-text-box>
            </div>
          </div>
          <br/><br/>
          <button *ngIf="cancellable" type="button" class="btn btn-outline" (click)="opened = false;">{{ 'CANCEL' | translate }}</button>
          <button type="button" class="btn btn-primary" (click)="opened = false; close.emit(name);">{{ buttonCloseText | translate }}</button>
      </dx-scroll-view>
  </dx-popup>
  </div>
