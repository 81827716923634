import { Component, OnInit, Injector, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _moment from 'moment';
import { Moment } from 'moment';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

// general services
import { LanguageService } from '../../shared/services/language.service';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';
import { TranslatableFieldComponent } from '../../shared/components/translatable-field/translatable-field.component';

// services
import { PrebidOnProductService } from '../shared/services/prebid-on-product.service';
import { AuctionClusterService } from '../shared/services/auction-cluster.service';
import { ProductService } from '../shared/services/product.service';

// models
import { Language } from '../../shared/models/language';
import { PrebidOnProduct } from '../shared/models/prebid';
import { AuctionCluster } from '../../platform/shared/models/auction-cluster';
import { Product, ProductPropertyTypes } from '../../auction/shared/models/product';
import { Lot, LotProperty } from '../../auction/shared/models/lot';
import { MasterData } from '../../auction/shared/models/master-data';
import { Supplier } from '../../shared/models/supplier';

@Component({
  selector: 'buyer-prebid-lot-component',
  templateUrl: 'buyer-prebid-lot.component.html',
  styleUrls: ['./buyer-prebid-lot.component.scss']
})
export class BuyerPrebidLotComponent extends ItemDetailsComponent<Lot> implements OnInit {
  @ViewChildren(TranslatableFieldComponent) translatableFields: Array<TranslatableFieldComponent> = [];

  buyerId: number;
  catalogId: number;
  languages: Array<Language> = [];
  product: Product = new Product();
  prebidOnProduct: PrebidOnProduct = new PrebidOnProduct();
  prebidPropertyFields = [];
  masterDatas: Array<MasterData> = [];
  masterDataDropdowns: Array<any> = [];
  suppliers: Array<Supplier> = [];

  constructor(
    protected injector: Injector,
    private dataService: PrebidOnProductService,
    private auctionClusterService: AuctionClusterService,
    private productService: ProductService,
    private languageService: LanguageService,
    private route: ActivatedRoute
  ) {
    super(injector);
  }

  ngOnInit() {
    this.model = new Lot();
    this.buyerId = +this.route.snapshot.params['id'];
    this.catalogId = +this.route.snapshot.params['catalogId'];
  }

  open(lots: Array<Lot>, lotId: number, languages: Array<Language>, product: Product,
    prebidOnProduct: PrebidOnProduct, prebidPropertyFields: Array<any>,
    masterDatas: Array<MasterData>, masterDataDropdowns: Array<any>, suppliers: Array<Supplier>) {

    this.allItems = lots;
    this.languages = languages;
    this.product = product;
    this.prebidOnProduct = prebidOnProduct;
    this.prebidPropertyFields = prebidPropertyFields;
    this.masterDatas = masterDatas;
    this.masterDataDropdowns = masterDataDropdowns;
    this.suppliers = suppliers;

    // this.auctionClusterService.getAuctionClusters(this.buyerId).subscribe(acs => {
    //   this.auctionClusters = acs;
    //   // this.auctionClusters = [ { auctionClusterId: 1, name: '{"en-GB":"Test A1","nl-BE":""}' }];
    // });

    if (lotId != null) {
      this.isEditMode = true;
      this.spinner.show();
      this.dataService.getLot(this.buyerId, this.prebidOnProduct.auctionClusterId, lotId)
        .subscribe((res: Lot) => {
          this.model = res;
          this.parseModel();
          this.isOpened = true;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    } else {
      this.model = new Lot();
      this.parseModel();
      this.model.catalogId = this.prebidOnProduct.catalogId;
      this.isEditMode = false;
      this.isOpened = true;
    }
  }

  parseModel() {
    if (this.model.lotProperties.length === 0) {
      this.prebidPropertyFields.forEach(ppf => {
        // check if already added
        const alreadyAddedProp = this.model.lotProperties.find(p => p.productPropertyId === ppf.productPropertyId);
        if (!alreadyAddedProp) {
          const newObj = new LotProperty();
          newObj.orderNumber = ppf.orderNumber;
          newObj.masterDataListFieldId = ppf.masterDataListFieldId;
          newObj.name = ppf.name;
          newObj.propertyTypeId = ppf.propertyTypeId;
          newObj.propertyTypeFormatId = ppf.propertyTypeFormatId;
          newObj.productPropertyId = ppf.productPropertyId;
          newObj.translatable = ppf.translatable;
          if (newObj.translatable) {
            newObj.stringValue = this.createEmtpyObjectForTranslatableFields();
          }

          this.model.lotProperties.push(newObj);
        }
      });
    } else {
      this.model.lotProperties.forEach(lp => {
        const field = this.prebidPropertyFields.find(ppf => ppf.productPropertyId === lp.productPropertyId);

        if (field) {
          lp.orderNumber = field.orderNumber;
          lp.masterDataListFieldId = field.masterDataListFieldId;
          lp.name = field.name;
          lp.propertyTypeId = field.propertyTypeId;
          lp.propertyTypeFormatId = field.propertyTypeFormatId;
          lp.translatable = field.translatable;

          if (lp.propertyTypeId === ProductPropertyTypes.DATE &&  lp.dateTimeValue) {
            const serverReturnedTime = moment.utc(lp.dateTimeValue);
            lp.dateTimeValue = moment(serverReturnedTime).local();
          }
        }
      });
    }

    this.model.lotProperties.sort((a, b) => a.orderNumber - b.orderNumber );
  }

  dateChanged(property: LotProperty, dateTime: Moment) {
    property.dateTimeValue = moment(dateTime).toDate();
  }

  // onAuctionClusterChanged() {
  //   this.productService.getProducts(this.buyerId, this.model.auctionClusterId).subscribe(ps => {
  //     this.products = ps;
  //   });
  // }

  createEmtpyObjectForTranslatableFields() {
    // Create empty JSON object for translation fields
    const emptyTranslation = {};
    this.languages.forEach(lang => {
      emptyTranslation[lang.code] = '';
    });

    return JSON.stringify(emptyTranslation);
  }

  getDropdownValues(productPropertyId: number) {
    const dd = this.masterDataDropdowns.find(md => md.productPropertyId === productPropertyId);

    if (dd) {
      return dd.values;
    }

    return [];
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  parseBeforeSave() {
    this.model.lotProperties.forEach(lotProperty => {
      if (lotProperty.propertyTypeId === ProductPropertyTypes.DATE &&  lotProperty.dateTimeValue) {
        lotProperty.dateTimeValue = moment.utc(lotProperty.dateTimeValue);
      }

      if (lotProperty.propertyTypeId === ProductPropertyTypes.TEXT && lotProperty.translatable) {
        const field = this.translatableFields.find(tf => tf.componentIndex === lotProperty.productPropertyId);
        if (field) {
          lotProperty.stringValue = field.data;
        }
      }
    });
  }

  save() {

    this.parseBeforeSave();

    if (this.model.lotId) {
      this.isEditMode = true;
    }

    if (this.isEditMode) {
      this.spinner.show();
      this.dataService.editLot(this.buyerId, this.prebidOnProduct.auctionClusterId, this.model)
        .subscribe((res: any) => {
          this.model = new Lot();
          this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorMessage = this.errorService.toString(error);
            this.spinner.hide();
          });
    } else {
      this.spinner.show();
      this.dataService.saveLot(this.buyerId, this.prebidOnProduct.auctionClusterId, this.model)
        .subscribe((res: any) => {
          this.model = new Lot();
          this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorMessage = this.errorService.toString(error);
            this.spinner.hide();
          });
    }
  }

  public onCancel() {
    super.onCancel();
  }
}
