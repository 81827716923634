import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Supplier, SupplierSimple } from '../models/supplier';
import { ApplicationSettings } from '../models/application-settings';
import { WebApiService } from './web-api.service';
import { SupplierLevel } from '../models/supplier-level';

@Injectable()
export class SupplierService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'supplier';
  }

  getSuppliers(): Observable<Array<Supplier>> {
    return this.webApiService.getList(this.apiPath);
  }

  getSuppliersSimple(): Observable<Array<SupplierSimple>> {
    return this.webApiService.getList(this.apiPath + '/simple');
  }

  getSupplier(supplierId: number): Observable<Supplier> {
    return this.webApiService.get(this.apiPath, supplierId);
  }

  // Get available suppliers for current user
  getUserLevelSuppliers(): Observable<Array<SupplierLevel>> {
    return this.webApiService.getList(this.apiPath + 'level/supplier');
  }

  save(supplier: Supplier): Observable<any> {
    return this.webApiService.save<Supplier>(this.apiPath, supplier);
  }

  edit(supplier: Supplier): Observable<any> {
    return this.webApiService.edit<Supplier>(this.apiPath, supplier.supplierId, supplier);
  }

  delete(supplierId: number): Observable<any> {
    return this.webApiService.delete(this.apiPath, supplierId);
  }

  enableDisable(supplier: Supplier): Observable<Supplier> {
    if (supplier.isActive) {
      return this.webApiService.enableDisable(this.apiPath + '/disable', supplier.supplierId);
    } else {
      return this.webApiService.enableDisable(this.apiPath + '/enable', supplier.supplierId);
    }
  }
}
