<div class="item-list-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <div class="grid-buttons">
    <dx-button icon="add" hint="{{ 'ADD' | translate }}"
               (onClick)="add()"></dx-button>
    <dx-button icon="print" hint="{{ 'PRINT' | translate }}"
               (onClick)="print()"></dx-button>
  </div>
  <br />
  <dx-data-grid #buyersGrid id="printSection"
                [allowColumnReordering]="true"
                [allowColumnResizing]="true"
                [columnAutoWidth]="true"
                [dataSource]="items"
                [hoverStateEnabled]="true"
                [rtlEnabled]="rtlEnabled"
                [showBorders]="true"
                [rowAlternationEnabled]="true"
                (onRowClick)="edit($event)"
                (onExporting)="onExporting($event)">
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>
    <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxo-selection mode="single"></dxo-selection>
    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="storage_buyers"></dxo-state-storing>
    <dxo-pager [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]"
               [showInfo]="true"
               [showNavigationButtons]="true"
               [showPageSizeSelector]="true"
               [visible]="true"
               infoText="{2} {{ 'BUYERS.PAGINATION_TEXT_PLACEHOLDER' | translate | slice:2 }}">
    </dxo-pager>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="boolean" dataField="isActive" caption="{{ 'BUYERS.ACTIVE' | translate }}">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="name" caption="{{ 'BUYERS.NAME' | translate }}">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="email" caption="{{ 'BUYERS.EMAIL' | translate }}">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="telNumber" caption="{{ 'BUYERS.TEL_NUMBER' | translate }}">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="vat" caption="{{ 'BUYERS.VAT' | translate }}" [visible]="false">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="address1" caption="{{ 'BUYERS.ADDRESS' | translate }}">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="zip" caption="{{ 'BUYERS.ZIP' | translate }}">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="city" caption="{{ 'BUYERS.CITY' | translate }}">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="country" caption="{{ 'BUYERS.COUNTRY' | translate }}">
    </dxi-column>
    <dxi-column type="buttons" caption="..." [allowHiding]="false">
      <dxi-button icon="user" hint="{{ 'BUYERS.SHOW_RELATED_USERS' | translate }}"
                  [onClick]="navigateToUsers"></dxi-button>
      <dxi-button icon="edit" hint="{{ 'EDIT' | translate }}"
                  [onClick]="edit"></dxi-button>
      <dxi-button icon="check" hint="{{ 'BUYERS.DEACTIVATE_BUYER' | translate }}"
                  [onClick]="enableDisableBuyer"
                  [visible]="calculateDisabledDisplayValue"></dxi-button>
      <dxi-button icon="remove" hint="{{ 'BUYERS.ACTIVATE_BUYER' | translate }}"
                  [onClick]="enableDisableBuyer"
                  [visible]="calculateEnabledDisplayValue"></dxi-button>
      <dxi-button icon="trash" hint="{{ 'DELETE' | translate }}"
                  [onClick]="deleteItem"></dxi-button>
    </dxi-column>
  </dx-data-grid>
</div>

<buyer-component #details
                 (onClosed)="onDetailsComponentClosed($event)"></buyer-component>
<confirmation-component #confirmation title="{{ 'BUYERS.DELETE_BUYER' | translate }}"
                        message="{{ 'BUYERS.DELETE_BUYER_MESSAGE' | translate }}"
                        buttonCloseText="DELETE" cancellable="true" (close)="deleteSelected();"></confirmation-component>
