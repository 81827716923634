import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { WebApiService } from '../../../shared/services/web-api.service';
import { TransactionMonitorLot } from '../../../shared/models/transaction-monitor-lot';
import { Catalog } from '../../../auction/shared/models/catalog';
import { Clock } from '../../../shared/models/clock';

@Injectable()
export class AuctionTransactionMonitorService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'auctioncluster';
  }

  getLots( auctionClusterId: number, catalogId: number, date: string, clockId: number = 0): Observable<TransactionMonitorLot> {
    let url = `${this.apiPath}/${auctionClusterId}/catalog/${catalogId}/date/${date}/transactionmonitor`;
    if (clockId && clockId !== 0) {
      url += `?clockId=${clockId}`;
    }
    return this.webApiService.getList(url);
  }

  getCatalogsForPermissions(auctionClusterId: number, permissionIds: Array<number>): Observable<Array<Catalog>> {
    return this.webApiService.save(this.apiPath + '/' + auctionClusterId + '/auction/catalog/permission', { 'ids': permissionIds });
  }


  getClocksByCatalog(auctionClusterId: number, catalogId: number, permissionIds: Array<number>): Observable<Array<Clock>> {
    return this.webApiService.save(`${this.apiPath}/${auctionClusterId}/auction/catalog/${catalogId}/clock`, { 'ids': permissionIds });
  }

  printTransactions(auctionClusterId: number, catalogId: number, transactionIds: Array<number>): Observable<any> {
    return this.webApiService.save(`${this.apiPath}/${auctionClusterId}/catalog/${catalogId}/printtransactiontickets`, { 'ids': transactionIds });
  }

}
