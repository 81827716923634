<div class="users-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <div class="item-list-component">
    <div class="grid-buttons">
      <dx-button icon="print" hint="{{ 'PRINT' | translate }}"
                 (onClick)="print()"></dx-button>
    </div>
    <br />
    <dx-data-grid #buyerUsersGrid id="printSection"
                  [allowColumnReordering]="true"
                  [allowColumnResizing]="true"
                  [columnAutoWidth]="true"
                  [dataSource]="items"
                  [hoverStateEnabled]="true"
                  [rtlEnabled]="rtlEnabled"
                  [showBorders]="true"
                  [rowAlternationEnabled]="true"
                  (onRowClick)="edit($event)"
                  (onExporting)="onExporting($event)">
      <dxo-export [enabled]="true"></dxo-export>
      <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>
      <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
      <dxo-filter-panel [visible]="true"></dxo-filter-panel>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-header-filter [visible]="true"></dxo-header-filter>
      <dxo-search-panel [visible]="true"></dxo-search-panel>
      <dxo-selection mode="single"></dxo-selection>
      <dxo-state-storing [enabled]="true" type="localStorage" storageKey="storage_users"></dxo-state-storing>
      <dxo-pager [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]"
                 [showInfo]="true"
                 [showPageSizeSelector]="true"
                 [showNavigationButtons]="true"
                 [visible]="true"
                 infoText="{2} {{ 'USERS.PAGINATION_TEXT_PLACEHOLDER' | translate | slice:2 }}">
      </dxo-pager>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="boolean" dataField="isActive" caption="{{ 'USERS.ACTIVE' | translate }}">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="firstname" caption="{{ 'USERS.FIRST_NAME' | translate }}">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="lastname" caption="{{ 'USERS.LAST_NAME' | translate }}">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="email" caption="{{ 'USERS.EMAIL' | translate }}">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="telNumber" caption="{{ 'USERS.TEL_NUMBER' | translate }}" [visible]="false">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="rolesString" caption="{{ 'USERS.ROLE' | translate }}">
      </dxi-column>
      <dxi-column type="buttons" caption="..." [allowHiding]="false">
        <dxi-button icon="edit" hint="{{ 'EDIT' | translate }}"
                    [onClick]="edit"></dxi-button>
        <dxi-button icon="trash" hint="{{ 'DELETE' | translate }}"
                    [onClick]="deleteItem"></dxi-button>
      </dxi-column>
    </dx-data-grid>
  </div>

  <!--<authentication-component></authentication-component>-->
  <buyer-user-component #details
                        (onClosed)="onDetailsComponentClosed($event)"></buyer-user-component>
  <confirmation-component #confirmation title="{{ 'USERS.DELETE_USER' | translate }}"
                          message="{{ 'USERS.DELETE_USER_MESSAGE' | translate }}"
                          buttonCloseText="DELETE" cancellable="true" (close)="deleteSelected();"></confirmation-component>
</div>
