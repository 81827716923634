<div class="version-component">
  <div class="version-field">
    <p class="version-text">{{ 'CONTENT.VERSION' | translate }} {{ version?.adminUIVersion }}</p>
    <!-- <clr-icon class="version-icon" shape="info-circle" (click)="versionModalOpened = true" size="24"></clr-icon> -->
  </div>
  <!-- <clr-modal [(clrModalOpen)]="versionModalOpened">
    <h3 class="modal-title">{{ 'CONTENT.VERSIONS' | translate }}</h3>
    <div class="modal-body">
      <div>
        <p>{{ 'CONTENT.ADMIN_UI_VERSION' | translate }} {{ version?.adminUIVersion }}</p>
        <p>{{ 'CONTENT.ADMIN_API_VERSION' | translate }} {{ version?.adminAPIVersion }}</p>
        <p>{{ 'CONTENT.ADMIN_CLOCK_VERSION' | translate}} {{ version?.clockVersion }}</p>
        <p>{{ 'CONTENT.ADMIN_CLOCK_SERVICE_VERSION' | translate}} {{ version?.clockServiceVersion }}</p>
        <p>{{ 'CONTENT.ADMIN_CLOCK_ENGINE_VERSION' | translate}} {{ version?.clockEngineVersion }}</p>
        <p>{{ 'CONTENT.ADMIN_AUDIO_SERVICE_VERSION' | translate}} {{ version?.audioServiceVersion }}</p>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline" (click)="versionModalOpened = false">{{ 'CLOSE' | translate }}</button>
    </div>
  </clr-modal> -->
</div>
