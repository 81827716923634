import { Component, Output, OnInit, OnDestroy, Injector, ViewChild, EventEmitter } from '@angular/core';

// general services
import { LanguageService } from '../../shared/services/language.service';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';

// services
import { ProductPropertyTypeService } from '../shared/services/product-property-type.service';

// models
import { Language } from '../../shared/models/language';
import { MasterDataField, FieldType } from '../shared/models/master-data';
import { ProductPropertyType, ProductPropertyTypes } from '../shared/models/product';
import { DataFormat } from '../shared/models/product-property-types';

import { DxFormComponent, DxPopupComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { Subscription } from 'rxjs';

@Component({
  selector: 'master-data-field-component',
  templateUrl: 'master-data-field.component.html',
  styleUrls: ['./master-data-field.component.scss']
})
export class MasterDataFieldComponent extends ItemDetailsComponent<MasterDataField> implements OnInit, OnDestroy {

  languages: Array<Language> = [];
  productPropertyTypes: Array<ProductPropertyType> = [];
  dataFormats: Array<DataFormat> = [];
  fieldTypes: Array<FieldType> = [];
  validFields: boolean = false;
  availableFieldsForCaption: Array<MasterDataField> = [];

  @ViewChild('masterDataFieldName') masterDataFieldName;
  @ViewChild('masterDataFieldDescription') masterDataFieldDescription;
  @ViewChild(DxPopupComponent) popup: DxPopupComponent;
  @ViewChild('masterDataFieldForm', { read: DxFormComponent}) masterDataFieldForm: DxFormComponent;


  // tslint:disable:no-output-on-prefix
  @Output() onAdded = new EventEmitter<MasterDataField>();
  @Output() onUpdated = new EventEmitter<MasterDataField>();
  // tslint:enable:no-output-on-prefix

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private dataFormatsService: ProductPropertyTypeService,
  ) {
    super(injector);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.model = new MasterDataField();
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  open(masterDataFields: Array<MasterDataField>, masterDataField: MasterDataField, languages: Array<Language>, productPropertyTypes: Array<ProductPropertyType>, fieldTypes: Array<FieldType>) { // tslint:disable-line:max-line-length

    this.allItems = masterDataFields;
    this.availableFieldsForCaption = masterDataFields.filter(_ => _.masterDataListFieldId !== masterDataField.masterDataListFieldId);
    this.translateMasterDataFields();

    this.languages = languages;
    this.fieldTypes = fieldTypes;
    this.productPropertyTypes = productPropertyTypes.filter(f => !f.isMasterData);

    if (masterDataField.name !== null && masterDataField.name !== undefined) {
      this.isEditMode = true;
      this.model = masterDataField;

      this.validFields = this.languageService.checkDefaultLanguageNotEmpty(this.languages, this.model.name);

      this.filterDataFormats();
      this.isOpened = true;
    } else {
      this.model = masterDataField;
      this.isEditMode = false;
      this.isOpened = true;

      // Create empty JSON object for translation fields
      const emptyTranslation = {};
      this.languages.forEach(lang => {
        emptyTranslation[lang.code] = '';
      });

      this.model.name = this.model.description = JSON.stringify(emptyTranslation);
    }
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  save() {
    this.model.name = this.masterDataFieldName.data;
    this.model.description = this.masterDataFieldDescription.data;

    if (this.isEditMode) {
      this.onUpdated.emit(this.model);
    } else {
      this.onAdded.emit(this.model);
    }

    this.model = new MasterDataField();
    // this.detailsForm.reset();
    this.close(true);
    this.errorMessage = null;
  }

  filterDataFormats() {
    if (this.model.propertyTypeId === ProductPropertyTypes.DATE || this.model.propertyTypeId === ProductPropertyTypes.DECIMAL
      || this.model.propertyTypeId === ProductPropertyTypes.URL) {
      this.dataFormatsService.getFormats(this.model.propertyTypeId).subscribe(res => {
        this.dataFormats = res;
      });
    }
  }

  onTypeChanged = (e: any) => {
    if (this.model.propertyTypeId === ProductPropertyTypes.DATE || this.model.propertyTypeId === ProductPropertyTypes.DECIMAL
      || this.model.propertyTypeId === ProductPropertyTypes.URL) {
      this.dataFormatsService.getFormats(this.model.propertyTypeId).subscribe(res => {
        this.dataFormats = res;
      });
    }
    if (this.model.propertyTypeId && this.model.propertyTypeId !== e.selectedItem.propertyTypeId) {
      this.model.propertyTypeFormatId = null;
      this.model.imageCaptionId = null;
    }
  }

  onUrlFormatChanged = (e: any) => {
    if (this.model.propertyTypeFormatId && this.model.propertyTypeFormatId !== e.selectedItem.propertyTypeFormatId) {
      this.model.imageCaptionId = null;
    }
  }

  translateMasterDataFields() {
    this.availableFieldsForCaption.forEach(masterDataField => {
      masterDataField.masterDataFieldName = this.languageService.getTranslatableText(masterDataField.name);
      masterDataField.masterDataDescription = this.languageService.getTranslatableText(masterDataField.description);
    });
  }

  onChangeTranslationField = (e: any, fieldName: string) => {
    if (fieldName === 'name') {
      this.validFields = this.languageService.checkDefaultLanguageNotEmpty(this.languages, this.masterDataFieldName.data);
      this.masterDataFieldForm.instance.updateData(fieldName, this.masterDataFieldName.data);
    } else if (fieldName === 'description') {
      this.masterDataFieldForm.instance.updateData(fieldName, this.masterDataFieldDescription.data);
    }
  }

  onFieldDataChanged(e: any) {
    if ((e.component._isReady && e.component.NAME !== 'dxPopup') || e.component.NAME === 'dxForm') {
      const result = e.component.validate();
      if (result.brokenRules.length == 0 || this.validFields) {
        document.getElementsByName('btnMasterDataFieldSubmit')[0].removeAttribute('disabled');
      } else {
        document.getElementsByName('btnMasterDataFieldSubmit')[0].setAttribute('disabled', 'disabled');
      }
    } else {
      if (this.isEditMode && this.validFields) {
        document.getElementsByName('btnMasterDataFieldSubmit')[0].removeAttribute('disabled');
      } else {
        document.getElementsByName('btnMasterDataFieldSubmit')[0].setAttribute('disabled', 'disabled');
      }
    }
  }

}
