import { Component, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
// models
import { AdditionalMenuItem, MenuTypes } from '../../models/additional-menu-item';
// services
import { AdditionalMenuItemsService } from '../../services/additional-menu-items.service';

// general services
import { LanguageService } from '../../services/language.service';
import { TitleService } from '../../services/title.service';

export class MasterDataDropdown {
  rowId: number;
  value: string;
}

@Component({
  selector: 'additional-menu-items-container-component',
  templateUrl: 'additional-menu-items-container.component.html',
  styleUrls: ['./additional-menu-items-container.component.scss']
})
export class AdditionalMenuItemsContainerComponent implements OnInit {

  menuItems: Array<AdditionalMenuItem> = [];
  menuItem: AdditionalMenuItem;
  externalAppId: number;
  externalApplicationUrl: SafeResourceUrl;
  htmlContent: SafeHtml;
  menuTypeChangeSubscription: Subscription;


  constructor(
    protected title: TitleService,
    private languageService: LanguageService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizationService: DomSanitizer,
    private dataService: AdditionalMenuItemsService,
  ) {
    this.title.set('ADDITIONAL_MENU_ITEMS.TITLE');
    this.menuTypeChangeSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.initializeValues();
      }
    })
  }

  ngOnInit() {
    this.initializeValues();
  }

  initializeValues() {
    this.externalAppId = +this.route.snapshot.paramMap.get('menuId');

    this.dataService.getPublicMenuItems().subscribe(result => {
      this.menuItems = result;

      this.menuItem = this.menuItems.find(_ => _.externalAppId === this.externalAppId);
      if (this.menuItem.menuType === MenuTypes.URL) {
        this.htmlContent = undefined;
        this.getData();

      } else if (this.menuItem.menuType === MenuTypes.CONTENT) {
        this.externalApplicationUrl = undefined;
        this.setContent();
      }
    });
  }

  ngOnDestroy(){
    if (this.menuTypeChangeSubscription) {
      this.menuTypeChangeSubscription.unsubscribe();
   }
  }

  getData() {
    this.dataService.getPublicMenuItems().subscribe(result => {
      this.menuItems = result;

      this.menuItem = this.menuItems.find(_ => _.externalAppId === this.externalAppId);
      if (this.menuItem) {
        this.title.set(this.getTranslation(this.menuItem.menuItemName));

        let url = this.menuItem.applicationUrl;
        this.externalApplicationUrl = this.sanitizationService.bypassSecurityTrustResourceUrl(url);
      }
    });
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  setContent() {
    this.dataService.getPublicMenuItems().subscribe(result => {

      if (result !== undefined){
        result.forEach( item => {
          if (item.externalAppId === this.externalAppId){
            this.htmlContent = this.getTranslation(item.content);
          }
        })
      }
    });
  }

  createDataSchema() {
  }
}
