<div class="item-list-component lottery-component" [ngClass]="{'dx-rtl': rtlEnabled}" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <div class="grid-buttons">
    <dx-button hint="{{ 'PRINT' | translate }}" icon="print" (onClick)="print()"></dx-button>
  </div>
  <div class="filter-column">
    <label for="catalogSelect">{{ 'LOTTERY.CATALOG' | translate }}</label>
    <dx-select-box name="catalog"
                   [items]="catalogs"
                   valueExpr="catalogId"
                   [(value)]="catalogId"
                   displayExpr="name"
                   [rtlEnabled]="rtlEnabled"
                   [searchEnabled]="true"
                   [showClearButton]="true"
                   (onValueChanged)="catalogChanged()"
                   placeholder="{{ 'SELECT' | translate }} ...">
    </dx-select-box>
  </div>
  <div *ngIf="customAdminSettings && customAdminSettings.hasCustomLottery" class="filter-column">
    <label for="lastXSupplierCount">{{ 'LOTTERY.LAST_X_SUPPLIER_COUNT' | translate }}</label>
    <dx-number-box name="lastXSupplierCount"
                   [(value)]="lastXSupplierCount"
                   [showSpinButtons]="true"
                   format="#0"
                   [step]="1"
                   [min]="0"
                   [rtlEnabled]="rtlEnabled">
    </dx-number-box>
  </div>

  <div class="filter-column">
    <label for="salesDateDateComponent">{{ 'LOTTERY.SALES_DATE' | translate }}</label>
    <date-component ngDefaultControl class="dt-input" name="salesDateDateComponent" [data]="salesDate" (modelChanged)="dateChanged('salesDate', $event)"></date-component>
  </div>

  <button type="button" class="btn btn-primary lottery-btn" (click)="show()">{{ 'LOTTERY.RUN' | translate }}</button>

  <div *ngIf="items">
    <div>
      <button *ngIf="customAdminSettings && customAdminSettings.hasCustomLottery && isLottery" type="button" class="btn btn-primary" style="margin-right: 20px" (click)="executeLottery()"><clr-icon shape="refresh"></clr-icon> {{ 'LOTTERY.RESET' | translate }}</button>
      <button *ngIf="customAdminSettings && customAdminSettings.hasCustomLottery && !isLottery" type="button" class="btn btn-primary" style="margin-right: 20px" (click)="showSuppliers()"><clr-icon shape="refresh"></clr-icon> {{ 'LOTTERY.RESET' | translate }}</button>
      <h4 *ngIf="customAdminSettings && customAdminSettings.hasCustomLottery && isLottery" style="display: inline">{{ 'LOTTERY.LOTTERY_TITLE' | translate }}</h4>
      <h4 *ngIf="customAdminSettings && customAdminSettings.hasCustomLottery && !isLottery" style="display: inline">{{ 'LOTTERY.SHOW_SUPPLIERS_TITLE' | translate }}</h4>
      <button *ngIf="customAdminSettings && customAdminSettings.hasCustomLottery && isLottery" type="button" class="btn btn-primary" style="margin-left: 20px" (click)="showSuppliers()"><clr-icon shape="two-way-arrows"></clr-icon> {{ 'LOTTERY.SWITCH_TO_SHOW_SUPPLIERS' | translate }}</button>
      <button *ngIf="customAdminSettings && customAdminSettings.hasCustomLottery && !isLottery" type="button" class="btn btn-primary" style="margin-left: 20px" (click)="executeLottery()"><clr-icon shape="two-way-arrows"></clr-icon> {{ 'LOTTERY.SWITCH_TO_LOTTERY' | translate }}</button>
    </div>

    <dx-data-grid #lotteryGrid
                  id="printSection"
                  [rtlEnabled]="rtlEnabled"
                  [dataSource]="items"
                  [showBorders]="true"
                  [allowColumnReordering]="true"
                  [hoverStateEnabled]="true"
                  [columnAutoWidth]="true"
                  [rowAlternationEnabled]="true"
                  (onExporting)="onExporting($event)">
      <dxo-export [enabled]="true"></dxo-export>
      <dxo-search-panel [visible]="true"></dxo-search-panel>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-filter-panel [visible]="true"></dxo-filter-panel>
      <dxo-header-filter [visible]="true"></dxo-header-filter>
      <dxo-pager [showPageSizeSelector]="true"
                 [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]"
                 [showNavigationButtons]="true"
                 [showInfo]="true"
                 [visible]="true"
                 infoText="{2} {{ 'LOTTERY.PAGINATION_TEXT_PLACEHOLDER' | translate | slice:2 }}">
      </dxo-pager>
      <dxi-column type="selection"
                  dataField="selected"
                  cellTemplate="selectionCellTemplate"
                  headerCellTemplate="selectionHeaderCellTemplate">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="supplierDisplayName" caption="{{ 'LOTTERY.SUPPLIER_NAME' | translate }}">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="arrivalTimeString" caption="{{ 'LOTTERY.ARRIVAL_TIME' | translate }}" [(visible)]="!isLottery && customAdminSettings && customAdminSettings.hasCustomLottery">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="lastLotteryDateString" caption="{{ 'LOTTERY.LAST_LOTTERY_DATE' | translate }}" [(visible)]="isLottery">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="lastFirstPlaceDateString" caption="{{ 'LOTTERY.LAST_FIRST_PLACE_DATE' | translate }}" [(visible)]="isLottery">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="boolean" dataField="isBalticSeaSupplier" caption="{{ 'LOTTERY.IS_BALTIC_SEA_SUPPLIER' | translate }}" [(visible)]="isLottery">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="previousLotteryPosition" caption="{{ 'LOTTERY.PREVIOUS_LOTTERY_POSITION' | translate }}" [(visible)]="isLottery">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="automaticPosition" caption="{{ 'LOTTERY.AUTOMATIC_POSITION' | translate }}" [(visible)]="isLottery">
      </dxi-column>
      <div *dxTemplate="let headerInfo of 'selectionHeaderCellTemplate'">
        <dx-button hint="{{ 'MOVE_UP' | translate }}" icon="arrowup" (onClick)="moveUp()"></dx-button>
        <dx-button hint="{{ 'MOVE_DOWN' | translate }}" icon="arrowdown" (onClick)="moveDown()"></dx-button>
      </div>
      <div *dxTemplate="let cellInfo of 'selectionCellTemplate'">
        <dx-check-box class="checkbox-selector" *ngIf="items && cellInfo && items[cellInfo.rowIndex]"
                      [(value)]="items[cellInfo.rowIndex].selected"
                      [rtlEnabled]="rtlEnabled">
        </dx-check-box>
      </div>
    </dx-data-grid>

    <div *ngIf="customAdminSettings && customAdminSettings.hasCustomLottery">

      <div class="filter-column">
        <label for="openingTimeTimeComponent">{{ 'LOTTERY.OPENING_TIME' | translate }}</label>
        <time-component ngDefaultControl class="dt-input" [name]="openingTimeTimeComponent" [data]="openingTime" (modelChanged)="dateChanged('openingTime', $event)" [required]="customAdminSettings && customAdminSettings.hasCustomLottery">
        </time-component>
      </div>

      <div class="filter-column">
        <label for="sortingTimeTimeComponent">{{ 'LOTTERY.SORTING_TIME' | translate }}</label>
        <time-component ngDefaultControl class="dt-input" [name]="sortingTimeTimeComponent" [data]="sortingTime" (modelChanged)="dateChanged('sortingTime', $event)" [required]="customAdminSettings && customAdminSettings.hasCustomLottery">
        </time-component>
      </div>

      <div class="filter-column">
        <label for="salesTimeTimeComponent">{{ 'LOTTERY.SALES_TIME' | translate }}</label>
        <time-component ngDefaultControl class="dt-input" [name]="salesTimeTimeComponent" [data]="salesTime" (modelChanged)="dateChanged('salesTime', $event)" [required]="customAdminSettings && customAdminSettings.hasCustomLottery">
        </time-component>
      </div>
    </div>

    <div *ngIf="customAdminSettings && customAdminSettings.hasCustomLottery">
      <div class="filter-column">
        <label for="remarkInput">{{ 'LOTTERY.REMARK' | translate }}</label>
        <dx-text-box [(value)]="remark"></dx-text-box>
      </div>
    </div>

    <button type="button" class="btn btn-primary" [disabled]="items?.length <= 0" (click)="saveLottery()">{{ 'LOTTERY.SAVE_LOTTERY' | translate }}</button>
    <button type="button" class="btn btn-primary" [disabled]="items?.length <= 0" (click)="sendNotification($event)">{{ 'LOTTERY.SEND_NOTIFICATION' | translate }}</button>
  </div>
</div>
