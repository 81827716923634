import { Component, OnInit, Injector, ViewChildren, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _moment from 'moment';
import { Moment } from 'moment';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

// general services
import { LanguageService } from '../../shared/services/language.service';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';
import { TranslatableFieldComponent } from '../../shared/components/translatable-field/translatable-field.component';
import { Subscription } from 'rxjs';
import notify from 'devextreme/ui/notify';

// services
import { ShoppingListsService } from '../shared/services/shopping-lists.service';
import { LookupTableService } from '../shared/services/lookup-table.service';

// models
import { Language } from '../../shared/models/language';
import { ShoppingList } from '../shared/models/shopping-list';
import { ProductPropertyTypes } from '../../auction/shared/models/product';
import { Lot, LotProperty } from '../../auction/shared/models/lot';
import { TranslateService } from '@ngx-translate/core';
import { DxFormComponent } from 'devextreme-angular';

@Component({
  selector: 'shopping-list-lot-component',
  templateUrl: 'shopping-list-lot.component.html',
  styleUrls: ['./shopping-list-lot.component.scss']
})
export class ShoppingListLotComponent extends ItemDetailsComponent<Lot> implements OnInit, OnDestroy {
  @ViewChildren(TranslatableFieldComponent) translatableFields: Array<TranslatableFieldComponent> = [];

  buyerId: number;
  catalogId: number;
  languages: Array<Language> = [];
  shoppingList: ShoppingList = new ShoppingList();
  masterDataDropdowns: Array<any> = [];
  masterDataDropdownsFiltered: Array<any> = [];
  dropdownsData: Array<any> = [];
  comboBoxes: Array<any> = [];
  lang = 'nl-be';

  @ViewChild('shoppingLotForm') formComponent: DxFormComponent;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private dataService: ShoppingListsService,
    private lookupTableService: LookupTableService,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private route: ActivatedRoute
  ) {
    super(injector);
    this.lang = this.translateService.currentLang;
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
    this.translate.get('SHOPPING_LIST').subscribe((res: string) => {
      this.translations = res;
    });
  }

  ngOnInit() {
    this.model = new Lot();
    this.buyerId = +this.route.snapshot.params['id'];
    this.catalogId = +this.route.snapshot.params['catalogId'];
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  open(lot: Lot, languages: Array<Language>,
    shoppingList: ShoppingList, masterDataDropdowns: Array<any>) {

    this.languages = languages;
    this.shoppingList = shoppingList;
    this.masterDataDropdowns = masterDataDropdowns;
    this.masterDataDropdownsFiltered = JSON.parse(JSON.stringify(this.masterDataDropdowns));
    this.dropdownsData = JSON.parse(JSON.stringify(masterDataDropdowns));

    if (lot != null) {
      this.isEditMode = true;
      this.model = lot;
    } else {
      this.model = new Lot();
      this.isEditMode = false;
    }

    this.parseModel();
    this.model.catalogId = this.shoppingList.shoppingListId;
    this.generateForm();
    this.isOpened = true;
  }

  parseModel() {
    if (this.model.lotProperties.length === 0) {
      this.shoppingList.shoppingListProductDefinitionBuyer.forEach(ppf => {
        // check if already added
        const alreadyAddedProp = this.model.lotProperties.find(p => p.productPropertyId === ppf.productPropertyId);
        if (!alreadyAddedProp) {
          const newObj = new LotProperty();
          newObj.orderNumber = ppf.orderNumber;
          newObj.masterDataListFieldId = ppf.masterDataListFieldId;
          newObj.name = ppf.name;
          newObj.propertyTypeId = ppf.propertyTypeId;
          newObj.propertyTypeFormatId = ppf.propertyTypeFormatId;
          newObj.productPropertyId = ppf.productPropertyId;
          newObj.translatable = ppf.translatable;
          if (newObj.translatable) {
            newObj.stringValue = this.createEmtpyObjectForTranslatableFields();
          }

          this.model.lotProperties.push(newObj);
        }
      });
    } else {
      this.model.lotProperties.forEach(lp => {
        const field = this.shoppingList.shoppingListProductDefinitionBuyer.find(ppf => ppf.productPropertyId === lp.productPropertyId);

        if (field) {
          lp.orderNumber = field.orderNumber;
          lp.masterDataListFieldId = field.masterDataListFieldId;
          lp.name = field.name;
          lp.propertyTypeId = field.propertyTypeId;
          lp.propertyTypeFormatId = field.propertyTypeFormatId;
          lp.translatable = field.translatable;

          if (lp.propertyTypeId === ProductPropertyTypes.DATE &&  lp.dateTimeValue) {
            const serverReturnedTime = moment.utc(lp.dateTimeValue);
            lp.dateTimeValue = moment(serverReturnedTime).local();
          }
          this.onPropertyChanged(lp);
        }
      });
    }

    this.model.lotProperties.sort((a, b) => a.orderNumber - b.orderNumber );

    this.setComboBoxes();
  }

  onPropertyChanged = (property: LotProperty) => {
    // clear values first
    this.dropdownsData.length = 0;
    for (let i = 0; i < this.masterDataDropdowns.length; i += 1) {
      this.lookupTableService.getLookupTableForProductProperty(this.buyerId, this.masterDataDropdowns[i].productPropertyId, this.model)
        .subscribe(lookup => {
          if (lookup) {
            if (lookup.lookupTableType === 0 && lookup.lookupTableRows.length > 0) {
              const newValues = [];
              lookup.lookupTableRows.forEach(row => {
                row.lookupTableValues.forEach(value => {
                  this.masterDataDropdowns[i].values.forEach(mdValue => {
                    if (mdValue.value === value.masterDataListRowId && newValues.indexOf(mdValue) === -1) {
                      newValues.push(mdValue);
                    }
                  });
                });
              });
              if (newValues.length > 0) {
                this.dropdownsData.push({ productPropertyId: this.masterDataDropdowns[i].productPropertyId, values: newValues });
                this.masterDataDropdownsFiltered[i] = {
                  productPropertyId: this.masterDataDropdowns[i].productPropertyId, values: newValues, filtered: true
                };
              }
              this.setComboBoxes();
            } else {
              this.pushDropdownsData(property, i);
            }
          } else {
            this.pushDropdownsData(property, i);
          }
        }, error => {
          this.pushDropdownsData(property, i);
        });
    }
  }

  pushDropdownsData(property: LotProperty, i: number) {
    if (this.masterDataDropdownsFiltered[i].filtered === true && property.masterDataListRowId) {
      this.dropdownsData.push(this.masterDataDropdownsFiltered[i]);
    } else {
      this.dropdownsData.push(this.masterDataDropdowns[i]);
    }
    this.setComboBoxes();
  }

  createEmtpyObjectForTranslatableFields() {
    // Create empty JSON object for translation fields
    const emptyTranslation = {};
    this.languages.forEach(lang => {
      emptyTranslation[lang.code] = '';
    });

    return JSON.stringify(emptyTranslation);
  } 

  clearLotPropertyValue(lotProperty: LotProperty) {
    const lp = this.model.lotProperties.find(lp => lp.lotPropertyId === lotProperty.lotPropertyId);
    if (lp) {
      lp.masterDataListRowId = null;
    }
  }

  setComboBoxes() {
    this.comboBoxes.length = 0;
    this.model.lotProperties.forEach((lp, i) => {
      this.comboBoxes[i] = this.getDropdownValues(lp);
      if (this.comboBoxes[i]) {
        var comboBoxValue = this.comboBoxes[i].find(c => c.value == lp.masterDataListRowId);
        if (!comboBoxValue) {
          lp.masterDataListRowId = null;
        }
      }
    });
  }

  getDropdownValues(lotProperty: LotProperty) {
    const masterDataDropdown = this.dropdownsData.find(dd => dd.productPropertyId === lotProperty.productPropertyId);
    if (masterDataDropdown) {
      const values = masterDataDropdown.values;
      values.sort((a, b) => a.label < b.label ? -1 : a.label > b.label ? 1 : 0);
      return values;
    }
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  parseBeforeSave() {
    this.model.lotProperties.forEach(lotProperty => {
      if (lotProperty.propertyTypeId === ProductPropertyTypes.DATE &&  lotProperty.dateTimeValue) {
        lotProperty.dateTimeValue = moment.utc(lotProperty.dateTimeValue);
      }

      if (lotProperty.propertyTypeId === ProductPropertyTypes.TEXT && lotProperty.translatable) {
        const field = this.translatableFields.find(tf => tf.componentIndex === lotProperty.productPropertyId);
        if (field) {
          lotProperty.stringValue = field.data;
        }
      }
    });
  }

  save() {
    this.parseBeforeSave();

    if (this.model.lotId) {
      this.isEditMode = true;
    }

    if (this.isEditMode) {
      this.spinner.show();
      this.dataService.editLot(this.buyerId, this.shoppingList.auctionClusterId, this.model)
        .subscribe((res: any) => {
          this.model = new Lot();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorMessage = this.errorService.toString(error);
            notify(this.errorMessage, 'error', 5000);
            this.spinner.hide();
          });
    } else {
      this.spinner.show();
      this.dataService.saveLot(this.buyerId, this.shoppingList.auctionClusterId, this.model)
        .subscribe((res: any) => {
          this.model = new Lot();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorMessage = this.errorService.toString(error);
            notify(this.errorMessage, 'error', 5000);
            this.spinner.hide();
          });
    }
  }

  dateChanged(property: any, dateTime: Moment) {
    const matches = property.dataField.match(/\[(.*?)\]/);
    this.model.lotProperties[Number(matches[1])].dateTimeValue = moment(dateTime).toDate();
  }

  dateValue(property: any) {
    const matches = property.dataField.match(/\[(.*?)\]/);
    if (this.model.lotProperties[Number(matches[1])])
      return this.model.lotProperties[Number(matches[1])].dateTimeValue;
    else
      return null;
  }

  public onCancel() {
    super.onCancel();
  }

  generateFields = () => {
    const generatedItems = [];

    return new Promise((resolve, reject) => {

      this.model.lotProperties.forEach((field, i) => {
        if (field.propertyTypeId === 1 && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].intValue',
              editorType: 'dxNumberBox',
              label: { text: field.name },
              editorOptions: {
                showSpinButtons: true,
                mode: 'number',
                format: '#0',
                min: 0,
                step: 1
              }
            }
          );
        } else if (field.propertyTypeId === 2 && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].decimalValue',
              editorType: 'dxNumberBox',
              label: { text: field.name },
              editorOptions: {
                showSpinButtons: true,
                mode: 'number',
                format: '#0.0',
                min: 0,
                step: 0.1
              }
            }
          );
        } else if (field.propertyTypeId === 3 && !field.translatable && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].stringValue',
              label: { text: field.name }
            }
          );
        } else if (field.propertyTypeId === 3 && field.translatable && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].stringValue',
              label: { text: field.name },
              template: 'translatableField'
            }
          );
        } else if (field.propertyTypeId === 4 && field.propertyTypeFormatId === 11 && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].dateTimeValue',
              label: { text: field.name },
              template: 'fullDateTemplate'
            }
          );
        } else if (field.propertyTypeId === 4 && field.propertyTypeFormatId === 12 && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].dateTimeValue',
              label: { text: field.name },
              template: 'longDateTemplate'
            }
          );
        } else if (field.propertyTypeId === 4 && field.propertyTypeFormatId === 13 && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].dateTimeValue',
              label: { text: field.name },
              template: 'longTimeTemplate'
            }
          );
        } else if (field.propertyTypeId === 4 && field.propertyTypeFormatId === 14 && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].dateTimeValue',
              label: { text: field.name },
              template: 'shortDateTemplate'
            }
          );
        } else if (field.propertyTypeId === 4 && field.propertyTypeFormatId === 15 && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].dateTimeValue',
              label: { text: field.name },
              template: 'shortTimeTemplate'
            }
          );
        } else if (field.propertyTypeId === 5 && this.model.lotProperties[i]) {
          const intervalSelectBoxes = setInterval(() => {
            if (this.comboBoxes[i] && this.comboBoxes[i].length > 0) {
              generatedItems.push(
                {
                  dataField: 'lotProperties[' + i + '].masterDataListRowId',
                  label: { text: field.name },
                  editorType: 'dxSelectBox',
                  editorOptions: {
                    dataSource: this.comboBoxes[i],
                    valueExpr: 'value',
                    displayExpr: 'label',
                    searchEnabled: true,
                    showClearButton: true,
                    onValueChanged: (e: any) => {
                      this.onPropertyChanged(field);
                    },
                    onOpened: (e: any) => {
                      this.onOpenedBoxes(e, i);
                    }
                  }
                }
              );
              clearInterval(intervalSelectBoxes);
            }
          }, 10);
        } else if (field.propertyTypeId === 7 && this.model.lotProperties[i]) {
          generatedItems.push(
            {
              dataField: 'lotProperties[' + i + '].booleanValue',
              editorType: 'dxCheckBox',
              label: { text: field.name }
            }
          );
        }
      });

      const intervalResolve = setInterval(() => {
        if (generatedItems.length >= this.model.lotProperties.length) {
          resolve(generatedItems);
          clearInterval(intervalResolve);
        }
      }, 50);

    });
  }

  onOpenedBoxes = (e: any, index: number) => {
    e.component.option('dataSource', this.comboBoxes[index]);
  }

  generateForm = async () => {
    const items = [];
    let generatedItems;
    this.formComponent.instance.option('items', items);

    if (this.model.lotProperties.length > 0) {
      generatedItems = await this.generateFields();
    }

    generatedItems.push(
      {
        dataField: 'amountWanted',
        editorType: 'dxNumberBox',
        label: { text: this.translations.AMOUNT_WANTED },
        editorOptions: {
          showSpinButtons: true,
          mode: 'number',
          format: '#0',
          min: 0,
          step: 1
        }
      }
    );

    items.push(
      {
        itemType: 'group',
        caption: '',
        items: generatedItems
      });
      this.formComponent.instance.option('items', items);
  }
}
