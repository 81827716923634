import { Component, OnInit, Injector } from '@angular/core';
import { forkJoin } from 'rxjs';

// general services
import { LanguageService } from '../../shared/services/language.service';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { ProductComponent } from './product.component';

// services
import { ProductService } from '../shared/services/product.service';

// models
import { Product } from '../shared/models/product';
import { Language } from '../../shared/models/language';
import { ImportSchema, ImportField } from '../../shared/import/import-schema';

@Component({
  selector: 'products-component',
  templateUrl: 'products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent extends FullListComponent<Product, ProductComponent> implements OnInit {

  languages: Array<Language> = [];

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private dataService: ProductService,
  ) {
    super(injector, Product);
    this.title.set('PRODUCTS.TITLE');
  }

  ngOnInit() {
    this.setTranslations('PRODUCTS');
  }

  // tslint:disable:no-magic-numbers
  getData() {
    this.spinner.show();
    forkJoin(
      this.dataService.getProducts(this.id),
      this.languageService.getAuctionClusterLanguages(this.id),
    ).subscribe(result => {
      this.items = result[0];
      this.languages = result[1];
      this.translateProducts();
      this.spinner.hide();
    },
      error => {
        this.errorService.show(error);
        this.spinner.hide();
      });
  }
  // tslint:disable:no-magic-numbers

  add() {
    this.router.navigate(['auction/products/' + this.id + '/product']); // tslint:disable-line:no-floating-promises
  }

  private translateProducts() {
    this.items.forEach(product => {
      product.productNameText = this.languageService.getTranslatableText(product.name);
      product.productDescriptionText = this.languageService.getTranslatableText(product.description);
    });
  }

  deleteSelected() {
    this.spinner.show();
    this.dataService.delete(this.id, this.itemIdToDelete)
      .subscribe((users: Array<Product>) => {
        this.getData();
        this.spinner.hide();
      },
        error => {
          this.errorService.show(this.errorService.translations.DELETE_ERROR_MESSAGE);
          this.spinner.hide();
        });
  }

  navigateToProduct(productId: number, event: Event) {
    event.stopPropagation();
    this.router.navigate(['auction/products/' + this.id + '/product/' + productId]); // tslint:disable-line:no-floating-promises
  }

  createDataSchema() {

    const schema = new ImportSchema();

    const productNameText = new ImportField('productNameText', this.translations.NAME);
    productNameText.required = true;

    const productDescriptionText = new ImportField('productDescriptionText', this.translations.DESCRIPTION);
    productDescriptionText.required = true;

    schema.fields.push(productNameText, productDescriptionText
    );

    this.schema = schema;
  }

  edit(itemId: number, event: Event) { }
}
