import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../shared/services/auth-guard.service';
import { BrowserGuard } from '../shared/services/browser-guard.service';

import { AuctionContentComponent } from './content/auction-content.component';
import { AuctionHomeComponent } from './home/auction-home.component';

import { AuctionClusterUsersComponent } from './auction-cluster-users/auction-cluster-users.component';
import { AuctionClusterRolesComponent } from './auction-cluster-roles/auction-cluster-roles.component';
import { AuctionClusterReportingRolesComponent } from './auction-cluster-reporting-roles/auction-cluster-reporting-roles.component';
import { AuctionsComponent } from './auctions/auctions.component';
import { BuyersComponent } from './buyers/buyers.component';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { LocationsComponent } from './locations/locations.component';
import { ProductsComponent } from './products/products.component';
import { MasterDetailsComponent } from './catalogs/master-details.component';
import { SupplyCatalogsComponent } from './catalogs/supply-catalogs.component';
import { ForecastCatalogsComponent } from './catalogs/forecast-catalogs.component';
import { TicketPrintersComponent } from './ticket-printers/ticket-printers.component';
import { TicketPrinterComponent } from './ticket-printers/ticket-printer.component';
import { ClocksComponent } from './clocks/clocks.component';
import { ZonesComponent } from './zones/zones.component';
import { MasterDataListsComponent } from './master-data/master-data-lists.component';
import { MasterDataListEditorComponent } from './master-data/master-data-list-editor.component';
import { LookupTablesComponent } from './lookup-table/lookup-tables.component';
import { MasksComponent } from './mask/masks.component';
import { LookupTableEditorComponent } from './lookup-table/lookup-table-editor.component';
import { LeavePageService } from './shared/services/leave-page.service';
import { SupplyLotsComponent } from './catalogs/supply-lots.component';
import { ProductEditorComponent } from './products/product-editor.component';
import { PublicSupplyForecastComponent } from '../shared/components/public-supply-forecast/public-supply-forecast.component';
import { MasterDataListRowEditorComponent } from './master-data/master-data-list-row-editor.component';
import { LotteryComponent } from './lottery/lottery.component';
import { ReportComponent } from '../shared/components/reporting/report.component';
import { UserLoginsComponent } from '../shared/components/reporting/user-logins.component';
import { UserLineTimesComponent } from '../shared/components/reporting/user-line-times.component';
import { CalculationsComponent } from './calculations/calculations.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportEditorComponent } from './reports/report-editor.component';
import { ReportExportComponent } from './reports/report-export.component';
import { AuctionSupplyMonitorComponent } from './supply-monitor/auction-supply-monitor.component';
import { AuctionTransactionMonitorComponent } from './transaction-monitor/auction-transaction-monitor.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { SupplyLotsTouchComponent } from './catalogs/supply-lots-touch.component';
import { PublicSupplyForecastURKComponent } from '../shared/components/public-supply-forecast-urk/public-supply-forecast-urk.component';
import { GalleryComponent } from './gallery/gallery.component';
import { AdditionalMenuItemsContainerComponent } from '../shared/components/additional-menu-items-container/additional-menu-items-container.component';
import { NotificationsComponent } from './notifications/notifications.component';

const appRoutes: Routes = [
  {
    path: 'auction',
    component: AuctionContentComponent,
    canActivateChild: [AuthGuard, BrowserGuard],
    data: { level: 'auction' },
    children: [
      { path: 'supplymonitor/:id', component: AuctionSupplyMonitorComponent, data: { permission: ['AuctionClusterPermissions.12', 'AuctionPermissions.12'] }, runGuardsAndResolvers: 'paramsChange' },
      { path: 'users/:id', component: AuctionClusterUsersComponent, data: { permission: 'AuctionClusterPermissions.1' } },
      { path: 'roles/:id', component: AuctionClusterRolesComponent, data: { permission: 'AuctionClusterPermissions.16' } },
      { path: 'reportingroles/:id', component: AuctionClusterReportingRolesComponent, data: { permission: 'AuctionClusterPermissions.16' } },
      { path: 'auction/:id', component: AuctionsComponent, data: { permission: 'AuctionClusterPermissions.2' } },
      { path: 'buyer/:id', component: BuyersComponent, data: { permission: 'AuctionClusterPermissions.8' } },
      { path: 'supplier/:id', component: SuppliersComponent, data: { permission: 'AuctionClusterPermissions.9' } },
      { path: 'locations/:id', component: LocationsComponent, data: { permission: 'AuctionClusterPermissions.5' } },
      { path: ':id/menu-item/:menuId', component: AdditionalMenuItemsContainerComponent },

      {
        path: 'products/:id',
        children: [
          { path: '', component: ProductsComponent, data: { permission: 'AuctionClusterPermissions.2' } },
          { path: 'product', component: ProductEditorComponent, data: { permission: 'AuctionClusterPermissions.2' } },
          { path: 'product/:productId', component: ProductEditorComponent, data: { permission: 'AuctionClusterPermissions.2' } },
        ]
      },

      { path: 'calculations/:id', component: CalculationsComponent, data: { permission: 'AuctionClusterPermissions.2' } },
      { path: 'supplycatalogs/:id', component: SupplyCatalogsComponent, data: { permission: ['AuctionPermissions.4'] } },
      { path: 'forecastcatalogs/:id', component: ForecastCatalogsComponent, data: { permission: ['AuctionPermissions.18'] } },

      {
        path: 'catalogs/:id',
        data: { permission: ['AuctionPermissions.4', 'AuctionPermissions.18' ] },
        children: [
          { path: 'masterdetails/:catalogId', component: MasterDetailsComponent },
          { path: 'forecastmasterdetails/:catalogId', component: MasterDetailsComponent, data: { permission: ['AuctionPermissions.18'], isForecast: true } },
          { path: 'supplylots/:catalogId', component: SupplyLotsComponent, data: { permission: ['AuctionPermissions.4'] } },
          { path: 'supplylots/:catalogId/editor', component: SupplyLotsTouchComponent, data: { permission: ['AuctionPermissions.4'] } },
          { path: 'supplyforecastlots/:catalogId', component: SupplyLotsComponent, data: { permission: ['AuctionPermissions.18'], isForecast: true } },
          { path: 'supplyforecastlots/:catalogId/editor', component: SupplyLotsTouchComponent, data: { permission: ['AuctionPermissions.18'], isForecast: true } },
        ]
      },
      { path: 'ticketprinters/:id', component: TicketPrintersComponent, data: { permission: 'AuctionClusterPermissions.13' } },
      { path: 'ticketprinters/:id/ticketprinter/:ticketPrinterId', component: TicketPrinterComponent, data: { permission: 'AuctionClusterPermissions.13' } },
      { path: 'clocks/:id', component: ClocksComponent, data: { permission: ['AuctionClusterPermissions.3', 'AuctionClusterPermissions.10', 'AuctionPermissions.10', 'AuctionPermissions.3'] } },
      { path: 'zones/:id', component: ZonesComponent, data: { permission: 'AuctionClusterPermissions.17' } },
      { path: 'masterdata/:id', component: MasterDataListsComponent, data: { permission: 'AuctionClusterPermissions.6' } },
      { path: 'masterdata/:id/masterdatalist/:masterDataListId', component: MasterDataListEditorComponent, pathMatch: 'full', canDeactivate: [LeavePageService], data: { permission: 'AuctionClusterPermissions.6' } },
      { path: 'lookuptables/:id', component: LookupTablesComponent, data: { permission: 'AuctionClusterPermissions.7' } },
      { path: 'lookuptables/:id/lookuptableeditor/:lookupTableId', component: LookupTableEditorComponent, data: { permission: 'AuctionClusterPermissions.7' } },
      { path: 'masks/:id', component: MasksComponent, data: { permission: 'AuctionClusterPermissions.2' } },
      { path: 'reports/:id', component: ReportsComponent, data: { permission: 'AuctionClusterPermissions.11' } },
      { path: 'reports/:id/report/:reportId', component: ReportEditorComponent, data: { permission: 'AuctionClusterPermissions.11' } },      
      { path: 'reports/:id/report/:reportId/export', component: ReportExportComponent, data: { permission: 'AuctionClusterPermissions.11' } },
      { path: 'supplyforecast/:id', component: PublicSupplyForecastURKComponent, },
      { path: 'lottery/:id', component: LotteryComponent, data: { permission: ['AuctionClusterPermissions.19', 'AuctionPermissions.19'] } },
      { path: 'transactionmonitor/:id', component: AuctionTransactionMonitorComponent, data: { permission: ['AuctionClusterPermissions.15', 'AuctionPermissions.15'] } },
      { path: 'terms/:id', component: TermsAndConditionsComponent, data: { permission: 'AuctionClusterPermissions.21' } },
      { path: 'gallery/:id', component: GalleryComponent, data: { permission: 'AuctionClusterPermissions.22' } },      
      { path: 'notifications/:id', component: NotificationsComponent},
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(appRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class AuctionRoutingModule { }

