import { Component, Injector, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { ClrTabs } from '@clr/angular';

// general services
import { Language } from '../../shared/models/language';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';
import notify from 'devextreme/ui/notify';
import { Subscription } from 'rxjs';

// services
import { AuctionClusterLevelRoleService } from '../shared/services/auction-cluster-level-role.service';
import { LanguageService } from '../../shared/services/language.service';

// models
import { AuctionClusterRole } from '../../shared/models/auction-cluster-role';
import { AuctionClusterPermission } from '../shared/models/auction-cluster-permission';
import { AuctionClusterPermissionEnum } from '../../shared/models/user-permissions';
import { DxFormComponent } from 'devextreme-angular';

@Component({
  selector: 'auction-cluster-role-component',
  templateUrl: 'auction-cluster-role.component.html',
  styleUrls: ['./auction-cluster-role.component.scss']
})
export class AuctionClusterRoleComponent extends ItemDetailsComponent<AuctionClusterRole> implements OnInit, OnDestroy {

  permissions: Array<AuctionClusterPermission> = [];
  permissionsCluster: Array<AuctionClusterPermission> = [];
  languages: Array<Language> = [];
  requiredPermissions: Array<number> = [];
  requiredPermissionsCluster: Array<number> = [];
  auctionClusterId: number;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  validFields: boolean = false;

  @ViewChild('tabs') tabs: ClrTabs;
  @ViewChild('roleName') roleName;
  @ViewChild('roleDescription') roleDescription;
  @ViewChild('roleForm', { read: DxFormComponent}) roleForm: DxFormComponent;
  
  constructor(
    protected injector: Injector,
    private dataService: AuctionClusterLevelRoleService,
    private languageService: LanguageService
  ) {
    super(injector);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.model = new AuctionClusterRole();
    this.setTranslations('ROLE');
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  open(roles: Array<AuctionClusterRole>, roleId: number, permissions: Array<AuctionClusterPermission>,
    languages: Array<Language>, auctionClusterId: number) {
    this.allItems = roles;

    this.permissions = permissions.filter(_ => !this.isAuctionClusterPermission(_.auctionPermissionId));
    this.permissionsCluster = permissions.filter(_ => this.isAuctionClusterPermission(_.auctionPermissionId));
    this.languages = languages;
    this.requiredPermissions = [];
    this.requiredPermissionsCluster = [];
    this.auctionClusterId = auctionClusterId;

    // Autoselect first tab
    // this.tabs.ifActiveService.current = this.tabs.tabsService.children[0].id;

    if (roleId != null) {
      this.isEditMode = true;
      this.spinner.show();
      this.dataService.getAuctionClusterRole(this.auctionClusterId, roleId)
        .subscribe((res: AuctionClusterRole) => {
          this.model = res;
          this.model.name = this.languageService.updateStringToAllLanguages(languages, this.model.name);
          this.model.description = this.languageService.updateStringToAllLanguages(languages, this.model.description);
          this.requiredPermissions = this.model.permissions.filter(_ => !this.isAuctionClusterPermission(_));
          this.requiredPermissionsCluster = this.model.permissions.filter(_ => this.isAuctionClusterPermission(_));
          this.isOpened = true;

          this.validFields = this.languageService.checkDefaultLanguageNotEmpty(this.languages, this.model.name);

          this.spinner.hide();
        },
        error => {
          notify(error.message, 'error', 5000);
          this.spinner.hide();
        });
    } else {
      this.model = new AuctionClusterRole();
      this.model.name = this.model.description = this.languageService.updateStringToAllLanguages(languages, "{}");
      this.isEditMode = false;
      this.isOpened = true;
    }
  }

  save() {
    this.model.name = this.roleName.data;

    if (!this.isUnique()) {
      return;
    }

    this.model.permissions = this.requiredPermissions.concat(this.requiredPermissionsCluster);
    this.model.description = this.roleDescription.data;

    if (this.isEditMode) {
      this.spinner.show();
      this.dataService.edit(this.model)
        .subscribe((res: any) => {
          this.model = new AuctionClusterRole();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
        error => {
          this.errorMessage = this.errorService.toString(error);
          notify(this.errorMessage, 'error', 5000);
          this.spinner.hide();
        });
    } else {
      this.spinner.show();

      this.model.auctionClusterId = this.auctionClusterId;
      this.model.isAuctionClusterTypeRole = true;

      this.dataService.save(this.model.auctionClusterId, this.model)
        .subscribe((res: any) => {
          this.model = new AuctionClusterRole();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
        error => {
          this.errorMessage = this.errorService.toString(error);
          notify(this.errorMessage, 'error', 5000);
          this.spinner.hide();
        });
    }
  }

  private isUnique(): boolean {

    // create role list without current role
    const roleList = this.allItems.filter((r) => {
      return r.auctionClusterRoleId !== this.model.auctionClusterRoleId;
    });

    const nameNotUniqueList = roleList.filter((s) => {
      let name = this.languageService.updateStringToAllLanguages(this.languages, s.name);
      return !this.languageService.checkAllLanguageFieldsUnique(this.languages, name, this.model.name)
    });

    if (nameNotUniqueList.length > 0) {
      this.errorMessage = this.translations.NAME_NOT_UNIQUE;
      notify(this.errorMessage, 'error', 5000);
      return false;
    }

    return true;
  }

  onCancel() {
   this.roleName.resetLanguageButtons();
   this.roleDescription.resetLanguageButtons();
   super.onCancel();
  }

  isAuctionClusterPermission(permissionId: number) {
    return permissionId === AuctionClusterPermissionEnum.UserManagement
      || permissionId === AuctionClusterPermissionEnum.Administrator.valueOf()
      || permissionId === AuctionClusterPermissionEnum.LocationManagement.valueOf()
      || permissionId === AuctionClusterPermissionEnum.MasterdataManagement.valueOf()
      || permissionId === AuctionClusterPermissionEnum.LookuptableManagement.valueOf()
      || permissionId === AuctionClusterPermissionEnum.BuyerConfiguration.valueOf()
      || permissionId === AuctionClusterPermissionEnum.SupplierConfiguration.valueOf()
      || permissionId === AuctionClusterPermissionEnum.RoleManagement.valueOf()
      || permissionId === AuctionClusterPermissionEnum.ZoneManagement.valueOf()
      || permissionId === AuctionClusterPermissionEnum.ExternalManagement.valueOf()
      || permissionId === AuctionClusterPermissionEnum.TermsOfUse.valueOf()
  }

  onFieldDataChanged(e: any) {
    if ((e.component._isReady && e.component.NAME !== 'dxPopup') || e.component.NAME === 'dxForm') {
      const result = e.component.validate();
      if (result.brokenRules.length == 0 || this.validFields) {
        document.getElementsByName('btnRoleSubmit')[0].removeAttribute('disabled');
      } else {
        document.getElementsByName('btnRoleSubmit')[0].setAttribute('disabled', 'disabled');
      }
    } else {
      if (this.isEditMode && this.validFields) {
        document.getElementsByName('btnRoleSubmit')[0].removeAttribute('disabled');
      } else {
        document.getElementsByName('btnRoleSubmit')[0].setAttribute('disabled', 'disabled');
      }
    }
  }

  onChangeTranslationField = (e: any, fieldName: string) => {
    if (fieldName === 'name') {
      this.validFields = this.languageService.checkDefaultLanguageNotEmpty(this.languages, this.roleName.data);
      this.roleForm.instance.updateData(fieldName, this.roleName.data);
    } else if (fieldName === 'description') {
      this.roleForm.instance.updateData(fieldName, this.roleDescription.data);
    }
  }

  translateCheckBoxes = (item) => {
    if (item) {
      const label = this.getTranslation(item.name);
      return label;
    }
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }
}
