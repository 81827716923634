<div class="line-monitor-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup [width]="850" [showTitle]="true" title="{{ modalTitle }}" [dragEnabled]="false"
            [closeOnOutsideClick]="false" [rtlEnabled]="rtlEnabled" [(visible)]="isOpened" (onHidden)="onHidden()">
    <dx-scroll-view width="100%" height="100%">
      <dx-form *ngIf="isOpened" id="lineMonitorForm" validationGroup="lineMonitorData" [showValidationSummary]="false"
               colCount="auto" [(formData)]="model" [minColWidth]="500">
        <dxi-item itemType="tabbed">
          <dxi-tab title="{{ 'SALES.LINE_MONITOR.ONLINE_USERS' | translate }}">
            <dx-data-grid id="printSection"
                          [rtlEnabled]="rtlEnabled"
                          [dataSource]="model.networkLatencies"
                          [showBorders]="true"
                          [allowColumnReordering]="true"
                          [hoverStateEnabled]="true"
                          [columnAutoWidth]="true"
                          [rowAlternationEnabled]="true">
              <dxo-selection mode="single"></dxo-selection>
              <dxo-filter-row [visible]="true"></dxo-filter-row>
              <dxo-filter-panel [visible]="true"></dxo-filter-panel>
              <dxo-search-panel [visible]="true"></dxo-search-panel>
              <dxo-header-filter [visible]="true"></dxo-header-filter>
              <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]"
                         [showNavigationButtons]="true">
              </dxo-pager>
              <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="name" caption="{{ 'SALES.LINE_MONITOR.NAME' | translate }}">
              </dxi-column>
              <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="buyerNumber" caption="{{ 'SALES.LINE_MONITOR.BUYER_NUMBER' | translate }}">
              </dxi-column>
              <dxi-column [editorOptions]="{ showClearButton: true }" cellTemplate="latencyTiming" dataField="networkLatencyTiming" caption="{{ 'SALES.LINE_MONITOR.LINE_TIME' | translate }}">
              </dxi-column>
              <dxi-column type="buttons" [width]="120">
                <dxi-button hint="{{ 'DISCONNECT' | translate }}" icon="clear" [onClick]="kickOutLineMonitorBuyer"></dxi-button>
                <dxi-button hint="{{ 'AUCTION.TRANSACTION_MONITOR.OPENREPORT' | translate }}" icon="chart" [onClick]="openReport"></dxi-button>
              </dxi-column>

              <dxo-summary>
                <dxi-total-item column="name"
                                summaryType="count" >
                </dxi-total-item>
              </dxo-summary>

              <div *dxTemplate="let data of 'latencyTiming'">
                {{ data.displayValue }} ms
                <dx-button *ngIf="isExcellent(data.value)" id="isExcellentIcon" icon="isnotblank"></dx-button>
                <dx-button *ngIf="isAverage(data.value)" id="isAverageIcon" icon="isnotblank"></dx-button>
                <dx-button *ngIf="isPoor(data.value)" id="isPoorIcon" icon="isnotblank"></dx-button>
                <dx-button *ngIf="isBad(data.value)" id="isBadIcon" icon="isnotblank"></dx-button>
              </div>
            </dx-data-grid>

          </dxi-tab>
        </dxi-item>
      </dx-form>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'CLOSE' | translate }}</button>
    </dx-scroll-view>
  </dx-popup>
</div>
