import { Component, OnInit, Injector, OnDestroy, ViewChild } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';

// general services
import { LanguageService } from '../../shared/services/language.service';
import { DateTimeService } from '../../shared/services/datetime.service';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { ReportComponent } from './report.component';
import notify from 'devextreme/ui/notify';

// services
import { AuctionClusterReportService } from '../shared/services/report.service';
import { ProductService } from '../shared/services/product.service';
import { MasterDataService } from '../shared/services/master-data.service';

// models
import { Language } from '../../shared/models/language';
import { Report, DateRanges } from '../../shared/models/report';
import { ImportSchema, ImportField } from '../../shared/import/import-schema';
import { Product, ProductPropertyTypes } from '../shared/models/product';
import { DateTimeTypes } from '../../shared/models/dateTimeTypes';
import { MasterData } from '../shared/models/master-data';

@Component({
  selector: 'reports-component',
  templateUrl: 'reports.component.html',
  styleUrls: ['reports.component.scss']
})
export class ReportsComponent extends FullListComponent<Report, ReportComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;

  languages: Array<Language> = [];
  products: Array<Product> = [];
  masterDatas: Array<MasterData> = [];
  reports: Array<Report> = [];

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private dataService: AuctionClusterReportService,
    private productService: ProductService,
    private masterDataService: MasterDataService,
    private dateTimeService: DateTimeService
  ) {
    super(injector, Report);
    this.title.set('REPORTS.TITLE');
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.setTranslations('REPORTS');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getData() {
    this.spinner.show();
    forkJoin(
      this.dataService.getReports(this.id),
      this.languageService.getLanguages()
    ).subscribe(result => {
      this.items = result[0];
      this.items.sort((a, b) => {
        if (a.reportDesign.name < b.reportDesign.name) {
          return -1;
        }
        if (a.reportDesign.name > b.reportDesign.name) {
          return 1;
        }
        return 0;
      });
      this.setReportLabels();
      this.languages = result[1];
      this.spinner.hide();
    },
      error => {
        this.errorService.show(error);
        this.spinner.hide();
      });
  }

  setReportLabels() {
    const productIds = [];
    const productObservables = [];

    this.items.forEach(i => {
      if (productIds.indexOf(i.reportDesign.productId) === -1) {
        productIds.push(i.reportDesign.productId);
        productObservables.push(this.productService.getProduct(this.id, i.reportDesign.productId));
      }
    });

    forkJoin(productObservables).subscribe(products => {
      products.forEach((prod: Product) => {
        this.products.push(prod);

        const mdIds = [];
        const mdObservables = [];

        prod.reportProperties.forEach(rp => {
          const pp = prod.productProperties.find(p => p.productPropertyId === rp.productPropertyId);

          if (pp && pp.masterDataListId) {
            if (mdIds.indexOf(pp.masterDataListId) === -1) {
              mdIds.push(pp.masterDataListId);
              mdObservables.push(this.masterDataService.getMasterDataList(pp.masterDataListId, this.id));
            }
          }
        });

        forkJoin(mdObservables).subscribe(lists => {
          lists.forEach((mdl: MasterData) => {
            this.masterDatas.push(mdl);
          });


          this.items.forEach(item => {
            const product = this.products.find(p => p.productId === item.reportDesign.productId);

            if (product) {
              item.productName = product.name;
            }

            const filterLabels = [];
            const filterData = JSON.parse(item.data);
            if (filterData !== null) {
              filterData.forEach(fd => {
                const rp = product.reportProperties.find(p => p.productPropertyId === fd.productPropertyId);
                const pp = product.productProperties.find(p => p.productPropertyId === fd.productPropertyId);
                if (rp && pp) {
                  const label = this.languageService.getTranslatableText(pp.name);
                  let value = '-';

                  if (fd.productPropertyTypeId === ProductPropertyTypes.MASTER_DATA_VALUE) {
                    const md = this.masterDatas.find(m => m.masterDataListId === pp.masterDataListId);

                    if (md) {
                      const row = md.rows.find(m => m.masterDataListRowId === fd.value);

                      if (row) {
                        const mdValue = row.values.find(v => v.masterDataListFieldId === rp.masterDataListFieldId);
                        if (mdValue) {
                          const mdField = md.fields.find(f => f.masterDataListFieldId === rp.masterDataListFieldId);
                          if (mdField) {
                            switch (mdField.propertyTypeId) {
                              case ProductPropertyTypes.NUMBER:
                                if (mdValue.intValue !== null) {
                                  value = mdValue.intValue.toString();
                                }
                                break;
                              case ProductPropertyTypes.TEXT:
                                if (mdValue.stringValue !== null) {
                                  let text = mdValue.stringValue;
                                  if (mdField.translatable) {
                                    text = this.languageService.getTranslatableText(text);
                                  }
                                  if (text) {
                                    value = text;
                                  }
                                }
                                break;
                              case ProductPropertyTypes.DECIMAL:
                                if (mdValue.decimalValue !== null) {
                                  value = mdValue.decimalValue.toString();
                                }
                                break;
                              case ProductPropertyTypes.DATE:
                                if (mdValue.dateTimeValue !== null) {
                                  value = this.dateTimeService.getDateStringByFormatAnyUtc(mdValue.dateTimeValue, mdField.propertyTypeFormatId);
                                }
                                break;
                              case ProductPropertyTypes.BOOLEAN:
                                if (mdValue.booleanValue !== null) {
                                  value = `${mdValue.booleanValue ? 'true' : 'false' }`;
                                }
                                break;
                              default: break;
                            }
                          }
                        }
                      }
                    }
                    filterLabels.push(`${label}: ${value}`);
                  } else if (fd.productPropertyTypeId === ProductPropertyTypes.DATE) {
                    if (fd.dateRangeType !== null) {
                      if (fd.dateRangeType === DateRanges.RANGE) {
                        const dateStringFrom = this.dateTimeService.getDateStringByFormatAnyUtc(fd.value.split('|')[0], DateTimeTypes.SHORT_DATE);
                        const dateStringTo = this.dateTimeService.getDateStringByFormatAnyUtc(fd.value.split('|')[1], DateTimeTypes.SHORT_DATE);

                        value = `${dateStringFrom} - ${dateStringTo}`;
                      } else {
                        value = this.translations[DateRanges[fd.dateRangeType]];
                      }
                    }
                    filterLabels.push(`${label}: ${value}`);
                  }
                }
              });
            }
            item.filterLabel = filterLabels.join('; ');
          });

        });

      });
    });

  }

  // getReportProduct(reportId) {
  //   const report = this.items.find(r => r.reportId === reportId);

  //   if (report) {
  //     const product = this.products.find(p => p.productId === report.reportDesign.productId);

  //     if (product) {
  //       return this.languageService.getTranslatableText(product.name);
  //     }
  //   }

  //   return '-';
  // }

  openReport = (e: any) => {

    const reportId = e.row !== undefined ? e.row.data.reportId : e.data.reportId;
    const auctionClusterId = e.row !== undefined ? e.row.data.reportDesign.auctionClusterId : e.data.reportDesign.auctionClusterId;

    this.router.navigate(['/auction/reports/' + auctionClusterId + '/report/' + reportId]);
  }

  deleteSelected() {
    this.spinner.show();
    this.dataService.delete(this.id, this.itemIdToDelete)
      .subscribe((users: Array<Report>) => {
        this.getData();
        this.spinner.hide();
      },
      error => {
        this.errorService.show(this.errorService.translations.DELETE_ERROR_MESSAGE);
        this.spinner.hide();
      });
  }

  createDataSchema() {

    const schema = new ImportSchema();

    const name = new ImportField('Name', this.translations.NAME);
    name.required = true;

    schema.fields.push(name);

    this.schema = schema;
  }

  deleteButtonVisible = (e: any) => {
    if (e.row.data.systemDefault) {
      return false;
    } else {
      return true;
    }
  }


  translateField = (item: any) => {
    if (item && this.isJson(item.value)) {
      const label = this.getTranslation(item.value);
      return label;
    } else {
      return item.value;
    }
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  isJson(value: any) {
    value = typeof value !== 'string' ? JSON.stringify(value) : value;

    try {
      value = JSON.parse(value);
    } catch (e) {
      return false;
    }
    if (typeof value === 'object' && value !== null) {
      return true;
    }
    return false;
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.reportId;
    this.confirmation.opened = true;
  }

  getPlainTextField = (e: any) => {
    if (e.valueText !== '') {
      return e.valueText.replace(/<[^>]*>/g, '');
    } else {
      return '';
    }
  }

  add() { }

  edit(itemId: number, event: Event) { }
}
