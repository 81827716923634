<div [ngClass]="{'dx-rtl': rtlEnabled}" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
<dx-popup
    #confirmation
    width="60%"
    height="250"
    [showTitle]="true"
    title="{{ title }}"
    [dragEnabled]="false"
    [closeOnOutsideClick]="false"
    [rtlEnabled]="rtlEnabled"
    [visible]="opened"
    (onHiding)="opened = false; cancel.emit(true);">
    <dx-scroll-view width="100%" height="100%">
        <p>{{ message }}</p>
        <br/><br/>
        <button *ngIf="cancellable" type="button" class="btn btn-outline" (click)="opened = false; cancel.emit(true);">{{ buttonCancelText | translate }}</button>
        <button type="button" class="btn btn-primary" (click)="opened = false; close.emit(true);">{{ buttonCloseText | translate }}</button> 
    </dx-scroll-view>
</dx-popup>
</div>
