<div class="auction-transaction-monitor-component item-list-component" [ngClass]="{ 'dx-rtl': rtlEnabled }"
  dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <div class="grid-buttons">
    <dx-button hint="{{ 'PRINT' | translate }}" icon="print" (onClick)="print()"></dx-button>
  </div>

  <div class="card">
    <div class="card-header">
      {{ 'AUCTION.TRANSACTION_MONITOR.FILTER_TRANSACTIONS' | translate }}
    </div>
    <div class="filter-column">
      <label for="catalogs">{{ 'AUCTION.TRANSACTION_MONITOR.CATALOGS' | translate }}</label>
      <dx-select-box name="catalog" [items]="catalogs" valueExpr="catalogId" [(value)]="catalogId" displayExpr="name"
        [rtlEnabled]="rtlEnabled" [searchEnabled]="false" [showClearButton]="false"
        placeholder="{{ 'SELECT' | translate }} ..." (onValueChanged)="onCatalogChange()" cssClass="filter-lot-select">
      </dx-select-box>
    </div>


    <div class="filter-column">
      <label for="catalog">{{ 'AUCTION.TRANSACTION_MONITOR.CLOCKS' | translate }}</label>
      <dx-select-box name="clocks" [items]="clocks" valueExpr="clockId" [(value)]="clockId" displayExpr="name"
        [rtlEnabled]="rtlEnabled" [searchEnabled]="true" [showClearButton]="true"
        placeholder="{{ 'SELECT' | translate }} ..." cssClass="filter-lot-select">
      </dx-select-box>
    </div>

    <div class="filter-column">
      <label>{{ 'AUCTION.TRANSACTION_MONITOR.DATE' | translate }}</label>
      <label for="date" aria-haspopup="true" role="tooltip"
        class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm">
        <long-date-component class="dt-input" [name]="date" [data]="date" [required]="true"
          (modelChanged)="dateChanged($event)">
        </long-date-component>
      </label>
    </div>

    <div>

      <div class="filter-column">
        <div class="filter-button">
          <button type="button" class="btn btn-primary"
            (click)="filterLots()">{{ 'AUCTION.TRANSACTION_MONITOR.FILTER_TRANSACTIONS' | translate }}</button>
          <button [disabled]="!anyRowSelected()" type="button" class="btn btn-primary"
            (click)="printTransactions()">{{ 'AUCTION.TRANSACTION_MONITOR.PRINT_TRANSACTIONS' | translate }}</button>
          <button type="button" class="btn btn-primary"
            (click)="sendNotification($event)">{{ 'AUCTION.TRANSACTION_MONITOR.SEND_NOTIFICATION' | translate }}</button>
          <!-- <span *ngIf="lastTimeSentNotification">{{ 'AUCTION.TRANSACTION_MONITOR.LAST_SENT_NOTIFICATION' | translate }} {{lastTimeSentNotification}}</span> -->
        </div>
      </div>
    </div>
  </div>


  <dx-data-grid #transactionMonitorGrid id="printSection" [rtlEnabled]="rtlEnabled" [showBorders]="true"
                (onInitialized)="initColumn($event)"
                [allowColumnReordering]="true"
                [hoverStateEnabled]="true"
                [columnAutoWidth]="true"
                [rowAlternationEnabled]="true"
                [(selectedRowKeys)]="selectedRows"
                (onSelectionChanged)="selectionChangedHandler($event)"
                (onRowPrepared)="editBackground($event)"
                (onExporting)="onExporting($event)">
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-selection [selectAllMode]="allMode" [showCheckBoxesMode]="checkBoxesMode" mode="multiple"></dxo-selection>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]"
               [showNavigationButtons]="true" [showInfo]="true"
               infoText="{2} {{ 'AUCTION.TRANSACTION_MONITOR.PAGINATION_TEXT_PLACEHOLDER' | translate | slice:2 }}"></dxo-pager>
    <dxi-column cellTemplate="cellTemplate"></dxi-column>
    <div *dxTemplate="let cell of 'cellTemplate'">
      <span [innerHtml]="cell.text | format | safeHtml"></span>
    </div>
    <div *dxTemplate="let cell of 'textCellTemplate'">
      <span [innerHtml]="cell.text | safeHtml"></span>
    </div>
    <dxi-column type="buttons" [width]="40" [visible]="(reportId && reportId !== 0)">
      <dxi-button [visible]="isTransactionAutoCreated" hint="{{ 'AUCTION.TRANSACTION_MONITOR.OPENREPORT' | translate }}" icon="chart" [onClick]="openReport">
      </dxi-button>
    </dxi-column>
  </dx-data-grid>
</div>
