import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PlatformPermission } from '../models/platform-permission';

import { ApplicationSettings } from '../../../shared/models/application-settings';
import { WebApiService } from '../../../shared/services/web-api.service';

@Injectable()
export class PlatformPermissionService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'platformpermission';
  }

  getPlatformPermissions(): Observable<Array<PlatformPermission>> {
    return this.webApiService.getList(this.apiPath);
  }
}
