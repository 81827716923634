import { Component, OnInit, OnDestroy, Injector, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClrTabs } from '@clr/angular';

// general services
import { LanguageService } from '../../shared/services/language.service';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';
import { TranslatableFieldComponent } from '../../shared/components/translatable-field/translatable-field.component';

// services
import { LookupTableService } from '../shared/services/lookup-table.service';
import { ProductPropertyTypeService } from '../shared/services/product-property-type.service';
import { MasterDataService } from '../shared/services/master-data.service';

// models
import { Language } from '../../shared/models/language';
import { LookupTable, LookupTableField } from '../shared/models/lookup-table';
import { ProductPropertyType, UrlTypes } from '../shared/models/product';
import { MasterData } from '../shared/models/master-data';
import { ProductPropertyTypes } from '../shared/models/product-property-types';

import { DxFormComponent, DxPopupComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { Subscription } from 'rxjs';

enum LookupTableTypes {
  LOOKUP_TABLE_TYPE_FILTER = 0,
  LOOKUP_TABLE_TYPE_DEFAULT_VALUE = 1
}

@Component({
  selector: 'lookup-table-component',
  templateUrl: 'lookup-table.component.html',
  styleUrls: ['./lookup-table.component.scss']
})
export class LookupTableComponent extends ItemDetailsComponent<LookupTable> implements OnInit, OnDestroy {

  @ViewChild('tabs') tabs: ClrTabs;
  @ViewChild('lookupTableName') lookupTableName: TranslatableFieldComponent;
  @ViewChild(DxPopupComponent) popup: DxPopupComponent;
  @ViewChild('lookupTableForm', { read: DxFormComponent}) lookupTableForm: DxFormComponent;


  auctionClusterId: number;
  languages: Array<Language> = [];
  lookupTableTypes: any = LookupTableTypes;
  productPropertyTypes: Array<ProductPropertyType> = [];
  productPropertyFormats: Array<UrlTypes> = [];
  masterDataLists: Array<MasterData> = [];
  validFields: boolean = false;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  get isAnyRowSet(): boolean {
    return this.model && this.model.lookupTableRows && this.model.lookupTableRows.length > 0;
  }

  constructor(
    protected injector: Injector,
    private dataService: LookupTableService,
    private languageService: LanguageService,
    private productPropertyTypeService: ProductPropertyTypeService,
    private masterDataService: MasterDataService,
    private route: ActivatedRoute
  ) {
    super(injector);
    this.lookupTableTypes = Object.keys(this.lookupTableTypes).filter(f => !isNaN(Number(f))).map(key => ({ name: this.lookupTableTypes[key], value: Number(key) })); // tslint:disable-line:max-line-length
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.model = new LookupTable();
    this.auctionClusterId = +this.route.snapshot.params['id'];
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  open(lookupTables: Array<LookupTable>, lookupTableId: number, languages: Array<Language>) {
    // Autoselect first tab
    // this.tabs.ifActiveService.current = this.tabs.tabsService.children[0].id;

    this.allItems = lookupTables;
    this.languages = languages;

    this.productPropertyTypeService.getProductProperties().subscribe(types => {
      this.productPropertyTypes = types;
    });

    this.productPropertyTypeService.getFormats(ProductPropertyTypes.URL).subscribe(formats => {
      this.productPropertyFormats = formats;
    });

    this.masterDataService.getMasterDataLists(this.auctionClusterId).subscribe(lists => {
      this.masterDataLists = lists;
    });

    // EDIT
    if (lookupTableId != null) {
      this.isEditMode = true;
      this.spinner.show();
      this.dataService.getLookupTableWithoutRows(this.auctionClusterId, lookupTableId)
        .subscribe((res: LookupTable) => {
          this.model = res;

          this.validFields = this.languageService.checkDefaultLanguageNotEmpty(this.languages, this.model.name);

          if (this.model.resultPropertyTypeId || this.model.resultMasterDataListId) {
            const resultField = new LookupTableField();
            resultField.isResult = true;
            resultField.propertyTypeId = this.model.resultPropertyTypeId || ProductPropertyTypes.MASTER_DATA;
            if (this.model.resultPropertyFormatId) {
              resultField.propertyFormatId = this.model.resultPropertyFormatId;
            }
            if (this.model.resultMasterDataListId) {
              resultField.masterDataListId = this.model.resultMasterDataListId;
            }
            this.model.lookupTableFields.push(resultField);
          }

          this.isOpened = true;
          this.spinner.hide();
        },
        error => {
          notify(error.message, 'error', 5000);
          this.spinner.hide();
        });
    // NEW
    } else {
      this.model = new LookupTable();
      this.model.lookupTableType = 0;
      this.isEditMode = false;
      this.isOpened = true;

      // Create empty JSON object for translation fields
      const emptyTranslation = {};
      this.languages.forEach(lang => {
        emptyTranslation[lang.code] = '';
      });

      this.model.name = JSON.stringify(emptyTranslation);
    }

    // this.lookupTableName.elementFocus();
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  save() {
    this.model.name = this.lookupTableName.data;

    /*if (this.model.lookupTableType === 1)*/ {
      let hasDefaultResult = false;
      for (let i = this.model.lookupTableFields.length - 1; i >= 0; i -= 1) {
        // result field for default value type
        if (this.model.lookupTableFields[i].propertyTypeId != null) {
          hasDefaultResult = true;
          if (this.model.lookupTableFields[i].propertyTypeId === ProductPropertyTypes.MASTER_DATA) {
            this.model.resultMasterDataListId = this.model.lookupTableFields[i].masterDataListId;
            this.model.lookupTableFields.splice(i, 1);
          } else {
            this.model.resultPropertyTypeId = this.model.lookupTableFields[i].propertyTypeId;
            this.model.resultPropertyFormatId = this.model.lookupTableFields[i].propertyFormatId;
            this.model.lookupTableFields.splice(i, 1);
          }
        }
      }

      if (!hasDefaultResult) {
        this.model.resultPropertyTypeId = null;
        this.model.resultPropertyFormatId = null;
        this.model.resultMasterDataListId = null;
      }
    }

    if (this.model.lookupTableId) {
      this.isEditMode = true;
    }

    if (this.isEditMode) {
      this.spinner.show();
      this.dataService.edit(this.model)
        .subscribe((res: any) => {
          this.model = res;
          this.model = new LookupTable();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
        error => {
          this.errorMessage = this.errorService.toString(error);
          notify(this.errorMessage, 'error', 5000);
          this.spinner.hide();
        });
    } else {
      this.spinner.show();
      this.model.auctionClusterId = this.auctionClusterId;

      // remove fields of another type
      // FILTER
      /*if (this.model.lookupTableType === 0) {
        // if filter type, result is not of property type
        this.model.resultPropertyTypeId = null;
        this.model.resultMasterDataListId = null;
      // DEFAULT VALUE
      } else*/ 
      if (this.model.lookupTableType === 1)
      {
        // if default value type, fields cannot be results
        this.model.lookupTableFields.forEach(f => {
          f.isResult = false;
        });
      }

      this.dataService.save(this.model)
        .subscribe((res: any) => {
          this.model = res;
          this.model = new LookupTable();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
        error => {
          this.errorMessage = this.errorService.toString(error);
          notify(this.errorMessage, 'error', 5000);
          this.spinner.hide();
        });
    }
  }

  saveWithoutClose() {
    this.model.name = this.lookupTableName.data;

    //let hasDefaultResult = false;

    let hasNonMasterDataResult = false;
    let hasMasterDataResult = false;
    let hasResultProperty = false;

    //filter
    if (this.model.lookupTableType == 0)
    {
      for (let i = this.model.lookupTableFields.length - 1; i >= 0; i -= 1) {
        if (this.model.lookupTableFields[i].isResult || this.model.lookupTableFields[i].propertyTypeId !== ProductPropertyTypes.MASTER_DATA /*this.model.lookupTableFields[i].propertyTypeId != null*/) {
          if (!this.model.lookupTableFields[i].masterDataListId || 
            (this.model.lookupTableFields[i].propertyTypeId && this.model.lookupTableFields[i].propertyTypeId !== ProductPropertyTypes.MASTER_DATA)) {
            this.model.resultPropertyTypeId = this.model.lookupTableFields[i].propertyTypeId;
            this.model.resultPropertyFormatId = this.model.lookupTableFields[i].propertyFormatId;
            this.model.lookupTableFields.splice(i, 1);
            hasNonMasterDataResult = true;
            hasResultProperty = true;
          }
        }
      }
    }
    
    // result field for default value type
    if (this.model.lookupTableType == 1)
    {
      for (let i = this.model.lookupTableFields.length - 1; i >= 0; i -= 1) {
        
        if (this.model.lookupTableFields[i].isResult || 
          (this.model.lookupTableFields[i].propertyTypeId !== ProductPropertyTypes.MASTER_DATA && !this.model.lookupTableFields[i].masterDataListId) /*this.model.lookupTableFields[i].propertyTypeId != null*/) {
          if (this.model.lookupTableFields[i].masterDataListId || this.model.lookupTableFields[i].propertyTypeId === ProductPropertyTypes.MASTER_DATA) {
            this.model.resultMasterDataListId = this.model.lookupTableFields[i].masterDataListId;
            this.model.resultPropertyTypeId = ProductPropertyTypes.MASTER_DATA;
            this.model.resultPropertyFormatId = null;
            this.model.lookupTableFields.splice(i, 1);
            hasMasterDataResult = true;
            hasResultProperty = true;

          } else {
            this.model.resultPropertyTypeId = this.model.lookupTableFields[i].propertyTypeId;
            this.model.resultPropertyFormatId = this.model.lookupTableFields[i].propertyFormatId;
            this.model.resultMasterDataListId = null;
            this.model.lookupTableFields.splice(i, 1);
            hasResultProperty = true;
          }
        }
      }
    }

    if(!hasResultProperty)
    {
      this.model.resultPropertyTypeId = null;
      this.model.resultPropertyFormatId = null;
      this.model.resultMasterDataListId = null;
    }

    //for filter lookups, save only non-masterdata as result type
    /*if(this.model.lookupTableType == 0)
    {
      if(!hasNonMasterDataResult)
        {
          this.model.resultPropertyTypeId = null;
        }
      this.model.resultMasterDataListId = null;
    }*/

    //for default lookups if the result is masterdata, set the result type, else clear the result masterdata id
    /*if(this.model.lookupTableType == 1)
    {
      if(!hasMasterDataResult)
        {
          this.model.resultMasterDataListId = null;
        }
        else {
          this.model.resultPropertyTypeId = ProductPropertyTypes.MASTER_DATA;
        }
    }*/
    

    if (this.model.lookupTableId) {
      this.isEditMode = true;
    }

    if (this.isEditMode) {
      this.spinner.show();
      this.dataService.edit(this.model)
        .subscribe((res: any) => {
          this.model = res;
          if ((this.model.resultPropertyTypeId /*|| this.model.resultMasterDataListId*/)) {
            const resultField = new LookupTableField();
            resultField.isResult = true;
            resultField.propertyTypeId = this.model.resultPropertyTypeId || ProductPropertyTypes.MASTER_DATA;

            if (this.model.resultPropertyFormatId) {
              resultField.propertyFormatId = this.model.resultPropertyFormatId;
            }
            if (this.model.resultMasterDataListId) {
              resultField.masterDataListId = this.model.resultMasterDataListId;
            }
            this.model.lookupTableFields.push(resultField);
          }
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorMessage = this.errorService.toString(error);
            notify(this.errorMessage, 'error', 5000);
            this.spinner.hide();
          });
    } else {
      this.spinner.show();
      this.model.auctionClusterId = this.auctionClusterId;

      // remove fields of another type
      // FILTER
      if (this.model.lookupTableType === 0) {
        // if filter type, result is not of property type
        this.model.resultPropertyTypeId = null;
        this.model.resultPropertyFormatId = null;
        this.model.resultMasterDataListId = null;
        // DEFAULT VALUE
      } else {
        // if default value type, fields cannot be results
        this.model.lookupTableFields.forEach(f => {
          f.isResult = false;
        });
      }

      this.dataService.save(this.model)
        .subscribe((res: any) => {
          this.model = res;
          if ((this.model.resultPropertyTypeId /*|| this.model.resultMasterDataListId*/)) {
            const resultField = new LookupTableField();
            resultField.isResult = true;
            resultField.propertyTypeId = this.model.resultPropertyTypeId || ProductPropertyTypes.MASTER_DATA;

            if (this.model.resultPropertyFormatId) {
              resultField.propertyFormatId = this.model.resultPropertyFormatId;
            }
            if (this.model.resultMasterDataListId) {
              resultField.masterDataListId = this.model.resultMasterDataListId;
            }
            this.model.lookupTableFields.push(resultField);
          }
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorMessage = this.errorService.toString(error);
            notify(this.errorMessage, 'error', 5000);
            this.spinner.hide();
          });
    }
  }

  translateSelectBoxes = (item) => {
    if (item) {
      let label = item.name;
      this.translate.get(item.name).subscribe((res: string) => {
        label  = res;
    });

      return label;
    }
  }

  public onCancel() {
    this.lookupTableName.resetLanguageButtons();
    super.onCancel();
  }

  onChangeTranslationField = (e: any, fieldName: string) => {
    if (fieldName === 'name') {
      this.validFields = this.languageService.checkDefaultLanguageNotEmpty(this.languages, this.lookupTableName.data);
      this.lookupTableForm.instance.updateData(fieldName, this.lookupTableName.data);
    }
  }

  onFieldDataChanged(e: any) {
    if ((e.component._isReady && e.component.NAME !== 'dxPopup') || e.component.NAME === 'dxForm') {
      const result = e.component.validate();
      if (result.brokenRules.length == 0 || this.validFields) {
        document.getElementsByName('btnLookupTableSubmit')[0].removeAttribute('disabled');
      } else {
        document.getElementsByName('btnLookupTableSubmit')[0].setAttribute('disabled', 'disabled');
      }
    } else {
      if (this.isEditMode && this.validFields) {
        document.getElementsByName('btnLookupTableSubmit')[0].removeAttribute('disabled');
      } else {
        document.getElementsByName('btnLookupTableSubmit')[0].setAttribute('disabled', 'disabled');
      }
    }
  }
}
