import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApplicationSettings } from '../../../shared/models/application-settings';
import { WebApiService } from '../../../shared/services/web-api.service';

import { Product } from '../models/product';
import { Report } from '../../../shared/models/report';
import { SystemProductProperty } from '../models/system-product-property';
import { IProductService } from '../../../shared/interfaces/product';

@Injectable()
export class ProductService implements IProductService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'auctioncluster';
  }

  getProducts(auctionClusterId: number): Observable<Array<Product>> {
    return this.webApiService.getList(this.apiPath + '/' + auctionClusterId + '/product');
  }

  getProduct(auctionClusterId: number, productId: number): Observable<Product> {
    return this.webApiService.get(this.apiPath + '/' + auctionClusterId + '/product', productId);
  }

  save(auctionClusterId: number, product: Product): Observable<any> {
    return this.webApiService.save<Product>(this.apiPath + '/' + auctionClusterId + '/product', product);
  }

  edit(auctionClusterId: number, product: Product): Observable<any> {
    return this.webApiService.edit<Product>(this.apiPath + '/' + auctionClusterId + '/product', product.productId, product);
  }

  delete(auctionClusterId: number, productId: number): Observable<any> {
    return this.webApiService.delete(this.apiPath + '/' + auctionClusterId + '/product', productId);
  }

  getSystemProductProperties(): Observable<Array<SystemProductProperty>> {
    return this.webApiService.getList(this.appSettings.adminApi + 'systemproductproperty');
  }

  getReportTypes(): Observable<Array<Report>> {
    return this.webApiService.getList(this.appSettings.adminApi + 'report');
  }

  getNewProductModel(auctionClusterId: number): Observable<Product> {
    return this.webApiService.getSingle(this.apiPath + '/' + auctionClusterId + '/product/new');
  }

  getProductForReports(auctionClusterId: number, productId: number)
  {
    return this.webApiService.get(this.apiPath + '/' + auctionClusterId + '/product', productId);
  }
}
