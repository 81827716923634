<div class="ticket-printer-component item-list-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup [width]="600"
            [showTitle]="true"
            title="{{ modalTitle }}"
            [dragEnabled]="false"
            [closeOnOutsideClick]="false"
            [rtlEnabled]="rtlEnabled"
            [(visible)]="isOpened"
            (onShown)="onFieldDataChanged($event)">
    <dx-scroll-view width="100%" height="100%">
      <dx-form #ticketPrinterForm
               id="ticketPrinterForm"
               [showValidationSummary]="false"
               (onFieldDataChanged)="onFieldDataChanged($event)"
               colCount="auto"
               [(formData)]="model"
               [minColWidth]="500">
        <dxi-item itemType="tabbed">
          <dxi-tab title="{{ 'TICKET_PRINTER.GENERAL' | translate }}">
            <dxi-item dataField="name">
              <dxo-label text="{{ 'TICKET_PRINTER.NAME' | translate }}"></dxo-label>
              <dxi-validation-rule type="required" message="{{ 'TICKET_PRINTER.NAME' | translate }}"></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="routingSubKey">
              <dxo-label text="{{ 'TICKET_PRINTER.ROUTINGSUBKEY' | translate }}"></dxo-label>
              <dxi-validation-rule type="required" message="{{ 'TICKET_PRINTER.ROUTINGSUBKEY_VALIDATION' | translate }}"></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="ticketType"
                      editorType="dxSelectBox"
                      [editorOptions]="{ items: ticketPrinterTypes, valueExpr:'value', displayExpr:translateField, searchEnabled: 'true', showClearButton: 'true'}">
              <dxo-label text="{{ 'TICKET_PRINTER.TYPE' | translate }}"></dxo-label>
              <dxi-validation-rule type="required" message="{{ 'TICKET_PRINTER.TYPE_VALIDATION' | translate }}"></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="enabled" editorType="dxCheckBox">
              <dxo-label text="{{ 'TICKET_PRINTER.ENABLED' | translate }}"></dxo-label>
            </dxi-item>
            <dxi-item dataField="useAsSystemFieldPrinter" editorType="dxCheckBox">
              <dxo-label text="{{ 'TICKET_PRINTER.USE_AS_SYSTEMFIELD_PRINTER' | translate }}"></dxo-label>
            </dxi-item>
            <dxi-item dataField="useAllLocations" editorType="dxCheckBox">
              <dxo-label text="{{ 'TICKET_PRINTER.USE_ALL_LOCATIONS' | translate }}"></dxo-label>
            </dxi-item>
            <dxi-item dataField="locations" [(visible)]="!model.useAllLocations" [template]="'locationsTemplate'">
              <dxo-label text="{{ 'TICKET_PRINTER.LOCATIONS' | translate }}"></dxo-label>
            </dxi-item>
            <dxi-item dataField="clocks" [template]="'clocksTemplate'">
              <dxo-label text="{{ 'TICKET_PRINTER.CLOCKS' | translate }}"></dxo-label>
            </dxi-item>
            <dxi-item dataField="catalogs" [template]="'catalogsTemplate'">
              <dxo-label text="{{ 'TICKET_PRINTER.CATALOGS' | translate }}"></dxo-label>
            </dxi-item>

            <dxi-item dataField="status" [template]="'statusTemplate'">
              <dxo-label text="{{ 'TICKET_PRINTER.STATUS' | translate }}"></dxo-label>
            </dxi-item>
          </dxi-tab>
        </dxi-item>

        <div *dxTemplate="let data of 'locationsTemplate'">
          <dx-tag-box [dataSource]="locations"
                      valueExpr="locationId"
                      displayExpr="name"
                      searchEnabled="true"
                      [(value)]="model.locations">
          </dx-tag-box>
        </div>
        <div *dxTemplate="let data of 'clocksTemplate'">
          <dx-tag-box [dataSource]="clocks"
                      valueExpr="clockId"
                      displayExpr="name"
                      searchEnabled="true"
                      [(value)]="model.clocks">
          </dx-tag-box>
        </div>
        <div *dxTemplate="let data of 'catalogsTemplate'">
          <dx-tag-box [dataSource]="catalogs"
                      valueExpr="catalogId"
                      displayExpr="name"
                      searchEnabled="true"
                      [(value)]="model.catalogs">
          </dx-tag-box>
        </div>
        <div *dxTemplate="let data of 'statusTemplate'">
          <dx-button stylingMode="text" icon="isnotblank" *ngIf="isDisconnected(model.status)" id="isDisconnectedIcon"></dx-button>
          <dx-button stylingMode="text" icon="isnotblank" *ngIf="isConnected(model.status)" id="isConnectedIcon"></dx-button>
          <dx-button stylingMode="text" icon="isnotblank" *ngIf="isError(model.status)" id="isErrorIcon"></dx-button>
          {{ translateStatus(model.status) }}
        </div>

      </dx-form>
      <br />
      <button type="button" class="btn btn-primary" (click)="onTestTicket();" name="btnTestTicket" disabled="disabled">{{ 'TEST' | translate }}</button>
      <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnTicketPrinterSubmit" disabled="disabled">
        {{ 'SAVE' | translate }}
      </button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'CANCEL' | translate }}</button>
    </dx-scroll-view>
  </dx-popup>
</div>
