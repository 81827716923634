import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { WebApiService } from '../../../shared/services/web-api.service';
import { GenericLot, GenericLots, BuyerActionOnLotGroup } from '../../../shared/models/generic-lot';
import { Catalog } from '../../../auction/shared/models/catalog';
import { BuyerClock } from '../models/buyer-level-clocks';
import { BuyerLevelSubbuyer } from '../models/buyer-level-subbuyer';

@Injectable()
export class SupplyMonitorService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'buyerlevel';
  }

  getLots(buyerId: number, auctionClusterId: number, catalogId: number, date: string, clockId: number = 0, genericFilters: string = null): Observable<GenericLot> {
    let url = `${this.apiPath}/${buyerId}/auctioncluster/${auctionClusterId}/catalog/${catalogId}/date/${date}/lot`;
    if (clockId && clockId !== 0) {
      url += `?clockId=${clockId}`;
      if (genericFilters != null) {
        url += `&genericFilters=${genericFilters}`;
      }      
    }
    else if (genericFilters != null) {
      url += `?genericFilters=${genericFilters}`;
    }

    return this.webApiService.getList(url);
  }

  getUngroupedLots(buyerId: number, auctionClusterId: number, catalogId: number, date: string, lotIds: Array<number>): Observable<GenericLots> {
    let url = `${this.apiPath}/${buyerId}/auctioncluster/${auctionClusterId}/catalog/${catalogId}/date/${date}/ungroupedlots`;
    return this.webApiService.save(url, { 'ids': lotIds });
  }

  getCatalogs(buyerId: number, auctionClusterId: number): Observable<Array<Catalog>> {
    return this.webApiService.getList(`${this.apiPath}/${buyerId}/auctioncluster/${auctionClusterId}/catalog`);
  }

  getClocks(buyerId: number, auctionClusterId: number): Observable<Array<BuyerClock>> {
    return this.webApiService.getList(`${this.apiPath}/${buyerId}/auctioncluster/${auctionClusterId}/clock`);
  }

  getClocksByCatalog(buyerId: number, auctionClusterId: number, catalogId: number): Observable<Array<BuyerClock>> {
    return this.webApiService.getList(`${this.apiPath}/${buyerId}/auctioncluster/${auctionClusterId}/catalog/${catalogId}/clock`);
  }

  getSubbuyers(buyerId: number, auctionClusterId: number): Observable<Array<BuyerLevelSubbuyer>> {
    return this.webApiService.getList(`${this.apiPath}/${buyerId}/auctioncluster/${auctionClusterId}/subbuyerbuyer`);
  }

  savePrebid(prebid: BuyerActionOnLotGroup, auctionClusterId: number): Observable<any> {
    return this.webApiService.save(`${this.apiPath}/${prebid.buyerId}/auctioncluster/${auctionClusterId}/lot/prebid`, prebid);
  }

  editPrebid(prebid: BuyerActionOnLotGroup, auctionClusterId: number): Observable<any> {
    return this.webApiService.editSingle(`${this.apiPath}/${prebid.buyerId}/auctioncluster/${auctionClusterId}/lot/prebid`, prebid);
  }

  deletePrebid(prebid: BuyerActionOnLotGroup, auctionClusterId: number): Observable<any> {
    return this.webApiService.deleteSingle(`${this.apiPath}/${prebid.buyerId}/auctioncluster/${auctionClusterId}/lot/prebid`, prebid);
  }

  saveMark(prebid: BuyerActionOnLotGroup, auctionClusterId: number): Observable<any> {
    return this.webApiService.save(`${this.apiPath}/${prebid.buyerId}/auctioncluster/${auctionClusterId}/lot/mark`, prebid);
  }

  deleteMark(prebid: BuyerActionOnLotGroup, auctionClusterId: number): Observable<any> {
    return this.webApiService.deleteSingle(`${this.apiPath}/${prebid.buyerId}/auctioncluster/${auctionClusterId}/lot/mark`, prebid);
  }
}
