<div class="edit-profile-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup
  #addUser
  [width]="600"
  [showTitle]="true"
  title="{{ 'HEADER.CHANGE_PROFILE' | translate}}"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [showCloseButton]="false"
  [rtlEnabled]="rtlEnabled"
  [(visible)]="isOpened">
  <dx-scroll-view width="100%" height="100%">
        <dx-form
            #editProfileForm
            id="editProfileForm"
            [showValidationSummary]="false"
            (onFieldDataChanged)="onFieldDataChanged($event)"
            colCount="auto"
            [(formData)]="model"
            [minColWidth]="500">
            <dxi-item itemType="tabbed">
                <dxi-tab title="{{ 'USER.GENERAL' | translate }}">
                  <dxi-item itemType="group">
                    <dxi-item dataField="email" [editorOptions]="{ disabled: true }">
                      <dxo-label text="{{ 'USER.EMAIL' | translate }}"></dxo-label>
                      <dxi-validation-rule type="required" message="{{ 'USER.EMAIL_VALIDATION' | translate }}"></dxi-validation-rule>
                      <dxi-validation-rule type="email" message="{{ 'USER.EMAIL_VALIDATION' | translate }}"></dxi-validation-rule>
                    </dxi-item>
                    <dxi-item dataField="firstname">
                      <dxo-label text="{{ 'USER.FIRST_NAME' | translate }}"></dxo-label>
                      <dxi-validation-rule type="required" message="{{ 'USER.FIRST_NAME_VALIDATION' | translate }}"></dxi-validation-rule>
                    </dxi-item>
                    <dxi-item dataField="lastname">
                      <dxo-label text="{{ 'USER.LAST_NAME' | translate }}"></dxo-label>
                      <dxi-validation-rule type="required" message="{{ 'USER.LAST_NAME_VALIDATION' | translate }}"></dxi-validation-rule>
                    </dxi-item>
                    <dxi-item dataField="telNumber">
                      <dxo-label text="{{ 'USER.TEL_NUMBER' | translate }}"></dxo-label>
                    </dxi-item>
                    <dxi-item dataField="languageId"
                              editorType="dxSelectBox"
                              [editorOptions]="{ items: languages, valueExpr:'languageId' ,
                        displayExpr:'name'}">
                      <dxo-label text="{{ 'USER.PREFERRED_LANGUAGE' | translate }}"></dxo-label>
                    </dxi-item>
                  </dxi-item>
                  
                </dxi-tab>
                <dxi-tab title="{{ 'USER.PLATFORM' | translate }}" [disabled]="!isPlatformUser">
                  <dxi-item 
                        dataField="platformRoleId"
                        editorType="dxSelectBox"
                        [editorOptions]="{
                          disabled: true,
                          placeholder: getTranslation(model.platformRole) 
                        }">
                        <dxi-validation-rule type="required" message="{{ 'USER.PLATFORM_ROLE' | translate }}"></dxi-validation-rule>
                    <dxo-label text="{{ 'USER.PLATFORM_ROLE' | translate }}"></dxo-label>
                  </dxi-item>
                </dxi-tab>
                <dxi-tab title="{{ 'USER.AUCTION_CLUSTER' | translate }}" [disabled]="!isAuctionClusterUser">
                  <div *dxTemplate>
                  <dx-data-grid
                    [rtlEnabled]="rtlEnabled"
                    [(dataSource)]="model.auctionClusterRoles"
                    [showBorders]="true"
                    [allowColumnReordering]="true" [hoverStateEnabled]="true" [columnAutoWidth]="true"
                    (onInitialized)="initAuctionCluster($event)">
                      <dxi-column
                          dataField="auctionCluster.name"
                          caption="{{ 'USER.AUCTION_CLUSTER' | translate }}">
                      </dxi-column>

                      <dxi-column
                        dataField="auction"
                        caption="{{ 'USER.AUCTION' | translate }}"
                        cellTemplate="auctionCell">
                      </dxi-column>

                      <dxi-column
                          dataField="auctionClusterRole"
                          caption="{{ 'USER.ROLE' | translate }}"
                          cellTemplate="roleCell">
                      </dxi-column>
                      
                      <div *dxTemplate="let data of 'auctionCell'">
                        <span *ngIf="data.value">{{ getTranslation(data.value) }}</span>
                        <span *ngIf="!data.value">{{ 'USER.ALL_AUCTIONS' | translate }}</span>
                      </div>

                      <div *dxTemplate="let data of 'roleCell'">
                        <span *ngIf="data.value">{{ getTranslation(data.value) }}</span>
                      </div>

                    </dx-data-grid>
                    </div>
                </dxi-tab>
                <dxi-tab title="{{ 'USER.BUYER' | translate }}" [disabled]="!isBuyerUser">
                  <dxi-item itemType="group" name="buyerRoles" *ngFor="let item of model.buyerRoles; let i=index">
                    
                    <dxi-item [dataField]="item.buyerRole.name" [editorOptions]="{placeholder: getTranslation(item.buyerRole), disabled: true}"><!-- getTranslation()-->
                      <dxo-label text="{{ 'USER.ROLE' | translate }}"></dxo-label>
                      <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxi-item>

                    <dxi-item dataField="'model.buyerRoles[' + i + '].userBuyer.buyer.vat'" [editorOptions]="{ placeholder: item.userBuyer.buyer.vat, disabled: true}">
                      <dxo-label text="{{ 'BUYERS.VAT' | translate }}"></dxo-label>
                      <dxi-validation-rule type="required" message="{{ 'BUYERS.VAT_VALIDATION' | translate }}"></dxi-validation-rule>
                    </dxi-item>

                    <dxi-item [dataField]="'model.buyerRoles[' + i + '].userBuyer.buyer.name'" [editorOptions]="{ placeholder: item.userBuyer.buyer.name, disabled: true}">
                      <dxo-label text="{{ 'BUYERS.NAME' | translate }}"></dxo-label>
                      <dxi-validation-rule type="required" message="{{ 'BUYERS.NAME_VALIDATION' | translate }}"></dxi-validation-rule>
                    </dxi-item>

                    <dxi-item [dataField]="'model.buyerRoles[' + i + '].userBuyer.buyer.address1'" [editorOptions]="{ placeholder: item.userBuyer.buyer.address1, disabled: true}">
                      <dxo-label text="{{ 'BUYERS.ADDRESS' | translate }}"></dxo-label>
                      <dxi-validation-rule type="required" message="{{ 'BUYERS.ADDRESS_VALIDATION' | translate }}"></dxi-validation-rule>
                    </dxi-item>

                    <dxi-item [dataField]="'model.buyerRoles[' + i + '].userBuyer.buyer.address2'" [editorOptions]="{ placeholder: item.userBuyer.buyer.address2, disabled: true}">
                      <dxo-label text="{{ 'BUYERS.ADDRESS2' | translate }}"></dxo-label>
                    </dxi-item>

                    <dxi-item [dataField]="'model.buyerRoles[' + i + '].userBuyer.buyer.zip'" [editorOptions]="{ placeholder: item.userBuyer.buyer.zip, disabled: true}">
                      <dxo-label text="{{ 'BUYERS.ZIP' | translate }}"></dxo-label>
                      <dxi-validation-rule type="required" message="{{ 'BUYERS.ZIP_VALIDATION' | translate }}"></dxi-validation-rule>
                    </dxi-item>

                    <dxi-item [dataField]="'model.buyerRoles[' + i + '].userBuyer.buyer.city'" [editorOptions]="{ placeholder: item.userBuyer.buyer.city, disabled: true}">
                      <dxo-label text="{{ 'BUYERS.CITY' | translate }}"></dxo-label>
                      <dxi-validation-rule type="required" message="{{ 'BUYERS.CITY_VALIDATION' | translate }}"></dxi-validation-rule>
                    </dxi-item>

                    <dxi-item [dataField]="'model.buyerRoles[' + i + '].userBuyer.buyer.country'" [editorOptions]="{ placeholder: item.userBuyer.buyer.country, disabled: true}">
                      <dxo-label text="{{ 'BUYERS.COUNTRY' | translate }}"></dxo-label>
                      <dxi-validation-rule type="required" message="{{ 'BUYERS.COUNTRY_VALIDATION' | translate }}"></dxi-validation-rule>
                    </dxi-item>

                    <dxi-item [dataField]="'model.buyerRoles[' + i + '].userBuyer.buyer.telNumber'" [editorOptions]="{ placeholder: item.userBuyer.buyer.telNumber, disabled: true}">
                      <dxo-label text="{{ 'BUYERS.TEL_NUMBER' | translate }}"></dxo-label>
                      <dxi-validation-rule type="required" message="{{ 'BUYERS.TEL_NUMBER_VALIDATION' | translate }}"></dxi-validation-rule>
                    </dxi-item>

                    <dxi-item [dataField]="'model.buyerRoles[' + i + '].userBuyer.buyer.faxNumber'" [editorOptions]="{ placeholder: item.userBuyer.buyer.faxNumber, disabled: true}">
                      <dxo-label text="{{ 'BUYERS.FAX_NUMBER' | translate }}"></dxo-label>
                    </dxi-item>

                    <dxi-item [dataField]="'model.buyerRoles[' + i + '].userBuyer.buyer.email'" [editorOptions]="{ placeholder: item.userBuyer.buyer.email, disabled: true}">
                      <dxo-label text="{{ 'BUYERS.EMAIL' | translate }}"></dxo-label>
                      <dxi-validation-rule type="required" message="{{ 'BUYERS.EMAIL_VALIDATION' | translate }}"></dxi-validation-rule>
                    </dxi-item>

                    <dxi-item [dataField]="'model.buyerRoles[' + i + '].userBuyer.buyer.url'" [editorOptions]="{ placeholder: item.userBuyer.buyer.url, disabled: true}">
                      <dxo-label text="{{ 'BUYERS.URL' | translate }}"></dxo-label>
                    </dxi-item>

                  </dxi-item>
                  
                </dxi-tab>
                <dxi-tab title="{{ 'USER.SUPPLIER' | translate }}" [disabled]="!isSupplierUser">
                  
                  <dxi-item itemType="group" name="supplierRoles" *ngFor="let item of model.supplierRoles; let i=index">
                    
                    <dxi-item [dataField]="'model.supplierRoles[' + i + '].supplierRole.name'" [editorOptions]="{placeholder: getTranslation(item.supplierRole), disabled: true}">
                      <dxo-label text="{{ 'USER.ROLE' | translate }}"></dxo-label>
                      <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxi-item>

                    <dxi-item [dataField]="'model.supplierRoles[' + i + '].userSupplier.supplier.vat'" [editorOptions]="{ placeholder: item.userSupplier.supplier.vat, disabled: true}">
                      <dxo-label text="{{ 'SUPPLIERS.VAT' | translate }}"></dxo-label>
                      <dxi-validation-rule type="required" message="{{ 'SUPPLIERS.VAT_VALIDATION' | translate }}"></dxi-validation-rule>
                    </dxi-item>

                    <dxi-item [dataField]="'model.supplierRoles[' + i + '].userSupplier.supplier.name'" [editorOptions]="{ placeholder: item.userSupplier.supplier.name, disabled: true}">
                      <dxo-label text="{{ 'SUPPLIERS.NAME' | translate }}"></dxo-label>
                      <dxi-validation-rule type="required" message="{{ 'SUPPLIERS.NAME_VALIDATION' | translate }}"></dxi-validation-rule>
                    </dxi-item>

                    <dxi-item [dataField]="'model.supplierRoles[' + i + '].userSupplier.supplier.address1'" [editorOptions]="{ placeholder: item.userSupplier.supplier.address1, disabled: true}">
                      <dxo-label text="{{ 'SUPPLIERS.ADDRESS' | translate }}"></dxo-label>
                      <dxi-validation-rule type="required" message="{{ 'SUPPLIERS.ADDRESS_VALIDATION' | translate }}"></dxi-validation-rule>
                    </dxi-item>

                    <dxi-item [dataField]="'model.supplierRoles[' + i + '].userSupplier.supplier.address2'" [editorOptions]="{ placeholder: item.userSupplier.supplier.address2, disabled: true}">
                      <dxo-label text="{{ 'SUPPLIERS.ADDRESS2' | translate }}"></dxo-label>
                    </dxi-item>

                    <dxi-item [dataField]="'model.supplierRoles[' + i + '].userSupplier.supplier.zip'" [editorOptions]="{ placeholder: item.userSupplier.supplier.zip, disabled: true}">
                      <dxo-label text="{{ 'SUPPLIERS.ZIP' | translate }}"></dxo-label>
                      <dxi-validation-rule type="required" message="{{ 'SUPPLIERS.ZIP_VALIDATION' | translate }}"></dxi-validation-rule>
                    </dxi-item>

                    <dxi-item [dataField]="'model.supplierRoles[' + i + '].userSupplier.supplier.city'" [editorOptions]="{ placeholder: item.userSupplier.supplier.city, disabled: true}">
                      <dxo-label text="{{ 'SUPPLIERS.CITY' | translate }}"></dxo-label>
                      <dxi-validation-rule type="required" message="{{ 'SUPPLIERS.CITY_VALIDATION' | translate }}"></dxi-validation-rule>
                    </dxi-item>

                    <dxi-item [dataField]="'model.supplierRoles[' + i + '].userSupplier.supplier.country'" [editorOptions]="{ placeholder: item.userSupplier.supplier.country, disabled: true}">
                      <dxo-label text="{{ 'SUPPLIERS.COUNTRY' | translate }}"></dxo-label>
                      <dxi-validation-rule type="required" message="{{ 'SUPPLIERS.COUNTRY_VALIDATION' | translate }}"></dxi-validation-rule>
                    </dxi-item>

                    <dxi-item [dataField]="'model.supplierRoles[' + i + '].userSupplier.supplier.telNumber'" [editorOptions]="{ placeholder: item.userSupplier.supplier.telNumber, disabled: true}">
                      <dxo-label text="{{ 'SUPPLIERS.TEL_NUMBER' | translate }}"></dxo-label>
                      <dxi-validation-rule type="required" message="{{ 'SUPPLIERS.TEL_NUMBER_VALIDATION' | translate }}"></dxi-validation-rule>
                    </dxi-item>

                    <dxi-item [dataField]="'model.supplierRoles[' + i + '].userSupplier.supplier.faxNumber'" [editorOptions]="{ placeholder: item.userSupplier.supplier.faxNumber, disabled: true}">
                      <dxo-label text="{{ 'SUPPLIERS.FAX_NUMBER' | translate }}"></dxo-label>
                    </dxi-item>

                    <dxi-item [dataField]="'model.supplierRoles[' + i + '].userSupplier.supplier.email'" [editorOptions]="{ placeholder: item.userSupplier.supplier.email, disabled: true}">
                      <dxo-label text="{{ 'SUPPLIERS.EMAIL' | translate }}"></dxo-label>
                      <dxi-validation-rule type="required" message="{{ 'SUPPLIERS.EMAIL_VALIDATION' | translate }}"></dxi-validation-rule>
                    </dxi-item>

                    <dxi-item [dataField]="'model.supplierRoles[' + i + '].userSupplier.supplier.url'" [editorOptions]="{ placeholder: item.userSupplier.supplier.url, disabled: true}">
                      <dxo-label text="{{ 'SUPPLIERS.URL' | translate }}"></dxo-label>
                    </dxi-item>

                  </dxi-item>

                </dxi-tab>
            </dxi-item>
            
        </dx-form>
         
        <button type="submit" class="btn btn-primary" (click)="onDelete();">{{ 'DELETE' | translate }}</button>
        <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnEditProfileSubmit">
          {{ 'SAVE' | translate }}
        </button>
        <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'CANCEL' | translate }}</button>
      </dx-scroll-view>
</dx-popup>
</div>
<confirmation-component #confirmation title="{{ 'USER.ACCOUNT_DELETE_HEADER' | translate }}" message="{{ 'USER.ACCOUNT_DELETE' | translate }}" buttonCloseText="DELETE" cancellable="true" (close)="deleteSelected();"></confirmation-component>
<save-confirmation-component #saveConfirmation title="{{ 'LEAVE_PAGE' | translate }}" buttonCloseText="OK" cancellable="true" (close)="onCancel();"></save-confirmation-component>
