import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Catalog } from '../../../auction/shared/models/catalog';

import { ApplicationSettings } from '../../../shared/models/application-settings';
import { WebApiService } from '../../../shared/services/web-api.service';
import { ICatalogService } from '../../../shared/interfaces/catalog';
@Injectable()
export class CatalogService implements ICatalogService {

  private apiPath: string;
  private buyerId: number;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi;
  }

  setId(id: number){
    this.buyerId = id;
  }

  getAllSupplyCatalogs(auctionClusterId: number, buyerId: number): Observable<Array<Catalog>> {
    return this.webApiService.getList(this.apiPath + `buyerlevel/${ buyerId }` + '/auctioncluster/' + auctionClusterId + '/catalog');
  }

  getAuctionCatalogs(buyerId: number, auctionId: number = null, auctionClusterId: number = null): Observable<Array<Catalog>> {
    return this.webApiService.getList(this.apiPath + `buyerlevel/${buyerId}` + `/auctioncluster/auction/catalog?auctionId=${auctionId}&auctionClusterId=${auctionClusterId}`);
  }
  
  getAllSupplyAndForecastCatalogs(auctionClusterId: number, buyerId: number): Observable<Array<Catalog>> {
    return this.webApiService.getList(this.apiPath + `buyerlevel/${buyerId}` + '/auctioncluster/' + auctionClusterId + '/catalog/all');
  }

  getAllSupplyAndForecastCatalogsForReports(auctionClusterId: number): Observable<Array<Catalog>> {
    return this.webApiService.getList(this.apiPath + `buyerlevel/${this.buyerId}` + '/auctioncluster/' + auctionClusterId + '/catalog/all');
  }
  
  
  
}
