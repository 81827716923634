import { Component, OnInit, Injector, OnDestroy, ViewChild } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';

// general services
import { LanguageService } from '../../shared/services/language.service';
import { AuctionClusterNotificationService } from '../shared/services/auction-cluster-notification.service';
import { AuctionClusterReportService } from '../shared/services/report.service';

// components
import notify from 'devextreme/ui/notify';
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { NotificationComponent } from './notification.component';

// models

import { AuctionClusterNotification } from '../../shared/models/auction-cluster-notification';
import { Language } from '../../shared/models/language';
import { AuctionClusterChannel } from '../../shared/models/auction-cluster-channel';
import { Report } from '../../shared/models/report';

@Component({
  selector: 'notifications-component',
  templateUrl: 'notifications.component.html',
  styleUrls: ['notifications.component.scss']
})

export class NotificationsComponent extends FullListComponent<AuctionClusterNotification, NotificationComponent> implements OnInit, OnDestroy {
  
  @ViewChild('details') detailsComponent: NotificationComponent;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  languages: Array<Language> = [];
  channels: Array<AuctionClusterChannel> = [];
  reports: Array<Report> = [];
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private notificationService: AuctionClusterNotificationService,
    private languageService: LanguageService,
    private reportService: AuctionClusterReportService
  ) {
      super(injector, AuctionClusterNotification);
      this.title.set('NOTIFICATION.PAGE_TITLE');
      this._subscription = this.languageService.direction.subscribe(dir => {
        this.rtlEnabled = dir;
      });
    }

  ngOnInit() {
    this.setTranslations('NOTIFICATION');
    this.getNotificationsData();
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  getData() {
  }

  getNotificationsData() {
    this.spinner.show();
    forkJoin(
      this.notificationService.getEnabledAuctionClusterNotificationChannels(this.id),
      this.languageService.getAuctionClusterLanguages(this.id),
      this.notificationService.getAuctionClusterChannels(this.id),
      this.reportService.getReports(this.id)
    ).subscribe(result => {
      this.items = result[0];
      this.languages = result[1];
      this.channels = result[2];
      this.reports = result[3];

      this.translateNotifications();
      this.spinner.hide();
    },
      error => {
      this.errorService.show(error);
        this.spinner.hide();
      });
  }

  translateNotifications() {
    this.items.forEach(notification => {
      notification.nameText = this.languageService.getTranslatableText(notification.name);
      notification.titleText = this.languageService.getTranslatableText(notification.title);
      notification.descriptionText = this.languageService.getTranslatableText(notification.description);
      notification.reportName = this.languageService.getTranslatableText(this.reports.find(_ => _.reportId == notification.reportId)?.reportDesign.name);
      notification.activeChannelsString = this.getActiveChannelsString(notification.activeAuctionClusterNotificationChannelIds);
    });
  }

  add() {

  }

  edit = (e: any) => {
    const auctionClusterNotification = e.row !== undefined ? e.row.data : e.data;

    if(e.column.dataType === "boolean") {
      this.activateDeactivateAuctionClusterNotification(auctionClusterNotification);
    } else {      
      this.detailsComponent.modalTitle = this.translations.EDIT;
      this.detailsComponent.open(this.items, auctionClusterNotification.auctionClusterNotificationId, this.languages, this.channels, this.reports);
    }   
  }

  onDetailsComponentClosed(isDataChanged: boolean) {
    if (isDataChanged) {
      this.getNotificationsData();
    }
  }

  getActiveChannelsString(activeChannels: Array<number>) {
    let activeTranslatedChannels = [];

    activeChannels?.forEach(channelId => {
        activeTranslatedChannels.push(this.languageService.getTranslatableText(this.channels.find(channel => channel.auctionClusterChannelId == channelId).name));
    });

    return activeTranslatedChannels.join(', ');
  }

  activateDeactivateAuctionClusterNotification = (auctionClusterNotification: AuctionClusterNotification) => {
    this.notificationService.activateDeactivateAuctionClusterNotification(this.id, auctionClusterNotification.auctionClusterNotificationId, !auctionClusterNotification.isActive).subscribe(() => {
      this.getNotificationsData();
    }, error => {
      notify(error.message, 'error', 5000);
    });
  }

  resetChannelsForAllUsersToDefault = (e: any) => {
    const AuctionClusterNotification: AuctionClusterNotification = e.row.data;
    this.notificationService.resetChannelsForAllUsersToDefault(this.id, AuctionClusterNotification).subscribe(() => {
      notify(this.translations.SUCCESSFUL_RESET, 'success', 5000);
    }, error => {
      notify(error.message, 'error', 5000);
    });
  }
}
