<div dir="{{rtlEnabled ? 'rtl' : 'ltr'}}" class="main-container content-component" [class.efice]="baseUrl && baseUrl.includes('efice.com')" [class.test]="baseUrl && baseUrl.includes('test')">
  <div class="content-container">
    <div class="content-area">
      <header-component></header-component>
      <div class="contents">
        <alert-component></alert-component>
        <router-outlet></router-outlet>
      </div>
    </div>
    <nav class="sidenav" [ngClass]="{'collapsed': !expanded && !pin}">
      <section class="sidenav-content" *ngIf="expanded || pin">
        <div class="collapsed-btn" (click)="navCollapse()">
          <clr-icon shape="arrow" dir="left" size="40" style="padding-left: 16px;padding-bottom: 16px"></clr-icon>
        </div>

        <a [routerLink]="['/core/home']" class="logo-wrapper" (click)="onRouterLinkClick()">
          <img *ngIf="!sidebarLogo" src="assets/images/Aucxis.png" class="aucxis-logo" />
          <img *ngIf="!sidebarLogo" src="assets/images/efice.png" class="efice-logo" style="display: none;" />
          <img *ngIf="sidebarLogo" [src]="sidebarLogo" />
        </a>

        <a routerLinkActive="active" [routerLink]="['/supplier/users/' + id]"  *ngIf="tokenService.permissionMet('SupplierPermissions.3', id)"  class="nav-link" (click)="onRouterLinkClick()">
          {{ 'SUPPLIER.USERS' | translate }}
        </a>

        <!--<collapsable-section sectionId="public" [caption]="'CONTENT.PUBLIC_MENU_ITEM' | translate" (clicked)="onRouterLinkClick('public')">
          <ul class="nav-list">
            <li>
              <a [routerLink]="['/supplier/supplyforecast/' + id]" routerLinkActive="active" class="nav-link" (click)="onRouterLinkClick('public')">
                {{ 'CONTENT.SUPPLY_FORECAST' | translate }}
              </a>
            </li>
          </ul>
        </collapsable-section>-->

        <a routerLinkActive="active" [routerLink]="['/supplier/clocks/' + id]" *ngIf="tokenService.permissionMet('SupplierPermissions.4', id)" class="nav-link" (click)="onRouterLinkClick('supplier-clocks')">
          {{ 'SUPPLIER.SALES' | translate }}
        </a>

        <a routerLinkActive="active" [routerLink]="['/supplier/supplyforecast/' + id]" class="nav-link public-supply-forecast-urk" (click)="onRouterLinkClick()">
          {{ 'CONTENT.SUPPLY_FORECAST' | translate }}
        </a>

        <a routerLinkActive="active" [routerLink]="['/supplier/reports/' + id]" *ngIf="tokenService.permissionMet('SupplierPermissions.2', id)" class="nav-link" (click)="onRouterLinkClick()">
          {{ 'REPORTING.TITLE' | translate }}
        </a>

        <ng-container *ngFor="let item of menuItems" >
          <collapsable-section *ngIf="item.menuType === 3" [caption]="getTranslation(item.menuItemName)" sectionId="item.menuItemName" (clicked)="onRouterLinkClick('item.menuItemName')">
            <ul class="nav-list">
              <ng-container *ngFor="let subItem of subMenuItems" >
                <ng-container *ngIf="item.externalAppId === subItem.submenuId">
                  <a *ngIf="!subItem.openInNewTab"
                  [routerLink]="['/supplier/' + id + '/menu-item/' + subItem.externalAppId]"
                  routerLinkActive="active"
                  class="nav-link">{{ getTranslation(subItem.menuItemName) }}</a>

                  <a *ngIf="subItem.openInNewTab"
                  [href]="subItem.applicationUrl"
                  [target]="'_blank'"
                  routerLinkActive="active"
                  class="nav-link">{{ getTranslation(subItem.menuItemName) }}</a>
                </ng-container>
              </ng-container>
            </ul>
          </collapsable-section>

          <ng-container *ngIf="item.menuType === 1">
            <a *ngIf="!item.openInNewTab"
            [routerLink]="['/supplier/' + id + '/menu-item/' + item.externalAppId]"
            routerLinkActive="active"
            class="nav-link">{{ getTranslation(item.menuItemName) }}</a>

            <a *ngIf="item.openInNewTab"
            [href]="item.applicationUrl"
            [target]="'_blank'"
            routerLinkActive="active"
            class="nav-link">{{ getTranslation(item.menuItemName) }}</a>
          </ng-container>

          <ng-container *ngIf="item.menuType === 2">
            <a [routerLink]="['/supplier/' + id + '/menu-item/' + item.externalAppId]"
            routerLinkActive="active"
            class="nav-link">{{ getTranslation(item.menuItemName) }}</a>
          </ng-container>
        </ng-container>

      </section>
      <a *ngIf="expanded || pin" href="http://www.aucxis.com/" target="_blank" class="bottom-logo-wrapper">
        <img src="assets/images/Aucxis_w.png" />
      </a>
      <version-component></version-component>

      <section class="sidenav-content" *ngIf="!expanded && !pin">
        <a [routerLink]="['/core/home']" class="small-logo-wrapper">
          <!--<img src="assets/images/europool_logo_small.png" />-->
        </a>
        <div class="expand-btn" (click)="navExpand()">
          <clr-icon shape="arrow right" size="42"></clr-icon>
        </div>
        <a href="http://www.aucxis.com/" target="_blank" class="bottom-logo-wrapper">
          <img src="assets/images/Aucxis_w_vert.png" />
        </a>
      </section>
    </nav>
  </div>
</div>
<spinner-component></spinner-component>
