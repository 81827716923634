<div class="auction-cluster-channel-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup
  #auctionClusterChannel
  [width]="600"
  [showTitle]="true"
  title="{{ 'NOTIFICATION.CHANNELS' | translate}}"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [showCloseButton]="false"
  [rtlEnabled]="rtlEnabled"
  [(visible)]="isOpened">
    <dx-scroll-view width="100%" height="100%">
      <dx-form
          #editAuctionClusterChannelForm
          id="editAuctionClusterChannelForm"
          [showValidationSummary]="false"
          (onFieldDataChanged)="onFieldDataChanged($event)"
          colCount="auto"
          [(formData)]="model"
          [minColWidth]="500">

          <dxi-item 
            dataField="name"
            [template]="'channelNameField'">
            <dxo-label text="{{ 'NOTIFICATION.NAME' | translate }}"></dxo-label>
          </dxi-item> <br>

          <dxi-item *ngIf="model.notificationChannelType == 1" dataField="provider" editorType="dxSelectBox" [editorOptions]="{ items: providers, valueExpr:'id', displayExpr:'name'}">
            <dxo-label text="{{ 'NOTIFICATION.PROVIDER' | translate }}"></dxo-label>
          </dxi-item>

          <div *dxTemplate="let data of 'channelNameField'" class="translatable-template">
            <translatable-field (change)="onChangeTranslationField($event, 'name')" [isDevExpress]="true" [isLabelHidden]="true" [textRequired]="false" [caption]="'NOTIFICATION.NAME'" [clusterLanguages]="languages" [(data)]="data.component.option('formData')[data.dataField]" [name]="'name'" #channelName></translatable-field>  
          </div>
      </dx-form>
      <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnAuctionClusterChannelSubmit">{{ 'SAVE' | translate }}</button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'CANCEL' | translate }}</button>
    </dx-scroll-view>
  </dx-popup>
</div>
<save-confirmation-component #saveConfirmation title="{{ 'LEAVE_PAGE' | translate }}" buttonCloseText="OK" cancellable="true" (close)="onCancel();"></save-confirmation-component>