import { Component, Output, OnInit, Injector, EventEmitter, ViewChild, Input } from '@angular/core';

// general services
import { LanguageService } from '../../shared/services/language.service';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';

// models
import { ProductProperty, ProductPropertyType,
  TransactionProperty, ProductPropertyTypes, NonGroupingSelection } from '../shared/models/product';
import { MasterData, MasterDataField } from '../shared/models/master-data';
import { ActivatedRoute } from '@angular/router';
import { MasterDataService } from '../shared/services/master-data.service';
import { TranslatableFieldComponent } from '../../shared/components/translatable-field/translatable-field.component';
import { Language } from '../../shared/models/language';

enum GroupingActionOnProperty {
  TAKE_FIRST = 0,
  FIXED_VALUE = 1,
  AGGREGATE = 2,
  CONCATENATE = 3,
  LEAVE_EMPTY = 4,
  CONCATENATE_DISTINCT = 5,
  ALL = 7
}

@Component({
  selector: 'transaction-property-component',
  templateUrl: 'transaction-property.component.html',
  styleUrls: ['./transaction-property.component.scss']
})
export class TransactionPropertyComponent extends ItemDetailsComponent<TransactionProperty> implements OnInit {

  productPropertyTypes: Array<ProductPropertyType> = [];
  masterDatas: Array<MasterData> = [];
  productProperties: Array<ProductProperty> = [];
  isMasterData: boolean;
  masterDataFields: Array<MasterDataField> = [];
  auctionClusterId: number;
  languages: Array<Language> = [];
  propertyGroupingShowing = false;
  automaticProductPropertyNonGroupingSelections: Array<NonGroupingSelection> = [];
  manualProductPropertyNonGroupingSelections: Array<NonGroupingSelection> = [];
  automaticGroupingProperties = [];
  manualGroupingProperties = [];
  groupingActions: any = GroupingActionOnProperty;
  groupingActionsTranslations: any;
  showWidth = false;
  showColRow = false;

  @ViewChild('name') name: TranslatableFieldComponent;

  // tslint:disable:no-output-on-prefix
  @Output() onAdded = new EventEmitter<TransactionProperty>();
  @Output() onUpdated = new EventEmitter<TransactionProperty>();
  // tslint:enable:no-output-on-prefix

  @Input('propertyGroupingShowing')
  set propertyGroupingShowingSetter(value: boolean) {
    this.propertyGroupingShowing = value;
  }

  @Input('showColRow')
  set showColRowSetter(value: boolean) {
    this.showColRow = value;
  }

  @Input('showWidth')
  set showWidthSetter(value: boolean) {
    this.showWidth = value;
  }

  @Input('automaticProductPropertyNonGroupingSelections')
  set automaticProductPropertyNonGroupingSelectionsValues(value: Array<NonGroupingSelection>) {
    this.automaticProductPropertyNonGroupingSelections = value;
    this.setAutomaticGroupingProperties();
  }

  @Input('manualProductPropertyNonGroupingSelections')
  set manualProductPropertyNonGroupingSelectionsValues(value: Array<NonGroupingSelection>) {
    this.manualProductPropertyNonGroupingSelections = value;
    this.setManualGroupingProperties();
  }

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private route: ActivatedRoute,
    private masterDataService: MasterDataService
  ) {
    super(injector);
    this.auctionClusterId = +this.route.snapshot.params['id'];
    this.groupingActions = Object.keys(this.groupingActions).filter(f => !isNaN(Number(f))).map(key => ({ name: this.groupingActions[key], value: Number(key) })); // tslint:disable-line:max-line-length

    this.translate.get('GROUPING_ACTIONS').subscribe((res: string) => {
      this.groupingActionsTranslations = res;
    });

    this.translate.onLangChange.subscribe(() => {
      this.translate.get('GROUPING_ACTIONS').subscribe((res: string) => {
        this.groupingActionsTranslations = res;
      });
    });
  }

  ngOnInit() {
    this.model = new TransactionProperty();
  }
  getGroupingActionTranslated(id: number) {
    const action = this.groupingActions.find(a => a.value === id);
    if (action) {
      return ` - ${this.groupingActionsTranslations[action.name]}`;
    }

    return '';
  }


  setAutomaticGroupingProperties() {
    if (!this.model || !this.model.productPropertyId) {
      return;
    }

    const newArray = [];
    this.automaticProductPropertyNonGroupingSelections.forEach(p => {
      if (p.productPropertyId === this.model.productPropertyId) {
        newArray.push({
          id: p.productPropertyGroupingSelectionId,
          label: `${p.productPropertyName}${this.getGroupingActionTranslated(p.actionOnGrouping)}`
        });
      }
    });

    this.automaticGroupingProperties = newArray;
  }

  setManualGroupingProperties() {
    if (!this.model || !this.model.productPropertyId) {
      return;
    }

    const newArray = [];
    this.manualProductPropertyNonGroupingSelections.forEach(p => {
      if (p.productPropertyId === this.model.productPropertyId) {
        newArray.push({
          id: p.productPropertyGroupingSelectionId,
          label: `${p.productPropertyName}${this.getGroupingActionTranslated(p.actionOnGrouping)}`
        });
      }
    });

    this.manualGroupingProperties = newArray;
  }

  open(properties: Array<TransactionProperty>, property: TransactionProperty, productProperties: Array<ProductProperty>,
    productPropertyTypes: Array<ProductPropertyType>, masterDataList: Array<MasterData>, languages: Array<Language>) {

    this.allItems = properties;
    this.productProperties = productProperties;
    this.masterDatas = masterDataList;
    this.productPropertyTypes = productPropertyTypes;
    this.languages = languages;

    this.translateProductProperties();

    this.masterDatas.forEach(masterData => {
      masterData.masterDataNameText = this.languageService.getTranslatableText(masterData.name);
    });

    // Create empty JSON object for translation fields
    const emptyTranslation = {};
    this.languages.forEach(lang => {
      emptyTranslation[lang.code] = '';
    });

    if (property.productPropertySelectionId !== null && property.productPropertySelectionId !== undefined) {
      this.isEditMode = true;
      this.model = property;
      this.isOpened = true;
      this.setAutomaticGroupingProperties();
      this.setManualGroupingProperties();
      this.filterMasterDataFields();
    } else {
      this.model = property;
      this.isEditMode = false;
      this.isOpened = true;
    }

    this.model.name = this.model.name || JSON.stringify(emptyTranslation);
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  translateProductProperties() {
    this.productProperties.forEach(productProperty => {
      productProperty.productPropertyNameText = this.languageService.getTranslatableText(productProperty.name);
    });
  }

  save() {
    this.model.name = this.name.data;
    this.model.orderNumber = this.allItems.length > 0 ? this.allItems[this.allItems.length - 1].orderNumber + 1 : 1;
    if (this.isEditMode) {
      this.onUpdated.emit(this.model);
    } else {
      this.onAdded.emit(this.model);
    }

    this.model = new TransactionProperty();
    this.detailsForm.reset();
    this.close(true);
    this.errorMessage = null;
  }

  setName() {
    const prodProp = this.productProperties.find(p => p.productPropertyId === this.model.productPropertyId);

    if (prodProp && this.name) {
      this.name.data = prodProp.name;
    }
  }

  filterMasterDataFields() {
    const prop = this.productProperties.find(pp => pp.productPropertyId === this.model.productPropertyId);
    if (prop && prop.propertyTypeId === ProductPropertyTypes.MASTER_DATA_VALUE) {
      const mdl = this.masterDatas.find(m => m.masterDataListId === prop.masterDataListId);
      if (mdl) {
        this.masterDataFields = mdl.fields;
        this.isMasterData = true;
      }
    } else {
      this.isMasterData = false;
    }

    this.setAutomaticGroupingProperties();
    this.setManualGroupingProperties();
  }
}
