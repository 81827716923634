<div class="clock-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup #addClock [width]="850" [showTitle]="true" title="{{ modalTitle }}" [dragEnabled]="false"
            [closeOnOutsideClick]="false" [rtlEnabled]="rtlEnabled" [(visible)]="isOpened"
            (onShown)="onFieldDataChanged($event)">
    <dx-scroll-view width="100%" height="100%" *ngIf="isOpened">

      <dx-form #clockForm id="clockForm" [showValidationSummary]="false"
               (onFieldDataChanged)="onFieldDataChanged($event)" colCount="auto" [(formData)]="model" [minColWidth]="500">

        <dxi-item dataField="name">
          <dxo-label text="{{ 'CLOCK.NAME' | translate }}"></dxo-label>
          <dxi-validation-rule type="required" message="{{ 'CLOCK.NAME_VALIDATION' | translate }}">
          </dxi-validation-rule>
        </dxi-item>

        <dxi-item dataField="auctionId" editorType="dxSelectBox"
                  [editorOptions]="{disabled: isEditMode, items: auctions, valueExpr:'auctionId', displayExpr:'name'}">
          <dxo-label text="{{ 'CLOCK.AUCTION' | translate }}"></dxo-label>
          <dxi-validation-rule type="required" message="{{ 'CLOCK.AUCTION_VALIDATION' | translate }}">
          </dxi-validation-rule>
        </dxi-item>

        <dxi-item dataField="catalogId" editorType="dxSelectBox"
                  [editorOptions]="{ items: filteredCatalogs, valueExpr:'catalogId', displayExpr:'name'}">
          <dxo-label text="{{ 'CLOCK.CATALOG' | translate }}"></dxo-label>
          <dxi-validation-rule type="required" message="{{ 'CLOCK.CATALOG_VALIDATION' | translate }}">
          </dxi-validation-rule>
        </dxi-item>

        <dxi-item itemType="tabbed">

          <dxi-tab title="{{ 'CLOCK.MODE' | translate }}">
            <dxi-item itemType="group">

              <dxi-item dataField="mode.mode" editorType="dxSelectBox"
                        [editorOptions]="{ items: modes, valueExpr:'value', displayExpr: translateSelectBoxes }">
                <dxo-label text="{{ 'CLOCK.MODE' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'CLOCK.MODE_VALIDATION' | translate }}">
                </dxi-validation-rule>
              </dxi-item>

              <dxi-item itemType="group" [visible]="(model.mode.mode === 1 || model.mode.mode === 4 || model.mode.mode === 6)">
                <dxi-item dataField="mode.clockStep" editorType="dxNumberBox">
                  <dxo-label text="{{ 'CLOCK.CLOCKSTEP' | translate }}"></dxo-label>
                </dxi-item>

                <dxi-item dataField="mode.clockSpeed" editorType="dxNumberBox">
                  <dxo-label text="{{ 'CLOCK.CLOCKSPEED' | translate }}"></dxo-label>
                </dxi-item>
              </dxi-item>

              <dxi-item itemType="group" [visible]="(model.mode.mode === 3 || model.mode.mode === 4)">
                <dxi-item dataField="mode.clockStepUp" editorType="dxNumberBox">
                  <dxo-label text="{{ 'CLOCK.CLOCKSTEP_UP' | translate }}"></dxo-label>
                </dxi-item>

                <dxi-item dataField="mode.clockSpeedUp" editorType="dxNumberBox">
                  <dxo-label text="{{ 'CLOCK.CLOCKSPEED_UP' | translate }}"></dxo-label>
                </dxi-item>
              </dxi-item>

              <dxi-item dataField="mode.coinCodeMasterDataListRowId" editorType="dxSelectBox"
                [editorOptions]="{ items: coinCodeModes, valueExpr:'masterDataListRowId', displayExpr: 'displayValue' }">
                <dxo-label text="{{ 'CLOCK.COINCODES' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'CLOCK.MODE_VALIDATION' | translate }}">
                </dxi-validation-rule>
              </dxi-item>

              <dxi-item itemType="group" [visible]="(model.mode.mode === 1)">
                <dxi-item dataField="mode.snap" editorType="dxNumberBox">
                  <dxo-label text="{{ 'CLOCK.SNAP' | translate }}"></dxo-label>
                </dxi-item>
              </dxi-item>

              <dxi-item itemType="group" [visible]="(model.mode.mode === 1 || model.mode.mode === 4 || model.mode.mode === 6)" *ngIf="user.isSystemUser">
                <dxi-item dataField="mode.minimumPriceSteps" editorType="dxNumberBox">
                  <dxo-label text="{{ 'CLOCK.MINIMUM_PRICE' | translate }}"></dxo-label>
                </dxi-item>

                <dxi-item dataField="mode.clockStepsAfterMinimumPrice" editorType="dxNumberBox">
                  <dxo-label text="{{ 'CLOCK.STEPS_AFTER_MINIMUM_PRICE' | translate }}"></dxo-label>
                  <dxi-validation-rule type="required"
                                       message="{{ 'CLOCK.STEPS_AFTER_MINIMUM_PRICE_VALIDATION' | translate }}">
                  </dxi-validation-rule>
                </dxi-item>

                <dxi-item dataField="mode.lowestStopClockPricePercentage" editorType="dxNumberBox">
                  <dxo-label text="{{ 'CLOCK.LOWEST_STOP_CLOCK_PRICE_PERCENTAGE' | translate }}"></dxo-label>
                  <dxi-validation-rule type="required"
                                       message="{{ 'CLOCK.LOWEST_STOP_CLOCK_PRICE_VALIDATION' | translate }}">
                  </dxi-validation-rule>
                </dxi-item>
              </dxi-item>

              <dxi-item dataField="mode.startPriceIncrementSteps" editorType="dxNumberBox">
                <dxo-label text="{{ 'CLOCK.START_PRICE_INCREMENT_STEPS' | translate }}"></dxo-label>
              </dxi-item>

              <dxi-item dataField="mode.offsetPriceSteps" editorType="dxNumberBox">
                <dxo-label text="{{ 'CLOCK.OFFSET_PRICE_STEPS' | translate }}"></dxo-label>
              </dxi-item>

              <dxi-item itemType="group"
                        [visible]="(model.mode.mode === 1 || model.mode.mode === 3 || model.mode.mode === 4)">
                <dxi-item dataField="mode.restartSteps" editorType="dxNumberBox">
                  <dxo-label text="{{ 'CLOCK.RESTART_STEPS' | translate }}"></dxo-label>
                  <dxi-validation-rule type="custom"
                                       message="{{ 'CLOCK.RESTART_STEPS_EVEN_NUMBER_VALIDATION' | translate }}"
                                       [validationCallback]="restartStepValidation">
                  </dxi-validation-rule>
                </dxi-item>
              </dxi-item>

              <dxi-item itemType="group" [visible]="(model.mode.mode === 3 || model.mode.mode === 4)">
                <dxi-item dataField="mode.waitTime" editorType="dxNumberBox">
                  <dxo-label text="{{ 'CLOCK.WAIT_TIME' | translate }}"></dxo-label>
                </dxi-item>

                <dxi-item dataField="mode.minWaitTime" editorType="dxNumberBox">
                  <dxo-label text="{{ 'CLOCK.MIN_WAIT_TIME' | translate }}"></dxo-label>
                </dxi-item>

                <dxi-item dataField="mode.maxWaitTime" editorType="dxNumberBox">
                  <dxo-label text="{{ 'CLOCK.MAX_WAIT_TIME' | translate }}"></dxo-label>
                </dxi-item>

                <dxi-item dataField="mode.waitTimeMinPrice" editorType="dxNumberBox">
                  <dxo-label text="{{ 'CLOCK.WAIT_TIME_MIN_PRICE' | translate }}"></dxo-label>
                </dxi-item>

                <dxi-item dataField="mode.minWaitTimeMinPrice" editorType="dxNumberBox">
                  <dxo-label text="{{ 'CLOCK.MIN_WAIT_TIME_MIN_PRICE' | translate }}"></dxo-label>
                </dxi-item>

                <dxi-item dataField="mode.maxWaitTimeMinPrice" editorType="dxNumberBox">
                  <dxo-label text="{{ 'CLOCK.MAX_WAIT_TIME_MIN_PRICE' | translate }}"></dxo-label>
                </dxi-item>

                <dxi-item dataField="mode.maxDownstepsToAllowRisingClock" editorType="dxNumberBox">
                  <dxo-label text="{{ 'CLOCK.MAX_DOWNSTEPS_TO_ALLOW_RISING_PRICE' | translate }}"></dxo-label>
                </dxi-item>

                <dxi-item dataField="mode.reactivateWaitTime" editorType="dxCheckBox">
                  <dxo-label text="{{ 'CLOCK.REACTIVATE_WAIT_TIME' | translate }}"></dxo-label>
                </dxi-item>

                <dxi-item dataField="mode.waitTimePurchaseAfterWaitTImeMinPrice" editorType="dxCheckBox">
                  <dxo-label text="{{ 'CLOCK.WAIT_TIME_PURCHASE_AFTER_WAIT_TIME_MIN_PRICE' | translate }}"></dxo-label>
                </dxi-item>
              </dxi-item>

              <dxi-item itemType="group"
                        [visible]="(model.mode.mode === 2)">
                <dxi-item dataField="mode.lotNumberPrefix" editorType="dxNumberBox">
                  <dxo-label text="{{ 'CLOCK.LOT_NUMBER_PREFIX' | translate }}"></dxo-label>
                </dxi-item>
              </dxi-item>

              <dxi-item itemType="group"
                [visible]="(model.mode.mode !== 2)" *ngIf="user.isSystemUser">
                <dxi-item dataField="mode.hideClockCircle" editorType="dxCheckBox">
                  <dxo-label text="{{ 'CLOCK.HIDE_CLOCK_CIRCLE' | translate }}"></dxo-label>
                </dxi-item>
              </dxi-item>

              <dxi-item itemType="group" [visible]="(model.mode.mode !== 1 && model.mode.mode !== 2)">
                <dxi-item dataField="mode.beepOnStartWaitTime" editorType="dxCheckBox">
                  <dxo-label text="{{ 'CLOCK.BEEP_ON_START_WAIT_TIME' | translate }}"></dxo-label>
                </dxi-item>
              </dxi-item>

              <dxi-item itemType="group" [visible]="(model.mode.mode === 5 || model.mode.mode === 6)">
                <dxi-item dataField="mode.showLastCallPopup" editorType="dxCheckBox">
                  <dxo-label text="{{ 'CLOCK.SHOW_LAST_CALL_POPUP' | translate }}"></dxo-label>
                </dxi-item>

                <dxi-item dataField="mode.showTemporaryWinnerDelay" editorType="dxNumberBox">
                  <dxo-label text="{{ 'CLOCK.SHOW_TEMPORARY_WINNER_DELAY' | translate }}"></dxo-label>
                </dxi-item>

                <dxi-item dataField="mode.waitTimeOnPurchase" editorType="dxNumberBox">
                  <dxo-label text="{{ 'CLOCK.WAIT_TIME_ON_PURCHASE' | translate }}"></dxo-label>
                </dxi-item>
              </dxi-item>

            </dxi-item>
          </dxi-tab>
          <dxi-tab title="{{ 'CLOCK.TRANSACTIONS' | translate }}">


            <dxi-item dataField="transactions.showOnlyTransactionsInBuffer" editorType="dxCheckBox">
              <dxo-label text="{{ 'CLOCK.SHOW_ONLY_TRANSACTIONS_IN_BUFFER' | translate }}"></dxo-label>
            </dxi-item>

            <dxi-item dataField="transactions.visibleTransactions" editorType="dxNumberBox">
              <dxo-label text="{{ 'CLOCK.VISIBLE_TRANSACTIONS' | translate }}"></dxo-label>
            </dxi-item>

            <dxi-item dataField="transactions.buyerMinimumPriceId" editorType="dxSelectBox"
                      [editorOptions]="{ dataSource: buyersPaginated, valueExpr:'buyerId', displayExpr:'name', showClearButton: true, searchEnabled: true }">
              <dxo-label text="{{ 'CLOCK.BUYER_MINIMUM_PRICE' | translate }}"></dxo-label>
              <dxi-validation-rule type="required" message="{{ 'CLOCK.BUYER_VALIDATION' | translate }}">
              </dxi-validation-rule>
            </dxi-item>

            <dxi-item dataField="transactions.buyerNotSoldId" editorType="dxSelectBox"
                      [editorOptions]="{ dataSource: buyersPaginated, valueExpr:'buyerId', displayExpr:'name', showClearButton: true, searchEnabled: true }">
              <dxo-label text="{{ 'CLOCK.BUYER_NOT_SOLD' | translate }}"></dxo-label>
              <dxi-validation-rule type="required" message="{{ 'CLOCK.BUYER_VALIDATION' | translate }}">
              </dxi-validation-rule>
            </dxi-item>

            <dxi-item dataField="transactions.showTransactions" editorType="dxRadioGroup"
                      [editorOptions]="{ items: showTransactionSettings, valueExpr:'value', displayExpr: translateSelectBoxes }">
              <dxo-label text="{{ 'CLOCK.SHOW_TRANSACTIONS' | translate }}"></dxo-label>
            </dxi-item>



          </dxi-tab>
          <dxi-tab title="{{ 'CLOCK.AUDIO' | translate }}">
            <dxi-item dataField="audio.enableAudio" editorType="dxCheckBox">
              <dxo-label text="{{ 'CLOCK.ENABLE_AUDIO' | translate }}"></dxo-label>
            </dxi-item>

            <dxi-item itemType="group" [visible]="model.audio.enableAudio">
              <dxi-item dataField="audio.audioAuctioneer" editorType="dxRadioGroup"
                        [editorOptions]="{items: auctioneerAudioSettings, valueExpr:'value', displayExpr: translateSelectBoxes }">
                <dxo-label text="{{ 'CLOCK.AUCTIONEER_AUDIO' | translate }}"></dxo-label>
              </dxi-item>

              <dxi-item dataField="audio.audioBuyer" editorType="dxRadioGroup"
                        [editorOptions]="{items: buyerAudioSettings, valueExpr:'value', displayExpr: translateSelectBoxes }">
                <dxo-label text="{{ 'CLOCK.BUYER_AUDIO' | translate }}"></dxo-label>
              </dxi-item>
            </dxi-item>

          </dxi-tab>
          <dxi-tab title="{{ 'CLOCK.NETWORK' | translate }}" *ngIf="user.isSystemUser">

            <dxi-item itemType="group" caption="{{ 'CLOCK.NETWORK_LATENCY_TITLE' | translate }}">
              <dxi-item dataField="network.networkLatencyExcellent" editorType="dxNumberBox">
                <dxo-label text="{{ 'CLOCK.NETWORK_LATENCY_EXCELLENT' | translate }}"></dxo-label>
                <dxi-validation-rule type="required"
                                     message="{{ 'CLOCK.NETWORK_LATENCY_TIMEOUTE_VALIDATION' | translate }}">
                </dxi-validation-rule>
              </dxi-item>

              <dxi-item dataField="network.networkLatencyAverage" editorType="dxNumberBox">
                <dxo-label text="{{ 'CLOCK.NETWORK_LATENCY_AVERAGE' | translate }}"></dxo-label>
                <dxi-validation-rule type="required"
                                     message="{{ 'CLOCK.NETWORK_LATENCY_TIMEOUTE_VALIDATION' | translate }}">
                </dxi-validation-rule>
              </dxi-item>

              <dxi-item dataField="network.networkLatencyPoor" editorType="dxNumberBox">
                <dxo-label text="{{ 'CLOCK.NETWORK_LATENCY_POOR' | translate }}"></dxo-label>
                <dxi-validation-rule type="required"
                                     message="{{ 'CLOCK.NETWORK_LATENCY_TIMEOUTE_VALIDATION' | translate }}">
                </dxi-validation-rule>
              </dxi-item>

              <dxi-item dataField="network.networkLatencyTimeout" editorType="dxNumberBox">
                <dxo-label text="{{ 'CLOCK.NETWORK_LATENCY_TIMEOUT' | translate }}"></dxo-label>
                <dxi-validation-rule type="required"
                                     message="{{ 'CLOCK.NETWORK_LATENCY_TIMEOUTE_VALIDATION' | translate }}">
                </dxi-validation-rule>
              </dxi-item>
            </dxi-item>


          </dxi-tab>
          <dxi-tab title="{{ 'CLOCK.SETTINGS' | translate }}">
            <dxi-item itemType="group">
              <dxi-item dataField="settings.maskDotpositionOnBuyIntention" editorType="dxCheckBox" *ngIf="user.isSystemUser">
                <dxo-label text="{{ 'CLOCK.MASK_DOT_POSITION_ON_BUY_INTENTION' | translate }}"></dxo-label>
              </dxi-item>

              <dxi-item dataField="settings.ticketPrinter" editorType="dxCheckBox">
                <dxo-label text="{{ 'CLOCK.TICKET_PRINTER' | translate }}"></dxo-label>
              </dxi-item>

              <dxi-item dataField="settings.creditLimitVerificationActive" editorType="dxCheckBox">
                <dxo-label text="{{ 'CLOCK.CREDIT_LIMIT_VERIFICATION_ACTIVE' | translate }}"></dxo-label>
              </dxi-item>

              <dxi-item dataField="settings.buyerNumber" editorType="dxCheckBox">
                <dxo-label text="{{ 'CLOCK.BUYER_NUMBER' | translate }}"></dxo-label>
              </dxi-item>

              <dxi-item dataField="settings.productClock" editorType="dxCheckBox">
                <dxo-label text="{{ 'CLOCK.PRODUCT_CLOCK' | translate }}"></dxo-label>
              </dxi-item>

              <dxi-item dataField="settings.lotsDivisible" editorType="dxCheckBox">
                <dxo-label text="{{ 'CLOCK.LOTS_DIVISIBLE' | translate }}"></dxo-label>
              </dxi-item>

              <dxi-item dataField="settings.autoValidateBuyerChoice" editorType="dxCheckBox">
                <dxo-label text="{{ 'CLOCK.AUTO_VALIDATE_BUYER_CHOICE' | translate }}"></dxo-label>
              </dxi-item>

              <dxi-item dataField="settings.temporaryBlockBuyerAmountOfClockstarts" editorType="dxNumberBox">
                <dxo-label text="{{ 'CLOCK.TEMP_BLOCK_BUYER_AMOUNT_CLOCKSTARTS' | translate }}"></dxo-label>
              </dxi-item>

              <dxi-item dataField="settings.temporaryBlockBuyerResetOnNextlot" editorType="dxCheckBox">
                <dxo-label text="{{ 'CLOCK.TEMP_BLOCK_BUYER_RESET_NEXT_LOT' | translate }}"></dxo-label>
              </dxi-item>

              <dxi-item dataField="settings.buyerPopupType" editorType="dxSelectBox" *ngIf="user.isSystemUser"
                        [editorOptions]="{ items: buyerPopupTypes, valueExpr:'value', displayExpr:'name'}">
                <dxo-label text="{{ 'CLOCK.BUYER_POPUP_TYPE' | translate }}"></dxo-label>
              </dxi-item>

              <dxi-item itemType="group" [visible]="model.settings.autoValidateBuyerChoice">
                <dxi-item dataField="settings.autoValidationDelay" editorType="dxNumberBox">
                  <dxo-label text="{{ 'CLOCK.AUTO_VALIDATION_DELAY' | translate }}"></dxo-label>
                </dxi-item>
              </dxi-item>

              <dxi-item dataField="settings.visibleNextLots" editorType="dxNumberBox">
                <dxo-label text="{{ 'CLOCK.VISIBLE_NEXT_LOTS' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'CLOCK.VISIBLE_NEXT_LOTS_VALIDATION' | translate }}">
                </dxi-validation-rule>
              </dxi-item>

              <dxi-item dataField="settings.livePriceIndications" editorType="dxCheckBox">
                <dxo-label text="{{ 'CLOCK.LIVE_PRICE_INDICATIONS' | translate }}"></dxo-label>
              </dxi-item>

              <dxi-item dataField="settings.autoRestartPartialLot" editorType="dxCheckBox">
                <dxo-label text="{{ 'CLOCK.AUTO_RESTART_PARTIAL_LOT' | translate }}"></dxo-label>
              </dxi-item>

              <dxi-item itemType="group" [visible]="model.settings.autoRestartPartialLot">
                <dxi-item dataField="settings.autoRestartPartialLotDelay" editorType="dxNumberBox">
                  <dxo-label text="{{ 'CLOCK.AUTO_RESTART_PARTIAL_LOT_DELAY' | translate }}"></dxo-label>
                  <dxi-validation-rule type="required"
                                       message="{{ 'CLOCK.AUTO_RESTART_PARTIAL_LOT_DELAY_VALIDATION' | translate }}">
                  </dxi-validation-rule>
                </dxi-item>
              </dxi-item>

              <dxi-item dataField="settings.requestNextLotNumber" editorType="dxCheckBox" *ngIf="user.isSystemUser">
                <dxo-label text="{{ 'CLOCK.REQUEST_NEXT_LOT_NUMBER' | translate }}"></dxo-label>
              </dxi-item>

              <dxi-item dataField="settings.filterMatrixByClockmode" editorType="dxCheckBox">
                <dxo-label text="{{ 'CLOCK.FILTER_MATRIX_BY_CLOCKMODE' | translate }}"></dxo-label>
              </dxi-item>

              <dxi-item dataField="settings.lotInPreparationAutoDelay" editorType="dxNumberBox">
                <dxo-label text="{{ 'CLOCK.LOT_IN_PREPARATION_AUTO_DELAY' | translate }}"></dxo-label>
              </dxi-item>
            </dxi-item>
          </dxi-tab>

          <dxi-tab title="{{ 'CLOCK.PAUSE_MESSAGES' | translate }}">
            <dx-data-grid #dataGrid [rtlEnabled]="rtlEnabled" [(dataSource)]="pauseMessages"
                          [showBorders]="true"
                          [allowColumnReordering]="false"
                          [hoverStateEnabled]="true"
                          [columnAutoWidth]="true"
                          [rowAlternationEnabled]="true">
              <dxi-column dataField="message" caption="{{ 'CLOCK.PAUSE_MESSAGES' | translate }}">
                <dxi-validation-rule type="required" message="{{ 'CLOCK.PAUSE_MESSAGE_VALIDATION' | translate }}">
                </dxi-validation-rule>
              </dxi-column>
              <dxo-selection mode="single"></dxo-selection>
              <dxo-editing mode="cell" [allowUpdating]="true" [allowAdding]="true" [allowDeleting]="true"
                           [useIcons]="true">
              </dxo-editing>
              <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]"
                         [showNavigationButtons]="true">
              </dxo-pager>
            </dx-data-grid>
          </dxi-tab>

        <dxi-tab title="{{ 'CLOCK.VIDEO' | translate }}" *ngIf="videoTabVisible">
            <dxi-item dataField="video.videoURL" editorType="dxTextBox">
              <dxo-label text="{{ 'CLOCK.VIDEO_URL' | translate }}"></dxo-label>
            </dxi-item>
          </dxi-tab>

        </dxi-item>
      </dx-form>
      <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnClockSubmit"
              disabled="disabled">
        {{ 'SAVE' | translate }}
      </button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'CANCEL' | translate }}</button>
    </dx-scroll-view>
  </dx-popup>
</div>
<save-confirmation-component #saveConfirmation title="{{ 'LEAVE_PAGE' | translate }}" buttonCloseText="OK"
                             cancellable="true" (close)="onCancel();"></save-confirmation-component>
<pause-message-component #pauseMessageDetails></pause-message-component>
