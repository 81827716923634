import { Component, OnInit, OnDestroy, Injector, ViewChild, ElementRef } from '@angular/core';
import { forkJoin, interval, Subscription } from 'rxjs';

// general services
import { CookieService } from 'ngx-cookie-service';
import { TokenService } from '../../shared/services/token.service';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { ClockComponent } from './clock.component';

// services
import { AuctionClusterAuctionService } from '../shared/services/auction-cluster-auction.service';
import { ClockService } from '../../shared/services/clock.service';
import { AuctionClusterBuyerService } from '../shared/services/auction-cluster-buyer.service';
import { LanguageService } from '../../shared/services/language.service';

// models
import { Auction } from '../shared/models/auction';
import { Clock, UserType } from '../../shared/models/clock';
import { Cookies } from '../../shared/constants/cookies';
import { ApplicationSettings } from '../../shared/models/application-settings';
import { ImportSchema, ImportField } from '../../shared/import/import-schema';
import { AuctionClusterBuyer } from '../shared/models/auction-cluster-buyer';
import { CatalogService } from '../shared/services/catalog.service';
import { Catalog } from '../shared/models/catalog';
import { UserPermissions } from '../../shared/models/user-permissions';
import { AuctionClusterPermissionEnum } from '../../shared/models/user-permissions';

import notify from 'devextreme/ui/notify';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'clocks-component',
  templateUrl: 'clocks.component.html',
  styleUrls: ['./clocks.component.scss']
})
export class ClocksComponent extends FullListComponent<Clock, ClockComponent> implements OnInit, OnDestroy {

  selectedAuction: number = -1;
  auctions: Array<Auction> = [];
  buyers: Array<AuctionClusterBuyer> = [];
  catalogs: Array<Catalog> = [];
  permissions: UserPermissions;
  subscription: Subscription;

  exportUrl: SafeUrl;
  exportFilename: string;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: ClockComponent;
  @ViewChild('lineMonitor') lineMonitor: any;
  @ViewChild('exportClockLink') exportLink: ElementRef;

  constructor(
    protected injector: Injector,
    private dataService: ClockService,
    private auctionService: AuctionClusterAuctionService,
    private buyerService: AuctionClusterBuyerService,
    private catalogService: CatalogService,
    private cookieService: CookieService,
    public tokenService: TokenService,
    private appSettings: ApplicationSettings,
    private languageService: LanguageService,
    private sanitizer: DomSanitizer
  ) {
    super(injector, Clock);
    this.title.set('CLOCKS.CLOCK_MANAGEMENT');
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.setTranslations('CLOCKS');

    if (!this.permissions) {
      this.permissions = this.tokenService.getCurrentUserPermissions();
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this._subscription.unsubscribe();
  }

  getData() {
    this.spinner.show();

    forkJoin(
      this.dataService.getAllClusterClocks(this.id),
      this.auctionService.getAuctionsForPermissions(this.id,
        [AuctionClusterPermissionEnum.ClockManagement, AuctionClusterPermissionEnum.Auctioneer]),
      // this.auctionService.getAuctions(this.id),
      this.buyerService.getBuyers(this.id),
      this.catalogService.getAllSupplyCatalogs(this.id)
    ).subscribe(result => {
      this.items = result[0];
      this.auctions = result[1] || [];

      /*const allSelectionAuctions = new Auction();
      allSelectionAuctions.auctionId = -1;
      allSelectionAuctions.name = 'All auctions';

      this.auctions.unshift(allSelectionAuctions);*/

      // tslint:disable-next-line:no-magic-numbers
      this.buyers = result[2];
      // tslint:disable-next-line:no-magic-numbers
      this.catalogs = result[3];
      this.spinner.hide();
      this.buildDisplayUpcomingSaleText();
      this.matchAuctionName();

      this.subscription = interval(10000).subscribe(() => {
        this.getClusterClocks();
      });
    },
      error => {
        notify(error.message, 'error', 5000);
        this.spinner.hide();
      });
  }

  getClusterClocks() {
    this.dataService.getClocksWithoutMasterData(this.id).subscribe((res: Clock[]) => {
      if (res && res.some(r => r.isDemo)) {
        res.forEach(clock => {
          const index = this.items.findIndex(item => item.clockId === clock.clockId);
          if (clock.isDemo && index !== -1) {

            let updatedItem = this.items[index];

            // Preserve all values except new isStarted value
            Object.keys(updatedItem).forEach(function (key) {
              if (key !== "isStarted") {
                clock[key] = updatedItem[key];
              }
            });

            this.items[index] = clock;
          }
        });
      } else {
        this.subscription.unsubscribe();
      }
    }, error => {
      notify(error.message, 'error', 5000);
    });
  }

  edit = (e: any) => {
    this.detailsComponent.modalTitle = this.translations.EDIT;
    this.detailsComponent.open(this.items, e.row.data.clockId, e.row.data.auctionId, this.buyers, this.catalogs, this.id);
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.ADD_NEW;
    this.detailsComponent.open(this.items, null, null, this.buyers, this.catalogs, this.id);
  }

  exportClock = (e: any) => {
    console.log(e);

    if (!this.tokenService.permissionMet('AuctionClusterPermissions.2', this.id))
      return;

    let clockId = e.row.data.clockId;
    this.spinner.show();

    this.dataService.exportClock(this.id, clockId).subscribe(result => {
      this.downloadFile(result, 'clock_export.zip', 'application/zip');
      this.spinner.hide();
    }, error => {
      this.errorService.show(error);
      this.spinner.hide();
    });
  }

  exportClockResults = (e: any) => {
    console.log(e);

    if (!this.tokenService.permissionMet('AuctionClusterPermissions.2', this.id))
      return;

    let clockId = e.row.data.clockId;
    this.spinner.show();

    this.dataService.exportClockResults(this.id, clockId).subscribe(result => {
      this.downloadFile(result, 'clock_export_results.zip', 'application/zip');
      this.spinner.hide();
    }, error => {
      this.errorService.show(error);
      this.spinner.hide();
    });
  }

  private downloadFile(data: any, fileName: string, type: string) {

    const blob = new Blob([data], { type: type });
    this.exportUrl = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(blob));
    this.exportFilename = fileName;

    window.setTimeout(() => { // anchor requires time interval to refresh its state
      this.exportLink.nativeElement.click();
    });
  }

  private matchAuctionName() {
    this.items.forEach(item => {
      item.auctionName = this.auctions.find(f => f.auctionId === item.auctionId).name;
    });
  }

  private buildDisplayUpcomingSaleText() {
    this.items.forEach(item => {
      if (item.nextSale && item.nextSale.startDate) {
        const localDate = new Date(item.nextSale.startDate);
        item.nextSaleText = (item.nextSale.clockName ? item.nextSale.clockName + ', ' : '') + item.nextSale.description + ' ' + localDate.toLocaleString(this.translate.currentLang); // tslint:disable-line:max-line-length
      }
    });
  }

  getSelectedClocks() {
    if (!this.selectedAuction) {
      this.getData();
    } else {
      this.dataService.getAuctionClocks(this.selectedAuction).subscribe(clocks => {
        this.items = clocks;
        this.matchAuctionName();
        this.buildDisplayUpcomingSaleText();
      });
    }
  }

  deleteSelected() {
    this.spinner.show();
    const auctionId = this.items.find(f => f.clockId === this.itemIdToDelete).auctionId;
    this.dataService.delete(auctionId, this.itemIdToDelete)
      .subscribe((clocks: Array<Clock>) => {
        this.getData();
        this.spinner.hide();
      },
        error => {
          notify(this.errorService.translations.DELETE_ERROR_MESSAGE, 'error', 5000);
          this.spinner.hide();
        });
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.clockId;
    this.confirmation.opened = true;
  }

  startClockClick = (e: any) => {
    if (e.column.type !== 'buttons') {
      if (this.tokenService.permissionMet('AuctionPermissions.10', this.id)) {
        const lang = this.translate.currentLang;
        const audioParameters = this.getAudioParameters(e.data);
        const url = `${this.appSettings.clockURL}?clockId=${e.data.clockId}&lang=${lang}&viewType=${UserType.AUCTIONEER}&useWebSocketsOnly=${e.data.useWebSocketOnly}${audioParameters}`;
        window.open(url, '_blank');
      }
    }
  }

  startClock = (e: any, readOnlyFlag: boolean) => {
    if (this.tokenService.permissionMet('AuctionPermissions.10', this.id)) {
      const lang = this.translate.currentLang;
      const audioParameters = this.getAudioParameters(e.row.data);
      if (readOnlyFlag) {
        const url = `${this.appSettings.clockURL}?clockId=${e.row.data.clockId}&lang=${lang}&viewType=${UserType.AUCTIONEER}&useWebSocketsOnly=${e.row.data.useWebSocketOnly}${audioParameters}&readOnly=1`;
        window.open(url, '_blank');
      } else {
        const url = `${this.appSettings.clockURL}?clockId=${e.row.data.clockId}&lang=${lang}&viewType=${UserType.AUCTIONEER}&useWebSocketsOnly=${e.row.data.useWebSocketOnly}${audioParameters}`;
        window.open(url, '_blank');
      }
    }
  }

  getAudioParameters = (data: any) => {
    return `&playerBufferSize=${data.playerBufferSize}&recorderBufferSize=${data.recorderBufferSize}&sampleRate=${data.sampleRate}&opusSamplingRate=${data.opusSamplingRate}&frameDuration=${data.frameDuration}`;
  }

  openClockAsViewer = (e: any) => {
    this.startClock(e, true);
  }

  openLineMonitor = (e: any) => {
    this.lineMonitor.modalTitle = this.translations.LINE_MONITOR.TITLE;
    this.lineMonitor.open(e.row.data.clockId);
  }

  createDataSchema() {

    const schema = new ImportSchema();

    const name = new ImportField('name', this.translations.NAME);
    name.required = true;

    const auctionName = new ImportField('auctionName', this.translations.AUCTION);
    auctionName.required = true;

    const nextSaleText = new ImportField('nextSaleText', this.translations.UPCOMING_SALE);
    nextSaleText.required = true;

    schema.fields.push(name, auctionName, nextSaleText);

    this.schema = schema;
  }

  startDemoVisible = (e: any) => {
    if (this.tokenService.permissionMet('AuctionPermissions.3', this.id) && e.row.data.isDemo) {
      if (!e.row.data.isStarted) {
        return true;
      }
    }
    return false;
  }

  stopDemoVisible = (e: any) => {
    if (this.tokenService.permissionMet('AuctionPermissions.3', this.id) && e.row.data.isDemo) {
      if (e.row.data.isStarted) {
        return true;
      }
    }
    return false;
  }

  checkAuctioneerPermission = (e: any) => {
    if (this.tokenService.permissionMet('AuctionPermissions.10', this.id)) {
      return true;
    }
    return false;
  }

  checkClockPermission = (e: any) => {
    if (this.tokenService.permissionMet('AuctionPermissions.3', this.id)) {
      return true;
    }
    return false;
  }

  startStopDemo = (e: any) => {
    this.spinner.show();
    const clock = this.items.find(f => f.clockId === e.row.data.clockId);
    this.dataService.demo(clock.auctionId, clock.clockId).subscribe((res: Clock) => {

      let updatedClock = this.items[this.items.findIndex(el => el.clockId === res.clockId)];

      // Preserve all values except new isStarted value
      Object.keys(updatedClock).forEach(function (key) {
        if (key !== "isStarted") {
          res[key] = updatedClock[key];
        }
      });

      this.items[this.items.findIndex(el => el.clockId === res.clockId)] = res;
      this.spinner.hide();
    }, error => {
      notify(error.message, 'error', 5000);
      this.spinner.hide();
    });
  }
}
