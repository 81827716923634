import { Component, OnInit, Injector, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Moment } from 'moment';
import * as _moment from 'moment';
import { Subscription } from 'rxjs';
import notify from 'devextreme/ui/notify';
import DataGrid from 'devextreme/ui/data_grid';

// services
import { AuctionTransactionMonitorService } from '../shared/services/auction-transaction-monitor.service';
import { TitleService } from '../../shared/services/title.service';
import { AuctionClusterReportService } from '../shared/services/report.service';
import { TranslateService } from '@ngx-translate/core';

// models
import { ImportSchema, ImportField } from '../../shared/import/import-schema';
import { TransactionMonitorLotRow, TransactionMonitorLotColumn } from '../../shared/models/transaction-monitor-lot';
import { AuctionCluster } from '../../platform/shared/models/auction-cluster';
import { Catalog } from '../../auction/shared/models/catalog';
import { Clock } from '../../shared/models/clock';
import { ClrDatagridSortOrder } from '@clr/angular';
import { ItemListComponent } from '../../shared/components/item-list/item-list.component';
import { AuctionClusterPermissionEnum } from '../../shared/models/user-permissions';
import { ProductPropertyTypes, UrlTypes, DateTypes } from '../shared/models/product';
import { ExportConstants } from '../../shared/constants/export.constants';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { SystemReportDesignTypes } from '../../shared/models/report';
import { AuctionClusterNotificationService } from '../shared/services/auction-cluster-notification.service';
import { AuctionClusterNotification } from '../../shared/models/auction-cluster-notification';
import { NotificationTypeEnum } from '../../shared/models/notificationTypeEnum';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'auction-transaction-monitor-component',
  templateUrl: 'auction-transaction-monitor.component.html',
  styleUrls: ['./auction-transaction-monitor.component.scss']
})
export class AuctionTransactionMonitorComponent extends ItemListComponent<TransactionMonitorLotRow> implements OnInit, OnDestroy {

  auctionClusterId: number = null;
  auctionClusters: Array<AuctionCluster> = [];
  catalogId: number = null;
  auctionId: number = null;
  catalogs: Array<Catalog> = [];
  clockId = 0;
  clocks: Array<Clock> = [];
  date: Date = new Date();
  columns: Array<any> = [];
  data: Array<any> = new Array<any>();
  objectKeys: Array<string> = new Array<string>();
  items = [];
  filterPrebids = false;
  filterMarked = false;
  reportId = 0;

  exportConstants: ExportConstants = new ExportConstants();
  exportUrl: SafeUrl;
  exportFilename: string;

  @ViewChild('exportLink') exportLink: ElementRef;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;
  dateFilterItems = [];
  dataGridInstance: DataGrid;
  gridColumns = [];
  allMode: string;
  checkBoxesMode: string;
  selectedRows: Array<number> = [];
  gridItems = [];
  auctionClusterNotification: AuctionClusterNotification;
  lastTimeSentNotification = '';

  constructor(
    protected injector: Injector,
    private transactionMonitorService: AuctionTransactionMonitorService,
    private reportService: AuctionClusterReportService,
    private sanitizer: DomSanitizer,
    private titleService: TitleService,
    private translateService: TranslateService,
    private notificationService: AuctionClusterNotificationService
  ) {
    super(injector, TransactionMonitorLotRow);
    this.title.set('AUCTION.TRANSACTION_MONITOR.TITLE');
    this._subscription = this.language.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always';
  }

  ngOnInit() {
    this.setTranslations('AUCTION.TRANSACTION_MONITOR');

    this.auctionClusterId = this.id;
    this.getReportDesignId();
    this.filterCatalogs();
    this.filterClocks();
    this.getAuctionClusterNotificationByType()
  }

  ngOnDestroy() {
    // Make sure to call ngOnDestroy method of inherited class to do the necessary
    // unsubscriptions and other cleanups which might cause app to hang if not propery handled
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getData() {
    if (!this.auctionClusterId || !this.catalogId) {
      return;
    }

    // Fetch lots without columns refresh
    this.fetchLots(false);
  }

  fetchLots(refreshColumns: boolean) {
    // Reset any existing filtering if column refresh is requested
    if (refreshColumns) {
      this.columns = [];
    }

    this.data = [];
    this.items = [];
    const dateFormatted = moment(this.date).local().toISOString();
    this.spinner.show();

    this.transactionMonitorService.getLots(this.auctionClusterId, this.catalogId, dateFormatted, this.clockId)
      .subscribe(result => {
        this.items = result.rows;
        if (refreshColumns) {
          // Parse columns only if explicitly requested
          this.parseColumns(result.columnTitles);
        }
        this.parseObject(result.rows, result.columnTitles);
        this.generateTable(result);
        this.spinner.hide();
      },
        error => {
          notify(error.message, 'error', 5000);
          this.spinner.hide();
        });
  }

  exportToCsv() {
    let csvString = '';
    let delimiter = ';';
    const currentLanguageCode = this.translateService.currentLang;

    this.language.getLanguages().subscribe(l => {
      const currentLanguge = l.filter(x => x.code === currentLanguageCode)[0]
      if (currentLanguge !== undefined) {
        delimiter = currentLanguge.csvDelimiter;
      }
    });

    this.columns.forEach(c => {
      csvString += `"${c.name}"${delimiter}`;
    });

    csvString += `
    `;

    this.data.forEach(d => {
      this.objectKeys.forEach(key => {
        // Remove line breaks for export
        let value = this.removeLineBreaks(d[key]);
        csvString += `"${value}"${delimiter}`;
      });
      csvString += `
      `;
    });

    const blob = new Blob([this.exportConstants.BLOB_HEADER + csvString], { type: this.exportConstants.BLOB_TYPE });
    this.exportUrl = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(blob));
    const title = this.titleService.text.getValue();
    this.exportFilename = `${title}.${this.exportConstants.FILE_EXTENSION_CSV}`;

    window.setTimeout(() => { // anchor requires time interval to refresh its state
      this.exportLink.nativeElement.click();
      csvString = '';
    });
  }

  clearAll() {
    this.data.forEach(d => {
      if (d.__item__) {
        d.__item__.selected = false;
      }
    });
  }

  private parseObject(rows: Array<TransactionMonitorLotRow>, columns: Array<TransactionMonitorLotColumn>) {
    this.data = [];

    // Ignore parsing if there are no columns defined
    if (this.columns.length === 0) {
      return;
    }

    rows.forEach(row => {
      if (row.groupedIds) {
        if (row.groupedIds.length == 0) {
          row.values.unshift("");
        }
        else {
          let ids = "";
          row.groupedIds.forEach((id, i) => {
            ids += id;
            if (i != (row.groupedIds.length - 1)) {
              ids += ", ";
            }
          });
          row.values.unshift(ids);
        }

      }
      const obj = new Object();
      this.columns.forEach((column, i) => {
        if (column.propertyTypeId === ProductPropertyTypes.DATE) {
          if(column.propertyTypeFormatId == DateTypes.SHORT_DATE)
          {
            let date = new Date(row.values[i]);
            date.setHours(12);
            date.setMinutes(0);
            obj['key' + i] = (date).toISOString();
          }
          else
          {
            obj['key' + i] = this.addLineBreaks(row.values[i], column.propertyTypeId, column.propertyTypeFormatId);
          }
        }
        else if (column.propertyTypeId === ProductPropertyTypes.TEXT
          || column.propertyTypeId === ProductPropertyTypes.BOOLEAN
          || column.propertyTypeId === ProductPropertyTypes.IMAGE
          || column.propertyTypeId === ProductPropertyTypes.MASTER_DATA_VALUE
          || isNaN(Number(row.values[i]))) {
          // Add line breaks for string value
          let objValue = this.getTranslation(row.values[i]);
          objValue = this.addLineBreaks(objValue, column.propertyTypeId);
          obj['key' + i] = objValue;
        } else {
          obj['key' + i] = row.values[i]; //this.format(Number(row.values[i]), column.propertyTypeId);
        }
      });
      obj['__item__'] = row;

      this.data.push(obj);
    });

    // Reset default page size
    this.setPageSize(this.pageSizeValue);
  }

  private parseColumns(columns: Array<TransactionMonitorLotColumn>) {
    const _columns = [];

    // Additional columns for displaying transaction lot ids
    _columns.push({
      name: this.getTranslation(this.translations.IDS),
      propertyTypeId: ProductPropertyTypes.TEXT,
      propertyTypeFormatId: null
    });

    columns.forEach(column => {
      _columns.push({
        name: this.getTranslation(column.name),
        propertyTypeId: column.propertyTypeId,
        propertyTypeFormatId: column.propertyTypeFormatId
      });
    });

    const _objectKeys = [];
    _columns.forEach((column, i) => {
      _objectKeys.push('key' + i);
    });

    this.objectKeys = _objectKeys;
    this.columns = _columns;
    this.createDataSchema();
  }

  filterLots() {
    // Fetch data and force columns refresh
    this.fetchLots(true);
  }

  filterCatalogs() {
    if (!this.auctionClusterId) {
      this.catalogs = [];
      this.catalogId = null;
      return;
    }

    this.transactionMonitorService.getCatalogsForPermissions(this.auctionClusterId, [AuctionClusterPermissionEnum.TransactionMonitor])
      .subscribe(result => {
        this.catalogs = result;
        if (this.catalogs.length !== 0) {
          this.catalogId = this.catalogs[0].catalogId;
          this.auctionId = this.catalogs[0].auctionId;
          this.onCatalogChange();
        }
      });
  }

  anyRowSelected() {
    return this.data && this.data.length > 0 && this.data.filter(d => d.__item__ && d.__item__.selected === true).length > 0;
  }

  printTransactions() {
    const ids = [];

    if (this.data && this.data.length) {
      this.data.forEach(d => {
        if (d.__item__ && d.__item__.selected) {
          ids.push(d.__item__.id);
        }
      });
    }

    this.spinner.show();
    this.transactionMonitorService.printTransactions(this.auctionClusterId, this.catalogId, ids)
      .subscribe(r => {
        this.clearAll();
        this.spinner.hide();
      }, error => {
        var errorMessage = this.errorService.toString(error);
        notify(errorMessage, 'error', 5000);
        this.spinner.hide();
      });
  }

  filterClocks() {
    if (!this.auctionClusterId || !this.catalogId) {
      this.clocks = [];
      this.clockId = 0;
      return;
    }

    this.transactionMonitorService.getClocksByCatalog(this.auctionClusterId, this.catalogId, [AuctionClusterPermissionEnum.SupplyMonitor])
      .subscribe(result => {
        this.clocks = result;
        this.clockId = 0;
      });
  }

  onCatalogChange() {
    if (!this.auctionClusterId) {
      this.clocks = [];
      this.clockId = null;
      return;
    }

    if (!this.catalogId) {
      this.filterClocks();
    }

    this.transactionMonitorService.getClocksByCatalog(this.auctionClusterId, this.catalogId, [AuctionClusterPermissionEnum.SupplyMonitor])
      .subscribe(result => {
        this.clocks = result;
        this.clockId = 0;
      });
  }

  dateChanged(dateTime: Moment) {
    this.date = moment(dateTime).toDate();
  }

  sendNotification(event: Event) {
    this.spinner.show();
    const dateFormatted = moment(this.date).local().format('YYYY-MM-DD');
    this.notificationService.sendNotifications(this.auctionId, NotificationTypeEnum.TransactionSummary, 
      this.catalogId, this.clockId, dateFormatted,new Date().getTimezoneOffset())
    .subscribe((result) => {
      //refresh last sent
      this.getAuctionClusterNotificationByType();
      this.spinner.hide();
    },
      error => {
        notify(error.message, 'error', 5000);
        this.spinner.hide();
      });
  }

  getAuctionClusterNotificationByType() {
    this.notificationService.getAuctionClusterNotificationByType(NotificationTypeEnum.TransactionSummary, this.auctionClusterId)
    .subscribe((res: AuctionClusterNotification) => {
      this.auctionClusterNotification = res;
      if(res.lastTimeSent)
        this.lastTimeSentNotification =  moment.utc(res.lastTimeSent).local().format('DD.MM.YYYY. HH:mm:ss');
    },
      error => {
        notify(error.message, 'error', 5000);
        this.spinner.hide();
      });
  }

  createDataSchema() {
    const schema = new ImportSchema();

    this.columns.forEach((column, i) => {
      const field = new ImportField(this.objectKeys[i], column.name);
      field.required = true;
      schema.fields.push(field);
    });

    this.schema = schema;
  }

  getReportDesignId() {
    this.reportService.getReportBySystemReportDesignType(this.auctionClusterId, SystemReportDesignTypes.TransactionMonitorReport).subscribe(report => {
      this.reportId = report.reportId;
    });
  }

  openReport = (e: any) => {
    let url = '/#/auction/reports/' + this.auctionClusterId + '/report/' + this.reportId + '?clockStartGuid=' + e.row.data.clockStartGuid + '?TransactionNumber=' + e.row.data.transactionnr;
    window.open(url);
  }

  initColumn = (e: any) => {
    this.dataGridInstance = e.component;
  }

  generateTable = (rows: any) => {
    this.gridColumns = [];
    const gridCurrentColums = this.dataGridInstance.getVisibleColumns();

    if (!gridCurrentColums.some(col => col.dataField === this.translations.IDS)) {
      this.dataGridInstance.addColumn({
        dataField: this.getTranslation(this.translations.IDS),
        caption: this.translations.IDS,
        visibleIndex: 0,
        allowEditing: false,
        encodeHtml: false,
        editorOptions: {
          showClearButton: true
        }
      });
    }
    this.gridColumns.push(this.translations.IDS);


    rows.columnTitles.forEach((row: any, i: number) => {
      let columnName = this.getTranslation(row.name);
      if (this.gridColumns.includes(columnName)) {
        let j = 0;
        do {
          columnName = this.getTranslation(row.name) + j;
          j++;
        } while (this.gridColumns.includes(columnName));
      }
      this.gridColumns.push(columnName);
      const isColumn = gridCurrentColums.find(c => c.dataField === columnName);
      if (!isColumn) {
        this.dataGridInstance.addColumn({
          dataField: columnName,
          name: row.uniqueName,
          caption: this.getTranslation(row.name),
          visibleIndex: i + 1,
          allowEditing: false,
          dataType: this.getDataType(row.propertyTypeId, row.propertyTypeFormatId),
          encodeHtml: false,
          cellTemplate: this.assignDefaultCellTemplate(row.propertyTypeId, row.propertyTypeFormatId),
          editorOptions: {
            showClearButton: true
          }
        });
      }
    });

    this.gridColumns.push('clockStartGuid');
    if (!gridCurrentColums.some(col => col.dataField === 'clockStartGuid')) {
      this.dataGridInstance.addColumn({
        dataField: 'clockStartGuid',
        caption: 'clockStartGuid',
        visible: false,
        allowEditing: false
      });
    }

    this.gridColumns.push('isTransactionAutoCreated');
    if (!gridCurrentColums.some(col => col.dataField === 'isTransactionAutoCreated')) {
      this.dataGridInstance.addColumn({
        dataField: 'isTransactionAutoCreated',
        caption: 'isTransactionAutoCreated',
        visible: false,
        allowEditing: false
      });
    }

    this.gridColumns.push('isDeleted');
    if (!gridCurrentColums.some(col => col.dataField === 'isDeleted')) {
      this.dataGridInstance.addColumn({
        dataField: 'isDeleted',
        caption: 'isDeleted',
        visible: false,
        allowEditing: false
      });
    }

    this.generateTableData();
  }

  assignDefaultCellTemplate(propertyTypeId: ProductPropertyTypes, propertyTypeFormatId?: UrlTypes) {
    /*if (propertyTypeId === ProductPropertyTypes.URL && propertyTypeFormatId === UrlTypes.IMAGE_URL) {
      return 'imageCellTemplate';
    } else */if (propertyTypeId === ProductPropertyTypes.TEXT) {
      return 'textCellTemplate';
    } else {
      return 'cellTemplate';
    }
  }


  generateTableData() {
    this.gridItems = [];
    this.data.forEach((row, i) => {
      let gridItem = '';
      this.gridColumns.forEach((column, j) => {
        if (column === 'clockStartGuid') {
          gridItem += '"' + column + '" : "' + row.__item__.clockStartGuid + '",';
        } else if (column === 'isTransactionAutoCreated') {
          gridItem += '"' + column + '" : "' + row.__item__.isTransactionAutoCreated + '",';
        } else if (column === 'isDeleted') {
          gridItem += '"' + column + '" : "' + row.__item__.isDeleted + '",';
        } else {
          gridItem += '"' + column + '" : "' + this.getTranslation(row['key' + j]) + '",';
        }
      });
      this.gridItems.push(JSON.parse('{ ' + gridItem.slice(0, -1) + '}'));
    });

    const rows = this.dataGridInstance.getDataSource();
    if (rows === null) {
      if (this.gridItems.length > 0) {
        this.dataGridInstance.option('dataSource', this.gridItems);
      }
    } else {
      this.dataGridInstance.option('dataSource', this.gridItems);
    }
  }

  isTransactionAutoCreated = (e: any) => {
    return e.row.data.isTransactionAutoCreated === 'false';
  }

  selectionChangedHandler = (e: any) => {
    this.data.forEach(d => {
      d.__item__.selected = false;
    });
    this.selectedRows.forEach(row => {
      const index = this.gridItems.findIndex(data => data === row);
      this.data[index].__item__.selected = true;
    });
  }

  editBackground(e: any) {
    if (e.rowType == 'data') {
      if (e.data.isDeleted == 'true') {
        e.rowElement.style.textDecoration = 'line-through';
      }
    }
  }
}
