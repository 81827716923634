<div class="product-property-styling-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup
  #productPropertyForm
  [width]="600"
  [height]="500"
  [showTitle]="true"
  title="{{ modalTitle }}"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [rtlEnabled]="rtlEnabled"
  [(visible)]="isOpened"
  (onShown)="onFieldDataChanged($event)">
  <dx-scroll-view width="100%" height="100%">
      <dx-form
        #userForm
        id="userForm"

        [showValidationSummary]="false"
        (onFieldDataChanged)="onFieldDataChanged($event)"
        colCount="auto"
        [(formData)]="model"
        [minColWidth]="500">
          <dxi-item itemType="group">
            <dxi-item dataField="name">
              <dxo-label text="{{ 'PRODUCT_PROPERTY_STYLING.NAME' | translate }}"></dxo-label>
              <dxi-validation-rule type="required" message="{{ 'PRODUCT_PROPERTY_STYLING.NAME_VALIDATION' | translate }}"></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="sysName">
              <dxo-label text="{{ 'PRODUCT_PROPERTY_STYLING.SYSNAME' | translate }}"></dxo-label>
              <dxi-validation-rule type="required" message="{{ 'PRODUCT_PROPERTY_STYLING.SYSNAME_VALIDATION' | translate }}"></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="font">
              <dxo-label text="{{ 'PRODUCT_PROPERTY_STYLING.FONT' | translate }}"></dxo-label>
            </dxi-item>
            <dxi-item 
                dataField="fontSize" 
                editorType="dxNumberBox"
                [editorOptions]="{ 
                  showSpinButtons: true,
                  mode: 'number', 
                  format: '#0', 
                  min: 0,
                  step: 1}"
                >
                  <dxo-label text="{{ 'PRODUCT_PROPERTY_STYLING.FONT_SIZE' | translate }}"></dxo-label>
                </dxi-item>
                <dxi-item 
                  dataField="color" 
                  editorType="dxColorBox"
                  [editorOptions]="{ 
                    applyValueMode: 'instantly'}"
                  >
                  <dxo-label text="{{ 'PRODUCT_PROPERTY_STYLING.COLOR' | translate }}"></dxo-label>
                </dxi-item>
                <dxi-item 
                  dataField="backgroundColor" 
                  editorType="dxColorBox"
                  [editorOptions]="{ 
                    applyValueMode: 'instantly'}"
                  >
                  <dxo-label text="{{ 'PRODUCT_PROPERTY_STYLING.BACKGROUND_COLOR' | translate }}"></dxo-label>
                </dxi-item>
                <dxi-item 
                  dataField="borderColor" 
                  editorType="dxColorBox"
                  [editorOptions]="{ 
                    applyValueMode: 'instantly'}"
                  >
                  <dxo-label text="{{ 'PRODUCT_PROPERTY_STYLING.BORDER_COLOR' | translate }}"></dxo-label>
                </dxi-item>
            </dxi-item>
        </dx-form>
<br/>
        <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnProductPropertyStylingSubmit" disabled="disabled">
          {{ 'SAVE' | translate }}
        </button>
        <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'CANCEL' | translate }}</button>
  </dx-scroll-view>
  </dx-popup>
</div>
