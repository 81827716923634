<div class="calculation-component item-list-component" [ngClass]="{ 'dx-rtl': rtlEnabled }"
  dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup 
    #editCalculations 
    [width]="700" 
    [showTitle]="true" 
    title="{{ modalTitle }}" 
    [dragEnabled]="false"
    [closeOnOutsideClick]="false" 
    [rtlEnabled]="rtlEnabled" 
    [(visible)]="isOpened"
    (onShown)="onFieldDataChanged($event)">
    <dx-scroll-view width="100%" height="100%">
      <dx-form 
        #editCalculationsForm 
        id="editCalculationsForm" 
        [showValidationSummary]="false"
        (onFieldDataChanged)="onFieldDataChanged($event)" 
        colCount="auto" 
        [(formData)]="model" 
        [minColWidth]="500">
          <dxi-item itemType="tabbed">
            <dxi-tab title="{{ 'CALCULATIONS.GENERAL' | translate }}">
              <dxi-item dataField="name" [template]="'calculationNameField'">
                <dxo-label text="{{ 'CALCULATIONS.NAME' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'CALCULATIONS.NAME' | translate }}"></dxi-validation-rule>
              </dxi-item>
              <dxi-item 
                #product 
                name="product"
                dataField="productId"
                editorType="dxSelectBox"
                [editorOptions]="{ items: products, valueExpr:'productId', displayExpr:'productNameText', onValueChanged: onProductChanged}"
                [rtlEnabled]="rtlEnabled">
                <dxo-label text="{{ 'CALCULATIONS.PRODUCT' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'CATALOG.PRODUCT_VALIDATION' | translate }}"></dxi-validation-rule>
              </dxi-item>
              <dxi-item 
                #propertyType 
                name="propertyType"
                dataField="propertyTypeId"
                editorType="dxSelectBox"
                [editorOptions]="{ items: propertyTypes, valueExpr:'value', displayExpr:translateSelectBoxes}"
                [rtlEnabled]="rtlEnabled">
                <dxo-label text="{{ 'CALCULATIONS.PROPERTY_TYPE' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'TICKET_PRINTER.TYPE_VALIDATION' | translate }}"></dxi-validation-rule>
              </dxi-item>
              <dxi-item 
                #calculationType 
                name="calculationType"
                dataField="calculationType"
                editorType="dxSelectBox"
                [editorOptions]="{ items: calculationTypes, valueExpr:'value', displayExpr:translateSelectBoxes}"
                [rtlEnabled]="rtlEnabled">
                <dxo-label text="{{ 'CALCULATIONS.CALCULATIONS_TYPE' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item 
                #frontEndOperation 
                name="frontEndOperation"
                dataField="frontEndOperation"
                editorType="dxSelectBox"
                [editorOptions]="{ items: frontEndTypes, valueExpr:'value', displayExpr:translateSelectBoxes}"
                [rtlEnabled]="rtlEnabled"
                [visible]="model.calculationType===3">
                <dxo-label text="{{ 'CALCULATIONS.FRONTEND_OPERATION' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item 
                #expression 
                name="expression"
                dataField="expression"
                editorType="dxTextArea"
                [rtlEnabled]="rtlEnabled"
                [visible]="model.calculationType===4">
                <dxo-label text="{{ 'CALCULATIONS.EXPRESSION' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item 
                dataField="offset" 
                editorType="dxNumberBox" 
                [editorOptions]="{ 
                  showSpinButtons: true, 
                  mode: 'number', 
                  format: '#0', 
                  min: 0,
                  step: 1}"
                >
                <dxo-label text="{{ 'CALCULATIONS.OFFSET' | translate }}"></dxo-label>
              </dxi-item>
              
              <dxi-item 
                dataField="isLiveCalculation" 
                editorType="dxCheckBox" 
                >
                <dxo-label text="{{ 'CALCULATIONS.LIVE_CALCULATION' | translate }}"></dxo-label>
              </dxi-item>

              <dxi-item 
                dataField="isLiveCalculationPartialSale" 
                editorType="dxCheckBox" 
                >
                <dxo-label text="{{ 'CALCULATIONS.LIVE_CALCULATION_PARTIAL_SALE' | translate }}"></dxo-label>
              </dxi-item>

              <dxi-item 
                dataField="isSplitLotCalculation" 
                editorType="dxCheckBox" 
                >
                <dxo-label text="{{ 'CALCULATIONS.SPLIT_LOT_CALCULATION' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item 
                dataField="isMultiEditCalculation" 
                editorType="dxCheckBox" 
                >
                <dxo-label text="{{ 'CALCULATIONS.MULTIEDIT_CALCULATION' | translate }}"></dxo-label>
              </dxi-item>
              <dxi-item 
                dataField="isExternalAdminCalculation" 
                editorType="dxCheckBox" 
                >
                <dxo-label text="{{ 'CALCULATIONS.EXTERNAL_ADMIN_CALCULATION' | translate }}"></dxo-label>
              </dxi-item>
            </dxi-tab>
            <dxi-tab title="{{ 'CALCULATIONS.FIELDS' | translate }}">
                <calculations-fields-component [data]="model.calculationFields" [productProperties]="productProperties" [masterData]="masterDatas"></calculations-fields-component>
            </dxi-tab>
          </dxi-item>

          <div *dxTemplate="let data of 'calculationNameField'" class="translatable-template">
            <translatable-field (change)="onChangeTranslationField($event, 'name')" [isDevExpress]="true" [isLabelHidden]="true" [caption]="'CALCULATIONS.NAME'" [clusterLanguages]="languages" [(data)]="data.component.option('formData')[data.dataField]" [name]="'name'" #calculationName></translatable-field>  
            <br/>
          </div>
      </dx-form>

      <br/>
      <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnCalculationsSubmit"
        disabled="disabled">{{ 'SAVE' | translate }}</button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'CANCEL' | translate }}</button>
    </dx-scroll-view>
  </dx-popup>

</div>