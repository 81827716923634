import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';

import { BuyerRoutingModule } from './buyer-routes.module';

// module components
import { BuyerContentComponent } from './content/buyer-content.component';
import { BuyerHomeComponent } from './home/buyer-home.component';
import { BuyerClocksComponent } from './clocks/buyer-clocks.component';
import { BuyerUsersComponent } from './users/buyer-users.component';
import { BuyerUserComponent } from './users/buyer-user.component';
import { OpenClockComponent } from './clocks/open-clocks.component';
import { BuyerPrebidsComponent } from './prebid/buyer-prebids.component';
import { BuyerPrebidComponent } from './prebid/buyer-prebid.component';
import { BuyerPrebidLotsComponent } from './prebid/buyer-prebid-lots.component';
import { BuyerPrebidLotComponent } from './prebid/buyer-prebid-lot.component';
import { ShoppingListsComponent } from './shopping-lists/shopping-lists.component';
import { ShoppingListComponent } from './shopping-lists/shopping-list.component';
import { ShoppingListLotsComponent } from './shopping-lists/shopping-list-lots.component';
import { ShoppingListLotComponent } from './shopping-lists/shopping-list-lot.component';
import { BuyerSupplyMonitorComponent } from './supply-monitor/buyer-supply-monitor.component';
import { BuyerSupplyMonitorPrebidComponent } from './supply-monitor/buyer-supply-monitor-prebid.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportComponent } from './reports/report.component';
import { ReportEditorComponent } from './reports/report-editor.component';
import { BuyerSupplyMatrixComponent } from './supply-matrix/buyer-supply-matrix.component';
import { BuyerSupplyMatrixLotsComponent } from './supply-matrix/buyer-supply-matrix-lots.component';
import { PrebidOnProductComponent } from './supply-monitor/prebid-on-product.component';
import { PrebidOnProductItemComponent } from './supply-monitor/prebid-on-product-item.component';
import { PrebidOnLotComponent } from './shared/components/prebids/prebid-on-lot.component'


// shared services
import { BuyerSaleService } from './shared/services/buyer-sale.service';
import { BuyerBuyerService } from './shared/services/buyer-buyer.service';
import { BuyerUserService } from './shared/services/buyer-user.service';
import { PrebidOnProductService } from './shared/services/prebid-on-product.service';
import { ShoppingListsService } from './shared/services/shopping-lists.service';
import { AuctionClusterService } from './shared/services/auction-cluster.service';
import { ProductService } from './shared/services/product.service';
import { MasterDataService } from './shared/services/master-data.service';
import { SupplierService } from './shared/services/supplier.service';
import { SupplyMonitorService } from './shared/services/supply-monitor.service';
import { BuyerLevelReportService } from './shared/services/report.service';
import { SupplyMatrixService } from './shared/services/supply-matrix.service';
import { AuctionService } from './shared/services/auction.service';
import { CatalogService } from './shared/services/catalog.service';
import { LookupTableService } from './shared/services/lookup-table.service';

import {
  DxColorBoxModule,
  DxDataGridModule,
  DxTemplateModule,
  DxBulletModule,
  DxButtonModule,
  DxSelectBoxModule,
  DxCheckBoxModule,
  DxTabsModule,
  DxTabPanelModule,
  DxTextBoxModule,
  DxValidatorModule,
  DxValidationSummaryModule,
  DxFormModule,
  DxValidationGroupModule,
  DxPopupModule,
  DxToastModule,
  DxScrollViewModule,
  DxTagBoxModule,
  DxListModule,
  DxBoxModule,
  DxNumberBoxModule,
  DxDateBoxModule,
  DxGalleryModule
} from 'devextreme-angular';

// shared modules
import { SharedModule } from '../shared/shared.module';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { DxReportViewerModule } from 'devexpress-reporting-angular';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    BuyerRoutingModule,
    HttpClientModule,
    TranslateModule,
    SharedModule,
    NgSelectModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    DxReportViewerModule,
    DxColorBoxModule,
    DxDataGridModule,
    DxTemplateModule,
    DxBulletModule,
    DxButtonModule,
    DxSelectBoxModule,
    DxCheckBoxModule,
    DxTabsModule,
    DxTabPanelModule,
    DxTextBoxModule,
    DxValidatorModule,
    DxValidationSummaryModule,
    DxFormModule,
    DxValidationGroupModule,
    DxPopupModule,
    DxToastModule,
    DxScrollViewModule,
    DxTagBoxModule,
    DxListModule,
    DxBoxModule,
    DxNumberBoxModule,
    DxDateBoxModule,
    DxGalleryModule
  ],
  declarations: [
    BuyerContentComponent,
    BuyerHomeComponent,
    BuyerClocksComponent,
    BuyerUsersComponent,
    BuyerUserComponent,
    OpenClockComponent,
    BuyerPrebidsComponent,
    BuyerPrebidComponent,
    BuyerPrebidLotsComponent,
    BuyerPrebidLotComponent,
    ShoppingListsComponent, ShoppingListComponent, ShoppingListLotsComponent, ShoppingListLotComponent,
    BuyerSupplyMonitorComponent,
    BuyerSupplyMonitorPrebidComponent,
    BuyerSupplyMatrixComponent,
    BuyerSupplyMatrixLotsComponent,
    ReportsComponent, ReportComponent, ReportEditorComponent,
    PrebidOnProductComponent, PrebidOnProductItemComponent,
    PrebidOnLotComponent
  ],
  providers: [
    BuyerSaleService,
    PrebidOnProductService,
    ShoppingListsService,
    AuctionClusterService,
    ProductService,
    MasterDataService,
    SupplierService,
    SupplyMonitorService,
    BuyerLevelReportService,
    BuyerBuyerService,
    BuyerUserService,
    SupplyMatrixService,
    AuctionService,
    CatalogService,
    LookupTableService
  ],
  bootstrap: [],
})
export class BuyerModule { }
